import { timePickerOptions } from '@/utils/util';
import ItemTitle from '../../../base/ui/title/itemTitle';

// :on-success="handleUploadSuccess"
// :before-upload="beforeAvatarUpload"
export default {
  name: 'ViewMerchantTaxDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      rules: {},
      date: [],
      fileList: [],
      rateTypeStatus: this.$formatTypeArray('rateTypeStatus'),
      freeText: '充值金额',
      freeName: '阶梯政策'
    };
  },
  props: {
    dialogFormVisible: false,
    merchantNo: '',
    item: {
      type: Object,
      default: () => {
        return {
          taxName: '',
          taxProtocolUrl: '',
          taxNo: '',
          rateListInfo: [],
          rateType: '',
          rechargeRate: ''
        };
      }
    }
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisible', val);
    }
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  methods: {
    goBack() {
      this.myDialogFormVisible = false;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    formatStatus(value) {
      return this.$formatType('rateTypeStatus', value);
    }
  }
};