import { timePickerOptions } from '@/utils/util';
import ItemTitle from '@/base/ui/title/itemTitle';
import FreeView from '@/base/ui/free/freeView';
export default {
  name: 'ViewAgentTaxDialog',
  components: {
    FreeView,
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      rateTypeStatus: this.$formatTypeArray('rateTypeStatus'),
      freeText: '充值金额',
      freeName: '阶梯政策'
    };
  },
  props: {
    dialogFormVisible: false,
    item: {
      type: Object,
      default: () => {
        return {
          agentNo: '',
          taxNo: '',
          taxName: '',
          rateListInfo: [],
          rateType: '',
          rechargeRate: ''
        };
      }
    }
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisible', val);
    }
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  methods: {
    goBack() {
      this.myDialogFormVisible = false;
    },
    formatStatus(value) {
      return this.$formatType('rateTypeStatus', value);
    }
  }
};