import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 获取园区列表*/
export const getTaxsourceList = params => {
  const {
    beginTime,
    cityCode,
    endTime,
    order,
    provinceCode,
    sort,
    status,
    taxName,
    taxNo,
    pageNo,
    pageSize,
    yearLargeSwitch
  } = params;
  return request({
    url: '/tax/taxsource/list',
    method: 'post',
    data: {
      beginTime,
      cityCode,
      endTime,
      order,
      provinceCode,
      sort,
      status,
      taxName,
      taxNo,
      pageNo,
      pageSize,
      yearLargeSwitch
    }
  });
};

/**
 * 新增基本信息*/
export const saveBase = params => {
  return request({
    url: '/tax/taxsource/saveBase',
    method: 'post',
    data: params
  });
};

/**
 * 新增或修改园区费率设置信息
 * @param params
 */
export const updateRateConfig = params => {
  const {
    agentRate,
    personRate,
    returnRate,
    serviceRate,
    supertaxRate,
    taxNo,
    vatRate,
    diffAgentRate,
    diffRate
  } = params;
  return request({
    url: '/tax/taxsource/updateRateConfig',
    method: 'post',
    data: {
      agentRate,
      personRate,
      returnRate,
      serviceRate,
      supertaxRate,
      taxNo,
      vatRate,
      diffAgentRate,
      diffRate
    }
  });
};

/**
 * 新增或修改园区参数设置信息
 * @param params
 */
export const updateParamsConfig = params => {
  const {
    accountNo,
    agentRate,
    appId,
    appKey,
    bankName,
    custName,
    personRate,
    privateKey,
    publicKey,
    returnRate,
    serviceRate,
    supertaxRate,
    taxNo,
    vatRate,
    ncPreposeIp,
    ncUserId,
    ncUserPwd
  } = params;
  return request({
    url: '/tax/taxsource/updateParamsConfig',
    method: 'post',
    data: {
      accountNo,
      agentRate,
      appId,
      appKey,
      bankName,
      custName,
      personRate,
      privateKey,
      publicKey,
      returnRate,
      serviceRate,
      supertaxRate,
      taxNo,
      vatRate,
      ncPreposeIp,
      ncUserId,
      ncUserPwd
    }
  });
};

/**
 * 修改基本信息*/
export const updateBase = params => {
  const {
    address,
    area,
    areaCode,
    city,
    cityCode,
    contactNumber,
    contacts,
    legalPerson,
    legalPersonIdcard,
    legalPersonIdcardpic,
    licenseNo,
    licensePic,
    protocolUrl,
    signetUrl,
    province,
    provinceCode,
    shortName,
    taxName,
    taxNo,
    quotaType,
    quota,
    rechargeNotifyMobile,
    rechargeAutoAudit,
    trusteeTimeEnd,
    trusteeMobile,
    trusteeIdCard,
    trusteeName,
    yearQuota,
    monthQuota,
    quarterQuota,
    yearLargeSwitch,
    yearLargeQuota
  } = params;
  return request({
    url: '/tax/taxsource/updateBase',
    method: 'post',
    data: {
      address,
      area,
      areaCode,
      city,
      cityCode,
      contactNumber,
      contacts,
      legalPerson,
      legalPersonIdcard,
      legalPersonIdcardpic,
      licenseNo,
      licensePic,
      protocolUrl,
      signetUrl,
      province,
      provinceCode,
      shortName,
      taxName,
      taxNo,
      quotaType,
      quota,
      rechargeNotifyMobile,
      rechargeAutoAudit,
      trusteeTimeEnd,
      trusteeMobile,
      trusteeIdCard,
      trusteeName,
      yearQuota,
      monthQuota,
      quarterQuota,
      yearLargeSwitch,
      yearLargeQuota
    }
  });
};

/**
 * 解冻园区*/
export const unfreeze = id => {
  return request({
    url: '/tax/taxsource/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结税源地*/
export const freeze = id => {
  return request({
    url: '/tax/taxsource/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结税源地*/
export const delTaxIndustry = id => {
  return request({
    url: '/tax/taxsource/delTaxIndustry',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *
 获取所属园区*/
export const showlist = () => {
  return request({
    url: '/tax/taxsource/showlist',
    method: 'get'
  });
};

/**
 *
 获取企业用户人员记录所属园区*/
export const mchntImportPersonTaxSelect = () => {
  return request({
    url: '/tax/taxsource/mchntImportPersonTaxSelect',
    method: 'get'
  });
};

/**
 *
 查看税源地详细信息(税源地)*/
export const showInfo = () => {
  return request({
    url: '/tax/taxsource/showInfo',
    method: 'get'
  });
};

/**
 * 获取行业税率配置列表信息*/
export const listTaxIndustry = params => {
  const {
    pageNo,
    pageSize,
    taxNo
  } = params;
  return request({
    url: '/tax/taxsource/listTaxIndustry',
    method: 'post',
    data: {
      pageNo,
      pageSize,
      taxNo
    }
  });
};

/**
 * 查看税源地详细信息(运营)*/
export const info = id => {
  return request({
    url: '/tax/taxsource/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 新增税源地行业税率信息*/
export const addTaxIndustry = params => {
  const {
    code,
    feeRate,
    taxNo
  } = params;
  return request({
    url: '/tax/taxsource/addTaxIndustry',
    method: 'post',
    data: {
      code,
      feeRate,
      taxNo
    }
  });
};

/**
 * 委托代征登记列表*/
export const taxWtdzdjListReg = params => {
  return request({
    url: '/taxdj/taxWtdzdj/list',
    method: 'post',
    data: params
  });
};

/**
 * 委托代征登记-回滚*/
export const deleteWtdzdjReg = params => {
  return request({
    url: '/taxdj/taxWtdzdj/deleteWtdzdj',
    method: 'post',
    data: params
  });
};

/**
 * 委托代征登记-详情*/
export const taxWtdzdjInfoReg = params => {
  return request({
    url: '/taxdj/taxWtdzdj/info',
    method: 'post',
    data: params
  });
};

/**
 *委托代征登记-导出申报数据记录
 */
export const downloadTaxWtdzdj = param => {
  return download({
    url: '/taxdj/taxWtdzdj/download',
    data: param
  });
};

/**
 * 完税证明登记表-列表*/
export const taxWszmdjListReq = params => {
  return request({
    url: '/taxdj/taxWszmdj/list',
    method: 'post',
    data: params
  });
};

/**
 * 完税证明登记表-删除*/
export const deleteTaxWszmdjReg = id => {
  return request({
    url: '/taxdj/taxWszmdj/delete',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *完税证明登记表-下载
 */
export const downloadTaxWszmdj = param => {
  return download({
    url: '/taxdj/taxWszmdj/download',
    data: param
  });
};

/**
 * 开票登记-获取列表信息*/
export const taxLsswkpListReq = params => {
  return request({
    url: '/taxdj/taxLsswkp/list',
    method: 'post',
    data: params
  });
};

/**
 * 开票登记-详情*/
export const taxLsswkpInfoReq = params => {
  return request({
    url: '/taxdj/taxLsswkp/info',
    method: 'post',
    data: params
  });
};

/**
 *开票登记-下载
 */
export const downloadTaxLsswkp = param => {
  return download({
    url: '/taxdj/taxLsswkp/download',
    data: param
  });
};

/**
 * 税务提醒-提醒列表*/
export const taxThresholdNoticeListReq = params => {
  return request({
    url: '/taxdj/taxThreshold/swtxList',
    method: 'post',
    data: params
  });
};

/**
 * 税务管理-提醒-阈值列表*/
export const taxThresholdListReq = params => {
  return request({
    url: '/taxdj/taxThreshold/list',
    method: 'post',
    data: params
  });
};

/**
 * 税务管理-提醒-新增阈值提醒*/
export const saveTaxThresholdReq = params => {
  return request({
    url: '/taxdj/taxThreshold/save',
    method: 'post',
    data: params
  });
};

/**
 * 税务管理-提醒-修改阈值提醒*/
export const updateTaxThresholdReq = params => {
  return request({
    url: '/taxdj/taxThreshold/update',
    method: 'post',
    data: params
  });
};

/**
 * 税务管理-个体户登记列表*/
export const taxGthdjListReq = params => {
  return request({
    url: '/taxdj/taxGthdj/list',
    method: 'post',
    data: params
  });
};

/**
 * 税务管理-个体户登记注销*/
export const updateGthdjReq = params => {
  return request({
    url: '/taxdj/taxGthdj/update',
    method: 'post',
    data: params
  });
};
/**
 *税务提醒-导出付款明细
 */
export const downloadTaxRepayments = param => {
  return download({
    url: '/taxdj/taxThreshold/downSwtxRepayments',
    data: param
  });
};

/**
 *税务提醒-导出临登材料
 */
export const downloadTaxRecord = param => {
  return download({
    url: '/taxdj/taxThreshold/downSwtxRecord',
    data: param
  });
};
/**
 * 临时税务登记-列表*/
export const taxLsswdjListReq = params => {
  return request({
    url: '/taxdj/taxLsswdj/list',
    method: 'post',
    data: params
  });
};

/**
 * 临时税务登记-注销*/
export const updateTaxLsswdjReq = id => {
  return request({
    url: '/taxdj/taxLsswdj/update',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 临时税务登记-新增*/
export const saveTaxLsswdjReq = params => {
  return request({
    url: '/taxdj/taxLsswdj/update',
    method: 'post',
    data: params
  });
};

/**
 * 临时税务登记-获取园区省市信息*/
export const taxsourceProvinceCityInfoReg = params => {
  return request({
    url: '/taxdj/taxLsswdj/taxsourceProvinceCityInfo',
    method: 'post',
    data: params
  });
};

/**
 *临时税务登记-下载
 */
export const downloadTaxLsswdj = param => {
  return download({
    url: '/taxdj/taxLsswdj/download',
    data: param
  });
};

/**
 *配置发票抬头
 */
export const updateInvoiceConfig = params => {
  return request({
    url: '/tax/taxsource/updateInvoiceConfig',
    method: 'post',
    data: params
  });
};