var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "任务信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务编号：",
      value: _vm.infoForm.taskNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务名称：",
      value: _vm.infoForm.taskName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "商户编号：",
      value: _vm.infoForm.merchantNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "所属商户：",
      value: _vm.infoForm.merchantName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "所属劳务公司编号：",
      value: _vm.infoForm.taxNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "所属劳务公司名称：",
      value: _vm.infoForm.taxName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务类型：",
      value: _vm.infoForm.taskTypeName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务状态：",
      value: _vm.$formatType("taskStatus", _vm.infoForm.taskStatus),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务计价方式：",
      value: _vm.infoForm.pricingModeName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "数量：",
      value: _vm.infoForm.submitCount,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务金额(元)：",
      value: _vm.infoForm.singleAmount,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "合计金额(元)：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.auditAmount),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "创建时间：",
      value: _vm.infoForm.createTime,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务有效期：",
      value: _vm.$formatDate(_vm.infoForm.startDate) + "至" + _vm.$formatDate(_vm.infoForm.endDate),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务描述：",
      value: _vm.infoForm.caption,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("任务附件：")]), _vm._v(" "), _c("div", _vm._l(_vm.infoForm.taskFileList, function (item) {
    return _c("div", [_c("el-link", {
      attrs: {
        type: "primary",
        href: item.fileUrlShow,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName))])], 1);
  }), 0)])], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "提交信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "提交时间：",
      value: _vm.infoForm.submitDate,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "提交金额",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.submitAmount),
      left: false
    }
  })], 1), _vm._v(" "), _vm._l(_vm.infoForm.extraList, function (extra, index) {
    return index % 2 == 0 ? _c("div", {
      staticClass: "row"
    }, [_c("detail-column", {
      attrs: {
        title: _vm.infoForm.extraList[index].key + "：",
        value: _vm.infoForm.extraList[index].value,
        left: true
      }
    }), _vm._v(" "), index + 1 < _vm.infoForm.extraList.length ? _c("detail-column", {
      attrs: {
        title: _vm.infoForm.extraList[index + 1].key + "：",
        value: _vm.infoForm.extraList[index + 1].value,
        left: true
      }
    }) : _vm._e()], 1) : _vm._e();
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "内容：",
      value: _vm.infoForm.content,
      "full-line": ""
    }
  })], 1), _vm._v(" "), _vm.infoForm.submitType != 2 ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("附件：")]), _vm._v(" "), _c("div", _vm._l(_vm.infoForm.annexFileList, function (item) {
    return _c("div", [_c("el-link", {
      attrs: {
        type: "primary",
        href: item.fileUrlShow,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName))])], 1);
  }), 0)]) : _vm._e(), _vm._v(" "), _vm.infoForm.photoFileList.length > 0 ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("照片：")]), _vm._v(" "), _c("div", {
    staticClass: "photo-file-list"
  }, _vm._l(_vm.infoForm.photoFileList, function (item) {
    return _c("div", [_c("el-image", {
      staticStyle: {
        width: "100px",
        height: "100px",
        "margin-right": "30px"
      },
      attrs: {
        src: item.fileUrlShow,
        "preview-src-list": [item.fileUrlShow]
      }
    })], 1);
  }), 0)]) : _vm._e(), _vm._v(" "), _vm.infoForm.submitType == 2 ? _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.listData,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "regular1",
      label: "医院名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "regular2",
      label: "医院等级"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.goDetails(scope.row);
            }
          }
        }, [_vm._v("详情")])];
      }
    }], null, false, 859468120)
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      autoScroll: false,
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1) : _vm._e()], 2), _vm._v(" "), _c("el-form", {
    ref: "auditForm",
    staticClass: "save-base-agent border-bg",
    attrs: {
      model: _vm.auditForm,
      "label-position": "left",
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "审核结果：",
      prop: "status"
    },
    model: {
      value: _vm.auditForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "status", $$v);
      },
      expression: "auditForm.status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.auditForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "status", $$v);
      },
      expression: "auditForm.status"
    }
  }, [_c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 2
    }
  }, [_vm._v("审核通过")]), _vm._v(" "), _c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 3
    }
  }, [_vm._v("审核拒绝")])], 1)], 1), _vm._v(" "), _vm.auditForm.status === 2 ? _c("el-form-item", {
    attrs: {
      label: "确认任务金额：",
      prop: "auditAmount"
    },
    model: {
      value: _vm.auditForm.auditAmount,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditAmount", $$v);
      },
      expression: "auditForm.auditAmount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入最终确认的任务金额"
    },
    model: {
      value: _vm.auditForm.auditAmount,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditAmount", $$v);
      },
      expression: "auditForm.auditAmount"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "审核意见：",
      prop: "auditRemark"
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入任务审核意见，最多可输入80个字(含标点符号)",
      maxlength: "80",
      "show-word-limit": ""
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.check();
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };