import request from '@/utils/request';
import download from '@/utils/download';

/**
 * /empTeam/invoice/list 班组开票列表
 */
export const invoiceList = data => {
  return request({
    url: '/empTeam/invoice/list',
    method: 'post',
    data
  });
};

/**
 * /empTeam/invoice/download 导出班组开票记录
 */
export const downloadInvoice = params => {
  return download({
    url: '/empTeam/invoice/download',
    data: params
  });
};

/**
 * /empTeam/invoice/info 获取详细信息(传参示例{id:123})
 */
export const invoiceInfo = params => {
  return request({
    url: '/empTeam/invoice/info',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/invoice/submit 提交班组开票
 */
export const submitInvoice = params => {
  return request({
    url: '/empTeam/invoice/submit',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/invoice/audit
班组开票审核
 */
export const invoiceAudit = params => {
  return request({
    url: '/empTeam/invoice/audit',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/invoice/confirm
确认出票
 */
export const invoiceConfirm = params => {
  return request({
    url: '/empTeam/invoice/confirm',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/invoice/cancel
确认出票
 */
export const cancel = params => {
  return request({
    url: '/empTeam/invoice/cancel',
    method: 'post',
    data: params
  });
};