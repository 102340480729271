import Pagination from '@/components/pagination';
import MsgItem from './msgItem';
import { getMsgCenterList } from '@/api/sys';
export default {
  name: 'subList',
  components: {
    MsgItem,
    Pagination
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: []
    };
  },
  props: {
    readFlag: {
      required: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getList();
  },
  methods: {
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getMsgCenterList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        readFlag: this.readFlag
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    freshData() {
      this.getList();
    }
  }
};