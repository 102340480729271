var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("footer", {
    staticClass: "footer-view"
  }, [_c("p", [_vm._v("Copyright ©2022   " + _vm._s(_vm.taxBrand) + "   "), _c("a", {
    attrs: {
      href: _vm.recordAddress,
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.recordNumber))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };