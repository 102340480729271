import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { downloadTaskReportMerchant, getTaskReportMerchantList } from '../../../api/task';
import { downloadExcel } from '../../../utils/util';
import { merchantShowList } from '../../../api/merchant';
import { getAcountInfo } from '../../../utils/auth';
import { showlist } from '../../../api/taxsource';
export default {
  name: 'reportMerchantContent',
  components: {
    Pagination
  },
  data() {
    return {
      searchForm: {
        merchantNo: '',
        month: '',
        status: '',
        taxNo: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      downloadVisible: '',
      addCategoryShow: false,
      updateCategoryShow: false,
      updateItem: {},
      merchantList: [],
      taxSources: [],
      partyType: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    this.getTaxSources();
    this.partyType = getAcountInfo().partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      this.getMerchantList();
    }
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.downloadVisible = map.has('download');
    }
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.dateTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getTaskReportMerchantList({
        merchantNo: this.currentSearch.merchantNo,
        month: this.currentSearch.month,
        status: this.currentSearch.status,
        taxNo: this.currentSearch.taxNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    handleDownload(row) {
      const params = {
        id: row.id
      };
      downloadTaskReportMerchant(params).then(response => {
        downloadExcel(response.data, '任务归总表格.xls');
      });
    },
    handleAdd() {
      this.addCategoryShow = true;
    },
    changeAddDialogVisible(val) {
      this.addCategoryShow = val;
      if (!val) {
        this.getList();
      }
    },
    changeUpdateDialogVisible(val) {
      this.updateCategoryShow = val;
      if (!val) {
        this.getList();
      }
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};