import { taxsourceShowlist, mchntProjectSignProjectList, empTeamSignedSave } from '@/api/leaderApply';
export default {
  props: {
    visiable: Boolean,
    projectNo: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      formLabelWidth: '120px',
      companyList: [],
      // 公司列表
      projectList: [],
      // 项目列表
      settlementList: [],
      form: {
        taxNo: '',
        projectNo: ''
      },
      rules: {
        taxNo: [{
          required: true,
          message: '请选择签约劳务公司',
          trigger: 'blur'
        }],
        projectNo: [{
          required: true,
          message: '请选择签约项目',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '申请班组签约';
    }
  },
  methods: {
    opened() {
      this.taxsourceShowlistAPI();
    },
    async taxsourceShowlistAPI() {
      const res = await taxsourceShowlist();
      this.companyList = res.data;
    },
    async mchntProjectSignProjectListAPI(taxNo) {
      this.projectList = [];
      this.form.projectNo = '';
      const params = {
        taxNo,
        teamNo: this.formData.teamNo
      };
      const res = await mchntProjectSignProjectList(params);
      this.projectList = res.data;
    },
    onClose() {
      this.handleClose();
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      this.$refs['ruleForm'].resetFields();
      done && done();
    },
    onSure() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.showModal();
        } else {
          return false;
        }
      });
    },
    showModal() {
      this.$confirm('平台将会短信通知班组长前往小程序签约，是否确定提交？', '提示', {
        closeOnClickModal: false
      }).then(() => {
        this.empTeamSignedSaveAPI();
      }).catch(() => {});
    },
    async empTeamSignedSaveAPI() {
      const params = {
        projectNo: this.form.projectNo,
        taxNo: this.form.taxNo,
        teamNo: this.formData.teamNo
      };
      await empTeamSignedSave(params);
      this.$emit('doRefresh');
      this.$message.success('操作成功！');
      this.dialogFormVisible = false;
    }
  }
};