import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import CityCascader from '@/base/ui/cascader/cityCascader';
import { downloadTaxLsswdj, taxLsswdjListReq, updateTaxLsswdjReq } from '../../../api/taxsource';
import { downloadExcel } from '../../../utils/util';
import SaveLsswdjDialog from './components/saveDialog';
export default {
  name: 'taxLsswdjList',
  components: {
    SaveLsswdjDialog,
    ItemTitle,
    Pagination,
    DbDatePicker,
    CityCascader
  },
  data() {
    return {
      total: 0,
      list: [],
      merchantList: [],
      provinceCityList: [],
      listLoading: true,
      searchForm: {
        custName: '',
        idcard: '',
        status: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      saveLsswdj: false,
      deleteLsswdj: false,
      downloadLsswdj: false,
      saveDialogVisible: false,
      taxsList: [],
      datePicker: []
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.saveLsswdj = map.get('saveLsswdj');
      this.deleteLsswdj = map.get('deleteLsswdj');
      this.downloadLsswdj = map.get('downloadLsswdj');
    }
    this.getTaxsList();
  },
  methods: {
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      taxLsswdjListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.pageNo = 1;
      this.queryForm();
    },
    downloadData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      downloadTaxLsswdj(this.currentSearch).then(response => {
        downloadExcel(response.data, '临时税务登记记录.xls');
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    handleDelete(row) {
      this.$confirm('是否确认注销该人员的临时税务登记?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        updateTaxLsswdjReq(row.id).then(res => {
          this.$message({
            message: '注销成功',
            type: 'success'
          });
          // this.getDictDetailList(this.viewDictGroupForm.id)
          this.getList();
        });
      }).catch(() => {});
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};