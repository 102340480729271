var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "服务商编号：",
      value: _vm.infoForm.agentNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "服务商名称：",
      value: _vm.infoForm.agentName,
      left: false
    }
  })], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "setUp",
      label: "状态",
      formatter: _vm.$formatTypeInTable("rateSetUp")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.setUp === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleAdd(scope.row);
            }
          }
        }, [_vm._v("设置")]) : _vm._e(), _vm._v(" "), scope.row.setUp === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), scope.row.setUp === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("update-agent-tax-dialog", {
    attrs: {
      dialogFormVisible: _vm.updateAgentTaxDialogVisible,
      item: _vm.viewDialogItem,
      agentNo: _vm.infoForm.agentNo
    },
    on: {
      changeVisible: _vm.changeVisible
    }
  }), _vm._v(" "), _c("view-agent-tax-dialog", {
    attrs: {
      dialogFormVisible: _vm.viewAgentTaxDialogVisible,
      item: _vm.viewDialogItem
    },
    on: {
      changeVisible: _vm.changeViewVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };