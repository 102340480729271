var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "upload-invoice-dialog",
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "上传半身照"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("el-form", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "人员姓名："
    }
  }, [_vm._v("\n\n        " + _vm._s(_vm.name) + "\n\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传半身照："
    }
  }, [_c("div", {
    staticClass: "upload-box"
  }, [_c("div", {
    staticStyle: {
      "min-width": "100px",
      "margin-right": "20px"
    }
  }, [_vm._v(_vm._s(this.myFileName))]), _vm._v(" "), _c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "show-file-list": false,
      "on-success": _vm.onSuccess,
      "before-upload": _vm.beforeDemoUpload,
      action: _vm.uploadHalfFile,
      data: {
        empNo: _vm.empNo
      }
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(this.myFileName ? "重新上传" : "点击上传"))])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：仅支持上传.jpg、.png格式，像素推荐480*640px)")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "40px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.doUploadHalfBody
    }
  }, [_vm._v("确认")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      size: "mini"
    },
    on: {
      click: _vm.close
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };