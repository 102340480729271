var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    attrs: {
      model: _vm.info,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "归属劳务公司",
      prop: "taxNo"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.info.taxName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知类型",
      prop: "noticeType"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("noticeType", _vm.info.noticeType)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", [_vm.info.noticeType === 1 ? _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知位置",
      prop: "popupFlag"
    }
  }, [_vm._v("\n          消息中心" + _vm._s(_vm.info.popupFlag == 1 ? ", 首页弹框" : "") + "\n        ")])], 1) : _vm._e()], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知标题",
      prop: "title"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.info.title) + "\n        ")])], 1)], 1), _vm._v(" "), _vm.info.noticeType === 0 || _vm.info.noticeType === 1 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "副标题",
      prop: "subTitle"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.info.subTitle) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "打开方式",
      prop: "openType"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$formatType("openType", _vm.info.openType)) + "\n          ")])], 1)], 1), _vm._v(" "), _vm.info.openType === 0 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知详情",
      prop: "contents"
    }
  }, [_c("div", {
    staticClass: "decode-class",
    domProps: {
      innerHTML: _vm._s(_vm.decodeContents(_vm.info.contents))
    }
  })])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.info.openType === 1 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "H5链接配置",
      prop: "contents"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.info.contents) + "\n          ")])], 1)], 1) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.info.noticeType === 2 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "短信内容",
      prop: "contents"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.info.contents) + "\n        ")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.info.noticeType === 1 || _vm.info.noticeType === 2 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知对象",
      prop: "accepterType"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$formatType("accepterType", _vm.info.accepterType)) + "\n          ")])], 1)], 1), _vm._v(" "), _vm.info.accepterType === 2 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.accountTitle,
      prop: "accepterList"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.info.accepterList) + "\n          ")])], 1)], 1) : _vm._e()] : _vm._e(), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发布方式",
      prop: "publishType"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("publishType", _vm.info.publishType)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发布时间",
      prop: "effectTime"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.info.effectTime) + "\n        ")])], 1)], 1), _vm._v(" "), _vm.info.noticeType === 0 || _vm.info.noticeType === 1 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "失效时间",
      prop: "invalidTime"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.info.invalidTime) + "\n        ")])], 1)], 1) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };