var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "border-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择签约劳务公司"
    },
    model: {
      value: _vm.saveForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxNo", $$v);
      },
      expression: "saveForm.taxNo"
    }
  }, _vm._l(_vm.taxSourceList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道类型",
      prop: "channelType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "请选择通道类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.channelType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "channelType", $$v);
      },
      expression: "saveForm.channelType"
    }
  }, _vm._l(_vm.channelTypes, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道序号",
      prop: "channelNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入通道序号，例如：001"
    },
    model: {
      value: _vm.saveForm.channelNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "channelNo", $$v);
      },
      expression: "saveForm.channelNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账开户行",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入劳务公司入账开户行"
    },
    model: {
      value: _vm.saveForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "bankName", $$v);
      },
      expression: "saveForm.bankName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账账户",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入账账户"
    },
    model: {
      value: _vm.saveForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "accountNo", $$v);
      },
      expression: "saveForm.accountNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账户名",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入劳务公司入账户名"
    },
    model: {
      value: _vm.saveForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "custName", $$v);
      },
      expression: "saveForm.custName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道名称",
      prop: "channelName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入通道名称"
    },
    model: {
      value: _vm.saveForm.channelName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "channelName", $$v);
      },
      expression: "saveForm.channelName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "基准类型",
      prop: "baseType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "请选择基准类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.baseType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "baseType", $$v);
      },
      expression: "saveForm.baseType"
    }
  }, _vm._l(_vm.$formatTypeArray("taxChannelBaseType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态",
      prop: "status"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "status", $$v);
      },
      expression: "saveForm.status"
    }
  }, _vm._l(_vm.taxStatusType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("保存")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };