import { render, staticRenderFns } from "./rechargeReview.vue?vue&type=template&id=7d96f1bb"
import script from "./rechargeReview.vue?vue&type=script&lang=js"
export * from "./rechargeReview.vue?vue&type=script&lang=js"
import style0 from "./rechargeReview.vue?vue&type=style&index=0&id=7d96f1bb&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d96f1bb')) {
      api.createRecord('7d96f1bb', component.options)
    } else {
      api.reload('7d96f1bb', component.options)
    }
    module.hot.accept("./rechargeReview.vue?vue&type=template&id=7d96f1bb", function () {
      api.rerender('7d96f1bb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/recharge/rechargeReview.vue"
export default component.exports