import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import UpdateAgentTaxDialog from './updateAgentTaxDialog';
import ViewAgentTaxDialog from './viewAgentTaxDialog';
import { getAgentProfitRuleList } from '../../../api/agent';
import { RATE_ADD_AGENT_INFO, RATE_LIST_AGENT_NO, RATE_UPDATE_AGENT_INFO, RATE_VIEW_AGENT_INFO } from '../../../utils/idName';
import DetailColumn from '../../../components/detailColumn/index';
export default {
  name: 'agentRateList',
  components: {
    DetailColumn,
    ViewAgentTaxDialog,
    UpdateAgentTaxDialog,
    ItemTitle
  },
  data() {
    return {
      list: [],
      updateAgentTaxDialogVisible: false,
      viewAgentTaxDialogVisible: false,
      dialogItem: {},
      viewDialogItem: {},
      infoForm: {}
    };
  },
  created() {
    const infoFormJson = sessionStorage.getItem(RATE_LIST_AGENT_NO);
    if (infoFormJson) {
      this.infoForm = JSON.parse(infoFormJson);
    }
    this.getList();
  },
  methods: {
    getList() {
      if (!this.infoForm) {
        return;
      }
      getAgentProfitRuleList(this.infoForm.agentNo).then(response => {
        this.list = response.data;
      });
    },
    handleAdd(row) {
      const addInfo = {
        taxName: row.taxName,
        taxNo: row.taxNo,
        agentNo: this.infoForm.agentNo,
        agentName: this.infoForm.agentName,
        agentLevel: this.infoForm.agentLevel
      };
      sessionStorage.setItem(RATE_ADD_AGENT_INFO, JSON.stringify(addInfo));
      this.$router.push({
        path: '/agent/agentList/addAgentRate'
      });
    },
    handleView(row) {
      const viewInfo = {
        id: row.id,
        taxName: row.taxName,
        taxNo: row.taxNo,
        agentNo: this.infoForm.agentNo,
        agentName: this.infoForm.agentName,
        agentLevel: this.infoForm.agentLevel
      };
      sessionStorage.setItem(RATE_VIEW_AGENT_INFO, JSON.stringify(viewInfo));
      this.$router.push({
        path: '/agent/agentList/viewAgentRate'
      });
    },
    handleUpdate(row) {
      const updateInfo = {
        id: row.id,
        taxName: row.taxName,
        taxNo: row.taxNo,
        agentNo: this.infoForm.agentNo,
        agentName: this.infoForm.agentName,
        agentLevel: this.infoForm.agentLevel
      };
      sessionStorage.setItem(RATE_UPDATE_AGENT_INFO, JSON.stringify(updateInfo));
      this.$router.push({
        path: '/agent/agentList/updateAgentRate'
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, 1, 200);
    },
    changeVisible(val) {
      // 监听新增dialog的dialogFormVisible的变化
      this.updateAgentTaxDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    changeViewVisible(val) {
      this.viewAgentTaxDialogVisible = val;
      if (this.viewAgentTaxDialogVisible === false) {
        this.getList();
      }
    }
  }
};