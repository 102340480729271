import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { downloadTaskReportPerson, getTaskReportPersonList, sendSmsByTaskReportPerson } from '../../../api/task';
import { copyText, downloadExcel, separateDate } from '../../../utils/util';
import DbDatePicker from '../../../base/ui/date/db-date-picker';
import { merchantShowList } from '../../../api/merchant';
import { getAcountInfo } from '../../../utils/auth';
export default {
  name: 'reportPersonContent',
  components: {
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      searchForm: {
        beginTime: '',
        endTime: '',
        custName: '',
        idcard: '',
        merchantNo: '',
        month: '',
        status: '',
        completeTime: []
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      copyLinkVisible: '',
      sendMessageVisible: '',
      detailsVisibel: '',
      downloadVisible: '',
      updateCategoryShow: false,
      merchantList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.copyLinkVisible = map.get('copyLinkVisible');
      this.sendMessageVisible = map.get('sendMessageVisible');
      this.downloadVisible = map.has('download');
      this.detailsVisible = map.get('details');
    }
    this.partyType = getAcountInfo().partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      this.getMerchantList();
    }
    this.getList();
  },
  methods: {
    goDetails(item) {
      this.$router.push({
        path: this.detailsVisible,
        query: {
          id: item.id
        }
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.dateTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const completeTime = separateDate(this.currentSearch.completeTime);
      getTaskReportPersonList({
        beginTime: completeTime.beginTime,
        endTime: completeTime.endTime,
        custName: this.currentSearch.custName,
        idcard: this.currentSearch.idcard,
        merchantNo: this.currentSearch.merchantNo,
        month: this.currentSearch.month,
        status: this.currentSearch.status,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadForm() {
      if (this.list.length <= 0) {
        this.$message.error('暂无数据导出');
        return;
      }
      const completeTime = separateDate(this.currentSearch.completeTime);
      const params = {
        beginTime: completeTime.beginTime,
        endTime: completeTime.endTime,
        custName: this.currentSearch.custName,
        idcard: this.currentSearch.idcard,
        merchantNo: this.currentSearch.merchantNo,
        month: this.currentSearch.month,
        status: this.currentSearch.status
      };
      downloadTaskReportPerson(params).then(response => {
        downloadExcel(response.data, '人员月度任务报告.xls');
      });
    },
    copyLink(row) {
      copyText(row.reportUrl).then(() => {
        this.$message.success('复制成功。');
      });
    },
    sendMessage(row) {
      sendSmsByTaskReportPerson(row.id).then(() => {
        this.$message.success('发送成功。');
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    }
  }
};