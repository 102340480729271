var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "班组开票记录"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票订单编号",
      prop: "serialNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开票订单编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.serialNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "serialNo", $$v);
      },
      expression: "searchForm.serialNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称",
      prop: "teamName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入班组名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.teamName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "teamName", $$v);
      },
      expression: "searchForm.teamName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组长姓名",
      prop: "monitorName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入班组长姓名",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.monitorName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "monitorName", $$v);
      },
      expression: "searchForm.monitorName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择所属劳务公司",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: `${tax.taxName}(${tax.taxNo})`,
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属项目子编号",
      prop: "projectNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入所属项目子编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectNo", $$v);
      },
      expression: "searchForm.projectNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目名称",
      prop: "projectName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectName", $$v);
      },
      expression: "searchForm.projectName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "申请时间",
      prop: "applyDate",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.applyDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "applyDate", $$v);
      },
      expression: "searchForm.applyDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "出票日期",
      prop: "invoiceDate",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.invoiceDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceDate", $$v);
      },
      expression: "searchForm.invoiceDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "完成时间",
      prop: "completeTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.completeTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "completeTime", $$v);
      },
      expression: "searchForm.completeTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择开票状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.invoiceStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceStatus", $$v);
      },
      expression: "searchForm.invoiceStatus"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("\n      查询汇总：  开票笔数  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.total))]), _vm._v("   笔；  开票金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$fmoneyWithSymbol(_vm.totalAmt)))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.invoices,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serialNo",
      label: "开票订单号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "teamName",
      label: "班组名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "monitorName",
      label: "班组长姓名",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectNo",
      label: "所属项目子编号",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectName",
      label: "项目名称",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "applyTime",
      label: "申请时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceDate",
      label: "出票时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "completeTime",
      label: "完成时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceAmount",
      label: "发票金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceTypeName",
      label: "发票类型",
      "min-width": "220"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceContent",
      label: "发票内容",
      "min-width": "180",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceStatusName",
      label: "发票状态",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "min-width": "120",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _vm.detailPath || _vm.hasCancelPermission || _vm.auditInvoicePath || _vm.hasConfirmInvoice ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "200",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.detailPath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.checkRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.auditInvoicePath && scope.row.invoiceStatus === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.reviewRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e(), _vm._v(" "), _vm.hasConfirmInvoice && scope.row.invoiceStatus === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.confirmRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("确认出票")]) : _vm._e(), _vm._v(" "), _vm.hasCancelPermission && [0, 1].includes(scope.row.invoiceStatus) ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.cancelRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("取消")]) : _vm._e()];
      }
    }], null, false, 422828846)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _c("dialog-review-invoice", {
    attrs: {
      isVisible: _vm.isReviewVisible,
      info: _vm.selectRow
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isReviewVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isReviewVisible = $event;
      },
      doRefresh: _vm.updated
    }
  }), _vm._v(" "), _c("dialog-confirm-invoice", {
    attrs: {
      isVisible: _vm.isConfirmVisible,
      info: _vm.selectRow
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isConfirmVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isConfirmVisible = $event;
      },
      doRefresh: _vm.updated
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };