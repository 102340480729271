import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { downloadExcel } from '@/utils/util';
import { taxsReportList, downTaxsReportList } from '@/api/statistics';
import { separateDate } from '../../../utils/util';
import DbDatePicker from '../../../base/ui/date/db-date-picker';
import { MERCHANT_INFO_ID } from '../../../utils/idName';
export default {
  name: 'companyInvoiceList',
  components: {
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      searchForm: {
        "beginDate": "",
        "endDate": "",
        "taxName": "",
        "taxNo": ""
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      hasDownloadPermission: false,
      viewMerchantPath: '',
      dateTime: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.has('download');
    } else {
      this.hasDownloadPermission = false;
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.pageNo = 1;
      this.dateTime = [];
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const time = separateDate(this.dateTime);
      taxsReportList({
        taxName: this.searchForm.taxName,
        taxNo: this.searchForm.taxNo,
        beginTime: time.beginTime,
        endTime: time.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadForm() {
      const time = separateDate(this.dateTime);
      downTaxsReportList({
        taxName: this.searchForm.taxName,
        taxNo: this.searchForm.taxNo,
        beginTime: time.beginTime,
        endTime: time.endTime
      }).then(response => {
        downloadExcel(response.data, '劳务公司资金发票报表.xls');
      });
    },
    handleView(row) {
      sessionStorage.setItem(MERCHANT_INFO_ID, row.id);
      this.$router.push({
        path: this.viewMerchantPath
      });
    }
  }
};