var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tabs", {
    staticClass: "border-bg",
    staticStyle: {
      "margin-top": "20px"
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "临登提醒阀值",
      name: "first"
    }
  }, [_c("tax-sub-list", {
    attrs: {
      thresholdType: 1
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "个体户提醒阀值",
      name: "second"
    }
  }, [_c("tax-sub-list", {
    attrs: {
      thresholdType: 2
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };