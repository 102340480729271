var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "修改委托代征登记"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.exData,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "申报月份：",
      prop: "djMonth",
      disabled: ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.exData.djMonth) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.exData.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.exData, "taxNo", $$v);
      },
      expression: "exData.taxNo"
    }
  }, _vm._l(_vm.taxList, function (tax) {
    return tax.taxNo !== "SYSTEM" ? _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    }) : _vm._e();
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "upload",
    attrs: {
      label: "完税明细："
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadApplyFile,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.onSuccess,
      data: _vm.exData,
      "auto-upload": false,
      limit: 1
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_temp_label",
    attrs: {
      type: "primary",
      href: _vm.templateUrl,
      target: "_blank",
      download: "委托代征登记模版"
    }
  }, [_vm._v("导出模板")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.submitUpload();
      }
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.myDialogFormVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };