var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_vm.orderNo === undefined || _vm.orderNo === "" ? _c("el-form-item", {
    attrs: {
      label: "充值订单号",
      prop: "orderNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入充值订单号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.orderNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "orderNo", $$v);
      },
      expression: "searchForm.orderNo"
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      label: "充值订单号",
      prop: "orderNo"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.orderNo) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户编号",
      prop: "customerNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerNo", $$v);
      },
      expression: "searchForm.customerNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "入账类型",
      prop: "customerNo"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择入账类型",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.recordType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "recordType", $$v);
      },
      expression: "searchForm.recordType"
    }
  }, _vm._l(_vm.$formatTypeArray("recordedType"), function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "入账时间",
      prop: "date",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      "margin-bottom": "10px",
      flex: "1"
    },
    attrs: {
      data: _vm.transactions,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "充值订单号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custNo",
      label: "客户编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "客户名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "入账类型",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("recordedType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeAmount",
      label: "充值金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "chargingAmount",
      label: "计费金额",
      width: "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "成本费率",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.costRate !== null ? _c("div", [_vm._v(_vm._s((scope.row.costRate * 100).toFixed(2)) + "%")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "下级费率",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.agencyRate !== null ? _c("div", [_vm._v(_vm._s((scope.row.agencyRate * 100).toFixed(2)) + "%")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountIncome",
      label: "入账金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "入账时间",
      "min-width": "190"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };