import ItemTitle from '../../../base/ui/title/itemTitle';
import FreeView from '../../../base/ui/free/freeView';
import { RATE_VIEW_AGENT_INFO } from '../../../utils/idName';
import DetailColumn from '../../../components/detailColumn/index';
import { getAgentProfitRuleInfo } from '../../../api/agent';
export default {
  name: 'updateAgentRate',
  components: {
    DetailColumn,
    FreeView,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        agentNo: '',
        taxNo: '',
        profitExplain: '',
        rewardExplain: '',
        ratioExplain: '',
        profitInfo: [],
        profitRatio: [],
        rewardInfo: [],
        profitType: ''
      },
      profitRateList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      ratioRateList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      rewardRateList: [{
        start: 0,
        end: '',
        rate: ''
      }]
    };
  },
  created() {
    const infoFormJson = sessionStorage.getItem(RATE_VIEW_AGENT_INFO);
    if (infoFormJson) {
      this.infoForm = JSON.parse(infoFormJson);
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      getAgentProfitRuleInfo(this.infoForm.id).then(response => {
        this.saveForm = response.data;
        this.profitRateList = this.saveForm.profitInfoList;
        this.ratioRateList = this.saveForm.profitRatioList;
        this.rewardRateList = this.saveForm.rewardInfoList;
      });
    }
  }
};