import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import AddTaxRouterDialog from './components/AddTaxRouterDialog';
import { getTaxsourceRouterList, updateNormalTaxRouter, updateStopTaxRouter } from '../../../api/taxRouter';
import UpdateTaxRouterDialog from './components/UpdateTaxRouterDialog';
export default {
  name: 'taxRouterList',
  components: {
    UpdateTaxRouterDialog,
    AddTaxRouterDialog,
    Pagination,
    ItemTitle
  },
  data() {
    return {
      searchForm: {
        taxName: '',
        taxNo: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: false,
      transactions: [],
      taxRouterStatus: this.$formatTypeArray('taxRouterStatus'),
      taxRouterRechargeInfo: '',
      addDialogVisible: false,
      updateDialogVisible: false,
      AddTaxRouterVisible: '',
      updateSortTaxRouterVisible: '',
      updateStopVisible: '',
      updateNormalVisible: '',
      clickItem: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.AddTaxRouterVisible = map.get('AddTaxRouter');
      this.updateSortTaxRouterVisible = map.get('updateSortTaxRouter');
      this.updateStopVisible = map.get('updateStop');
      this.updateNormalVisible = map.get('updateNormal');
    } else {
      this.AddTaxRouterVisible = '';
      this.updateSortTaxRouterVisible = '';
      this.updateStopVisible = '';
      this.updateNormalVisible = '';
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.searchForm = {
        taxName: '',
        taxNo: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getTaxsourceRouterList(this.currentSearch).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    updateSort(row) {
      this.clickItem = row;
      this.updateDialogVisible = true;
    },
    formatStatus(row, column) {
      return this.$formatType('taxRouterStatus', row.status);
    },
    changeAddDialogVisible(val) {
      this.addDialogVisible = val;
      this.getList();
    },
    changeUpateDialogVisible(val) {
      this.updateDialogVisible = val;
      this.getList();
    },
    updateNormal(row) {
      this.$confirm('启用操作后，其他劳务公司进行路由付款时，将可能选用该劳务公司，确定启用击“确认”，其他点击“取消”！', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        updateNormalTaxRouter(row.id).then(response => {
          this.$message.success('修改成功');
          this.getList();
        });
      });
    },
    updateStop(row) {
      this.$confirm('停用操作后，其他劳务公司进行路由付款时，将不会选用该劳务公司，确定启用击“确认”，其他点击“取消”！', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        updateStopTaxRouter(row.id).then(response => {
          this.$message.success('修改成功');
          this.getList();
        });
      });
    }
  }
};