import ViewBase from './components/view/viewBase';
import ViewBankCard from './components/view/viewBankCard';
import { info } from '@/api/agent';
import { AGENT_INFO_ID } from '@/utils/idName';
export default {
  components: {
    ViewBase,
    ViewBankCard
  },
  data() {
    return {
      active: 0,
      message: 'first',
      id: sessionStorage.getItem(AGENT_INFO_ID),
      bean: {
        bankCard: {}
      }
    };
  },
  created() {
    this.freshData();
  },
  watch: {
    agentNo(vla) {
      this.agentNo = vla;
      this.getList();
    }
  },
  methods: {
    freshData() {
      const that = this;
      const id = sessionStorage.getItem(AGENT_INFO_ID);
      info(id).then(response => {
        that.bean = response.data;
        if (this.bean.bankCard === null) {
          this.bean.bankCard = {};
        }
      });
    }
  }
};