var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("svg", {
    staticClass: "hamburger",
    class: {
      "is-active": _vm.isActive
    },
    attrs: {
      t: "1492500959545",
      viewBox: "0 0 1024 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "1691",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      width: "64",
      height: "64"
    },
    on: {
      click: _vm.toggleClick
    }
  }, [_c("path", {
    attrs: {
      d: "M245.76 173.548V286.72h757.76V173.548H245.76z m0 414.986h757.76V475.34H245.76v113.193z m0 301.814h757.76V777.175H245.76v113.173zM20.48 286.72h102.4V173.548H20.48V286.72z m0 307.2h102.4V480.748H20.48V593.92z m0 286.72h102.4V767.468H20.48V880.64z",
      "p-id": "10436",
      fill: "#ffffff"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: "M245.76 173.548V286.72h757.76V173.548H245.76z m0 414.986h757.76V475.34H245.76v113.193z m0 301.814h757.76V777.175H245.76v113.173zM20.48 286.72h102.4V173.548H20.48V286.72z m0 307.2h102.4V480.748H20.48V593.92z m0 286.72h102.4V767.468H20.48V880.64z",
      "p-id": "10436",
      fill: "#ffffff"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: "M245.76 173.548V286.72h757.76V173.548H245.76z m0 414.986h757.76V475.34H245.76v113.193z m0 301.814h757.76V777.175H245.76v113.173zM20.48 286.72h102.4V173.548H20.48V286.72z m0 307.2h102.4V480.748H20.48V593.92z m0 286.72h102.4V767.468H20.48V880.64z",
      "p-id": "10436",
      fill: "#ffffff"
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };