import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { mchntProAgreementSave, mchntProAgreementUpdate } from '@/api/merchant';
import { isMoneyNumber } from '@/utils/validate_rules';
import { uploadContract } from '@/api/upload';
import { saveContract, contractList, delContract } from '@/api/teamSignRecord';
import { downloadFile } from '@/api/upload';
import { downloadAllFile } from '@/utils/util';
export default {
  props: {
    visiable: Boolean,
    projectNo: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {
    UploadMoreFile
  },
  data() {
    return {
      uploadContract,
      isEdit: false,
      loading: false,
      formLabelWidth: '120px',
      form: {
        agreementUrls: [],
        projectNo: ''
      },
      contractUrlList: [],
      list: [],
      extendData: {
        id: this.formData.id
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '合同管理';
    }
  },
  methods: {
    opened() {
      // 调用详情接口
      this.extendData.id = this.formData.id;
      this.contractListAPI();
      console.log('this.formData: ', this.formData);
      console.log('this.extendData: ', this.extendData);
    },
    onClose() {
      this.dialogFormVisible = false;
    },
    contractListAPI() {
      contractList({
        "id": this.formData.id
      }).then(res => {
        this.list = res.data.contractList;
      });
    },
    onSubmit() {
      console.log('-------', this.contractUrlList);
      if (!this.contractUrlList.length) {
        this.$message.error('请先上传合同文件！');
        return;
      }
      const item = this.contractUrlList[0];
      saveContract({
        "contractName": item.name,
        "contractUrl": item.response.data.saveKey,
        "id": this.formData.id,
        "remark": ""
      }).then(() => {
        this.$message.success('保存成功！');
        this.contractUrlList = [];
        this.contractListAPI();
      });
    },
    onDelete(index, row) {
      this.$confirm('是否确定删除该合同？', '温馨提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 调用删除接口
        this.delContractAPI(row);
      }).catch(() => {});
    },
    delContractAPI(row) {
      delContract({
        "id": row.id
      }).then(() => {
        this.contractListAPI();
      });
    },
    downloadAgreement(index, row) {
      // todo:下载协议 contractUrl
      downloadFile(row.contractUrl).then(response => {
        downloadAllFile(response.data, row.contractName);
      });
    }
  }
};