var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "修改费率",
      visible: _vm.myDialogFormVisible,
      width: "85%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "12rem",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：费率及税率均为大于等于0且小于1的数值，如0.5%即输入 0.005)")])]), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.saveForm.taxName) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "标准充值费率：",
      prop: "rechargeRate"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.rechargeRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeRate", $$v);
      },
      expression: "saveForm.rechargeRate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "阶梯累计类型：",
      prop: "rateType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择阶梯累计类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.rateType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rateType", $$v);
      },
      expression: "saveForm.rateType"
    }
  }, _vm._l(_vm.rateTypeStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _vm.rateInfoList !== undefined && _vm.saveForm.rateType !== 0 ? _c("free-view", {
    ref: "freeView",
    attrs: {
      list: _vm.rateInfoList,
      text: _vm.freeText,
      name: _vm.freeName
    },
    on: {
      doSubmit: _vm.doSubmit
    }
  }) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateBtn
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };