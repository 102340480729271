var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "结算周期：",
      prop: "dateTime"
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM"
    },
    model: {
      value: _vm.searchForm.dateTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "dateTime", $$v);
      },
      expression: "searchForm.dateTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]) : _vm._e(), _vm._v(" "), _vm.hasImportPermission ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-upload2"
    },
    on: {
      click: _vm.showImportDialog
    }
  }, [_vm._v("导入收益")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("tax-month-charts", {
    ref: "chart",
    attrs: {
      searchForm: _vm.currentSearch
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "total_label",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm._v("查询汇总： \n        服务费总金额(元)： "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[0])))]), _vm._v("；\n        服务商分润总金额(元)： "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[1])))]), _vm._v("；\n        劳务公司收益总金额(元)： "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[2])))]), _vm._v("。\n    ")], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "statMonth",
      label: "结算周期",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "totalAmount",
      label: "服务费总额(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "劳务公司费率",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n          " + _vm._s((scope.row.taxFeeRate * 100).toFixed(2)) + "%\n        ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "costAmount",
      label: "税费成本(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "payAgent",
      label: "服务商分润(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "payService",
      label: "技术服务费(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "profitAmount",
      label: "劳务公司收益(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "min-width": "120",
      "show-overflow-tooltip": ""
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize,
      autoScroll: false
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _vm._v(" "), _c("month-import-file", {
    attrs: {
      dialogVisible: _vm.isShowDialog,
      templateUrl: _vm.templateUrl,
      templateName: "劳务公司月收益模板下载",
      saveFile: "/stat/statTaxsourceIncome/upload",
      titleName: "劳务公司月收益导入"
    },
    on: {
      changeDialogVisible: _vm.changeDialogVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };