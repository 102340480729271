import { mapGetters } from 'vuex';
import { rechargeLedgerReq } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import { RECHARGE_DETAIL_ID } from '@/utils/idName';
export default {
  name: 'account-recharge-detail',
  components: {
    ItemTitle,
    DetailColumn
  },
  data() {
    return {
      infoForm: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    accountStatus: function () {
      if (this.infoForm != null) {
        return this.$formatType('accountStatus', this.infoForm.status);
      }
      return '';
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const bussincessNo = sessionStorage.getItem(RECHARGE_DETAIL_ID);
      rechargeLedgerReq({
        bussincessNo: bussincessNo
      }).then(response => {
        console.log(response.data);
        this.infoForm = response.data;
      });
    }
  }
};