import { render, staticRenderFns } from "./saveBase.vue?vue&type=template&id=78f47aeb&scoped=true"
import script from "./saveBase.vue?vue&type=script&lang=js"
export * from "./saveBase.vue?vue&type=script&lang=js"
import style0 from "./saveBase.vue?vue&type=style&index=0&id=78f47aeb&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f47aeb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78f47aeb')) {
      api.createRecord('78f47aeb', component.options)
    } else {
      api.reload('78f47aeb', component.options)
    }
    module.hot.accept("./saveBase.vue?vue&type=template&id=78f47aeb&scoped=true", function () {
      api.rerender('78f47aeb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/agent/components/save/saveBase.vue"
export default component.exports