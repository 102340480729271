import { mapGetters } from 'vuex';
import { getAddressListReq, deleteAddressReq, updateAddressInfoReq } from '@/api/invoice';
import { getAcountInfo } from '@/utils/auth';
import UpdateAddress from '../components/updateAddress';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'invoice-address',
  components: {
    ItemTitle,
    UpdateAddress
  },
  data() {
    return {
      addressList: [],
      hasAddPermission: false,
      hasUpdatePermission: false,
      hasDeletePermission: false,
      hasSetDefaultPermission: false,
      isAddrVisible: false,
      curEditAddress: null
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    isFiveAddr: function () {
      if (Array.isArray(this.addressList) && this.addressList.length >= 5) {
        return true;
      }
      return false;
    }
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasAddPermission = map.has('add');
      this.hasUpdatePermission = map.has('update'); //invoiceApplyEditAddress
      this.hasDeletePermission = map.has('delete');
      this.hasSetDefaultPermission = map.has('setdefault');
    } else {
      this.hasAddPermission = false;
      this.hasUpdatePermission = false;
      this.hasDeletePermission = false;
      this.hadSetDefaultPermission = false;
    }
    this.getQuery();
  },
  methods: {
    // 新增地址
    addAddress() {
      this.curEditAddress = null;
      this.isAddrVisible = true;
    },
    // 设为默认地址
    setAddrRow(index, object) {
      this.updateAddress(object);
    },
    // 编辑地址
    editAddrRow(index, object) {
      this.curEditAddress = {
        ...object
      };
      this.isAddrVisible = true;
    },
    // 删除地址
    deleteAddrRow(index, object) {
      this.$confirm('地址删除后需重新添加，确认删除该地址么？', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.deleteAddress(object.id);
      });
    },
    // 刷新
    updated() {
      this.getQuery();
    },
    getQuery() {
      this.listLoading = true;
      const user = getAcountInfo();
      getAddressListReq({
        merchantNo: user.partyCode,
        pageNo: 1,
        pageSize: 10
      }).then(response => {
        this.addressList = response.data;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    deleteAddress(id) {
      deleteAddressReq({
        id
      }).then(response => {
        this.getQuery();
      });
    },
    updateAddress(addr) {
      const param = {
        ...addr,
        isDefault: 1
      };
      updateAddressInfoReq(param).then(() => {
        this.getQuery();
      });
    }
  }
};