import Pagination from '../../../../components/pagination/index';
export default {
  name: 'transactionDynamics',
  components: {
    Pagination
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      myList: []
    };
  },
  props: {
    list: {
      required: true,
      default: () => {
        return [];
      }
    }
  },
  created() {
    // this.myList = this.list.slice(0, 10)
    this.freshData(false);
    this.total = this.list.length;
  },
  watch: {
    list(val) {
      this.myList = this.list.slice(0, 10);
      this.total = this.list.length;
    }
  },
  methods: {
    gotoLastTime() {
      this.$router.push({
        path: '/dataReport/lastTime'
      });
    },
    pageChanged() {
      this.list.slice(this.pageNo, (this.pageNo + 1) * this.pageSize);
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      const start = (val - 1) * 10;
      const end = start + 10;
      this.myList = this.list.slice(start, end);
    },
    freshData(isWhile) {
      if (isWhile) {
        if (this.pageNo > 4) {
          this.pageNo = 1;
        } else {
          this.pageNo = this.pageNo + 1;
        }
      }
      this.handleCurrentChange(this.pageNo);
      setTimeout(() => {
        this.freshData(true);
      }, 1000 * 5);
    }
  }
};