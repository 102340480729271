import CityCascader from '@/base/ui/cascader/cityCascader';
import { updateBankCard } from '@/api/agent';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { isMobile } from '@/utils/validate_rules';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      bankType: this.$formatTypeArray('bankType'),
      rules: {
        accountNo: [{
          required: true,
          message: '请输入结算银行账户',
          trigger: 'blur'
        }, {
          min: 6,
          max: 24,
          message: '长度在 6 到 24 个字符',
          trigger: 'blur'
        }],
        cityCode: [{
          required: true,
          message: '请选择开户省市',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入开户银行名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        custName: [{
          required: true,
          message: '请输入账户名称(持卡人姓名)',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '小于20个字符',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入预留手机号码',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    baseForm: {
      default: ''
    },
    saveForm: {
      accountNo: '',
      bankName: '',
      belong: '',
      cityCode: '',
      custName: '',
      mobile: '',
      provinceCode: ''
    }
  },
  methods: {
    addSubmit() {
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      this.saveForm.belong = this.baseForm.agentNo;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateBankCard(this.saveForm).then(response => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city;
      }
    }
  }
};