var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myViewDialogFormVisible,
      center: "",
      width: "60%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myViewDialogFormVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "人员签约信息"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    staticClass: "save-base",
    attrs: {
      "label-width": "100px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple"
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名",
      prop: "empName"
    }
  }, [_vm._v(_vm._s(_vm.empName))])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple-light"
  }, [_c("el-form-item", {
    attrs: {
      label: "人员编号",
      prop: "empNo"
    }
  }, [_vm._v(_vm._s(_vm.empNo))])], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple"
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证号",
      prop: "idcard"
    }
  }, [_vm._v(_vm._s(_vm.idcard))])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple-light"
  }, [_c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "phone"
    }
  }, [_vm._v(_vm._s(_vm.phone))])], 1)])], 1)], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.signInfoList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      width: "150",
      label: "劳务公司编号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      "min-width": "150",
      label: "劳务公司名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolNo",
      width: "140",
      label: "协议编号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolStart",
      label: "开始日期",
      formatter: _vm.dateFormat,
      width: "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolEnd",
      label: "终止日期",
      formatter: _vm.dateFormat,
      width: "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      width: "120",
      label: "签约状态",
      formatter: _vm.formatEmpSignStatus
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };