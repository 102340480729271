var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称：",
      prop: "merchantName"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.merchantName) + "（" + _vm._s(_vm.saveForm.merchantNo) + "）")])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码：",
      prop: "licenseNo"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.licenseNo))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名：",
      prop: "legalPerson"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.legalPerson))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件号码：",
      prop: "legalPersonIdcard"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.legalPersonIdcard))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名：",
      prop: "contacts"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contacts))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机：",
      prop: "contactMobile"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contactMobile))])])], 1)], 1), _vm._v(" "), _vm.gjHelperSupport === 1 ? [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "渠道来源"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.gjAgentInfo.oemName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人手机号"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.gjAgentInfo.gjUserPhone) + "\n          ")])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人名称"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.gjAgentInfo.gjUserName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人身份"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.gjAgentInfo.gjUserRole) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属服务商"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.gjAgentInfo.gjAgentName) + "\n        ")])], 1)], 1)] : _vm._e(), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型：",
      prop: "invoiceType"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatMchantInvoiceType(_vm.saveForm.invoiceType)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区：",
      prop: "cityCode"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatCity()))])])], 1)], 1), _vm._v(" "), _vm.partyType !== 3 ? _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属客户经理：",
      prop: "salesmanNo"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.saveForm.salesmanName) + "(" + _vm._s(_vm.saveForm.salesmanPhone) + ")\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm.gjHelperSupport !== 1 ? _c("el-form-item", {
    attrs: {
      label: "所属服务商：",
      prop: "agentNo"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.saveForm.agentName) + "(" + _vm._s(_vm.saveForm.agentNo) + ")\n        ")]) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "交易额是否参与业绩奖励："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$formatType("isTrueFalse", _vm.saveForm.rewardFlag)) + "\n      ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "纳税人性质：",
      prop: "repayNotifyUrl"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatTaxpayerTypeStatus(_vm.saveForm.taxpayerType)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "复核手机号码：",
      prop: "reviewMobile"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.reviewMobile))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业联系地址：",
      prop: "address"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.address))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_vm.partyCode === "SYSTEM" ? _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否启用任务"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$formatType("isTrueFalse", _vm.saveForm.jobSwitch)) + "\n      ")])], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.jobSwitch === 1 && _vm.partyType !== 1 ? _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务库开关"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$formatType("switchStatus", _vm.saveForm.taskLibSwitch)) + "\n      ")])], 1) : _vm._e()], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照：",
      prop: "licensePic"
    }
  }, [_vm.saveForm.licensePicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.licensePicShow,
      "preview-src-list": [_vm.saveForm.licensePicShow]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证：",
      prop: "legalPersonIdcardpic"
    }
  }, [_vm.saveForm.legalPersonIdcardpicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.legalPersonIdcardpicShow,
      "preview-src-list": [_vm.saveForm.legalPersonIdcardpicShow]
    }
  }) : _vm._e()], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };