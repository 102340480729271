var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "订单信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "批次编号：",
      value: _vm.infoForm.batchNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "项目订单号：",
      value: _vm.infoForm.orderNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "项目子编号：",
      value: _vm.infoForm.merchantNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "项目名称：",
      value: _vm.infoForm.merchantName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "所属劳务公司编号：",
      value: _vm.infoForm.taxNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "劳务公司名称：",
      value: _vm.infoForm.taxName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "业务订单号：",
      value: _vm.infoForm.bussincessNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "付款通道编号：",
      value: _vm.infoForm.channelNo,
      left: false
    }
  })], 1), _vm._v(" "), _vm.infoForm.repayOrderNo ? _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "付款流水号：",
      value: _vm.infoForm.repayOrderNo,
      left: true
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "付款信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "账户名称：",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "收款账户：",
      value: _vm.infoForm.accountNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "账户类型：",
      value: _vm.accountType,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "付款金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.repayAmount),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "扣费模式：",
      value: _vm.$formatType("chargeType", _vm.infoForm.chargeType),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "付款说明：",
      value: _vm.infoForm.repayRemark,
      left: false,
      fullLine: true
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "业务信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "业务类型：",
      value: "代付",
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "业务状态：",
      value: _vm.businessStatus,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "创建时间：",
      value: _vm.infoForm.createTime,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "完成时间：",
      value: _vm.infoForm.completeTime,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_vm.infoForm.repayStatus === 3 ? _c("detail-column", {
    attrs: {
      title: "失败描述：",
      value: _vm.infoForm.responseMessage,
      left: true
    }
  }) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };