export default {
  name: 'totalTransaction',
  data() {
    return {
      ge: '',
      shi: '',
      bai: '',
      qian: '',
      wan: '',
      shiWan: '',
      baiWan: '',
      qianWan: '',
      yi: '',
      length: '',
      myMoney: ''
    };
  },
  props: {
    totalMoney: {
      required: true
    }
  },
  created() {
    this.formatter(this.totalMoney);
  },
  watch: {
    totalMoney(val) {
      this.formatter(val);
    }
  },
  methods: {
    formatter(val) {
      const total = Math.round(val);
      this.myMoney = total + '';
      this.length = this.myMoney.length;
    }
  }
};