import { setPwd } from "@/api/safe";
import { isValidPassword } from "@/utils/validate_rules";
export default {
  data() {
    return {
      passwordType: "password",
      editFormRules: {
        password: [{
          required: true,
          message: "请输入原密码",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        }, {
          validator: isValidPassword,
          trigger: "blur",
          message: "输入错误，密码需包含大写字母、小写字母、数字和特殊符号至少两种，长度6~16位"
        }],
        repassword: [{
          required: true,
          message: "请重复输入新密码",
          trigger: "blur"
        }, {
          validator: isValidPassword,
          trigger: "blur",
          message: "输入错误，密码需包含大写字母、小写字母、数字和特殊符号至少两种，长度6~16位"
        }]
      },
      // 编辑界面数据
      editForm: {
        password: "",
        newPassword: "",
        repassword: ""
      }
    };
  },
  mounted() {
    this.editForm.password = "";
  },
  methods: {
    // 编辑
    updateData() {
      if (this.editForm.newPassword !== this.editForm.repassword) {
        this.$message({
          message: "新密码与重复密码不一致",
          type: "error"
        });
        return;
      }
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            setPwd(this.editForm).then(response => {
              this.$message({
                message: "操作成功",
                type: "success"
              });
              this.$refs["editForm"].resetFields();
              this.$store.dispatch("LogOut").then(() => {
                this.$router.replace("/login");
              });
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    resetFields() {
      this.editForm = {
        password: "",
        newPassword: "",
        repassword: ""
      };
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};