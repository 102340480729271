import UpdateBase from './components/update/updateBase';
import UpdateBankCard from './components/update/updateBankCard';
import { info } from '@/api/agent';
import { AGENT_UPDATE_ID } from '@/utils/idName';
export default {
  components: {
    UpdateBase,
    UpdateBankCard
  },
  name: 'updateAgent',
  data() {
    return {
      active: 0,
      message: 'first',
      id: sessionStorage.getItem(AGENT_UPDATE_ID),
      bankCard: {},
      rateConfigList: [],
      bean: {}
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.freshData();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      const id = sessionStorage.getItem(AGENT_UPDATE_ID);
      info(id).then(response => {
        that.bean = response.data;
        that.bankCard = response.data.bankCard;
        that.rateConfigList = response.data.rateConfigList;
        if (that.bankCard === undefined || that.bankCard === null) {
          that.bankCard = {};
        }
      });
    }
  }
};