import ItemTitle from '../../../../base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { isValidQuota } from '../../../../utils/validate_rules';
export default {
  name: 'saveProductByBjb',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxSourceList: [],
      taxStatusType: this.$formatTypeArray('taxStatusType'),
      rechargeAutoStatus: this.$formatTypeArray('isSupport'),
      rules: {
        appId: [{
          required: true,
          message: '请输入代付商户编号',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '请输入正确的代付商户编号',
          trigger: 'blur'
        }],
        appKey: [{
          required: true,
          message: '请输入代付商户签名秘钥',
          trigger: 'blur'
        }],
        quotaMax: [{
          required: true,
          message: '请输入通道单日申请限额',
          trigger: 'blur'
        }, {
          validator: isValidQuota,
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入代付商户SM2私钥',
          trigger: 'blur'
        }],
        publicKey: [{
          required: true,
          message: '请输入代付平台SM2公钥',
          trigger: 'blur'
        }],
        ncPreposeIp: [{
          required: true,
          message: '请输入银企直联前置IP',
          trigger: 'blur'
        }, {
          min: 8,
          max: 50,
          message: '请输入正确的前置IP',
          trigger: 'blur'
        }],
        ncUserPwd: [{
          required: true,
          message: '请输入银企直联登录密码',
          trigger: 'blur'
        }],
        ncUserId: [{
          required: true,
          message: '请输入银企直联登录用户名',
          trigger: 'blur'
        }],
        rechargeAuto: [{
          required: true,
          message: '请选择是否支持自动充值',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择通道状态',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    saveForm: {
      accountNo: '',
      appId: '',
      appKey: '',
      bankName: '',
      channelName: '',
      channelNo: '',
      channelType: '',
      custName: '',
      ncPreposeIp: '',
      ncUserId: '',
      ncUserPwd: '',
      privateKey: '',
      publicKey: '',
      quotaMax: '',
      rechargeAuto: '',
      status: '',
      taxNo: '',
      singleMax: '',
      singleMin: ''
    }
  },
  created() {
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    checkSaveForm() {
      const {
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        quotaMax,
        appKey,
        privateKey,
        publicKey,
        ncUserId,
        ncUserPwd,
        ncPreposeIp,
        rechargeAuto,
        status,
        singleMax,
        singleMin
      } = this.saveForm; // 防止与建行或者支付宝通道数据串了

      const form = {
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        quotaMax,
        appKey,
        privateKey,
        publicKey,
        ncUserId,
        ncUserPwd,
        ncPreposeIp,
        rechargeAuto,
        status,
        singleMin,
        singleMax
      };
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$emit('addSubmit', form, true);
        } else {
          this.$emit('addSubmit', form, false);
        }
      });
    }
  }
};