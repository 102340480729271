var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "date-report-view"
  }, [_c("div", {
    staticClass: "index-top-view"
  }, [_c("date-view"), _vm._v(" "), _c("total-transaction", {
    attrs: {
      totalMoney: _vm.info.tradeAmountMonth
    }
  })], 1), _vm._v(" "), _c("total-data", {
    attrs: {
      info: _vm.info
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "index-top-center"
  }, [_c("transaction-histogram"), _vm._v(" "), _c("transaction-dynamics", {
    attrs: {
      list: _vm.info.latelyRechargeList
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "index-top-bottom"
  }, [_c("merchant-rate", {
    attrs: {
      list: _vm.info.merchantGradeList
    }
  }), _vm._v(" "), _c("partner-view", {
    attrs: {
      list: _vm.info.agentRankList
    }
  }), _vm._v(" "), _c("merchant-view", {
    attrs: {
      list: _vm.info.merchantRankList
    }
  }), _vm._v(" "), _c("quick-entry")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };