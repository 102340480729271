export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hasSubMenu(item) {
      const children = item.children;
      if (item.type === 0 && children.length === 0) {
        return false;
      }
      const hiddenChildren = children.filter(one => one.hidden);
      if (hiddenChildren.length === children.length) {
        return false;
      }
      const buttonChildren = children.filter(one => one.type === 2);
      if (buttonChildren.length === children.length) {
        return false;
      }
      if (buttonChildren.length + hiddenChildren.length === children.length) {
        return false;
      }
      return true;
    },
    findMatchMenu(item) {
      let obj = item.meta.title ? item.meta : {};
      if (!obj.title && item.children && item.children.length > 0) {
        const notButtonChildren = item.children.filter(one => {
          return one.type !== 2 && one.meta;
        });
        if (notButtonChildren.length > 0) {
          obj = notButtonChildren[0].meta;
        }
      }
      return obj;
    },
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return !item.hidden;
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    checkNest(item) {
      let data = false;
      if (item.children && item.children.length > 0) {
        const index = item.children.find(one => {
          const hidden = !one.hidden;
          const button = one.type && one.type !== 2;
          return !hidden && !button;
        });
        data = index > -1;
      }
      return data;
    }
  },
  created() {
    for (const o in this.routes) {
      const obj = this.routes[o];
      if (obj.path.indexOf('myiframe') >= 0) {
        obj.children[0].path = 'urlPath?src=https://www.baidu.com';
      }
    }
  }
};