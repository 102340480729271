var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "提现信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "订单号：",
      value: _vm.infoForm.bussincessNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "订单时间：",
      value: _vm.infoForm.withdrawTime,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "客户编号：",
      value: _vm.infoForm.agentNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "客户名称：",
      value: _vm.infoForm.agentName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收款户名：",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开户银行：",
      value: _vm.infoForm.bankName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收款账户：",
      value: _vm.infoForm.accountNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "账户类型：",
      value: _vm.accountType,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "提现金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.withdrawAmount),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "状　　态：",
      value: _vm.status,
      left: false
    }
  })], 1), _vm._v(" "), !_vm.isCheck ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "detail-column-left-col"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("回单信息：")]), _vm._v(" "), _c("div", {
    staticClass: "detail-column-value"
  }, [_c("el-link", {
    attrs: {
      href: _vm.infoForm.receiptUrlShow,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v("回单信息")])], 1), _vm._v(" "), _vm._t("default")], 2), _vm._v(" "), _c("div", {
    staticClass: "detail-column-right-col"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("发票信息：")]), _vm._v(" "), _c("div", {
    staticClass: "detail-column-value"
  }, [_c("el-link", {
    attrs: {
      href: _vm.infoForm.invoiceUrlShow,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v("发票信息")])], 1), _vm._v(" "), _vm._t("default")], 2)]) : _vm._e(), _vm._v(" "), !_vm.isCheck ? _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "审核意见：",
      value: _vm.infoForm.withdrawRemark,
      "full-line": ""
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), !_vm.isCheck ? _c("agent-profit-detail") : _vm._e(), _vm._v(" "), _vm.infoForm.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      data: _vm.infoForm.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isCheck ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "审核意见"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "reviewForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.reviewForm,
      rules: _vm.rules,
      inline: "",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "withdrawRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入审核意见，最多输入50个字",
      maxlength: "50",
      "show-word-limit": "",
      autosize: {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.reviewForm.withdrawRemark,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "withdrawRemark", $$v);
      },
      expression: "reviewForm.withdrawRemark"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "operation"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.pass
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "#E7AB29",
      color: "white"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("暂不审核")])], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };