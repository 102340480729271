var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base border-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名：",
      prop: "merchantName"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.info.empName) + "\n      ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证号：",
      prop: "contacts"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.info.idcard) + "\n      ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证正面：",
      prop: "idcardFrontUrl"
    }
  }, [_vm.info.idcardFrontShow === "" ? _c("upload-image", {
    on: {
      imageSuccess: _vm.handleIdCardFrontSuccess
    }
  }) : _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardFrontShow,
      "preview-src-list": [_vm.info.idcardFrontShow]
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证反面：",
      prop: "idcardBackUrl"
    }
  }, [_vm.info.idcardBackShow === "" ? _c("upload-image", {
    on: {
      imageSuccess: _vm.handleIdCardBackSuccess
    }
  }) : _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardBackShow,
      "preview-src-list": [_vm.info.idcardBackShow]
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "半身照：",
      prop: "halfBodyUrl"
    }
  }, [_vm.info.halfBodyUrlShow === "" ? _c("upload-image", {
    on: {
      imageSuccess: _vm.handleHalfBodySuccess
    }
  }) : _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.halfBodyUrlShow,
      "preview-src-list": [_vm.info.halfBodyUrlShow]
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };