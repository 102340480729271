import TotalDataItem from './totalDataItem';
export default {
  name: 'totalData',
  components: {
    TotalDataItem
  },
  data() {
    return {
      merchantTotal: 0,
      tradeAmountTotal: 0,
      addMerchant30Day: 0,
      tradeAmountToday: 0
    };
  },
  created() {
    this.merchantTotal = this.info.merchantTotal;
    this.addMerchant30Day = this.info.addMerchant30Day;
    this.tradeAmountToday = this.info.tradeAmountToday;
    this.tradeAmountTotal = Math.round(this.info.tradeAmountTotal / 10000);
  },
  watch: {
    info(val) {
      this.merchantTotal = val.merchantTotal;
      this.addMerchant30Day = val.addMerchant30Day;
      this.tradeAmountToday = val.tradeAmountToday;
      this.tradeAmountTotal = Math.round(this.info.tradeAmountTotal / 10000);
    }
  },
  props: {
    info: {
      required: true
    }
  }
};