var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "姓名：",
      value: _vm.kpInfo.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "身份证号：",
      value: _vm.kpInfo.idcard,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "所属劳务公司：",
      value: _vm.kpInfo.taxName,
      left: true
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "ticketTime",
      label: "开票时间",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "totalAmount",
      label: "累计应开票金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "ticketAmount",
      label: "本次开票金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "tickets",
      label: "本次涉及发票号",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "surplusAmount",
      label: "剩余可开票总额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };