var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transaction-query-index", {
    ref: "child",
    attrs: {
      repayType: _vm.repayType
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };