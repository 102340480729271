import { mapGetters } from 'vuex';
import WarnSet from './warnSet';
import { getMerchantConfigInfo } from '../../../api/merchant';
export default {
  name: 'warnSetIndex',
  components: {
    WarnSet
  },
  data() {
    return {
      openAppInfo: false,
      updateAppInfo: false,
      resetAppInfoSign: false,
      queryLoading: true,
      isShowWarnSet: false,
      merchantInfo: null
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    this.getMerchantConfigInfo();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.openAppInfo = map.has('openAppInfo');
      this.updateAppInfo = map.has('updateAppInfo');
      this.resetAppInfoSign = map.has('resetAppInfoSign');
    }
  },
  methods: {
    getMerchantConfigInfo() {
      getMerchantConfigInfo().then(response => {
        this.queryLoading = false;
        this.merchantInfo = response.data;
        if (this.merchantInfo) {
          this.isShowWarnSet = true;
        } else {
          this.merchantInfo = {};
        }
      });
    }
  }
};