import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import Pagination from '@/components/pagination';
import { unTicketPaymentListReq, unTicketPaymentAutoListReq } from '@/api/invoice';
import { separateDate } from '@/utils/util';
import GatherDialog from './gatherDialog';
export default {
  name: 'gatherList',
  components: {
    ItemTitle,
    DbDatePicker,
    Pagination,
    GatherDialog
  },
  data() {
    return {
      searchForm: {
        accountNo: '',
        custName: '',
        orderNo: ''
      },
      createTime: [],
      listLoading: false,
      invoices: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      selectRecords: [],
      isDialogVisible: false,
      expectAmount: '',
      isAuto: false,
      firstPage: true,
      maxId: null,
      autoSum: 0,
      availableAmount: 0
    };
  },
  computed: {
    selectSum() {
      if (this.isAuto) {
        return this.autoSum;
      } else {
        return this.selectRecords.reduce((accumulator, currentValue) => accumulator + currentValue.accountAmount, 0);
      }
    },
    selectCount() {
      return this.selectRecords.length;
    }
  },
  created() {
    this.invoiceInfo = JSON.parse(sessionStorage.getItem('makeInvoice'));
    this.availableAmount = this.invoiceInfo.unticketTotal;
    this.queryForm();
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.isAuto = false;
      this.pageNo = 1;
      this.selectRecords = [];
      this.getList();
    },
    clearForm() {
      this.isAuto = false;
      this.$refs['searchForm'].resetFields();
      this.selectRecords = [];
      this.getList();
    },
    selectForm() {
      this.isDialogVisible = true;
    },
    autoSelect(amount) {
      this.isAuto = true;
      this.firstPage = true;
      this.selectRecords = [];
      this.pageNo = 1;
      this.expectAmount = amount;
      this.maxId = null;
      this.getAutoList();
    },
    selectRow(array, row) {
      if (this.isAuto) {
        const index = this.selectRecords.findIndex(id => id === row.id);
        if (index === -1) {
          this.selectRecords.push(row.id);
          this.autoSum += row.accountAmount;
        } else {
          this.selectRecords.splice(index, 1);
          this.autoSum -= row.accountAmount;
        }
      } else {
        const index = this.selectRecords.findIndex(item => item.id === row.id);
        if (index === -1) {
          this.selectRecords.push(row);
        } else {
          this.selectRecords.splice(index, 1);
        }
      }
    },
    selectAll(array) {
      if (this.isAuto) {
        for (const row of this.invoices) {
          const index = this.selectRecords.findIndex(id => id === row.id);
          if (array.length === 0 && index !== -1) {
            this.selectRecords.splice(index, 1);
            this.autoSum -= row.accountAmount;
          } else if (array.length !== 0 && index === -1) {
            this.selectRecords.push(row);
            this.autoSum += row.accountAmount;
          }
        }
      } else {
        for (const row of this.invoices) {
          const index = this.selectRecords.findIndex(item => item.id === row.id);
          if (array.length === 0 && index !== -1) {
            this.selectRecords.splice(index, 1);
          } else if (array.length !== 0 && index === -1) {
            this.selectRecords.push(row);
          }
        }
      }
    },
    nextClick() {
      if (this.selectRecords.length == 0) {
        this.$message.error('请先选择付款记录');
        return;
      }
      let records = this.selectRecords;
      if (!this.isAuto) {
        records = this.selectRecords.map(item => item.id);
      }
      this.$emit('select', records, this.selectSum);
    },
    pageChanged() {
      if (this.isAuto) {
        this.getAutoList();
      } else {
        this.getList();
      }
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.createTime);
      unTicketPaymentListReq({
        ...this.searchForm,
        beginTime,
        endTime,
        taxNo: this.invoiceInfo.taxNo,
        merchantNo: this.invoiceInfo.merchantNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.invoices = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
        this.$nextTick(() => {
          this.invoices.forEach(row => {
            const index = this.selectRecords.findIndex(item => item.id === row.id);
            if (index !== -1) {
              this.$refs.table.toggleRowSelection(row, true);
            }
          });
        });
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getAutoList() {
      this.listLoading = true;
      unTicketPaymentAutoListReq({
        expectAmount: this.expectAmount,
        maxId: this.maxId,
        taxNo: this.invoiceInfo.taxNo,
        merchantNo: this.invoiceInfo.merchantNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listLoading = false;
        this.invoices = response.data.pageUtil.list;
        this.total = response.data.totalCnt;
        this.maxId = response.data.maxId;
        if (this.firstPage) {
          this.firstPage = false;
          this.selectRecords = response.data.paymentIdList;
          this.autoSum = response.data.totalAmt;
          this.$alert(`本次已帮您匹配到${this.total}条付款记录，开票金额共计${this.$fmoneyWithYuan(this.autoSum)}。`, '温馨提示', {
            type: 'success',
            confirmButtonText: '好的',
            center: true
          });
        }
        this.$nextTick(() => {
          this.invoices.forEach(row => {
            const index = this.selectRecords.findIndex(id => id === row.id);
            if (index !== -1) {
              this.$refs.table.toggleRowSelection(row, true);
            }
          });
        });
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};