import { privateUrlShow } from '../../../api/common';
export default {
  name: 'viewIdCardDialog',
  data() {
    return {
      myDialogFormVisible: false,
      idcardFrontUrl: '',
      idcardBackUrl: '',
      halfBodyUrl: ''
    };
  },
  props: {
    dialogVisible: false,
    idcardBack: '',
    idcardFront: '',
    halfBody: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
      this.getIdcardFront();
      this.getIdcardBack();
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleDialog', val);
    },
    idcardBack(val) {
      this.getIdcardBack();
    },
    idcardFront(val) {
      this.getIdcardFront();
    },
    halfBody() {
      this.getHalfBody();
    }
  },
  methods: {
    dialogClosed() {
      this.idcardFrontUrl = '';
    },
    getIdcardFront() {
      if (this.idcardFront) {
        privateUrlShow(this.idcardFront).then(response => {
          this.idcardFrontUrl = response.data;
        });
      }
    },
    getIdcardBack() {
      if (this.idcardBack) {
        privateUrlShow(this.idcardBack).then(response => {
          this.idcardBackUrl = response.data;
        });
      }
    },
    getHalfBody() {
      if (this.halfBody) {
        privateUrlShow(this.halfBody).then(response => {
          this.halfBodyUrl = response.data;
        });
      } else {
        this.halfBodyUrl = '';
      }
    }
  }
};