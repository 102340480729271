import { mapGetters } from 'vuex';
import { getAcountInfo } from '@/utils/auth';
import Preview from '../components/preview';
import ItemTitle from '@/base/ui/title/itemTitle';
import { getTaskSelect } from '../../../api/task';
import { showlist } from '@/api/taxsource';
import { merchantShowList } from '@/api/merchant';
import { mchntProjectShowlist, taxChannelSelectList } from '@/api/taxMerchant';

// const template_url = 'static/template/EmpImportTemplate.xlsx'
const template_url = 'https://gyoss.jianjianggong.cn/common/template/批量付款导入模板.xlsx';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'transaction-apply',
  components: {
    ItemTitle,
    Preview
  },
  data() {
    return {
      addForm: {
        taxNo: '',
        merchantNo: '',
        projectNo: '',
        repayDesc: '',
        channelId: '',
        bankFileList: []
      },
      fileList: [],
      uploadBankFile: BASE_URL + '/trade/repayment/uploadBankFlow',
      uploadApplyFile: BASE_URL + '/trade/repayment/upload',
      downloadUrl: template_url,
      responseBankData: null,
      responseData: null,
      taxSources: [],
      merchantList: [],
      projectList: [],
      channelList: [],
      isDialogVisible: false,
      transactionBatch: '',
      idCardRepeatDialogVisible: false,
      repeatDesc: '',
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'change'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属甲方单位',
          trigger: 'blur'
        }],
        projectNo: [{
          required: true,
          message: '请选择所属项目',
          trigger: 'blur'
        }],
        channelId: [{
          required: true,
          message: '请选择付款通道',
          trigger: 'blur'
        }]
        // bankFileList: [{ required: true, message: '请上传银行流水', trigger: ['blur', 'change'] }],
      },
      unAuthCnt: 0 // 未认证/未签约/无半身照/无身份证情况数量
    };
  },
  props: {
    repayType: {
      default: () => {
        return 0; // 付款类型：0-临时经营个人付款，1-个体工商户付款
      }
    }
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    exData() {
      return {
        taxNo: this.addForm.taxNo,
        merchantNo: this.addForm.merchantNo,
        projectNo: this.addForm.projectNo,
        repayDesc: this.addForm.repayDesc,
        channelId: this.addForm.channelId
      };
    },
    exBankData() {
      return {
        taxNo: this.addForm.taxNo,
        merchantNo: this.addForm.merchantNo,
        projectNo: this.addForm.projectNo,
        repayDesc: this.addForm.repayDesc,
        channelId: this.addForm.channelId
      };
    },
    channelType() {
      const res = this.channelList.find(item => {
        return item.id === this.addForm.channelId;
      }) || {};
      console.log('res: ', res);
      return res.channelType || '';
    },
    hasBanckFlow() {
      return ['PAY_SPECIAL', 'PAY_SELF'].includes(this.channelType);
    }
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    this.transactionBatch = map.get('transactionBatch');
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatProject(project) {
      return `${project.projectName}(${project.projectNo})`;
    },
    concatChannel(channel) {
      return `${channel.channelName}(${channel.channelNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    getMerchantList() {
      this.addForm.merchantNo = null;
      this.merchantList = [];
      if (this.addForm.taxNo !== '') {
        const params = {
          taxNo: this.addForm.taxNo
        };
        merchantShowList(params).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    merchantSelectChange(value) {
      console.log(11);
      if (value) {
        this.getProjectList();
        this.taxChannelSelectListAPI();
      }
      this.addForm.projectNo = '';
      this.addForm.channelId = '';
      this.projectList = [];
    },
    async getProjectList() {
      const params = {
        merchantNo: this.addForm.merchantNo,
        taxNo: this.addForm.taxNo
      };
      let res = await mchntProjectShowlist(params);
      this.projectList = res.data;
    },
    async taxChannelSelectListAPI() {
      const params = {
        // merchantNo: this.addForm.merchantNo,
        taxNo: this.addForm.taxNo
      };
      let res = await taxChannelSelectList(params);
      this.channelList = res.data;
    },
    changedPath() {
      const components = this.transactionBatch.split('/');
      if (components.length >= 2) {
        components.splice(components.length - 2, 1);
        return components.join('/');
      }
      return this.transactionBatch;
    },
    submitBankListUpload() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.$refs.bankUpload.submit();
        } else {
          return false;
        }
      });
    },
    async beforeBankUpload(file) {
      let able = await this.$refs['addForm'].validate();
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt2M && able;
    },
    handleBankDataList(response, file, fileList) {
      console.log('response: ', response);
      if (response != null && response.code === 'BASE.000') {
        this.responseBankData = response.data;
        this.$message.success('上传成功');
      } else {
        if (response != null) {
          this.$message.error(response.errMsg);
        } else {
          this.$message.error('上传失败');
        }
      }
    },
    handleBankRemove() {
      this.addForm.bankFileList = [];
      this.responseBankData = null;
    },
    submitUpload() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          if (!this.responseBankData && this.hasBanckFlow) {
            this.$message.error('请先上传银行流水');
            return;
          }
          this.$refs.upload.submit();
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      const xls = 'application/vnd.ms-excel';
      const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = file.type;
      const fileName = file.name;
      var isExcel = false;
      if (fileType != null && fileType !== '') {
        isExcel = fileType === xls || fileType === xlsx;
      } else {
        const reg = /^.*\.(xls|xlsx)$/; // 取不到文件type时，文件后缀匹配
        isExcel = reg.test(fileName);
      }
      if (!isExcel) {
        this.$message.error('上传文件只能是 .xls/.xlsx 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt2M && isExcel;
    },
    handleDataList(response, file, fileList) {
      if (response != null && response.code === 'BASE.000') {
        this.responseData = response.data;
        // 添加连个参数，保存的时候要用
        this.responseData.attachName = this.responseBankData && this.responseBankData.saveName || '';
        this.responseData.attachUrl = this.responseBankData && this.responseBankData.saveKey || '';
        this.unAuthCnt = response.data.unAuthCnt;
        // debugger
        if (response.data.repeatDesc) {
          this.idCardRepeatDialogVisible = true;
          this.repeatDesc = response.data.repeatDesc;
        } else {
          this.isDialogVisible = true;
        }
      } else {
        if (response != null) {
          this.$message.error(response.errMsg);
        } else {
          this.$message.error('上传失败');
        }
        this.handleRemove();
      }
    },
    handleRemove() {
      this.fileList = [];
      this.responseData = null;
    },
    uploadSuccess() {
      this.clearData();
      this.$router.push({
        path: this.transactionBatch
      });
    },
    uploadError() {
      this.clearData();
    },
    uploadCancel() {
      this.clearData();
    },
    clearData() {
      this.$refs['addForm'].resetFields();
      this.fileList = [];
      this.responseData = null;
      this.responseBankData = null;
      this.repeatDesc = '';
    },
    idCardDialogCancel() {
      this.idCardRepeatDialogVisible = false;
      this.clearData();
    },
    showUploadDialog() {
      this.idCardRepeatDialogVisible = false;
      this.isDialogVisible = true;
    }
  }
};