var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值录单"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "infoForm",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      model: _vm.infoForm,
      inline: "",
      size: "mini",
      "label-position": "right",
      "label-width": "130px",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择所属劳务公司",
      clearable: "",
      filterable: ""
    },
    on: {
      change: function ($event) {
        return _vm.getMerchantList();
      }
    },
    model: {
      value: _vm.infoForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "taxSource", $$v);
      },
      expression: "infoForm.taxSource"
    }
  }, _vm._l(_vm.taxSourceList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属甲方单位",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择所属甲方单位",
      clearable: "",
      filterable: "",
      disabled: !_vm.infoForm.taxSource || _vm.merchantList.length === 0
    },
    on: {
      change: _vm.merchantSelectChange
    },
    model: {
      value: _vm.infoForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "merchantNo", $$v);
      },
      expression: "infoForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属项目",
      prop: "projectNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择所属项目",
      clearable: "",
      filterable: "",
      disabled: !_vm.infoForm.merchantNo || _vm.projectList.length === 0
    },
    model: {
      value: _vm.infoForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "projectNo", $$v);
      },
      expression: "infoForm.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: _vm.concatProject(item),
        value: item.projectNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款账户名称",
      prop: "userName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入账户名称",
      clearable: ""
    },
    model: {
      value: _vm.infoForm.userName,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "userName", $$v);
      },
      expression: "infoForm.userName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行账户",
      prop: "bankAccount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入银行账户",
      clearable: ""
    },
    model: {
      value: _vm.infoForm.bankAccount,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "bankAccount", $$v);
      },
      expression: "infoForm.bankAccount"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行名称",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入银行名称",
      clearable: ""
    },
    model: {
      value: _vm.infoForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "bankName", $$v);
      },
      expression: "infoForm.bankName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值金额",
      prop: "amount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入充值金额",
      clearable: ""
    },
    model: {
      value: _vm.infoForm.amount,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "amount", $$v);
      },
      expression: "infoForm.amount"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值时间",
      prop: "date",
      clearable: ""
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      "value-format": "yyyy-MM-dd",
      placeholder: "充值时间"
    },
    model: {
      value: _vm.infoForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "date", $$v);
      },
      expression: "infoForm.date"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值凭证",
      prop: "fileList"
    }
  }, [_c("upload-more-file", {
    staticStyle: {
      width: "100%",
      "margin-left": "-15px"
    },
    model: {
      value: _vm.infoForm.fileList,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "fileList", $$v);
      },
      expression: "infoForm.fileList"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入充值备注，最大输入30个字符",
      maxlength: "30",
      "show-word-limit": "",
      autosize: {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.infoForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.infoForm, "remark", $$v);
      },
      expression: "infoForm.remark"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值凭证示例"
    }
  }, [_c("el-image", {
    staticStyle: {
      width: "400px",
      height: "200px"
    },
    attrs: {
      src: "https://gyoss.jianjianggong.cn/common/examples/VoucherExample.png"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: _vm.gutter
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.span
    }
  }, [_c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "plain"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };