import ItemTitle from '@/base/ui/title/itemTitle';
import { rechargeInfo } from '@/api/transaction';
export default {
  name: 'rechargeDetail',
  components: {
    ItemTitle
  },
  data() {
    return {
      view: {}
    };
  },
  computed: {
    itemId() {
      return this.$route.query.id;
    }
  },
  created() {
    this.rechargeInfo();
  },
  methods: {
    rechargeInfo() {
      const params = {
        id: this.itemId
      };
      rechargeInfo(params).then(response => {
        this.view = response.data;
      });
    }
  }
};