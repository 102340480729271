var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "批量提交"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    staticClass: "form-table",
    attrs: {
      model: _vm.addForm,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left",
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.addForm.taxName) + "(" + _vm._s(_vm.addForm.taxNo) + ")\n        ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务名称：",
      prop: "taskNo"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.addForm.taskName) + "(" + _vm._s(_vm.addForm.taskNo) + ")\n        ")]), _vm._v(" "), _c("el-form-item", {
    staticClass: "upload",
    attrs: {
      label: "上传任务完成信息："
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadApplyFile,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      "on-success": _vm.handleDataList,
      data: _vm.exData,
      "on-change": _vm.changeFiles,
      "auto-upload": false,
      limit: 1
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_temp_label",
    attrs: {
      type: "primary",
      target: "_blank",
      download: "批量提交模版"
    },
    on: {
      click: _vm.downTaskRecord
    }
  }, [_vm._v("批量提交模版下载")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 只能上传已填写好的标准模板文件，数据记录最多不能超过3000条，且文件大小不超过10M")]), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")])], 1)], 1), _vm._v(" "), _c("preview-dialog", {
    attrs: {
      dialogFormVisible: _vm.isDialogVisible,
      info: _vm.responseData
    },
    on: {
      changeVisiblePreviewDialog: _vm.changeVisiblePreviewDialog
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("item-title", {
    attrs: {
      name: "批量提交指导"
    }
  })], 1), _vm._v(" "), _vm._m(0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      "font-size": "1.4rem"
    }
  }, [_c("div", [_vm._v("1.企业下载批量提交信息模板，并按照模板填写表格；")]), _vm._v(" "), _c("div", [_vm._v("2.点击选取文件，选择已填写完并保存的表格进行导入；")]), _vm._v(" "), _c("div", [_vm._v("3.导入后点击上传至服务器，上传完成后会弹出预览窗口，请确认预览的提交记录是否准确，点击确认即可完成批量提交操作。")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };