import { saveFile } from '@/api/upload';
import { getAcountInfo } from '@/utils/auth';
export default {
  name: 'uploadImage',
  data() {
    return {
      mySaveFile: saveFile,
      partyCode: '',
      selfSrc: ''
    };
  },
  watch: {
    imageSrc(val) {
      this.selfSrc = val;
    },
    saveFile(val) {
      // debugger
      this.mySaveFile = val;
    }
  },
  props: {
    imageSrc: {
      required: false
    },
    saveFile: {
      default: () => {
        return saveFile;
      }
    },
    bizCode: {
      type: String,
      default: ''
    }
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
  },
  mounted() {
    this.partyCode = this.bizCode || this.partyCode;
    console.log(this.saveFile);
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt2M && isJPG;
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.selfSrc = res.data.showUrl;
      } else {
        this.$message.error(res.errMsg);
      }
      this.$emit('imageSuccess', res, file);
    }
  }
};