import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 获取列表*/
export const getAgentList = params => {
  const {
    agentName,
    agentNo,
    beginTime,
    endTime,
    order,
    taxNo,
    status,
    superiorAgent,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/org/agent/list',
    method: 'post',
    data: {
      agentName,
      agentNo,
      beginTime,
      endTime,
      order,
      taxNo,
      status,
      superiorAgent,
      pageNo,
      pageSize
    }
  });
};

/**
 * 查看服务商详细信息(运营/园区)
 */
export const info = id => {
  return request({
    url: '/org/agent/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 新增服务商基本信息
 * @param params
 */
export const saveBase = params => {
  const {
    address,
    agentName,
    agentType,
    areaCode,
    cityCode,
    contactNumber,
    contacts,
    legalPerson,
    legalPersonIdcard,
    legalPersonIdcardpic,
    licenseNo,
    licensePic,
    protocolEnd,
    protocolStart,
    protocolUrl,
    provinceCode,
    recharegeRate,
    salesmanNo,
    superiorAgent,
    agentLevel
  } = params;
  return request({
    url: '/org/agent/saveBase',
    method: 'post',
    data: {
      address,
      agentName,
      agentType,
      areaCode,
      cityCode,
      contactNumber,
      contacts,
      legalPerson,
      legalPersonIdcard,
      legalPersonIdcardpic,
      licenseNo,
      licensePic,
      protocolEnd,
      protocolStart,
      protocolUrl,
      provinceCode,
      recharegeRate,
      salesmanNo,
      superiorAgent,
      agentLevel
    }
  });
};

/**
 * 查看服务商详细信息(服务商)
 * @param params
 */
export const showInfo = () => {
  return request({
    url: '/org/agent/showInfo',
    method: 'get'
  });
};

/**
 * 获取下拉列表信息
 * @param params
 */
export const showAgentlist = () => {
  return request({
    url: '/org/agent/showlist',
    method: 'get'
  });
};

/**
 * 新增或修改服务商账户信息
 * @param params
 */
export const updateBankCard = params => {
  const {
    accountNo,
    accountType,
    bankName,
    belong,
    cityCode,
    custName,
    mobile,
    provinceCode
  } = params;
  return request({
    url: '/org/agent/updateBankCard',
    method: 'post',
    data: {
      accountNo,
      accountType,
      bankName,
      belong,
      cityCode,
      custName,
      mobile,
      provinceCode
    }
  });
};

/**
 * 修改服务商基本信息
 * @param params
 */
export const updateBase = params => {
  const {
    address,
    agentName,
    agentNo,
    agentType,
    area,
    areaCode,
    city,
    cityCode,
    contactNumber,
    contacts,
    legalPerson,
    legalPersonIdcard,
    legalPersonIdcardpic,
    licenseNo,
    licensePic,
    protocolEnd,
    protocolStart,
    protocolUrl,
    province,
    provinceCode,
    recharegeRate,
    salesmanNo,
    status,
    superiorAgent,
    agentLevel
  } = params;
  return request({
    url: '/org/agent/updateBase',
    method: 'post',
    data: {
      address,
      agentName,
      agentNo,
      agentType,
      area,
      areaCode,
      city,
      cityCode,
      contactNumber,
      contacts,
      legalPerson,
      legalPersonIdcard,
      legalPersonIdcardpic,
      licenseNo,
      licensePic,
      protocolEnd,
      protocolStart,
      protocolUrl,
      province,
      provinceCode,
      recharegeRate,
      salesmanNo,
      status,
      superiorAgent,
      agentLevel
    }
  });
};

/**
 * 解冻园区*/
export const unfreeze = id => {
  return request({
    url: '/org/agent/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结园区*/
export const freeze = id => {
  return request({
    url: '/org/agent/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 查询服务商园区费率信息列表*/
export const queryAgentTaxs = params => {
  const {
    agentNo,
    taxNo
  } = params;
  return request({
    url: '/org/agent/queryAgentTaxs',
    method: 'post',
    data: {
      agentNo,
      taxNo
    }
  });
};

/**
 * 新增或修改服务商园区费率信息*/
export const updateAgentTaxs = params => {
  const {
    agentNo,
    taxNo,
    rateInfoList,
    rateType,
    rechargeRate
  } = params;
  return request({
    url: '/org/agent/updateAgentTaxs',
    method: 'post',
    data: {
      agentNo,
      taxNo,
      rateInfoList,
      rateType,
      rechargeRate
    }
  });
};
export const audit = (agentNo, status) => {
  return request({
    url: '/org/agent/audit',
    method: 'post',
    data: {
      agentNo,
      status
    }
  });
};

/**
 * 下载服务商列表
 */
export const downloadAgent = params => {
  return download({
    url: '/org/agent/download',
    data: params
  });
};

/**
 * 费率详情*/
export const getAgentProfitRuleInfo = id => {
  return request({
    url: '/profit/agentProfitRule/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 费率列表*/
export const getAgentProfitRuleList = agentNo => {
  return request({
    url: '/profit/agentProfitRule/list',
    method: 'post',
    data: {
      agentNo
    }
  });
};

/**
 * 新增费率*/
export const saveAgentProfitRule = params => {
  return request({
    url: '/profit/agentProfitRule/save',
    method: 'post',
    data: params
  });
};

/**
 * 修改费率*/
export const updateAgentProfitRule = params => {
  return request({
    url: '/profit/agentProfitRule/update',
    method: 'post',
    data: params
  });
};