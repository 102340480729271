var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-container"
  }, [_c("div", {
    staticClass: "account"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("div", [_vm._v("账户汇总信息")]), _vm._v(" "), _c("el-button", {
    staticClass: "link",
    attrs: {
      size: "mini",
      type: "text"
    },
    on: {
      click: _vm.viewDetail
    }
  }, [_vm._v("查看详情")])], 1), _vm._v(" "), _c("div", {
    staticClass: "info-detail"
  }, [_vm._v("账户名称：" + _vm._s(_vm.account.taxName))]), _vm._v(" "), _c("div", {
    staticClass: "info-detail"
  }, [_vm._v("汇总余额(元)："), _c("span", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.account.totalAmount)))])]), _vm._v(" "), _c("div", {
    staticClass: "info-detail"
  }, [_vm._v("可用余额(元)："), _c("span", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.account.availAmount)))])]), _vm._v(" "), _c("div", {
    staticClass: "info-detail"
  }, [_vm._v("冻结余额(元)："), _c("span", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.account.blockAmount)))])]), _vm._v(" "), _c("div", {
    staticClass: "rightBottom"
  }, [_vm.type == 2 && _vm.hasWithdrawPermission ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.withdraw
    }
  }, [_vm._v("立即提现")]) : _vm._e(), _vm._v(" "), _vm.type == 3 ? _c("div", {
    staticClass: "tax"
  }, [_vm._v(_vm._s(_vm.account.taxName))]) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };