var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: _vm.title
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "left",
      "label-width": "90px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_vm.manager && _vm.manager.salesmanNo ? _c("el-form-item", {
    attrs: {
      label: "编　　号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户经理姓名",
      disabled: ""
    },
    model: {
      value: _vm.addForm.no,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "no", $$v);
      },
      expression: "addForm.no"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "姓　　名",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户经理姓名",
      clearable: ""
    },
    model: {
      value: _vm.addForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "name", $$v);
      },
      expression: "addForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号",
      prop: "cardId"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户身份证号",
      clearable: ""
    },
    model: {
      value: _vm.addForm.cardId,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "cardId", $$v);
      },
      expression: "addForm.cardId"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户手机号码",
      clearable: ""
    },
    model: {
      value: _vm.addForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "phone", $$v);
      },
      expression: "addForm.phone"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };