import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import uploadResultDialog from './components/uploadResultDialog';
import { getSamplingRecordsReq, samplingRecordsDownload, deleteSamplingRecords } from '@/api/transaction';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import { getAcountInfo } from '@/utils/auth';
import { showlist } from '@/api/taxsource';
import ItemTitle from '@/base/ui/title/itemTitle';
import { BATCH_DETAIL_ID, BATCH_CHECK_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'samplingRecords',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker,
    uploadResultDialog
  },
  data() {
    return {
      searchForm: {
        subTime: [],
        inspectionTime: [],
        completeTime: []
      },
      taxSources: [],
      summary: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      records: [],
      taxName: '',
      currentSearch: {},
      uploadDialogVisible: false
    };
  },
  props: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    repayStatusOptions: function () {
      return this.$formatTypeArray('transactionBusinessStatus');
    },
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  created() {
    const user = getAcountInfo();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasImportPermission = map.has('samplingRecordsImport');
      this.hasDeletePermission = map.has('deleteSamplingRecords');
    } else {
      this.hasImportPermission = false;
      this.hasDeletePermission = false;
    }
    this.queryForm();
    this.getTaxSources();
  },
  methods: {
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      const subTime = separateDate(this.currentSearch.subTime);
      const inspectionTime = separateDate(this.currentSearch.inspectionTime);
      const completeTime = separateDate(this.currentSearch.completeTime);
      const params = {
        inspectNo: this.currentSearch.inspectNo,
        inspectBeginTime: inspectionTime.beginTime,
        inspectEndTime: inspectionTime.endTime,
        inspector: this.currentSearch.inspector,
        taxNo: this.currentSearch.taxSource,
        custName: this.currentSearch.custName,
        accountNo: this.currentSearch.accountNo,
        repayStatus: this.currentSearch.repayStatus,
        repayRemark: this.currentSearch.repayRemark,
        beginTime: subTime.beginTime,
        endTime: subTime.endTime,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime
      };
      samplingRecordsDownload(params).then(response => {
        downloadExcel(response.data, '抽检记录.xls');
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const subTime = separateDate(this.currentSearch.subTime);
      const inspectionTime = separateDate(this.currentSearch.inspectionTime);
      const completeTime = separateDate(this.currentSearch.completeTime);
      getSamplingRecordsReq({
        inspectNo: this.currentSearch.inspectNo,
        inspectBeginTime: inspectionTime.beginTime,
        inspectEndTime: inspectionTime.endTime,
        inspector: this.currentSearch.inspector,
        taxNo: this.currentSearch.taxSource,
        custName: this.currentSearch.custName,
        accountNo: this.currentSearch.accountNo,
        repayStatus: this.currentSearch.repayStatus,
        repayRemark: this.currentSearch.repayRemark,
        beginTime: subTime.beginTime,
        endTime: subTime.endTime,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.records = response.data.list;
        this.total = response.data.totalCount;
        this.summary = response.data.summary.split('#');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    changeDialogVisible(val) {
      this.uploadDialogVisible = val;
      this.getList();
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该抽检记录?', '提示', {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        deleteSamplingRecords(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    }
  }
};