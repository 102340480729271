var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "80%",
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "批量付款预览"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "statistics"
  }, [_c("div", [_vm._v("笔数：" + _vm._s(_vm.total))]), _vm._v(" "), _c("div", [_vm._v("任务编号：" + _vm._s(_vm.taskNo))]), _vm._v(" "), _c("div", [_vm._v("市场调研：" + _vm._s(_vm.taskName))])]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.repaymentList,
      "highlight-current-row": "",
      size: "mini",
      height: "100px",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "submitCount",
      label: "数量",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "提交人",
      "min-width": "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      width: "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "address",
      label: "地点",
      "min-width": "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: "内容",
      "min-width": "120",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "annexNo",
      label: "附件编号",
      "min-width": "120"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.isLoading,
      s: ""
    },
    on: {
      click: function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("确认提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      type: "danger",
      disabled: _vm.isLoading
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };