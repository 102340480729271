import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import CityCascader from '@/base/ui/cascader/cityCascader';
import { updateGthdjReq, taxGthdjListReq } from '@/api/taxsource';
import SaveLsswkpDialog from './components/saveDialog';
export default {
  name: 'taxGthdjList',
  components: {
    SaveLsswkpDialog,
    ItemTitle,
    Pagination,
    DbDatePicker,
    CityCascader
  },
  data() {
    return {
      total: 0,
      list: [],
      taxSources: [],
      listLoading: true,
      searchForm: {
        custName: '',
        idcard: '',
        taxNo: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      importVisible: false,
      deleteVisible: false,
      saveDialogVisible: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.importVisible = map.get('import');
      this.deleteVisible = map.get('delete');
    }
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      taxGthdjListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    deleteInfo(row) {
      this.$confirm('是否确认注销该人员的个体户登记？', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        updateGthdjReq({
          id: row.id
        }).then(() => {
          this.$message.success('操作成功');
          this.getList();
        });
      });
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    }
  }
};