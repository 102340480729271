import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getTransactionListReq, transactionRecordsDownload, manualPayListDownload } from '@/api/transaction';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { QUERY_DETAIL_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'transactionQueryIndex',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        customerNo: '',
        customerName: '',
        taxSource: '',
        batchNo: '',
        orderNo: '',
        businessStatus: '',
        channelType: '',
        // checkStatus: '',
        accountName: '',
        accountNo: '',
        submitDate: [],
        completeDate: [],
        repayRemark: '',
        chargeType: '',
        payType: '',
        bussincessNo: '',
        projectNo: '',
        projectName: ''
      },
      taxSources: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      transactions: [],
      hasDetailPermission: false,
      hasDownloadPermission: false,
      hasBatchSurePermission: false,
      batchSurePath: '',
      detailPath: '',
      partyType: '',
      taxName: '',
      currentSearch: {},
      visiable: false
    };
  },
  props: {
    repayType: {
      default: () => {
        return 0; // 付款类型：0-临时经营个人付款，1-个体工商户付款
      }
    }
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    businessOptions: function () {
      return this.$formatTypeArray('transactionBusinessStatus');
    }
    /*,
    checkOptions: function() {
      return this.$formatTypeArray('transactionReconStatus')
    }
    */
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else if (this.partyType === 3) {
      this.searchForm.customerNo = user.partyCode;
    }
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.hasDetailPermission = map.has('detail');
      this.hasDownloadPermission = map.has('download');
      this.hasBatchSurePermission = map.has('batchSure');
      this.detailPath = map.get('detail');
      this.batchSurePath = map.get('batchSure');
    } else {
      this.hasDetailPermission = false;
      this.hasDownloadPermission = false;
      this.hasBatchSurePermission = false;
      this.detailPath = '';
      this.batchSurePath = '';
    }
    if (this.partyType !== 1) {
      this.getTaxSources();
    }
    this.queryForm();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      const createTime = separateDate(this.currentSearch.submitDate);
      const completeTime = separateDate(this.currentSearch.completeDate);
      const params = {
        merchantNo: this.currentSearch.customerNo,
        merchantName: this.currentSearch.customerName,
        taxNo: this.currentSearch.taxSource,
        batchNo: this.currentSearch.batchNo,
        orderNo: this.currentSearch.orderNo,
        repayStatus: this.currentSearch.businessStatus,
        beginTime: createTime.beginTime,
        endTime: createTime.endTime,
        custName: this.currentSearch.accountName,
        accountNo: this.currentSearch.accountNo,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        chargeType: this.currentSearch.chargeType,
        channelType: this.currentSearch.channelType,
        repayRemark: this.currentSearch.repayRemark,
        // repayType: this.repayType,
        projectName: this.currentSearch.projectName,
        projectNo: this.currentSearch.projectNo,
        invoiceStatus: this.currentSearch.invoiceStatus
        // reconStatus: this.searchForm.checkStatus
      };
      transactionRecordsDownload(params).then(response => {
        downloadExcel(response.data, 'repayment-record.xls');
      });
    },
    downloadBatchForm() {
      const createTime = separateDate(this.currentSearch.submitDate);
      const completeTime = separateDate(this.currentSearch.completeDate);
      const params = {
        merchantNo: this.currentSearch.customerNo,
        merchantName: this.currentSearch.customerName,
        taxNo: this.currentSearch.taxSource,
        batchNo: this.currentSearch.batchNo,
        orderNo: this.currentSearch.orderNo,
        repayStatus: this.currentSearch.businessStatus,
        beginTime: createTime.beginTime,
        endTime: createTime.endTime,
        custName: this.currentSearch.accountName,
        accountNo: this.currentSearch.accountNo,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        chargeType: this.currentSearch.chargeType,
        repayRemark: this.currentSearch.repayRemark,
        // repayType: this.repayType,
        projectName: this.currentSearch.projectName,
        projectNo: this.currentSearch.projectNo,
        invoiceStatus: this.currentSearch.invoiceStatus
        // reconStatus: this.searchForm.checkStatus
      };
      manualPayListDownload(params).then(response => {
        downloadExcel(response.data, 'manual-pay-record.xls');
      });
    },
    handleSure() {
      this.$router.push({
        path: this.batchSurePath
      });
    },
    checkRow(index, object) {
      // 查看详情
      sessionStorage.setItem(QUERY_DETAIL_ID, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const createTime = separateDate(this.currentSearch.submitDate);
      const completeTime = separateDate(this.currentSearch.completeDate);
      getTransactionListReq({
        merchantNo: this.currentSearch.customerNo,
        merchantName: this.currentSearch.customerName,
        taxNo: this.currentSearch.taxSource,
        batchNo: this.currentSearch.batchNo,
        orderNo: this.currentSearch.orderNo,
        repayStatus: this.currentSearch.businessStatus,
        beginTime: createTime.beginTime,
        endTime: createTime.endTime,
        custName: this.currentSearch.accountName,
        accountNo: this.currentSearch.accountNo,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        repayRemark: this.currentSearch.repayRemark,
        chargeType: this.currentSearch.chargeType,
        channelType: this.currentSearch.channelType,
        // repayType: this.repayType,
        projectName: this.currentSearch.projectName,
        invoiceStatus: this.currentSearch.invoiceStatus,
        projectNo: this.currentSearch.projectNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        payType: this.currentSearch.payType,
        bussincessNo: this.currentSearch.bussincessNo
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};