import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { getAcountInfo } from '@/utils/auth';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { merchantShowList } from '@/api/merchant';
import { downRepayReportReg, repayReportReg } from '@/api/task';
export default {
  name: 'repayList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      datePicker: [],
      taxsList: [],
      listLoading: true,
      empSignType: this.$formatTypeArray('empSignType'),
      searchForm: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        custName: '',
        idcard: '',
        invoiceSerialNo: ''
      },
      currentSearch: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        signinType: '',
        keyWords: ''
      },
      pageNo: 1,
      pageSize: 10,
      merchantList: [],
      downloadVisible: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.downloadVisible = map.get('download');
    }
    this.merchantNo = getAcountInfo().partyCode;
    this.getTaxsList();
    this.partyType = getAcountInfo().partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      this.getMerchantList();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      repayReportReg(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.pageNo = 1;
      this.queryForm();
    },
    downloadData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      downRepayReportReg(this.currentSearch).then(response => {
        if (!response.data) {
          return;
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        const fileName = '付款完成任务汇总表.xls';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
        console.log('error: ' + error);
        this.$message({
          message: '下载文件异常',
          type: 'error'
        });
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    }
  }
};