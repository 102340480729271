import ItemTitle from "@/base/ui/title/itemTitle";
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import UploadImage from "@/base/ui/upload/uploadImage";
import { saveFile } from "@/api/upload";
import { teamInfoDetailInfo, teamInfoDetailUpdate } from "@/api/leaderApply";
import { isRegexn16to20Len } from "@/utils/validate_rules";
export default {
  name: "groupDetail",
  components: {
    ItemTitle,
    UploadMoreFile,
    UploadImage
  },
  data() {
    return {
      saveFile: saveFile,
      loading: false,
      auditRemark: "",
      info: {},
      searchForm: {
        teamName: "",
        licenseNo: "",
        bizLicense: "",
        showUrl: "",
        issueDate: ""
      },
      rules: {
        teamName: [{
          required: true,
          message: "请输入班组名称",
          trigger: "blur"
        }],
        licenseNo: [{
          required: true,
          message: "请输入统一信用代码",
          trigger: "blur"
        }, {
          validator: isRegexn16to20Len,
          trigger: "blur"
        }],
        bizLicense: [{
          required: true,
          message: "请上传附件",
          trigger: "blur"
        }],
        issueDate: [{
          required: true,
          message: "请选择日期",
          trigger: "blur"
        }]
      }
    };
  },
  computed: {
    itemId() {
      return this.$route.query.id;
    },
    review() {
      return +this.$route.query.review === 1;
    }
  },
  created() {
    this.teamInfoDetailInfo();
  },
  // watch: {
  //   $route: "teamInfoDetailInfo",
  // },
  methods: {
    async teamInfoDetailInfo() {
      if (!this.itemId) {
        return;
      }
      this.loading = true;
      const params = {
        id: this.itemId
      };
      const res = await teamInfoDetailInfo(params);
      this.info = res.data;
      if (this.review) {
        const {
          bizLicense,
          licenseNo,
          teamName,
          fileName,
          issueDate
        } = res.data;
        this.searchForm.bizLicense = fileName;
        this.searchForm.licenseNo = licenseNo;
        this.searchForm.teamName = teamName;
        this.searchForm.showUrl = bizLicense;
        this.searchForm.issueDate = issueDate;
      }
      this.loading = false;
    },
    handleSuccessLogUrl(res, file) {
      console.log(res);
      this.searchForm.bizLicense = res.data.saveKey;
      this.searchForm.showUrl = res.data.showUrl;
    },
    onSubmit() {
      this.$refs["searchForm"].validate(valid => {
        if (valid) {
          this.teamInfoDetailUpdateAPI();
        } else {
          return;
        }
      });
    },
    async teamInfoDetailUpdateAPI() {
      const {
        teamName,
        licenseNo,
        bizLicense,
        issueDate
      } = this.searchForm;
      const params = {
        id: this.itemId,
        teamName,
        licenseNo,
        bizLicense,
        issueDate
      };
      console.log('params: ', params);
      const res = await teamInfoDetailUpdate(params);
      this.$message({
        type: "success",
        message: "修改成功!"
      });
      this.back();
    },
    back() {
      this.$router.go(-1);
    }
  }
};