import { isJobOpen } from '@/api/merchant';
import JobOpen from '@/base/ui/jobOpen';
import BusinessesReviewContent from './queryContent';
export default {
  name: 'businessesReviewIndex',
  components: {
    BusinessesReviewContent,
    JobOpen
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  data() {
    return {
      loading: true,
      isOpen: false
    };
  },
  created() {
    this.getJobOpen();
  },
  methods: {
    getJobOpen() {
      this.loading = true;
      isJobOpen().then(response => {
        this.loading = false;
        this.isOpen = response.data.jobOpen === 1;
      });
    },
    getList() {
      if (this.isOpen) {
        this.$refs.child.getList();
      }
    }
  }
};