var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticClass: "border-bg",
    staticStyle: {
      "padding-bottom": "30px"
    }
  }, [(_vm.appInfo === null || _vm.appInfo === {}) && !_vm.queryLoading ? _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "appInfo-text"
  }, [_vm._v("您尚未开通企业API接入，如需开通，请点击“立即开通”，并根据页面提示配置企业密钥信息。")]), _vm._v(" "), _vm.openAppInfo ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.openApp();
      }
    }
  }, [_vm._v("立即开通")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.appInfo !== null && _vm.appInfo !== {} ? _c("update-app-info", {
    attrs: {
      saveForm: _vm.appInfo,
      updateAppInfo: _vm.updateAppInfo,
      resetAppInfoSign: _vm.resetAppInfoSign
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };