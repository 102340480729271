import ViewBase from '../agent/components/view/viewBase';
import ViewBankCard from '../agent/components/view/viewBankCard';
import { showInfo } from '@/api/agent';
export default {
  components: {
    ViewBase,
    ViewBankCard
  },
  data() {
    return {
      active: 0,
      message: 'first',
      bean: {
        agentBase: {},
        bankCard: {}
      }
    };
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      showInfo().then(response => {
        that.bean = response.data;
      });
    }
  }
};