var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.jobLoading ? _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [!_vm.isOpen ? _c("job-open") : _vm._e(), _vm._v(" "), _vm.isOpen ? _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-bottom": "2rem"
    },
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属商户"
    },
    on: {
      change: _vm.merchantChange
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _c("el-form-item", {
    attrs: {
      label: "所属商户："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.merchantName) + "(" + _vm._s(_vm.searchForm.merchantNo) + ")\n        ")]), _vm._v(" "), _vm.partyType !== 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务编号：",
      prop: "taskNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taskNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskNo", $$v);
      },
      expression: "searchForm.taskNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务名称：",
      prop: "taskName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taskName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskName", $$v);
      },
      expression: "searchForm.taskName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "提交记录编号：",
      prop: "recordNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入提交记录编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.recordNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "recordNo", $$v);
      },
      expression: "searchForm.recordNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户姓名：",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户姓名",
      maxlength: "10",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "custName", $$v);
      },
      expression: "searchForm.custName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "证件号：",
      prop: "idcard"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入证件号",
      maxlength: "18",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.idcard,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "idcard", $$v);
      },
      expression: "searchForm.idcard"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票流水号：",
      prop: "invoiceSerialNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开票流水号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.invoiceSerialNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceSerialNo", $$v);
      },
      expression: "searchForm.invoiceSerialNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "更新情况：",
      prop: "updateStatus"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择更新情况"
    },
    model: {
      value: _vm.searchForm.updateStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "updateStatus", $$v);
      },
      expression: "searchForm.updateStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("isHasNo"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "附件情况：",
      prop: "annexStatus"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择附件情况"
    },
    model: {
      value: _vm.searchForm.annexStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "annexStatus", $$v);
      },
      expression: "searchForm.annexStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("isHasNo"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务类型：",
      prop: "taskType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务类型",
      "no-data-text": _vm.typeSelectNoDataText
    },
    model: {
      value: _vm.searchForm.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskType", $$v);
      },
      expression: "searchForm.taskType"
    }
  }, _vm._l(_vm.taskTypeSelectType, function (item) {
    return _c("el-option", {
      key: item.dictCode,
      attrs: {
        label: item.dictValue,
        value: item.dictCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务状态：",
      prop: "taskStatus"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务状态"
    },
    model: {
      value: _vm.searchForm.taskStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskStatus", $$v);
      },
      expression: "searchForm.taskStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("taskStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "记录状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.$formatTypeArray("taskRecordStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票申请时间：",
      prop: "createTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.createTime,
      callback: function ($$v) {
        _vm.createTime = $$v;
      },
      expression: "createTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "审核时间：",
      prop: "auditTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.auditTime,
      callback: function ($$v) {
        _vm.auditTime = $$v;
      },
      expression: "auditTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.exportTemplateVisible ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.showExportTemplateDialog
    }
  }, [_vm._v("导出附件模版")]) : _vm._e(), _vm._v(" "), _vm.updateRecordsPath ? _c("el-button", {
    attrs: {
      type: "info",
      icon: "el-icon-s-open"
    },
    on: {
      click: _vm.showUpdateRecordsDialog
    }
  }, [_vm._v("记录更新")]) : _vm._e(), _vm._v(" "), _vm.batchTaskFilesPath ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-upload2"
    },
    on: {
      click: _vm.showBatchFilesDialog
    }
  }, [_vm._v("任务附件提交")]) : _vm._e(), _vm._v(" "), _vm.importTaskVisible ? _c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.showImportDialog
    }
  }, [_vm._v("导入任务匹配")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "recordNo",
      label: "提交记录编号",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskNo",
      label: "任务编号",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskName",
      label: "任务名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskTypeName",
      label: "任务类型",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "所属商户",
      "min-width": "180",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", [_c("div", [_vm._v(_vm._s(scope.row.merchantName))]), _vm._v(" "), _c("div", [_vm._v("(" + _vm._s(scope.row.merchantNo) + ")")])])];
      }
    }], null, false, 4919323)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "180",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "startDate",
      label: "任务开始时间",
      "min-width": "120",
      formatter: _vm.$formatDateInTable
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "endDate",
      label: "任务结束时间",
      "min-width": "120",
      formatter: _vm.$formatDateInTable
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pricingModeName",
      label: "任务计价方式",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditAmount",
      label: "合计金额(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "任务金额(元)",
      "min-width": "170"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticStyle: {
            display: "flex"
          }
        }, [_c("div", [_vm._v("\n                " + _vm._s(_vm.$fmoneyWithSymbol(scope.row.singleAmount)) + "\n              ")]), _vm._v(" "), scope.row.singleMaxAmount > 0 ? _c("div", [_vm._v("\n                -\n                " + _vm._s(_vm.$fmoneyWithSymbol(scope.row.singleMaxAmount)) + "\n              ")]) : _vm._e()])];
      }
    }], null, false, 2854660202)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskStatus",
      label: "任务状态",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("taskStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "记录状态",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("taskRecordStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "提交人",
      "min-width": "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceSerialNo",
      label: "开票流水号",
      "min-width": "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "applyTime",
      label: "开票申请时间",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditTime",
      label: "审核时间",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateStatus",
      label: "更新情况",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("isHasNo")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "annexStatus",
      label: "附件情况",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("isHasNo")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      "min-width": "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.viewTaskPath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row.id);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.saveRecordAuditPath && scope.row.status === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.gotoAudit(scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e()];
      }
    }], null, false, 1109248388)
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("audit-task-dialog", {
    attrs: {
      ids: _vm.selectIds,
      isVisible: _vm.showAuditDialog
    },
    on: {
      updated: _vm.auditDialogChang
    }
  }), _vm._v(" "), _c("batch-task-files", {
    attrs: {
      dialogVisible: _vm.batchTaskFilesDialog
    },
    on: {
      changeDialogVisible: _vm.changeDialogVisible
    }
  }), _vm._v(" "), _c("update-record-dialog", {
    attrs: {
      dialogVisible: _vm.updateRecordsDialog
    },
    on: {
      changeDialogVisible: _vm.changeUpdateRecordsDialog
    }
  }), _vm._v(" "), _c("preview-dialog", {
    attrs: {
      dialogFormVisible: _vm.previewDialogVisible,
      info: _vm.previewData
    },
    on: {
      changeVisiblePreviewDialog: _vm.changeVisiblePreviewDialog
    }
  }), _vm._v(" "), _c("import-task-dialog", {
    attrs: {
      dialogVisible: _vm.importDialog
    },
    on: {
      changeDialogVisible: _vm.changeImportDialog
    }
  })], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };