import { MESSAGE_CENTER_INFO_ID } from '../../../utils/idName';
import { getMsgCenterInfo } from '../../../api/sys';
import ItemTitle from '../../../base/ui/title/itemTitle';
import { decode } from 'js-base64';
export default {
  name: 'msgCenterInfo',
  components: {
    ItemTitle
  },
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const id = sessionStorage.getItem(MESSAGE_CENTER_INFO_ID);
      getMsgCenterInfo(id).then(response => {
        this.info = response.data;
      });
    },
    decodeContents(content) {
      return decode(content);
    }
  }
};