import { mapGetters } from 'vuex';
import { timePickerOptions, separateDate } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getInvoiceContentList } from '@/api/invoice';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import CheckInvoice from '../components/checkInvoice';
import UpdateInvoice from '../components/updateInvoice';
import ItemTitle from '@/base/ui/title/itemTitle';
import AddInvoiceContentDialog from './components/AddInvoiceContentDialog';
import UpdateInvoiceContentDialog from './components/UpdateInvoiceContentDialog';
import { deleteInvoiceContent } from '../../../api/invoice';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import UploadInvoiceDialog from '../../account/withdraw/uploadInvoiceDialog';
export default {
  name: 'invoiceContentList',
  components: {
    UploadInvoiceDialog,
    UpdateInvoiceContentDialog,
    AddInvoiceContentDialog,
    ItemTitle,
    Pagination,
    CheckInvoice,
    UpdateInvoice,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        taxNo: '',
        invoiceType: '',
        contentName: '',
        contentCode: '',
        date: []
      },
      taxSources: [],
      invoices: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      hasDeletePermission: false,
      hasAddPermission: false,
      hasUpdatePermission: false,
      partyType: '',
      taxName: '',
      currentSearch: {},
      addDialogVisible: false,
      updateDialogVisible: false,
      hasImportPermission: false,
      uploadDialogVisible: false,
      updateItem: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else if (this.partyType === 3) {
      this.searchForm.customerNo = user.partyCode;
    }
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasAddPermission = map.has('addInvoiceContent');
      this.hasDeletePermission = map.has('deleteInvoiceContent');
      this.hasUpdatePermission = map.has('updateInvoiceContent');
      this.hasImportPermission = map.has('import');
    } else {
      this.hasAddPermission = false;
      this.hasDeletePermission = false;
      this.hasUpdatePermission = false;
    }
    if (this.partyType !== 1) {
      this.getTaxSources();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    // 刷新
    updated() {
      this.getList();
    },
    showAddDialog() {
      this.addDialogVisible = true;
    },
    showUpdateDialog(row) {
      this.updateItem = row;
      this.updateDialogVisible = true;
    },
    // 取消
    deleteRow(index, object) {
      this.$confirm('  删除该发票内容后，企业提交开票申请时，则无法选择该开票内容，确认要删除吗？', '删除提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        center: true,
        closeOnClickModal: false
      }).then(() => {
        this.deleteInvoice(object.id);
      });
    },
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      getInvoiceContentList({
        taxNo: this.currentSearch.taxNo,
        invoiceType: this.currentSearch.invoiceType,
        contentName: this.currentSearch.contentName,
        contentCode: this.currentSearch.contentCode,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.invoices = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    deleteInvoice(id) {
      deleteInvoiceContent(id).then(() => {
        this.$message.success('操作成功...');
        this.getQuery();
      });
    },
    changeAddDialogVisible(val) {
      this.addDialogVisible = val;
      this.getList();
    },
    changeUpdateDialogVisible(val) {
      this.updateDialogVisible = val;
      this.getList();
    },
    changeUploadDialogVisible(val) {
      this.uploadDialogVisible = val;
      this.getList();
    }
  }
};