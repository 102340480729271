import axios from 'axios';
import { Message } from 'element-ui';
import store from '../store';
import { getAuthSession } from '@/utils/auth';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API,
  // api的base_url
  timeout: 50000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  disableButtons();
  if (store.getters.authSession) {
    config.headers['X-AUTH-SESSION'] = getAuthSession(); // 让每个请求携带自定义X-AUTH-SESSION 请根据实际情况自行修改
  }
  config.method = 'post';
  config.responseType = 'blob';
  return config;
}, error => {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
  Message({
    message: error,
    type: 'error',
    duration: 5 * 1000
  });
});

// response拦截器
service.interceptors.response.use(response => {
  enableButtons();
  const res = response.data;
  // res.type == application/octet-stream
  if (res && res.size && res.type !== 'application/json') {
    return response;
  } else {
    if (res.type === 'application/json') {
      return getResultObject(res);
    } else {
      Message({
        message: '下载文件异常',
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject('error');
    }
  }
}, error => {
  console.log('error: ' + error);
  Message({
    message: '下载文件异常',
    type: 'error',
    duration: 5 * 1000
  });
  enableButtons();
  return Promise.reject(error);
});
// 请求后台enable所有button
function enableButtons() {
  const btns = document.getElementsByTagName('button');
  for (let i = 0; i < btns.length; i++) {
    btns[i].disabled = false;
    let clsname = btns[i].className;
    clsname = clsname.replace(' is-disabled', '');
    btns[i].className = clsname;
  }
}
// 请求后台禁用所有button
function disableButtons() {
  const btns = document.getElementsByTagName('button');
  for (let i = 0; i < btns.length; i++) {
    btns[i].disabled = 'disabled';
    btns[i].className += ' is-disabled';
  }
}
// 解析blob，得到错误提示
function getResultObject(blob) {
  return new Promise(function (resolve, reject) {
    blob.text().then(text => {
      const obj = JSON.parse(text);
      const errMsg = obj.errMsg ? obj.errMsg : '下载文件异常';
      Message({
        message: errMsg,
        type: 'error',
        duration: 5 * 1000
      });
      reject(errMsg);
    });
  });
}
export default service;