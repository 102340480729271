import CityCascader from '@/base/ui/cascader/cityCascader';
import { saveBase } from '@/api/taxsource';
import { beforeUploadPdf } from '@/utils/util';
import ElRadio from 'element-ui/packages/radio/src/radio';
import ElRadioGroup from 'element-ui/packages/radio/src/radio-group';
import { isMobile, isIdCardNo } from '@/utils/validate_rules';
import { isMobiles } from '@/utils/validate_rules';
import UploadImage from '@/base/ui/upload/uploadImage';
export default {
  name: 'taxSourceSaveBase',
  components: {
    UploadImage,
    ElRadioGroup,
    ElRadio,
    CityCascader
  },
  data() {
    return {
      beforeUploadPdf: beforeUploadPdf,
      legalPersonIdcardpic: '',
      licensePic: '',
      protocolUrl: '',
      signetUrl: '',
      selectedOptions: '',
      city: '',
      uploadDemoText: '',
      quotaTypes: this.$formatTypeArray('taxQuotaType'),
      saveForm: {
        address: '',
        area: '',
        areaCode: '',
        city: '',
        cityCode: '',
        contactNumber: '',
        contacts: '',
        legalPerson: '',
        legalPersonIdcard: '',
        legalPersonIdcardpic: '',
        licenseNo: '',
        licensePic: '',
        // protocolUrl: '',
        signetUrl: '',
        province: '',
        provinceCode: '',
        shortName: '',
        taxName: '',
        quotaType: '',
        quota: '',
        rechargeNotifyMobile: '',
        rechargeAutoAudit: 0,
        rechargeAuto: 0,
        trusteeTimeEnd: '',
        trusteeMobile: '',
        trusteeIdCard: '',
        trusteeName: '',
        yearQuota: '',
        yearLargeSwitch: 0,
        yearLargeQuota: ''
      },
      rules: {
        address: [{
          required: true,
          message: '请输入地址',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        contactNumber: [{
          required: true,
          message: '请输入联系人手机号码',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确联系人姓名',
          trigger: 'blur'
        }],
        legalPerson: [{
          required: true,
          message: '请输入法人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确的法人姓名',
          trigger: 'blur'
        }],
        legalPersonIdcard: [{
          required: true,
          message: '请输入法人身份证号码',
          trigger: 'blur'
        }, {
          validator: isIdCardNo,
          trigger: 'blur'
        }],
        taxName: [{
          required: true,
          message: '请输入劳务公司名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        shortName: [{
          required: true,
          message: '请输入劳务公司品牌',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        quotaType: [{
          required: true,
          message: '请选择个人月度限额额度',
          trigger: 'blur'
        }],
        quota: [{
          required: true,
          message: '请输入个人季度限额额度',
          trigger: 'blur'
        }, {
          min: 1,
          max: 8,
          message: '大于0，小于9位数字',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请输入劳务公司编号',
          trigger: 'blur'
        }],
        licenseNo: [{
          required: true,
          message: '请输入统一信用代码',
          trigger: 'blur'
        }, {
          min: 10,
          max: 20,
          message: '大于10字符，小于20个字符',
          trigger: 'blur'
        }],
        areaCode: [{
          required: true,
          message: '请选择所属地区',
          trigger: 'blur'
        }],
        licensePic: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }],
        legalPersonIdcardpic: [{
          required: true,
          message: '请上传身份证',
          trigger: 'blur'
        }],
        // protocolUrl: [
        //   { required: true, message: '请上传协议模版', trigger: 'blur' }
        // ],
        signetUrl: [{
          required: true,
          message: '请上传电子印章',
          trigger: 'blur'
        }],
        trusteeTimeEnd: [{
          required: true,
          message: '请选择税务代理结束日期',
          trigger: 'blur'
        }],
        trusteeMobile: [{
          required: true,
          message: '请输入税务代理人手机',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        trusteeIdCard: [{
          required: true,
          message: '请输入税务代理人身份证号',
          trigger: 'blur'
        }, {
          validator: isIdCardNo,
          trigger: 'blur'
        }],
        trusteeName: [{
          required: true,
          message: '请输入税务代理人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确的税务代理人姓名',
          trigger: 'blur'
        }],
        rechargeAutoAudit: [{
          required: true,
          message: '请选择充值自动审核',
          trigger: 'blur'
        }],
        rechargeNotifyMobile: [{
          validator: isMobiles,
          trigger: 'blur'
        }],
        yearQuota: [{
          required: true,
          message: '个人年度限额额度',
          trigger: 'blur'
        }],
        monthQuota: [{
          required: true,
          message: '个人月度限额额度',
          trigger: 'blur'
        }],
        quarterQuota: [{
          required: true,
          message: '个人季度限额额度',
          trigger: 'blur'
        }],
        yearLargeSwitch: [{
          required: true,
          message: '请选择个人大额出款开关',
          trigger: 'blur'
        }],
        yearLargeQuota: [{
          required: true,
          message: '请输入近12个月大额出款限额',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    }
  },
  methods: {
    addSubmit() {
      this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
      this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      this.saveForm.areaCode = this.$refs.proviceCity.selectedOptions[2];
      this.selectedOptions = this.$refs.proviceCity.selectedOptions[0];
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          // debugger
          saveBase(this.saveForm).then(response => {
            if (this.gotoNext) {
              this.gotoNext(response.data);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = res.data.saveKey;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = res.data.saveKey;
    },
    handleAvatarSuccessBySignetUrl(res, file) {
      this.saveForm.signetUrl = res.data.saveKey;
    }
  }
};