var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "invoiceForm",
    attrs: {
      model: _vm.invoiceForm,
      rules: _vm.rules,
      "label-position": "right",
      "label-width": "110px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开具类型",
      prop: "openType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.invoiceForm.openType,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "openType", $$v);
      },
      expression: "invoiceForm.openType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("个人")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("企业")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票抬头",
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票抬头",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "merchantName", $$v);
      },
      expression: "invoiceForm.merchantName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票类型",
      prop: "invoiceType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.invoiceForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "invoiceType", $$v);
      },
      expression: "invoiceForm.invoiceType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("增值税普通发票\n          "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top-start"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div", {
          staticClass: "tooltip"
        }, [_vm._v("\n                " + _vm._s(_vm.tips[0]) + "\n              ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("i", {
    staticClass: "el-icon-question"
  })])], 1), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("增值税专用发票\n          "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      placement: "top-start"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c("div", {
          staticClass: "tooltip"
        }, [_vm._v("\n                " + _vm._s(_vm.tips[1]) + "\n              ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("i", {
    staticClass: "el-icon-question"
  })])], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "税务登记证号",
      prop: "invoiceNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入税务登记证号",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.invoiceNo,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "invoiceNo", $$v);
      },
      expression: "invoiceForm.invoiceNo"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "note"
  }, [_vm._v("注意：请与贵公司财务人员核实后，仔细填写准确的三证合一后的社会统一信用代码，否则将影响后续发票的正常使用")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "基本开户银行",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入基本开户银行",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "bankName", $$v);
      },
      expression: "invoiceForm.bankName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "基本开户账户",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入基本开户账户",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "accountNo", $$v);
      },
      expression: "invoiceForm.accountNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址",
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入注册地址",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "companyAddress", $$v);
      },
      expression: "invoiceForm.companyAddress"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册电话",
      prop: "companyNumber"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入注册电话",
      clearable: ""
    },
    model: {
      value: _vm.invoiceForm.companyNumber,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceForm, "companyNumber", $$v);
      },
      expression: "invoiceForm.companyNumber"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };