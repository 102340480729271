var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称",
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入甲方单位名称"
    },
    model: {
      value: _vm.saveForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "merchantName", $$v);
      },
      expression: "saveForm.merchantName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名",
      prop: "contacts"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人姓名"
    },
    model: {
      value: _vm.saveForm.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contacts", $$v);
      },
      expression: "saveForm.contacts"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机",
      prop: "contactMobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人手机号码"
    },
    model: {
      value: _vm.saveForm.contactMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactMobile", $$v);
      },
      expression: "saveForm.contactMobile"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区",
      prop: "areaCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      leaf: "2",
      placeholder: "请选择所属地区"
    },
    model: {
      value: _vm.saveForm.areaCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "areaCode", $$v);
      },
      expression: "saveForm.areaCode"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位地址",
      prop: "address"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入甲方单位地址"
    },
    model: {
      value: _vm.saveForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "address", $$v);
      },
      expression: "saveForm.address"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "工商信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入统一信用代码"
    },
    model: {
      value: _vm.saveForm.licenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "licenseNo", $$v);
      },
      expression: "saveForm.licenseNo"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：统一信用代码提交成功后，不可修改。)")])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "纳税人性质"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择纳税人性质",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.taxpayerType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxpayerType", $$v);
      },
      expression: "saveForm.taxpayerType"
    }
  }, _vm._l(_vm.taxpayerTypeStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名",
      prop: "legalPerson"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入法人姓名"
    },
    model: {
      value: _vm.saveForm.legalPerson,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPerson", $$v);
      },
      expression: "saveForm.legalPerson"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件号码",
      prop: "legalPersonIdcard"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入证件号码"
    },
    model: {
      value: _vm.saveForm.legalPersonIdcard,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPersonIdcard", $$v);
      },
      expression: "saveForm.legalPersonIdcard"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型",
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择开票类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "invoiceType", $$v);
      },
      expression: "saveForm.invoiceType"
    }
  }, _vm._l(_vm.merchantInvoiceType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "证件照片"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传营业执照",
      prop: "licensePic"
    }
  }, [_c("upload-image", {
    on: {
      imageSuccess: _vm.handleAvatarSuccessBylicense
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传身份证",
      prop: "legalPersonIdcardpic"
    }
  }, [_c("upload-image", {
    on: {
      imageSuccess: _vm.handleAvatarSuccessByICCard
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    staticClass: "step-next-btn",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("确定")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };