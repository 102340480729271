import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { mapGetters } from 'vuex';
import { teamLeaderApplyList } from '@/api/leaderApply';
export default {
  name: 'LeaderApplyList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      loading: false,
      applyDetail: '',
      applyReview: '',
      searchForm: {
        keyWords: '',
        status: '',
        teamType: ''
      },
      datePicker: [],
      statusList: [],
      list: [],
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created() {
    this.statusList = this.$formatTypeArray('leaderApplyStatus');
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.applyDetail = map.get('applyDetail');
      this.applyReview = map.get('applyReview');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryForm();
    });
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    queryForm() {
      this.currentSearch = {
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = this.$options.data().searchForm;
      this.queryForm();
    },
    async getList() {
      this.loading = true;
      const {
        keyWords,
        status,
        teamType
      } = this.searchForm;
      const {
        pageNo,
        pageSize
      } = this.currentSearch;
      const [beginTime, endTime] = this.datePicker;
      const params = {
        keyWords,
        status,
        teamType,
        pageNo,
        pageSize,
        beginTime,
        endTime
      };
      const res = await teamLeaderApplyList(params);
      this.list = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    },
    handleView(index, row) {
      this.$router.push({
        path: this.applyDetail,
        query: {
          review: 0,
          id: row.id
        }
      });
    },
    addAgreement(index, row) {
      this.$router.push({
        path: this.applyReview,
        query: {
          review: 1,
          id: row.id
        }
      });
    }
  }
};