import { getAcountInfo } from '../../../utils/auth';
import { queryWindowNoticeGongGao } from '../../../api/sys';
import { MESSAGE_CENTER_INFO_ID } from '../../../utils/idName';
export default {
  name: 'noticeGongGao',
  data() {
    return {
      partyType: '',
      list: '',
      showItem: {},
      index: 0
    };
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 3 || this.partyType === 2) {
      this.getMsg();
    }
  },
  methods: {
    getMsg() {
      queryWindowNoticeGongGao().then(response => {
        this.list = response.data;
        this.index = 0;
        this.setItem();
      });
    },
    setItem() {
      this.showItem = this.list[this.index];
      this.index++;
      if (this.index === this.list.length) {
        this.index = 0;
      }
      this.forItem();
    },
    forItem() {
      setTimeout(() => {
        this.setItem();
      }, 1000 * 5);
    },
    gotoInfo() {
      if (this.showItem.openType === 1) {
        window.open(this.showItem.contents);
      } else {
        sessionStorage.setItem(MESSAGE_CENTER_INFO_ID, this.showItem.id);
        this.$router.push({
          path: '/msgCenter/msgCenterList/msgCenterInfo'
        });
      }
    }
  }
};