export default {
  name: 'detail-column',
  props: {
    left: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    fullLine: {
      type: Boolean,
      default: false
    },
    customTitleClass: {
      type: String
    },
    customValueClass: {
      type: String
    }
  },
  computed: {
    className: function () {
      if (this.fullLine) {
        return 'detail-column-full-line';
      }
      return this.left ? 'detail-column-left-col' : 'detail-column-right-col';
    },
    titleClassName: function () {
      if (this.customTitleClass) {
        return ['detail-column-title', this.customTitleClass];
      }
      return 'detail-column-title';
    },
    valueClassName: function () {
      if (this.customValueClass) {
        return ['detail-column-value', this.customValueClass];
      }
      return 'detail-column-value';
    }
  }
};