import ItemTitle from '@/base/ui/title/itemTitle';
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { uploadInvoice } from '@/api/upload';
import { invoiceConfirm } from "@/api/teamInvoice";
export default {
  components: {
    ItemTitle,
    UploadMoreFile
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    info: {
      type: Object
    }
  },
  data() {
    return {
      uploadInvoice,
      addForm: {
        invoiceDate: '',
        invoiceUrlList: []
      },
      extendData: {
        id: ''
      },
      rules: {
        invoiceDate: [{
          required: true,
          message: '请选择开票日期',
          trigger: 'blur'
        }],
        invoiceUrlList: [{
          required: true,
          message: '请上传开票文件',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    open() {
      this.extendData.id = this.info.id;
    },
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.invoiceConfirmAPI();
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    invoiceConfirmAPI() {
      const invoiceUrlList = this.addForm.invoiceUrlList.map(el => {
        return el.response.data.saveKey;
      });
      invoiceConfirm({
        id: this.info.id,
        invoiceDate: this.addForm.invoiceDate,
        invoiceUrlList
      }).then(() => {
        this.dialogVisible = false;
        this.$emit('doRefresh');
      }).catch(() => {
        this.dialogVisible = false;
      });
    },
    dialogClosed() {
      this.$refs['addForm'].resetFields();
      this.$refs['addForm'].clearValidate();
      this.cancelForm();
    }
  }
};