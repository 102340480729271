var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _vm.saveForm !== undefined ? _c("el-form", {
    staticClass: "save-base-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "150px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.taxName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.taxNo) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.licenseNo) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人证件号码："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.legalPersonIdcard) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.contacts) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.contactNumber) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司品牌："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.shortName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatCity()) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人月度限额额度："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.monthQuota) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人季度限额额度："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.quarterQuota) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人年度限额额度："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.yearQuota) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "财务负责人姓名："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.trusteeName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值自动审核："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("rechargeAutoAudit", this.saveForm.rechargeAutoAudit)) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "财务负责人手机号："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.trusteeMobile) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人大额出款开关："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("yearLargeSwitch", this.saveForm.yearLargeSwitch)) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.saveForm.yearLargeSwitch === 1 ? _c("el-form-item", {
    attrs: {
      label: "近12个月大额出款限额："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.yearLargeQuota) + "\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "单位地址："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.address) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值通知手机："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.rechargeNotifyMobile) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照："
    }
  }, [_vm.saveForm.licensePicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.licensePicShow,
      "preview-src-list": [_vm.saveForm.licensePicShow]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证："
    }
  }, [_vm.saveForm.legalPersonIdcardpicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.legalPersonIdcardpicShow,
      "preview-src-list": [_vm.saveForm.legalPersonIdcardpicShow]
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.legalPerson) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "电子印章："
    }
  }, [_vm.saveForm.signetUrlShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.signetUrlShow,
      "preview-src-list": [_vm.saveForm.signetUrlShow]
    }
  }) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };