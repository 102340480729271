import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { deleteTaskCategory, downloadTaskCategory, getTaskCategoryList } from '../../../api/task';
import { downloadExcel } from '../../../utils/util';
import { merchantShowList } from '../../../api/merchant';
import { TASK_UPDATE_CATEGORY_ID } from '../../../utils/idName';
export default {
  name: 'categoryListContent',
  components: {
    Pagination
  },
  data() {
    return {
      searchForm: {
        merchantNo: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      addCategory: '',
      updateCategory: '',
      deleteCategory: '',
      downloadCategory: '',
      merchantList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addCategory = map.get('addCategory');
      this.updateCategory = map.get('updateCategory');
      this.deleteCategory = map.get('deleteCategory');
      this.downloadCategory = map.has('downloadCategory');
    }
    this.getList();
    this.getMerchantList();
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.dateTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getTaskCategoryList({
        merchantNo: this.currentSearch.merchantNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadForm() {
      if (this.list.length <= 0) {
        this.$message.error('暂无数据导出');
        return;
      }
      const params = {
        merchantNo: this.currentSearch.merchantNo
      };
      downloadTaskCategory(params).then(response => {
        downloadExcel(response.data, '企业任务分类管理.xls');
      });
    },
    handleUpdate(row) {
      sessionStorage.setItem(TASK_UPDATE_CATEGORY_ID, row.id);
      this.$router.push({
        path: this.updateCategory
      });
    },
    handleAdd() {
      this.$router.push({
        path: this.addCategory
      });
    },
    handleDelete(row) {
      this.$confirm('是否确定删除该任务分类？', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false,
        confirmButtonText: '确定'
        // closeOnPressEscape: false,
      }).then(() => {
        deleteTaskCategory(row.id).then(() => {
          this.$message.success('操作成功。。。');
          this.getList();
        });
      }).catch(() => {});
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    }
  }
};