import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { empSignList, downloadSignList, cancelEmpSign } from '@/api/merchantEmp';
import { getAcountInfo } from '@/utils/auth';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'merchantEmpSignList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      cancelEmpSign: '',
      activateEmpSign: '',
      list: [],
      datePicker: [],
      taxsList: [],
      listLoading: true,
      empSignStatus: this.$formatTypeArray('empSignStatus'),
      empSignType: this.$formatTypeArray('empSignType'),
      searchForm: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        status: '',
        signinType: '',
        keyWords: ''
      },
      currentSearch: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        status: '',
        signinType: '',
        keyWords: ''
      },
      pageNo: 1,
      pageSize: 10,
      empNo: '',
      downloadVisible: false
    };
  },
  props: {
    empNoChild: '',
    isChild: false
  },
  watch: {
    empNoChild(val) {
      this.empNoChild = this.empNo = val;
      this.getList();
    }
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.cancelEmpSign = map.get('cancelEmpSign');
      this.activateEmpSign = map.get('activateEmpSign');
      this.downloadVisible = map.has('download');
    }
    this.merchantNo = getAcountInfo().partyCode;
    this.getTaxsList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getListByChild(empNo) {
      this.empNo = empNo;
      this.getList();
    },
    getList() {
      if (this.isChild) {
        if (this.empNo === '') {
          return;
        }
        this.currentSearch.empNo = this.searchForm.empNo = this.empNo;
      }
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      empSignList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.searchForm = {
        beginTime: '',
        endTime: '',
        taxNo: '',
        status: '',
        signinType: '',
        keyWords: ''
      };
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.queryForm();
    },
    handleCancelEmp(row) {
      this.$confirm('是否确定解除该人员签约信息?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        cancelEmpSign(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    downloadData() {
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      downloadSignList(this.currentSearch).then(response => {
        if (!response.data) {
          return;
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        const fileName = 'EmployeeList.xls';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
        console.log('error: ' + error);
        this.$message({
          message: '下载文件异常',
          type: 'error'
        });
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('empSignStatus', row.status);
    }
  }
};