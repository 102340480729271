import { mapGetters } from 'vuex';
import { getApiList, deleteApi } from '@/api/sys';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { API_UPDATE_ID, API_INFO_ID } from '@/utils/idName';
export default {
  name: 'addUserForm',
  components: {
    Pagination
  },
  data() {
    return {
      listQuery: {
        name: '',
        pageNo: 1,
        pageSize: 10,
        type: '',
        status: ''
      },
      listLoading: true,
      total: 0,
      apis: [],
      addApiPath: '',
      addApiVisible: false,
      viewApiPath: '',
      deleteApiVisible: false,
      updateApiVisible: false,
      updateApiPath: '',
      apiStatus: this.$formatTypeArray('apiStatus'),
      apiTypes: this.$formatTypeArray('apiType')
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewApiPath = map.get('viewApi');
      this.updateApiPath = map.get('updateApi');
      this.updateApiVisible = map.has('updateApi');
      this.addApiPath = map.get('addApi');
      this.addApiVisible = map.has('addApi');
      this.deleteApiVisible = map.has('deleteApi');
    } else {
      this.addApiVisible = false;
      this.updateApiVisible = false;
      this.deleteApiVisible = false;
    }
    this.getList();
  },
  methods: {
    getQuery() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      getApiList(this.listQuery).then(response => {
        this.apis = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.listQuery = {
        name: '',
        pageNo: 1,
        pageSize: 10,
        type: '',
        status: ''
      };
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.listQuery.pageNo, this.listQuery.pageSize);
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteApi(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handleAdd() {
      this.$router.push({
        path: this.addApiPath
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(API_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updateApiPath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(API_INFO_ID, row.id);
      this.$router.push({
        path: this.viewApiPath
      });
    },
    formatType(row, column) {
      return this.$formatType('apiType', row.type);
    },
    formatStatus(row, column) {
      return this.$formatType('apiStatus', row.status);
    }
  },
  mounted() {}
};