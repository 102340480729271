import CityCascader from '@/base/ui/cascader/cityCascader';
import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      legalPersonIdcardpic: '',
      licensePic: '',
      protocolUrl: '',
      signetUrl: '',
      selectedOptions: '',
      city: ''
    };
  },
  props: {
    saveForm: {
      address: '',
      area: '',
      areaCode: '',
      city: '',
      cityCode: '',
      contactNumber: '',
      contacts: '',
      legalPerson: '',
      legalPersonIdcard: '',
      legalPersonIdcardpic: '',
      licenseNo: '',
      licensePic: '',
      protocolUrl: '',
      signetUrl: '',
      province: '',
      provinceCode: '',
      shortName: '',
      taxName: '',
      taxNo: '',
      quota: ''
    }
  },
  methods: {
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city + '/' + this.saveForm.area;
      }
    },
    getUploadDemoText() {
      if (this.saveForm.protocolUrl !== '') {
        return this.saveForm.taxName + '协议模版';
      } else {
        return '';
      }
    }
  }
};