import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination/index';
import { PROFIT_VIEW_RECORD_INFO } from '@/utils/idName';
import { getInvoiceTitle, getProfitRecordInfo } from '@/api/profit';
import { getAgentProfitDetailList } from '@/api/transaction';
export default {
  components: {
    Pagination,
    ItemTitle
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      infoForm: {},
      list: [],
      invoiceTitleInfo: {},
      showDialog: false,
      itemInfo: {}
    };
  },
  created() {
    this.getInfo();
    getInvoiceTitle().then(response => {
      this.invoiceTitleInfo = response.data;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getInfo();
    });
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getInfo() {
      const itemInfoJson = sessionStorage.getItem(PROFIT_VIEW_RECORD_INFO);
      if (itemInfoJson) {
        this.itemInfo = JSON.parse(itemInfoJson);
      }
      getProfitRecordInfo(this.itemInfo.id).then(response => {
        this.infoForm = response.data;
        this.getQuery();
      });
    },
    getList() {
      this.listLoading = true;
      getAgentProfitDetailList({
        agentNo: this.infoForm.agentNo,
        profitMonth: this.infoForm.profitMonth,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};