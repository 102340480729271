const AuthKey = 'X-AUTH-SESSION';
const ACOUNT_INFO = 'X-ACOUNT-INFO';
function getAuthSession() {
  return localStorage.getItem(AuthKey);
}
function setAuthSession(authKey) {
  return localStorage.setItem(AuthKey, authKey);
}
function removeAuthSession() {
  return localStorage.removeItem(AuthKey);
}
function setAcountInfo(acountInfo) {
  return localStorage.setItem(ACOUNT_INFO, JSON.stringify(acountInfo));
}
function getAcountInfo() {
  const infoJson = localStorage.getItem(ACOUNT_INFO);
  if (infoJson) {
    return JSON.parse(infoJson);
  }
  return null;
}
function removeAcountInfo(acountInfo) {
  return localStorage.removeItem(ACOUNT_INFO);
}
export { setAcountInfo, getAuthSession, setAuthSession, removeAuthSession, getAcountInfo, removeAcountInfo };