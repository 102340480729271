var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号",
      prop: "taxNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入劳务公司编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "劳务公司名称",
      prop: "taxName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入劳务公司名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taxName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxName", $$v);
      },
      expression: "searchForm.taxName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "通道类型",
      prop: "channelType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择通道类型",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.channelType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "channelType", $$v);
      },
      expression: "searchForm.channelType"
    }
  }, _vm._l(_vm.channelTypes, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.taxStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.saveProduct ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "60",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelNo",
      label: "通道编号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelName",
      label: "通道名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelTypeName",
      label: "通道类型",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "contactNumber",
      label: "自动充值支持",
      formatter: _vm.formatIsSupport,
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "baseType",
      label: "基准类型",
      formatter: _vm.$formatTypeInTable("taxChannelBaseType"),
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "状态",
      formatter: _vm.formatStatus,
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "140",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.updateProduct ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.viewProduct ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && _vm.forzenChannel ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.freeze(scope.row);
            }
          }
        }, [_vm._v("冻结")]) : _vm._e(), _vm._v(" "), scope.row.status === 2 && _vm.forzenChannel ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.unfreeze(scope.row);
            }
          }
        }, [_vm._v("解冻")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };