import UploadFile from '../../../base/ui/upload/uploadFile';
import ItemTitle from '../../../base/ui/title/itemTitle';
import UploadMoreFile from '../../../base/ui/upload/uploadMoreFile';
import { uploadRecept } from '../../../api/transaction';
export default {
  name: 'uploadReceptDialog',
  components: {
    UploadMoreFile,
    ItemTitle,
    UploadFile
  },
  data() {
    return {
      myDialogFormVisible: false,
      saveForm: {
        fileList: [],
        filePath: '',
        id: []
      }
    };
  },
  props: {
    dialogFormVisible: false,
    id: {
      require: true
    }
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeReceptVisible', val);
    }
  },
  methods: {
    handleUploadSuccess(res, file) {
      this.saveForm.filePath = res.data.saveKey;
    },
    dialogClosed() {
      this.saveForm.fileList = [];
    },
    doSubmit() {
      if (this.saveForm.fileList.length === 0) {
        this.$message.error('请选择上传文件。');
        return;
      }
      uploadRecept({
        id: this.id,
        filePath: this.saveForm.fileList[0].response.data.saveKey
      }).then(resopnse => {
        this.$message.success('上传成功。');
        this.dialogClosed();
        this.myDialogFormVisible = false;
      });
    }
  }
};