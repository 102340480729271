var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.projectName))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分包范围:"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.projectScope))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "合同总金额:"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.amount))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "工程地址：",
      prop: "address"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.province) + _vm._s(_vm.saveForm.city) + _vm._s(_vm.saveForm.area) + _vm._s(_vm.saveForm.address))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目母编号：",
      prop: "cityCode"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.parentProjectNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目子编号：",
      prop: "cityCode"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.projectNo))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "合同代发金额："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.amount))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开工日期："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.startDate))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "完工日期："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.completeDate))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contactName))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contactMobile))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "复核人手机号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.reviewMobile))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型：",
      prop: "invoiceType"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatMchantInvoiceType(_vm.saveForm.invoiceType)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "业主单位："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.businessOwner))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目备注："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.remark))])])], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "所属甲方单位"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称：",
      prop: "repayNotifyUrl"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.merchantName))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码：",
      prop: "licenseNo"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.licenseNo))])])], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "项目合同"
    }
  }), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.saveForm.agreementList,
      loading: _vm.loading,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agreementNo",
      label: "合同编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agreementName",
      label: "合同名称",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agreementAmt",
      label: "合同金额",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "signDate",
      label: "签约时间",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "合同备注",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "fileName",
      label: "合同附件",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-link", {
          attrs: {
            type: "primary",
            href: scope.row.fileUrl,
            target: "_blank"
          }
        }, [_vm._v(_vm._s(scope.row.fileName))])];
      }
    }])
  })], 1), _vm._v(" "), _c("item-title", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.saveForm.logList,
      loading: _vm.loading,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "userName",
      label: "操作员",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "描述"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };