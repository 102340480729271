import BatchReviewCommon from '../components/batchReviewCommon';
export default {
  components: {
    BatchReviewCommon
  },
  name: 'transactionReviewIndex',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.child.getList();
    });
  },
  data() {
    return {
      repayType: 0
    };
  }
};