import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 获取我的账户信息
 */
export const getAccountInfoReq = params => {
  return request({
    url: '/account/account/info',
    method: 'post',
    data: params
  });
};

/**
 * 获取账户列表
 */
export const getAccountListReq = params => {
  return request({
    url: '/account/account/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取我的账户信息列表
 */
export const getAccountFlowListReq = params => {
  return request({
    url: '/account/accountFlow/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载账户明细记录
 */
export const downloadAccountDetailRecords = params => {
  return download({
    url: '/account/accountFlow/download',
    data: params
  });
};

/**
 * 下载账户查询记录
 */
export const downloadAccountRecords = params => {
  return download({
    url: '/account/account/download',
    data: params
  });
};

/**
 * 退款复核审查
 */
export const refundAudit = params => {
  return request({
    url: '/trade/refund/audit',
    method: 'post',
    data: params
  });
};

/**
 * 下载退款订单记录
 */
export const downloadRefund = params => {
  return download({
    url: '/trade/refund/download',
    data: params
  });
};

/**
 * 获取退款订单详细信息
 */
export const refundInfo = id => {
  return request({
    url: '/trade/refund/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 获取退款订单列表信息
 */
export const refundList = params => {
  return request({
    url: '/trade/refund/list',
    method: 'post',
    data: params
  });
};

/**
 *新增退款记录
 */
export const saveRefund = params => {
  return request({
    url: '/trade/refund/save',
    method: 'post',
    data: params
  });
};

/**
 *付款预警-查看阀值
 */
export const querySetValueReg = () => {
  return request({
    url: '/account/paymentAlert/querySetValue',
    method: 'post'
  });
};
/**
 *获取付款预警列表信息
 */
export const paymentAlertListReg = params => {
  return request({
    url: '/account/paymentAlert/list',
    method: 'post',
    data: params
  });
};

/**
 * 付款预警-导出数据
 */
export const downloadPaymentAlert = params => {
  return download({
    url: '/account/paymentAlert/download',
    data: params
  });
};

/**
 *付款预警-设置阀值
 */
export const setValuePayment = params => {
  return request({
    url: '/account/paymentAlert/setValue',
    method: 'post',
    data: params
  });
};