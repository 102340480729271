import { RECHARGE_DETAIL_ID } from '../../../utils/idName';
import AccountRecordedIndex from '../recorded/list';
export default {
  name: 'recordeDetailList',
  components: {
    AccountRecordedIndex
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const orderNo = sessionStorage.getItem(RECHARGE_DETAIL_ID);
      vm.orderNo = orderNo;
      vm.$refs.child.getListByChild(orderNo);
    });
  },
  data() {
    return {
      orderNo: ''
    };
  }
};