import { showInfo } from '@/api/merchant';
import MerchantViewBase from '../merchant/components/view/viewBase';
import MerchantViewBankCard from '../merchant/components/view/viewBankCard';
import MerchantViewSignConfig from './components/viewSignConfig';
export default {
  components: {
    MerchantViewSignConfig,
    MerchantViewBankCard,
    MerchantViewBase
  },
  data() {
    return {
      active: 0,
      message: 'first',
      bean: {
        feeConfig: {},
        bankCardList: [],
        merchantBase: {
          merchantNo: ''
        },
        signConfigList: []
      },
      merchantNo: ''
    };
  },
  watch: {
    merchantNo(val) {
      this.merchantNo = val;
    }
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      showInfo().then(response => {
        that.bean = response.data;
        that.merchantNo = that.bean.merchantBase.merchantNo;
      });
    }
  }
};