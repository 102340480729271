import ItemTitle from '@/base/ui/title/itemTitle';
import { rechargeInfo, rechargeUpdate } from '@/api/transaction';
import { encrypt, decrypt } from "@/utils/encryptionAES";
export default {
  name: 'rechargeReview',
  components: {
    ItemTitle
  },
  data() {
    return {
      view: {},
      passDialogVisible: false,
      password: '',
      status: 0,
      reviewForm: {
        bankSerialNo: '',
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '处理意见不能为空',
          trigger: 'blur'
        }, {
          max: 80,
          message: '最多输入80个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    itemId() {
      return this.$route.query.id;
    }
  },
  created() {
    this.rechargeInfo();
  },
  methods: {
    rechargeInfo() {
      const params = {
        id: this.itemId
      };
      rechargeInfo(params).then(response => {
        this.view = response.data;
      });
    },
    pass() {
      this.rules.bankSerialNo = {
        required: true,
        message: '收款流水号不能为空',
        trigger: 'blur'
      };
      this.$refs['reviewForm'].validate(valid => {
        if (valid) {
          this.passDialogVisible = true;
          this.status = 3;
          this.password = '';
        } else {
          return false;
        }
      });
    },
    reject() {
      delete this.rules.bankSerialNo;
      this.$refs['reviewForm'].validate(valid => {
        if (valid) {
          this.passDialogVisible = true;
          this.status = 9;
          this.password = '';
          return;
        } else {
          return false;
        }
      });
    },
    rechargeUpdate() {
      if (!this.password) {
        this.$message.error('请输入确认密码！');
        return;
      }
      const pwd = encrypt(this.password);
      console.log(pwd);
      const params = {
        id: this.itemId,
        remark: this.reviewForm.remark,
        reviewPwd: pwd,
        status: this.status,
        bankSerialNo: this.reviewForm.bankSerialNo
      };
      rechargeUpdate(params).then(response => {
        this.goBack(status);
        this.passDialogVisible = false;
      }).catch(() => {
        this.passDialogVisible = false;
      });
    },
    cancel() {
      this.goBack();
    },
    goBack(status) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (status) {
          const message = status === 1 ? '已通过' : '已拒绝';
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};