// const template_url = 'static/template/EmpImportTemplate.xlsx'
import { getAcountInfo } from '../../../utils/auth';
const template_url = 'https://gyoss.inabei.cn/product/template/EmpImportTemplate.xlsx';
import { beforUploadByExcel } from '@/utils/util';
import UploadViewDialog from './uploadViewDialog';
import ItemTitle from '@/base/ui/title/itemTitle';
import { mchntImportPersonTaxSelect } from '@/api/taxsource';
export default {
  components: {
    UploadViewDialog,
    ItemTitle
  },
  data() {
    return {
      template_url: template_url,
      saveFile: process.env.BASE_API + '/employee/merchant/import',
      fileList: [],
      // employeeList: [],
      responseData: null,
      totalNum: 0,
      uploadViewFromVisible: false,
      activeNames: ['1'],
      taxSources: [],
      taxNo: '',
      dataErrorDialog: false,
      dataErrorDesc: ''
    };
  },
  props: {
    merchantNo: ''
  },
  computed: {
    uploadData: function () {
      return {
        merchantNo: this.merchantNo,
        taxNo: this.taxNo,
        partyCode: this.partyCode
      };
    }
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
    this.getTaxSources();
  },
  methods: {
    handleUploadSuccess(res, file) {
      //
      // if (file.response.code === 'BASE.000') {
      //   this.updateSuccess(file.response)
      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: file.response.errMsg
      //   })
      // }
      if (file.response.code === 'BASE.000') {
        if (file.response.data.errorDesc !== null && file.response.data.errorDesc !== '') {
          this.dataErrorDialog = true;
          this.dataErrorDesc = file.response.data.errorDesc;
        } else {
          this.updateSuccess(file.response);
        }
      } else {
        this.$message({
          type: 'error',
          message: file.response.errMsg
        });
      }
    },
    beforeAvatarUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    submitUpload() {
      // if (this.fileList.length === 0) {
      //   this.$message({
      //     type: 'error',
      //     message: '请选择文件'
      //   })
      // }
      this.uploadViewFromVisible = false;
      this.$refs.upload.submit();
    },
    updateSuccess(response) {
      this.uploadViewFromVisible = true;
      console.log(response.data);
      this.responseData = response.data;
      this.$message({
        message: '上传成功',
        type: 'success'
      });
    },
    changeVisibleUpdateView(val) {
      this.uploadViewFromVisible = val;
    },
    uploadSuccess(val) {
      this.clearData();
      this.$message({
        message: '导入成功,总记录数[' + val.totalNum + ']，成功[' + val.successNum + ']，失败[' + val.failureNum + ']',
        type: 'success'
      });
    },
    uploadError() {
      this.clearData();
      this.$message({
        message: '导入失败',
        type: 'error'
      });
    },
    uploadCancel() {
      this.clearData();
      this.$message({
        message: '取消导入',
        type: 'view.vue'
      });
    },
    clearData() {
      this.fileList = [];
      this.responseData = null;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      mchntImportPersonTaxSelect().then(response => {
        this.taxSources = response.data;
      });
    },
    sureButton() {
      this.dataErrorDialog = false;
      this.clearData();
    }
  }
};