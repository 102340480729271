import ItemTitle from '../../base/ui/title/itemTitle';
import { mchntProjectInfo, delBankCard } from '@/api/merchant';
import table from '@/utils/table';
import AddBankCardDialog from './components/addBankCardDialog';
import { getAcountInfo } from '@/utils/auth';
export default {
  components: {
    AddBankCardDialog,
    ItemTitle
  },
  data() {
    return {
      partyCode: getAcountInfo().partyCode,
      bankCardList: [],
      dialogFormVisible: false,
      activeNames: ['1']
    };
  },
  created() {
    this.freshData();
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, 1, 100);
    },
    freshData() {
      const that = this;
      const params = {
        projectNo: this.partyCode
      };
      console.log(params);
      mchntProjectInfo(params).then(response => {
        that.bankCardList = response.data.bankCardList;
      });
    },
    changeVisibleAdd(val) {
      // 监听新增dialog的dialogFormVisible的变化
      this.dialogFormVisible = val;
      if (val === false) {
        this.freshData();
      }
    },
    handleAdd() {
      if (this.bankCardList.length >= 10) {
        this.$message({
          type: 'error',
          message: '企业已添加充值账户达10个，请选择删除后再添加!'
        });
        return;
      }
      this.dialogFormVisible = true;
    },
    deleteCard(row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        delBankCard(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.freshData();
        });
      }).catch(() => {});
    }
  }
};