var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "退款订单信息"
    }
  }), _vm._v(" "), _c("el-form", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      model: _vm.view,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款订单号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.bussincessNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款状态："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$formatType("refundStatus", _vm.view.status)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退回账户名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.accountName))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退回银行账户："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.accountNo))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款金额(元)："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.view.refundAmount)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "调账金额(元)："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.view.adjustAmount)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "提交时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.createTime))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "完成时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.updateTime))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款原因："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.remark))])])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.view.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.view.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };