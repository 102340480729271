import ItemTitle from '@/base/ui/title/itemTitle';
import { invoiceAudit } from "@/api/teamInvoice";
export default {
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    info: {
      type: Object
    }
  },
  data() {
    return {
      addForm: {
        auditRemark: ''
      },
      loading: false,
      rules: {
        auditRemark: [
          // { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.invoiceAuditAPI(1);
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      // todo：拒绝时审核意见必填
      if (!this.addForm.auditRemark) {
        this.$message.error('审计拒绝时备注信息必填！');
        return;
      }
      this.invoiceAuditAPI(8);
    },
    dialogClosed() {
      // this.$refs['addForm'].resetFields()
      this.$refs['addForm'].clearValidate();
    },
    invoiceAuditAPI(status) {
      this.loading = true;
      invoiceAudit({
        auditRemark: this.addForm.auditRemark,
        id: this.info.id,
        status
      }).then(() => {
        this.dialogVisible = false;
        this.loading = false;
        this.$emit('doRefresh');
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};