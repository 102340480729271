import request from '@/utils/request';
import download from '@/utils/download';

/**
 * /report/projectReportList 项目资金发票报表
 * @param param
 */
export const projectReportList = param => {
  return request({
    url: '/report/projectReportList',
    method: 'post',
    data: param
  });
};

/**
 * /report/taxsReportList 劳务公司资金发票报表
 * @param param
 */
export const taxsReportList = param => {
  return request({
    url: '/report/taxsReportList',
    method: 'post',
    data: param
  });
};

/**
 * /report/teamReportList 班组资金发票报表
 * @param param
 */
export const teamReportList = param => {
  return request({
    url: '/report/teamReportList',
    method: 'post',
    data: param
  });
};

/**
 * /report/downProjectReportList 导出项目资金数据报表
 * @param param
 */
export const downProjectReportList = param => {
  return download({
    url: '/report/downProjectReportList',
    data: param
  });
};

/**
 * /report/downTaxsReportList 导出劳务公司资金数据报表
 * @param param
 */
export const downTaxsReportList = param => {
  return download({
    url: '/report/downTaxsReportList',
    data: param
  });
};

/**
 * /report/downTeamReportList 导出班组资金数据报表
 * @param param
 */
export const downTeamReportList = param => {
  return download({
    url: '/report/downTeamReportList',
    data: param
  });
};