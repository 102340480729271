import { isJobOpen } from '@/api/merchant';
import JobOpen from '@/base/ui/jobOpen';
import AddContent from './addContent';
export default {
  name: 'addTaskIndex',
  components: {
    AddContent,
    JobOpen
  },
  data() {
    return {
      loading: true,
      isOpen: false
    };
  },
  created() {
    this.getJobOpen();
  },
  methods: {
    getJobOpen() {
      this.loading = true;
      isJobOpen().then(response => {
        this.loading = false;
        this.isOpen = response.data.jobOpen === 1;
      });
    }
  }
};