import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 首页BI汇总(交易额、商户数、成交动态、交易排行、商户评分)
 */
export const getStatIndex = () => {
  return request({
    url: '/stat/index/index',
    method: 'post'
  });
};

/**
 * 获取交易额趋势(日、周、月、年)，参数{type:'1-日，2-周，3-月，4-年'}
 */
export const getStatTradeInfo = type => {
  return request({
    url: '/stat/index/tradeInfo',
    method: 'post',
    data: {
      type
    }
  });
};

/**
 * 获取商户交易额统计的列表
 */
export const getStatMerchantRechargeList = params => {
  return request({
    url: '/stat/statMerchantRecharge/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载商户交易额统计列表
 */
export const downloadStatMerchant = params => {
  return download({
    url: '/stat/statMerchantRecharge/download',
    data: params
  });
};

/**
 * 获取商户交易额统计的图标
 */
export const getStatMerchantRechargeCharts = params => {
  return request({
    url: '/stat/statMerchantRecharge/tradeCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取商户评级的列表
 */
export const getStatMerchantGradeList = params => {
  return request({
    url: '/stat/statMerchantGrade/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载商户评级列表
 */
export const downloadStatGrade = params => {
  return download({
    url: '/stat/statMerchantGrade/download',
    data: params
  });
};

/**
 * 获取商户评级的图标
 */
export const getStatMerchantGradeCharts = params => {
  return request({
    url: '/stat/statMerchantGrade/gradeCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取区域交易额统计列表
 */
export const getTaxAreaList = params => {
  return request({
    url: '/stat/statTaxsourceArea/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载区域交易额统计列表
 */
export const downloadTaxArea = params => {
  return download({
    url: '/stat/statTaxsourceArea/download',
    data: params
  });
};

/**
 * 获取区域交易额统计图标
 */
export const getTaxAreaCharts = params => {
  return request({
    url: '/stat/statTaxsourceArea/areaCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取客户经理交易额统计列表
 */
export const getSaleManTradList = params => {
  return request({
    url: '/stat/statSalemanTrade/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载客户经理交易额统计列表
 */
export const downloadSaleManTrad = params => {
  return download({
    url: '/stat/statSalemanTrade/download',
    data: params
  });
};

/**
 * 获取客户经理交易额统计图标
 */
export const getSaleManTradCharts = params => {
  return request({
    url: '/stat/statSalemanTrade/areaCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取合伙人交易额统计列表
 */
export const getAgentRechargeList = params => {
  return request({
    url: '/stat/statAgentRecharge/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载合伙人交易额统计列表
 */
export const downloadAgentRecharge = params => {
  return download({
    url: '/stat/statAgentRecharge/download',
    data: params
  });
};

/**
 * 获取合伙人交易额统计图标
 */
export const getAgentRechargeCharts = params => {
  return request({
    url: '/stat/statAgentRecharge/tradeCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取园区交易额统计列表
 */
export const getTaxSummaryList = params => {
  return request({
    url: '/stat/statTaxsourceTrade/summarylist',
    method: 'post',
    data: params
  });
};

/**
 * 下载园区交易额统计列表
 */
export const downloadTaxSummary = params => {
  return download({
    url: '/stat/statTaxsourceTrade/summaryDownload',
    data: params
  });
};

/**
 * 获取园区交易额统计图标
 */
export const getTaxSummaryCharts = params => {
  return request({
    url: '/stat/statTaxsourceTrade/summaryCharts',
    method: 'post',
    data: params
  });
};

/**
 * 获取园区月交易额统计列表
 */
export const getTaxMounthSummaryList = params => {
  return request({
    url: '/stat/statTaxsourceTrade/monthSummarylist',
    method: 'post',
    data: params
  });
};

/**
 * 下载园区月交易额统计列表
 */
export const downloadTaxMounthSummary = params => {
  return download({
    url: '/stat/statTaxsourceTrade/monthSummaryDownload',
    data: params
  });
};

/**
 * 获取园区月交易额统计图标
 */
export const getTaxMounthSummaryCharts = params => {
  return request({
    url: '/stat/statTaxsourceTrade/monthSummaryCharts',
    method: 'post',
    data: params
  });
};

/**
 * 最新交易额统计列表
 */
export const getLatelyRechargeList = params => {
  return request({
    url: '/stat/statLatelyRecharge/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载最新交易额统计列表
 */
export const downloadLatelyRecharge = params => {
  return download({
    url: '/stat/statLatelyRecharge/download',
    data: params
  });
};
/**
 * 获取平台月收益统计汇总计列表
 */
export const getPlatformIncomeList = params => {
  return request({
    url: '/stat/statPlatformIncome/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载平台月收益统计汇总列表
 */
export const downloadPlatformIncome = params => {
  return download({
    url: '/stat/statPlatformIncome/download',
    data: params
  });
};

/**
 * 获取平台月收益统计汇总图标
 */
export const getPlatformIncomeCharts = params => {
  return request({
    url: '/stat/statPlatformIncome/summaryCharts',
    method: 'post',
    data: params
  });
};
/**
 * 获取园区月收益统计汇总计列表
 */
export const getTaxIncomeList = params => {
  return request({
    url: '/stat/statTaxsourceIncome/list',
    method: 'post',
    data: params
  });
};

/**
 * 下载园区月收益统计汇总列表
 */
export const downloadTaxIncome = params => {
  return download({
    url: '/stat/statTaxsourceIncome/download',
    data: params
  });
};

/**
 * 获取园区月收益统计汇总图标
 */
export const getTaxIncomeCharts = params => {
  return request({
    url: '/stat/statTaxsourceIncome/summaryCharts',
    method: 'post',
    data: params
  });
};