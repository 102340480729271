var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "65%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "导入任务匹配"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addForm",
    staticClass: "form-table",
    attrs: {
      size: "mini",
      "label-position": "left",
      "label-width": "100px",
      rules: _vm.rules,
      model: _vm.addForm
    }
  }, [_vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属商户"
    },
    model: {
      value: _vm.addForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "merchantNo", $$v);
      },
      expression: "addForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.partyType !== 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.addForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "taxNo", $$v);
      },
      expression: "addForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传附件信息：",
      prop: "saveKey"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadMatchRecords,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      "on-success": _vm.handleSuccess,
      data: _vm.exData,
      "on-change": _vm.changeFiles,
      "auto-upload": false,
      limit: 1
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_label",
    attrs: {
      type: "primary",
      href: _vm.templateUrl,
      target: "_blank",
      download: "模版"
    }
  }, [_vm._v("下载模板")]), _vm._v(" "), _c("div", {
    staticClass: "title-span"
  }, [_vm._v("只能上传excel文件，文件数据不超过10000条，且大小不超过300M。\n          重复提交将覆盖原有记录数据。")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.dialogClosed
    }
  }, [_vm._v("取消")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("item-title", {
    attrs: {
      name: "操作指引"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "1.4rem",
      "line-height": "25px"
    }
  }, [_c("div", [_vm._v("1.通过票据关联-导出开票关联的发放记录；")]), _vm._v(" "), _c("div", [_vm._v("2.请将导出的任务记录表格中未填充的信息填充完毕，并按提交记录整理好相关附件；")]), _vm._v(" "), _c("div", [_vm._v("3.发放记录处理后的任务匹配记录，每列格式必须与模板保持一致，否则无法识别")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };