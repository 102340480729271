import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn/index';
import { invoiceAssociateInfoReg } from '../../../api/invoice';
import { ASSOCIATE_INFO_ID } from '@/utils/idName';
import Pagination from '@/components/pagination/index';
export default {
  components: {
    Pagination,
    DetailColumn,
    ItemTitle
  },
  data() {
    return {
      id: '',
      info: {},
      importVisible: false,
      downloadVisible: false,
      list: [],
      pageNo: 0,
      pageSize: 10,
      total: 0,
      listLoading: false,
      amountTotal: 0,
      multipleSelection: []
    };
  },
  created() {
    this.id = sessionStorage.getItem(ASSOCIATE_INFO_ID, 0);
    this.getQuery();
  },
  methods: {
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      invoiceAssociateInfoReg({
        id: this.id,
        isDetail: 1,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.info = response.data;
        this.list = response.data.pageList.list;
        this.total = response.data.pageList.totalCount;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};