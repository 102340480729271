import { constantRouterMap } from '@/router';
// import axios from 'axios'
import { getRouteList } from '@/api/login';
const permission = {
  state: {
    routers: [],
    addRouters: [],
    buttons: {},
    alreadyFetchRouter: false
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.alreadyFetchRouter = true;
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons;
    }
  },
  actions: {
    StoreRoutes({
      commit
    }, accessedRouters) {
      return new Promise(resolve => {
        commit('SET_ROUTERS', accessedRouters);
        const buttonMap = fetchButton4Menus(accessedRouters, '');
        commit('SET_BUTTONS', buttonMap);
        resolve();
      });
    },
    GenerateRoutes({
      commit
    }) {
      return new Promise(resolve => {
        getRouteList().then(res => {
          resolve(res.data);
        });
        // axios.get('https://www.easy-mock.com/mock/5accbc12b7b3be6bd9b3758e/readhub/routelist').then(response => {
        //   const data = response.data.data
        //   resolve(data)
        // })
      });
    }
  }
};
function fetchButton4Menus(menus, path) {
  var map = new Map();
  menus.forEach(menu => {
    if (menu.children && menu.children.length > 0) {
      // 菜单有重定向
      const k = menu.redirect && menu.redirect !== 'noredirect' && menu.redirect.length > 0 ? menu.redirect : menu.path;
      // 按钮及子页面的key
      const childKey = formatPath(path, menu.path);
      menu.children.forEach(item => {
        if (item.type && item.type === 2 && item.path && item.path.length > 0) {
          // 2为buttton
          const key = formatPath(path, k);
          const vv = formatPath(childKey, item.path);
          let v = map.get(key);
          // map对应的key 处理动态路由的情况
          const vk = handleDynamicPath(item.path);
          if (!v) {
            v = new Map();
            v.set(vk, vv);
            map.set(key, v);
          } else {
            v.set(vk, vv);
          }
        }
      });
      const temp = fetchButton4Menus(menu.children, menu.path);
      for (var [key, value] of temp) {
        map.set(key, value);
      }
    }
  });
  return map;
}
function handleDynamicPath(path) {
  let index = path.indexOf('/:');
  if (index > -1) {
    const prefix = path.substring(0, index);
    const subString = prefix.substring(index, path.length);
    index = subString.indexOf('/');
    return prefix.concat(subString.substring(index + 1, subString.length));
  }
  return path;
}
function formatPath(path, subPath) {
  if (!subPath.startsWith('/')) {
    subPath = '/' + subPath;
  }
  const count = subPath.split('/').filter(one => {
    return one === path;
  }).length;
  return count > 0 ? subPath : path + subPath;
}
export default permission;