var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bank-item-container"
  }, [_vm.icon ? _c("el-image", {
    staticStyle: {
      width: "20px",
      height: "20px"
    },
    attrs: {
      src: _vm.icon,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };