import { ACCOUNT_DETAIL_ID } from '@/utils/idName';
import { mapGetters } from 'vuex';
export default {
  name: 'account',
  data() {
    return {
      hasWithdrawPermission: false
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasWithdrawPermission = map.has('withdraw');
    } else {
      this.hasWithdrawPermission = false;
    }
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  props: {
    account: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: [String, Number]
    },
    detailPath: {
      required: true,
      type: String
    }
  },
  methods: {
    viewDetail() {
      sessionStorage.setItem(ACCOUNT_DETAIL_ID, this.account.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    withdraw() {
      this.$emit('withdraw', this.account);
    }
  }
};