import ItemTitle from "@/base/ui/title/itemTitle";
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { getMerchantTaskInfo, taskReview, taskCheckReview } from "../../../api/task";
import { TASK_INFO_ID } from "../../../utils/idName";
import { downloadFile } from "../../../api/upload";
import { downloadAllFile } from "../../../utils/util";
import DetailColumn from "../../../components/detailColumn/index";
export default {
  components: {
    DetailColumn,
    ItemTitle
  },
  data() {
    return {
      fileList: [],
      addForm: {
        caption: "",
        fileSaveKeys: [],
        taskName: "",
        taskType: "",
        startDate: "",
        endDate: "",
        taxNo: "",
        invitePublic: 0,
        pricingMode: "",
        singleAmount: "",
        submitMode: 0,
        id: "",
        logList: []
      },
      isReview: "",
      isCheckReview: "",
      reviewForm: {
        id: "",
        status: "",
        checkRemark: ""
      },
      rules: {
        status: [{
          required: true,
          message: "请选择审核结果",
          trigger: "blur"
        }]
      }
    };
  },
  created() {
    const id = sessionStorage.getItem(TASK_INFO_ID);
    this.isReview = this.$route.query.isReview;
    this.isCheckReview = this.$route.query.isCheckReview;
    this.reviewForm.id = id;
    console.log(id);
    getMerchantTaskInfo(id).then(response => {
      this.addForm = response.data;
      this.addForm.time = [response.data.startDate, response.data.endDate];
      this.addForm.fileSaveKeys = [];
      response.data.fileList.forEach(item => {
        const file = {
          name: item.fileName,
          url: item.fileUrlShow,
          response: {
            data: {
              saveKey: item.fileUrl
            },
            code: "BASE.000"
          }
        };
        this.fileList.push(file);
      });
    });
  },
  methods: {
    handleCancel() {
      this.$router.go(-1);
    },
    handleReview() {
      this.$refs["reviewForm"].validate(async valid => {
        if (valid) {
          this.$confirm("是否确定提交？", "温馨提示", {
            type: "warning"
          }).then(async () => {
            const res = this.isCheckReview != "1" ? await taskReview(this.reviewForm) : await taskCheckReview(this.reviewForm);
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.goToList();
          });
        }
      });
    },
    goToList() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.push("/task/taskRecord");
      });
    },
    downloadUrl(item) {
      const that = this;
      downloadFile(item.fileUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename, that);
      });
    },
    amountValue() {
      if (this.addForm.singleMaxAmount > 0) {
        return this.$fmoneyWithSymbol(this.addForm.singleAmount) + "-" + this.$fmoneyWithSymbol(this.addForm.singleMaxAmount);
      } else {
        return this.$fmoneyWithSymbol(this.addForm.singleAmount);
      }
    }
  }
};