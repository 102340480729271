var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-menu", {
    staticClass: "navbar",
    attrs: {
      mode: "horizontal"
    }
  }, [_c("nx-hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      toggleClick: _vm.toggleSideBar,
      isActive: _vm.sidebar.opened
    }
  }), _vm._v(" "), _c("nx-breadcrumb", {
    staticClass: "breadcrumb-container"
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("欢迎 " + _vm._s(_vm.userName) + " (" + _vm._s(_vm.realName) + ") 登录管理平台！")]), _vm._v(" "), _vm.isShowMsgCenter() ? _c("div", {
    staticClass: "navbar-line"
  }) : _vm._e(), _vm._v(" "), _vm.isShowMsgCenter() ? _c("div", {
    staticClass: "item-badge",
    on: {
      click: _vm.gotoMsgCenter
    }
  }, [_c("el-badge", {
    attrs: {
      "is-dot": _vm.noReadNum !== 0 && _vm.noReadNum !== ""
    }
  }, [_c("el-image", {
    staticClass: "msg-img",
    attrs: {
      src: "static/img/msg.png"
    }
  })], 1), _vm._v(" "), _vm.noReadNum !== 0 && _vm.noReadNum !== "" ? _c("div", [_vm._v("（"), _c("span", {
    staticClass: "read-class"
  }, [_vm._v(_vm._s(_vm.noReadNum))]), _vm._v("）")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticStyle: {
      color: "#E3530A"
    },
    attrs: {
      round: "",
      size: "mini"
    },
    on: {
      click: _vm.logout
    }
  }, [_vm._v("\n      退出\n    ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };