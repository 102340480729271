var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商名称："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.agentName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商类型："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatAgentTypeStatutes()) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_vm.saveForm.agentType !== 0 ? _c("el-form-item", {
    attrs: {
      label: "法人姓名："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.legalPerson) + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_vm.saveForm.agentType !== 0 ? _c("el-form-item", {
    attrs: {
      label: "证件号码："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.legalPersonIdcard) + "\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名：",
      prop: "contacts"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.contacts) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机：",
      prop: "contactNumber"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.contactNumber) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上级服务商："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.superiorAgentName) + "（" + _vm._s(_vm.saveForm.superiorAgent) + "）\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商属性："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("agentLevelStatutes", _vm.saveForm.agentLevel)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属客户经理："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.salesmanName) + "(" + _vm._s(_vm.saveForm.salesmanPhone) + ")\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatCity()) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "协议有效期："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.protocolStart) + "至" + _vm._s(_vm.saveForm.protocolEnd) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系地址："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.address) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "协议附件："
    }
  }, [_c("div", {
    staticClass: "link-view"
  }, _vm._l(_vm.saveForm.fileList, function (item, index) {
    return _c("el-link", {
      key: index,
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.downloadUrl(item);
        }
      }
    }, [_vm._v("\n              " + _vm._s(item.fileName) + "\n            ")]);
  }), 1)])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_vm.saveForm.agentType !== 0 ? _c("el-form-item", {
    attrs: {
      label: "统一信用代码："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.licenseNo) + "\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_vm.saveForm.agentType !== 0 ? _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照："
    }
  }, [_vm.saveForm.licensePicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.licensePicShow,
      "preview-src-list": [_vm.saveForm.licensePicShow]
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证："
    }
  }, [_vm.saveForm.legalPersonIdcardpic ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.legalPersonIdcardpicShow,
      "preview-src-list": [_vm.saveForm.legalPersonIdcardpicShow]
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };