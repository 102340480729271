import { getStatTradeInfo } from '../../../../api/stat';
import DbsHistogram from '../../../../components/chart/dbs-histogram';
export default {
  name: 'transaction-histogram',
  components: {
    DbsHistogram
  },
  data() {
    const chartSettings = {
      itemStyle: {
        color: '#2DA8FF'
      },
      labelMap: {
        'million': '金额'
      },
      legendName: {
        'million': '金额'
      }
    };
    return {
      clickIndex: 4,
      chartData: {
        columns: ['columnName', 'million'],
        rows: []
      },
      chartSettings: chartSettings
    };
  },
  methods: {
    freshData(index) {
      this.clickIndex = index;
      this.getList(index);
    },
    getList(type) {
      getStatTradeInfo(type).then(response => {
        this.chartData.rows = response.data;
      });
    },
    afterSetOption(chartObj) {
      chartObj.setOption({
        xAxis: {
          axisLabel: {
            interval: 0
          }
        }
      });
    }
  },
  created() {
    this.getList(4);
  }
};