import Pagination from '@/components/pagination';
import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import ElLink from 'element-ui/packages/link/src/main';
import { TASK_AUDIT_ID } from '@/utils/idName';
import { auditTaskRecord, getTaskRecordInfo, getVoucherRecordList } from '../../../api/task';
import { isMoneyNumber } from '../../../utils/validate_rules';
export default {
  name: 'saveRecordAudit',
  components: {
    ElLink,
    ItemTitle,
    DetailColumn,
    Pagination
  },
  data() {
    return {
      recordDetailAddress: process.env.RECORD_DETAIL_ADDRESS,
      listData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      infoForm: {
        taskFileList: []
      },
      id: '',
      auditForm: {
        auditRemark: '',
        status: '',
        auditAmount: ''
      },
      rules: {
        auditRemark: [{
          required: true,
          message: '请输入任务审核意见',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }],
        auditAmount: [{
          required: true,
          message: '请输入确认任务金额',
          trigger: 'change'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.id = sessionStorage.getItem(TASK_AUDIT_ID);
      vm.getDetail();
    });
  },
  methods: {
    goDetails(row) {
      let tempUrl = '';
      switch (row.templeteNo) {
        case 'TN001':
          tempUrl = 'conceptTable';
          break;
        case 'TN002':
          tempUrl = 'potentialityTable';
          break;
        case 'TN003':
          tempUrl = 'productAwarenessTable';
          break;
        case 'TN004':
          tempUrl = 'hospitalInfoTable';
          break;
        case 'TN005':
          tempUrl = 'trainTable';
          break;
        case 'TN006':
          tempUrl = 'visitTable';
          break;
        case 'TN007':
          tempUrl = 'competitorTable';
          break;
        case 'TN008':
          tempUrl = 'productTable';
          break;
      }
      const left = (screen.availWidth - 500) / 2;
      const top = (screen.availHeight - 300) / 2;
      window.open(this.recordDetailAddress + tempUrl + '?voucherData=' + encodeURIComponent(row.voucherData), '_blank', 'width=1100,height=800,top=' + top + ',left=' + left + ',toolbar=no,menubar=no,location=no,status=yes');
      // window.open('http://localhost:9529/auth/#/' + tempUrl + '?voucherData=' + encodeURIComponent(row.voucherData), '_blank', 'width=1100,height=800,top=' + top + ',left=' + left + ',toolbar=no,menubar=no,location=no,status=yes')
    },
    getList() {
      this.listLoading = true;
      getVoucherRecordList({
        recordNo: this.infoForm.recordNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listData = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    // 审核
    check() {
      this.$refs['auditForm'].validate(valid => {
        if (valid) {
          auditTaskRecord({
            auditRemark: this.auditForm.auditRemark,
            status: this.auditForm.status,
            auditAmount: this.auditForm.auditAmount,
            id: this.id
          }).then(() => {
            this.$message.success('处理成功');
            this.goBack();
          });
        } else {
          return;
        }
      });
    },
    getDetail() {
      getTaskRecordInfo(this.id).then(response => {
        this.infoForm = response.data;
        // debugger

        const money = response.data.singleAmount;
        const singleAmount = this.$fmoneyWithSymbol(this.infoForm.singleAmount);
        if (this.infoForm.singleMaxAmount > 0) {
          const singleMaxAmount = this.$fmoneyWithSymbol(this.infoForm.singleMaxAmount);
          this.infoForm.singleAmount = singleAmount + '-' + singleMaxAmount;
        } else {
          this.infoForm.singleAmount = singleAmount;
          this.auditForm.auditAmount = money;
        }
        if (this.infoForm.submitType == 2) {
          this.getList();
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    // 刷新
    updated() {
      this.getDetail();
    }
  }
};