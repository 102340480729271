import { payVerifyCode } from '../../api/verifyCode';
import { PAY_VERIFY_CODE } from '../../utils/idName';
const MSGINIT = '发送验证码';
// const MSGERROR = '验证码发送失败'
const MSGSCUCCESS = '${time}秒后重发';
const MSGTIME = 60;
export default {
  name: 'VerifyCode',
  data() {
    return {
      verifyCode: '',
      taxBrand: process.env.TAX_BRAND,
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false
    };
  },
  props: {
    type: {
      required: true
    },
    phone: {
      required: true
    }
  },
  methods: {
    handleSend() {
      if (this.msgKey) {
        return;
      }
      switch (this.type) {
        case PAY_VERIFY_CODE:
          this.payVerifyCodeType();
          break;
      }
    },
    payVerifyCodeType() {
      const phone = this.phone;
      const taxBrand = this.taxBrand;
      payVerifyCode(phone, taxBrand).then(res => {
        this.timeCount(res);
      });
    },
    timeCount(res) {
      if (this.msgKey) return;
      this.$message({
        type: 'success',
        message: res.data
      });
      this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime);
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime);
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME;
          this.msgText = MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    }
  }
};