var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("div", {
    staticClass: "segement-border"
  }, [_c("item-title", {
    attrs: {
      name: "批量出款确认"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    staticClass: "form-table",
    attrs: {
      size: "mini",
      "label-position": "left",
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    staticClass: "upload",
    attrs: {
      label: "上传确认出款清单"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadApplyFile,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      "on-success": _vm.handleDataList,
      data: _vm.exData,
      "auto-upload": false
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("上传到服务器")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 只能上传xlsx/xls，数据记录最多不能超过1000条且文件大小不超过10M")])], 1), _vm._v(" "), _c("batch-preview-payment", {
    attrs: {
      isVisible: _vm.isDialogVisible,
      transactionData: _vm.responseData
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      "on-success": _vm.uploadSuccess,
      "on-error": _vm.uploadError,
      "on-cancel": _vm.uploadCancel
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "space-line"
  }, [_c("item-title", {
    attrs: {
      name: "批量出款确认指导"
    }
  })], 1), _vm._v(" "), _vm._m(0)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "65%",
      title: "文件错误提示信息",
      visible: _vm.idCardRepeatDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.idCardRepeatDialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("\n        上传文件存在如下数据格式错误，请修正后重新提交！\n      ")]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-col", {
    staticStyle: {
      "font-size": "medium"
    },
    attrs: {
      span: 3
    }
  }, [_vm._v("\n          错误描述：\n        ")]), _vm._v(" "), _c("el-col", {
    staticClass: "border-spec",
    attrs: {
      span: 16
    }
  }, [_c("el-scrollbar", [_c("p", {
    staticStyle: {
      "white-space": "pre-line",
      height: "100px",
      overflow: "visible"
    }
  }, [_vm._v(" " + _vm._s(_vm.repeatDesc) + " ")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.idCardDialogCancel();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      "font-size": "1.4rem"
    }
  }, [_c("div", [_vm._v("1、请先前往付款记录页面，通过“人工出款清单导出”按钮导出需要人工线下转账出款的清单")]), _vm._v(" "), _c("div", [_vm._v("2、完成线下转账后，请将出款结果、出款通道名、出款流水号、失败原因按文件要求回调至导出的“人工出款清单”")]), _vm._v(" "), _c("div", [_vm._v("3、在本页面点击选取文件，选择已填写完并保存的表格进行导入")]), _vm._v(" "), _c("div", [_vm._v("4、导入后点击上传至服务器，上传完成后会弹出预览窗口，"), _c("span", {
    staticStyle: {
      color: "#FF4C4C"
    }
  }, [_vm._v(" 请确认预览的代发记录是否准确，")]), _vm._v("点击确认即可完成批量出款确认操作")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };