import Vue from 'vue';
import Router from 'vue-router';

// const _import = require('./_import_' + process.env.NODE_ENV)
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from '../base/ui/layout/Layout';

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [{
  path: '/login',
  component: () => new Promise(resolve => {
    require.ensure([], require => {
      resolve(require('@/views/login/index'));
    });
  }),
  name: '登录',
  hidden: true
}, {
  path: '/404',
  component: () => new Promise(resolve => {
    require.ensure([], require => {
      resolve(require('@/views/errorPage/404'));
    });
  }),
  hidden: true
}, {
  path: '/401',
  component: () => new Promise(resolve => {
    require.ensure([], require => {
      resolve(require('@/views/errorPage/401'));
    });
  }),
  hidden: true
}, {
  path: '/forgetPwd',
  component: () => new Promise(resolve => {
    require.ensure([], require => {
      resolve(require('@/views/login/forgetPwd'));
    });
  }),
  name: '忘记密码',
  hidden: true
},
// 首页
{
  path: '',
  component: Layout,
  meta: {
    title: '首页'
  },
  hidden: true,
  children: [{
    path: '',
    name: 'home',
    component: () => new Promise(resolve => {
      require.ensure([], require => {
        resolve(require('@/views/first/index'));
      });
    }),
    meta: {
      title: '首页'
    }
  }]
}];
export default new Router({
  mode: 'hash',
  // 后端支持可开
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
});
export const asyncRouterMap = [{
  path: '/error',
  component: Layout,
  redirect: 'noredirect',
  name: 'errorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '401',
    component: () => new Promise(resolve => {
      require.ensure([], require => {
        resolve(require('@/views/errorPage/401'));
      });
    }),
    name: 'page401',
    meta: {
      title: 'page401',
      noCache: true
    }
  }, {
    path: '404',
    component: () => new Promise(resolve => {
      require.ensure([], require => {
        resolve(require('@/views/errorPage/404'));
      });
    }),
    name: 'page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];