var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "upload-box"
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "on-exceed": _vm.handleExceed,
      action: _vm.action,
      data: _vm.extendData,
      multiple: "",
      limit: _vm.limit,
      "file-list": _vm.fileList,
      "before-upload": _vm.beforeDemoUpload,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      "on-error": _vm.handleError,
      disabled: _vm.disabled
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("点击上传")]), _vm._v(" "), _vm.fileType === 1 ? _c("div", {
    staticClass: "title-span"
  }, [_vm._v("最多上传" + _vm._s(_vm.limit) + "个，单个文件大小不超过10M")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };