var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("transaction-apply", {
    ref: "child",
    attrs: {
      repayType: _vm.repayType
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };