var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.handleClose,
      visible: _vm.dialogFormVisible,
      "close-on-click-modal": false
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "班组编号:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组长姓名:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司:",
      "label-width": _vm.formLabelWidth,
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "请选择签约劳务公司",
      size: "mini"
    },
    on: {
      change: _vm.mchntProjectSignProjectListAPI
    },
    model: {
      value: _vm.form.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "taxNo", $$v);
      },
      expression: "form.taxNo"
    }
  }, _vm._l(_vm.companyList, function (item) {
    return _c("el-option", {
      key: item.taxNo,
      attrs: {
        label: item.taxName,
        value: item.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目:",
      "label-width": _vm.formLabelWidth,
      prop: "projectNo"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "请选择签约项目",
      size: "mini"
    },
    model: {
      value: _vm.form.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "projectNo", $$v);
      },
      expression: "form.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: item.projectName,
        value: item.projectNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };