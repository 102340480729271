var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "基本信息",
      name: "first"
    }
  }, [_c("merchant-view-base", {
    attrs: {
      saveForm: _vm.bean.merchantBase
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "签约信息",
      name: "third"
    }
  }, [_c("merchant-view-sign-config", {
    attrs: {
      merchantNo: _vm.merchantNo,
      signConfigList: _vm.bean.signConfigList
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };