import { submitTaskLibApplyReq, getCategoryByBillReq } from '@/api/invoice';
import { queryMakeInvoiceContents } from '@/api/common';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'gatherInvoice',
  components: {
    ItemTitle
  },
  props: {
    amount: {
      required: true,
      type: [Number, String]
    },
    records: {
      required: true,
      type: [Array]
    }
  },
  data() {
    return {
      addForm: {
        invoiceType: '',
        contents: '',
        remark: ''
      },
      contentList: [],
      rules: {
        contents: [{
          required: true,
          message: '请选择开票内容',
          trigger: 'change'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'change'
        }]
      },
      makeInvoiceInfo: {},
      category: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.makeInvoiceInfo = JSON.parse(sessionStorage.getItem('makeInvoice'));
      this.getContents();
      this.getInvoiceType();
    },
    getInvoiceType() {
      this.addForm.invoiceType = 1;
      /*
      // 0-差额发票，1-全额发票
      if (this.makeInvoiceInfo.merchantType == 0) {
        this.addForm.invoiceType = 2
      } else {
        if (this.makeInvoiceInfo.taxpayerType == 0) {
          this.addForm.invoiceType = ''
        } else {
          this.addForm.invoiceType = 0
        }
      }
      */
    },
    contentsChange(item) {
      getCategoryByBillReq({
        contentName: item
      }).then(res => {
        if (res.data && Array.isArray(res.data)) {
          this.category = res.data.join('，');
        }
      });
    },
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.makeInvoice();
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.$emit('cancel');
    },
    makeInvoice() {
      const ticketAmount = this.amount.toFixed(2);
      const param = {
        ...this.addForm,
        ticketAmount: ticketAmount,
        ticketNum: this.records.length,
        paymentIdList: this.records,
        addressId: this.makeInvoiceInfo.addressId,
        merchantNo: this.makeInvoiceInfo.merchantNo,
        taxNo: this.makeInvoiceInfo.taxNo
      };
      submitTaskLibApplyReq(param).then(() => {
        this.$message.success('开票申请成功...');
        this.$emit('submit');
      });
    },
    getContents() {
      // const code = (this.merchantType === '0' || this.merchantType === 0) ? 'biz_invoice_diff' : 'biz_invoice_content'
      if (this.makeInvoiceInfo && this.makeInvoiceInfo.taxNo) {
        // queryMakeInvoiceContents(this.makeInvoiceInfo.taxNo, this.makeInvoiceInfo.merchantType).then((response) => {
        queryMakeInvoiceContents(this.makeInvoiceInfo.taxNo, 1).then(response => {
          this.contentList = response.data;
        });
      }
    }
  }
};