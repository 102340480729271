import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { queryMerchantSignList, auditMerchantSign, freezeMerchantSign, unfreezeMerchantSign, queryTaxChannelList, updateTaxChannel, downloadMchntSignList } from '@/api/taxMerchant';
import { showlist } from '@/api/taxsource';
import { mapGetters } from 'vuex';
import { MERCHANT_SIGN_INFO_ID, UPDATE_MERCHANT_SIGN_INFO_ID, MERCHANT_INFO_ID, PROJECT_NO } from '@/utils/idName'; // MERCHANT_SIGN_UPDATE_ID
import { downloadExcel } from '@/utils/util';
import { downloadFile } from '../../../api/upload';
import { downloadAllFile } from '../../../utils/util';
export default {
  components: {
    CityCascader,
    Pagination
  },
  name: 'merchantSignList',
  data() {
    return {
      show: false,
      auditDialogVisible: false,
      auditMerchantSignId: '',
      channelDialogVisible: false,
      currentSignId: '',
      currentChannelNo: '',
      total: 0,
      saveMerchantSign: '',
      viewMerchantSign: '',
      authMerchantSign: '',
      updateMerchantSign: '',
      forzenMerchantSign: '',
      downloadMerchantSign: '',
      updateSignChannel: '',
      viewMerchantInfo: '',
      list: [],
      listLoading: true,
      taxMerchantSignStatus: this.$formatTypeArray('taxMerchantSignStatus'),
      taxsourceList: [],
      taxChannelList: [],
      clickUpdate: false,
      searchForm: {
        merchantNo: '',
        merchantName: '',
        taxNo: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      editChannelForm: {
        id: '',
        channelNo: ''
      },
      editChannelRules: {
        channelNo: [{
          required: true,
          message: '请选择劳务公司通道',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateMerchantSign && this.clickUpdate) {
      to.meta.isFresh = true;
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.saveMerchantSign = map.get('saveMerchantSign');
      this.viewMerchantSign = map.get('viewMerchantProjectSign');
      this.authMerchantSign = map.get('authMerchantProjectSign');
      this.updateMerchantSign = map.get('updateMerchantProjectSign');
      this.forzenMerchantSign = map.get('forzenMerchantProjectSign');
      this.downloadMerchantSign = map.get('downloadMerchantProjectSign');
      this.updateSignChannel = map.get('updateSignChannel');
      this.viewMerchantInfo = map.get('viewMerchantProjectInfo');
    }
    const that = this;
    showlist().then(response => {
      that.taxsourceList = response.data;
    });
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatTaxChannel(taxChannel) {
      return `${taxChannel.channelName}(${taxChannel.channelNo})`;
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = false;
      queryMerchantSignList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        merchantNo: '',
        merchantName: '',
        taxNo: '',
        status: '',
        channelType: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    handleAdd() {
      this.$router.push({
        path: this.saveMerchantSign
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(UPDATE_MERCHANT_SIGN_INFO_ID, row.id);
      this.clickUpdate = true;
      this.$router.push({
        path: this.updateMerchantSign
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(MERCHANT_SIGN_INFO_ID, row.id);
      this.$router.push({
        path: this.viewMerchantSign
      });
    },
    // 显示查看项目详情界面
    handleMerchantView(index, row) {
      sessionStorage.setItem(PROJECT_NO, row.projectNo);
      this.$router.push({
        path: this.viewMerchantInfo
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatSignStatus(row, column) {
      return this.$formatType('taxMerchantSignStatus', row.status);
    },
    downloadProtocolUrl(row) {
      downloadFile(row.protocolUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename);
      });
    },
    unfreeze(row) {
      this.$confirm('是否确定解冻该企业签约关系？', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        unfreezeMerchantSign(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    freeze(row) {
      this.$confirm('是否确定冻结该企业签约关系？', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        freezeMerchantSign(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    showAuditDialog(row) {
      this.auditMerchantSignId = row.id;
      this.auditDialogVisible = true;
    },
    audit(status) {
      this.auditDialogVisible = false;
      if (status === 1) {
        auditMerchantSign(this.auditMerchantSignId).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }
    },
    handleChannelDialog(row) {
      this.currentSignId = row.id;
      this.currentChannelNo = row.channelNo;
      this.editChannelForm.id = row.id;
      this.editChannelForm.channelNo = row.channelNo;
      this.channelDialogVisible = true;
      queryTaxChannelList(row.taxNo).then(response => {
        this.taxChannelList = response.data;
      });
    },
    updateChannel() {
      this.channelDialogVisible = false;
      const params = {
        id: this.editChannelForm.id,
        channelNo: this.editChannelForm.channelNo
      };
      updateTaxChannel(params).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        // 清空dialog表单
        this.editChannelForm = {
          id: '',
          channelNo: ''
        };
        this.getList();
      });
    },
    downloadMchntSignList() {
      downloadMchntSignList(this.currentSearch).then(response => {
        downloadExcel(response.data, '企业签约列表.xls');
      });
    }
  }
};