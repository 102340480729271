import { noReadNum } from '../../api/sys';
const msgCenter = {
  state: {
    noReadNum: ''
  },
  mutations: {
    SET_NO_READ_NUM: (state, noReadNum) => {
      state.noReadNum = noReadNum;
    }
  },
  actions: {
    // 获取未读树
    GetNoReadNum({
      commit
    }) {
      return new Promise((resolve, reject) => {
        noReadNum().then(response => {
          let num = response.data;
          if (num > 99) {
            num = '99+';
          }
          commit('SET_NO_READ_NUM', num);
          resolve(num);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
export default msgCenter;