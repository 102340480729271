import { mapGetters } from 'vuex';
import { getSaveRechargeReq } from '@/api/transaction';
import { showlist } from '@/api/taxsource';
import { merchantShowList } from '@/api/merchant';
import { isMoneyNumber, isBankCardNo } from '@/utils/validate_rules';
import ItemTitle from '@/base/ui/title/itemTitle';
import UploadMoreFile from '@/base/ui/upload/uploadMoreFile';
import { mchntProjectShowlist } from '@/api/taxMerchant';
export default {
  name: 'accountRechargeAdd',
  components: {
    ItemTitle,
    UploadMoreFile
  },
  data() {
    return {
      infoForm: {
        taxSource: '',
        merchantNo: '',
        projectNo: '',
        bankSerialNo: '',
        userName: '',
        bankAccount: '',
        bankName: '',
        amount: '',
        date: '',
        remark: '',
        fileList: [],
        fileSaveKeys: []
      },
      taxSourceList: [],
      merchantList: [],
      projectList: [],
      span: 12,
      gutter: 40,
      rules: {
        taxSource: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'change'
        }],
        projectNo: [{
          required: true,
          message: '充值项目不能为空',
          trigger: 'change'
        }],
        merchantNo: [{
          required: true,
          message: '充值项目不能为空',
          trigger: 'change'
        }],
        bankSerialNo: [{
          required: true,
          message: '银行流水号不能为空',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '账户名称不能为空',
          trigger: 'blur'
        }],
        bankAccount: [{
          required: true,
          message: '银行账户不能为空',
          trigger: 'blur'
        }, {
          validator: isBankCardNo,
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '银行名称不能为空',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '充值金额不能为空',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        date: [{
          required: true,
          message: '充值日期不能为空',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '充值备注不能为空',
          trigger: 'blur'
        }, {
          max: 30,
          message: '最多输入30个字符',
          trigger: 'blur'
        }],
        fileList: [{
          required: true,
          message: '请上传充值凭证',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    accountType: function () {
      return this.$formatType('bankType', this.infoForm.accountType);
    },
    status: function () {
      return this.$formatType('withdrawStatus', this.infoForm.withdrawStatus);
    },
    isCheck: function () {
      return this.$route.path.includes('check');
    }
  },
  created() {
    this.getTaxSources();
  },
  methods: {
    getTaxSources() {
      showlist().then(response => {
        this.taxSourceList = response.data;
      });
    },
    getMerchantList() {
      this.infoForm.projectNo = null;
      this.merchantList = [];
      this.infoForm.projectNo = '';
      this.projectList = [];
      if (this.infoForm.taxSource !== '') {
        merchantShowList({
          taxNo: this.infoForm.taxSource
        }).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    merchantSelectChange(value) {
      console.log(11);
      if (value) {
        this.getProjectList();
      }
      this.infoForm.projectNo = '';
      this.projectList = [];
    },
    async getProjectList() {
      const params = {
        merchantNo: this.infoForm.merchantNo,
        taxNo: this.infoForm.taxSource
      };
      let res = await mchntProjectShowlist(params);
      this.projectList = res.data;
    },
    handleAvatarSuccessBylicense(res, file) {
      this.infoForm.licensePic = res.data.saveKey;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatProject(project) {
      return `${project.projectName}(${project.projectNo})`;
    },
    submitForm() {
      this.infoForm.fileSaveKeys = [];
      this.infoForm.fileList.forEach(item => {
        this.infoForm.fileSaveKeys.push(item.response.data.saveKey);
      });
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.goBack();
    },
    save() {
      getSaveRechargeReq({
        accountName: this.infoForm.userName,
        accountNo: this.infoForm.bankAccount,
        bankName: this.infoForm.bankName,
        bankSerialNo: this.infoForm.bankSerialNo,
        projectNo: this.infoForm.projectNo,
        merchantNo: this.infoForm.merchantNo,
        rechargeAmount: this.infoForm.amount,
        rechargeDate: this.infoForm.date,
        remark: this.infoForm.remark,
        taxNo: this.infoForm.taxSource,
        voucherUrls: this.infoForm.fileSaveKeys
      }).then(() => {
        this.goBack('提交成功，待劳务公司审核通过后即可入账！');
      });
    },
    goBack(message) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (message) {
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};