import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { getCustomerManagers } from '@/api/customerManager';
import AddForm from './components/addForm';
import Dimission from './components/dimission';
import ItemTitle from '@/base/ui/title/itemTitle';
import CManagerRegistration from './components/registration';
export default {
  name: 'managerIndex',
  components: {
    CManagerRegistration,
    Pagination,
    AddForm,
    Dimission,
    ItemTitle
  },
  data() {
    return {
      searchForm: {
        no: '',
        name: '',
        phone: '',
        status: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      managers: [],
      isAddVisible: false,
      curManager: {},
      isRegisterVisible: false,
      isDimissionVisible: false,
      hasAddPermission: false,
      hasUpdatePermission: false,
      hasRegisterPermission: false,
      hasDimissionPermission: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    statusOptions: function () {
      return this.$formatTypeArray('managerStatus');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasAddPermission = map.has('addManager');
      this.hasUpdatePermission = map.has('updateManager');
      this.hasRegisterPermission = map.has('registerManager');
      this.hasDimissionPermission = map.has('leaveManager');
    } else {
      this.hasAddPermission = false;
      this.hasUpdatePermission = false;
      this.hasRegisterPermission = false;
      this.hasDimissionPermission = false;
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('managerStatus', row.status);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    addForm() {
      this.curManager = {};
      this.isAddVisible = true;
    },
    updateRow(index, object) {
      this.isAddVisible = true;
      this.curManager = {
        ...object
      };
    },
    cancelRow(index, object) {
      this.isRegisterVisible = true;
      this.curManager = {
        ...object
      };
    },
    departRow(index, object) {
      this.isDimissionVisible = true;
      this.curManager = {
        ...object
      };
    },
    newRecordAdded() {
      this.getList();
    },
    recordUpdated() {
      console.log('newRecordUpdated');
      this.getList();
    },
    pageChanged() {
      console.log('pageChanged');
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getCustomerManagers({
        salesmanName: this.currentSearch.name,
        salesmanNo: this.currentSearch.no,
        salesmanPhone: this.currentSearch.phone,
        status: this.currentSearch.status,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.managers = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};