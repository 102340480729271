var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.handleClose,
      visible: _vm.dialogFormVisible,
      "close-on-click-modal": false
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "班组编号:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目:",
      "label-width": _vm.formLabelWidth
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.projectName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票抬头:",
      "label-width": _vm.formLabelWidth,
      prop: "remark"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.formData.taxName))]), _c("el-button", {
    staticClass: "it-button",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.onDetail
    }
  }, [_vm._v("抬头详情")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票金额:",
      "label-width": _vm.formLabelWidth,
      prop: "invoiceAmount"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.invoiceAmount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceAmount", $$v);
      },
      expression: "form.invoiceAmount"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票类型:",
      "label-width": _vm.formLabelWidth,
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      clearable: "",
      placeholder: "请选择发票类型",
      size: "mini"
    },
    model: {
      value: _vm.form.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceType", $$v);
      },
      expression: "form.invoiceType"
    }
  }, _vm._l(_vm.initData.invoiceTypeList, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票类目:",
      "label-width": _vm.formLabelWidth,
      prop: "invoiceContent"
    }
  }, [_c("el-select", {
    staticClass: "w100",
    attrs: {
      clearable: "",
      placeholder: "请选择开票类目",
      size: "mini"
    },
    model: {
      value: _vm.form.invoiceContent,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceContent", $$v);
      },
      expression: "form.invoiceContent"
    }
  }, _vm._l(_vm.initData.contentList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传结算单:",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 1,
      action: _vm.uploadSettlement,
      extendData: _vm.extendData
    },
    model: {
      value: _vm.settlementList,
      callback: function ($$v) {
        _vm.settlementList = $$v;
      },
      expression: "settlementList"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "抬头详情",
      visible: _vm.invoiceHeaderVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.invoiceHeaderVisible = $event;
      },
      close: function ($event) {
        _vm.invoiceHeaderVisible = false;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm1",
    attrs: {
      model: _vm.form,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票抬头:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.taxName) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "统一社会信用代码:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.licenseNo) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册电话:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.companyNumber) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.companyAddress) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户银行账号:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.bankAccount) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户银行:"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.initData.bankName) + "\n      ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.invoiceHeaderVisible = false;
      }
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };