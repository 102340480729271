import CityCascader from '@/base/ui/cascader/cityCascader';
import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      bankType: this.$formatTypeArray('bankType')
    };
  },
  props: {
    saveForm: {
      accountNo: '',
      accountType: '',
      bankName: '',
      cityCode: '',
      custName: '',
      mobile: '',
      provinceCode: ''
    },
    baseForm: {
      default: () => {
        return {};
      }
    }
  },
  methods: {
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city;
      }
    },
    formatterAccountType() {
      return this.$formatType('bankType', this.saveForm.accountType);
    }
  }
};