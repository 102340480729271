import { isMoneyNumber, isGEOneHundred } from '@/utils/validate_rules';
import { getWithdrawApplyReq } from '@/api/transaction';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { fmoneyWithYuan } from '@/utils/formatter';
export default {
  name: 'withdraw-dialog',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    availableAmount: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      form: {
        amount: ''
      },
      rules: {
        amount: [{
          required: true,
          message: '请输入提现金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }, {
          validator: isGEOneHundred,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    fAvailableAmount: function () {
      return fmoneyWithYuan(this.availableAmount);
    }
  },
  methods: {
    allIn() {
      this.form.amount = this.availableAmount;
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const amount = Number.parseFloat(this.form.amount);
          if (Number.isNaN(amount)) {
            this.$message.error('请输入正确的提现金额');
            return;
          }
          if (amount <= 0) {
            this.$message.error('提现金额必须大于0');
            return;
          }
          this.withdraw();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      this.$refs['form'].resetFields();
    },
    withdraw() {
      const user = getAcountInfo();
      getWithdrawApplyReq({
        agentNo: user.partyCode,
        withdrawAmount: this.form.amount
      }).then(response => {
        this.$emit('updated');
      });
    }
  }
};