import ItemTitle from '@/base/ui/title/itemTitle';
import { invitemployee } from '@/api/operateEmp';
import { getAcountInfo } from '../../../utils/auth';
import { showlist } from '../../../api/taxsource';
export default {
  name: 'inviteEmployeeDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      userName: '',
      partyType: '',
      saveForm: {
        taxNo: '',
        id: ''
      },
      taxSources: []
    };
  },
  props: {
    dialogFormVisible: false,
    item: ''
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleInviteDialog', val);
    }
  },
  created() {
    this.userName = getAcountInfo().partyName;
    this.partyType = getAcountInfo().partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      this.getTaxSources();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    doSubmit() {
      invitemployee(this.item.id).then(() => {
        this.$alert('邀请成功，赶紧通知该用户前往“合作签约”小程序进行半身照认证吧！', '温馨提示', {
          type: 'warning',
          confirmButtonText: '知道了',
          center: true,
          closeOnClickModal: false
        }).then(() => {
          this.myDialogFormVisible = false;
        });
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};