import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions, separateDate } from '@/utils/util';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { showlist } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import { getCurrentMonth } from '../../../../utils/util';
import { downloadLatelyRecharge, getLatelyRechargeList } from '../../../../api/stat';
export default {
  name: 'lastTimeList',
  components: {
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      hasDownloadPermission: false,
      list: [],
      listLoading: true,
      dateTime: [],
      searchForm: {
        beginTime: '',
        endTime: '',
        belongTaxNo: '',
        incomeTaxNo: '',
        merchantName: '',
        merchantNo: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      taxSources: [],
      totalAmount: 0,
      totalCount: 0
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.get('download');
    }
  },
  mounted() {
    this.dateTime = getCurrentMonth();
    this.getList();
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      if (this.dateTime === null || this.dateTime.length === 0) {
        this.$message.error('请选择入账时间');
        return;
      }
      this.listLoading = true;
      const qryCreateTime = separateDate(this.dateTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      getLatelyRechargeList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmount = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.dateTime = getCurrentMonth();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      const qryCreateTime = separateDate(this.dateTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      downloadLatelyRecharge(this.currentSearch).then(response => {
        downloadExcel(response.data, '最新交易额统计表.xls');
      });
    }
  }
};