var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      height: "100%"
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    staticStyle: {
      width: "40%",
      height: "100%"
    },
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.title) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "顺序："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.seqNum) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属banner组："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.appGroup) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属系统："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$formatType("appCode", _vm.saveForm.appCode)) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$formatType("bannerStatus", _vm.saveForm.status)) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "概述："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.summary) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "banner图："
    }
  }, [_vm.saveForm.imgUrl ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.imgUrl,
      "preview-src-list": [_vm.saveForm.imgUrl]
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };