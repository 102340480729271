import { mapGetters } from 'vuex';
import { getMenuList, deleteMenu } from '@/api/sys';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { MENU_UPDATE_ID, MENU_INFO_ID } from '@/utils/idName';
export default {
  name: 'menuIndex',
  components: {
    Pagination
  },
  props: {
    parentId: Number
  },
  data() {
    return {
      show: false,
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        parentId: 1
      },
      total: 0,
      listLoading: true,
      menus: [],
      viewMenuPath: '',
      deleteMenuVisible: false,
      updateMenuVisible: false,
      updateMenuPath: '',
      clickUpdate: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updatePartyrolePath && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewMenuPath = map.get('viewMenu');
      this.updateMenuPath = map.get('updateMenu');
      this.updateMenuVisible = map.has('updateMenu');
      this.deleteMenuVisible = map.has('deleteMenu');
    } else {
      this.updateMenuVisible = false;
      this.deleteMenuVisible = false;
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList();
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      getMenuList(this.listQuery).then(response => {
        this.menus = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.listQuery.pageNo, this.listQuery.pageSize);
    },
    refershList(id) {
      this.listQuery.parentId = id;
      this.listQuery.pageNo = 1;
      this.getList();
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteMenu(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.clickUpdate = true;
      sessionStorage.setItem(MENU_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updateMenuPath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(MENU_INFO_ID, row.id);
      this.$router.push({
        path: this.viewMenuPath
      });
    },
    formatMenuType(row, column) {
      return this.$formatType('menuType', row.type);
    }
  },
  mounted() {
    this.getList();
  }
};