import { updateRateConfig } from '@/api/taxsource';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { isValidFloat01 } from '@/utils/validate_rules'; // isValidFloat

export default {
  name: 'saveRate',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        agentRate: '',
        personRate: '',
        returnRate: '',
        serviceRate: '',
        supertaxRate: '',
        taxNo: '',
        vatRate: '',
        diffAgentRate: '',
        diffRate: ''
      },
      rules: {
        agentRate: [{
          required: true,
          message: '请输入平台代理费率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        // personRate: [
        //   { required: true, message: '请输入个人所得税率', trigger: 'blur' },
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ],
        returnRate: [{
          required: true,
          message: '请输入政府补贴费率比例',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }]
        // serviceRate: [
        //   { required: true, message: '请输入技术服务费率', trigger: 'blur' },
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ],
        // supertaxRate: [
        //   { required: true, message: '请输入地方附加税率', trigger: 'blur' },
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ],
        // vatRate: [
        //   { required: true, message: '请输入增值税率', trigger: 'blur' },
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ],
        // diffRate: [
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ],
        // diffAgentRate: [
        //   { validator: isValidFloat01, trigger: 'blur' }
        // ]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    },
    taxNo: '',
    id: ''
  },
  methods: {
    addSubmit() {
      this.saveForm.taxNo = this.taxNo;
      console.log('this.saveForm.taxNo:' + this.taxNo + '    saveForm.id:' + this.id + '   gotoNext :' + this.gotoNext);
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateRateConfig(this.saveForm).then(response => {
            if (this.gotoNext) {
              this.gotoNext(response.data);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};