import { mchntProjectInfo } from '@/api/merchant';
import { PROJECT_NO } from '@/utils/idName';
import MerchantViewBase from './components/view/viewBase';
import SignInfo from './components/view/signInfo';
export default {
  components: {
    MerchantViewBase,
    SignInfo
  },
  data() {
    return {
      loading: false,
      active: 0,
      message: 'first',
      list: [{
        label: '基本信息',
        name: 'first',
        component: () => new Promise(resolve => {
          require.ensure([], require => {
            resolve(require('./components/view/viewBase'));
          });
        })
      }, {
        label: '签约信息',
        name: 'third',
        component: () => new Promise(resolve => {
          require.ensure([], require => {
            resolve(require('./components/view/signInfo'));
          });
        })
      }]
    };
  },
  methods: {}
};