import ItemTitle from '@/base/ui/title/itemTitle';
import { getTaxMounthSummaryCharts } from '../../../../api/stat';
import DbsHistogram from '../../../../components/chart/dbs-histogram';
export default {
  name: 'parkTransMonthCharts',
  components: {
    DbsHistogram,
    ItemTitle
  },
  data() {
    const chartSettings = {
      // itemStyle: {
      //   color: '#2DA8FF'
      // },
      labelMap: {
        'rechargeTotalMillion': '充值金额',
        'repayTotalMillion': '发放金额',
        'ticketTotalMillion': '开票金额'
      },
      legendName: {
        'rechargeTotalMillion': '充值金额',
        'repayTotalMillion': '发放金额',
        'ticketTotalMillion': '开票金额'
      },
      dataOrder: false
    };
    return {
      chartData: {
        columns: ['statMonth', 'rechargeTotalMillion', 'repayTotalMillion', 'ticketTotalMillion'],
        rows: []
      },
      chartSettings: chartSettings
    };
  },
  props: {
    searchForm: {
      required: true,
      default: () => {
        return {
          area: '',
          beginTime: '',
          endTime: '',
          taxNo: ''
        };
      }
    }
  },
  watch: {
    searchForm(val) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList() {
      getTaxMounthSummaryCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    }
  }
};