import table from '@/utils/table';
import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'ViewIndustry',
  components: {
    ItemTitle
  },
  props: {
    gotoNext: {
      default: null
    },
    industryList: {
      type: Array,
      default: () => {
        return [{}];
      }
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, 1, 200);
    }
  }
};