import { addBankCard, listBankCard, delBankCard } from '@/api/merchant';
import { queryDistrictListToIndustry } from '@/api/common';
import table from '@/utils/table';
export default {
  name: 'saveBase',
  data() {
    return {
      total: 0,
      bankType: this.$formatTypeArray('bankType'),
      saveForm: {
        ccountNo: '',
        accountType: '',
        bankName: '',
        custName: '',
        belong: ''
      },
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        merchantNo: this.merchantNo
      },
      typeList: [],
      listTaxIndustry: [],
      rules: {
        accountNo: [{
          required: true,
          message: '请输入充值账号',
          trigger: 'blur'
        }],
        accountType: [{
          required: true,
          message: '请选择账户类型',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入开户银行',
          trigger: 'blur'
        }, {
          min: 4,
          max: 30,
          message: '请输入4-30位字符',
          trigger: 'blur'
        }],
        custName: [{
          required: true,
          message: '请输入账户名称(持卡人姓名)',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '小于20个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    const that = this;
    this.getList();
    queryDistrictListToIndustry().then(response => {
      that.typeList = response.data;
    });
  },
  props: {
    gotoNext: {
      default: null
    },
    belong: ''
  },
  watch: {
    belong(val) {
      this.belong = val;
      this.getList();
    }
  },
  methods: {
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        delBankCard(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    getList() {
      if (this.belong === '') {
        return;
      }
      const that = this;
      this.queryParam.merchantNo = this.belong;
      listBankCard(this.queryParam).then(response => {
        that.listTaxIndustry = response.data;
        that.total = response.data.length;
      });
    },
    addBtn() {
      if (this.listTaxIndustry.length >= 10) {
        this.$message({
          type: 'error',
          message: '企业已添加充值帐户达10个，请选择删除后再添加!'
        });
        return;
      }
      this.saveForm.belong = this.belong;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          addBankCard(this.saveForm).then(response => {
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    addSubmit() {
      if (this.listTaxIndustry.length === 0) {
        this.$message.error('请添加充值账户');
        return;
      }
      if (this.gotoNext) {
        this.gotoNext('');
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.queryParam.pageNo, this.queryParam.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('bankType', row.status);
    }
  }
};