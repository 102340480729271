import { timePickerOptions } from '@/utils/util';
import ItemTitle from '@/base/ui/title/itemTitle';
import { merchantSignInfo } from '@/api/taxMerchant';
import { MERCHANT_SIGN_INFO_ID } from '../../../../../utils/idName';
import FreeView from '../../../../../base/ui/free/freeView';
import ElLink from 'element-ui/packages/link/src/main';
import { downloadFile } from '../../../../../api/upload';
import { downloadAllFile } from '../../../../../utils/util';
import { getAcountInfo } from '../../../../../utils/auth';
export default {
  name: 'updateMerchantSign',
  components: {
    ElLink,
    FreeView,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        merchantNo: '',
        protocolEnd: '',
        protocolStart: '',
        protocolUrl: '',
        taxNo: '',
        rateType: '',
        rechargeRate: '',
        rateInfoList: [{
          start: '',
          end: '',
          rate: ''
        }],
        sideAgreementUrlShow: ''
      },
      date: [],
      taxSelectList: [],
      freeText: '企业充值金额',
      freeName: '充值阶梯政策',
      partyCode: ''
    };
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  created() {
    this.getDetailInfo();
    this.partyCode = getAcountInfo().partyCode;
  },
  methods: {
    getDetailInfo() {
      const id = sessionStorage.getItem(MERCHANT_SIGN_INFO_ID);
      if (id) {
        merchantSignInfo(id).then(response => {
          this.saveForm = response.data;
          this.date = [response.data.protocolStartShow, response.data.protocolEndShow];
          switch (this.saveForm.billMode) {
            case 0:
              {
                this.freeName = '充值阶梯政策';
                this.freeText = '企业充值金额';
                break;
              }
            case 1:
              {
                this.freeName = '出款阶梯政策';
                this.freeText = '个人出款金额';
                break;
              }
            case 2:
              {
                this.freeName = '企业出款阶梯政策';
                this.freeText = '企业出款金额';
                break;
              }
          }
        });
      }
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    formatStatus() {
      return this.$formatType('rateTypeStatus', this.saveForm.rateType);
    },
    downloadUrl(item) {
      const that = this;
      downloadFile(item.fileUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename, that);
      });
    }
  }
};