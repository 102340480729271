import { mapGetters } from 'vuex';
import { requestRechageGuideReq } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'account-guide',
  components: {
    ItemTitle
  },
  data() {
    return {
      list: [],
      servicePhone: process.env.SERVICE_PHONE,
      hasCardPermission: false,
      cardPath: '',
      activeNames: ['1']
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasCardPermission = map.has('card');
      this.cardPath = map.get('card');
    } else {
      this.hasCardPermission = false;
      this.cardPath = '';
    }
    this.getQuery();
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    getQuery() {
      requestRechageGuideReq().then(response => {
        this.list = response.data;
      });
    },
    accountManagement() {
      this.$router.push({
        path: '/merchantInfo/setBankCard'
      });
    }
  }
};