import { mapGetters } from 'vuex';
import SubList from './components/subList';
import { allRead } from '../../../api/sys';
export default {
  name: 'msgCenterListIndex',
  components: {
    SubList
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  computed: {
    ...mapGetters(['buttonsMap', 'noReadNum'])
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.getList()
  //   })
  // },
  created() {
    this.getNoReadNum();
  },
  methods: {
    getNoReadNum() {
      this.$store.dispatch('GetNoReadNum');
    },
    setAllRead() {
      this.$confirm('此操作会将未读 消息全部标记为已读，可能错过重要信息。是否确定全部标记为已读？', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false,
        confirmButtonText: '全部标记为已读'
        // closeOnPressEscape: false,
      }).then(() => {
        allRead().then(() => {
          this.getNoReadNum();
          this.$message.success('操作成功。。。');
          this.$refs.list1.getList();
          this.$refs.list2.getList();
        });
      }).catch(() => {});
    }
  }
};