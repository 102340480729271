import { getAcountInfo } from '../../../utils/auth';
const template_url = 'https://gyoss.jianjianggong.cn/common/template/人员批量导入模板.xlsx';
import { beforUploadByExcel } from '@/utils/util';
import ImportViewDialog from './importViewDialog';
import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { merchantShowList } from '@/api/merchant';
import { mchntProjectShowlist } from '@/api/taxMerchant';
export default {
  components: {
    ImportViewDialog,
    ItemTitle
  },
  data() {
    return {
      template_url: template_url,
      saveFile: process.env.BASE_API + '/employee/operate/import',
      fileList: [],
      responseData: null,
      totalNum: 0,
      dataErrorDesc: '',
      dataErrorDialog: false,
      uploadViewFromVisible: false,
      uploadForm: {
        taxNo: '',
        merchantNo: '',
        projectNo: ''
      },
      taxSourceList: [],
      merchantList: [],
      projectList: [],
      authTypes: this.$formatTypeArray('authType'),
      uploadFormRules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属甲方单位',
          trigger: 'blur'
        }],
        projectNo: [{
          required: true,
          message: '请选择所属项目',
          trigger: 'blur'
        }]
      },
      message_box: '',
      partyCode: ''
    };
  },
  computed: {
    uploadData: function () {
      return {
        taxNo: this.uploadForm.taxNo,
        merchantNo: this.uploadForm.merchantNo,
        projectNo: this.uploadForm.projectNo,
        bizCode: this.partyCode
      };
    }
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
    this.getTaxSources();
  },
  methods: {
    getTaxSources() {
      showlist().then(response => {
        this.taxSourceList = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getMerchantList() {
      this.uploadForm.merchantNo = null;
      this.merchantList = [];
      if (this.uploadForm.taxNo !== '') {
        const params = {
          taxNo: this.uploadForm.taxNo
        };
        merchantShowList(params).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    merchantSelectChange(value) {
      console.log(11);
      if (value) {
        this.getProjectList();
      }
      this.uploadForm.projectNo = '';
      this.projectList = [];
    },
    async getProjectList() {
      const params = {
        merchantNo: this.uploadForm.merchantNo,
        taxNo: this.uploadForm.taxNo
      };
      let res = await mchntProjectShowlist(params);
      this.projectList = res.data;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatProject(project) {
      return `${project.projectName}(${project.projectNo})`;
    },
    handleUploadSuccess(res, file) {
      // this.saveForm.protocolUrl = file.response.data
      // console.log('this.saveForm.protocolUrl========='+this.saveForm.protocolUrl)
      if (file.response.code === 'BASE.000') {
        if (file.response.data.errorDesc !== null && file.response.data.errorDesc !== '') {
          this.dataErrorDialog = true;
          this.dataErrorDesc = file.response.data.errorDesc;
        } else {
          this.updateSuccess(file.response);
        }
      } else {
        this.$message({
          type: 'error',
          message: file.response.errMsg
        });
      }
    },
    sureButton() {
      this.dataErrorDialog = false;
      this.clearData();
    },
    beforeAvatarUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    submitUpload() {
      this.$refs['uploadForm'].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
        } else {
          return false;
        }
      });
    },
    updateSuccess(response) {
      this.uploadViewFromVisible = true;
      console.log(response.data);
      this.responseData = response.data;
      /*
      this.$message({
        message: '上传成功',
        type: 'success'
      })
      */
    },
    changeVisibleUpdateView(val) {
      this.uploadViewFromVisible = val;
    },
    uploadSuccess(val) {
      this.clearData();
      this.$alert(val, '操作提示', {
        confirmButtonText: '确定',
        closeOnClickModal: false,
        callback: action => {
          this.$emit('refresh');
          /*
          this.$message({
            type: 'success',
            message: '提交成功!'
          })
          */
        }
      });
    },
    uploadError() {
      this.clearData();
      this.$message({
        message: '导入失败',
        type: 'error'
      });
    },
    uploadCancel() {
      this.clearData();
      this.$message({
        message: '取消导入',
        type: 'view.vue'
      });
    },
    clearData() {
      this.fileList = [];
      this.responseData = null;
    }
  }
};