import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { mchntProAgreementSave, mchntProAgreementUpdate } from '@/api/merchant';
import { isMoneyNumber } from '@/utils/validate_rules';
export default {
  props: {
    visiable: Boolean,
    projectNo: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {
    UploadMoreFile
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      isEdit: false,
      formLabelWidth: '80px',
      form: {
        customAgreementNo: '',
        agreementAmt: 0,
        agreementName: "",
        agreementUrls: [],
        projectNo: '',
        remark: "",
        signDate: ''
      },
      rules: {
        agreementAmt: [{
          required: true,
          message: '请输入合同金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        customAgreementNo: [{
          required: true,
          message: '请输入合同编号',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '长度在 6 到 20 个字符',
          trigger: 'blur'
        }],
        agreementName: [{
          required: true,
          message: '请输入合同名称',
          trigger: 'blur'
        }, {
          min: 6,
          max: 64,
          message: '长度在 6 到 64 个字符',
          trigger: 'blur'
        }],
        // agreementUrls: [{
        //   type: 'array',
        //   required: true,
        //   message: '请至少上传一个附件',
        //   trigger: 'change'
        // }],
        signDate: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }]
      },
      agreementUrlList: [],
      loading: false
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return this.isEdit ? '编辑合同' : '新增合同';
    }
  },
  methods: {
    opened() {
      console.log('this.projectNo: ', this.projectNo);
      this.form.projectNo = this.projectNo;
      if (this.formData) {
        this.isEdit = true;
        this.form = Object.assign({}, this.formData);
        this.agreementUrlList = [this.form.fileName].map(item => {
          const file = {
            name: item,
            url: item,
            response: {
              data: {
                saveKey: item
              },
              code: 'BASE.000'
            }
          };
          return file;
        });
      }
    },
    onClose() {
      this.handleClose();
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      this.agreementUrlList = [];
      this.$refs['ruleForm'].resetFields();
      done && done();
    },
    onSure() {
      if (!this.agreementUrlList.length) {
        this.$message.error('请先上传合同文件！');
        return;
      }
      this.agreementUrlList.forEach(item => {
        if (!this.form.agreementUrls) {
          this.form.agreementUrls = [];
        }
        if (item.response && item.response.data.saveKey) {
          this.form.agreementUrls.push(item.response.data.saveKey);
        }
      });
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.handle();
        } else {
          return false;
        }
      });
    },
    handle() {
      if (this.isEdit) {
        this.mchntProAgreementUpdate();
      } else {
        this.mchntProAgreementSave();
      }
    },
    mchntProAgreementSave() {
      this.loading = true;
      const params = {
        ...this.form
      };
      mchntProAgreementSave(params).then(() => {
        this.loading = false;
        this.$message.success('添加成功。。。');
        this.handleSuccess();
      }).catch(() => {
        this.loading = false;
      });
    },
    async mchntProAgreementUpdate() {
      this.loading = true;
      const {
        agreementAmt,
        agreementName,
        agreementNo,
        agreementUrls,
        id,
        projectNo,
        remark,
        signDate
      } = this.form;
      const params = {
        agreementAmt,
        agreementName,
        agreementNo,
        agreementUrls,
        id,
        projectNo,
        remark,
        signDate
      };
      mchntProAgreementUpdate(params).then(() => {
        this.loading = false;
        this.$message.success('更新成功。。。');
        this.handleSuccess();
      }).catch(() => {
        this.loading = false;
      });
    },
    handleSuccess() {
      this.handleClose();
      this.dialogFormVisible = false;
      this.$emit('handleSuccess');
    }
  }
};