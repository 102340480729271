var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: `基本信息`,
      name: "first"
    }
  }, [_c("update-base", {
    attrs: {
      saveForm: _vm.taxBase
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: `费率设置`,
      name: "second"
    }
  }, [_c("update-rate", {
    attrs: {
      saveForm: _vm.rateConfig,
      taxNo: _vm.taxBase.taxNo
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };