var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "设置预警阀值"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "130px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "全国付款限额：",
      prop: "nationwidePayLimit"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "正整数，最高7位数，允许保留 2 位小数"
    },
    model: {
      value: _vm.saveForm.nationwidePayLimit,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "nationwidePayLimit", $$v);
      },
      expression: "saveForm.nationwidePayLimit"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "劳务公司付款限额：",
      prop: "taxPayLimit"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "正整数，最高7位数，允许保留 2 位小数"
    },
    model: {
      value: _vm.saveForm.taxPayLimit,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxPayLimit", $$v);
      },
      expression: "saveForm.taxPayLimit"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("提 交")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };