import ItemTitle from '../../../base/ui/title/itemTitle';
import { VIEW_INVOICE_CONTENT_ID } from '../../../utils/idName';
import { refundInfo } from '../../../api/account';
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      view: {}
    };
  },
  created() {
    const id = sessionStorage.getItem(VIEW_INVOICE_CONTENT_ID);
    refundInfo(id).then(response => {
      this.view = response.data;
    });
  }
};