var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "开票记录："
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.taxName,
      disabled: ""
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxSource", $$v);
      },
      expression: "searchForm.taxSource"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.partyType != 3 ? _c("el-form-item", {
    attrs: {
      label: "企业编号",
      prop: "merchantNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入企业编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票抬头：",
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票抬头",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantName", $$v);
      },
      expression: "searchForm.merchantName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票订单号：",
      prop: "serialNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开票订单号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.serialNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "serialNo", $$v);
      },
      expression: "searchForm.serialNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择开票状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "申请时间：",
      prop: "date",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票时间：",
      prop: "billedTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.billedTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "billedTime", $$v);
      },
      expression: "searchForm.billedTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("\n      查询汇总：  开票笔数  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.total))]), _vm._v("   笔；  开票金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$fmoneyWithSymbol(_vm.totalAmt)))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.invoices,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serialNo",
      label: "开票订单号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectNo",
      label: "项目子编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectName",
      label: "项目名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "发票抬头",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "applyTime",
      label: "申请时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "billedTime",
      label: "开票时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "发票金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceType",
      label: "发票类型",
      "min-width": "220",
      formatter: _vm.formatInvoiceType
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "contents",
      label: "发票内容",
      "min-width": "180",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "发票状态",
      "min-width": "120",
      formatter: _vm.formatStatus
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditRemark",
      label: "审核备注",
      "min-width": "120",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _vm.hasDetailPermission || _vm.hasCancelPermission || _vm.auditInvoicePath || _vm.hasUpdatePermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "200",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasDetailPermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.checkRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.auditInvoicePath && scope.row.status === 6 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.reviewRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e(), _vm._v(" "), _vm.hasUpdatePermission && (scope.row.status === 1 || scope.row.status === 2) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.updateRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("更新物流")]) : _vm._e(), _vm._v(" "), _vm.hasUpdateBillPermission && (scope.row.status === 1 || scope.row.status === 2) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.UpdateBillNo(scope.row, "editBill");
            }
          }
        }, [_vm._v("修改发票信息")]) : _vm._e(), _vm._v(" "), _vm.hasCancelPermission && scope.row.status === 6 ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.cancelRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("取消")]) : _vm._e(), _vm._v(" "), _vm.hasUpdateBillPermission && scope.row.status === 7 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showUpdateBillDialog(scope.row, "updateBill");
            }
          }
        }, [_vm._v("更新发票")]) : _vm._e()];
      }
    }], null, false, 2678480471)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _c("update-invoice", {
    attrs: {
      isVisible: _vm.isUpdateVisible,
      expressInfo: _vm.expressInfo
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isUpdateVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isUpdateVisible = $event;
      },
      updated: _vm.updated
    }
  }), _vm._v(" "), _c("upload-letter-dialog", {
    attrs: {
      uploadDialogVisible: _vm.uploadLetterDialogVisible,
      id: _vm.uploadDialogId
    },
    on: {
      changeUploadDialogVisible: _vm.changeUploadDialogVisible
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "40%",
      title: "更新发票信息",
      visible: _vm.updateBillVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.updateBillVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "updateBillForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      model: _vm.updateBillForm,
      "label-width": "130px",
      rules: _vm.updateBillRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票编号：",
      prop: "billInfo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票编号，多个编号以“,”隔开",
      type: "textarea"
    },
    model: {
      value: _vm.updateBillForm.billInfo,
      callback: function ($$v) {
        _vm.$set(_vm.updateBillForm, "billInfo", $$v);
      },
      expression: "updateBillForm.billInfo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传发票文件："
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 10,
      fileType: 2
    },
    model: {
      value: _vm.updateBillForm.billUrlList,
      callback: function ($$v) {
        _vm.$set(_vm.updateBillForm, "billUrlList", $$v);
      },
      expression: "updateBillForm.billUrlList"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    staticStyle: {
      "font-size": "12px",
      color: "#ff4c4c",
      "line-height": "1.2",
      "margin-left": "20px"
    }
  }, [_c("div", [_vm._v("1.上传的文件须是PDF或图片格式(PNG、JPG、JEPG、BMP)")]), _vm._v(" "), _c("div", [_vm._v("2.最多上传10份文件，每份文件不超过8M。")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.doUpdateBill();
      }
    }
  }, [_vm._v("确 定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.updateBillVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "40%",
      title: _vm.billType == "editBill" ? "修改发票编号" : "更新发票",
      visible: _vm.updateBillNoVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.updateBillNoVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "updateBillForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      model: _vm.updateBillForm,
      "label-width": "130px",
      rules: _vm.updateBillRules
    }
  }, [_c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.billType == "editBill",
      expression: "billType == 'editBill'"
    }],
    attrs: {
      label: "旧发票编号："
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium"
    }
  }, [_vm._v(_vm._s(_vm.oldBillNo))])]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票编号：",
      prop: "billInfo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票编号，多个编号以逗号隔开",
      type: "textarea",
      rows: "5",
      cols: "5"
    },
    model: {
      value: _vm.updateBillForm.billInfo,
      callback: function ($$v) {
        _vm.$set(_vm.updateBillForm, "billInfo", $$v);
      },
      expression: "updateBillForm.billInfo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传发票文件："
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 10,
      fileType: 2
    },
    model: {
      value: _vm.updateBillForm.billUrlList,
      callback: function ($$v) {
        _vm.$set(_vm.updateBillForm, "billUrlList", $$v);
      },
      expression: "updateBillForm.billUrlList"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    staticStyle: {
      "font-size": "12px",
      color: "#ff4c4c",
      "line-height": "1.2",
      "margin-left": "20px"
    }
  }, [_c("div", [_vm._v("1.上传的文件须是PDF或图片格式(PNG、JPG、JEPG、BMP)")]), _vm._v(" "), _c("div", [_vm._v("2.最多上传10份文件，每份文件不超过8M。")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.doUpdateBilled();
      }
    }
  }, [_vm._v("确 定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.updateBillNoVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };