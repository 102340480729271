import SaveProduct from './components/save/saveProduct';
import SaveIndustry from './components/save/saveIndustry';
import SaveRate from './components/save/saveRate';
import TaxSourceSaveBase from './components/save/saveBase';
export default {
  components: {
    TaxSourceSaveBase,
    SaveRate,
    SaveProduct,
    SaveIndustry
  },
  name: 'addTaxsource',
  data() {
    return {
      active: 0,
      taxNo: ''
    };
  },
  methods: {
    next() {
      this.$refs.submit.addSubmit();
    },
    gotoSecond(taxNo) {
      this.taxNo = taxNo;
      if (this.active++ > 1) this.finish();
    },
    finish() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$message.success('添加成功。。。');
        this.$router.go(-1);
      });
    },
    gotoNext() {
      if (this.active++ > 2) this.finish();
    }
  }
};