var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "订单信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "充值订单号：",
      value: _vm.infoForm.bussincessNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "充值时间：",
      value: _vm.infoForm.createTime,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "充值金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.rechargeAmount),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "记账状态：",
      value: _vm.accountStatus,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "劳务公司编号：",
      value: _vm.infoForm.taxNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "劳务公司税费：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.taxIncome),
      left: false
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "平台"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "平台代理费率：",
      value: _vm.infoForm.platformAgentFeerate,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "平台代理费：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.platformAgentIncome),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "平台服务费率：",
      value: _vm.infoForm.platformFeerate,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "平台服务费：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.platformIncome),
      left: false
    }
  })], 1)], 1), _vm._v(" "), _vm.infoForm.agentNo != null ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "服务商  (" + _vm.infoForm.agentNo + ")"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "服务商费率：",
      value: _vm.infoForm.agentFeerate,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "服务商服务费：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.agentIncome),
      left: false
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "甲方单位  (" + _vm.infoForm.merchantNo + ")"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "甲方单位费率：",
      value: _vm.infoForm.merchantFeerate,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-title-class": "custom-title-class",
      title: "充值入账金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.merchantIncome),
      left: false
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };