import ItemTitle from '@/base/ui/title/itemTitle';
import { invoiceRecordAudit } from '../../../api/invoice';
export default {
  name: 'invoice-list-check',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    id: {
      required: true
    }
  },
  data() {
    return {
      addForm: {
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '请输入审核备注',
          trigger: 'blur'
        }, {
          max: 30,
          message: '最多输入30个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.checkInvoice(1);
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.checkInvoice(8);
      this.dialogVisible = false;
    },
    dialogClosed() {
      this.$refs['addForm'].resetFields();
    },
    checkInvoice(status) {
      const param = {
        id: this.id,
        auditRemark: this.addForm.remark,
        status: status
      };
      invoiceRecordAudit(param).then(() => {
        const message = status === 1 ? '开票申请已同意...' : '开票申请已拒绝...';
        this.$message.success(message);
        this.$emit('updated');
      });
    }
  }
};