var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "人员新增"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.saveForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxNo", $$v);
      },
      expression: "saveForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "姓名",
      prop: "empName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入姓名"
    },
    model: {
      value: _vm.saveForm.empName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "empName", $$v);
      },
      expression: "saveForm.empName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号",
      prop: "idcard"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入18位身份证号"
    },
    model: {
      value: _vm.saveForm.idcard,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "idcard", $$v);
      },
      expression: "saveForm.idcard"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "银行卡号",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入个人银行卡号"
    },
    model: {
      value: _vm.saveForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "accountNo", $$v);
      },
      expression: "saveForm.accountNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入手机号码"
    },
    model: {
      value: _vm.saveForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "phone", $$v);
      },
      expression: "saveForm.phone"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("保存")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.cancelBtn
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };