var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业编号",
      prop: "merchantNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入企业编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "甲方单位名称",
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入甲方单位名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantName", $$v);
      },
      expression: "searchForm.merchantName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上级服务商编号",
      prop: "agentNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入上级服务商编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.agentNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "agentNo", $$v);
      },
      expression: "searchForm.agentNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "类型",
      prop: "merchantType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择类型"
    },
    model: {
      value: _vm.searchForm.merchantType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantType", $$v);
      },
      expression: "searchForm.merchantType"
    }
  }, _vm._l(_vm.$formatTypeArray("agentProfitDetailType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间：",
      prop: "dateTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM-dd"
    },
    model: {
      value: _vm.dateTime,
      callback: function ($$v) {
        _vm.dateTime = $$v;
      },
      expression: "dateTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("查询汇总：  \n    直客商户总计(个)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.summary[0]))]), _vm._v("；\n    渠道商户总计 (个)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.summary[1]))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "企业编号",
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentNo",
      label: "上级服务商编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentName",
      label: "上级服务商名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantType",
      label: "类型",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("agentProfitDetailType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "所属地区",
      "min-width": "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s(scope.row.province) + "/" + _vm._s(scope.row.city) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "120",
      formatter: _vm.$formatTypeInTable("agentMerchantStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      "min-width": "180"
    }
  }), _vm._v(" "), _vm.viewMerchantPath ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "80",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.merchantType === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e()];
      }
    }], null, false, 3950608089)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };