import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { getAcountInfo } from '@/utils/auth';
import { mapGetters } from 'vuex';
import { timePickerOptions, separateDate } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import ElLink from 'element-ui/packages/link/src/main';
import { INVOICE_OPERATION_PERMISSION, TASK_AUDIT_ID } from '@/utils/idName';
import { getTaskTypeSelect } from '@/api/task';
import { getTaskRecordList } from '@/api/task';
import AuditTaskDialog from './auditDialog';
import { TASK_RECORD_ID } from '../../../utils/idName';
import { isJobOpen, merchantShowList } from '../../../api/merchant';
import JobOpen from '../../../base/ui/jobOpen';
import BatchTaskFiles from './batchFilesDialog';
import UpdateRecordDialog from './updateRecordDialog';
import PreviewDialog from './preview2';
import { downLoadTaskRecord, preDownLoadTaskRecord } from '../../../api/task';
import { downloadAllFile, getCurrentDay } from '../../../utils/util';
import ImportTaskDialog from './importTaskDialog';
export default {
  name: 'saveRecordListContent',
  // eslint-disable-next-line no-dupe-keys
  components: {
    ImportTaskDialog,
    PreviewDialog,
    UpdateRecordDialog,
    BatchTaskFiles,
    JobOpen,
    AuditTaskDialog,
    ElLink,
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      saveRecordAuditPath: '',
      viewTaskPath: '',
      batchTaskFilesPath: '',
      updateRecordsPath: '',
      batchAuditVisible: false,
      batchTaskFilesDialog: false,
      importDialog: false,
      updateRecordsDialog: false,
      previewDialogVisible: false,
      exportTemplateVisible: false,
      importTaskVisible: false,
      previewData: {},
      total: 0,
      list: [],
      listLoading: true,
      statusType: this.$formatTypeArray('statusType'),
      updateStatus: {
        id: '',
        status: ''
      },
      createTime: getCurrentDay(),
      auditTime: [],
      searchForm: {
        merchantNo: '',
        beginTime: '',
        endTime: '',
        taxNo: '',
        status: '',
        taskStatus: '',
        taskNo: '',
        taskName: '',
        custName: '',
        idcard: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      isFresh: false,
      partyType: '',
      taxSources: [],
      taskTypeSelectType: [],
      selectItems: [],
      showAuditDialog: false,
      selectIds: [],
      jobLoading: true,
      isOpen: false,
      clickUpdate: false,
      merchantList: [],
      merchantName: '',
      typeSelectNoDataText: '请选择所属商户',
      rules: {
        merchantNo: [{
          required: true,
          message: '请选择所属商户',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap', 'updateRecordResponse']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getJobOpen();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.saveRecordAuditPath = map.get('saveRecordAudit');
      this.viewTaskPath = map.get('viewTask');
      this.batchAuditVisible = map.has('batchTaskAudit');
      this.batchTaskFilesPath = map.get('batchTaskFiles');
      this.updateRecordsPath = map.has('updateRecords');
      this.exportTemplateVisible = map.has('exportTemplate');
      this.importTaskVisible = map.has('importTask');
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.getTaxSources();

    // if (this.partyType === 0) {
    //   this.getMerchantList()
    // } else {
    //   this.currentSearch.merchantNo = this.searchForm.merchantNo = user.partyCode
    //   this.merchantName = user.realname
    // }

    if (this.partyType === 3) {
      this.merchantNo = user.partyCode;
      this.currentSearch.merchantNo = this.searchForm.merchantNo = user.partyCode;
      this.merchantName = user.realname;
      this.getTaskTypeList(this.merchantNo);
    } else {
      this.getMerchantList();
    }
    this.getList();
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const qryCreateTime = separateDate(this.createTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      const qryAuditTime = separateDate(this.auditTime);
      this.currentSearch.auditBeginTime = qryAuditTime.beginTime;
      this.currentSearch.auditEndTime = qryAuditTime.endTime;
      getTaskRecordList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.createTime = [];
      this.auditTime = [];
      this.queryForm();
    },
    // 显示查看界面
    handleView(id) {
      sessionStorage.setItem(TASK_RECORD_ID, id);
      this.$router.push({
        path: this.viewTaskPath
      });
    },
    formatCity() {
      if (this.searchForm.provinceCode === '') {
        return '';
      } else {
        return this.searchForm.provinceCode + '/' + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('statusType', row.status);
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    gotoDetail(invoiceId) {
      sessionStorage.setItem(INVOICE_OPERATION_PERMISSION, invoiceId);
      this.$router.push('/invoice/list/detail');
    },
    gotoAudit(row) {
      this.clickUpdate = true;
      sessionStorage.setItem(TASK_AUDIT_ID, row.id);
      this.$router.push({
        path: this.saveRecordAuditPath
      });
    },
    auditDialogChang(val) {
      this.showAuditDialog = val;
      this.getList();
    },
    batchAudit() {
      if (this.selectItems.length === 0) {
        this.$message.error('请选择记录');
        return;
      }
      this.selectIds = [];
      this.selectItems.forEach(item => {
        this.selectIds.push(item.id);
      });
      this.showAuditDialog = true;
    },
    handleSelectionChange(val) {
      this.selectItems = val;
    },
    selectable(row) {
      if (row.status === 1) {
        return true;
      } else {
        return false;
      }
    },
    getJobOpen() {
      this.jobLoading = true;
      isJobOpen().then(response => {
        this.jobLoading = false;
        this.isOpen = response.data;
      });
    },
    showBatchFilesDialog() {
      this.batchTaskFilesDialog = true;
    },
    showImportDialog() {
      this.importDialog = true;
    },
    changeDialogVisible(val) {
      this.batchTaskFilesDialog = val;
    },
    changeUpdateRecordsDialog(val) {
      this.updateRecordsDialog = val;
      const data = this.updateRecordResponse;
      if (data.totalNum > 0) {
        this.previewData = data;
        this.previewDialogVisible = true;
      }
    },
    showUpdateRecordsDialog() {
      this.updateRecordsDialog = true;
    },
    changeImportDialog(val) {
      this.importDialog = val;
      this.getList();
    },
    changeVisiblePreviewDialog(val) {
      this.previewDialogVisible = val;
      if (val === false) {
        this.$store.dispatch('setUpdateRecordResponse', {});
      }
    },
    showExportTemplateDialog() {
      this.$refs['searchForm'].validate(valid => {
        if (valid) {
          this.listLoading = true;
          const qryCreateTime = separateDate(this.createTime);
          this.currentSearch.beginTime = qryCreateTime.beginTime;
          this.currentSearch.endTime = qryCreateTime.endTime;
          const qryAuditTime = separateDate(this.auditTime);
          this.currentSearch.auditBeginTime = qryAuditTime.beginTime;
          this.currentSearch.auditEndTime = qryAuditTime.endTime;

          // preDownLoadTaskRecord(this.currentSearch).then(response => {
          //   const data = response.data.split('#')
          //   this.$confirm('本次将导出' + data[0] + '个任务，共计' + data[1] + '条提交记录，是否确定继续？', '温馨提示', {
          //     type: 'plain',
          //     closeOnClickModal: false,
          //     confirmButtonText: '继续导出'
          //   }).then(() => {
          downLoadTaskRecord(this.currentSearch).then(response => {
            this.listLoading = false;
            downloadAllFile(response.data, response.headers.filename, this);
          }).catch(error => {
            this.listLoading = false;
            this.$message.error(error.errMsg);
          });
          // }).catch(() => {})
          // })
        } else {
          return false;
        }
      });
    },
    getTaskTypeList(val) {
      getTaskTypeSelect(val).then(resonse => {
        this.taskTypeSelectType = resonse.data;
        if (this.taskTypeSelectType.length === 0) {
          this.typeSelectNoDataText = '无数据';
        }
      });
    },
    merchantChange(val) {
      if (val === '') {
        this.taskTypeSelectType = [];
        this.typeSelectNoDataText = '请选择所属商户';
      } else {
        this.getTaskTypeList(val);
      }
    }
  }
};