var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "600px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "班组审核"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票订单编号"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.serialNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.taxName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票金额"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.invoiceAmount) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "审核备注",
      prop: "auditRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "64",
      placeholder: "请输入审核备注",
      clearable: ""
    },
    model: {
      value: _vm.addForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "auditRemark", $$v);
      },
      expression: "addForm.auditRemark"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      disabled: _vm.loading,
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      disabled: _vm.loading,
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("拒绝")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };