import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { updateSeqNumTaxRouter } from '../../../../api/taxRouter';
export default {
  name: 'updateTaxRouterDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        id: '',
        seqNumber: ''
      },
      taxList: [],
      myDialogFormVisible: false,
      rules: {
        seqNumber: [{
          required: true,
          message: '请输入路由顺序',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    updateDialogVisible: false,
    id: '',
    taxName: '',
    seqNumber: ''
  },
  watch: {
    updateDialogVisible(val) {
      this.myDialogFormVisible = val;
      this.saveForm.seqNumber = this.seqNumber;
    },
    myDialogFormVisible(val) {
      this.$emit('changeUpdateDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    updateBtn() {
      this.saveForm.id = this.id;
      // this.saveForm.status = this.status
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateSeqNumTaxRouter(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};