var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "segement-container"
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "人员记录"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字："
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入姓名/手机号/身份证号/银行卡号"
    },
    model: {
      value: _vm.searchForm.keyWords,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "keyWords", $$v);
      },
      expression: "searchForm.keyWords"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "认证状态："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择认证状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.empStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证照片："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择身份证照片",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.idcardPicStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "idcardPicStatus", $$v);
      },
      expression: "searchForm.idcardPicStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("isHasNo"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "更新时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.updateDatePicker,
      callback: function ($$v) {
        _vm.updateDatePicker = $$v;
      },
      expression: "updateDatePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.addEmp ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empNo",
      label: "编号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empName",
      label: "姓名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "银行卡号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "reservePhone",
      label: "手机号码",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "authType",
      label: "认证方式",
      formatter: _vm.formatAuthTypes,
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "认证状态",
      formatter: _vm.formatEmpStatus,
      "min-width": "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcardPicStatus",
      label: "身份证照片",
      formatter: _vm.$formatTypeInTable("isHasNo"),
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "commonAddress",
      label: "常驻地址",
      width: "100",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      "min-width": "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "更新时间",
      "min-width": "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticClass: "make-class"
        }, [_vm.viewMerchantEmpSignListInfo ? _c("el-button", {
          key: 1,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.viewSignInfo(scope.$index, scope.row);
            }
          }
        }, [_vm._v("签约详情")]) : _vm._e(), _vm._v(" "), _vm.downPersonPicPermission && scope.row.status === 1 ? _c("el-button", {
          key: 3,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadPersonPicFile(scope.row);
            }
          }
        }, [_vm._v("下载证件照")]) : _vm._e(), _vm._v(" "), _vm.viewPersonPicPermission && (scope.row.idcardBack || scope.row.idcardFront) ? _c("el-button", {
          key: 5,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.viewPersonPicFile(scope.row);
            }
          }
        }, [_vm._v("查看证件照")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1), _vm._v(" "), _c("invite-employee-dialog", {
    attrs: {
      dialogFormVisible: _vm.inviteDialog,
      item: _vm.inviteItem
    },
    on: {
      changeVisibleInviteDialog: _vm.changeVisibleInviteDialog
    }
  }), _vm._v(" "), _c("audit-employee-dialog", {
    attrs: {
      dialogFormVisible: _vm.auditDialogVisible,
      item: _vm.auditItem
    },
    on: {
      changeVisibleAuditDialog: _vm.changeVisibleAuditDialog
    }
  }), _vm._v(" "), _c("view-id-card-dialog", {
    attrs: {
      dialogVisible: _vm.viewIdCardDialogVisible,
      idcardBack: _vm.idcardBack,
      idcardFront: _vm.idcardFront,
      halfBody: _vm.halfBody
    },
    on: {
      changeVisibleDialog: _vm.changeVisibleViewIdDialog
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };