import ItemTitle from '@/base/ui/title/itemTitle';
import { getAcountInfo } from '@/utils/auth';
import { mchntProjectInfo } from '@/api/merchant';
import { PROJECT_NO } from '@/utils/idName';
export default {
  components: {
    ItemTitle
  },
  name: 'merchantViewBase',
  data() {
    return {
      saveForm: {},
      loading: false,
      id: sessionStorage.getItem(PROJECT_NO)
    };
  },
  created() {
    this.id = this.id || getAcountInfo().partyCode;
    this.freshData();
  },
  methods: {
    freshData() {
      this.loading = true;
      const param = {
        projectNo: this.id
      };
      mchntProjectInfo(param).then(response => {
        this.loading = false;
        this.saveForm = response.data;
      });
    },
    formatMchantInvoiceType(val) {
      return this.$formatType('merchantInvoiceType', val);
    }
  }
};