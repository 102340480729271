import { isMobile, isEmail } from '@/utils/validate_rules';
import { addAddressInfoReq, updateAddressInfoReq } from '@/api/invoice';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'invoice-update-address',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    addressObj: {
      type: Object
    }
  },
  data() {
    return {
      addForm: {
        custName: '',
        mobile: '',
        address: '',
        email: '',
        projectNo: '',
        isDefault: false,
        id: ''
      },
      rules: {
        custName: [{
          required: true,
          message: '请输入收件人姓名',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入收件人手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入收件人详细地址',
          trigger: 'blur'
        }],
        email: [{
          validator: isEmail,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    title: {
      get() {
        return this.addressObj == null ? '新增收件地址' : '修改收件地址';
      }
    }
  },
  watch: {
    addressObj: function (newValue, oldValue) {
      this.addForm.id = newValue != null ? newValue.id : '';
      this.addForm.custName = newValue != null ? newValue.custName : '';
      this.addForm.mobile = newValue != null ? newValue.mobile : '';
      this.addForm.address = newValue != null ? newValue.address : '';
      this.addForm.email = newValue != null ? newValue.email : '';
      this.addForm.projectNo = newValue != null ? newValue.projectNo : '';
      this.addForm.isDefault = newValue != null ? newValue.isDefault === 1 : '';
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          if (this.addressObj == null) {
            // 新增
            const user = getAcountInfo();
            this.addForm.projectNo = user.partyCode;
            this.addAddress();
          } else {
            // 修改
            this.updateAddress();
          }
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      if (this.addressObj == null) {
        this.$refs['addForm'].resetFields();
      } else {
        this.$refs['addForm'].clearValidate();
      }
    },
    addAddress() {
      const param = {
        ...this.addForm
      };
      addAddressInfoReq(param).then(() => {
        this.$message.success('新增地址成功...');
        this.$emit('updated');
      });
    },
    updateAddress() {
      const param = {
        ...this.addForm
      };
      updateAddressInfoReq(param).then(() => {
        this.$message.success('修改地址成功...');
        this.$emit('updated');
      });
    }
  }
};