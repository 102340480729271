const tagsView = {
  state: {
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    ADD_VISITED_VIEWS: (state, view) => {
      const fullPath = view.fullPath && view.fullPath.indexOf('?') > -1 ? view.fullPath.substring(0, view.fullPath.indexOf('?')) : view.fullPath;
      const index = state.visitedViews.findIndex(v => {
        const tem = v.path.indexOf('?') > -1 ? v.path.substring(0, v.path.indexOf('?')) : v.path;
        return tem === fullPath;
      });
      // state.visitedViews.some(v => v.path.indexOf('?') > -1 ? v.path.substring(0, v.path.indexOf('?')) : v.path === fullPath)
      if (index === -1) {
        state.visitedViews.push({
          name: view.name,
          path: view.fullPath,
          title: view.meta.title || 'no-name'
        });
        if (!view.meta.noCache) {
          state.cachedViews.push(view.name);
        }
      } else {
        // 存在且是参数路由
        if (view.fullPath.indexOf('?') > -1) {
          state.visitedViews.splice(index, 1);
          state.visitedViews.push({
            name: view.name,
            path: view.fullPath,
            title: view.meta.title || 'no-name'
          });
        }
      }
    },
    DEL_VISITED_VIEWS: (state, view) => {
      const unqie = view.path.indexOf('?') > -1 ? view.path.substring(0, view.path.indexOf('?')) : view.path;
      for (const [i, v] of state.visitedViews.entries()) {
        if ((v.path.indexOf('?') > -1 ? v.path.substring(0, v.path.indexOf('?')) : v.path === unqie) && (state.visitedViews.length > 1 || v.name !== 'home')) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
      for (const i of state.cachedViews) {
        if (i === view.name && (state.cachedViews.length > 1 || view.name !== 'home')) {
          const index = state.cachedViews.indexOf(i);
          state.cachedViews.splice(index, 1);
          break;
        }
      }
    },
    DEL_VISITED_VIEWS_PATH: (state, path, name) => {
      const unqie = path.indexOf('?') > -1 ? path.substring(0, path.indexOf('?')) : path;
      let _name = name;
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path.indexOf('?') > -1 ? v.path.substring(0, v.path.indexOf('?')) : v.path === unqie) {
          if (!_name) {
            _name = v.name;
          }
          state.visitedViews.splice(i, 1);
          break;
        }
      }
      for (const i of state.cachedViews) {
        if (i === _name) {
          const index = state.cachedViews.indexOf(i);
          state.cachedViews.splice(index, 1);
          break;
        }
      }
    },
    DEL_OTHERS_VIEWS: (state, view) => {
      const unqie = view.path.indexOf('?') > -1 ? view.path.substring(0, view.path.indexOf('?')) : view.path;
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path.indexOf('?') > -1 ? v.path.substring(0, v.path.indexOf('?')) : v.path === unqie) {
          state.visitedViews = state.visitedViews.slice(i, i + 1);
          break;
        }
      }
      for (const i of state.cachedViews) {
        if (i === view.name) {
          const index = state.cachedViews.indexOf(i);
          state.cachedViews = state.cachedViews.slice(index, i + 1);
          break;
        }
      }
    },
    DEL_ALL_VIEWS: state => {
      state.visitedViews = [];
      state.cachedViews = [];
    }
  },
  actions: {
    addVisitedViews({
      commit
    }, view) {
      commit('ADD_VISITED_VIEWS', view);
    },
    delVisitedViews({
      commit,
      state
    }, view) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEWS', view);
        resolve([...state.visitedViews]);
      });
    },
    delOthersViews({
      commit,
      state
    }, view) {
      return new Promise(resolve => {
        commit('DEL_OTHERS_VIEWS', view);
        resolve([...state.visitedViews]);
      });
    },
    delAllViews({
      commit,
      state
    }) {
      return new Promise(resolve => {
        commit('DEL_ALL_VIEWS');
        resolve([...state.visitedViews]);
      });
    },
    delVisitedViewsByPath({
      commit,
      state
    }, path, name) {
      return new Promise(resolve => {
        commit('DEL_VISITED_VIEWS_PATH', path, name);
        resolve([...state.visitedViews]);
      });
    }
  }
};
export default tagsView;