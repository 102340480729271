var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "发票信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票抬头：",
      value: _vm.infoForm.merchantName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "税务登记号：",
      value: _vm.infoForm.invoiceNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票类型：",
      value: _vm.invoiceType,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "发票内容：",
      value: _vm.infoForm.contents,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "开户银行：",
      value: _vm.infoForm.bankName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开户账户：",
      value: _vm.infoForm.accountNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "注册电话：",
      value: _vm.infoForm.companyNumber,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "注册地址：",
      value: _vm.infoForm.companyAddress,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.amount),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开票备注：",
      value: _vm.infoForm.remark,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "是否超额：",
      value: _vm.infoForm.exceedQuotaName,
      customValueClass: {
        "over-value": _vm.infoForm.exceedQuota == "1"
      },
      left: true
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "收件信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收件人：",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "联系电话：",
      value: _vm.infoForm.mobile,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收件地址：",
      value: _vm.infoForm.address,
      "full-line": ""
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "物流公司：",
      value: _vm.infoForm.logistics,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "快递单号：",
      value: _vm.infoForm.expressNumber,
      left: false
    }
  }, [_c("el-link", {
    staticClass: "link",
    attrs: {
      type: "primary",
      href: "https://www.kuaidi100.com/",
      target: "_blank",
      underline: false
    }
  }, [_vm._v("快递查询")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "状态信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "申请时间：",
      value: _vm.infoForm.applyTime,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-value-class": "invoice-status",
      title: "发票状态：",
      value: _vm.status,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "审核时间：",
      value: _vm.infoForm.auditTime,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "审核描述：",
      value: _vm.infoForm.auditRemark,
      left: true
    }
  })], 1)], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base-agent border-bg",
    attrs: {
      model: _vm.auditForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "审核描述"
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.check(7);
      }
    }
  }, [_vm._v("同意")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.check(8);
      }
    }
  }, [_vm._v("拒绝")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };