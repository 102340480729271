var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "发票信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票抬头：",
      value: _vm.infoForm.merchantName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "税务登记号：",
      value: _vm.infoForm.invoiceNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票类型：",
      value: _vm.invoiceType,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "发票内容：",
      value: _vm.infoForm.contents,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "开户银行：",
      value: _vm.infoForm.bankName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开户账户：",
      value: _vm.infoForm.accountNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "注册电话：",
      value: _vm.infoForm.companyNumber,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "注册地址：",
      value: _vm.infoForm.companyAddress,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.amount),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "发票编号：",
      value: _vm.infoForm.billInfo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "是否超额：",
      value: _vm.infoForm.exceedQuotaName,
      customValueClass: _vm.infoForm.exceedQuota == "1" ? "over-value" : "",
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开票备注：",
      value: _vm.infoForm.remark,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      "custom-value-class": "invoice-file",
      title: "发票文件：",
      value: "",
      left: true
    }
  }, [_c("div", _vm._l(_vm.infoForm.billList, function (item) {
    return _c("el-link", {
      key: item.id,
      staticClass: "link link-list",
      attrs: {
        type: "primary",
        href: item.fileUrlShow,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName))]);
  }), 1)])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "收件信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收件人：",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "联系电话：",
      value: _vm.infoForm.mobile,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "收件地址：",
      value: _vm.infoForm.address,
      "full-line": ""
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "物流公司：",
      value: _vm.infoForm.logistics,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "快递单号：",
      value: _vm.infoForm.expressNumber,
      left: false
    }
  }, [_c("el-link", {
    staticClass: "link",
    attrs: {
      type: "primary",
      href: "https://www.kuaidi100.com/",
      target: "_blank",
      underline: false
    }
  }, [_vm._v("快递查询\n        ")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "状态信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "申请时间：",
      value: _vm.infoForm.applyTime,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      "custom-value-class": "invoice-status",
      title: "发票状态：",
      value: _vm.status,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "审核时间：",
      value: _vm.infoForm.auditTime,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "审核描述：",
      value: _vm.infoForm.auditRemark,
      left: true
    }
  })], 1)], 1), _vm._v(" "), _vm.infoForm.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.infoForm.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };