import CityCascader from '@/base/ui/cascader/cityCascader';
import { mchntProjectSave, gjhelperSupportReq, gjOemCodeSelectReq, queryGjAgentReq, taxsourceShowlist, merchantShowList, mchntProjectInfo, mchntProjectUpdate } from '@/api/merchant';
import { showSalesmanlist } from '@/api/salesman';
import { showAgentlist } from '@/api/agent';
import { isMobile, isInteger } from '@/utils/validate_rules';
import { validMobile } from '@/utils/validate';
import ItemTitle from '@/base/ui/title/itemTitle';
import { MERCHANT_UPDATE_ID, PROJECT_NO } from '@/utils/idName';
import AddAndEditAgreement from './components/dialog/addAndEditAgreement.vue';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader,
    AddAndEditAgreement
  },
  data() {
    return {
      dialogVisiable: false,
      activeRow: {},
      loading: false,
      taxsourceList: [],
      merchantList: [],
      projectScopeList: [{
        value: '建筑劳务分包',
        label: '建筑劳务分包'
      }],
      merchantInvoiceType: this.$formatTypeArray('merchantInvoiceType'),
      creditCode: '',
      id: sessionStorage.getItem(PROJECT_NO),
      saveForm: {
        provinceCode: '',
        province: '',
        cityCode: '',
        city: '',
        invoiceType: '',
        areaCode: '',
        area: '',
        address: '',
        contactMobile: '',
        contactName: '',
        merchantNo: '',
        projectName: '',
        projectPeriod: '',
        projectScope: '',
        remark: '',
        reviewMobile: '',
        taxNo: ''
      },
      rules: {
        projectName: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }],
        projectScope: [{
          required: true,
          message: '请选择分包范围',
          trigger: 'blur'
        }],
        projectPeriod: [{
          required: true,
          message: '请输入工程工期',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        areaCode: [{
          required: true,
          message: '请选择项目地址',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入项目详细地址',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        contactName: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }],
        contactMobile: [{
          required: true,
          message: '请输入联系人手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属企业',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    placeholder() {
      if (this.saveForm.area) {
        return `${this.saveForm.province}/${this.saveForm.city}/${this.saveForm.area}`;
      } else {
        return '';
      }
    }
  },
  created() {
    this.taxsourceShowlist();
    this.freshData();
  },
  methods: {
    async freshData() {
      this.loading = true;
      const param = {
        projectNo: this.id
      };
      const res = await mchntProjectInfo(param);
      this.saveForm = Object.assign({}, res.data);
      this.loading = false;
    },
    async taxsourceShowlist() {
      let res = await taxsourceShowlist();
      this.taxsourceList = res.data;
    },
    onTaxsourceList(value) {
      this.merchantShowList();
    },
    async merchantShowList() {
      this.merchantList = [];
      this.saveForm.merchantNo = '';
      const params = {
        pageNo: 0,
        pageSize: 300,
        taxNo: this.saveForm.taxNo
      };
      let res = await merchantShowList(params);
      this.merchantList = res.data;
    },
    onMerchantList(value) {
      this.merchantList.forEach(val => {
        if (val.merchantNo === value) {
          this.creditCode = val.licenseNo;
        }
      });
    },
    changeMobile(val) {
      this.saveForm.reviewMobile = this.saveForm.contactMobile;
    },
    updateSubmit() {
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
        this.saveForm.areaCode = this.$refs.proviceCity.selectedOptions[2];
      }
      console.log(this.saveForm);
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          mchntProjectUpdate(this.saveForm).then(response => {
            this.finish();
          });
        } else {
          return false;
        }
      });
    },
    handleEdit(index, row) {
      this.activeRow = row;
      this.dialogVisiable = true;
    },
    handleSuccess() {
      this.freshData();
    },
    finish() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$message.success('修改成功。。。');
        this.$router.go(-1);
      });
    }
  }
};