import UploadFile from '@/base/ui/upload/uploadFile';
import ItemTitle from '@/base/ui/title/itemTitle';
import axios from 'axios';
import UploadMoreFile from '@/base/ui/upload/uploadMoreFile';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'uploadResultDialog',
  components: {
    UploadMoreFile,
    ItemTitle,
    UploadFile
  },
  data() {
    return {
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E5%B7%A1%E6%A3%80%E8%AE%B0%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      uploadApplyFile: BASE_URL + '/trade/repaymentInspect/saveInspect',
      myDialogFormVisible: false,
      exData: {},
      rules: {},
      fileList: [],
      fileName: []
    };
  },
  props: {
    dialogFormVisible: false,
    id: {
      require: true
    }
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  methods: {
    handleUploadSuccess(res, file) {
      this.saveForm.filePath = res.data.saveKey;
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.$refs['saveForm'].resetFields();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.$message.success('提交成功');
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    },
    submitFileForm() {
      if (this.fileList.length == 0) {
        this.$message.error('请选择上传的文件');
        return;
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          // 创建新的数据对象
          const formData = new FormData();
          // 将上传的文件放到数据对象中
          this.fileList.forEach(file => {
            formData.append('file', file.raw);
            this.fileName.push(file.name);
          });
          // 自定义上传
          axios.post(this.uploadApplyFile, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if (response.data.code === 'BASE.000') {
              this.$message.success('导入成功！');
              this.dialogClosed();
              this.myDialogFormVisible = false;
            } else {
              this.$message.error(response.data.errMsg);
            }
          });
        } else {
          return false;
        }
        this.$refs.upload.clearFiles();
        this.fileList = [];
      });
    }
  }
};