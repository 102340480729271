import { savePartyRole, getSysRole } from '@/api/sys';
export default {
  name: 'add-partyrole',
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        roleType: 1,
        roleId: '',
        partyType: 1
      },
      addRules: {
        roleId: [{
          required: true,
          message: '请选择角色名称',
          trigger: 'change'
        }]
      },
      partyTypeArr: this.$formatTypeArray('partyType'),
      roleTypeArr: this.$formatTypeArray('roleType'),
      roleArr: []
    };
  },
  created() {
    this.handleInit();
  },
  methods: {
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          savePartyRole(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    },
    handleInit() {
      getSysRole().then(res => {
        this.roleArr = res.data;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};