import TaxSubList from './subList';
export default {
  components: {
    TaxSubList
  },
  data() {
    return {
      active: 0,
      message: 'first'
    };
  }
};