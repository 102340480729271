import ItemTitle from '@/base/ui/title/itemTitle';
import { getTaxIncomeCharts } from '@/api/stat';
import DbsHistogram from '../../../components/chart/dbs-histogram';
export default {
  name: 'taxMonthCharts',
  components: {
    DbsHistogram,
    ItemTitle
  },
  data() {
    const chartSettings = {
      // itemStyle: {
      //   color: '#2DA8FF'
      // },
      labelMap: {
        'totalAmountMillion': '服务费总额',
        'payAgentMillion': '服务商分润',
        'profitAmountMillion': '劳务公司收益'
      },
      legendName: {
        'incomeAmountMillion': '服务费总额',
        'payAmountMillion': '服务商分润',
        'profitAmountMillion': '劳务公司收益'
      },
      dataOrder: false
    };
    return {
      chartData: {
        columns: ['month', 'totalAmountMillion', 'payAgentMillion', 'profitAmountMillion'],
        rows: []
      },
      chartSettings: chartSettings
    };
  },
  props: {
    searchForm: {
      required: true,
      default: () => {
        return {
          area: '',
          beginTime: '',
          endTime: '',
          taxNo: ''
        };
      }
    }
  },
  watch: {
    searchForm(val) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList() {
      getTaxIncomeCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    }
  }
};