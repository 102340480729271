import { render, staticRenderFns } from "./noticeGongGao.vue?vue&type=template&id=f487b948&scoped=true"
import script from "./noticeGongGao.vue?vue&type=script&lang=js"
export * from "./noticeGongGao.vue?vue&type=script&lang=js"
import style0 from "./noticeGongGao.vue?vue&type=style&index=0&id=f487b948&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f487b948",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f487b948')) {
      api.createRecord('f487b948', component.options)
    } else {
      api.reload('f487b948', component.options)
    }
    module.hot.accept("./noticeGongGao.vue?vue&type=template&id=f487b948&scoped=true", function () {
      api.rerender('f487b948', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/first/components/noticeGongGao.vue"
export default component.exports