import { mapGetters } from 'vuex';
import '@/icons';
export default {
  name: 'logo',
  data() {
    return {};
  },
  props: ['isCollapse'],
  created() {},
  computed: {
    ...mapGetters(['website']),
    type: function (val) {
      return this.website.logo.indexOf('static') !== -1;
    }
  },
  methods: {}
};