import ItemTitle from '@/base/ui/title/itemTitle';
import { beforUploadByExcel } from '../../../../utils/util';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'saveLsswkpDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E5%A4%A7%E9%A2%9D%E5%BC%80%E7%A5%A8%E7%99%BB%E8%AE%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      uploadApplyFile: BASE_URL + '/taxdj/taxLsswkp/largeInvoiceRegistration',
      myDialogFormVisible: false,
      rules: {},
      provinceCityInfo: {}
    };
  },
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {},
  methods: {
    submitUpload() {
      // this.$refs['saveForm'].validate((valid) => {
      //   if (valid) {
      //     this.$refs.upload.submit()
      //   } else {
      //     return false
      //   }
      // })

      this.$refs.upload.submit();
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.$refs['saveForm'].resetFields();
    },
    beforeUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.$message.success('提交成功');
        this.myDialogFormVisible = false;
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    }
  }
};