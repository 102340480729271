var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "上传附件"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    staticClass: "form-table",
    attrs: {
      size: "mini",
      "label-position": "left",
      "label-width": "100px",
      rules: _vm.rules,
      model: _vm.addForm
    }
  }, [_vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属商户"
    },
    on: {
      change: _vm.invoiceSelectList
    },
    model: {
      value: _vm.addForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "merchantNo", $$v);
      },
      expression: "addForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _c("el-form-item", {
    attrs: {
      label: "所属商户："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.merchantName) + "(" + _vm._s(_vm.addForm.merchantNo) + ")\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票订单：",
      prop: "invoiceSerialNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择开票订单"
    },
    model: {
      value: _vm.addForm.invoiceSerialNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "invoiceSerialNo", $$v);
      },
      expression: "addForm.invoiceSerialNo"
    }
  }, _vm._l(_vm.invoiceList, function (tax) {
    return _c("el-option", {
      key: tax.value,
      attrs: {
        label: tax.text,
        value: tax.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传附件信息：",
      prop: "saveKey"
    }
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "file-list": _vm.fileList,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeDemoUpload,
      "on-exceed": _vm.handleExceed,
      "on-change": _vm.changeFiles,
      action: _vm.saveFile,
      limit: 1,
      data: {
        partyCode: _vm.addForm.merchantNo,
        invoiceSerialNo: _vm.addForm.invoiceSerialNo
      }
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("div", {
    staticClass: "title-span"
  }, [_vm._v("请先上传更新记录后再上传任务附件，仅支持上传.zip格式压缩包，单次上传。\n            文件不得超过300M。重复提交将覆盖任务记录的原附件。")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("item-title", {
    attrs: {
      name: "操作指引"
    }
  }), _vm._v(" "), _vm._m(0)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "font-size": "1.4rem",
      "line-height": "25px"
    }
  }, [_c("div", [_vm._v("1.提交附件前请确认已完成更新任务记录操作；")]), _vm._v(" "), _c("div", [_vm._v("2.请将每个任务提交记录的附件以单独的文件夹存放，并根据对应任务记录的附件名称为文件夹进行命名；")]), _vm._v(" "), _c("div", [_vm._v("3.请将本次要提交的所有文件夹打包成一个.zip格式的压缩包（压缩包大小不能超过300M），再在本页面进行上传。")]), _vm._v(" "), _c("div", [_vm._v("4.完成上传操作后系统后台将进行解析入库，您可前往任务提交记录页面查看解析结果。")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };