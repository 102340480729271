import { merchantInfo } from '@/api/merchant';
import { MERCHANT_UPDATE_ID } from '@/utils/idName';
import MerchantUpdateBankCard from './components/update/updateBankCard';
import MerchantUpdateBase from './components/update/updateBase';
export default {
  components: {
    MerchantUpdateBase,
    MerchantUpdateBankCard
  },
  name: 'updateMerchant',
  data() {
    return {
      active: 0,
      message: 'first',
      bean: {
        feeConfig: {},
        bankCardList: [],
        merchantBase: {
          merchantNo: ''
        },
        signConfigList: []
      },
      merchantNo: '',
      taxNo: '',
      gjAgentInfo: {},
      gjHelperSupport: ''
    };
  },
  created() {
    this.freshData();
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.freshData();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  watch: {
    merchantNo(val) {
      this.merchantNo = val;
    }
  },
  methods: {
    freshData() {
      const param = {
        id: sessionStorage.getItem(MERCHANT_UPDATE_ID),
        operateType: 1 // 操作类型(0-查看详情, 1-编辑修改)
      };
      merchantInfo(param).then(response => {
        this.bean = response.data;
        this.gjAgentInfo = response.data.gjAgent || {};
        this.gjHelperSupport = response.data.gjHelperSupport;
        this.merchantNo = this.bean.merchantBase.merchantNo;
      });
    }
  }
};