var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "余额预警设置"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "border-bg",
    staticStyle: {
      width: "70%"
    },
    attrs: {
      size: "mini",
      "label-width": "150px",
      model: _vm.merchantInfo,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否余额预警：",
      prop: "warnSwitch"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.merchantInfo.warnSwitch,
      callback: function ($$v) {
        _vm.$set(_vm.merchantInfo, "warnSwitch", $$v);
      },
      expression: "merchantInfo.warnSwitch"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "单日预警次数：",
      prop: "warnCount"
    }
  }, [_c("el-input-number", {
    attrs: {
      "controls-position": "right",
      min: 0,
      max: 10
    },
    model: {
      value: _vm.merchantInfo.warnCount,
      callback: function ($$v) {
        _vm.$set(_vm.merchantInfo, "warnCount", $$v);
      },
      expression: "merchantInfo.warnCount"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "余额预警阈值：",
      prop: "warnValue"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入预警阈值"
    },
    model: {
      value: _vm.merchantInfo.warnValue,
      callback: function ($$v) {
        _vm.$set(_vm.merchantInfo, "warnValue", $$v);
      },
      expression: "merchantInfo.warnValue"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("* 打开余额预警，当企业在签约劳务公司的账户可用余额小于阈值时触发预警")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "通知手机号码：",
      prop: "warnContacts"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "36",
      placeholder: "请输入接收预警手机号信息，多个以半角“,”分隔，最多输入36个字符"
    },
    model: {
      value: _vm.merchantInfo.warnContacts,
      callback: function ($$v) {
        _vm.$set(_vm.merchantInfo, "warnContacts", $$v);
      },
      expression: "merchantInfo.warnContacts"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("* 企业账户的入账信息，余额预警信息将发送至配置的手机号码")])], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.doSubmit();
      }
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };