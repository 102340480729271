import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import axios from 'axios';
import { beforUploadByExcel } from '@/utils/util';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'addWszmdjDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E4%B8%AA%E4%BD%93%E6%88%B7%E6%B3%A8%E5%86%8C%E7%99%BB%E8%AE%B0%E6%A8%A1%E6%9D%BF.xlsx',
      uploadApplyFile: BASE_URL + '/taxdj/taxGthdj/saveGthdj',
      taxList: [],
      exData: {
        taxNo: ''
      },
      myDialogFormVisible: false,
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }]
      },
      fileList: [],
      fileName: []
    };
  },
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      if (val) {
        this.getTaxsList();
      }
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    submitUpload() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
          // debugger
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxList = response.data;
      });
    },
    beforeUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.$message.success('提交成功');
        this.myDialogFormVisible = false;
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    },
    submitFileForm() {
      if (this.fileList.length <= 0) {
        this.$message.error('请选择上传的文件。');
        return;
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          // 创建新的数据对象
          const formData = new FormData();
          // 将上传的文件放到数据对象中
          this.fileList.forEach(file => {
            formData.append('file', file.raw);
            this.fileName.push(file.name);
          });
          formData.append('taxNo', this.exData.taxNo);

          // 自定义上传
          axios.post(this.uploadApplyFile, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if (response.data.code === 'BASE.000') {
              this.$refs.upload.clearFiles();
              this.$message.success('上传成功！');
              this.myDialogFormVisible = false;
            } else {
              this.$message.error(response.data.errMsg);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};