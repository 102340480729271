import request from '@/utils/request';

/**
 * 获取忘记密码动态验证码*/
export function getDynamicCode(phone, userName, taxBrand) {
  return request({
    url: '/api/dynamiccode/forgetPwd',
    method: 'post',
    data: {
      phone,
      userName,
      taxBrand
    }
  });
}
/**
 * 获取充值复核密码重置验证码
 */
export function resetRechargePwd() {
  return request({
    url: '/api/dynamiccode/resetRechargePwd',
    method: 'post',
    data: {}
  });
}
/**
 * 获取登录密码动态验证码*/
export function loginCode(userName, taxBrand) {
  return request({
    url: '/api/dynamiccode/loginCode',
    method: 'post',
    data: {
      userName,
      taxBrand
    }
  });
}

/**
 * 获取复核验证码
 */
export function payVerifyCode(phone, taxBrand) {
  return request({
    url: '/api/dynamiccode/payVerifyCode',
    method: 'post',
    data: {
      phone,
      taxBrand
    }
  });
}