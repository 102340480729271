import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import RateInfoDialog from './rateInfoDialog';
import { merchantSignInfo } from '@/api/taxMerchant';
import { downloadAllFile } from '@/utils/util';
import { downloadFile } from '@/api/upload';
import { mchntProjectInfo } from '@/api/merchant';
import { PROJECT_NO } from '@/utils/idName';
import { getAcountInfo } from '@/utils/auth';
export default {
  components: {
    RateInfoDialog,
    ItemTitle
  },
  name: 'MerchantViewSignConfig',
  data() {
    return {
      dialogFormVisible: false,
      rateInfo: {},
      signConfigList: [],
      id: sessionStorage.getItem(PROJECT_NO)
    };
  },
  created() {
    this.id = this.id || getAcountInfo().partyCode;
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      const param = {
        projectNo: this.id
      };
      mchntProjectInfo(param).then(response => {
        that.signConfigList = response.data.signConfigList;
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, 1, 100);
    },
    formatStatus(row, column) {
      return this.$formatType('bankType', row.status);
    },
    showRateInfoDialog(row) {
      merchantSignInfo(row.id).then(response => {
        this.rateInfo = response.data;
      });
      this.dialogFormVisible = true;
    },
    downloadProtocolUrl(row) {
      downloadFile(row.protocolUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename);
      });
    },
    changeVisible2(val) {
      // 监听新增dialog的dialogFormVisible的变化
      this.dialogFormVisible = val;
    }
  }
};