import { getApiInfo } from '@/api/sys';
import { API_INFO_ID } from '@/utils/idName';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        name: '',
        type: '',
        status: '',
        permission: '',
        url: '',
        description: ''
      },
      typeArr: this.$formatTypeArray('apiType'),
      statusArr: this.$formatTypeArray('apiStatus')
    };
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      const id = sessionStorage.getItem(API_INFO_ID);
      if (id) {
        getApiInfo(id).then(response => {
          this.editForm = response.data;
        });
      }
    },
    back() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};