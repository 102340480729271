import { bannerInfoReg } from '../../../api/sys';
import UploadImage from '../../../base/ui/upload/uploadImage';
import { BANNER_INFO_ID } from '../../../utils/idName';
import ElImage from 'element-ui/packages/image/src/main';
export default {
  components: {
    ElImage,
    UploadImage
  },
  data() {
    return {
      id: '',
      saveForm: {
        appCode: '',
        appGroup: '',
        imgUrl: '',
        linkUrl: '',
        seqNum: '',
        status: '',
        summary: '',
        title: ''
      }
    };
  },
  created() {
    this.id = sessionStorage.getItem(BANNER_INFO_ID);
    this.getInfo();
  },
  methods: {
    getInfo() {
      bannerInfoReg(this.id).then(response => {
        this.saveForm = response.data;
      });
    }
  }
};