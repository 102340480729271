var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: _vm.title
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_vm.partyType !== 4 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择劳务公司"
    },
    on: {
      change: _vm.getMerchantList
    },
    model: {
      value: _vm.searchForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxSource", $$v);
      },
      expression: "searchForm.taxSource"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.partyType !== 4 ? _c("el-form-item", {
    attrs: {
      label: "所属甲方单位：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择所属甲方单位",
      clearable: "",
      filterable: "",
      disabled: !_vm.searchForm.taxSource || _vm.merchantList.length === 0
    },
    on: {
      change: _vm.merchantSelectChange
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.partyType !== 4 ? _c("el-form-item", {
    attrs: {
      label: "所属项目：",
      prop: "projectNo"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择所属项目",
      clearable: "",
      filterable: "",
      disabled: !_vm.searchForm.merchantNo || _vm.projectList.length === 0
    },
    model: {
      value: _vm.searchForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectNo", $$v);
      },
      expression: "searchForm.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: _vm.concatProject(item),
        value: item.projectNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "批次编号",
      prop: "batchNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入批次编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.batchNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "batchNo", $$v);
      },
      expression: "searchForm.batchNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "批次描述",
      prop: "repayDesc"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入批次描述",
      maxlength: "100",
      "show-word-limit": ""
    },
    model: {
      value: _vm.searchForm.repayDesc,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "repayDesc", $$v);
      },
      expression: "searchForm.repayDesc"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "渠道来源",
      prop: "oemName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入渠道来源",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.oemName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "oemName", $$v);
      },
      expression: "searchForm.oemName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "业务批次号",
      prop: "bizBatchNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入业务批次号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.bizBatchNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "bizBatchNo", $$v);
      },
      expression: "searchForm.bizBatchNo"
    }
  })], 1), _vm._v(" "), _vm.partyType != 3 ? [_c("el-form-item", {
    attrs: {
      label: "客户编号",
      prop: "merchantNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户名称",
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入客户名称"
    },
    model: {
      value: _vm.searchForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantName", $$v);
      },
      expression: "searchForm.merchantName"
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择批次状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (option) {
    return _c("el-option", {
      key: option.label,
      attrs: {
        label: option.name,
        value: option.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "提交时间",
      prop: "date"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.syncRepayVisible ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-mouse"
    },
    on: {
      click: _vm.syncRepayBatch
    }
  }, [_vm._v("一键同步")]) : _vm._e()], 1)], 2), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("查询汇总：  批次数量  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.total))]), _vm._v(" \n     笔；  汇总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.totalAmt)))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      "margin-top": "30px",
      flex: "1"
    },
    attrs: {
      data: _vm.batches,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectName",
      label: "项目名称",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectNo",
      label: "项目子编号",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "120",
      formatter: _vm.$formatTypeInTable("batchStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "batchNo",
      label: "批次编号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelTypeName",
      label: "通道类型",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelName",
      label: "通道名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "chargeType",
      label: "扣费模式",
      "min-width": "110",
      formatter: _vm.$formatTypeInTable("chargeType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "total",
      label: "汇总笔数",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-popover", {
          attrs: {
            trigger: "hover",
            placement: "top"
          }
        }, [_c("p", [_vm._v("成功: " + _vm._s(scope.row.successCount) + "笔")]), _vm._v(" "), _c("p", [_vm._v("失败: " + _vm._s(scope.row.failCount) + "笔")]), _vm._v(" "), _c("div", {
          staticClass: "name-wrapper",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_c("el-tag", {
          attrs: {
            size: "medium"
          }
        }, [_vm._v(_vm._s(scope.row.total))])], 1)])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayDesc",
      label: "批次描述",
      "min-width": "130",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "totalAmount",
      label: "汇总金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "source",
      label: "批次来源",
      width: "90",
      formatter: _vm.$formatTypeInTable("orderSource"),
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "提交时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "quotaTime",
      label: "复核时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bizBatchNo",
      label: "业务批次号\t",
      width: "170"
    }
  }), _vm._v(" "), _vm.hasDetailPermission || _vm.hasCheckPermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasDetailPermission ? _c("el-button", {
          key: "1",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.checkRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm.hasCheckPermission && scope.row.status === 0 ? _c("el-button", {
          key: "2",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.reviewRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("复核")]) : _vm._e(), _vm._v(" "), _vm.hasDetailPermission ? _c("el-button", {
          key: "3",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("下载")]) : _vm._e(), _vm._v(" "), _vm.hasCancelPermission && scope.row.status === 0 ? _c("el-button", {
          key: "4",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.cancel(scope.$index, scope.row);
            }
          }
        }, [_vm._v("撤销")]) : _vm._e(), _vm._v(" "), _vm.hasDeletePermission && scope.row.status === 3 && scope.row.channelType === "PAY_SPECIAL" && [0, 2].includes(scope.row.deleteStatus) ? _c("el-button", {
          key: "5",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onDelete(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e(), _vm._v(" "), _vm.hasReviewPermission && scope.row.deleteStatus === 1 ? _c("el-button", {
          key: "6",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onDeleteReview(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除复核")]) : _vm._e()];
      }
    }], null, false, 1522478507)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("dialog-delete-review", {
    attrs: {
      id: _vm.selectId,
      "is-visible": _vm.reviewVisible
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.reviewVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.reviewVisible = $event;
      },
      refresh: _vm.queryForm
    }
  }), _vm._v(" "), _c("dialog-delete", {
    attrs: {
      id: _vm.selectId,
      "is-visible": _vm.delVisible
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.delVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.delVisible = $event;
      },
      refresh: _vm.queryForm
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };