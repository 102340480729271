var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini",
      "label-width": "80px",
      "label-position": "left"
    }
  }, [_vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "签约劳务公司"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.taxName,
      disabled: ""
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      label: "签约劳务公司",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择签约劳务公司"
    },
    model: {
      value: _vm.searchForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxSource", $$v);
      },
      expression: "searchForm.taxSource"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.partyType != 3 ? _c("el-form-item", {
    attrs: {
      label: "甲方单位名称",
      prop: "customerName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入甲方单位名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerName", $$v);
      },
      expression: "searchForm.customerName"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "业务订单号",
      prop: "bussincessNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入业务订单号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.bussincessNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "bussincessNo", $$v);
      },
      expression: "searchForm.bussincessNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "企业订单号",
      prop: "orderNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入企业订单号"
    },
    model: {
      value: _vm.searchForm.orderNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "orderNo", $$v);
      },
      expression: "searchForm.orderNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "差错类型",
      prop: "reconStatus"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择差错类型"
    },
    model: {
      value: _vm.searchForm.reconStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "reconStatus", $$v);
      },
      expression: "searchForm.reconStatus"
    }
  }, _vm._l(_vm.businessOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "交易时间 ",
      prop: "submitDate",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.submitDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "submitDate", $$v);
      },
      expression: "searchForm.submitDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "完成时间",
      prop: "completeDate",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.completeDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "completeDate", $$v);
      },
      expression: "searchForm.completeDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("查询汇总：  交易笔数  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.total))]), _vm._v(" \n     笔；  交易金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.totalAmt)))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.transactions,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "交易时间",
      width: "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "completeTime",
      label: "完成时间",
      width: "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "企业编号",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "180",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "业务订单号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "orderNo",
      label: "企业订单号",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "签约劳务公司",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelName",
      label: "付款通道",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "收款户名",
      width: "90",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "收款账户",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "账户类型",
      width: "100",
      formatter: _vm.$formatTypeInTable("accountType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayAmount",
      label: "交易金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountAmount",
      label: "实付金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "reconStatus",
      label: "差错类型",
      width: "100",
      formatter: _vm.$formatTypeInTable("reconStatus2")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "60",
      fixed: "right",
      align: "center"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };