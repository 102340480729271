var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.saveFile,
      "show-file-list": false,
      "on-success": _vm.onSuccess,
      "before-upload": _vm.beforeAvatarUpload,
      data: {
        bizCode: _vm.partyCode
      }
    }
  }, [_vm.selfSrc ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.selfSrc
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })]), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：上传JPG/PNG文件，不超过2M)")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };