import ItemTitle from '@/base/ui/title/itemTitle';
import { checkHalfBody, getEmployeeInfo } from '../../../api/operateEmp';
import { getAcountInfo } from '../../../utils/auth';
import ElRadioGroup from 'element-ui/packages/radio/src/radio-group';
import ElRadio from 'element-ui/packages/radio/src/radio';
import UploadImage from '../upload/uploadImage';
import { uploadHalfFile } from '@/api/upload';
import { beforUploadImg } from '../../../utils/util';
export default {
  name: 'auditEmployeeDialog',
  components: {
    UploadImage,
    ElRadio,
    ElRadioGroup,
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      info: {},
      saveForm: {
        checkStatus: 1,
        checkRemark: '',
        empNo: '',
        halfBodyUrl: ''
      },
      name: '',
      uploadHalfFile: uploadHalfFile,
      rules: {
        checkRemark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      },
      unRules: {}
    };
  },
  props: {
    dialogFormVisible: false,
    item: ''
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
      if (val === true) {
        this.getInfo();
      }
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleAuditDialog', val);
    }
  },
  created() {
    this.name = getAcountInfo().realname;
  },
  methods: {
    checkSubmit() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.doSubmit();
        } else {
          return;
        }
      });
    },
    doSubmit() {
      this.$confirm('是否确定提交审核？', '提示', {
        closeOnClickModal: false
      }).then(() => {
        checkHalfBody(this.saveForm).then(() => {
          this.$message.success('提交成功。。。');
          this.dialogClosed();
          this.myDialogFormVisible = false;
        });
      }).catch(() => {});
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    getInfo() {
      getEmployeeInfo(this.item.id).then(response => {
        this.info = response.data;
        this.saveForm.halfBodyUrl = this.info.halfBodyUrl;
        this.saveForm.empNo = this.info.empNo;
      });
    },
    uploadImgSuccess(res) {
      this.saveForm.halfBodyUrl = res.data.saveKey;
      this.info.halfBodyUrlShow = res.data.showUrl;
    },
    beforeDemoUpload(file) {
      return beforUploadImg(this, file);
    },
    isRules() {
      return this.saveForm.checkStatus === 0;
    }
  }
};