var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "update-base-tax",
    attrs: {
      model: _vm.saveForm,
      "label-width": "150px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称",
      prop: "taxName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.taxName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxName", $$v);
      },
      expression: "saveForm.taxName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号",
      prop: "taxNo"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.saveForm.taxNo))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码",
      prop: "licenseNo"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.licenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "licenseNo", $$v);
      },
      expression: "saveForm.licenseNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名",
      prop: "legalPerson"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPerson,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPerson", $$v);
      },
      expression: "saveForm.legalPerson"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名",
      prop: "contacts"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contacts", $$v);
      },
      expression: "saveForm.contacts"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactNumber", $$v);
      },
      expression: "saveForm.contactNumber"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司品牌",
      prop: "shortName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.shortName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "shortName", $$v);
      },
      expression: "saveForm.shortName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区",
      prop: "areaCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    staticStyle: {
      "min-width": "100%"
    },
    attrs: {
      leaf: "2",
      placeholder: _vm.formatCity(),
      size: "mini"
    },
    model: {
      value: _vm.saveForm.areaCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "areaCode", $$v);
      },
      expression: "saveForm.areaCode"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人月度限额额度",
      prop: "monthQuota"
    }
  }, [_c("el-input", {
    attrs: {
      onkeyup: "this.value=this.value.replace(/[^\\d.]/g,'');",
      placeholder: "请输入个人月度限额额度"
    },
    model: {
      value: _vm.saveForm.monthQuota,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "monthQuota", $$v);
      },
      expression: "saveForm.monthQuota"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人季度限额额度",
      prop: "quarterQuota"
    }
  }, [_c("el-input", {
    attrs: {
      onkeyup: "this.value=this.value.replace(/[^\\d.]/g,'');",
      placeholder: "请输入个人季度限额额度"
    },
    model: {
      value: _vm.saveForm.quarterQuota,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "quarterQuota", $$v);
      },
      expression: "saveForm.quarterQuota"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人年度限额额度",
      prop: "yearQuota"
    }
  }, [_c("el-input", {
    attrs: {
      onkeyup: "this.value=this.value.replace(/[^\\d.]/g,'');",
      placeholder: "请输入个人年度限额额度"
    },
    model: {
      value: _vm.saveForm.yearQuota,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "yearQuota", $$v);
      },
      expression: "saveForm.yearQuota"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "财务负责人姓名",
      prop: "trusteeName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入财务负责人姓名"
    },
    model: {
      value: _vm.saveForm.trusteeName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "trusteeName", $$v);
      },
      expression: "saveForm.trusteeName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值自动审核",
      prop: "rechargeAutoAudit"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.saveForm.rechargeAutoAudit,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeAutoAudit", $$v);
      },
      expression: "saveForm.rechargeAutoAudit"
    }
  }, _vm._l(_vm.$formatTypeArray("rechargeAutoAudit"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v("\n            " + _vm._s(item.name) + "\n          ")]);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "财务负责人手机号",
      prop: "trusteeMobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入财务负责人手机号"
    },
    model: {
      value: _vm.saveForm.trusteeMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "trusteeMobile", $$v);
      },
      expression: "saveForm.trusteeMobile"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个人大额出款开关",
      prop: "yearLargeSwitch"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.yearLargeSwitch,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "yearLargeSwitch", $$v);
      },
      expression: "saveForm.yearLargeSwitch"
    }
  }, _vm._l(_vm.$formatTypeArray("yearLargeSwitch"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.saveForm.yearLargeSwitch === 1 ? _c("el-form-item", {
    attrs: {
      label: "近12个月大额出款限额",
      prop: "yearLargeQuota"
    }
  }, [_c("el-input", {
    attrs: {
      onkeyup: "this.value=this.value.replace(/[^\\d.]/g,'');",
      placeholder: "请输入近12个月大额出款限额"
    },
    model: {
      value: _vm.saveForm.yearLargeQuota,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "yearLargeQuota", $$v);
      },
      expression: "saveForm.yearLargeQuota"
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "单位地址",
      prop: "address"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "address", $$v);
      },
      expression: "saveForm.address"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值通知手机",
      prop: "rechargeNotifyMobile"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "36",
      rows: 2,
      "show-word-limit": "",
      placeholder: "请输入劳务公司入账接收通知手机号信息，多个以半角“,”分隔，最多输入36个字符"
    },
    model: {
      value: _vm.saveForm.rechargeNotifyMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeNotifyMobile", $$v);
      },
      expression: "saveForm.rechargeNotifyMobile"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人身份证号",
      prop: "legalPersonIdcard"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPersonIdcard,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPersonIdcard", $$v);
      },
      expression: "saveForm.legalPersonIdcard"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传身份证",
      prop: "legalPersonIdcardpic"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.saveForm.legalPersonIdcardpicShow
    },
    on: {
      imageSuccess: _vm.handleAvatarSuccessByICCard
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传营业执照",
      prop: "licensePic"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.saveForm.licensePicShow
    },
    on: {
      imageSuccess: _vm.handleAvatarSuccessBylicense
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传电子印章",
      prop: "signetUrl"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.saveForm.signetUrlShow
    },
    on: {
      imageSuccess: _vm.handleAvatarSuccessBySignetUrl
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("修改")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };