var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.handleClose,
      visible: _vm.dialogFormVisible
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "注册电话:",
      "label-width": _vm.formLabelWidth,
      prop: "companyNumber"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.companyNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.companyNumber"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址:",
      "label-width": _vm.formLabelWidth,
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      maxlength: "60"
    },
    model: {
      value: _vm.form.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyAddress", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.companyAddress"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户银行:",
      "label-width": _vm.formLabelWidth,
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      maxlength: "20"
    },
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.bankName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户账号:",
      "label-width": _vm.formLabelWidth,
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      maxlength: "20"
    },
    model: {
      value: _vm.form.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountNo", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.accountNo"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };