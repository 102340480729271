var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "upload-invoice-dialog",
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "通知"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_c("div", {
    staticClass: "text1"
  }, [_vm._v(_vm._s(_vm.item.title))]), _vm._v(" "), _vm.item.openType === 1 ? _c("div", {
    staticClass: "text2"
  }, [_vm._v(_vm._s(_vm.item.subTitle))]) : _vm.item.contents ? _c("div", {
    staticClass: "text2"
  }, [_vm.item.noticeType === 2 ? _c("div", {
    staticClass: "text3"
  }, [_vm._v(_vm._s(_vm.item.contents))]) : _c("div", {
    staticClass: "decode-class",
    domProps: {
      innerHTML: _vm._s(_vm.decodeContents(_vm.item.contents))
    }
  })]) : _vm._e(), _vm._v(" "), _c("img")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.gotoInfo
    }
  }, [_vm._v("点击查看详情")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };