var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      position: "relative"
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "未读消息  (" + _vm.noReadNum + ")",
      name: "first"
    }
  }, [_c("sub-list", {
    ref: "list1",
    attrs: {
      readFlag: 0
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "全部消息",
      name: "second"
    }
  }, [_c("sub-list", {
    ref: "list2",
    attrs: {
      readFlag: 1
    }
  })], 1)], 1), _vm._v(" "), _vm.noReadNum > 0 || _vm.noReadNum === "99+" ? _c("div", {
    staticClass: "read-class",
    on: {
      click: _vm.setAllRead
    }
  }, [_vm._v("全部阅读")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };