import { getRoleByUser, getUserInfo } from '@/api/sys';
import { USER_INFO_ID } from '@/utils/idName';
export default {
  name: 'view-user',
  data() {
    return {
      // 编辑界面数据
      editForm: {
        email: '',
        mobile: '',
        partyCode: '',
        password: '123456',
        realname: '',
        roleIdList: [],
        roleType: 0,
        status: 2,
        username: ''
      },
      userStatusArr: this.$formatTypeArray('userStatus'),
      roleArr: []
    };
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      this.handleRoles();
      const id = sessionStorage.getItem(USER_INFO_ID);
      if (id) {
        getUserInfo(id).then(response => {
          this.editForm = response.data;
        });
      }
    },
    handleRoles() {
      getRoleByUser().then(res => {
        this.roleArr = res.data;
      });
    },
    back() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};