var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号：",
      prop: "status"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入劳务公司编号"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "劳务公司名称：",
      prop: "status"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入劳务公司名称"
    },
    model: {
      value: _vm.searchForm.taxName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxName", $$v);
      },
      expression: "searchForm.taxName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.taxStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属地区：",
      prop: "selectedOptions"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      placeholder: _vm.formatCity()
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "个人大额出款开关：",
      prop: "yearLargeSwitch"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.yearLargeSwitch,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "yearLargeSwitch", $$v);
      },
      expression: "searchForm.yearLargeSwitch"
    }
  }, _vm._l(_vm.$formatTypeArray("yearLargeSwitch"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间：",
      prop: "createTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.createTime,
      callback: function ($$v) {
        _vm.createTime = $$v;
      },
      expression: "createTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.addTaxsource ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "contacts",
      label: "联系人",
      width: "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "contactNumber",
      label: "手机号码",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "所属地区",
      "min-width": "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s(scope.row.province) + "/" + _vm._s(scope.row.city) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "monthQuota",
      label: "个人月度限额额度",
      formatter: _vm.$fmoneyWithSymbol,
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "quarterQuota",
      label: "个人季度限额额度",
      formatter: _vm.$fmoneyWithSymbol,
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "yearQuota",
      label: "个人年度限额额度",
      formatter: _vm.$fmoneyWithSymbol,
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "yearLargeSwitch",
      label: "个人大额出款开关",
      formatter: _vm.$formatTypeInTable("yearLargeSwitch"),
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "supportDiff",
      label: "差额税率",
      width: "80",
      align: "center",
      formatter: _vm.$formatTypeInTable("isTrueFalse")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeAutoAudit",
      label: "充值自动审核",
      formatter: _vm.$formatTypeInTable("rechargeAutoAudit"),
      width: "110",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "代理费率(增值/差额)",
      "min-width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s((scope.row.agentRate * 100).toFixed(2)) + "% / " + _vm._s((scope.row.diffAgentRate * 100).toFixed(2)) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "技术服务费率",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s((scope.row.serviceRate * 100).toFixed(2)) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      formatter: _vm.formatStatus,
      width: "80",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "140",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.updateTaxsource ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.viewTaxsource ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && _vm.forzenTaxsource ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.freeze(scope.row);
            }
          }
        }, [_vm._v("冻结")]) : _vm._e(), _vm._v(" "), scope.row.status === 2 && _vm.forzenTaxsource ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.unfreeze(scope.row);
            }
          }
        }, [_vm._v("解冻")]) : _vm._e(), _vm._v(" "), _vm.configInvoiceHeader ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onInvoiceHeader(scope.row);
            }
          }
        }, [_vm._v("发票抬头设置")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("dialog-invoice-header", {
    attrs: {
      visiable: _vm.invoiceHeaderVisible,
      row: _vm.selectRow
    },
    on: {
      "update:visiable": function ($event) {
        _vm.invoiceHeaderVisible = $event;
      },
      refresh: _vm.queryForm
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };