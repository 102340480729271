var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "70%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "半身照审核"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      "label-width": "130px",
      size: "mini",
      "label-position": "left",
      model: _vm.saveForm,
      rules: _vm.isRules() ? _vm.rules : _vm.unRules
    }
  }, [_c("item-title", {
    attrs: {
      name: "待审核信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账户编号："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.info.empNo) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "人员姓名："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.info.empName) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "性别："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$formatType("sexType", _vm.info.sex)) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证照片(正面)："
    }
  }, [_vm.info.idcardFrontShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardFrontShow,
      "preview-src-list": [_vm.info.idcardFrontShow]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证照片(反面)："
    }
  }, [_vm.info.idcardBackShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardBackShow,
      "preview-src-list": [_vm.info.idcardBackShow]
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 15
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "待审核照片(半身照)："
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      position: "relative"
    }
  }, [_vm.info.halfBodyUrlShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.halfBodyUrlShow,
      "preview-src-list": [_vm.info.halfBodyUrlShow]
    }
  }) : _vm._e(), _vm._v(" "), _c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "show-file-list": false,
      "on-success": _vm.uploadImgSuccess,
      "before-upload": _vm.beforeDemoUpload,
      action: _vm.uploadHalfFile,
      data: {
        empNo: _vm.saveForm.empNo
      }
    }
  }, [_c("p", {
    staticClass: "upload-text"
  }, [_vm._v("编辑")])])], 1)])], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "确认结果"
    }
  }), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "审核结果：",
      prop: "checkStatus"
    }
  }, [_c("el-radio-group", {
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.saveForm.checkStatus,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "checkStatus", $$v);
      },
      expression: "saveForm.checkStatus"
    }
  }, [_c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 1
    }
  }, [_vm._v("审核通过")]), _vm._v(" "), _c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 0
    }
  }, [_vm._v("审核不通过")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注：",
      prop: "checkRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "200",
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.checkRemark,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "checkRemark", $$v);
      },
      expression: "saveForm.checkRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "操作员："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.checkSubmit
    }
  }, [_vm._v("确认")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.dialogClosed
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };