var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "批次信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "劳务公司编号：",
      value: _vm.infoForm.taxNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "劳务公司名称：",
      value: _vm.infoForm.taxName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "甲方单位编号：",
      value: _vm.infoForm.merchantNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "甲方单位名称：",
      value: _vm.infoForm.merchantName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "项目子编号：",
      value: _vm.infoForm.projectNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "项目名称：",
      value: _vm.infoForm.projectName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "扣费模式：",
      value: _vm.$formatType("chargeType", _vm.infoForm.chargeType),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "通道名称：",
      value: _vm.infoForm.channelName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "批次编号：",
      value: _vm.infoForm.batchNo,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "批次状态：",
      value: _vm.status,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "汇总笔数：",
      value: _vm.infoForm.total,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "汇总金额：",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.totalAmount),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "批次来源：",
      value: _vm.source,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "批次描述：",
      value: _vm.infoForm.repayDesc,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "提交时间：",
      value: _vm.infoForm.createTime,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "复核时间：",
      value: _vm.infoForm.quotaTime,
      left: false
    }
  })], 1)], 1), _vm._v(" "), _vm.infoForm.attachUrl !== "" ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "银行流水"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("el-link", {
    attrs: {
      type: "primary",
      href: _vm.infoForm.attachUrl,
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.infoForm.attachName))])], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "付款明细"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "pay-detail-container"
  }, [_vm.isCheck ? _c("div", {
    staticStyle: {
      "margin-top": "20px",
      color: "#666666"
    }
  }, [_vm._v("\n        账户可用余额：  " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.availAmount)) + " \n         "), _c("font", {
    staticStyle: {
      color: "red",
      display: "none"
    },
    attrs: {
      id: "fontShow"
    }
  }, [_vm._v("(余额不足)")]), _vm._v("\n            订单总金额：  " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.totalAmount)))], 1) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      type: "warning",
      icon: "el-icon-download",
      disabled: _vm.transactions.length === 0,
      size: "mini"
    },
    on: {
      click: _vm.downloadRow
    }
  }, [_vm._v("导出列表")])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.transactions,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "orderNo",
      label: "订单编号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "收款户名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "账户类型",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("accountType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "收款账户",
      "min-width": "155"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idCardNo",
      label: "身份证号",
      "min-width": "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayAmount",
      label: "订单金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayType",
      label: "发放方式",
      "min-width": "165",
      formatter: _vm.$formatTypeInTable("repayType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "teamNo",
      label: "班组名称",
      "min-width": "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "leaderName",
      label: "班组长姓名",
      "min-width": "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "leaderIdcard",
      label: "班组长身份证",
      "min-width": "165"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _vm.isCheck ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "处理意见"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "reviewForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.reviewForm,
      rules: _vm.rules,
      inline: "",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入批次复核意见，最多输入80个字",
      maxlength: "80",
      "show-word-limit": "",
      autosize: {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.reviewForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "remark", $$v);
      },
      expression: "reviewForm.remark"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "operation"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.pass();
      }
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.reject
    }
  }, [_vm._v("拒绝")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "#E7AB29",
      color: "white"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("暂不复核")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.infoForm.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.infoForm.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "35%",
      title: "审核通过",
      visible: _vm.passDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.passDialogVisible = $event;
      }
    }
  }, [_vm.infoForm.payAuthVerifySms === 1 ? _c("el-form", {
    staticClass: "login-forget-form",
    attrs: {
      "status-icon": "",
      "label-width": "0"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    为保证您资金安全，请您输入复核短信验证码")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "verifyCode"
    }
  }, [_c("verify-code", {
    ref: "passDialog",
    attrs: {
      type: _vm.payVerifyCode,
      phone: _vm.infoForm.mobile
    }
  })], 1)], 1) : _c("div", [_c("div", [_vm._v("确定通过此批次的复核吗？")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "-50px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.reviewBatch(1);
      }
    }
  }, [_vm._v("通 过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.passDialogVisible = false;
      }
    }
  }, [_vm._v("返 回")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "提示",
      visible: _vm.dialogVisible,
      width: "30%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("span", [_vm._v("您的账户余额可能不足以支付本次付款，请确认是否继续审核付款？")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        ;
        _vm.dialogVisible = false, _vm.passDialogVisible = true;
      }
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "35%",
      title: "审核拒绝",
      visible: _vm.rejectDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.rejectDialogVisible = $event;
      }
    }
  }, [_vm.infoForm.payAuthVerifySms === 1 ? _c("el-form", {
    staticClass: "login-forget-form",
    attrs: {
      "status-icon": "",
      "label-width": "0"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    为保证您资金安全，请您输入复核短信验证码")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "verifyCode"
    }
  }, [_c("verify-code", {
    ref: "rejectDialog",
    attrs: {
      type: _vm.payVerifyCode,
      phone: _vm.infoForm.mobile
    }
  })], 1)], 1) : _c("div", [_c("div", [_vm._v("确定拒绝此批次的复核吗?")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "-50px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.reviewBatch(9);
      }
    }
  }, [_vm._v("拒 绝")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.rejectDialogVisible = false;
      }
    }
  }, [_vm._v("返 回")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };