import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import VerifyCode from '@/base/ui/verifyCode';
import { importAssociatePageReg, improtAssociateSaveReg } from '@/api/invoice';
export default {
  name: 'associatePreview',
  components: {
    VerifyCode,
    ItemTitle,
    Pagination
  },
  props: {
    dialogVisible: {
      required: true,
      type: Boolean
    },
    totalAmount: '',
    totalCount: '',
    serialNo: ''
  },
  data() {
    return {
      listLoading: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      list: [],
      total: 0,
      myDialogFormVisible: false
    };
  },
  watch: {
    serialNo: function (val) {
      if (val) {
        this.getList();
      }
    },
    dialogVisible(val) {
      this.myDialogFormVisible = val;
      if (val && this.serialNo) {
        this.getList();
      }
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  created() {
    // this.$refs.elDialog[0].$el.firstChild.style.height = '80%'
  },
  methods: {
    cancelForm() {
      this.myDialogFormVisible = false;
      this.$emit('on-cancel');
    },
    doSave() {
      this.isLoading = true;
      improtAssociateSaveReg({
        serialNo: this.serialNo
      }).then(() => {
        this.isLoading = false;
        this.myDialogFormVisible = false;
        this.$message.success('操作成功。');
        this.$emit('on-success');
      }).catch(() => {
        this.isLoading = false;
      });
    },
    getList() {
      this.listLoading = true;
      importAssociatePageReg({
        serialNo: this.serialNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listLoading = false;
        this.list = response.data.list;
        this.total = response.data.totalCount;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};