import { bankInfoReg } from '../../../api/sys';
import UploadImage from '../../../base/ui/upload/uploadImage';
import { BANK_INFO_ID } from '../../../utils/idName';
import ElImage from 'element-ui/packages/image/src/main';
export default {
  components: {
    ElImage,
    UploadImage
  },
  data() {
    return {
      id: '',
      saveForm: {
        bankCode: '',
        bankName: '',
        logUrl: ''
      }
    };
  },
  created() {
    this.id = sessionStorage.getItem(BANK_INFO_ID);
    this.getInfo();
  },
  methods: {
    getInfo() {
      bankInfoReg(this.id).then(response => {
        this.saveForm = response.data;
      });
    }
  }
};