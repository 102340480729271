import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { getUserList, deleteUser } from '@/api/sys';
import Pagination from '@/components/pagination';
import { getAcountInfo } from '@/utils/auth';
import { USER_UPDATE_ID, USER_INFO_ID } from '@/utils/idName';
export default {
  name: 'userList',
  components: {
    Pagination
  },
  data() {
    return {
      listQuery: {
        username: '',
        realname: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      roles: [],
      listLoading: true,
      viewUserPath: '',
      addUserPath: '',
      updateUserPath: '',
      addUserVisible: false,
      updateUserVisible: false,
      deleteUserVisible: false,
      localName: getAcountInfo().id
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewUserPath = map.get('viewUser');
      this.updateUserPath = map.get('updateUser');
      this.updateUserVisible = map.has('updateUser');
      this.addUserPath = map.get('addUser');
      this.addUserVisible = map.has('addUser');
      this.deleteUserVisible = map.has('deleteUser');
    } else {
      this.addUserVisible = false;
      this.updateUserVisible = false;
      this.deleteUserVisible = false;
    }
    this.getQuery();
    console.log(this.localName);
  },
  methods: {
    formatStatus(row, column) {
      return this.$formatType('userStatus', row.status);
    },
    formatUserType(row, column) {
      return this.$formatType('userType', row.usertype);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getQuery() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      getUserList(this.listQuery).then(response => {
        this.roles = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.listQuery = {
        username: '',
        realname: '',
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.listQuery.pageNo, this.listQuery.pageSize);
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteUser(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(USER_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updateUserPath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(USER_INFO_ID, row.id);
      this.$router.push({
        path: this.viewUserPath,
        query: {
          id: row.id
        }
      });
    },
    handleAdd() {
      this.$router.push({
        path: this.addUserPath
      });
    }
  },
  mounted() {}
};