import { updateResetRechargePwd } from "@/api/safe";
import { isValidPassword } from "@/utils/validate_rules";
import { resetRechargePwd } from "@/api/verifyCode";
const MSGINIT = "发送验证码";
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
export default {
  name: "resetPayPwdForm",
  data() {
    return {
      passwordType: "password",
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      editFormRules: {
        password: [{
          required: true,
          message: "请输入原密码",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        }, {
          min: 6,
          max: 16,
          message: "密码长度为6位",
          trigger: "blur"
        }],
        repassword: [{
          required: true,
          message: "请重复输入新密码",
          trigger: "blur"
        }, {
          min: 6,
          max: 16,
          message: "密码长度为6位",
          trigger: "blur"
        }],
        verifyCode: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }, {
          min: 6,
          max: 6,
          message: "验证码长度为6位",
          trigger: "blur"
        }]
      },
      // 编辑界面数据
      editForm: {
        password: "",
        newPassword: "",
        repassword: "",
        verifyCode: ""
      }
    };
  },
  mounted() {
    this.editForm.password = "";
  },
  methods: {
    // 编辑
    updateData() {
      if (this.editForm.newPassword !== this.editForm.repassword) {
        this.$message({
          message: "新密码与重复密码不一致",
          type: "error"
        });
        return;
      }
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            updateResetRechargePwd(this.editForm).then(response => {
              this.$message({
                message: "修改成功",
                type: "success"
              });
              this.$refs["editForm"].resetFields();
              this.goBack();
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    resetFields() {
      this.editForm = {
        password: "",
        newPassword: "",
        repassword: "",
        verifyCode: ""
      };
    },
    handleSend() {
      if (this.msgKey) {
        return;
      }
      resetRechargePwd().then(res => {
        if (this.msgKey) return;
        this.$message({
          type: "success",
          message: res.data
        });
        this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
        this.msgKey = true;
        const time = setInterval(() => {
          this.msgTime--;
          this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
          if (this.msgTime === 0) {
            this.msgTime = MSGTIME;
            this.msgText = MSGINIT;
            this.msgKey = false;
            clearInterval(time);
          }
        }, 1000);
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};