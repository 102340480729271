import { Navbar, Sidebar, AppMain, TagsView, FooterView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
export default {
  name: 'layout',
  components: {
    FooterView,
    Navbar,
    Sidebar,
    AppMain,
    TagsView
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  methods: {
    handleClickOutside() {
      // this.$store.dispatch('CloseSideBar', { withoutAnimation: false })
    }
  },
  created() {}
};