import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { addInvoiceInfoReq, updateInvoiceInfoReq } from '@/api/invoice';
export default {
  name: 'invoice-update-info',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    invoiceConfigObj: {
      type: Object
    }
  },
  data() {
    return {
      addForm: {
        id: '',
        merchantName: '',
        invoiceNo: '',
        companyNumber: '',
        companyAddress: '',
        bankName: '',
        accountNo: '',
        merchantNo: ''
      },
      rules: {
        merchantName: [{
          required: true,
          message: '请输入发票抬头',
          trigger: 'blur'
        }],
        invoiceNo: [{
          required: true,
          message: '税务登记证号',
          trigger: 'blur'
        }],
        companyNumber: [{
          required: true,
          message: '请输入注册电话',
          trigger: 'blur'
        }],
        companyAddress: [{
          required: true,
          message: '请输入注册地址',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入开户银行',
          trigger: 'blur'
        }],
        accountNo: [{
          required: true,
          message: '请输入银行账户',
          trigger: 'blur'
        }, {
          min: 6,
          max: 24,
          message: '长度在 6 到 24 个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    title: {
      get() {
        return !this.isUpdate ? '新增开票信息' : '修改开票信息';
      }
    },
    isUpdate: function () {
      return this.invoiceConfigObj != null;
    }
  },
  watch: {
    invoiceConfigObj: function (newValue, oldValue) {
      this.addForm.id = newValue != null ? newValue.id : '';
      this.addForm.merchantNo = newValue != null ? newValue.merchantNo : '';
      this.addForm.merchantName = newValue != null ? newValue.merchantName : '';
      this.addForm.invoiceNo = newValue != null ? newValue.invoiceNo : '';
      this.addForm.companyNumber = newValue != null ? newValue.companyNumber : '';
      this.addForm.companyAddress = newValue != null ? newValue.companyAddress : '';
      this.addForm.bankName = newValue != null ? newValue.bankName : '';
      this.addForm.accountNo = newValue != null ? newValue.accountNo : '';
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          if (!this.isUpdate) {
            // 新增
            const user = getAcountInfo();
            this.addForm.merchantNo = user.partyCode;
            this.addInvoiceInfo();
          } else {
            // 修改
            this.updateInvoiceInfo();
          }
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      if (this.invoiceConfigObj == null) {
        this.$refs['addForm'].resetFields();
      } else {
        this.$refs['addForm'].clearValidate();
      }
    },
    addInvoiceInfo() {
      const param = {
        ...this.addForm
      };
      addInvoiceInfoReq(param).then(() => {
        this.$message.success('新增发票信息成功...');
        this.$emit('updated');
      });
    },
    updateInvoiceInfo() {
      const param = {
        ...this.addForm
      };
      updateInvoiceInfoReq(param).then(() => {
        this.$message.success('修改发票信息成功...');
        this.$emit('updated');
      });
    }
  }
};