import { isMoneyNumber, isMobile } from '@/utils/validate_rules';
import { updateInvoiceConfig } from '@/api/taxsource';
export default {
  props: {
    visiable: Boolean,
    row: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      submitLoading: false,
      form: {
        bankAccount: "",
        bankName: "",
        companyAddress: "",
        companyNumber: "",
        taxNo: ""
      },
      rules: {
        companyNumber: [
          // { validator: isMobile, trigger: 'blur' },
        ]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '发票抬头设置';
    }
  },
  methods: {
    opened() {
      this.form = Object.assign({}, this.row);
    },
    onClose() {
      this.dialogFormVisible = false;
    },
    onSure() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.updateInvoiceConfigAPI();
        } else {
          return false;
        }
      });
    },
    updateInvoiceConfigAPI() {
      this.submitLoading = true;
      const {
        bankAccount,
        bankName,
        companyAddress,
        companyNumber,
        taxNo
      } = this.form;
      updateInvoiceConfig({
        bankAccount,
        bankName,
        companyAddress,
        companyNumber,
        taxNo
      }).then(response => {
        this.$message.success('设置成功！');
        this.$emit('refresh');
        this.submitLoading = false;
        this.onClose();
      }).catch(() => {
        this.submitLoading = false;
      });
    }
  }
};