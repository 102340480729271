import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import dialogAgreementManager from '../components/dialog/dialogAgreementManager.vue';
import dialogInvoiceApply from '../components/dialog/dialogInvoiceApply.vue';
import { mapGetters } from 'vuex';
import { empTeamSignedList, downloadSigned, signedConfirm, signedRevoke } from '@/api/teamSignRecord';
import { showlist } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
export default {
  name: 'teamSignRecord',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker,
    dialogAgreementManager,
    dialogInvoiceApply
  },
  data() {
    return {
      loading: false,
      agreementVisible: false,
      invoiceVisible: false,
      selectRow: {},
      teamDetail: '',
      contractManage: '',
      invoiceApply: '',
      cancel: '',
      signed: '',
      download: '',
      searchForm: {
        "keyWords": "",
        "merchantNo": "",
        "order": "",
        "projectName": "",
        "projectNo": "",
        "signDateBegin": "",
        "signDateEnd": "",
        "sort": "",
        "status": "",
        "taxNo": "",
        "teamName": "",
        "teamNo": ""
      },
      datePicker: [],
      signPicker: [],
      updatePicker: [],
      list: [],
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      groupSignStatus: this.$formatTypeArray('groupSignStatus')
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log('this.buttonsMap: ', this.buttonsMap);
    console.log('map: ', map);
    if (map) {
      this.teamDetail = map.get('teamSignRecordDetail');
      console.log('this.teamDetail: ', this.teamDetail);
      this.contractManage = map.get('contractManage');
      this.invoiceApply = map.get('invoiceApply');
      this.cancel = map.get('cancel');
      this.signed = map.get('signed');
      this.download = map.get('download');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryForm();
    });
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    queryForm() {
      this.currentSearch = {
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.datePicker = [];
      this.queryForm();
    },
    async getList() {
      this.loading = true;
      const {
        keyWords,
        teamNo,
        projectNo,
        projectName,
        taxNo,
        status
      } = this.searchForm;
      const {
        pageNo,
        pageSize
      } = this.currentSearch;
      const [signDateBegin, signDateEnd] = this.datePicker;
      const params = {
        keyWords,
        teamNo,
        projectNo,
        projectName,
        taxNo,
        status,
        pageNo,
        pageSize,
        signDateBegin,
        signDateEnd
      };
      const res = await empTeamSignedList(params);
      this.list = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    },
    onTeamDetail(index, row) {
      this.$router.push({
        path: this.teamDetail,
        query: {
          review: 0,
          id: row.teamId
        }
      });
    },
    onContractManage(index, row) {
      this.selectRow = row;
      this.agreementVisible = true;
    },
    onInvoiceApply(index, row) {
      this.selectRow = row;
      this.invoiceVisible = true;
    },
    onCancel(index, row) {
      this.$confirm('是否确定撤销该签约申请？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.signedRevokeAPI(row);
      }).catch(() => {});
    },
    onSigned(index, row) {
      this.$confirm('请确认班组长已通过其他方式完成签约后进行本操作', '温馨提示', {
        confirmButtonText: '确定签约',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.signedConfirmAPI(row);
      }).catch(() => {});
    },
    async signedConfirmAPI(row) {
      const params = {
        id: row.id
      };
      await signedConfirm(params);
      this.$message({
        type: 'success',
        message: '已签约'
      });
      this.queryForm();
    },
    async signedRevokeAPI(row) {
      const params = {
        id: row.id
      };
      await signedRevoke(params);
      this.$message({
        type: 'success',
        message: '撤销成功!'
      });
      this.queryForm();
    },
    downloadForm() {
      downloadSigned(this.searchForm).then(response => {
        downloadExcel(response.data, '班组签约列表.xls');
      });
    }
  }
};