import { getStatMerchantRechargeCharts } from '../../../../api/stat';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import DbsHistogram from '../../../../components/chart/dbs-histogram';
export default {
  name: 'taxTransactionChart',
  components: {
    DbsHistogram,
    ItemTitle
  },
  data() {
    const chartSettings = {
      itemStyle: {
        color: '#2DA8FF'
      },
      labelMap: {
        'amount': '金额'
      },
      legendName: {
        'amount': '金额'
      }
    };
    return {
      chartData: {
        columns: ['columnName', 'amount'],
        rows: []
      },
      chartSettings: chartSettings,
      searchForm: {
        merchantName: '',
        merchantNo: '',
        taxNo: '',
        type: 3
      },
      taxSources: []
    };
  },
  created() {
    this.getTaxSources();
    this.getList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    freshData(index) {
      this.searchForm.type = index;
      this.getList(index);
    },
    getList() {
      getStatMerchantRechargeCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    }
  }
};