var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "app-container border-bg"
  }, [_c("el-container", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("el-aside", {
    attrs: {
      width: "200px"
    }
  }, [_c("LeftForm", {
    ref: "leftForm",
    on: {
      changeTreeData: _vm.changeTreeData
    }
  })], 1), _vm._v(" "), _c("el-main", [_c("MainForm", {
    ref: "mainForm",
    attrs: {
      parentId: _vm.parentId
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };