export default {
  name: 'previewDialog',
  data() {
    return {
      myDialogFormVisible: false
    };
  },
  props: {
    dialogFormVisible: false,
    info: {
      require: true
    }
  },
  created() {},
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisiblePreviewDialog', val);
    },
    info(val) {
      // debugger
    }
  },
  methods: {
    close() {
      this.myDialogFormVisible = false;
    }
  }
};