import UploadFile from '../../../base/ui/upload/uploadFile';
import ItemTitle from '../../../base/ui/title/itemTitle';
import UploadMoreFile from '../../../base/ui/upload/uploadMoreFile';
import { uploadHalfFile } from '@/api/upload';
import { getAcountInfo } from '../../../utils/auth';
import { beforUploadImg } from '../../../utils/util';
import { uploadHalfBody } from '../../../api/operateEmp';
export default {
  name: 'uploadJpgFileDialog',
  components: {
    UploadMoreFile,
    ItemTitle,
    UploadFile
  },
  data() {
    return {
      myDialogFormVisible: false,
      saveForm: {
        fileList: [],
        filePath: ''
      },
      myFileName: '',
      uploadHalfFile: uploadHalfFile,
      saveKey: ''
    };
  },
  props: {
    dialogFormVisible: false,
    empNo: {
      require: true
    },
    name: {
      require: true
    }
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleUploadPhotoDialog', val);
    }
  },
  methods: {
    handleUploadSuccess(res, file) {
      this.saveForm.filePath = res.data.saveKey;
    },
    dialogClosed() {
      this.saveForm.fileList = [];
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.saveKey = res.data.saveKey;
        this.myFileName = file.raw.name;
      } else {
        this.$message.error(res.errMsg);
      }
    },
    beforeDemoUpload(file) {
      return beforUploadImg(this, file);
    },
    doUploadHalfBody() {
      uploadHalfBody({
        empNo: this.empNo,
        halfBodyUrl: this.saveKey
      }).then(() => {
        this.$message.success('上传成功。。。');
        this.close();
      });
    },
    close() {
      this.myDialogFormVisible = false;
      this.myFileName = '';
      this.saveKey = '';
    }
  }
};