var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "600px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed,
      open: _vm.open
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "确认出票"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票订单编号"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.serialNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.taxName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票金额"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.invoiceAmount) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "出票日期",
      prop: "invoiceDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      "value-format": "yyyy-MM-dd",
      placeholder: "出票日期"
    },
    model: {
      value: _vm.addForm.invoiceDate,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "invoiceDate", $$v);
      },
      expression: "addForm.invoiceDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传发票文件",
      prop: "invoiceUrlList"
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 1,
      action: _vm.uploadInvoice,
      extendData: _vm.extendData
    },
    model: {
      value: _vm.addForm.invoiceUrlList,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "invoiceUrlList", $$v);
      },
      expression: "addForm.invoiceUrlList"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    staticStyle: {
      "font-size": "12px",
      color: "#ff4c4c",
      "line-height": "1.2",
      "margin-left": "20px"
    }
  }, [_c("div", [_vm._v("多个文件请请上传压缩包，文件大小不超过30M")])])], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };