export default {
  name: 'RateInfoDialog',
  data() {
    return {
      myDialogFormVisible: false
    };
  },
  props: {
    rateInfo: {
      rateType: '',
      rechargeRate: '',
      rateListInfo: []
    },
    dialogFormVisible: false
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisible', val);
    }
  },
  methods: {
    goBack() {
      this.myDialogFormVisible = false;
    }
  }
};