var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row"
    }
  }, [_c("div", {
    staticStyle: {
      width: "45%"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("item-title", {
    attrs: {
      name: "人员批量导入"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "uploadForm",
    staticClass: "form-table",
    attrs: {
      model: _vm.uploadForm,
      rules: _vm.uploadFormRules,
      size: "mini",
      "label-position": "right",
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择所属劳务公司",
      clearable: "",
      filterable: ""
    },
    on: {
      change: _vm.getMerchantList
    },
    model: {
      value: _vm.uploadForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.uploadForm, "taxNo", $$v);
      },
      expression: "uploadForm.taxNo"
    }
  }, _vm._l(_vm.taxSourceList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属甲方单位：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择所属甲方单位",
      clearable: "",
      filterable: "",
      disabled: !_vm.uploadForm.taxNo || _vm.merchantList.length === 0
    },
    on: {
      change: _vm.merchantSelectChange
    },
    model: {
      value: _vm.uploadForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.uploadForm, "merchantNo", $$v);
      },
      expression: "uploadForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属项目：",
      prop: "projectNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择所属项目",
      clearable: "",
      filterable: "",
      disabled: !_vm.uploadForm.merchantNo || _vm.projectList.length === 0
    },
    model: {
      value: _vm.uploadForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.uploadForm, "projectNo", $$v);
      },
      expression: "uploadForm.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: _vm.concatProject(item),
        value: item.projectNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "upload",
    attrs: {
      label: "上传信息："
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      limit: 1,
      action: _vm.saveFile,
      "file-list": _vm.fileList,
      "on-preview": _vm.handlePreview,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleUploadSuccess,
      "before-upload": _vm.beforeAvatarUpload,
      data: _vm.uploadData,
      "auto-upload": false
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("上传到服务器\n            ")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_label",
    attrs: {
      type: "primary",
      href: _vm.template_url,
      target: "_blank",
      download: "人员导入模版"
    }
  }, [_vm._v("\n              批量导入模版下载")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 只能上传已填写好的标准模板文件，数据记录最多不能超过500条，且文件大小不超过10M")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      flex: "1",
      "padding-bottom": "1rem"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("item-title", {
    attrs: {
      name: "批量导入指导"
    }
  })], 1), _vm._v(" "), _vm._m(0)])]), _vm._v(" "), _c("import-view-dialog", {
    attrs: {
      uploadRespData: _vm.responseData,
      uploadViewFromVisible: _vm.uploadViewFromVisible
    },
    on: {
      changeVisibleUpdateView: _vm.changeVisibleUpdateView,
      "on-success": _vm.uploadSuccess,
      "on-error": _vm.uploadError,
      "on-cancel": _vm.uploadCancel
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "文件错误提示信息",
      visible: _vm.dataErrorDialog,
      width: "60%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dataErrorDialog = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    文件中存在以下数据格式错误信息，请剔除格式错误数据或认证核对修正后重新提交！")]), _vm._v(" "), _c("el-form", {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      "label-width": "90px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 100
    }
  }, [_c("el-col", {
    attrs: {
      span: 23
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "错误描述："
    }
  }, [_c("el-input", {
    staticStyle: {
      "font-size": "medium"
    },
    attrs: {
      type: "textarea",
      rows: 5
    },
    model: {
      value: _vm.dataErrorDesc,
      callback: function ($$v) {
        _vm.dataErrorDesc = $$v;
      },
      expression: "dataErrorDesc"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.sureButton
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-desc"
  }, [_c("div", [_vm._v("1、下载人员批量导入信息模板，并按照模板格式要求填写表格")]), _vm._v(" "), _c("div", [_vm._v("2、选择要导入目标项目所属劳务公司、所属甲方单位、所属项目")]), _vm._v(" "), _c("div", [_vm._v("3、点击选取文件，选择已填写完并保存的表格进行导入")]), _vm._v(" "), _c("div", [_vm._v("4、导入后点击上传至服务器，上传完成后会弹出预览窗口，点击确认即可导入表格中的人员信息")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#FF4C4C"
    }
  }, [_vm._v("注：批量录入时请务必输入准确的人员信息（真实姓名、身份证号、银行卡号、银行预留手机号码），否则会导致付款失败。\n        ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };