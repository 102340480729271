import Pagination from "@/components/pagination";
import table from "@/utils/table";
import { getAcountInfo } from "@/utils/auth";
import { mapGetters } from "vuex";
import { timePickerOptions, separateDate } from "@/utils/util";
import { showlist } from "@/api/taxsource";
import DbDatePicker from "@/base/ui/date/db-date-picker";
import ElLink from "element-ui/packages/link/src/main";
import { INVOICE_OPERATION_PERMISSION } from "@/utils/idName";
import { finishMerchantTask, cancelMerchantTask, getTaskList, getTaskTypeSelect, suspendMerchantTask, upMerchantTask } from "@/api/task";
import { BATCH_SAVE_INFO, TASK_INFO_ID, TASK_UPDATE_ID } from "../../../utils/idName";
import { merchantShowList } from "../../../api/merchant";
export default {
  name: "taskRecordListContent",
  // eslint-disable-next-line no-dupe-keys
  components: {
    ElLink,
    DbDatePicker,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      updateTaskVisible: false,
      upTaskVisible: false,
      finishTaskVisible: false,
      suspendTaskVisible: false,
      resubmitTaskVisible: false,
      reviewTaskVisible: false,
      checkReviewTaskVisible: false,
      cancelTaskVisible: false,
      batchSavePath: '',
      viewTaskPath: '',
      total: 0,
      list: [],
      listLoading: true,
      statusType: this.$formatTypeArray("statusType"),
      updateStatus: {
        id: "",
        status: ""
      },
      createTime: [],
      searchForm: {
        merchantNo: "",
        beginTime: "",
        endTime: "",
        taxNo: "",
        status: "",
        taskNo: "",
        taskName: "",
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      isFresh: false,
      partyType: "",
      taxSources: [],
      taskTypeSelectType: [],
      merchantNo: "",
      typeSelectNoDataText: "请选择所属商户",
      merchantList: []
    };
  },
  computed: {
    ...mapGetters(["buttonsMap"]),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateAgent && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.updateTaskVisible = map.get('updateMerchantTask');
      this.upTaskVisible = map.get('upMerchantTask');
      this.suspendTaskVisible = map.get('suspendMerchantTask');
      this.finishTaskVisible = map.get('finishMerchantTask');
      this.viewTaskPath = map.get('viewTask');
      this.batchSavePath = map.get('batchSaveIndex');
      this.resubmitTaskVisible = map.get('resubmitMerchantTask');
      this.reviewTaskVisible = map.get('reviewMerchantTask');
      this.checkReviewTaskVisible = map.get('checkReviewMerchantTask');
      this.cancelTaskVisible = map.get('cancelMerchantTask');
    }
    this.getTaxSources();
    this.getList();
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 3) {
      this.merchantNo = user.partyCode;
      this.currentSearch.merchantNo = this.searchForm.merchantNo = user.partyCode;
      this.getTaskTypeList(this.merchantNo);
    } else {
      this.getMerchantList();
    }
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const qryCreateTime = separateDate(this.createTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      getTaskList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs["searchForm"].resetFields();
      this.createTime = [];
      this.queryForm();
    },
    // 显示查看界面
    handleView(id) {
      sessionStorage.setItem(TASK_INFO_ID, id);
      this.$router.push({
        path: this.viewTaskPath
      });
    },
    handleReview(id) {
      sessionStorage.setItem(TASK_INFO_ID, id);
      this.$router.push({
        path: this.viewTaskPath,
        query: {
          isReview: "1"
        }
      });
    },
    handleCheckReview(id) {
      sessionStorage.setItem(TASK_INFO_ID, id);
      this.$router.push({
        path: this.viewTaskPath,
        query: {
          isReview: "1",
          isCheckReview: "1"
        }
      });
    },
    formatCity() {
      if (this.searchForm.provinceCode === "") {
        return "";
      } else {
        return this.searchForm.provinceCode + "/" + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType("statusType", row.status);
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    gotoDetail(invoiceId) {
      sessionStorage.setItem(INVOICE_OPERATION_PERMISSION, invoiceId);
      this.$router.push("/invoice/list/detail");
    },
    gotoBatchSavePath(row) {
      sessionStorage.setItem(BATCH_SAVE_INFO, JSON.stringify(row));
      this.$router.push(this.batchSavePath);
    },
    handleFinish(row) {
      this.$confirm("是否确定结束任务？", "温馨提示", {
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        finishMerchantTask(row.id).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      }).catch(() => {});
    },
    handleCancel(row) {
      this.$confirm("是否确定取消任务？", "温馨提示", {
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        cancelMerchantTask(row.id).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      }).catch(() => {});
    },
    handleSuspend(row) {
      this.$confirm("是否确定暂停任务？", "温馨提示", {
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        suspendMerchantTask(row.id).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      }).catch(() => {});
    },
    handlePrefer(row) {
      this.$confirm("是否确定重新上架？", "温馨提示", {
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        upMerchantTask(row.id).then(() => {
          this.$message.success("操作成功");
          this.getList();
        });
      }).catch(() => {});
    },
    gotoUpdate(row) {
      sessionStorage.setItem(TASK_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updateTaskVisible
      });
    },
    gotoResubmit(row) {
      sessionStorage.setItem(TASK_UPDATE_ID, row.id);
      this.$router.push({
        path: this.resubmitTaskVisible
      });
    },
    getTaskTypeList(val) {
      getTaskTypeSelect(val).then(resonse => {
        this.taskTypeSelectType = resonse.data;
        if (this.taskTypeSelectType.length === 0) {
          this.typeSelectNoDataText = "无数据";
        }
      });
    },
    merchantChange(val) {
      if (val === "") {
        this.taskTypeSelectType = [];
        this.typeSelectNoDataText = "请选择所属商户";
      } else {
        this.getTaskTypeList(val);
      }
    }
  }
};