var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "修改劳务公司路由"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "140px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司："
    }
  }, [_vm._v("\n      " + _vm._s(`${_vm.item.belongName}(${_vm.item.belong})`) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "路由劳务公司："
    }
  }, [_vm._v("\n      " + _vm._s(`${_vm.item.taxName}(${_vm.item.taxNo})`) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "充值费率(增值)：",
      prop: "rechargeRate"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "应高于路由劳务公司基本代理费率，不高于1"
    },
    model: {
      value: _vm.saveForm.rechargeRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeRate", $$v);
      },
      expression: "saveForm.rechargeRate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "充值费率(差额)：",
      prop: "diffRechargeRate"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "应高于路由劳务公司基本代理费率，不高于1"
    },
    model: {
      value: _vm.saveForm.diffRechargeRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "diffRechargeRate", $$v);
      },
      expression: "saveForm.diffRechargeRate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.saveForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "status", $$v);
      },
      expression: "saveForm.status"
    }
  }, _vm._l(_vm.taxRouterStatus, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateBtn
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };