import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { separateDate } from '../../../utils/util';
import DbDatePicker from '../../../base/ui/date/db-date-picker';
import { SYS_MESSAGE_ADD_ID, SYS_MESSAGE_INFO_ID } from '../../../utils/idName';
import { getNoticeContentList, sysMessageCancel, sysMessageOffLine, sysMessagePublish, sysMessageRollBack } from '../../../api/sys';
import { showlist } from '../../../api/taxsource';
export default {
  name: 'sysMessageListIndex',
  components: {
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      searchForm: {
        title: '',
        createUser: '',
        taxNo: '',
        noticeType: '',
        status: '',
        beginTime: '',
        endTime: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      sysMessageInfoPath: '',
      addSysMessage: '',
      dateTime: [],
      taxSources: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
      vm.getTaxSources();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.sysMessageInfoPath = map.get('sysMessageInfo');
      this.addSysMessage = map.get('addSysMessage');
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatBelongType(row, column) {
      return this.$formatType('belongType', row.belongType);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.dateTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const time = separateDate(this.dateTime);
      getNoticeContentList({
        createUser: this.currentSearch.createUser,
        title: this.currentSearch.title,
        noticeType: this.currentSearch.noticeType,
        taxNo: this.currentSearch.taxNo,
        status: this.currentSearch.status,
        beginTime: time.beginTime,
        endTime: time.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    handleView(row) {
      sessionStorage.setItem(SYS_MESSAGE_INFO_ID, row.id);
      this.$router.push({
        path: this.sysMessageInfoPath
      });
    },
    handleAdd(row) {
      sessionStorage.setItem(SYS_MESSAGE_ADD_ID, row.id);
      this.$router.push({
        path: this.addSysMessage
      });
    },
    handleCancel(row) {
      this.$confirm('是否确定取消通知?', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        sysMessageCancel(row.id).then(res => {
          this.$message({
            message: '操作成功。。。',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handlePublish(row) {
      this.$confirm('是否确定立即发布？', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        sysMessagePublish(row.id).then(res => {
          this.$message({
            message: '操作成功。。。',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handleOffLine(row) {
      this.$confirm('是否确定立即下线?', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        sysMessageOffLine(row.id).then(res => {
          this.$message({
            message: '操作成功。。。',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handleRollBack(row) {
      this.$confirm('是否确定撤回通知?', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        sysMessageRollBack(row.id).then(res => {
          this.$message({
            message: '操作成功。。。',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    }
  }
};