var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "menu-item-container",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("el-image", {
    staticClass: "menu-item-image",
    attrs: {
      src: _vm.src
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "menu-item-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c("div", {
    staticClass: "menu-item-detail"
  }, [_vm._v(_vm._s(_vm.detail))])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };