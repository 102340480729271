import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination/index';
import { getInvoiceTitle, getProfitRecordInfo } from '@/api/profit';
import { getAgentProfitDetailList } from '@/api/transaction';
import { confirmRecord } from '../../../../api/profit';
import { PROFIT_CONFIRM_RECORD_ID } from '../../../../utils/idName';
export default {
  components: {
    Pagination,
    ItemTitle
  },
  name: 'confrimRecord',
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      infoForm: {},
      list: [],
      invoiceTitleInfo: {},
      showDialog: false,
      confirmForm: {
        submitOpinion: '',
        status: '',
        id: ''
      }
    };
  },
  created() {
    this.getInfo();
    getInvoiceTitle().then(response => {
      this.invoiceTitleInfo = response.data;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getInfo();
    });
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getInfo() {
      const id = sessionStorage.getItem(PROFIT_CONFIRM_RECORD_ID);
      this.confirmForm.id = id;
      getProfitRecordInfo(id).then(response => {
        this.infoForm = response.data;
        this.getQuery();
      });
    },
    getList() {
      this.listLoading = true;
      getAgentProfitDetailList({
        agentNo: this.infoForm.agentNo,
        profitMonth: this.infoForm.bussincessMonth,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    doSubmit(status) {
      if (status === -1 && this.confirmForm.submitOpinion === '') {
        this.$message.error('请填写确认意见。');
        return;
      }
      this.confirmForm.status = status;
      confirmRecord(this.confirmForm).then(() => {
        this.$message.success('操作成功');
        this.goBack();
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};