import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { taxThresholdListReq } from '@/api/taxsource';
import SaveThresholdDialog from './components/saveDialog';
import UpdateThresholdDialog from './components/updateDialog';
export default {
  name: 'taxSubList',
  components: {
    UpdateThresholdDialog,
    SaveThresholdDialog,
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      taxsList: [],
      listLoading: true,
      searchForm: {},
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      downloadRepayments: false,
      downloadRecord: false,
      saveThresholdPath: '',
      saveDialogVisible: false,
      updateDialogVisible: false,
      updateItem: {}
    };
  },
  props: {
    thresholdType: {
      // 劳务公司阈值类型：1-临时税务登记阈值,2-个体户注册阈值
      required: true
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.downloadRepayments = map.has('downloadRepayments');
      this.downloadRecord = map.has('downloadRecord');
      this.saveThresholdPath = map.get('saveThreshold');
    }
    this.getList();
    this.getTaxsList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      this.currentSearch.thresholdType = this.thresholdType;
      taxThresholdListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    showUpdateDialog(row) {
      this.updateItem = row;
      this.updateDialogVisible = true;
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    },
    changeUpdateDialogVisible(val) {
      this.updateDialogVisible = val;
      this.getList();
    }
  }
};