import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 新增企业商户充值银行卡信息
 * @param param
 */
export const addBankCard = param => {
  const {
    accountNo,
    accountType,
    bankName,
    custName,
    belong
  } = param;
  return request({
    url: '/org/merchant/addBankCard',
    method: 'post',
    data: {
      accountNo,
      accountType,
      bankName,
      custName,
      belong
    }
  });
};

/**
 *
 删除企业商户充值银行卡信息
 * @param id
 */
export const delBankCard = id => {
  return request({
    url: '/org/merchant/delBankCard',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 解冻企业商户*/
export const unfreeze = id => {
  return request({
    url: '/org/merchant/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结企业商户*/
export const freeze = id => {
  return request({
    url: '/org/merchant/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 查看企业商户详细信息(运营/园区)
 * @param param
 */
export const merchantInfo = param => {
  const {
    id,
    operateType
  } = param;
  return request({
    url: '/org/merchant/info',
    method: 'post',
    data: {
      id,
      operateType
    }
  });
};

/**
 * 获取列表信息
 * @param param
 */
export const merchantList = param => {
  return request({
    url: '/mchntProject/list',
    method: 'post',
    data: param
  });
};

/**
 * 获取企业列表
 * @param param
 */
export const orgMerchantList = param => {
  return request({
    url: '/org/merchant/list',
    method: 'post',
    data: param
  });
};

/**
 * 获取企业商户已添加充值银行卡列表
 * @param param
 */
export const listBankCard = param => {
  const {
    merchantNo,
    pageNo,
    pageSize
  } = param;
  return request({
    url: '/org/merchant/listBankCard',
    method: 'post',
    data: {
      merchantNo,
      pageNo,
      pageSize
    }
  });
};

/**
 * 新增企业商户基本信息
 * @param param
 */
export const saveBase = param => {
  const {
    address,
    agentNo,
    areaCode,
    cityCode,
    contactMobile,
    contacts,
    legalPerson,
    legalPersonIdcard,
    legalPersonIdcardpic,
    licenseNo,
    licensePic,
    merchantName,
    provinceCode,
    taxpayerType,
    reviewMobile,
    salesmanNo,
    invoiceType,
    rewardFlag,
    taskLibSwitch,
    jobSwitch,
    gjAgentJson,
    gjOemCode,
    taskReportSwitch
  } = param;
  return request({
    url: '/org/merchant/saveBase',
    method: 'post',
    data: {
      address,
      agentNo,
      areaCode,
      cityCode,
      contactMobile,
      contacts,
      legalPerson,
      legalPersonIdcard,
      legalPersonIdcardpic,
      licenseNo,
      licensePic,
      merchantName,
      provinceCode,
      taxpayerType,
      reviewMobile,
      salesmanNo,
      invoiceType,
      rewardFlag,
      taskLibSwitch,
      jobSwitch,
      gjAgentJson,
      gjOemCode,
      taskReportSwitch
    }
  });
};

/**
 * 查看企业商户详细信息(企业商户)
 * @param param
 */
export const showInfo = () => {
  return request({
    url: '/org/merchant/showInfo',
    method: 'get'
  });
};

/**
 * 获取园区签约企业下拉列表
 * @param param
 */
export const merchantShowList = param => {
  return request({
    url: '/org/merchant/showlist',
    method: 'post',
    data: {
      ...param
    }
  });
};

/**
 * 获取商户下拉选择列表信息
 * @param param
 */
export const taxSelectByMchntNo = param => {
  return request({
    url: '/tax/taxsource/taxSelectByMchntNo',
    method: 'post',
    data: param
  });
};

/**
 * 获取园区归属企业下拉列表
 */
export const merchantBelongMchntList = () => {
  return request({
    url: '/org/merchant/belongMchntList',
    method: 'post'
  });
};

/**
 * 修改企业商户基本信息
 * @param param
 */
export const updateBase = param => {
  const {
    address,
    agentNo,
    areaCode,
    cityCode,
    contactMobile,
    contacts,
    legalPerson,
    legalPersonIdcard,
    legalPersonIdcardpic,
    licenseNo,
    licensePic,
    merchantName,
    provinceCode,
    taxpayerType,
    merchantNo,
    reviewMobile,
    salesmanNo,
    rewardFlag,
    taskLibSwitch,
    jobSwitch,
    taskReportSwitch
  } = param;
  return request({
    url: '/org/merchant/updateBase',
    method: 'post',
    data: {
      address,
      agentNo,
      areaCode,
      cityCode,
      contactMobile,
      contacts,
      legalPerson,
      legalPersonIdcard,
      legalPersonIdcardpic,
      licenseNo,
      licensePic,
      merchantName,
      provinceCode,
      taxpayerType,
      merchantNo,
      reviewMobile,
      salesmanNo,
      rewardFlag,
      taskLibSwitch,
      jobSwitch,
      taskReportSwitch
    }
  });
};

/**
 * 新增或修改企业商户费率设置
 * @param param
 */
export const updateFeeConfig = param => {
  const {
    merchantNo,
    recharegeRate,
    rechargeRateList,
    rechargeBackType
  } = param;
  return request({
    url: '/org/merchant/updateFeeConfig',
    method: 'post',
    data: {
      merchantNo,
      recharegeRate,
      rechargeRateList,
      rechargeBackType
    }
  });
};

/**
 * 新增或修改企业商户签约设置
 * signList是数组
 *
 * @param param
 */
export const updateSignConfig = param => {
  const {
    merchantNo,
    signList
  } = param;
  // signList是数组{"protocolEnd": "string","protocolStart": "string","protocolUrl": "string","taxNo": "string"}

  return request({
    url: '/org/merchant/updateSignConfig',
    method: 'post',
    data: {
      merchantNo,
      signList
    }
  });
};

/**
 * 新增企业商户园区签约信息
 *
 * @param param
 */
export const updateMerchantTaxs = param => {
  const {
    merchantNo,
    protocolEnd,
    protocolStart,
    protocolUrl,
    taxNo,
    rateInfoList,
    rateType,
    rechargeRate
  } = param;
  return request({
    url: '/org/merchant/updateMerchantTaxs',
    method: 'post',
    data: {
      merchantNo,
      protocolEnd,
      protocolStart,
      protocolUrl,
      taxNo,
      rateInfoList,
      rateType,
      rechargeRate
    }
  });
};

/**
 *删除企业商户园区签约信息
 *
 * @param param
 */
export const delMerchantTaxs = id => {
  return request({
    url: '/org/merchant/delMerchantTaxs',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 查询企业商户园区签约信息列表
 *
 * @param param
 */
export const queryMerchantTaxs = merchantNo => {
  return request({
    url: '/org/merchant/queryMerchantTaxs',
    method: 'post',
    data: {
      merchantNo
    }
  });
};
export const audit = (merchantNo, status) => {
  return request({
    url: '/org/merchant/audit',
    method: 'post',
    data: {
      merchantNo,
      status
    }
  });
};

/**
 *预警设置获取详细信息
 *
 * @param param
 */
export const getMerchantConfigInfo = id => {
  return request({
    url: '/org/merchantConfig/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *保存预警设置
 *
 * @param param
 */
export const saveMerchantConfig = params => {
  const {
    merchantNo,
    warnContacts,
    warnCount,
    warnSwitch,
    warnValue
  } = params;
  return request({
    url: '/org/merchantConfig/save',
    method: 'post',
    data: {
      merchantNo,
      warnContacts,
      warnCount,
      warnSwitch,
      warnValue
    }
  });
};

/**
 *修改预警设置
 *
 * @param param
 */
export const updateMerchantConfig = params => {
  const {
    id,
    merchantNo,
    warnContacts,
    warnCount,
    warnSwitch,
    warnValue
  } = params;
  return request({
    url: '/org/merchantConfig/update',
    method: 'post',
    data: {
      id,
      merchantNo,
      warnContacts,
      warnCount,
      warnSwitch,
      warnValue
    }
  });
};

/**
 * 下载企业列表
 */
export const downloadMerchant = params => {
  return download({
    url: '/org/merchant/download',
    data: params
  });
};

/**
 * 项目下载
 */
export const mchntProjectDownload = params => {
  return download({
    url: '/mchntProject/download',
    data: params
  });
};

/**
 *项目记录列表
 *
 * @param param
 */
export const merchantProjectList = params => {
  const {
    beginTime,
    endTime,
    merchantNo,
    projectName,
    projectNo,
    taxNo,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/project/merchantProject/list',
    method: 'post',
    data: {
      beginTime,
      endTime,
      merchantNo,
      projectName,
      projectNo,
      taxNo,
      pageNo,
      pageSize
    }
  });
};

/**
 *项目记录详情
 *
 * @param param
 */
export const merchantProjectInfo = id => {
  return request({
    url: '/project/merchantProject/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *项目记录详情
 *
 * @param param
 */
export const isJobOpen = () => {
  return request({
    url: '/org/merchant/jobOpen',
    method: 'get'
  });
};
export const isShowResult = param => {
  return request({
    url: '/org/merchant/taskReportSwitch',
    method: 'post',
    data: param
  });
};

/**
 *查看系统是否支持国金助手服务商体系
 *
 */
export const gjhelperSupportReq = () => {
  return request({
    url: '/org/merchant/gjhelperSupport',
    method: 'get'
  });
};

/**
 *查询国金助手OEM机构数据-下拉框
 *
 */
export const gjOemCodeSelectReq = () => {
  return request({
    url: '/org/merchant/gjOemCodeSelect',
    method: 'get'
  });
};

/**
 *查询国金助手服务商信息
 *
 * @param param
 */
export const queryGjAgentReq = params => {
  return request({
    url: '/org/merchant/queryGjAgent',
    method: 'post',
    data: params
  });
};

/**
 *新增查询商户国金助手服务商费率信息
 *
 * @param param
 */
export const queryGjAgentFeeRateReg = params => {
  return request({
    url: '/org/merchant/queryGjAgentFeeRate',
    method: 'post',
    data: params
  });
};

/**
 *获取园区下拉列表 - 标准接口(按登录角色获取下拉园区)
 *
 * @param param
 */
export const taxsourceShowlist = params => {
  return request({
    url: '/tax/taxsource/showlist',
    method: 'get',
    data: params
  });
};

/**
 *获取园区下拉列表 - 标准接口(按登录角色获取下拉园区)
 *
 * @param param
 */
export const mchntProjectSave = params => {
  return request({
    url: '/mchntProject/save',
    method: 'post',
    data: params
  });
};

/**
 *获取详细信息
 *
 * @param param
 */
export const mchntProjectInfo = params => {
  return request({
    url: '/mchntProject/showinfo',
    method: 'post',
    data: params
  });
};

/**
 *新增合同信息
 *
 * @param param
 */
export const mchntProAgreementSave = params => {
  return request({
    url: '/mchntProAgreement/save',
    method: 'post',
    data: params
  });
};

/**
 *修改合同信息
 *
 * @param param
 */
export const mchntProAgreementUpdate = params => {
  return request({
    url: '/mchntProAgreement/update',
    method: 'post',
    data: params
  });
};

/**
 *修改
 *
 * @param param
 */
export const mchntProjectUpdate = params => {
  return request({
    url: '/mchntProject/update',
    method: 'post',
    data: params
  });
};

/**
 * 解冻项目*/
export const projectUnfreeze = id => {
  return request({
    url: '/mchntProject/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结项目
 *
 */
export const projectFreeze = id => {
  return request({
    url: '/mchntProject/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 管理端获取项目小程序邀请码地址(传参示例{id:123})
 *
 */
export const getInviteUrlById = id => {
  return request({
    url: '/mchntProject/getInviteUrlById',
    method: 'post',
    data: {
      id
    }
  });
};