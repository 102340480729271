import { getInvoiceInfoReq, updateContentInvoiceRecord } from '../../../api/invoice';
import { UPDATE_INVOICE_ITEM_ID } from '../../../utils/idName';
import { queryMakeInvoiceContents } from '../../../api/common';
import { isMobile } from '@/utils/validate_rules';
export default {
  name: 'updateInvoiceItem',
  data() {
    return {
      id: '',
      infoForm: {},
      updateForm: {},
      rules: {
        contents: [{
          required: true,
          message: '请选择开票内容',
          trigger: 'change'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'change'
        }],
        custName: [{
          required: true,
          message: '请输入收件人姓名',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入收件人手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入收件人详细地址',
          trigger: 'blur'
        }],
        projectCaption: [{
          required: true,
          message: '请输入项目说明',
          trigger: 'blur'
        }],
        valuationType: [{
          required: true,
          message: '请选择项目计价方式',
          trigger: 'change'
        }],
        projectName: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'change'
        }, {
          min: 1,
          max: 30,
          message: '超过最大长度30个字符',
          trigger: 'blur'
        }],
        datePicker: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }]
      },
      contentList: [],
      // filename: '查看履约确认函',
      projectName: '',
      exampleProjectList: []
    };
  },
  created() {
    this.id = sessionStorage.getItem(UPDATE_INVOICE_ITEM_ID);
    this.getDetail();
  },
  methods: {
    getDetail() {
      getInvoiceInfoReq({
        id: this.id
      }).then(response => {
        this.infoForm = response.data;
        this.updateForm = {
          invoiceType: this.infoForm.invoiceType,
          contents: this.infoForm.contents,
          custName: this.infoForm.custName,
          address: this.infoForm.address,
          mobile: this.infoForm.mobile,
          remark: this.infoForm.remark,
          id: this.infoForm.id
          // valuationType: this.infoForm.project.valuationType,
          // projectName: this.infoForm.project.projectName,
          // projectCaption: this.infoForm.project.caption,
          // datePicker: [this.infoForm.project.createTime, this.infoForm.project.endDate]
        };
        this.getContents(); // 查询发票内容
      });
    },
    getContents() {
      if (this.infoForm.taxNo) {
        // queryMakeInvoiceContents(this.infoForm.taxNo, this.infoForm.projectInvoiceType).then((response) => {
        queryMakeInvoiceContents(this.infoForm.taxNo, 1).then(response => {
          this.contentList = response.data;
          this.contentList.find(item => {
            if (item.contentName === this.updateForm.contents) {
              this.exampleProjectList = item.exampleProjectList;
              return;
            }
          });
        });
      }
    },
    beforeUpload(file) {
      return true;
    },
    doUpdate() {
      this.$refs['updateForm'].validate(valid => {
        if (valid) {
          // if (this.updateForm.datePicker.length) {
          //   this.updateForm.projectEndDate = this.updateForm.datePicker[1]
          //   this.updateForm.projectStartDate = this.updateForm.datePicker[0]
          // }
          updateContentInvoiceRecord(this.updateForm).then(() => {
            this.$message.success('修改成功');
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    contentsChange() {
      this.contentList.find(item => {
        if (item.contentName === this.updateForm.contents) {
          this.exampleProjectList = item.exampleProjectList;
          return;
        }
      });
    },
    setProjectCaption(value) {
      console.log('setProjectCaption=====' + value);
      this.updateForm.projectName = value;
    }
  }
};