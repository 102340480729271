import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 班组成员列表
 */
export const memberList = data => {
  return request({
    url: '/empTeam/member/list',
    method: 'post',
    data
  });
};

/**
 * 导出班组成员列表
 */
export const downloadMember = params => {
  return download({
    url: '/empTeam/member/download',
    data: params
  });
};