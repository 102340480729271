// import { updateParamsConfig } from '@/api/taxsource'
import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { saveTaxChannel } from '@/api/taxChannel';
import SaveProductByBjb from './saveProductByBJB';
import SaveProductByCcb from './saveProductByCCB';
import SaveProductByZfb from './saveProductByZFB';
import SaveProductByMyb from './saveProductByMYB';
import SaveProductByCcbzx from './saveProductByCCBZX';
import SaveProductByWeiXin from './saveProductByWEIXIN';
import SaveProductByYeepay from './saveProductByYeepay';
import { isExcessSingleMax, isExcessSingleMin, isInteger, isMoney } from '@/utils/validate_rules.js';
export default {
  name: 'saveProduct',
  components: {
    SaveProductByWeiXin,
    SaveProductByCcbzx,
    SaveProductByMyb,
    SaveProductByZfb,
    SaveProductByCcb,
    SaveProductByBjb,
    SaveProductByYeepay,
    ItemTitle
  },
  data() {
    return {
      taxSourceList: [],
      taxStatusType: this.$formatTypeArray('taxStatusType'),
      rechargeAutoStatus: this.$formatTypeArray('isSupport'),
      channelTypes: this.$formatTypeArray('channelType'),
      saveForm: {
        accountNo: '',
        appId: '',
        appKey: '',
        bankName: '',
        channelName: '',
        channelNo: '',
        channelType: 'TAX_OFFLINE',
        custName: '',
        ncPreposeIp: '',
        ncUserId: '',
        ncUserPwd: '',
        privateKey: '',
        publicKey: '',
        quotaMax: '',
        rechargeAuto: 0,
        status: '',
        billCode: '',
        ccbBillCode: '',
        useofCode: '',
        baseType: '',
        alipayPid: '',
        subMchid: '',
        subAppid: '',
        singleMax: '2000',
        singleMin: '0',
        onlinePayStatus: 0,
        onlinePayMax: 0,
        onlinePayCount: 0
      },
      rules: {
        accountNo: [{
          required: true,
          message: '请输入劳务公司入账账户',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入劳务公司入账开户行',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        custName: [{
          required: true,
          message: '请输入劳务公司入账户名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入代付商户SM2私钥',
          trigger: 'blur'
        }],
        channelNo: [{
          required: true,
          message: '请输入通道序号',
          trigger: 'blur'
        }, {
          min: 3,
          max: 3,
          message: '通道序号为三位数',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择通道状态',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入劳务公司入账开户行',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        accountNo: [{
          required: true,
          message: '请输入账账户',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        custName: [{
          required: true,
          message: '请输入劳务公司入账户名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        channelName: [{
          required: true,
          message: '请输入通道名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '请输入正确的通道名称',
          trigger: 'blur'
        }],
        channelType: [{
          required: true,
          message: '请输入通道类型',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择签约劳务公司',
          trigger: 'blur'
        }],
        baseType: [{
          required: true,
          message: '请选择基准类型',
          trigger: 'blur'
        }],
        singleMin: [{
          required: true,
          message: '请输入最小限额',
          trigger: 'blur'
        }, {
          validator: isExcessSingleMin,
          trigger: 'blur'
        }],
        singleMax: [{
          required: true,
          message: '请输入最大限额',
          trigger: 'blur'
        }, {
          validator: isExcessSingleMax,
          trigger: 'blur'
        }],
        onlinePayStatus: [{
          required: true,
          message: '请选择基准类型',
          trigger: 'change'
        }],
        onlinePayMax: [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }, {
          validator: isMoney,
          trigger: 'blur'
        }],
        onlinePayCount: [{
          required: true,
          message: '请输入次数',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    addSubmit() {
      // debugger
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          saveTaxChannel(this.saveForm).then(response => {
            this.$message.success('操作成功');
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack(message) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (message) {
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};