var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-scrollbar", {
    attrs: {
      wrapClass: "scrollbar-wrapper"
    }
  }, [_c("logo", {
    attrs: {
      isCollapse: _vm.isCollapse
    }
  }), _vm._v(" "), _c("el-menu", {
    attrs: {
      mode: "vertical",
      "show-timeout": 200,
      "default-active": _vm.activeKey,
      collapse: _vm.isCollapse,
      "background-color": "#353441",
      "text-color": "white",
      "active-text-color": "white",
      router: ""
    }
  }, [_c("sidebar-item", {
    attrs: {
      routes: _vm.permission_routers
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };