var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._l(_vm.dynamicTags, function (tag, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(tag);
        },
        change: _vm.changeVal
      }
    }, [_vm._v("\n    " + _vm._s(tag) + "\n  ")]);
  }), _vm._v(" "), _vm.inputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.showInput
    }
  }, [_vm._v(_vm._s(_vm.btnText))])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };