var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "editForm",
    staticClass: "add-menu-form",
    attrs: {
      size: "mini",
      model: _vm.editForm,
      "label-width": "150px",
      rules: _vm.addRules
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "类型",
      prop: "type"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "type", $$v);
      },
      expression: "editForm.type"
    }
  }, _vm._l(_vm.menuTypeArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n            ")]);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "菜单名称",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.editForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "title", $$v);
      },
      expression: "editForm.title"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上级菜单",
      prop: "parentName"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      readonly: _vm.isTrue
    },
    on: {
      focus: _vm.handleSelectTree
    },
    model: {
      value: _vm.editForm.parentName,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "parentName", $$v);
      },
      expression: "editForm.parentName"
    }
  }), _vm._v(" "), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.editForm.parentId,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "parentId", $$v);
      },
      expression: "editForm.parentId"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "vue组件名称",
      prop: "component"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-editForm": "off"
    },
    model: {
      value: _vm.editForm.component,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "component", $$v);
      },
      expression: "editForm.component"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "VUE重定向",
      prop: "redirect"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-editForm": "off"
    },
    model: {
      value: _vm.editForm.redirect,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "redirect", $$v);
      },
      expression: "editForm.redirect"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "路由Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-editForm": "off"
    },
    model: {
      value: _vm.editForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "name", $$v);
      },
      expression: "editForm.name"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "路由Path",
      prop: "url"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.editForm.url,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "url", $$v);
      },
      expression: "editForm.url"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "图标",
      prop: "icon"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.editForm.icon,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "icon", $$v);
      },
      expression: "editForm.icon"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否展现",
      prop: "hidden"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.hidden,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "hidden", $$v);
      },
      expression: "editForm.hidden"
    }
  }, _vm._l(_vm.isHiddenArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n            ")]);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "排序编号",
      prop: "orderNum"
    }
  }, [_c("el-input-number", {
    attrs: {
      "auto-editForm": "off",
      "controls-position": "right",
      min: 1,
      max: 100
    },
    model: {
      value: _vm.editForm.orderNum,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "orderNum", $$v);
      },
      expression: "editForm.orderNum"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否连续录入"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.isContinue,
      callback: function ($$v) {
        _vm.isContinue = $$v;
      },
      expression: "isContinue"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("否")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("是")])], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.editForm.type == 2 ? _c("el-form-item", {
    attrs: {
      label: "权限接口",
      prop: "apiIdList"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      placeholder: "请输入关键词",
      "remote-method": _vm.remoteMethod,
      loading: _vm.loading
    },
    on: {
      onblur: _vm.initApiList
    },
    model: {
      value: _vm.editForm.apiIdList,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "apiIdList", $$v);
      },
      expression: "editForm.apiIdList"
    }
  }, _vm._l(_vm.apiArr, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "选择菜单",
      visible: _vm.dialogVisible,
      width: "30%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.treeData,
      "node-key": "id",
      defaultExpandAll: "",
      "highlight-current": "",
      props: _vm.defaultProps
    },
    on: {
      "node-click": _vm.handleNodeClick
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.handleChangeMenu
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };