import CryptoJS from 'crypto-js';
/*
export default {
  encrypt(word, keyStr,password){
    var key  = CryptoJS.enc.Utf8.parse(keyStr)+password//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    var srcs = CryptoJS.enc.Utf8.parse(word)
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7})
    return encrypted.toString()
  },
  //解密
  decrypt(word, keyStr){
    keyStr = keyStr ? keyStr : 'abcdefgabcdefg12'
    var key  = CryptoJS.enc.Utf8.parse(keyStr)//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
    var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7})
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  },
  userKey(key) {
    return key + key.substring(key.length - 6)
  }

}
*/

var key = CryptoJS.enc.Utf8.parse('A-16-Byte-keyVal');
var iv = CryptoJS.enc.Utf8.parse('A-16-Byte-String');

// aes加密
export function encrypt(context) {
  var encrypted = '';
  if (typeof context === 'object') {
    context = JSON.stringify(context);
  }
  var srcs = CryptoJS.enc.Utf8.parse(context);
  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

// aes解密
export function decrypt(context) {
  var decrypt = CryptoJS.AES.decrypt(context, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}