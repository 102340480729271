import CityCascader from '@/base/ui/cascader/cityCascader';
import { saveBase, showAgentlist } from '@/api/agent';
import { showSalesmanlist } from '@/api/salesman';
import { beforeUploadPdf } from '@/utils/util';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { isLicenseNo, isMobile } from '@/utils/validate_rules';
import UploadFile from '@/base/ui/upload/uploadFile';
import UploadImage from '@/base/ui/upload/uploadImage';
import UploadMoreFile from '../../../../base/ui/upload/uploadMoreFile';
export default {
  name: 'saveBase',
  components: {
    UploadMoreFile,
    UploadImage,
    UploadFile,
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      legalPersonIdcardpic: '',
      licensePic: '',
      selectedOptions: '',
      city: '',
      salesManList: [],
      agentList: [],
      agentType: this.$formatTypeArray('agentTypeStatutes'),
      saveForm: {
        address: '',
        agentName: '',
        agentType: '',
        areaCode: '',
        cityCode: '',
        contactNumber: '',
        contacts: '',
        legalPerson: '',
        legalPersonIdcard: '',
        legalPersonIdcardpic: '',
        licenseNo: '',
        licensePic: '',
        protocolEnd: '',
        protocolStart: '',
        protocolUrl: '',
        provinceCode: '',
        salesmanNo: '',
        superiorAgent: '',
        datePicker: [],
        baseFileList: []
      },
      rules: {
        datePicker: [{
          required: true,
          message: '请选择协议有效期',
          trigger: 'blur'
        }],
        superiorAgent: [{
          required: true,
          message: '请选择上级服务商',
          trigger: 'blur'
        }],
        contactNumber: [{
          required: true,
          message: '请输入联系人手机号码',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确联系人姓名',
          trigger: 'blur'
        }],
        legalPerson: [{
          required: true,
          message: '请输入法人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确的法人姓名',
          trigger: 'blur'
        }],
        legalPersonIdcard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          min: 18,
          max: 18,
          message: '请输入正确的身份证号码',
          trigger: 'blur'
        }],
        agentName: [{
          required: true,
          message: '请输入服务商名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        licenseNo: [{
          required: true,
          message: '请输入统一信用代码',
          trigger: 'blur'
        }, {
          validator: isLicenseNo,
          trigger: 'blur'
        }],
        cityCode: [{
          required: true,
          message: '请选择所属地区',
          trigger: 'blur'
        }],
        agentType: [{
          required: true,
          message: '请选择服务商类型',
          trigger: 'blur'
        }],
        licensePic: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }],
        legalPersonIdcardpic: [{
          required: true,
          message: '请上传身份证',
          trigger: 'blur'
        }],
        salesmanNo: [{
          required: true,
          message: '请选择所属客户经理',
          trigger: 'blur'
        }],
        agentLevel: [{
          required: true,
          message: '请选择服务商属性',
          trigger: 'blur'
        }],
        baseFileList: [{
          required: true,
          message: '请上传协议',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    gotoSecond: {
      default: null
    }
  },
  created() {
    const that = this;
    showSalesmanlist('').then(response => {
      that.salesManList = response.data;
    });
    this.getAngentList();
  },
  watch: {
    saveForm(value) {
      this.saveForm.datePicker = [value.protocolStart, value.protocolEnd];
    }
  },
  methods: {
    getAngentList() {
      const that = this;
      showAgentlist().then(response => {
        that.agentList = response.data;
      });
    },
    addSubmit() {
      this.saveForm.protocolUrl = [];
      this.saveForm.baseFileList.forEach(item => {
        this.saveForm.protocolUrl.push(item.response.data.saveKey);
      });
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      if (this.saveForm.datePicker !== null && this.saveForm.datePicker.length) {
        this.saveForm.protocolEnd = this.saveForm.datePicker[1];
        this.saveForm.protocolStart = this.saveForm.datePicker[0];
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          saveBase(this.saveForm).then(response => {
            if (this.gotoSecond) {
              this.saveForm.agentNo = response.data;
              this.gotoSecond(this.saveForm);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = res.data.saveKey;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = res.data.saveKey;
    }
  }
};