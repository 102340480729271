import { repayBatchDelete } from '@/api/taxMerchant';
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        status: 1,
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '请输入删除原因',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.repayBatchDeleteAPI();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('refresh');
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.resetForm('form');
    },
    deleteSuccess() {
      this.$confirm('提交成功，等待平台工作人员审核后生效！', '温馨提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        this.handleClose();
      }).catch(() => {
        this.handleClose();
      });
    },
    async repayBatchDeleteAPI() {
      await repayBatchDelete({
        id: this.id,
        status: this.form.status,
        remark: this.form.remark
      });
      this.dialogVisible = false;
      this.deleteSuccess();
    }
  }
};