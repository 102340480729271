var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg invoice"
  }, [_c("div", {
    staticClass: "title-top"
  }, [_c("item-title", {
    attrs: {
      name: "开票信息"
    }
  }), _vm._v(" "), _vm.hasUpdateInfoPermission ? _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.updateInvoiceInfo
    }
  }, [_vm._v(_vm._s(_vm.invoiceOperation))]) : _vm._e()], 1), _vm._v(" "), _vm.invoiceConfig != null ? _c("div", [_c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票抬头：",
      value: _vm.invoiceConfig.merchantName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "税务登记证号：",
      value: _vm.invoiceConfig.invoiceNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "注册电话：",
      value: _vm.invoiceConfig.companyNumber,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "注册地址：",
      value: _vm.invoiceConfig.companyAddress,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "开户银行：",
      value: _vm.invoiceConfig.bankName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "开户账户：",
      value: _vm.invoiceConfig.accountNo,
      left: false
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "title-top"
  }, [_c("item-title", {
    attrs: {
      name: "收件地址"
    }
  }), _vm._v(" "), _vm.hasAddAddrPermission && !_vm.isFiveAddr ? _c("el-button", {
    attrs: {
      type: "text",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.addAddress
    }
  }, [_vm._v("新增地址")]) : _vm._e()], 1), _vm._v(" "), _vm.addressList.length > 0 ? _c("div", {
    staticClass: "address-container"
  }, [_c("address-item", {
    key: _vm.addressList[0].id,
    attrs: {
      address: _vm.addressList[0],
      selected: _vm.isAddrSelected(_vm.addressList[0])
    },
    on: {
      click: _vm.selAddrChanged
    }
  }), _vm._v(" "), _c("el-collapse-transition", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAddrExpanded,
      expression: "isAddrExpanded"
    }]
  }, _vm._l(_vm.expandAddress, function (address) {
    return _c("address-item", {
      key: address.id,
      attrs: {
        address: address,
        selected: _vm.isAddrSelected(address)
      },
      on: {
        click: _vm.selAddrChanged
      }
    });
  }), 1)]), _vm._v(" "), _vm.addressList.length > 1 ? _c("el-button", {
    staticClass: "address-expand",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.expand
    }
  }, [_vm._v(_vm._s(_vm.isAddrExpanded ? "收起" : "展开"))]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "可开票信息"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.taxsourceList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeTotal",
      label: "累计交易金额",
      "min-width": "170",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "已开票金额",
      "min-width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n          " + _vm._s(_vm.substract(scope.row)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "unticketTotal",
      label: "剩余可开票金额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _vm.makeInvoicePath ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.makeInvoicePath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.makeInvoiceRow(scope.row);
            }
          }
        }, [_vm._v("申请开票")]) : _vm._e()];
      }
    }], null, false, 4535557)
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("update-info", {
    attrs: {
      isVisible: _vm.isInfoVisible,
      invoiceConfigObj: _vm.editInvoiceConfig
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isInfoVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isInfoVisible = $event;
      },
      updated: _vm.updated
    }
  }), _vm._v(" "), _c("update-address", {
    attrs: {
      isVisible: _vm.isAddrVisible,
      addressObj: _vm.curEditAddress
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isAddrVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isAddrVisible = $event;
      },
      updated: _vm.updated
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };