var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值凭证审核"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticClass: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.taxName,
      disabled: ""
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxSource", $$v);
      },
      expression: "searchForm.taxSource"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目子编号",
      prop: "customerNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目子编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerNo", $$v);
      },
      expression: "searchForm.customerNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目名称",
      prop: "oemName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.oemName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "oemName", $$v);
      },
      expression: "searchForm.oemName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "充值账号",
      prop: "customerName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入充值账号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerName", $$v);
      },
      expression: "searchForm.customerName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "审核状态",
      prop: "rechargeType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择审核状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.rechargeType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "rechargeType", $$v);
      },
      expression: "searchForm.rechargeType"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.rechargeTypeOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "充值时间",
      prop: "rechargeTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.rechargeTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "rechargeTime", $$v);
      },
      expression: "searchForm.rechargeTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.transactions,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "充值订单号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeAmount",
      label: "充值金额",
      "min-width": "150",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "项目子编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "项目名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelName",
      label: "充值账号",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "chargeType",
      label: "充值银行卡号",
      width: "110",
      formatter: _vm.$formatTypeInTable("chargeType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeDate",
      label: "充值时间",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "chargingAmount",
      label: "审核状态",
      "min-width": "150",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "gjOemName",
      label: "充值备注",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.checkRow(scope.row);
            }
          }
        }, [_vm._v("审核")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.detailRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "45%",
      title: "充值复核确认",
      visible: _vm.updateDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.updateDialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "1.6rem",
      "line-height": "40px"
    }
  }, [_vm._v("请确认当前充值记录是否有效")]), _vm._v(" "), _c("el-form", {
    ref: "updateForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.updateForm,
      "label-position": "left",
      "label-width": "120px",
      rules: _vm.updateRules,
      size: "mini"
    }
  }, [_vm.clickItemSystemRemark !== "" || _vm.clickItemBankRemark !== "" ? _c("el-form-item", {
    attrs: {
      label: "充值提示："
    }
  }, [_vm.clickItemSystemRemark !== "" ? _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.clickItemSystemRemark))]) : _vm._e(), _vm._v(" "), _vm.clickItemBankRemark !== "" ? _c("span", [_vm._v(_vm._s(_vm.clickItemBankRemark))]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.updateForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "status", $$v);
      },
      expression: "updateForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 9
    }
  }, [_vm._v("无效")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("有效")])], 1)], 1), _vm._v(" "), _vm.clickUpdateItem.merchantNo === "" && _vm.updateForm.status === 1 ? _c("el-form-item", {
    attrs: {
      label: "充值甲方单位：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "360px"
    },
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择充值甲方单位"
    },
    model: {
      value: _vm.updateForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "merchantNo", $$v);
      },
      expression: "updateForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (tax) {
    return _c("el-option", {
      key: tax.merchantNo,
      attrs: {
        label: _vm.concatMerchant(tax),
        value: tax.merchantNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "复核意见：",
      prop: "remark"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "360px"
    },
    attrs: {
      type: "textarea",
      maxlength: "80",
      rows: 2,
      "show-word-limit": "",
      clearable: ""
    },
    model: {
      value: _vm.updateForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "remark", $$v);
      },
      expression: "updateForm.remark"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.checkDetail();
      }
    }
  }, [_vm._v("确 认")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.updateDialogVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };