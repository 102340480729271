import request from '@/utils/request';
import download from '@/utils/download';

/*
 * 图片api
 */

const BASE_URL = process.env.BASE_API;
export const saveFile = BASE_URL + '/ossfile/fileSave';
export const savePublicFile = BASE_URL + '/ossfile/publicFileSave';
export const uploadContract = BASE_URL + '/empTeam/signed/uploadContract';
export const uploadInvoice = BASE_URL + '/empTeam/invoice/uploadInvoice';
export const uploadSettlement = BASE_URL + '/empTeam/signed/uploadSettlement'; //上传开票结算单文件
export const uploadHalfFile = BASE_URL + '/client/employee/uploadHalfBody';
export const importAssociateFile = BASE_URL + '/invoice/invoiceAssociate/improtAssociate';
export const annexFileSave = BASE_URL + '/ossfile/annexFileSave';
export const uploadImg = process.env.BASE_IMG_URL;

/**
 * 下载商户列表
 */
export const downloadFile = saveKey => {
  return download({
    url: '/ossfile/fileDown',
    data: {
      saveKey
    }
  });
};

/**
 * 上传图片公有域
 */
export const uploadPublicFileReq = data => {
  return request({
    url: '/ossfile/publicFileSave',
    method: 'post',
    data: data
  });
};