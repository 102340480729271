var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "支付宝参数"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝APPID"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appId) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝PID"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.alipayPid) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝应用私钥"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      disabled: ""
    },
    model: {
      value: _vm.saveForm.privateKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "privateKey", $$v);
      },
      expression: "saveForm.privateKey"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "其他设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支持充值入单"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatIsSupport(_vm.saveForm.rechargeAuto)) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatStatus(_vm.saveForm.status)) + "\n        ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };