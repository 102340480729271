export default {
  name: 'process-item',
  props: {
    number: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    detail: {
      type: String,
      required: true
    }
  }
};