import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { showlist } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import { downloadTaxArea, getTaxAreaList } from '@/api/stat';
import RegionTransChart from './chart';
import { getCurrentMonth, separateDate } from '../../../../utils/util';
import CityCascader from '../../../../base/ui/cascader/cityCascader';
export default {
  name: 'regionTransactionList',
  components: {
    CityCascader,
    RegionTransChart,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      hasDownloadPermission: false,
      list: [],
      listLoading: true,
      searchForm: {
        area: '',
        beginTime: '',
        endTime: '',
        // taxNo: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      taxSources: [],
      dateTime: [],
      selectedOptions: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.get('download');
    }
  },
  mounted() {
    this.dateTime = getCurrentMonth();
    this.getList();
    // this.getTaxSources()
  },
  methods: {
    // concatTaxSource(tax) {
    //   return `${tax.taxName}(${tax.taxNo})`
    // },
    // getTaxSources() {
    //   showlist().then(response => {
    //     this.taxSources = response.data
    //   })
    // },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      if (this.dateTime === null || this.dateTime.length === 0) {
        this.$message.error('请选择入账时间');
        return;
      }
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.dateTime);
      this.currentSearch.beginTime = beginTime;
      this.currentSearch.endTime = endTime;
      if (this.$refs.proviceCity && this.$refs.proviceCity.selectedOptions.length) {
        this.currentSearch.area = this.$refs.proviceCity.selectedOptions[0];
      }
      getTaxAreaList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.dateTime = getCurrentMonth();
      this.$refs.proviceCity.selectedOptions = [];
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      if (this.$refs.proviceCity && this.$refs.proviceCity.selectedOptions.length) {
        this.currentSearch.area = this.$refs.proviceCity.selectedOptions[0];
      }
      downloadTaxArea(this.currentSearch).then(response => {
        downloadExcel(response.data, '区域交易额统计表.xls');
      });
    }
  }
};