import { invoiceConfigInfo, invoiceConfigUpdate } from '@/api/taxMerchant';
import { isMoneyNumber } from '@/utils/validate_rules';
export default {
  props: {
    visiable: Boolean,
    merchantNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formLabelWidth: '80px',
      form: {
        accountNo: '',
        bankName: '',
        companyAddress: '',
        companyNumber: '',
        id: '',
        invoiceNo: '',
        merchantName: '',
        merchantNo: ''
      },
      rules: {
        agreementAmt: [{
          required: true,
          message: '请输入合同金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '修改发票信息';
    }
  },
  methods: {
    opened() {
      if (this.merchantNo) {
        this.startInvoiceConfigInfo();
      }
    },
    onClose() {
      this.handleClose();
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      this.$refs['ruleForm'].resetFields();
      done && done();
    },
    onSure() {
      this.startInvoiceConfigUpdate();
    },
    async startInvoiceConfigInfo() {
      const params = {
        merchantNo: this.merchantNo
      };
      let res = await invoiceConfigInfo(params);
      const {
        accountNo,
        bankName,
        companyAddress,
        companyNumber,
        id,
        invoiceNo,
        merchantName
      } = res.data;
      this.form = {
        accountNo,
        bankName,
        companyAddress,
        companyNumber,
        id,
        invoiceNo,
        merchantName,
        merchantNo: this.merchantNo
      };
    },
    async startInvoiceConfigUpdate() {
      const {
        accountNo,
        bankName,
        companyAddress,
        companyNumber,
        id,
        invoiceNo,
        merchantName,
        merchantNo
      } = this.form;
      const params = {
        accountNo,
        bankName,
        companyAddress,
        companyNumber,
        id,
        invoiceNo,
        merchantName,
        merchantNo
      };
      let res = await invoiceConfigUpdate(params);
      this.$message.success('修改成功。。。');
      this.handleSuccess();
    },
    handleSuccess() {
      this.handleClose();
      this.dialogFormVisible = false;
      this.$emit('handleSuccess');
    }
  }
};