import { isDecimal, isValidFloat01, isValidFloat02 } from '@/utils/validate_rules';
export default {
  name: 'FreeItem',
  data() {
    return {
      itemRules: {
        start: [{
          required: true,
          message: '必填项不能为空',
          trigger: 'blur'
        }, {
          validator: isDecimal,
          trigger: 'blur'
        }],
        end: [{
          required: true,
          message: '必填项不能为空',
          trigger: 'blur'
        }, {
          validator: isDecimal,
          trigger: 'blur'
        }],
        rate: [{
          required: true,
          message: '必填项不能为空',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    typeName(val) {
      if (val === '系数') {
        this.itemRules.rate = [{
          required: true,
          message: '必填项不能为空',
          trigger: 'blur'
        }, {
          validator: isValidFloat02,
          trigger: 'blur'
        }];
      } else {
        this.itemRules.rate = [{
          required: true,
          message: '必填项不能为空',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }];
      }
    }
  },
  mounted() {
    if (this.typeName === '系数') {
      this.itemRules.rate = [{
        required: true,
        message: '必填项不能为空',
        trigger: 'blur'
      }, {
        validator: isValidFloat02,
        trigger: 'blur'
      }];
    } else {
      this.itemRules.rate = [{
        required: true,
        message: '必填项不能为空',
        trigger: 'blur'
      }, {
        validator: isValidFloat01,
        trigger: 'blur'
      }];
    }
  },
  props: {
    item: {
      start: '',
      end: '',
      rate: ''
    },
    index: '',
    isUpdate: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    text: {
      required: true
    },
    typeName: {
      type: String,
      default() {
        return '费率';
      }
    }
  },
  created() {},
  methods: {
    validateForm() {
      if (parseInt(this.item.end) < parseInt(this.item.start)) {
        this.$message.error('开始金额小于结束金额');
        return;
      }
      this.$refs['aForm'].validate(valid => {
        this.$emit('checkFreeItem', valid);
      });
    },
    endChange() {
      this.$emit('endChange', this.index);
    }
  }
};