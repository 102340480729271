var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "upload-invoice-dialog",
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "上传回单"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("el-form", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传回单信息："
    }
  }, [_c("div", {
    staticStyle: {
      display: "-webkit-box"
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 1
    },
    model: {
      value: _vm.saveForm.fileList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "fileList", $$v);
      },
      expression: "saveForm.fileList"
    }
  }), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("上传至服务器")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };