import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { accountOutListReq, downloadOutRecords } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'accountOutIndex',
  components: {
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        taxSource: '',
        orderNo: '',
        date: []
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      taxSources: [],
      total: 0,
      listLoading: false,
      transactions: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    downloadForm() {
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const params = {
        taxNo: this.currentSearch.taxSource,
        rechargeBusiNo: this.currentSearch.orderNo,
        beginTime: beginTime,
        endTime: endTime
      };
      downloadOutRecords(params).then(response => {
        downloadExcel(response.data, 'recharge-recorded-records.xls');
      });
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      accountOutListReq({
        taxNo: this.currentSearch.taxSource,
        rechargeBusiNo: this.currentSearch.orderNo,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};