var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      overflow: "auto",
      height: "81vh"
    }
  }, [_c("div", {
    staticClass: "app-container"
  }, [_c("h1", {
    staticClass: "welcome"
  }, [_vm._v("欢迎登录管理平台！")]), _vm._v(" "), _c("img", {
    staticClass: "title",
    attrs: {
      src: "static/img/first/first_title.png"
    }
  })])]);
}];
render._withStripped = true;
export { render, staticRenderFns };