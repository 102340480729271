var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "修改任务分类"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "130px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属商户"
    },
    model: {
      value: _vm.saveForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "merchantNo", $$v);
      },
      expression: "saveForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务分类名称：",
      prop: "categoryName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务分类名称"
    },
    model: {
      value: _vm.saveForm.categoryName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "categoryName", $$v);
      },
      expression: "saveForm.categoryName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "对应开票类目：",
      prop: "billingCategory"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      "show-word-limit": "",
      rows: "3",
      placeholder: "请输入对应开票类目，多个以“,”分隔开。"
    },
    model: {
      value: _vm.saveForm.billingCategory,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "billingCategory", $$v);
      },
      expression: "saveForm.billingCategory"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "是否添加任务提交参数：",
      prop: "hasExtra"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.hasExtra,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "hasExtra", $$v);
      },
      expression: "saveForm.hasExtra"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1), _vm._v(" "), _vm.saveForm.hasExtra === 1 ? _c("el-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增提交参数")]) : _vm._e()], 1)]), _vm._v(" "), _vm.saveForm.hasExtra === 1 ? _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：最多可配置6个自定义参数)")]) : _vm._e(), _vm._v(" "), _vm.saveForm.hasExtra === 1 ? _c("el-table", {
    attrs: {
      data: _vm.saveForm.taskCategoryExtraList,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "extraName",
      label: "参数名",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "extraType",
      label: "参数类型",
      "min-width": "120",
      formatter: _vm.$formatTypeInTable("extraType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "required",
      label: "是否必填",
      "min-width": "140",
      formatter: _vm.$formatTypeInTable("isTrueFalse")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "150",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.$index);
            }
          }
        }, [_vm._v("删除")])];
      }
    }], null, false, 4103083268)
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("提 交")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取 消")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "60%",
      title: _vm.dialogType === 0 ? "添加提交参数" : "修改提交参数",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "dialogForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.dialogForm,
      "label-width": "130px",
      rules: _vm.dialogRules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "参数名：",
      prop: "extraName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入参数名"
    },
    model: {
      value: _vm.dialogForm.extraName,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "extraName", $$v);
      },
      expression: "dialogForm.extraName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "参数类型：",
      prop: "extraType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.dialogForm.extraType,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "extraType", $$v);
      },
      expression: "dialogForm.extraType"
    }
  }, _vm._l(_vm.$formatTypeArray("extraType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "是否必填：",
      prop: "required"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.dialogForm.required,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "required", $$v);
      },
      expression: "dialogForm.required"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.dialogForm.extraType === 2 ? _c("el-form-item", {
    attrs: {
      label: "请设置可选项：",
      prop: "extraValue"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      "show-word-limit": "",
      rows: "3",
      placeholder: "请设置可选项，多个以“|”分隔开。"
    },
    model: {
      value: _vm.dialogForm.extraValue,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "extraValue", $$v);
      },
      expression: "dialogForm.extraValue"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCategoryExtraList
    }
  }, [_vm._v("确认")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.dialogCancel
    }
  }, [_vm._v("取消")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };