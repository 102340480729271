import LeftForm from './components/leftForm';
import MainForm from './components/mainForm';
export default {
  name: 'menuIndex2',
  components: {
    LeftForm,
    MainForm
  },
  data() {
    return {
      parentId: 1
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.$refs.mainForm.updateMenuPath && this.$refs.mainForm.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.$refs.mainForm.clickUpdate = false;
    next();
  },
  methods: {
    changeTreeData(data) {
      localStorage.setItem('menuParentId', data.id);
      this.$refs.mainForm.refershList(data.id);
    }
  },
  mounted() {}
};