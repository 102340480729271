import { showlist } from "@/api/taxsource";
import CityCascader from "@/base/ui/cascader/cityCascader";
import ItemTitle from "@/base/ui/title/itemTitle";
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { isInteger, isMoneyNumber } from "@/utils/validate_rules";
import DbDatePicker from "../../../base/ui/date/db-date-picker";
import { isShowResult } from '../../../api/merchant';
import { getMerchantTaskInfo, getPricingModeSelect, getTaskTypeSelect, updateMerchantTask, getTaskReportList } from "../../../api/task";
import { TASK_UPDATE_ID } from "../../../utils/idName";
import { VueEditor } from 'vue2-editor';
import { fullToolbar } from '@/utils/editor_toolbar';
export default {
  name: "addTaskIndex",
  components: {
    DbDatePicker,
    UploadMoreFile,
    ItemTitle,
    CityCascader,
    VueEditor
  },
  data() {
    return {
      fileList: [],
      addForm: {
        caption: "",
        fileSaveKeys: [],
        taskName: "",
        taskType: "",
        startDate: "",
        endDate: "",
        taxNo: "",
        invitePublic: 0,
        pricingMode: "",
        singleAmount: "",
        submitMode: 0,
        id: "",
        taskTime: [],
        captionInfo: '',
        templeteNo: ''
      },
      editorSettings: {
        modules: {
          toolbar: fullToolbar
        },
        theme: 'snow'
      },
      rules: {
        caption: [{
          required: true,
          message: "请输入任务描述",
          trigger: "blur"
        }, {
          min: 1,
          max: 150,
          message: "最多可输入150个字符",
          trigger: "blur"
        }],
        selectedAddress: [{
          required: true,
          message: "请选择地区",
          trigger: "blur"
        }],
        fileSaveKeys: [{
          required: true,
          message: "请上传任务附件",
          trigger: "blur"
        }],
        taskType: [{
          required: true,
          message: "请选择任务类型",
          trigger: "blur"
        }],
        pricingMode: [{
          required: true,
          message: "请选择任务计价方式",
          trigger: "blur"
        }],
        taskTime: [{
          required: true,
          message: "请选择任务有效期",
          trigger: "blur"
        }],
        singleAmount: [{
          required: true,
          message: "请输入任务金额",
          trigger: "blur"
        }, {
          validator: isMoneyNumber,
          trigger: "blur"
        }],
        singleMaxAmount: [{
          required: true,
          message: "请输入任务金额",
          trigger: "blur"
        }, {
          validator: isMoneyNumber,
          trigger: "blur"
        }],
        deviceType: [{
          required: true,
          message: "请输入设备型号",
          trigger: "blur"
        }],
        itemCycleNum: [{
          required: true,
          message: "请输入租赁时长",
          trigger: "blur"
        }, {
          validator: isInteger,
          trigger: "blur"
        }],
        itemDeviceNum: [{
          required: true,
          message: "请输入需求数量",
          trigger: "blur"
        }, {
          validator: isInteger,
          trigger: "blur"
        }],
        devicePrice: [{
          required: true,
          message: "请输入每台报价",
          trigger: "blur"
        }, {
          validator: isInteger,
          trigger: "blur"
        }],
        unitPrice: [{
          required: true,
          message: "单价不能为空",
          trigger: "blur"
        }, {
          validator: isMoneyNumber,
          trigger: "blur"
        }]
      },
      pricingModeType: [],
      taskTypeSelectType: [],
      amountType: -1,
      taskReportSwitch: '',
      taskTemplateList: []
    };
  },
  created() {
    const id = sessionStorage.getItem(TASK_UPDATE_ID);
    getMerchantTaskInfo(id).then(response => {
      this.addForm = response.data;
      if (this.addForm.singleMaxAmount > 0) {
        this.amountType = 1;
      } else {
        this.amountType = 0;
      }
      // this.addForm.taskTime = [response.data.startDate, response.data.endDate]

      const dateTime = [this.addForm.startDate, this.addForm.endDate];
      this.$set(this.addForm, "taskTime", dateTime);
      this.addForm.fileSaveKeys = [];
      response.data.fileList.forEach(item => {
        const file = {
          name: item.fileName,
          url: item.fileUrlShow,
          response: {
            data: {
              saveKey: item.fileUrl
            },
            code: "BASE.000"
          }
        };
        this.fileList.push(file);
      });
      this.getSelect();
      this.getJobOpen();
    });
  },
  mounted() {},
  methods: {
    getTaskReportList() {
      getTaskReportList({}).then(res => {
        this.taskTemplateList = res.data;
      });
    },
    getJobOpen() {
      isShowResult({
        bizNo: this.addForm.merchantNo
      }).then(response => {
        this.taskReportSwitch = response.data.taskReportSwitch;
        if (this.taskReportSwitch === 1) {
          this.getTaskReportList();
        }
      });
    },
    submitForm() {
      this.addForm.fileSaveKeys = [];
      this.fileList.forEach(item => {
        this.addForm.fileSaveKeys.push(item.response.data.saveKey);
      });
      console.log("this.addForm.taskTime:=====" + this.addForm.taskTime);
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          if (this.taskReportSwitch === 1 && !this.addForm.templeteNo) {
            this.$message.error('请选择任务模版');
            return;
          }
          updateMerchantTask({
            caption: this.addForm.caption,
            fileSaveKeys: this.addForm.fileSaveKeys,
            taskType: this.addForm.taskType,
            startDate: this.addForm.taskTime[0],
            endDate: this.addForm.taskTime[1],
            invitePublic: this.addForm.invitePublic,
            pricingMode: this.addForm.pricingMode,
            singleAmount: this.addForm.singleAmount,
            singleMaxAmount: this.addForm.singleMaxAmount,
            merchantNo: this.addForm.merchantNo,
            id: this.addForm.id,
            captionInfo: this.addForm.captionInfo,
            templeteNo: this.addForm.templeteNo
          }).then(() => {
            this.$message.success("提交成功");
            this.goToList();
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    goToList() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.push("/task/taskRecord");
      });
    },
    getSelect() {
      getPricingModeSelect().then(resonse => {
        this.pricingModeType = resonse.data;
      });
      getTaskTypeSelect(this.addForm.merchantNo).then(resonse => {
        this.taskTypeSelectType = resonse.data;
      });
    },
    changeAmountType() {
      if (this.amountType === 0) {
        this.amountType = 1;
      } else {
        this.amountType = 0;
      }
    }
  }
};