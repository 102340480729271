var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "editForm",
    staticClass: "add-partyrole-form border-bg",
    attrs: {
      size: "mini",
      model: _vm.editForm,
      "label-width": "150px",
      rules: _vm.addRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "机构类型",
      prop: "partyType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.partyType,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "partyType", $$v);
      },
      expression: "editForm.partyType"
    }
  }, _vm._l(_vm.partyTypeArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "roleType",
      label: "角色类型"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.roleType,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "roleType", $$v);
      },
      expression: "editForm.roleType"
    }
  }, _vm._l(_vm.roleTypeArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "roleId",
      label: "角色名称"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.roleId,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "roleId", $$v);
      },
      expression: "editForm.roleId"
    }
  }, _vm._l(_vm.roleArr, function (role) {
    return _c("el-radio", {
      key: role.id,
      attrs: {
        label: role.id,
        border: "",
        size: "medium"
      }
    }, [_vm._v(_vm._s(role.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };