var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "自动选择付款记录"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "本次期望开票金额",
      prop: "expectAmount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入期望开票金额"
    },
    model: {
      value: _vm.addForm.expectAmount,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "expectAmount", $$v);
      },
      expression: "addForm.expectAmount"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "red",
      margin: "30px 0 10px"
    }
  }, [_vm._v("*系统将帮您按发放时间顺序选择未开票的付款记录")]), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };