var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "咨询记录处理"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "handleForm",
    attrs: {
      model: _vm.handleForm,
      "label-position": "left",
      "label-width": "90px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "处理描述",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入处理描述，不超过100个字",
      maxlength: "100",
      rows: 5,
      "show-word-limit": "",
      clearable: ""
    },
    model: {
      value: _vm.handleForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.handleForm, "remark", $$v);
      },
      expression: "handleForm.remark"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确认")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };