var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "费率详情"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "技术服务费率："
    }
  }, [_vm._v("\n            " + _vm._s((_vm.saveForm.serviceRate * 100).toFixed(2)) + "%\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "平台代理费率(增值)："
    }
  }, [_vm._v("\n            " + _vm._s((_vm.saveForm.agentRate * 100).toFixed(2)) + "%\n          ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };