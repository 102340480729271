import DateView from './components/dateView';
import TotalTransaction from './components/totalTransaction';
import TotalData from './components/totalData';
import TransactionHistogram from './components/transactionHistogram';
import TransactionDynamics from './components/transactionDynamics';
import MerchantRate from './components/merchantRate';
import PartnerView from './components/partnerView';
import MerchantView from './components/merchantView';
import QuickEntry from './components/quickEntry';
import { getStatIndex } from '../../../api/stat';
export default {
  name: 'dataReportView',
  components: {
    QuickEntry,
    MerchantView,
    PartnerView,
    MerchantRate,
    TransactionDynamics,
    TransactionHistogram,
    TotalData,
    TotalTransaction,
    DateView
  },
  data() {
    return {
      info: {
        tradeAmountMonth: 0
      }
    };
  },
  created() {
    getStatIndex().then(response => {
      this.info = response.data;
    });
  }
};