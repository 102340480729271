import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getWithdrawListReq, getWithdrawUpdateReq, downloadWithdrawRecords } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import { WITHDRAW_DETAIL_ID, WITHDRAW_CHECK_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import UploadInvoiceDialog from './uploadInvoiceDialog';
import UploadReceptDialog from './uploadReceptDialog';
import { WITHDRAW_DETAIL_OBJECT } from '../../../utils/idName';
export default {
  name: 'accountWithdrawIndex',
  components: {
    UploadReceptDialog,
    UploadInvoiceDialog,
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        customerNo: '',
        customerName: '',
        businessNo: '',
        status: '',
        date: [],
        invoiceFlag: '',
        receiptFlag: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      transactions: [],
      hasDetailPermission: false,
      hasPayPermission: false,
      hasCheckPermission: false,
      detailPath: '',
      checkPath: '',
      uploadReceptDialogVisible: false,
      uploadInvoiceDialogVisible: false,
      invoiceId: false,
      receptId: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    statusOptions: function () {
      return this.$formatTypeArray('withdrawStatus');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDetailPermission = map.has('detail');
      this.hasPayPermission = map.has('payment');
      this.hasCheckPermission = map.has('check');
      this.detailPath = map.get('detail');
      this.checkPath = map.get('check');
    } else {
      this.hasDetailPermission = false;
      this.hasPayPermission = false;
      this.hasCheckPermission = false;
      this.detailPath = '';
      this.checkPath = '';
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('withdrawStatus', row.withdrawStatus);
    },
    formatAccountType(row, column) {
      return this.$formatType('bankType', row.accountType);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    downloadForm() {
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const params = {
        agentNo: this.currentSearch.customerNo,
        agentName: this.currentSearch.customerName,
        status: this.currentSearch.status,
        bussincessNo: this.currentSearch.businessNo,
        invoiceFlag: this.currentSearch.invoiceFlag,
        receiptFlag: this.currentSearch.receiptFlag,
        beginTime: beginTime,
        endTime: endTime
      };
      downloadWithdrawRecords(params).then(response => {
        downloadExcel(response.data, 'withdraw-records.xls');
      });
    },
    checkRow(index, object) {
      // 查看详情
      sessionStorage.setItem(WITHDRAW_DETAIL_ID, object.id);
      sessionStorage.setItem(WITHDRAW_DETAIL_OBJECT, JSON.stringify(object));
      this.$router.push({
        path: this.detailPath
      });
    },
    payRow(index, object) {
      // 付款
      this.$confirm('确认线下已付款，请点击"是"，否则请点击"否"', '付款提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.checkDetail(object.id);
      });
    },
    reviewRow(index, object) {
      // 审核
      sessionStorage.setItem(WITHDRAW_CHECK_ID, object.id);
      this.$router.push({
        path: this.checkPath
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      getWithdrawListReq({
        agentNo: this.currentSearch.customerNo,
        agentName: this.currentSearch.customerName,
        status: this.currentSearch.status,
        bussincessNo: this.currentSearch.businessNo,
        invoiceFlag: this.currentSearch.invoiceFlag,
        receiptFlag: this.currentSearch.receiptFlag,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    checkDetail(id) {
      getWithdrawUpdateReq({
        id: id,
        withdrawStatus: '2'
      }).then(() => {
        this.getList();
      });
    },
    showUploadReceptDialog(row) {
      this.receptId = row.id;
      this.uploadReceptDialogVisible = true;
    },
    showUploadInvoiceDialog(row) {
      this.invoiceId = row.id;
      this.uploadInvoiceDialogVisible = true;
    },
    changeInvoiceVisible(val) {
      this.uploadInvoiceDialogVisible = val;
      if (!val) {
        this.getList();
      }
    },
    changeReceptVisible(val) {
      this.uploadReceptDialogVisible = val;
      if (!val) {
        this.getList();
      }
    }
  }
};