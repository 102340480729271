import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { addTaxsourceRouter } from '../../../../api/taxRouter';
export default {
  name: 'addTaxRouterDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        taxNo: '',
        seqNumber: ''
      },
      taxList: [],
      myDialogFormVisible: false,
      rules: {
        taxNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        seqNumber: [{
          required: true,
          message: '请输入路由顺序',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    addDialogVisible: false
  },
  watch: {
    addDialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    addBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          addTaxsourceRouter(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};