import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 获取交易查询列表
 */
export const getTransactionListReq = params => {
  return request({
    url: '/trade/repayment/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取批次查询列表
 */
export const getBatchListReq = params => {
  return request({
    url: '/trade/repayBatch/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取批次详细信息
 */
export const getBatchInfoReq = params => {
  return request({
    url: '/trade/repayBatch/info',
    method: 'post',
    data: params
  });
};

/**
 * 复核批次
 */
export const reviewBatchReq = params => {
  return request({
    url: '/trade/repayBatch/update',
    method: 'post',
    data: params
  });
};

/**
 * 获取交易详细信息
 */
export const getTransactionInfoReq = params => {
  return request({
    url: '/trade/repayment/info',
    method: 'post',
    data: params
  });
};

/**
 * 获取提现列表
 */
export const getWithdrawListReq = params => {
  return request({
    url: '/profit/withdraw/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取提现详情
 */
export const getWithdrawInfoReq = params => {
  return request({
    url: '/profit/withdraw/info',
    method: 'post',
    data: params
  });
};

/**
 * 获取提现状态维护
 */
export const getWithdrawUpdateReq = params => {
  return request({
    url: '/profit/withdraw/audit',
    method: 'post',
    data: params
  });
};

/**
 * 上传发票
 */
export const uploadRecept = params => {
  return request({
    url: '/profit/withdraw/uploadRecept',
    method: 'post',
    data: params
  });
};

/**
 * 上传回单
 */
export const uploadInvoice = params => {
  return request({
    url: '/profit/withdraw/uploadInvoice',
    method: 'post',
    data: params
  });
};

/**
 * 获取提现申请
 */
export const getWithdrawApplyReq = params => {
  return request({
    url: '/profit/withdraw/save',
    method: 'post',
    data: params
  });
};

/**
 * 获取充值列表
 */
export const getRechargeListReq = params => {
  return request({
    url: '/trade/recharge/list',
    method: 'post',
    data: params
  });
};

/**
 * 新增充值
 */
export const getSaveRechargeReq = params => {
  return request({
    url: '/trade/recharge/save',
    method: 'post',
    data: params
  });
};

/**
 * 修改充值状态
 */
export const getUpdateRechargeReq = params => {
  return request({
    url: '/trade/recharge/update',
    method: 'post',
    data: params
  });
};

/**
 * 新接口-充值记录一键同步 V4.3
 */
export const syncRechargeListReq = () => {
  return request({
    url: '/trade/recharge/syncGjAll',
    method: 'get'
  });
};

/**
 * 代付记录入库
 */
export const repaymentSaveReq = params => {
  return request({
    url: '/trade/repayment/save',
    method: 'post',
    data: params
  });
};

/**
 * 批量付款确认更新
 */
export const confirmSaveReq = params => {
  return request({
    url: '/trade/confirm/save',
    method: 'post',
    data: params
  });
};

/**
 * 获取批量付款确认分页列表信息
 */
export const tradeConfirmPageList = params => {
  return request({
    url: '/trade/confirm/pageList',
    method: 'post',
    data: params
  });
};

/**
 * 下载确认异常数据(上送保存接口返回的errorCacheKey)
 */
export const tradeConfirmDownError = params => {
  return download({
    url: '/trade/confirm/downError',
    data: params
  });
};

/**
 * 下载认证问题数据
 */
export const repaymentDownUnAuthReq = params => {
  return download({
    url: '/trade/repayment/downUnAuth',
    data: params
  });
};

/**
 * 获取充值指引列表
 */
export const requestRechageGuideReq = params => {
  return request({
    url: '/trade/recharge/guide',
    method: 'post',
    data: params
  });
};

/**
 * 获取申请付款分页列表信息
 */
export const transactionUploadListReq = params => {
  return request({
    url: '/trade/repayment/uploadList',
    method: 'post',
    data: params
  });
};

/**
 * 首页信息
 */
export function homePageInfoReq() {
  return request({
    url: '/index/index/info',
    method: 'post'
  });
}

/**
 * 获取充值台账明细
 */
export const rechargeLedgerReq = params => {
  return request({
    url: '/trade/rechargeLedger/info',
    method: 'post',
    data: params
  });
};

/**
 * 获取充值入账列表
 */
export const rechargeRecordedListReq = params => {
  return request({
    url: '/trade/rechargeAccount/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取银企出账列表
 */
export const accountOutListReq = params => {
  return request({
    url: '/trade/yinqinc/list',
    method: 'post',
    data: params
  });
};

/**
 * 付款记录下载
 */
export const transactionRecordsDownload = params => {
  return download({
    url: '/trade/repayment/download',
    data: params
  });
};

/**
 * 人工付款记录下载
 */
export const manualPayListDownload = params => {
  return download({
    url: '/trade/repayment/manualPayListDownload',
    data: params
  });
};

/**
 * 批次复核时付款记录下载
 */
export const transactionReviewDownload = params => {
  return download({
    url: '/trade/repayment/reviewDownload',
    data: params
  });
};

/**
 * 下载提现记录
 */
export const downloadWithdrawRecords = params => {
  return download({
    url: '/profit/withdraw/download',
    data: params
  });
};

/**
 * 下载充值记录
 */
export const downloadRechargeRecords = params => {
  return download({
    url: '/trade/recharge/download',
    data: params
  });
};

/**
 * 下载充值入账记录
 */
export const downloadRecordedRecords = params => {
  return download({
    url: '/trade/rechargeAccount/download',
    data: params
  });
};

/**
 * 下载充值入账记录
 */
export const downloadOutRecords = params => {
  return download({
    url: '/trade/yinqinc/download',
    data: params
  });
};

/**
 * 查询银行账户对应商户列表
 */
export const queryMchntList = accountNo => {
  return request({
    url: '/trade/recharge/queryMchntList',
    method: 'post',
    data: {
      accountNo
    }
  });
};

/**
 * 用于付款园区下拉选择
 */
export const getTaxSelectList = params => {
  return request({
    url: '/trade/repayment/taxSelectList',
    method: 'post',
    data: params
  });
};

/**
 * 获取列表信息(供业绩分润明细查询、提现业绩明细查询)
 */
export const getAgentProfitDetailList = params => {
  return request({
    url: '/profit/agentProfitDetail/list',
    method: 'post',
    data: params
  });
};

/**
 * 新接口-批次代发一键同步(同步失败记录)
 */
export const syncRepayBatchReq = () => {
  return request({
    url: '/trade/repayBatch/syncGjAll',
    method: 'get'
  });
};

/**
 * 抽检记录-查询列表
 */
export const getSamplingRecordsReq = params => {
  return request({
    url: '/trade/repaymentInspect/list',
    method: 'post',
    data: params
  });
};

/**
 * 抽检记录-导出列表
 */
export const samplingRecordsDownload = params => {
  return download({
    url: '/trade/repaymentInspect/download',
    data: params
  });
};

/**
 * 抽检记录-删除
 */
export const deleteSamplingRecords = id => {
  return request({
    url: '/trade/repaymentInspect/deleteInspect',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 * 差异处理记录列表
 */
export const getErrorList = params => {
  return request({
    url: '/trade/error/list',
    method: 'post',
    data: params
  });
};
/**
 * 账户充值(限项目端自助充值使用)
 */
export const rechargeCreate = params => {
  return request({
    url: '/trade/recharge/create',
    method: 'post',
    data: params
  });
};

/**
 * 重新支付(限项目端自助充值使用)(传参示例{id:123})
 */
export const rechargeRepay = params => {
  return request({
    url: '/trade/recharge/repay',
    method: 'post',
    data: params
  });
};

/**
 * 根据充值记录ID获取详情(传参示例{id:123})
 */
export const rechargeInfo = params => {
  return request({
    url: '/trade/recharge/info',
    method: 'post',
    data: params
  });
};

/**
 * /trade/recharge/update 修改充值状态(充值复核)
 */
export const rechargeUpdate = params => {
  return request({
    url: '/trade/recharge/update',
    method: 'post',
    data: params
  });
};

/**
 * 撤销批次信息(传参示例{id:123})
 */
export const repayBatchCancel = params => {
  return request({
    url: '/trade/repayBatch/cancel',
    method: 'post',
    data: params
  });
};

/**
 * /trade/repayBatch/reviewCheck 复核处理校验(审核通过是调用)
 */
export const repayBatchReviewCheck = params => {
  return request({
    url: '/trade/repayBatch/reviewCheck',
    method: 'post',
    data: params
  });
};