import BatchDetail from '../../batch/detail';
export default {
  components: {
    BatchDetail
  },
  // beforeRouteEnter(to, from, next) {
  //
  //   next(vm => {
  //     vm.$refs.child.getTaxSources()
  //   })
  // },
  data() {
    return {
      repayType: 1
    };
  }
};