var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-steps", {
    staticStyle: {
      "margin-bottom": "2rem"
    },
    attrs: {
      active: _vm.active,
      "align-center": "",
      "finish-status": "success"
    }
  }, [_c("el-step", {
    attrs: {
      title: "选择付款记录"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "提交发票信息"
    }
  })], 1), _vm._v(" "), _c("keep-alive", {
    attrs: {
      include: "gatherList"
    }
  }, [_vm.active == 0 ? _c("gather-list", {
    on: {
      select: _vm.onSelect
    }
  }) : _c("gather-invoice", {
    attrs: {
      amount: _vm.totalAmt,
      records: _vm.records
    },
    on: {
      cancel: _vm.onCancel,
      submit: _vm.onSubmit
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };