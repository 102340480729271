import { beforeUploadPdf } from '@/utils/util';
import { getAcountInfo } from '@/utils/auth';
import { beforUploadByExcel } from '@/utils/util';
import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'monthImportFile',
  components: {
    ItemTitle
  },
  data() {
    return {
      partyCode: '',
      myFileName: '',
      uploadData: {
        ...this.data,
        partyCode: this.partyCode
      },
      myDialogFormVisible: false,
      baseUrl: process.env.BASE_API
    };
  },
  watch: {
    fileName(val) {
      this.myFileName = val;
    },
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  props: {
    url: {},
    fileName: '',
    data: '',
    dialogVisible: false,
    templateUrl: {
      required: true
    },
    saveFile: {
      required: true
    },
    templateName: {
      default: () => {
        return '模版下载';
      }
    },
    titleName: ''
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
    this.myFileName = this.fileName;
  },
  methods: {
    beforeAvatarUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.$message.success('导入成功');
        this.myDialogFormVisible = false;
      } else {
        this.$message.error(res.errMsg);
      }
    },
    beforeDemoUpload(file) {
      return beforeUploadPdf(this, file);
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.myDialogFormVisible = false;
    },
    submitUpload() {
      this.$refs.upload.submit();
    }
  }
};