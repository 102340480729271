import { queryDistrictList } from '@/api/common';
export default {
  name: 'CityCascader',
  data() {
    const that = this;
    return {
      parentCode: '860000',
      selectedOptions: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const {
            level,
            value
          } = node;
          let code;
          if (level === 0) {
            code = that.parentCode;
          } else {
            code = value;
          }
          queryDistrictList(code).then(response => {
            const nodes = response.data.map(item => ({
              value: item.code,
              label: item.name,
              leaf: level >= that.leaf
            }));
            resolve(nodes);
          });
        }
      }
    };
  },
  props: {
    placeholder: {
      type: String,
      default: function () {
        return '请选择省市';
      }
    },
    isUpdate: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    leaf: {
      default: () => {
        // 设置几级联动，默认2级
        return 1;
      }
    }
  }
};