import { bankInfoReg, updateBankReg } from '../../../api/sys';
import UploadImage from '../../../base/ui/upload/uploadImage';
import { BANK_UPDATE_ID } from '../../../utils/idName';
import { savePublicFile } from '@/api/upload';
export default {
  name: 'updateBank',
  components: {
    UploadImage
  },
  data() {
    return {
      saveFile: savePublicFile,
      id: '',
      saveForm: {
        bankCode: '',
        bankName: '',
        logUrl: ''
      },
      rules: {
        bankCode: [{
          required: true,
          message: '请输入银行名称',
          trigger: 'blur'
        }, {
          min: 4,
          max: 100,
          message: '请输入正确的银行名称',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入英文简称',
          trigger: 'blur'
        }, {
          min: 4,
          max: 100,
          message: '请输入正确的英文简称',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.id = sessionStorage.getItem(BANK_UPDATE_ID);
    this.getInfo();
  },
  methods: {
    getInfo() {
      bankInfoReg(this.id).then(response => {
        this.saveForm = response.data;
      });
    },
    updateBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateBankReg(this.saveForm).then(response => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    handleSuccessLogUrl(res, file) {
      this.saveForm.logUrl = res.data.showUrl;
    }
  }
};