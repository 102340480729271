import request from '@/utils/request';

/**
 * 获取下拉列表信息
 * @param params
 */
export const showSalesmanlist = agentNo => {
  return request({
    url: '/org/salesman/showlist',
    method: 'post',
    data: {
      agentNo
    }
  });
};