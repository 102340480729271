import { isIdCardNo, isMobile } from '@/utils/validate_rules';
import { addCustomerManagerRequest, updateCustomerManagerRequest } from '@/api/customerManager';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'c-manager-addForm',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    manager: {
      type: Object
    }
  },
  data() {
    return {
      addForm: {
        no: '',
        name: '',
        phone: '',
        cardId: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入客户经理姓名',
          trigger: 'blur'
        }],
        cardId: [{
          required: true,
          message: '请输入客户经理身份证号',
          trigger: 'blur'
        }, {
          validator: isIdCardNo,
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入客户经理手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    title: {
      get() {
        return this.manager.id === undefined ? '新增客户经理' : '修改客户经理';
      }
    }
  },
  watch: {
    manager: function (newManager, oldManager) {
      console.log(this.manager);
      this.addForm.no = newManager !== {} ? newManager.salesmanNo : '';
      this.addForm.name = newManager !== {} ? newManager.salesmanName : '';
      this.addForm.cardId = newManager !== {} ? newManager.salesmanIdcard : '';
      this.addForm.phone = newManager !== {} ? newManager.salesmanPhone : '';
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          // 新增
          if (this.manager.id === undefined) {
            this.addCustomerManager();
          } else {
            // 修改
            this.updateCustomerManager();
          }
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      if (this.manager.id === undefined) {
        this.$refs['addForm'].resetFields();
      } else {
        this.$refs['addForm'].clearValidate();
      }
    },
    addCustomerManager() {
      addCustomerManagerRequest({
        salesmanIdcard: this.addForm.cardId,
        salesmanName: this.addForm.name,
        salesmanPhone: this.addForm.phone
      }).then(response => {
        this.$message.success('添加成功...');
        this.$emit('added');
      });
    },
    updateCustomerManager() {
      updateCustomerManagerRequest({
        id: this.manager.id,
        salesmanIdcard: this.addForm.cardId,
        salesmanName: this.addForm.name,
        salesmanPhone: this.addForm.phone
      }).then(response => {
        this.$message.success('修改成功...');
        this.$emit('added');
      });
    }
  }
};