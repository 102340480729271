var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业编号",
      prop: "status"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入企业编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "甲方单位名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入甲方单位名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantName", $$v);
      },
      expression: "searchForm.merchantName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择签约劳务公司",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxsourceList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.taxMerchantSignStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "通道类型"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择通道类型",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.channelType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "channelType", $$v);
      },
      expression: "searchForm.channelType"
    }
  }, _vm._l(_vm.$formatTypeArray("channelType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.saveMerchantSign ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e(), _vm._v(" "), _vm.downloadMerchantSign ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadMchntSignList
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "企业编号",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleMerchantView(scope.$index, scope.row);
            }
          }
        }, [_vm._v(_vm._s(scope.row.merchantNo))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "签约劳务公司编号",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "签约劳务公司",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeTotal",
      label: "累计充值(元)",
      formatter: _vm.$fmoneyWithSymbol,
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "unticketTotal",
      label: "可开票金额(元)",
      formatter: _vm.$fmoneyWithSymbol,
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "billMode",
      label: "扣费模式",
      width: "110",
      formatter: _vm.$formatTypeInTable("chargeType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolStartShow",
      label: "协议开始日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolEndShow",
      label: "协议终止日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelNo",
      label: "通道编号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "channelName",
      label: "通道名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      formatter: _vm.formatSignStatus,
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "签约协议",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.protocolUrl !== "" ? _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank"
          },
          on: {
            click: function ($event) {
              return _vm.downloadProtocolUrl(scope.row);
            }
          }
        }, [_vm._v("下载签约协议")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "230",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.updateMerchantSign ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && _vm.forzenMerchantSign ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.freeze(scope.row);
            }
          }
        }, [_vm._v("冻结")]) : _vm._e(), _vm._v(" "), scope.row.status === 2 && _vm.forzenMerchantSign ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.unfreeze(scope.row);
            }
          }
        }, [_vm._v("解冻")]) : _vm._e(), _vm._v(" "), scope.row.status === 0 && _vm.authMerchantSign ? _c("el-button", {
          staticStyle: {
            color: "green"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showAuditDialog(scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e(), _vm._v(" "), _vm.viewMerchantSign ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.updateSignChannel ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleChannelDialog(scope.row);
            }
          }
        }, [_vm._v("通道设置")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "30%",
      title: "提示",
      visible: _vm.auditDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.auditDialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    审核企业签约劳务公司信息，请确认企业签约信息及费率信息是否正确，确认审核通过请点击“通过”，\n      审核不通过请点击“取消”，不进行审核请关闭窗口。")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.audit(1);
      }
    }
  }, [_vm._v("通 过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.audit();
      }
    }
  }, [_vm._v("取 消")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "40%",
      title: "通道设置",
      visible: _vm.channelDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.channelDialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    修改企业在劳务公司下使用的通道会影响现有交易的处理，请谨慎操作。")]), _vm._v(" "), _c("el-form", {
    ref: "editChannelForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      model: _vm.editChannelForm,
      "label-width": "120px",
      rules: _vm.editChannelRules
    }
  }, [_vm.show ? _c("el-input", {
    attrs: {
      placeholder: "企业签约ID"
    },
    model: {
      value: _vm.editChannelForm.id,
      callback: function ($$v) {
        _vm.$set(_vm.editChannelForm, "id", $$v);
      },
      expression: "editChannelForm.id"
    }
  }) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "劳务公司通道",
      prop: "channelNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择劳务公司通道",
      size: "mini"
    },
    model: {
      value: _vm.editChannelForm.channelNo,
      callback: function ($$v) {
        _vm.$set(_vm.editChannelForm, "channelNo", $$v);
      },
      expression: "editChannelForm.channelNo"
    }
  }, _vm._l(_vm.taxChannelList, function (taxChannel) {
    return _c("el-option", {
      key: taxChannel.channelNo,
      attrs: {
        label: _vm.concatTaxChannel(taxChannel),
        value: taxChannel.channelNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.updateChannel();
      }
    }
  }, [_vm._v("确 定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.channelDialogVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };