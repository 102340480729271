import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 服务商企业列表
 * @param param
 */
export const getAgnetMerchantList = param => {
  return request({
    url: '/profit/merchant4Agent/mchntList4Agent',
    method: 'post',
    data: param
  });
};

/**
 * 下载服务商企业列表
 */
export const downloadAgnetMerchant = params => {
  return download({
    url: '/profit/merchant4Agent/downloadMchnt4Agent',
    data: params
  });
};
/**
 * 服务商交易记录-入账明细列表
 * @param param
 */
export const getAgnetRechargeList = param => {
  return request({
    url: '/profit/merchant4Agent/rechargeList4Agent',
    method: 'post',
    data: param
  });
};

/**
 * 服务商下载查询入账明细记录
 */
export const downloadAgnetRechargeList = params => {
  return download({
    url: '/profit/merchant4Agent/downloadRecharge4Agent',
    data: params
  });
};

/**
 * 服务商交易明细列表
 * @param param
 */
export const getAgnetTradeList = param => {
  return request({
    url: '/profit/merchant4Agent/tradeList4Agent',
    method: 'post',
    data: param
  });
};

/**
 * 服务商下载交易明细记录
 */
export const downloadAgnetTradeList = params => {
  return download({
    url: '/profit/merchant4Agent/downloadTrade4Agent',
    data: params
  });
};

/**
 * 服务商交易记录-发放明细列表
 * @param param
 */
export const getAgnetRepaymentList = param => {
  return request({
    url: '/profit/merchant4Agent/repaymentList4Agent',
    method: 'post',
    data: param
  });
};

/**
 * 服务商下载查询发放明细记录
 */
export const downloadAgnetRepaymentList = params => {
  return download({
    url: '/profit/merchant4Agent/downloadRepayment4Agent',
    data: params
  });
};

/**
 * 分润业绩记录表
 * @param param
 */
export const getAgnetRecordList = param => {
  return request({
    url: '/profit/agentProfitRecord/list',
    method: 'post',
    data: param
  });
};

/**
 * 下载分润业绩记录表
 */
export const downloadAgnetRecordList = params => {
  return download({
    url: '/profit/agentProfitRecord/download',
    data: params
  });
};

/**
 * 分润业绩记录详情
 * @param param
 */
export const getProfitRecordInfo = id => {
  return request({
    url: '/profit/agentProfitRecord/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 查看发票抬头
 * @param param
 */
export const getInvoiceTitle = () => {
  return request({
    url: '/profit/agentProfitRecord/invoiceTitle',
    method: 'post'
  });
};

/**
 * 分润业绩-确认
 * @param param
 */
export const confirmRecord = param => {
  return request({
    url: '/profit/agentProfitRecord/confrim',
    method: 'post',
    data: param
  });
};

/**
 * 分润业绩-复核
 * @param param
 */
export const adjustRecord = param => {
  return request({
    url: '/profit/agentProfitRecord/adjust',
    method: 'post',
    data: param
  });
};

/**
 * 分润业绩-单个结算
 * @param param
 */
export const singleSettleRecord = id => {
  return request({
    url: '/profit/agentProfitRecord/singleSettle',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 分润业绩-批量结算
 * @param param
 */
export const batchSettleRecord = param => {
  return request({
    url: '/profit/agentProfitRecord/batchSettle',
    method: 'post',
    data: param
  });
};

/**
 * 分润业绩-付款列表
 * @param param
 */
export const getWithdrawPayList = param => {
  return request({
    url: '/profit/agentProfitRecord/withdrawPayList',
    method: 'post',
    data: param
  });
};

/**
 * 下载分润业绩-付款列表
 * @param param
 */
export const downloadWithdrawPayList = param => {
  return download({
    url: '/profit/agentProfitRecord/downloadWithdrawPay',
    data: param
  });
};