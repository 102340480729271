var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "代付平台参数"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代付商户编号",
      prop: "appId"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入代付商户编号"
    },
    model: {
      value: _vm.saveForm.appId,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "appId", $$v);
      },
      expression: "saveForm.appId"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道单日申请限额",
      prop: "quotaMax"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入代付单日限额"
    },
    model: {
      value: _vm.saveForm.quotaMax,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "quotaMax", $$v);
      },
      expression: "saveForm.quotaMax"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代付商户签名密钥",
      prop: "appKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      placeholder: "请输入代付签名密钥"
    },
    model: {
      value: _vm.saveForm.appKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "appKey", $$v);
      },
      expression: "saveForm.appKey"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代付商户SM2私钥",
      prop: "privateKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      placeholder: "请输入代付商户私钥"
    },
    model: {
      value: _vm.saveForm.privateKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "privateKey", $$v);
      },
      expression: "saveForm.privateKey"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代付平台SM2公钥",
      prop: "publicKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      placeholder: "请输入代付平台公钥"
    },
    model: {
      value: _vm.saveForm.publicKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "publicKey", $$v);
      },
      expression: "saveForm.publicKey"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "银企直联参数"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银企直联用户名",
      prop: "ncUserId"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入银企直联用户名"
    },
    model: {
      value: _vm.saveForm.ncUserId,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "ncUserId", $$v);
      },
      expression: "saveForm.ncUserId"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银企直联密码",
      prop: "ncUserPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      clearable: "",
      placeholder: "请输入银企直联密码"
    },
    model: {
      value: _vm.saveForm.ncUserPwd,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "ncUserPwd", $$v);
      },
      expression: "saveForm.ncUserPwd"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "前置服务访问地址"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入前置访问地址"
    },
    model: {
      value: _vm.saveForm.ncPreposeIp,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "ncPreposeIp", $$v);
      },
      expression: "saveForm.ncPreposeIp"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "其他设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支持充值入单",
      prop: "rechargeAuto"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.rechargeAuto,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeAuto", $$v);
      },
      expression: "saveForm.rechargeAuto"
    }
  }, _vm._l(_vm.rechargeAutoStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "status", $$v);
      },
      expression: "saveForm.status"
    }
  }, _vm._l(_vm.taxStatusType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px",
      "margin-left": "-140px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.checkSaveForm
    }
  }, [_vm._v("保存")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };