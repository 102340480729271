import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { rechargeRecordedListReq, downloadRecordedRecords } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'accountRecordedIndex',
  components: {
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        customerNo: '',
        orderNo: '',
        recordType: '',
        date: []
      },
      currentSearch: {
        customerNo: '',
        orderNo: '',
        recordType: '',
        date: []
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      transactions: [],
      orderNo: ''
    };
  },
  watch: {
    orderNoChild(val) {
      if (val) {
        this.searchForm.orderNo = this.currentSearch.orderNo = this.orderNoChild = this.orderNo = val;
      }
      this.getList();
    }
  },
  props: {
    orderNoChild: '',
    isChild: false
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    // console.log('create orderNo' + this.orderNo)
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      // this.$refs['searchForm'].resetFields()
      this.searchForm = {
        customerNo: '',
        orderNo: '',
        recordType: '',
        date: []
      };
      this.queryForm();
    },
    downloadForm() {
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const params = {
        custNo: this.currentSearch.customerNo,
        bussincessNo: this.currentSearch.orderNo,
        accountType: this.currentSearch.recordType,
        beginTime: beginTime,
        endTime: endTime
      };
      downloadRecordedRecords(params).then(response => {
        downloadExcel(response.data, 'recharge-recorded-records.xls');
      });
    },
    getListByChild(orderNo) {
      this.orderNo = orderNo;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (this.isChild) {
        if (this.orderNo === '') {
          return;
        }
        this.searchForm.orderNo = this.currentSearch.orderNo = this.orderNo;
      }
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      rechargeRecordedListReq({
        custNo: this.currentSearch.customerNo,
        bussincessNo: this.currentSearch.orderNo,
        accountType: this.currentSearch.recordType,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};