import { mapGetters } from 'vuex';
import { getMenuTree } from '@/api/sys';
export default {
  name: 'leftForm',
  components: {},
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      checkedKeys: null,
      id: 0,
      name: '',
      addMenuVisible: false,
      addMenuPath: ''
    };
  },
  created() {
    this.handleMenuTree();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addMenuPath = map.get('addMenu');
      this.addMenuVisible = map.has('addMenu');
    } else {
      this.addMenuVisible = false;
    }
    this.$nextTick(function () {
      this.$refs.tree.setCurrentKey(localStorage.getItem('menuParentId'));
    });
  },
  mounted() {},
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  props: [],
  methods: {
    handleNodeClick(data) {
      this.id = data.id;
      this.name = data.label;
      this.$emit('changeTreeData', data);
    },
    handleMenuTree() {
      getMenuTree().then(res => {
        this.treeData = res.data;
      });
    },
    handleAdd() {
      if (this.id == null) {
        this.$message({
          message: '请选择菜单',
          type: 'warning'
        });
      } else {
        const value = encodeURI(encodeURI(this.name));
        this.$router.push({
          path: this.addMenuPath,
          query: {
            id: this.id,
            name: value
          }
        });
      }
    }
  }
};