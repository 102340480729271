import request from '@/utils/request';

/**
 * 新增客户经理
 */
export const addCustomerManagerRequest = params => {
  return request({
    url: '/org/salesman/save',
    method: 'post',
    data: params
  });
};

/**
 * 更新客户经理
 */
export const updateCustomerManagerRequest = params => {
  return request({
    url: '/org/salesman/update',
    method: 'post',
    data: params
  });
};

/**
 * 恢复客户经理
 */
export const registerCustomerManagerRequest = params => {
  return request({
    url: '/org/salesman/renew',
    method: 'post',
    data: params
  });
};

/**
 * 注销客户经理
 */
export const deregisterCustomerManagerRequest = params => {
  return request({
    url: '/org/salesman/cancel',
    method: 'post',
    data: params
  });
};

/**
 * 获取客户经理列表
 */
export const getCustomerManagers = params => {
  return request({
    url: '/org/salesman/list',
    method: 'post',
    data: params
  });
};

/**
 * 客户经理离职办理
 */
export const customerManagerDimission = params => {
  return request({
    url: '/org/salesman/leaveoff',
    method: 'post',
    data: params
  });
};

/**
 * 客户经理离职办理时，获取下拉列表信息
 */
export const getDimissionCustomerManagers = params => {
  return request({
    url: '/org/salesman/showlist',
    method: 'post',
    data: params
  });
};