import { clickRead } from '../../../api/sys';
import { MESSAGE_CENTER_INFO_ID } from '../../../utils/idName';
import { decode } from 'js-base64';
export default {
  name: 'noticeDialog',
  data() {
    return {
      myDialogFormVisible: false,
      myFileName: '',
      saveKey: ''
    };
  },
  props: {
    dialogFormVisible: false,
    item: {
      require: true
    }
  },
  created() {},
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleNoticeDialog', val);
    }
  },
  methods: {
    dialogClosed() {
      this.setRead();
    },
    close() {
      this.myDialogFormVisible = false;
      this.myFileName = '';
      this.saveKey = '';
    },
    setRead() {
      clickRead(this.item.id).then(() => {
        this.$store.dispatch('GetNoReadNum');
      });
    },
    gotoInfo() {
      this.setRead();
      if (this.item.openType === 1) {
        window.open(this.item.contents);
      } else {
        sessionStorage.setItem(MESSAGE_CENTER_INFO_ID, this.item.id);
        this.$router.push({
          path: '/msgCenter/msgCenterList/msgCenterInfo'
        });
      }
    },
    decodeContents(content) {
      return decode(content);
    }
  }
};