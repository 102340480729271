import TransactionApply from '../../apply/apply';
export default {
  name: 'businessesApplyContent',
  components: {
    TransactionApply
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.child.getTaxSources();
    });
  },
  data() {
    return {
      repayType: 1
    };
  }
};