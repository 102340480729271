export default {
  name: 'merchantView',
  // data() {
  //   return {
  //     list: [{
  //       aa: '11',
  //       bb: '22',
  //       cc: '33'
  //     },
  //     {
  //       aa: '11',
  //       bb: '22',
  //       cc: '33'
  //     }]
  //   }
  // },
  props: {
    list: {
      required: true
    }
  },
  methods: {}
};