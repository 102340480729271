import { getStatMerchantGradeCharts } from '../../../../api/stat';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { getLast3Month } from '../../../../utils/util';
import DbsHistogram from '../../../../components/chart/dbs-histogram';
export default {
  name: 'tadGradeChart',
  components: {
    DbsHistogram,
    ItemTitle
  },
  data() {
    const chartSettings = {
      itemStyle: {
        color: '#2DA8FF'
      },
      labelMap: {
        'total': '总数'
      },
      legendName: {
        'total': '总数'
      }
    };
    return {
      chartData: {
        columns: ['gradeName', 'total'],
        rows: []
      },
      chartSettings: chartSettings,
      searchForm: {
        merchantName: '',
        merchantNo: '',
        taxNo: '',
        isNewMchnt: ''
      },
      taxSources: [],
      dateTime: []
    };
  },
  created() {
    this.getTaxSources();
    this.getList();
    const dates = getLast3Month();
    this.dateTime = [dates.last, dates.now];
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList() {
      getStatMerchantGradeCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    }
  }
};