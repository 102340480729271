var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "人员签约记录："
    }
  })], 1), _vm._v(" "), _c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_vm.empNo ? _c("el-form-item", {
    attrs: {
      label: "人员编号："
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入人员编号",
      clearable: "",
      disabled: ""
    },
    model: {
      value: _vm.empNo,
      callback: function ($$v) {
        _vm.empNo = $$v;
      },
      expression: "empNo"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "关键字："
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      clearable: "",
      placeholder: "请输入姓名/手机号/身份证号/银行卡号"
    },
    model: {
      value: _vm.searchForm.keyWords,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "keyWords", $$v);
      },
      expression: "searchForm.keyWords"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxsList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约状态："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择签约状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.empSignStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "remark", $$v);
      },
      expression: "searchForm.remark"
    }
  }, _vm._l(_vm.$formatTypeArray("isHasNo"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.downloadVisible ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empName",
      label: "姓名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "签约账号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "reservePhone",
      label: "手机号码",
      width: "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolNo",
      label: "签约编号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolStartShow",
      label: "开始日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolEndShow",
      label: "终止日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "签约状态",
      formatter: _vm.formatStatus,
      width: "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "min-width": "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "80",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === 1 && _vm.cancelEmpSign ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCancelEmp(scope.row);
            }
          }
        }, [_vm._v("解约")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };