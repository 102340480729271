import NxCountUp from '../../../../components/nx-count-up/index';
export default {
  name: 'totalDataItem',
  components: {
    NxCountUp
  },
  props: {
    imgIcon: {
      required: true
    },
    name: {
      required: true
    },
    num: {
      required: true
    },
    unit: {
      default: () => {
        return '个';
      }
    }
  }
};