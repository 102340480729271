import request from '@/utils/request';
import axios from 'axios';
import { getAuthSession } from '@/utils/auth';

/**
 * 查询园区路由池列表
 * @param param
 */
export const getTaxsourceRouterList = params => {
  const {
    taxName,
    taxNo,
    status,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/tax/taxsourceRouter/list',
    method: 'post',
    data: {
      taxName,
      taxNo,
      status,
      pageNo,
      pageSize
    }
  });
};

/**
 * 新增园区路由池
 * @param param
 */
export const addTaxsourceRouter = params => {
  const {
    seqNumber,
    taxNo
  } = params;
  return request({
    url: '/tax/taxsourceRouter/save',
    method: 'post',
    data: {
      seqNumber,
      taxNo
    }
  });
};

/**
 * 园区路由池启用
 * @param param
 */
export const updateNormalTaxRouter = id => {
  return request({
    url: '/tax/taxsourceRouter/updateNormal',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 园区路由池停用
 * @param param
 */
export const updateStopTaxRouter = id => {
  return request({
    url: '/tax/taxsourceRouter/updateStop',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 * 园区路由池顺序修改
 * @param param
 */
export const updateSeqNumTaxRouter = params => {
  const {
    id,
    seqNumber
  } = params;
  return request({
    url: '/tax/taxsourceRouter/updateSeqNum',
    method: 'post',
    data: {
      id,
      seqNumber
    }
  });
};

/**
 * 查询园区路由转账记录列表
 * @param param
 */
export const getTaxRouterRechargeList = params => {
  const {
    beginTime,
    endTime,
    bussincessNo,
    payTaxNo,
    rechargeStatus,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/tax/taxsourceRouterRecharge/list',
    method: 'post',
    data: {
      beginTime,
      endTime,
      bussincessNo,
      payTaxNo,
      rechargeStatus,
      pageNo,
      pageSize
    }
  });
};

/**
 *下载签约列表信息
 */
export async function downloadTaxRouterRecharge(params) {
  let res;
  await axios({
    url: process.env.BASE_API + '/tax/taxsourceRouterRecharge/download',
    method: 'post',
    headers: {
      'X-AUTH-SESSION': getAuthSession()
    },
    data: params,
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/**
 * 查询园区路由转账记录表详情
 * @param param
 */
export const getTaxRouterRechargeInfo = id => {
  return request({
    url: '/tax/taxsourceRouterRecharge/info',
    method: 'post',
    data: {
      id
    }
  });
};