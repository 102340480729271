var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticClass: "border-bg",
    staticStyle: {
      "padding-bottom": "30px"
    }
  }, [!_vm.isShowWarnSet && !_vm.queryLoading && (_vm.merchantInfo === null || _vm.merchantInfo.id === undefined) ? _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "merchant-info-text"
  }, [_vm._v("\n      您尚未开通企业预警，如需开通，请点击“立即开通”，并根据页面提示配置相关预警信息。")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.isShowWarnSet = true;
      }
    }
  }, [_vm._v("立即开通")])], 1) : _vm._e(), _vm._v(" "), _vm.isShowWarnSet ? _c("warn-set", {
    attrs: {
      merchantInfo: _vm.merchantInfo
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };