import { saveMenu, getMenuTree, getApiList } from '@/api/sys';
export default {
  name: 'addMenu',
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 编辑界面数据
      editForm: {
        id: '',
        name: '',
        parentId: '',
        type: 1,
        orderNum: 1,
        icon: '',
        parentName: '',
        apiIdList: [],
        component: '',
        redirect: '',
        title: '',
        hidden: false,
        url: ''
      },
      isContinue: 0,
      addRules: {
        title: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '菜单名称长度有误',
          trigger: 'blur'
        }],
        component: [{
          required: true,
          message: '请输入VUE组件名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 100,
          message: 'vue组件名称长度有误',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入路由Name',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '路由Name长度有误',
          trigger: 'blur'
        }],
        url: [{
          type: 'string',
          min: 1,
          max: 50,
          message: '路由Path长度有误',
          trigger: 'blur'
        }],
        redirect: [{
          type: 'string',
          min: 1,
          max: 50,
          message: 'VUE重定向长度有误',
          trigger: 'blur'
        }],
        icon: [{
          type: 'string',
          min: 1,
          max: 50,
          message: '图标长度有误',
          trigger: 'blur'
        }],
        orderNum: [{
          required: true,
          message: '请输入排序编号',
          trigger: 'blur'
        }]
      },
      isTrue: true,
      dialogVisible: false,
      apiArr: [],
      allApiList: [],
      loading: false,
      menuTypeArr: this.$formatTypeArray('menuType'),
      isHiddenArr: this.$formatTypeArray('isHidden')
    };
  },
  created() {
    this.getMenuInfo();
  },
  methods: {
    getMenuInfo() {
      // 获取用户列表
      const id = this.$route.query.id;
      const name = decodeURI(decodeURI(this.$route.query.name));
      this.editForm.parentId = id;
      this.editForm.parentName = name;
      const query = {
        pageSize: 10000,
        pageNo: 1,
        name: '',
        status: 1
      };
      getApiList(query).then(response => {
        this.allApiList = response.data.list;
        this.apiArr = this.allApiList;
      });
    },
    initApiList() {
      this.apiArr = this.allApiList;
    },
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          saveMenu(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            if (this.isContinue === 0) {
              this.goBack();
            } else {
              const id = this.$route.query.id;
              const name = decodeURI(decodeURI(this.$route.query.name));
              this.editForm = {
                parentId: id,
                parentName: name,
                id: '',
                name: '',
                type: 1,
                orderNum: 1,
                icon: '',
                apiIdList: [],
                component: '',
                redirect: '',
                title: '',
                hidden: false,
                url: ''
              };
            }
          });
        }
      });
    },
    handleSelectTree() {
      this.dialogVisible = true;
      this.handleMenuTree();
    },
    handleChangeMenu() {
      this.dialogVisible = false;
    },
    handleNodeClick(data) {
      if (data) {
        this.editForm.parentId = data.id;
        this.editForm.parentName = data.label;
      }
    },
    handleMenuTree() {
      getMenuTree().then(res => {
        this.treeData = res.data;
      });
    },
    changeValue() {
      this.remoteMethod('');
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.apiArr = this.allApiList.filter(item => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.apiArr = this.allApiList;
      }
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};