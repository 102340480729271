import ItemTitle from '@/base/ui/title/itemTitle';
import { deleteWtdzdjReg, showlist } from '../../../../api/taxsource';
export default {
  name: 'deleteWtdzdjDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxList: [],
      deleteForm: {
        taxNo: '',
        djMonth: ''
      },
      myDialogFormVisible: false,
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }],
        djMonth: [{
          required: true,
          message: '请选择申报月份',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      if (val) {
        this.getTaxsList();
      }
      this.$emit('changeDeleteDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    submitUpload() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          deleteWtdzdjReg({
            taxNo: this.deleteForm.taxNo,
            djMonth: this.deleteForm.djMonth
          }).then(() => {
            this.$message.success('操作成功。');
            this.myDialogFormVisible = false;
          }).catch(erros => {
            this.$message.error(erros.errMsg);
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxList = response.data;
      });
    }
  }
};