import request from '@/utils/request';

/**
 * 获取列表信息
 * @param param
 */
export const teamLeaderApplyList = param => {
  return request({
    url: '/empTeam/teamLeaderApply/list',
    method: 'post',
    data: param
  });
};

/**
 *获取详细信息
 * @param param
 */
export const teamLeaderApplyInfo = params => {
  return request({
    url: '/empTeam/teamLeaderApply/info',
    method: 'post',
    data: params
  });
};

/**
 *
审核申请
 * @param param
 */
export const teamLeaderApplyUpdate = params => {
  return request({
    url: '/empTeam/teamLeaderApply/update',
    method: 'post',
    data: params
  });
};

/**
 * 班组长信息详情列表
 * @param param
 */
export const teamInfoDetailList = param => {
  return request({
    url: '/empTeam/teamInfoDetail/list',
    method: 'post',
    data: param
  });
};

/**
 * 获取详细信息
 * @param param
 */
export const teamInfoDetailInfo = param => {
  return request({
    url: '/empTeam/teamInfoDetail/info',
    method: 'post',
    data: param
  });
};

/**
 * 获取详细信息
 * @param param
 */
export const teamInfoDetailUpdate = param => {
  return request({
    url: '/empTeam/teamInfoDetail/update',
    method: 'post',
    data: param
  });
};

/**
 * 查询劳务公司下拉列表 - 标准接口(按登录角色获取下拉劳务公司)
 * @param param
 */
export const taxsourceShowlist = param => {
  return request({
    url: '/tax/taxsource/showlist',
    method: 'get',
    data: param
  });
};

/**
 * 可签约项目下拉列表
 * @param param
 */
export const mchntProjectSignProjectList = param => {
  return request({
    url: '/mchntProject/signProjectList ',
    method: 'post',
    data: param
  });
};

/**
 * 新增申请班组签约提交接口
 * @param param
 */
export const empTeamSignedSave = param => {
  return request({
    url: '/empTeam/signed/save',
    method: 'post',
    data: param
  });
};