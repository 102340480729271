import Vue from 'vue';
import App from './App';
import VCharts from 'v-charts';
import 'default-passive-events';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import '../static/css/iconfont/1.0.0/index.css';
import '../static/theme/index.css';
import 'blob-polyfill';

/* icofont*/

// css
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import '@/styles/index.scss'; // global css

// import './mock' // simulation data
// font-awesome
import '@/assets/library/font-awesome-4.7.0/css/font-awesome.min.css';
import '@/assets/library/iconfont/css/iconfont.css';
import store from './store';
import { loadStyle } from './utils/util';
import { iconfontUrl, iconfontVersion } from '@/config/env';
import '@/icons'; // icon

import '@/permission'; // permission control

import * as filters from './filters'; // global filters
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.use(ElementUI, {
  locale
});
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele));
});
Vue.use(ElementUI, {
  size: 'medium' // set element-ui default size
});
Vue.use(VCharts);

// 全局函数
import * as global from '@/prototype';
Object.keys(global).forEach(key => {
  Vue.prototype['$' + key] = global[key];
});
Vue.config.productionTip = false;
import router from './router';
new Vue({
  el: '#app',
  router,
  store,
  // template: '<App/>',
  // components: { App }
  render: h => h(App)
});