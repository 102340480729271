import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import { mapGetters } from 'vuex';
import { memberList, downloadMember } from '@/api/members';
import { downloadExcel } from '@/utils/util';
import { downloadAllFile } from '@/utils/util';
import { downloadFile } from '@/api/upload';
export default {
  name: 'MembersList',
  components: {
    ItemTitle,
    Pagination
  },
  data() {
    return {
      loading: false,
      download: '',
      downloadAgreementBtn: '',
      searchForm: {
        "keyWords": "",
        "teamName": "",
        "teamNo": "",
        "monitorName": "",
        "monitorMobile": "",
        "monitorIdcard": ""
      },
      list: [],
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.download = map.get('download');
      this.downloadAgreementBtn = map.get('downloadAgreement');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryForm();
    });
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    queryForm() {
      this.currentSearch = {
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = this.$options.data().searchForm;
      this.queryForm();
    },
    async getList() {
      this.loading = true;
      const {
        keyWords,
        teamName,
        teamNo,
        monitorName,
        monitorMobile,
        monitorIdcard
      } = this.searchForm;
      const {
        pageNo,
        pageSize
      } = this.currentSearch;
      const params = {
        keyWords,
        teamName,
        teamNo,
        monitorName,
        monitorMobile,
        monitorIdcard,
        pageNo,
        pageSize
      };
      const res = await memberList(params);
      this.list = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    },
    downloadAgreement(index, row) {
      // todo:下载协议 contractUrl
      downloadFile(row.contractUrl).then(response => {
        downloadAllFile(response.data, row.contractName);
      });
    },
    downloadForm() {
      // todo：调用导出接口
      downloadMember(this.searchForm).then(response => {
        downloadExcel(response.data, '班组成员列表.xls');
      });
    }
  }
};