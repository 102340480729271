var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("merchant-emp-sign-list", {
    ref: "child",
    attrs: {
      empNoChild: _vm.empNo,
      isChild: true
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };