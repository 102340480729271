import { getDictGroupList, addDictGroup, updateDictGroup } from '@/api/dict';
import { isRegexnNoLen } from '@/utils/validate_rules';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { DICTDETAIL_ID } from '@/utils/idName';
export default {
  name: 'dictIndex',
  components: {
    Pagination,
    ItemTitle
  },
  data() {
    return {
      show: false,
      dialogDictStatus: '',
      textMap: {
        update: '修改字典',
        create: '新增字典'
      },
      dialogDictVisible: false,
      // dialog编辑界面数据
      dialogEditDictForm: {
        id: null,
        sysCode: null,
        dictGroupCode: null,
        dictGroupName: null
      },
      editDictFormRules: {
        sysCode: [{
          required: true,
          message: '请输入系统ID',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 2,
          max: 32,
          message: '系统ID要求2-32位',
          trigger: 'blur'
        }],
        dictGroupCode: [{
          required: true,
          message: '请输入字典Code',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 2,
          max: 64,
          message: '字典Code要求2-64位',
          trigger: 'blur'
        }, {
          validator: isRegexnNoLen,
          trigger: 'blur'
        }],
        dictGroupName: [{
          required: true,
          message: '请输入字典名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 2,
          max: 128,
          message: '字典名称要求2-128位',
          trigger: 'blur'
        }]
      },
      searchForm: {
        sysCode: '',
        dictGroupCode: '',
        dictGroupName: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      listLoading: true,
      dictGroupList: [],
      total: 0,
      addDictPath: '',
      updateDictPath: '',
      viewDictDetailPath: '',
      addDictVisible: false,
      updateDictVisible: false,
      viewDictDetailVisible: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewDictDetailPath = map.get('viewDictDetail');
      this.viewDictDetailVisible = map.get('viewDictDetail');
      this.updateDictPath = map.get('updateDict');
      this.updateDictVisible = map.has('updateDict');
      this.addDictPath = map.get('addDict');
      this.addDictVisible = map.has('addDict');
    } else {
      this.addDictVisible = false;
      this.updateDictVisible = false;
      this.viewDictDetailVisible = false;
    }
  },
  methods: {
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageNo = this.pageNo;
      this.currentSearch.pageSize = this.pageSize;
      getDictGroupList(this.currentSearch).then(response => {
        this.dictGroupList = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearCondition() {
      this.searchForm = {
        sysCode: '',
        dictGroupCode: '',
        dictGroupName: ''
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.dialogDictStatus = 'update';
      this.dialogDictVisible = true;
      this.dialogEditDictForm = Object.assign({}, row);
    },
    // 显示新增界面
    handleAdd() {
      this.dialogDictStatus = 'create';
      this.dialogDictVisible = true;
      this.dialogEditDictForm = {
        id: null,
        sysCode: null,
        dictGroupCode: null,
        dictGroupName: null
      };
    },
    // 查看字典明细
    handleViewDetail(index, row) {
      // this.$router.push({ path: '/sys/viewDictDetail', query: { id: row.id }})
      sessionStorage.setItem(DICTDETAIL_ID, row.id);
      this.$router.push({
        path: this.viewDictDetailPath
      });
    },
    // 新增
    addDictGroup() {
      this.$refs.dialogEditDictForm.validate(valid => {
        if (valid) {
          this.$confirm('确认添加该字典项吗？', '提示', {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            const para = Object.assign({}, this.dialogEditDictForm);
            addDictGroup(para).then(res => {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$refs['dialogEditDictForm'].resetFields();
              this.dialogDictVisible = false;
              this.getList();
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    // 编辑
    updateDictGroup() {
      this.$refs.dialogEditDictForm.validate(valid => {
        if (valid) {
          this.$confirm('确认更新该字典项吗？', '提示', {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            const para = Object.assign({}, this.dialogEditDictForm);
            updateDictGroup(para).then(res => {
              this.$message({
                message: '更新成功',
                type: 'success'
              });
              this.$refs['dialogEditDictForm'].resetFields();
              this.dialogDictVisible = false;
              this.getList();
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    }
  }
};