import ItemTitle from '@/base/ui/title/itemTitle';
import { taxChannelInfo } from '@/api/taxChannel';
import { VIEW_TAXSOURCE_CHANNEL_ID } from '@/utils/idName';
import ViewProductByZfb from './viewProductByZFB';
import ViewProductByCcb from './viewProductByCCB';
import ViewProductByBjb from './viewProductByBJB';
import ViewProductByMyb from './viewProductByMYB';
import ViewProductByCcbzx from './viewProductByCCBZX';
import ViewProductByWeiXin from './viewProductByWEIXIN';
import ViewProductByYeepay from './viewProductByYeepay';
export default {
  components: {
    ViewProductByWeiXin,
    ViewProductByCcbzx,
    ViewProductByMyb,
    ViewProductByBjb,
    ViewProductByCcb,
    ViewProductByZfb,
    ItemTitle,
    ViewProductByYeepay
  },
  created() {
    const id = sessionStorage.getItem(VIEW_TAXSOURCE_CHANNEL_ID);
    taxChannelInfo(id).then(response => {
      this.saveForm = response.data;
    });
  },
  data() {
    return {
      saveForm: {}
    };
  },
  methods: {
    formatChannelType(channelType) {
      return this.$formatType('channelType', channelType);
    },
    formatStatus(status) {
      return this.$formatType('taxStatusType', status);
    },
    formatIsSupport(rechargeAuto) {
      return this.$formatType('isSupport', rechargeAuto);
    }
  }
};