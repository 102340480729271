var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    staticStyle: {
      width: "90%"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "标准充值费率",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s((scope.row.rechargeRate * 100).toFixed(2)) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(scope.$index);
            }
          }
        }, [_vm._v("修改")]), _vm._v(" "), scope.row.rateFlag ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index);
            }
          }
        }, [_vm._v("查看")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("update-agent-tax-dialog", {
    attrs: {
      dialogFormVisible: _vm.updateAgentTaxDialogVisible,
      item: _vm.viewDialogItem,
      agentNo: _vm.agentNo
    },
    on: {
      changeVisible: _vm.changeVisible
    }
  }), _vm._v(" "), _c("view-agent-tax-dialog", {
    attrs: {
      dialogFormVisible: _vm.viewAgentTaxDialogVisible,
      item: _vm.viewDialogItem
    },
    on: {
      changeVisible: _vm.changeViewVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };