import BatchReviewCommon from '../../components/batchReviewCommon';
export default {
  components: {
    BatchReviewCommon
  },
  name: 'businessesBatchContent',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  data() {
    return {
      repayType: 1
    };
  },
  methods: {
    getList() {
      this.$refs.child.getList();
    }
  }
};