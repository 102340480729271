import { mapGetters } from 'vuex';
import nxBreadcrumb from '@/components/nx-breadcrumb';
import nxHamburger from '@/components/nx-hamburger';
import nxHelp from '@/components/nx-help/index';
import nxLangSelect from '@/components/nx-lang-select/index';
import nxGithub from '@/components/nx-github/index';
import { getAcountInfo } from '@/utils/auth';
import ElImage from 'element-ui/packages/image/src/main';
export default {
  name: 'navBar',
  components: {
    ElImage,
    nxBreadcrumb,
    nxHamburger,
    nxHelp,
    nxLangSelect,
    nxGithub
  },
  computed: {
    ...mapGetters(['sidebar', 'name', 'avatar', 'noReadNum'])
  },
  mounted() {
    if (this.isShowMsgCenter()) {
      this.getNoReadNum();
    }
  },
  data() {
    return {
      userName: getAcountInfo().username,
      realName: getAcountInfo().realname,
      partyType: getAcountInfo().partyType
    };
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar');
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        // this.$router.push('/login')
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
    getNoReadNum() {
      this.$store.dispatch('GetNoReadNum');
    },
    gotoMsgCenter() {
      this.$router.push({
        path: '/msgCenter/msgCenterList'
      });
    },
    isShowMsgCenter() {
      return this.partyType === 3 || this.partyType === 2;
    }
  }
};