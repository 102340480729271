var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "账户信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账户名称"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.baseForm.agentName) + "\n")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账户类型"
    }
  }, [_vm.baseForm.agentType === 1 ? _c("div", [_vm._v("对公")]) : _vm._e(), _vm._v(" "), _vm.baseForm.agentType === 0 ? _c("div", [_vm._v("对私")]) : _vm._e()])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行账户",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入结算银行账户"
    },
    model: {
      value: _vm.saveForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "accountNo", $$v);
      },
      expression: "saveForm.accountNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户银行",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开户银行名称(如：北京银行)"
    },
    model: {
      value: _vm.saveForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "bankName", $$v);
      },
      expression: "saveForm.bankName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "预留手机号",
      prop: "mobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入银行预留手机"
    },
    model: {
      value: _vm.saveForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "mobile", $$v);
      },
      expression: "saveForm.mobile"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户城市",
      prop: "cityCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      placeholder: "请选择开户城市"
    },
    model: {
      value: _vm.saveForm.cityCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "cityCode", $$v);
      },
      expression: "saveForm.cityCode"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };