import ItemTitle from '../../../base/ui/title/itemTitle';
import UploadMoreFile from '../../../base/ui/upload/uploadMoreFile';
import { batchSaveRecordAnnexs, invoiceSelectListReg } from '../../../api/task';
import { getAcountInfo } from '../../../utils/auth';
import { merchantShowList } from '../../../api/merchant';
import { saveFile } from '@/api/upload';
import { isZip } from '../../../utils/util';
export default {
  name: 'batchFiles',
  components: {
    UploadMoreFile,
    ItemTitle
  },
  data() {
    return {
      saveFile: saveFile,
      partyCode: '',
      partyType: '',
      fileList: [],
      taskList: [],
      invoiceList: [],
      taxSources: [],
      merchantList: [],
      addForm: {
        merchantNo: '',
        invoiceSerialNo: '',
        saveKey: ''
      },
      merchantName: '',
      rules: {
        merchantNo: [{
          required: true,
          message: '请选择所属商户',
          trigger: 'change'
        }],
        invoiceSerialNo: [{
          required: true,
          message: '请选择开票订单',
          trigger: 'change'
        }]
      }
    };
  },
  props: {
    dialogVisible: false
  },
  watch: {
    dialogVisible(val) {
      this.fileList = [];
      this.$refs['addForm'].resetFields();
    }
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    } else {
      this.addForm.merchantNo = user.partyCode;
      this.merchantName = user.realname;
      this.invoiceSelectList();
    }
  },
  methods: {
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选取提交文件。');
        return;
      }
      this.$confirm('是否确定提交？', '温馨提示', {
        type: 'plain',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs['addForm'].validate(valid => {
          if (valid) {
            batchSaveRecordAnnexs({
              saveKey: this.addForm.saveKey,
              merchantNo: this.addForm.merchantNo,
              invoiceSerialNo: this.addForm.invoiceSerialNo
            }).then(() => {
              this.submitSuccess();
            });
          } else {
            return false;
          }
        });
      }).catch(() => {});
    },
    submitSuccess() {
      this.$emit('dialogClosed');
      this.$alert('  附件文件已上传成功，系统后台正在解析，您可前往附件提交记录页面查看解析结果，请确保文件全部解析成功！', '上传结果', {
        type: 'success',
        confirmButtonText: '前往附件提交记录',
        closeOnClickModal: false
      }).then(() => {
        this.$router.push({
          path: '/task/annex'
        });
      });
    },
    beforeDemoUpload(file) {
      const isZIP2 = isZip(file);
      const isLt300M = file.size / 1024 / 1024 < 300;
      console.log('========file.type:' + file.type);
      if (!isZIP2) {
        this.$message.error('上传文件只能是 ZIP格式!');
      }
      if (!isLt300M) {
        this.$message.error('上传文件大小不能超过 300MB!');
      }
      return isZIP2 && isLt300M;
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件，本次选择了1个文件。');
    },
    handleRemove() {
      this.fileList = [];
      this.addForm.saveKey = '';
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 'BASE.000') {
        this.addForm.saveKey = response.data.saveKey;
      } else {
        this.$message.error(`${file.name}上传失败`);
      }
    },
    goBack() {
      this.$emit('dialogClosed');
    },
    invoiceSelectList() {
      if (this.addForm.merchantNo) {
        invoiceSelectListReg({
          merchantNo: this.addForm.merchantNo
        }).then(response => {
          this.invoiceList = response.data;
        });
      }
    }
  }
};