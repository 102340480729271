import { customerManagerDimission, getDimissionCustomerManagers } from '@/api/customerManager';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'c-manager-dimission',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    manager: {}
  },
  data() {
    return {
      form: {
        id: ''
      },
      managers: null,
      rules: {
        id: [{
          required: true,
          message: '请选择交接客户经理',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  watch: {
    isVisible: function (newValue, oldValue) {
      if (newValue === true) {
        this.getCustomerManagers();
      }
    }
  },
  methods: {
    concatManger(manager) {
      return `${manager.salesmanName}(${manager.salesmanPhone})`;
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.dimission();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      this.$refs['form'].resetFields();
    },
    dimission() {
      customerManagerDimission({
        leaveoffId: this.manager.id,
        handoverId: this.form.id
      }).then(response => {
        this.$message.success(this.manager.salesmanName + '已离职');
        this.$emit('updated');
      });
    },
    getCustomerManagers() {
      getDimissionCustomerManagers({
        agentNo: null
      }).then(response => {
        const managers = response.data.filter(manager => manager.id !== this.manager.id);
        this.managers = managers;
      });
    }
  }
};