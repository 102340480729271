import { showlist } from '../../../api/taxsource';
import CancelButton from '../../../base/ui/button/cancelButton';
import { sysMessageAdd } from '../../../api/sys';
import { VueEditor } from 'vue2-editor';
import { fullToolbar } from '@/utils/editor_toolbar';
import { validMobile } from '@/utils/validate';
import { uploadPublicFileReq } from '@/api/upload';
import { getAcountInfo } from '@/utils/auth';
import { encode } from 'js-base64';
export default {
  name: 'addSysMessage',
  components: {
    CancelButton,
    VueEditor
  },
  data() {
    return {
      taxSources: [],
      saveForm: {
        taxNo: '',
        noticeType: '',
        title: '',
        popupFlag: [0],
        openType: '',
        subTitle: '',
        contents: '',
        accepterType: '',
        accepterList: '',
        publishType: '',
        effectTime: '',
        invalidTime: ''
      },
      editorSettings: {
        modules: {
          toolbar: fullToolbar
        },
        theme: 'snow'
      },
      rules: {
        taxNo: [{
          required: true,
          message: '请选择归属劳务公司',
          trigger: 'change'
        }],
        noticeType: [{
          required: true,
          message: '请选择通知类型',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '请输入通知标题',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '小于20个字符',
          trigger: 'blur'
        }],
        popupFlag: [{
          required: true,
          message: '请选择通知位置',
          trigger: 'change'
        }],
        openType: [{
          required: true,
          message: '请选择打开方式',
          trigger: 'change'
        }],
        subTitle: [{
          required: true,
          message: '请输入副标题',
          trigger: 'blur'
        }, {
          min: 1,
          max: 40,
          message: '小于40个字符',
          trigger: 'blur'
        }],
        accepterType: [{
          required: true,
          message: '请选择通知对象',
          trigger: 'change'
        }],
        publishType: [{
          required: true,
          message: '请选择发布方式',
          trigger: 'change'
        }],
        effectTime: [{
          required: true,
          message: '请选择发布时间',
          trigger: 'change'
        }],
        invalidTime: [{
          required: true,
          message: '请选择失效时间',
          trigger: 'change'
        }, {
          validator: this.dateValidator,
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    contentsRules() {
      let message = '';
      if (this.saveForm.noticeType === 2) {
        message = '请输入短信内容';
      } else {
        if (this.saveForm.openType === 0) {
          message = '请输入通知详情';
        } else {
          message = '请输入H5链接';
        }
      }
      return [{
        required: true,
        message: message,
        trigger: 'blur'
      }];
    },
    accepterListRules() {
      if (this.saveForm.noticeType === 2) {
        return [{
          required: true,
          message: '请输入需要通知的用户手机号',
          trigger: 'blur'
        }, {
          validator: this.checkUserPhones,
          trigger: 'blur'
        }];
      } else {
        return {
          required: true,
          message: '请输入需要通知的用户账号',
          trigger: 'blur'
        };
      }
    },
    accountTitle() {
      if (this.saveForm.noticeType === 2) {
        return '用户手机号';
      } else {
        return '用户账号';
      }
    },
    accountPlaceholder() {
      if (this.saveForm.noticeType === 2) {
        return '输入需通知的用户手机号，每个手机号以逗号隔开';
      } else {
        return '输入需通知的用户账号，每个账号以逗号隔开';
      }
    },
    accountOption() {
      if (this.saveForm.noticeType === 2) {
        return '指定手机号';
      } else {
        return '指定账号';
      }
    }
  },
  created() {
    this.getTaxSources();
    this.partyCode = getAcountInfo().partyCode;
  },
  methods: {
    noticeTypeChanged() {
      this.saveForm.contents = '';
      this.$refs['saveForm'].clearValidate();
    },
    openTypeChanged() {
      this.saveForm.contents = '';
      this.$refs['saveForm'].clearValidate('contents');
    },
    checkUserPhones(rule, value, callback) {
      if (!value) {
        return callback(new Error('输入需通知的用户手机号'));
      }
      value = value.replace(/\s+/g, '');
      const separator = /[,，]/;
      const array = value.split(separator);
      if (array.length === 0) {
        return callback(new Error('输入需通知的用户手机号'));
      }
      for (const item of array) {
        if (!validMobile(item)) {
          return callback(new Error(item + '不是正确的手机号'));
        }
      }
      callback();
    },
    dateValidator(rule, value, callback) {
      if (!value) {
        return callback(new Error('请选择失效时间'));
      }
      let effectTime = null;
      const invalidTime = new Date(value);
      if (this.saveForm.publishType === 0) {
        effectTime = new Date();
      } else if (this.saveForm.publishType === 1 && this.saveForm.effectTime) {
        effectTime = new Date(this.saveForm.effectTime);
      }
      if (effectTime && effectTime >= invalidTime) {
        return callback(new Error('失效时间需大于发布时间'));
      }
      callback();
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('partyCode', this.partyCode);
      uploadPublicFileReq(formData).then(res => {
        if (res.data.showUrl) {
          Editor.insertEmbed(cursorLocation, 'image', res.data.showUrl);
        }
        resetUploader();
      }).catch(err => {
        console.log(err);
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    doSubmit() {
      const reqData = Object.assign({}, this.saveForm);
      reqData.popupFlag = this.saveForm.popupFlag.includes(1) ? 1 : 0;
      if (this.saveForm.noticeType !== 2 && this.saveForm.openType === 0) {
        reqData.contents = encode(this.saveForm.contents);
      }
      if (this.saveForm.accepterList) {
        reqData.accepterList = this.saveForm.accepterList.replace(/\s+/g, '');
        reqData.accepterList = reqData.accepterList.replace(/，/g, ',');
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          sysMessageAdd(reqData).then(res => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    }
  }
};