var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名：",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入姓名",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "custName", $$v);
      },
      expression: "searchForm.custName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号：",
      prop: "idcard"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "可输后四位"
    },
    model: {
      value: _vm.searchForm.idcard,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "idcard", $$v);
      },
      expression: "searchForm.idcard"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票流水号：",
      prop: "invoiceSerialNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "可输后四位"
    },
    model: {
      value: _vm.searchForm.invoiceSerialNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceSerialNo", $$v);
      },
      expression: "searchForm.invoiceSerialNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务名称：",
      prop: "taskName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入任务名称"
    },
    model: {
      value: _vm.searchForm.taskName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskName", $$v);
      },
      expression: "searchForm.taskName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxsList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "签约企业：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择签约企业"
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票申请时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.downloadVisible ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "姓名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskName",
      label: "任务名称",
      "min-width": "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditAmount",
      label: "总金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceSerialNo",
      label: "开票流水号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "applyTime",
      label: "开票申请时间",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "jobCount",
      label: "完成任务",
      width: "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "签约企业",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      width: "170"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };