import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { downloadExcel } from '@/utils/util';
import { batchSettleRecord, downloadAgnetRecordList, getAgnetRecordList, singleSettleRecord } from '../../../api/profit';
import DbDatePicker from '../../../base/ui/date/db-date-picker';
import { PROFIT_ADJUST_RECORD_ID, PROFIT_CONFIRM_RECORD_ID, PROFIT_VIEW_RECORD_INFO } from '../../../utils/idName';
import { getLastMonth } from '@/utils/util';
export default {
  name: 'profitRecordListSub',
  components: {
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      searchForm: {
        agentNo: '',
        agentName: '',
        agentLevel: '',
        status: '',
        profitMonth: ''
      },
      currentSearch: {
        profitMonth: ''
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      hasDownloadPermission: false,
      viewMerchantPath: '',
      adjustRecordPath: '',
      confrimRecordPath: '',
      singleSettlePermisson: false,
      summary: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.searchForm.profitMonth = this.currentSearch.profitMonth = getLastMonth();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.has('download');
      this.viewRecordPath = map.get('viewRecord');
      this.adjustRecordPath = map.get('adjustRecord');
      this.singleSettlePermisson = map.has('singleSettle');
      this.confrimRecordPath = map.get('confrimRecord');
    } else {
      this.hasDownloadPermission = false;
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatBelongType(row, column) {
      return this.$formatType('belongType', row.belongType);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.searchForm.profitMonth = getLastMonth();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getAgnetRecordList({
        agentNo: this.currentSearch.agentNo,
        agentName: this.currentSearch.agentName,
        agentLevel: this.currentSearch.agentLevel,
        status: this.currentSearch.status,
        profitMonth: this.currentSearch.profitMonth,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.summary = response.data.summary.split('#');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadForm() {
      downloadAgnetRecordList({
        agentNo: this.currentSearch.agentNo,
        agentName: this.currentSearch.agentName,
        agentLevel: this.currentSearch.agentLevel,
        status: this.currentSearch.status,
        profitMonth: this.currentSearch.profitMonth
      }).then(response => {
        downloadExcel(response.data, '分润业绩记录.xls');
      });
    },
    handleView(row) {
      sessionStorage.setItem(PROFIT_VIEW_RECORD_INFO, JSON.stringify(row));
      this.$router.push({
        path: this.viewRecordPath
      });
    },
    handleConfirm(row) {
      sessionStorage.setItem(PROFIT_CONFIRM_RECORD_ID, row.id);
      this.$router.push({
        path: this.confrimRecordPath
      });
    },
    handleAdjust(row) {
      sessionStorage.setItem(PROFIT_ADJUST_RECORD_ID, row.id);
      this.$router.push({
        path: this.adjustRecordPath
      });
    },
    handleSingleSettle(row) {
      singleSettleRecord(row.id).then(() => {
        this.getList();
        this.$alert('分润提现申请已提交成功，请完成付款申请。', '温馨提示', {
          type: 'success',
          confirmButtonText: '确定',
          closeOnClickModal: false
        });
      });
    },
    batchSettle() {
      if (this.currentSearch.status !== 1) {
        this.$message.error('状态请选择"已确认"查询后，才能批量结算');
        return;
      }
      this.listLoading = true;
      batchSettleRecord({
        agentNo: this.currentSearch.agentNo,
        agentName: this.currentSearch.agentName,
        agentLevel: this.currentSearch.agentLevel,
        status: this.currentSearch.status,
        profitMonth: this.currentSearch.profitMonth
      }).then(() => {
        this.listLoading = false;
        this.getList();
        this.$alert('分润提现申请已提交成功，请完成付款申请。', '温馨提示', {
          type: 'success',
          confirmButtonText: '确定',
          closeOnClickModal: false
        });
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};