import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { taxThresholdNoticeListReq, downloadTaxRepayments, downloadTaxRecord } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import { getLastMonth, downloadAllFile } from '@/utils/util';
export default {
  name: 'taxThresholdList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      taxsList: [],
      listLoading: true,
      searchForm: {
        gthdjStatus: '',
        lsswdjStatus: '',
        djMonth: getLastMonth()
      },
      currentSearch: {
        gthdjStatus: '',
        lsswdjStatus: '',
        djMonth: getLastMonth()
      },
      pageNo: 1,
      pageSize: 10,
      downloadRepayments: false,
      downloadRecord: false,
      saveThresholdPath: ''
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.downloadRepayments = map.has('downloadRepayments');
      this.downloadRecord = map.has('downloadRecord');
      this.saveThresholdPath = map.get('saveThreshold');
    }
    this.getTaxsList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      taxThresholdNoticeListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.searchForm.djMonth = getLastMonth();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    gotoSavePath() {
      this.$router.push({
        path: this.saveThresholdPath
      });
    },
    downloadRepaymentsData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      this.listLoading = true;
      downloadTaxRepayments(this.currentSearch).then(response => {
        downloadExcel(response.data, '付款明细.xls');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadRecordData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      this.listLoading = true;
      downloadTaxRecord(this.currentSearch).then(response => {
        // downloadExcel(response.data, '临登材料.xls')
        // debugger
        downloadAllFile(response.data, response.headers.filename, this);
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};