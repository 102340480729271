// import QRCode from 'qrcodejs2'
import { uploadImg } from '@/api/upload';
import { getAcountInfo } from '@/utils/auth';
import { getwxacode } from '@/api/merchantEmp';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  // components: { QRCode, ItemTitle },
  components: {
    ItemTitle
  },
  data() {
    return {
      servicePhone: process.env.SERVICE_PHONE,
      merchantNo: '',
      listLoading: true,
      wxacode: ''
    };
  },
  created() {
    this.merchantNo = getAcountInfo().partyCode;
    this.viewwxacode();
  },
  /*
  mounted() {
    this.$nextTick(function() {
      this.qrcode()
    })
  },
  */
  methods: {
    /*
    qrcode() {
      const that = this
      // eslint-disable-next-line no-unused-vars
      new QRCode('qrcode', {
        width: 180,
        height: 180, // 高度
        text: that.merchantNo // 二维码内容
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      })
    }
    },
    */
    viewwxacode() {
      this.listLoading = true;
      getwxacode().then(response => {
        this.listLoading = false;
        this.wxacode = response.data;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    fomaterUrl(url) {
      // console.log('uploadImg + url========' + uploadImg + url)
      return uploadImg + url;
    }
  }
};