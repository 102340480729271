var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-form", {
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [!_vm.review ? _c("el-form-item", {
    attrs: {
      label: "个体户名称:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.teamName) + "\n    ")]) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组长姓名:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.empName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.idCard) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.mobile) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.createTime) + "\n    ")])], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "证件信息"
    }
  }), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.info.idcardFront ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardFront,
      "preview-src-list": [_vm.info.idcardFront]
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.info.idcardBack ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardBack,
      "preview-src-list": [_vm.info.idcardBack]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "班组经营范围"
    }
  }), _vm._v(" "), _c("el-form", {
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "经营范围:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.bizScope) + "\n    ")])], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "班组形式"
    }
  }), _vm._v(" "), _c("el-form", {
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "班组形式:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.teamTypeStr) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "选择工种:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.workType || "-") + "\n    ")])], 1), _vm._v(" "), _vm.review ? [_c("item-title", {
    attrs: {
      name: "工商信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      size: "mini",
      rules: _vm.rules,
      inline: true,
      "label-width": "100px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "个体户名称:",
      prop: "teamName"
    }
  }, [_c("el-input", {
    staticClass: "width-300",
    model: {
      value: _vm.searchForm.teamName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "teamName", $$v);
      },
      expression: "searchForm.teamName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码:",
      prop: "licenseNo"
    }
  }, [_c("el-input", {
    staticClass: "width-300",
    model: {
      value: _vm.searchForm.licenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "licenseNo", $$v);
      },
      expression: "searchForm.licenseNo"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发证日期:",
      prop: "issueDate"
    }
  }, [_c("el-date-picker", {
    staticClass: "width-300",
    attrs: {
      type: "date",
      "value-format": "yyyy-MM-dd",
      placeholder: "发证日期"
    },
    model: {
      value: _vm.searchForm.issueDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "issueDate", $$v);
      },
      expression: "searchForm.issueDate"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照:",
      prop: "bizLicense"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.searchForm.showUrl,
      saveFile: _vm.saveFile
    },
    on: {
      imageSuccess: _vm.handleSuccessLogUrl
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "review-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("确定")])], 1)] : [_c("item-title", {
    attrs: {
      name: "工商信息"
    }
  }), _vm._v(" "), _c("el-form", {
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码:",
      prop: ""
    }
  }, [_vm._v("\n        " + _vm._s(_vm.info.licenseNo) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "营业执照:"
    }
  }, [_vm.info.bizLicense ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.bizLicense,
      "preview-src-list": [_vm.info.idcardBack]
    }
  }) : _vm._e()], 1)], 1)]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };