import { isJobOpen } from '@/api/merchant';
import JobOpen from '@/base/ui/jobOpen';
import AnnexListContent from './listContent';
export default {
  name: 'taskRecordList',
  components: {
    AnnexListContent,
    JobOpen
  },
  data() {
    return {
      loading: true,
      isOpen: false,
      batchSubmitTaskRecordSwitch: false
    };
  },
  created() {
    this.getJobOpen();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // debugger
      if (vm.isOpen) {
        vm.$refs.child.getList();
      }
    });
  },
  methods: {
    getJobOpen() {
      this.loading = true;
      isJobOpen().then(response => {
        // debugger
        this.loading = false;
        this.isOpen = response.data.jobOpen === 1;
      });
    }
  }
};