var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "姓名：",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "身份证号：",
      value: _vm.infoForm.idcard,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "临时税务登记：",
      value: _vm.getSummary(),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "所属劳务公司：",
      value: _vm.infoForm.taxName,
      left: false
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "委托代征记录"
    }
  }), _vm._v(" "), _c("el-checkbox", {
    staticStyle: {
      margin: "20px 0"
    },
    on: {
      change: _vm.getInfo
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }, [_vm._v("仅显示完税平衡异常的数据")]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "djMonth",
      label: "申报月份",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "payBatchNo",
      label: "涉及付款批次",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "payAmount",
      label: "付款总额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "djAmount",
      label: "申报总额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "完税平衡",
      width: "120",
      formatter: _vm.$formatTypeInTable("taxDjStatus")
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getInfo
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };