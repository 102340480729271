var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "border-bg save-merchant-sign",
    attrs: {
      model: _vm.saveForm,
      "label-width": "130px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约劳务公司：",
      prop: "tax"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择劳务公司",
      "value-key": "taxNo",
      size: "mini"
    },
    on: {
      change: _vm.taxNoChange
    },
    model: {
      value: _vm.saveForm.tax,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "tax", $$v);
      },
      expression: "saveForm.tax"
    }
  }, _vm._l(_vm.taxSelectList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.saveForm.tax || _vm.merchantList.length === 0,
      placeholder: "请选择甲方单位：",
      clearable: "",
      filterable: ""
    },
    on: {
      change: _vm.merchantSelectChange
    },
    model: {
      value: _vm.saveForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "merchantNo", $$v);
      },
      expression: "saveForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约项目：",
      prop: "projectNo"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择签约项目",
      disabled: !_vm.saveForm.merchantNo || _vm.projectList.length === 0,
      clearable: "",
      filterable: ""
    },
    model: {
      value: _vm.saveForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "projectNo", $$v);
      },
      expression: "saveForm.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: _vm.concatProject(item),
        value: item.projectNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约日期：",
      prop: "date"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传协议附件：",
      prop: "baseFileList"
    }
  }, [_c("upload-more-file", {
    attrs: {
      disabled: !_vm.saveForm.projectNo,
      bizCode: _vm.saveForm.projectNo
    },
    model: {
      value: _vm.saveForm.baseFileList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "baseFileList", $$v);
      },
      expression: "saveForm.baseFileList"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "扣费信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "扣费模式：",
      "label-width": "160px",
      prop: "billMode"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.saveForm.billMode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "billMode", $$v);
      },
      expression: "saveForm.billMode"
    }
  }, _vm._l(_vm.$formatTypeArray("chargeType"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      },
      on: {
        change: _vm.chargeTypeChange
      }
    }, [_vm._v("\n              " + _vm._s(item.name) + "\n              ")]);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_vm.saveForm.billMode !== 1 ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务费费率：",
      "label-width": "160px",
      prop: "baseRate"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.baseRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "baseRate", $$v);
      },
      expression: "saveForm.baseRate"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("例：0.5%请输入0.005")])], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.billMode === 2 ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "类型:",
      prop: "cumulativeType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择类型"
    },
    model: {
      value: _vm.saveForm.cumulativeType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "cumulativeType", $$v);
      },
      expression: "saveForm.cumulativeType"
    }
  }, _vm._l(_vm.$formatTypeArray("cumulativeType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("free-view", {
    ref: "freeView",
    attrs: {
      list: _vm.saveForm.rateInfoList,
      text: _vm.freeText,
      name: _vm.freeName,
      isShowBottom: false
    },
    on: {
      doSubmit: function ($event) {
        return _vm.doSubmit();
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title-span",
    staticStyle: {
      display: "block"
    }
  }, [_c("div", [_vm._v("\n      1.阶梯费率政策最少设置一行参数，通过右上角操作可增加或减少参数配置，最多可增加5级费率配置。\n    ")]), _vm._v(" "), _c("div", [_vm._v("\n      2.费率设置为大于等于0且小于1的数值，如0.5%即输入 0.005。\n    ")]), _vm._v(" "), _vm.saveForm.billMode === 0 ? _c("div", [_vm._v("\n      3.基准服务费计费费率：客户出款时按该费率估算出款服务费。\n    ")]) : _vm._e(), _vm._v(" "), _vm.saveForm.billMode === 2 ? _c("div", [_vm._v("\n      3.客户出款时按该费率进行单笔计算服务费，月底进行差额返还。\n    ")]) : _vm._e()]), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("保存")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };