import CityCascader from '@/base/ui/cascader/cityCascader';
import { saveBase, gjhelperSupportReq, gjOemCodeSelectReq, queryGjAgentReq } from '@/api/merchant';
import { showSalesmanlist } from '@/api/salesman';
import { showAgentlist } from '@/api/agent';
import { isIdCardNo, isMobile } from '@/utils/validate_rules';
import UploadImage from '../../../../base/ui/upload/uploadImage';
import { getAcountInfo } from '../../../../utils/auth';
import { validMobile } from '@/utils/validate';
import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    UploadImage,
    CityCascader
  },
  data() {
    return {
      isGjhelperSupport: false,
      gjOemList: [],
      gjAgentInfo: {},
      legalPersonIdcardpic: '',
      licensePic: '',
      protocolUrl: '',
      salesManList: [],
      agentList: [],
      merchantInvoiceType: this.$formatTypeArray('merchantInvoiceType'),
      taxpayerTypeStatus: this.$formatTypeArray('taxpayerTypeStatus'),
      partyType: '',
      partyCode: '',
      saveForm: {
        gjOemCode: '',
        invoiceType: '',
        address: '',
        agentNo: '',
        areaCode: '',
        cityCode: '',
        contactMobile: '',
        contacts: '',
        legalPerson: '',
        legalPersonIdcard: '',
        legalPersonIdcardpic: '',
        licenseNo: '',
        licensePic: '',
        merchantName: '',
        provinceCode: '',
        // repayNotifyUrl: '',
        reviewMobile: '',
        salesmanNo: '',
        taxpayerType: '',
        rewardFlag: 1,
        taskLibSwitch: 0,
        jobSwitch: 0,
        taskReportSwitch: 0
      },
      rules: {
        gjOemCode: [{
          required: true,
          message: '请选择渠道来源',
          trigger: 'blur'
        }],
        address: [
        // { required: true, message: '请输入地址', trigger: 'blur' },
        {
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        // repayNotifyUrl: [
        //   // { required: true, message: '请输入地址', trigger: 'blur' },
        //   { max: 30, message: '小于30个字符', trigger: 'blur' }
        // ],
        taxpayerType: [{
          required: true,
          message: '请选择纳税人性质',
          trigger: 'blur'
        }],
        contactMobile: [{
          required: true,
          message: '请输入联系人手机号码',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确联系人姓名',
          trigger: 'blur'
        }],
        legalPerson: [{
          required: true,
          message: '请输入法人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确的法人姓名',
          trigger: 'blur'
        }],
        legalPersonIdcard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          validator: isIdCardNo,
          trigger: 'blur'
        }],
        merchantName: [{
          required: true,
          message: '请输入甲方单位名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        licenseNo: [{
          required: true,
          message: '请输入统一信用代码',
          trigger: 'blur'
        }, {
          min: 10,
          max: 20,
          message: '大于10字符，小于20个字符',
          trigger: 'blur'
        }],
        cityCode: [{
          required: true,
          message: '请选择所属地区',
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        protocolUrl: [{
          required: true,
          message: '请上传协议模版',
          trigger: 'blur'
        }],
        salesmanNo: [{
          required: true,
          message: '请选择所属客户经理',
          trigger: 'blur'
        }],
        taskReportSwitch: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        agentNo: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        reviewMobile: [
        // { required: true, message: '请输入复核手机号码', trigger: 'blur' },
        {
          validator: isMobile,
          trigger: 'blur'
        }],
        rewardFlag: [{
          required: true,
          message: '请选择交易额是否参与业绩奖励',
          trigger: 'blur'
        }],
        taskLibSwitch: [{
          required: true,
          message: '请选择是否开启任务库开关',
          trigger: 'blur'
        }],
        jobSwitch: [{
          required: true,
          message: '请选择是否开启任务',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    }
  },
  created() {
    // this.getAngentList()
    // this.getSalesmanList()
    // this.gjhelperSupport()
    // this.gjOemCodeSelect()

    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.partyCode = user.partyCode;
  },
  methods: {
    getAngentList() {
      const that = this;
      showAgentlist().then(response => {
        that.agentList = response.data;
      });
    },
    getSalesmanList() {
      const that = this;
      showSalesmanlist().then(response => {
        that.salesManList = response.data;
      });
    },
    addSubmit() {
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.saveForm.gjAgentJson = JSON.parse(JSON.stringify(this.gjAgentInfo));
          saveBase(this.saveForm).then(response => {
            if (this.gotoNext) {
              this.gotoNext(response.data);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = res.data.saveKey;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = res.data.saveKey;
    },
    gjhelperSupport() {
      // 查看系统是否支持国金助手服务商体系
      gjhelperSupportReq().then(res => {
        this.isGjhelperSupport = res.data === 1; // 1-支持，其他-不支持
      });
    },
    gjOemCodeSelect() {
      // gjOemCodeSelectReq().then(res => {
      //   this.gjOemList = res.data
      // })
    },
    queryGjAgent() {
      if (!this.saveForm.gjOemCode) {
        this.$message.error('请选择渠道来源。');
        return;
      }
      if (!validMobile(this.saveForm.mobile)) {
        this.$message.error('请正确的手机号。');
        return;
      }
      queryGjAgentReq({
        mobile: this.saveForm.mobile,
        oemCode: this.saveForm.gjOemCode
      }).then(res => {
        this.gjAgentInfo = res.data;
      }).catch(erros => {
        this.$message.error(erros.errMsg);
      });
    }
  }
};