var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      height: "100%"
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    staticStyle: {
      width: "40%",
      height: "100%"
    },
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行名称：",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入银行名称"
    },
    model: {
      value: _vm.saveForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "bankName", $$v);
      },
      expression: "saveForm.bankName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "英文简称：",
      prop: "bankCode"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入英文简称"
    },
    model: {
      value: _vm.saveForm.bankCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "bankCode", $$v);
      },
      expression: "saveForm.bankCode"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "logo："
    }
  }, [_c("upload-image", {
    attrs: {
      saveFile: _vm.saveFile
    },
    on: {
      imageSuccess: _vm.handleSuccessLogUrl
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("保存")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };