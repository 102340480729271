var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      height: "100%"
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    staticStyle: {
      width: "40%",
      height: "100%"
    },
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行名称：",
      prop: "bankName"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.bankName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "英文简称：",
      prop: "bankCode"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.saveForm.bankCode) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "logo："
    }
  }, [_vm.saveForm.logUrl ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.logUrl,
      "preview-src-list": [_vm.saveForm.logUrl]
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };