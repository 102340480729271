var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.onClose,
      visible: _vm.dialogFormVisible
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.row.taxName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "企业税号:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.row.licenseNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址:",
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "64",
      placeholder: "请输入注册地址"
    },
    model: {
      value: _vm.form.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyAddress", $$v);
      },
      expression: "form.companyAddress"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册电话:",
      prop: "companyNumber"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "24",
      placeholder: "请输入注册电话"
    },
    model: {
      value: _vm.form.companyNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyNumber", $$v);
      },
      expression: "form.companyNumber"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户行:",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "34",
      placeholder: "请输入开户行"
    },
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", $$v);
      },
      expression: "form.bankName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户账号:",
      prop: "bankAccount"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "34",
      placeholder: "请输入开户账号"
    },
    model: {
      value: _vm.form.bankAccount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankAccount", $$v);
      },
      expression: "form.bankAccount"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading,
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };