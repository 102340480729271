var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "editForm",
    staticClass: "add-user-form border-bg",
    attrs: {
      size: "mini",
      model: _vm.editForm,
      "label-width": "150px",
      rules: _vm.addRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户名",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      placeholder: "请输入登录用户名，建议使用手机号码"
    },
    model: {
      value: _vm.editForm.username,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "username", $$v);
      },
      expression: "editForm.username"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号",
      prop: "mobile"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      placeholder: "请输入手机号码，用户接收登录验证码"
    },
    model: {
      value: _vm.editForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "mobile", $$v);
      },
      expression: "editForm.mobile"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "真实姓名",
      prop: "realname"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      placeholder: "请输入用户真实姓名"
    },
    model: {
      value: _vm.editForm.realname,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "realname", $$v);
      },
      expression: "editForm.realname"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.editForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "status", $$v);
      },
      expression: "editForm.status"
    }
  }, _vm._l(_vm.userStatusArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "邮箱",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off"
    },
    model: {
      value: _vm.editForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "email", $$v);
      },
      expression: "editForm.email"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "角色",
      prop: "roleIdList"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.editForm.roleIdList,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "roleIdList", $$v);
      },
      expression: "editForm.roleIdList"
    }
  }, _vm._l(_vm.roleArr, function (role) {
    return _c("el-checkbox", {
      key: role.id,
      attrs: {
        label: role.id,
        border: "",
        size: "medium"
      }
    }, [_vm._v(_vm._s(role.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };