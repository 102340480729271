var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.dialogFormVisible,
      width: "700px",
      "close-on-click-modal": false
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    staticClass: "rz-form",
    attrs: {
      size: "small",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "班组编号:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组名称:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.teamName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司编号:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.taxNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司名称:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.taxName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目子编号:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.projectNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目名称:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.formData.projectName) + "\n    ")])], 1), _vm._v(" "), _c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传新合同:",
      "label-width": _vm.formLabelWidth,
      prop: "agreementUrls"
    }
  }, [_c("upload-more-file", {
    staticClass: "upload-wrap",
    attrs: {
      limit: 1,
      action: _vm.uploadContract,
      extendData: _vm.extendData
    },
    model: {
      value: _vm.contractUrlList,
      callback: function ($$v) {
        _vm.contractUrlList = $$v;
      },
      expression: "contractUrlList"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.formLabelWidth
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("确定上传")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "contractName",
      label: "合同名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "uploadDate",
      label: "上传时间",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "140",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.contractUrl ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadAgreement(scope.$index, scope.row);
            }
          }
        }, [_vm._v("下载协议")]) : _vm._e(), _vm._v(" "), _c("el-button", {
          staticClass: "ml8",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onDelete(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };