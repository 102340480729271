import { login, logout, forgetPwd, getCaptcha } from '@/api/login';
import { getAuthSession, setAuthSession, removeAuthSession, setAcountInfo, removeAcountInfo } from '@/utils/auth';
import { Message } from 'element-ui';
const user = {
  state: {
    name: '',
    avatar: '',
    authSession: getAuthSession(),
    userInfo: {}
  },
  mutations: {
    SET_AUTH_SESSION: (state, authSession) => {
      state.authSession = authSession;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    }
  },
  actions: {
    // 图形验证码
    Captcha({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getCaptcha().then(response => {
          commit('SET_USER_INFO', {});
          const authSession = response.headers['x-auth-session'];
          commit('SET_AUTH_SESSION', authSession);
          setAuthSession(authSession);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 登录
    Login({
      commit
    }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        login(username, userInfo.password, userInfo.loginCode).then(response => {
          // console.log('====>response.headers=' + response.headers)
          const authSession = response.headers['x-auth-session'];
          commit('SET_AUTH_SESSION', authSession);
          setAuthSession(authSession);
          const data = response.data.data;
          // console.log('====>response.data.code=' + response.data.code)
          // BASE.000 表示登陆成功，否则提示错误
          if (response.data.code === 'BASE.000') {
            commit('SET_USER_INFO', data);
            setAcountInfo(data);
            resolve();
          } else {
            Message.error(response.data.errMsg);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 忘记密码
    ForgetPwd({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        forgetPwd(userInfo).then(response => {
          const data = response;
          commit('SET_NAME', data.name);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        resolve(state.userInfo);
        /**
         * getInfo(state.token).then(response => {
            const data = response
            if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                commit('SET_ROLES', data.roles)
            } else {
                reject('getInfo: roles must be a non-null array !')
            }
            commit('SET_NAME', data.name)
            commit('SET_AVATAR', data.avatar)
            resolve(response)
        }).catch(error => {
            reject(error)
        })*/
      });
    },
    // 登出
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_USER_INFO', {});
          removeAuthSession();
          removeAcountInfo();
          sessionStorage.clear();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        removeAuthSession();
        resolve();
      });
    },
    // 动态修改权限
    ChangeRoles({
      commit
    }, role) {
      return new Promise(resolve => {
        resolve();
      });
    }
  }
};
export default user;