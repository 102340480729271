import table from '@/utils/table';
import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination/index';
import { getInvoiceTitle, getProfitRecordInfo } from '@/api/profit';
import { getAgentProfitDetailList } from '@/api/transaction';
import { adjustRecord } from '../../../../api/profit';
import { isMoneyNumber } from '../../../../utils/validate_rules';
import { PROFIT_ADJUST_RECORD_ID } from '../../../../utils/idName';
export default {
  components: {
    Pagination,
    ItemTitle
  },
  name: 'adjustRecord',
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      infoForm: {},
      list: [],
      invoiceTitleInfo: {},
      showDialog: false,
      confirmForm: {
        submitOpinion: '',
        status: '',
        id: '',
        isSelect: ''
      },
      confirmFormRules: {
        isSelect: [{
          required: true,
          message: '请选择是否调账',
          trigger: 'blur'
        }],
        adjustAmount: [{
          required: true,
          message: '请输入调账金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        adjustRemark: [{
          required: true,
          message: '请输入调账说明',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '最多可输入50个字符',
          trigger: 'blur'
        }],
        reviewOpinion: [{
          required: true,
          message: '请输入复核意见',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '最多可输入50个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.getInfo();
    getInvoiceTitle().then(response => {
      this.invoiceTitleInfo = response.data;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getInfo();
    });
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getInfo() {
      const id = sessionStorage.getItem(PROFIT_ADJUST_RECORD_ID);
      this.confirmForm.id = id;
      getProfitRecordInfo(id).then(response => {
        this.infoForm = response.data;
        this.getQuery();
      });
    },
    getList() {
      this.listLoading = true;
      getAgentProfitDetailList({
        agentNo: this.infoForm.agentNo,
        profitMonth: this.infoForm.bussincessMonth,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    doSubmit() {
      this.$refs['confirmForm'].validate(valid => {
        if (valid) {
          adjustRecord(this.confirmForm).then(() => {
            this.$message.success('操作成功');
            this.goBack();
          });
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};