var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "服务商名称：",
      value: _vm.infoForm.agentName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "服务商属性：",
      value: _vm.$formatType("agentLevelStatutes", _vm.infoForm.agentLevel),
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "劳务公司名称：",
      value: _vm.infoForm.taxName,
      left: true
    }
  })], 1)]), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("el-form-item", {
    attrs: {
      label: "业绩分润描述："
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.saveForm.profitExplain,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "profitExplain", $$v);
      },
      expression: "saveForm.profitExplain"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "类型：",
      prop: "profitType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.saveForm.profitType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "profitType", $$v);
      },
      expression: "saveForm.profitType"
    }
  }, _vm._l(_vm.$formatTypeArray("agentProfitType"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n          ")]);
  }), 1)], 1), _vm._v(" "), _c("free-view", {
    ref: "profitFreeView",
    attrs: {
      list: _vm.profitRateList,
      text: "交易金额 ",
      name: "业绩分润阶梯费率政策"
    },
    on: {
      doSubmit: _vm.profitDoSubmit
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-bottom": "10px"
    }
  }, [_c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：费率及税率均为大于等于0且小于1的数值，如0.5%即输入 0.005)")])])], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form-item", {
    attrs: {
      label: "业绩分润收益比例描述："
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.saveForm.ratioExplain,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "ratioExplain", $$v);
      },
      expression: "saveForm.ratioExplain"
    }
  })], 1), _vm._v(" "), _c("free-view", {
    ref: "ratioFreeView",
    attrs: {
      list: _vm.ratioRateList,
      text: "交易金额 ",
      name: "业绩分润收益阶梯系数政策",
      typeName: "系数"
    },
    on: {
      doSubmit: _vm.ratioDoSubmit
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form-item", {
    attrs: {
      label: "业绩奖励描述："
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.saveForm.rewardExplain,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rewardExplain", $$v);
      },
      expression: "saveForm.rewardExplain"
    }
  })], 1), _vm._v(" "), _c("free-view", {
    ref: "rewardFreeView",
    attrs: {
      list: _vm.rewardRateList,
      text: "交易金额 ",
      name: "业绩奖励阶梯系数政策",
      typeName: "系数"
    },
    on: {
      doSubmit: _vm.rewardDoSubmit
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateBtn
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };