var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: _vm.title
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票抬头",
      prop: "merchantName"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.addForm.merchantName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "税务登记号",
      prop: "invoiceNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.addForm.invoiceNo) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册电话",
      prop: "companyNumber"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入注册电话",
      clearable: ""
    },
    model: {
      value: _vm.addForm.companyNumber,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "companyNumber", $$v);
      },
      expression: "addForm.companyNumber"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址",
      prop: "companyAddress"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入注册地址",
      clearable: ""
    },
    model: {
      value: _vm.addForm.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "companyAddress", $$v);
      },
      expression: "addForm.companyAddress"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户银行",
      prop: "bankName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开户银行",
      clearable: ""
    },
    model: {
      value: _vm.addForm.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "bankName", $$v);
      },
      expression: "addForm.bankName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "银行账户",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入银行账户",
      clearable: ""
    },
    model: {
      value: _vm.addForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "accountNo", $$v);
      },
      expression: "addForm.accountNo"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "tip"
  }, [_vm._v("注：请输入和营业执照对应的开票信息")])], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };