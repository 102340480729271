import request from '@/utils/request';

/**
 * 获取咨询列表
 */
export const getConsultListReq = params => {
  return request({
    url: '/consult/record/list',
    method: 'post',
    data: params
  });
};

/**
 * 处理咨询
 */
export const handleConsultReq = params => {
  return request({
    url: '/consult/record/handle',
    method: 'post',
    data: params
  });
};

/**
 * 关闭咨询
 */
export const closeConsultReq = params => {
  return request({
    url: '/consult/record/close',
    method: 'post',
    data: params
  });
};