import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { saveInvoiceContent } from '@/api/invoice';
import DbAddTags from '@/base/ui/tag/addTags';
import { isTrueTags } from '@/utils/validate_rules';
export default {
  name: 'AddInvoiceContentDialog',
  components: {
    DbAddTags,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        contentCode: '',
        taxNo: '',
        contentName: '',
        invoiceType: '',
        remark: '',
        exampleProjectList: []
      },
      myDialogFormVisible: false,
      taxList: [],
      taxRouterList: [],
      rules: {
        taxNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        contentCode: [{
          required: true,
          message: '请输入发票内容代码',
          trigger: 'blur'
        }, {
          max: 16,
          message: '最多可输入16个字符',
          trigger: 'blur'
        }],
        contentName: [{
          required: true,
          message: '请输入发票内容',
          trigger: 'blur'
        }, {
          max: 32,
          message: '最多可输入32个字符',
          trigger: 'blur'
        }],
        exampleProjectList: [{
          validator: isTrueTags,
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    addDialogVisible: false
  },
  watch: {
    addDialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    addBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          saveInvoiceContent(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.myDialogFormVisible = false;
      this.$refs['saveForm'].resetFields();
      this.$emit('on-cancel');
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};