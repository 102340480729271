var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "银企直联参数"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "建行客户编号"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appId) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代发用途编号"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.useofCode) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "建行代发协议编号"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.ccbBillCode) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "跨行代发协议编号"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.billCode) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银企直联用户名"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.ncUserId) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银企直联密码"
    }
  }, [_vm.saveForm.ncUserPwd !== "" ? _c("div", [_vm._v("\n            ******\n          ")]) : _vm._e()])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "前置服务访问地址"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.ncPreposeIp) + "\n        ")])], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "其他设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支持充值入单"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatIsSupport(_vm.saveForm.rechargeAuto)) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatStatus(_vm.saveForm.status)) + "\n        ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };