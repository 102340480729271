import request from '@/utils/request';
import axios from 'axios';
import { getAuthSession } from '@/utils/auth';
import download from '@/utils/download';

/**
 * 获取人员列表信息
 */
export const employeeList = params => {
  return request({
    url: '/employee/operate/empList',
    method: 'post',
    data: params
  });
};

/**
 * 获取人员签约列表信息
 */
export const empSignList = params => {
  return request({
    url: '/employee/operate/empSignList',
    method: 'post',
    data: params
  });
};

/**
 * 预览数据分页查询
 */
export const viewPageList = params => {
  const {
    merchantNo,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/employee/operate/importPage',
    method: 'post',
    data: {
      merchantNo,
      pageNo,
      pageSize
    }
  });
};
/**
 * 批量数据提交保存
 */
export const saveImport = params => {
  return request({
    url: '/employee/operate/saveImport',
    method: 'post',
    data: {
      ...params
    }
  });
};

/**
 * 查看人员签约详细信息
 */
export const queryEmpInfo = pbNo => {
  return request({
    url: '/employee/operate/info',
    method: 'post',
    data: {
      pbNo
    }
  });
};

/**
 * 修改人员信息
 */
export const updateEmpInfo = params => {
  const {
    pbNo,
    empName,
    idcard,
    reservePhone,
    accountNo,
    merchantNo
  } = params;
  return request({
    url: '/employee/operate/update',
    method: 'post',
    data: {
      pbNo,
      empName,
      idcard,
      reservePhone,
      accountNo,
      merchantNo
    }
  });
};

/**
 *下载人员列表信息
 */
export async function downloadEmpList(params) {
  let res;
  await axios({
    url: process.env.BASE_API + '/employee/operate/downloadEmpList',
    method: 'post',
    headers: {
      'X-AUTH-SESSION': getAuthSession()
    },
    data: params,
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/**
 *下载签约列表信息
 */
export async function downloadSignList(params) {
  let res;
  await axios({
    url: process.env.BASE_API + '/employee/operate/downloadSignList',
    method: 'post',
    headers: {
      'X-AUTH-SESSION': getAuthSession()
    },
    data: params,
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/**
 * 上传半身照照片
 */
export const uploadHalfBody = params => {
  return request({
    url: '/employee/merchant/uploadHalfBody',
    method: 'post',
    data: params
  });
};

/**
 * 邀请半身照认证(确认邀请)
 */
export const invitemployee = id => {
  return request({
    url: '/employee/merchant/invite',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *  下载人员证件照
 */
export const downPersonPic = id => {
  return download({
    url: '/employee/merchant/downPersonPic',
    data: {
      id
    }
  });
};

/**
 *  审核半身照照片
 */
export const checkHalfBody = params => {
  return request({
    url: '/employee/merchant/checkHalfBody',
    method: 'post',
    data: params
  });
};

/**
 *  获取详细信息{id:xx}
 */
export const getEmployeeInfo = id => {
  return request({
    url: '/employee/merchant/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *  V4.2补充需求-人员记录-上传半身照
 */
export const uploadIdphotoReq = params => {
  return request({
    url: '/employee/operate/uploadIdphoto',
    method: 'post',
    data: params
  });
};

/**
 * V4.6 下载个人签约协议
 */
export const downloadProtocol = param => {
  return download({
    url: '/employee/operate/downloadProtocol',
    data: param
  });
};