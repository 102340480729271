var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pwdset-index"
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "query-line"
  }, [_c("div", [_c("svg-icon", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      "icon-class": "pwdset"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "#666666",
      "font-size": "18px"
    }
  }, [_vm._v("密码设置")]), _vm._v(" "), _vm.setpwdFormVisible ? _c("el-button", {
    staticStyle: {
      "margin-left": "80px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.handleEdit
    }
  }, [_vm._v("去修改")]) : _vm._e()], 1)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };