import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { taxWtdzdjListReg, downloadTaxWtdzdj } from '../../../api/taxsource';
import SaveWtdzdjDialog from './components/saveDialog';
import UpdateWtdzdjDialog from './components/updateDialog';
import DeleteWtdzdjDialog from './components/deleteDialog';
import { WTDZ_INFO_ID } from '../../../utils/idName';
import { downloadExcel } from '../../../utils/util';
export default {
  name: 'taxWtdzdjList',
  components: {
    DeleteWtdzdjDialog,
    UpdateWtdzdjDialog,
    SaveWtdzdjDialog,
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      taxsList: [],
      listLoading: true,
      empSignType: this.$formatTypeArray('empSignType'),
      searchForm: {
        taxNo: '',
        signinType: '',
        custName: '',
        idcard: '',
        status: '',
        djMonth: '',
        voucherType: ''
      },
      currentSearch: {
        taxNo: '',
        signinType: '',
        custName: '',
        idcard: '',
        status: '',
        djMonth: '',
        voucherType: ''
      },
      totalCswhjsAmount: 0,
      totalDfjyfjAmount: 0,
      totalDjAmount: 0,
      totalGrsdsAmount: 0,
      totalJyffjAmount: 0,
      totalPayAmount: 0,
      totalSljszxAmount: 0,
      totalZzsAmount: 0,
      pageNo: 1,
      pageSize: 10,
      deleteTaxWszmdj: false,
      saveTaxWszmdj: false,
      updateTaxWszmdj: false,
      taxWszmdjInfoPath: '',
      saveDialogVisible: false,
      updateDialogVisible: false,
      deleteDialogVisible: false,
      downloadVisible: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.deleteTaxWszmdj = map.has('deleteTaxWszmdj');
      this.saveTaxWszmdj = map.has('saveTaxWszmdj');
      this.updateTaxWszmdj = map.has('updateTaxWszmdj');
      this.taxWszmdjInfoPath = map.get('taxWszmdjInfo');
      this.downloadVisible = map.get('download');
    }
    this.getTaxsList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      taxWtdzdjListReg(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.totalCswhjsAmount = response.data.totalCswhjsAmount;
        this.totalDfjyfjAmount = response.data.totalDfjyfjAmount;
        this.totalDjAmount = response.data.totalDjAmount;
        this.totalGrsdsAmount = response.data.totalGrsdsAmount;
        this.totalJyffjAmount = response.data.totalJyffjAmount;
        this.totalPayAmount = response.data.totalPayAmount;
        this.totalSljszxAmount = response.data.totalSljszxAmount;
        this.totalZzsAmount = response.data.totalZzsAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    handleView(row) {
      const json = JSON.stringify({
        idcard: row.idcard,
        taxNo: row.taxNo,
        taxName: row.taxName,
        custName: row.custName
      });
      sessionStorage.setItem(WTDZ_INFO_ID, json);
      this.$router.push({
        path: this.taxWszmdjInfoPath
      });
    },
    showSaveDialog() {
      this.saveDialogVisible = true;
    },
    showUpdateDialog() {
      this.updateDialogVisible = true;
    },
    showDeleteDialog() {
      this.deleteDialogVisible = true;
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    },
    changeUpdateDialogVisible(val) {
      this.updateDialogVisible = val;
      this.getList();
    },
    changeDeleteDialogVisible(val) {
      this.deleteDialogVisible = val;
      this.getList();
    },
    downloadListData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      this.listLoading = true;
      downloadTaxWtdzdj(this.currentSearch).then(response => {
        downloadExcel(response.data, '申报数据.xls');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};