import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '../store';
import {
// getToken,
getAuthSession } from '@/utils/auth';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API,
  // api的base_url
  timeout: 50000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  // 请求后台禁用所有button
  var btns = document.getElementsByTagName('button');
  for (var i = 0; i < btns.length; i++) {
    btns[i].disabled = 'disabled';
    btns[i].className += ' is-disabled';
  }
  /**
   if (store.getters.token) {
    config.headers['X-Token'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }*/
  if (store.getters.authSession) {
    config.headers['X-AUTH-SESSION'] = getAuthSession(); // 让每个请求携带自定义X-AUTH-SESSION 请根据实际情况自行修改
    config.headers['Content-Security-Policy'] = "script-src 'self'; object-src 'none'; style-src cdn.example.org third-party.org; child-src https:";
    config.headers['X-Content-Type-Options'] = 'nosniff';
    config.headers['X-XSS-Protection'] = '1; mode=block';
  }
  return config;
}, error => {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
  Message({
    message: error,
    type: 'error',
    duration: 5 * 1000
  });
});

// respone拦截器ÒÒÒÒ
service.interceptors.response.use(response => {
  /**
   * code为非20000是抛错 可结合自己业务进行修改
   */
  const res = response.data;

  // 请求后台禁用所有button
  var btns = document.getElementsByTagName('button');
  for (var i = 0; i < btns.length; i++) {
    btns[i].disabled = false;
    let clsname = btns[i].className;
    clsname = clsname.replace(' is-disabled', '');
    btns[i].className = clsname;
  }
  if (res.code !== 'BASE.000') {
    if (res.code !== 'BASE.009') {
      Message({
        message: res.errMsg,
        type: 'error',
        duration: 5 * 1000
      });
    }
    // BASE.009 Token 过期
    if (res.code === 'BASE.009') {
      // 首页直接跳转到登录页面不弹框
      if (store.getters.currentPath !== '/') {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      } else {
        store.dispatch('FedLogOut').then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      }
    }
    return Promise.reject('error');
  } else {
    return response.data;
  }
}, error => {
  Message({
    message: '访问后台失败',
    type: 'error',
    duration: 5 * 1000
  });
  // 请求后台禁用所有button
  var btns = document.getElementsByTagName('button');
  for (var i = 0; i < btns.length; i++) {
    btns[i].disabled = false;
    let clsname = btns[i].className;
    clsname = clsname.replace(' is-disabled', '');
    btns[i].className = clsname;
  }
  // store.dispatch('FedLogOut').then(() => {
  //   location.reload() // 为了重新实例化vue-router对象 避免bug
  // })
  return Promise.reject(error);
});
export default service;