import ItemTitle from '@/base/ui/title/itemTitle';
import { isTrueTags } from '@/utils/validate_rules';
import { updateInvoiceContent } from '@/api/invoice';
import DbAddTags from '../../../../base/ui/tag/addTags';
export default {
  name: 'UpdateInvoiceContentDialog',
  components: {
    DbAddTags,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        contentCode: '',
        taxNo: '',
        contentName: '',
        invoiceType: '',
        remark: '',
        exampleProjectList: []
      },
      myDialogFormVisible: false,
      belongList: [],
      taxRouterList: [],
      taxRouterStatus: this.$formatTypeArray('taxRouterStatus'),
      rules: {
        taxNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        contentCode: [{
          required: true,
          message: '请输入发票内容代码',
          trigger: 'blur'
        }, {
          max: 16,
          message: '最多可输入16个字符',
          trigger: 'blur'
        }],
        contentName: [{
          required: true,
          message: '请输入发票名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '最多可输入32个字符',
          trigger: 'blur'
        }],
        exampleProjectList: [{
          validator: isTrueTags,
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    updateDialogVisible: false,
    item: ''
  },
  watch: {
    updateDialogVisible(val) {
      this.myDialogFormVisible = val;
      if (val) {
        const {
          taxNo,
          invoiceType,
          contentCode,
          contentName,
          remark,
          exampleProjectList
        } = this.item;
        this.saveForm = {
          taxNo,
          invoiceType,
          contentCode,
          contentName,
          remark,
          exampleProjectList
        };
        this.saveForm.exampleProjectList = exampleProjectList;
      }
    },
    myDialogFormVisible(val) {
      this.$emit('changeUpdateDialogVisible', val);
    }
  },
  methods: {
    updateBtn() {
      this.saveForm.id = this.item.id;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateInvoiceContent(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.saveForm = {};
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};