import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getAccountFlowListReq, downloadAccountDetailRecords } from '@/api/account';
import ItemTitle from '@/base/ui/title/itemTitle';
import { TAX_NAME, ACCOUNT_DETAIL_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'accountInfoDetail',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        businessNo: '',
        batchNo: '',
        businessType: '',
        date: []
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      orders: [],
      taxName: '',
      datePicker: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    businessTypes: function () {
      return this.$formatTypeArray('businessType');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    downloadForm() {
      const accountId = sessionStorage.getItem(ACCOUNT_DETAIL_ID);
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const params = {
        accountId: accountId,
        batchNo: this.currentSearch.batchNo,
        busincessType: this.currentSearch.businessType,
        bussincessNo: this.currentSearch.businessNo,
        beginTime: beginTime,
        endTime: endTime
      };
      downloadAccountDetailRecords(params).then(response => {
        downloadExcel(response.data, 'account-detail-records.xls');
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const accountId = sessionStorage.getItem(ACCOUNT_DETAIL_ID);
      const {
        beginTime,
        endTime
      } = separateDate(this.datePicker);
      getAccountFlowListReq({
        accountId: accountId,
        batchNo: this.currentSearch.batchNo,
        busincessType: this.currentSearch.businessType,
        bussincessNo: this.currentSearch.businessNo,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.orders = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};