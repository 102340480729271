var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "msg-item",
    on: {
      click: _vm.gotoInfo
    }
  }, [_c("el-row", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("el-col", {
    staticClass: "read-class",
    attrs: {
      span: 1.5
    }
  }, [_vm.item.isRead === 0 ? _c("div", {
    staticClass: "un-read"
  }, [_c("div", {
    staticClass: "circle"
  }), _vm._v(" 未读\n      ")]) : _c("div", {
    staticClass: "is-read"
  }, [_vm._v("已读")])]), _vm._v(" "), _c("el-col", {
    staticClass: "title-class",
    attrs: {
      span: 13
    }
  }, [_vm._v("\n      " + _vm._s(_vm.item.title) + "\n    ")]), _vm._v(" "), _c("el-col", {
    staticClass: "time-class",
    attrs: {
      span: 5
    }
  }, [_vm._v("\n      " + _vm._s(_vm.item.effectTime) + "\n    ")])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("el-col", {
    staticClass: "content-class"
  }, [_vm._v("\n      " + _vm._s(_vm.item.subTitle) + "\n    ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };