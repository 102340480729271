var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-title"
  }, [_c("div", {
    staticClass: "icon-color"
  }), _vm._v(" "), _c("div", {
    staticClass: "text-font"
  }, [_vm._v(_vm._s(_vm.name))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };