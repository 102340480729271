import userLogin from "./userlogin";
import { mapGetters } from "vuex";
import "./login&forget.scss";
export default {
  name: "login",
  components: {
    userLogin
  },
  data() {
    return {
      activeName: "user",
      isReload: false,
      summaryDesc: process.env.SUMMARY_DESC,
      servicePhone: process.env.SERVICE_PHONE,
      serviceQRCode: process.env.SERVICE_QRCODE,
      recordNumber: process.env.RECORD_NUMBER,
      recordAddress: process.env.RECORD_ADDRESS,
      carouselImgs: ["static/img/1@2x.png"
      // "static/img/2@2x.png",
      // "static/img/5@2x.png",
      ]
    };
  },
  created() {
    // if (this.$store.getters.addRouters.length > 0) { // 判断是否已经刷新
    //   return window.location.reload()
    // }
  },
  mounted() {},
  computed: {
    ...mapGetters(["website"])
  },
  props: [],
  methods: {}
};