import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getRechargeListReq, getUpdateRechargeReq, downloadRechargeRecords, syncRechargeListReq } from '@/api/transaction';
import { showlist } from '@/api/taxsource';
import ItemTitle from '@/base/ui/title/itemTitle';
import { RECHARGE_DETAIL_ID } from '@/utils/idName';
import { getAcountInfo } from '@/utils/auth';
import { queryMchntList } from '../../../api/transaction';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'accountRechargeReview',
  components: {
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        taxSource: '',
        customerNo: '',
        customerName: '',
        chargeType: '',
        rechargeType: '',
        bankSerialNo: '',
        rechargeStatus: '',
        agentNo: '',
        salemanName: '',
        oemName: '',
        syncStatus: '',
        rechargeTime: [],
        // 充值时间
        accountTime: [] // 入账时间
      },
      currentSearch: {},
      partyType: '',
      taxName: '',
      taxSources: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      transactions: [],
      hasCheckPermission: false,
      hasDetailPermission: false,
      hasAddPermission: false,
      syncRechargeVisible: false,
      addPath: '',
      detailPath: '',
      updateDialogVisible: false,
      clickUpdateItem: {},
      clickItemSystemRemark: '',
      // 平台系统追加的提示
      clickItemBankRemark: '',
      // 银行打款提示
      updateForm: {},
      merchantList: [],
      updateRules: {
        merchantNo: [{
          required: true,
          message: '请选择充值甲方单位',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请输入复核意见',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    rechargeTypeOptions: function () {
      return this.$formatTypeArray('rechargeType');
    },
    rechargeStatusOptions: function () {
      return this.$formatTypeArray('rechargeStatus');
    },
    amountTitle: function () {
      return this.partyType === 0 ? '甲方单位入账' : '入账金额';
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasCheckPermission = map.has('check');
      this.hasAddPermission = map.has('add');
      this.addPath = map.get('add');
      this.hasDetailPermission = map.has('recordeDetailList');
      this.detailPath = map.get('recordeDetailList'); // '/account/recorded'
      this.syncRechargeVisible = map.get('syncRecharge');
    } else {
      this.hasCheckPermission = false;
      this.hasDetailPermission = false;
      this.hasAddPermission = false;
      this.syncRechargeVisible = false;
      this.addPath = '';
      this.detailPath = '';
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else {
      this.getTaxSources();
    }
  },
  methods: {
    concatMerchant(tax) {
      return `${tax.merchantName}(${tax.merchantNo})`;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatRechargeType(row, column) {
      return this.$formatType('rechargeType', row.rechargeType);
    },
    formatRechargeStatus(row, column) {
      return this.$formatType('rechargeStatus', row.status);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.rechargeTime);
      const accountTime = separateDate(this.currentSearch.accountTime);
      const params = {
        bankSerialNo: this.currentSearch.bankSerialNo,
        merchantNo: this.currentSearch.customerNo,
        accountName: this.currentSearch.customerName,
        chargeType: this.currentSearch.chargeType,
        rechargeType: this.currentSearch.rechargeType,
        status: this.currentSearch.rechargeStatus,
        taxNo: this.currentSearch.taxSource,
        agentNo: this.currentSearch.agentNo,
        salesman: this.currentSearch.salemanName,
        beginTime: beginTime,
        endTime: endTime,
        acctBeginTime: accountTime.beginTime,
        acctEndTime: accountTime.endTime,
        oemName: this.currentSearch.oemName,
        syncStatus: this.currentSearch.syncStatus
      };
      downloadRechargeRecords(params).then(response => {
        downloadExcel(response.data, 'recharge-records.xls');
      });
    },
    addForm() {
      this.$router.push({
        path: this.addPath
      });
    },
    checkRow(object) {
      // 审核
      // this.$confirm('请确认当前充值记录是否有效，有效请点击"确认"，其他请点击"无效"或关闭对话框。', '充值确认', {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: '确认',
      //   cancelButtonText: '无效',
      //   center: true,
      //   closeOnClickModal: false
      //   // closeOnPressEscape: false
      // }).then(() => {
      //   this.checkDetail(object.id, 1)
      // }).catch(action => {
      //   console.log(action)
      //   if (action === 'cancel') {
      //     this.checkDetail(object.id, 9)
      //   }
      // })
      this.clickUpdateItem = object;
      if (object.remark !== '' && object.remark.indexOf('^') > 0) {
        var tempArray = object.remark.split('^');
        this.clickItemSystemRemark = tempArray[0]; // 平台系统追加的提示
        this.clickItemBankRemark = tempArray[1]; // 银行打款提示
      } else {
        this.clickItemSystemRemark = '';
        this.clickItemBankRemark = object.remark;
      }
      console.log('SystemRemark====>' + this.clickItemSystemRemark);
      console.log('BankRemark====>' + this.clickItemBankRemark);
      this.updateForm = {};
      queryMchntList(object.accountNo).then(response => {
        this.merchantList = response.data;
        this.updateDialogVisible = true;
      });
    },
    // 入账明细
    detailRow(index, object) {
      // 查看详情
      sessionStorage.setItem(RECHARGE_DETAIL_ID, object.bussincessNo);
      this.$router.push({
        path: this.detailPath
      });
    },
    pageChanged() {
      this.getList();
    },
    getList(orderNo) {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.rechargeTime);
      const accountTime = separateDate(this.currentSearch.accountTime);
      getRechargeListReq({
        bankSerialNo: this.currentSearch.bankSerialNo,
        merchantNo: this.currentSearch.customerNo,
        accountName: this.currentSearch.customerName,
        chargeType: this.currentSearch.chargeType,
        rechargeType: this.currentSearch.rechargeType,
        status: this.currentSearch.rechargeStatus,
        taxNo: this.currentSearch.taxSource,
        agentNo: this.currentSearch.agentNo,
        salesman: this.currentSearch.salemanName,
        beginTime: beginTime,
        endTime: endTime,
        acctBeginTime: accountTime.beginTime,
        acctEndTime: accountTime.endTime,
        oemName: this.currentSearch.oemName,
        syncStatus: this.currentSearch.syncStatus,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    checkDetail() {
      this.updateForm = {
        id: this.clickUpdateItem.id,
        merchantNo: this.updateForm.merchantNo,
        remark: this.updateForm.remark,
        status: this.updateForm.status
      };
      this.$refs['updateForm'].validate(valid => {
        if (valid) {
          getUpdateRechargeReq(this.updateForm).then(() => {
            this.$message.success('复核成功...');
            this.updateDialogVisible = false;
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    syncRechargeList() {
      syncRechargeListReq().then(() => {
        this.$message.success('操作成功');
      }).catch(error => {
        this.$message.error(error.errMsg);
      });
    }
  }
};