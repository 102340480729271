import { mapGetters } from 'vuex';
import { getAcountInfo } from '@/utils/auth';
import Preview from './preview';
import ItemTitle from '@/base/ui/title/itemTitle';
import { downTaskRecordTemplate } from '../../../api/task';
import { BATCH_SAVE_INFO } from '../../../utils/idName';
import { downloadExcel } from '../../../utils/util';
import PreviewDialog from './preview2';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'batchSaveContent',
  components: {
    PreviewDialog,
    ItemTitle,
    Preview
  },
  data() {
    return {
      addForm: {
        taskNo: '',
        taxNo: ''
      },
      fileList: [],
      uploadApplyFile: BASE_URL + '/project/merchantTaskRecord/uploadRecords',
      responseData: {},
      taxSources: [],
      isDialogVisible: false,
      rules: {
        taxSource: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'change'
        }],
        taskNo: [{
          required: true,
          message: '请选择任务',
          trigger: 'change'
        }]
      },
      taskList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    exData: function () {
      return {
        taxNo: this.addForm.taxNo,
        merchantNo: this.addForm.merchantNo,
        taskNo: this.addForm.taskNo
      };
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.getTaxSources()
  //   })
  // },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    const json = sessionStorage.getItem(BATCH_SAVE_INFO);
    this.addForm = JSON.parse(json);
  },
  methods: {
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选取提交文件。');
        return;
      }
      this.$confirm('是否确定提交？', '温馨提示', {
        type: 'plain',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs['addForm'].validate(valid => {
          if (valid) {
            this.$refs.upload.submit();
          } else {
            return false;
          }
        });
      }).catch(() => {});
    },
    beforeUpload(file) {
      const xls = 'application/vnd.ms-excel';
      const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = file.type;
      const fileName = file.name;
      // console.log('====>Upload File Type=' + file.type)
      // console.log('====>Upload File Name=' + file.name)
      var isExcel = false;
      if (fileType != null && fileType !== '') {
        isExcel = fileType === xls || fileType === xlsx;
        // console.log('====>Upload File isExcelByType=' + isExcel)
      } else {
        const reg = /^.*\.(xls|xlsx)$/; // 取不到文件type时，文件后缀匹配
        isExcel = reg.test(fileName);
        // console.log('====>Upload File isExcelByName=' + isExcel)
      }
      // console.log('====>Upload File isExcel=' + isExcel)
      if (!isExcel) {
        this.$message.error('上传文件只能是 .xls/.xlsx 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt2M && isExcel;
    },
    handleDataList(response, file, fileList) {
      if (response != null && response.code === 'BASE.000') {
        this.responseData = response.data;

        // if (this.responseData.successNum === 0) {
        //   this.$alert('文件内容全部解析失败，请确认提交文件是通过“导出附件模板”操作获取！', '更新结果', {
        //     type: 'error',
        //     confirmButtonText: '确定',
        //     closeOnClickModal: false
        //   })
        //   this.clearData()
        //   return
        // }

        this.isDialogVisible = true;
      } else {
        if (response != null) {
          this.$message.error(response.errMsg);
        } else {
          this.$message.error('上传失败');
        }
      }
    },
    handleRemove() {
      this.fileList = [];
      this.responseData = {};
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    changeVisiblePreviewDialog(val) {
      this.isDialogVisible = val;
      if (!val) {
        this.clearData();
      }
    },
    clearData() {
      this.$refs['addForm'].resetFields();
      this.fileList = [];
      this.responseData = {};
    },
    downTaskRecord() {
      downTaskRecordTemplate({
        taskNo: this.addForm.taskNo
      }).then(response => {
        downloadExcel(response.data, '任务批量提交模板.xlsx');
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};