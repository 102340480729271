import ViewBase from '../taxsource/components/view/viewBase';
import ViewIndustry from '../taxsource/components/view/viewIndustry';
import ViewProduct from '../taxsource/components/view/viewProduct';
import { showInfo } from '@/api/taxsource';
import ViewRate from '../taxsource/components/view/viewRate';
export default {
  components: {
    ViewRate,
    ViewBase,
    ViewIndustry,
    ViewProduct
  },
  data() {
    return {
      active: 0,
      message: 'first',
      bean: {
        taxBase: {},
        industryList: [],
        rateConfig: {},
        paramsConfig: {}
      }
    };
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      showInfo().then(response => {
        that.bean = response.data;
      });
    }
  }
};