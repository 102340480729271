var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "total-transaction"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "money"
  }, [_vm.length > 8 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("亿")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 9)))])]) : _vm._e(), _vm._v(" "), _vm.length > 7 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("千万")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 8)))])]) : _vm._e(), _vm._v(" "), _vm.length > 6 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("百万")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 7)))])]) : _vm._e(), _vm._v(" "), _vm.length > 6 ? _c("div", {
    staticClass: "dou-hao"
  }, [_vm._v(",")]) : _vm._e(), _vm._v(" "), _vm.length > 5 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("十万")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 6)))])]) : _vm._e(), _vm._v(" "), _vm.length > 4 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("万")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 5)))])]) : _vm._e(), _vm._v(" "), _vm.length > 3 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span", [_vm._v("千")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 4)))])]) : _vm._e(), _vm._v(" "), _vm.length > 3 ? _c("div", {
    staticClass: "dou-hao"
  }, [_vm._v(",")]) : _vm._e(), _vm._v(" "), _vm.length > 2 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span"), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 3)))])]) : _vm._e(), _vm._v(" "), _vm.length > 1 ? _c("div", {
    staticClass: "money-item"
  }, [_c("span"), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 2)))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "money-item"
  }, [_c("span"), _vm._v(" "), _vm.length > 0 ? _c("div", [_vm._v(_vm._s(_vm.myMoney.charAt(_vm.length - 1)))]) : _c("div", [_vm._v("0")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trophy"
  }, [_c("img", {
    attrs: {
      src: "static/img/first/trophy.png"
    }
  }), _vm._v(" "), _c("p", [_vm._v("本月交易额")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };