export default {
  name: 'dbAddTags',
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    };
  },
  props: {
    btnText: {
      type: String,
      default: () => {
        return '+ 新 增';
      }
    },
    value: {
      type: Array
    }
  },
  model: {
    prop: 'value',
    event: 'modelVal' // 自定义方法，用来更新 model
  },
  watch: {
    dynamicTags(val) {
      this.$emit('modelVal', val); // 更新 model
    },
    value(val) {
      this.dynamicTags = val;
    }
  },
  created() {
    this.dynamicTags = this.value;
  },
  destroyed() {
    this.dynamicTags = [];
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    changeVal(val, lr) {
      this.$emit('modelVal', val); // 更新 model
      this.$emit('change', val, lr); // 事件传值
    }
  }
};