import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist, downloadTaxWszmdj } from '../../../../api/taxsource';
import { downloadExcel } from '../../../../utils/util';
import { merchantShowList } from '../../../../api/merchant';
import { getAcountInfo } from '../../../../utils/auth';
export default {
  name: 'downloadWszmdjDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxList: [],
      merchantList: [],
      exData: {
        taxNo: '',
        merchantNo: ''
      },
      myDialogFormVisible: false,
      partyType: ''
    };
  },
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      if (val) {
        this.getTaxsList();
        this.partyType = getAcountInfo().partyType;
        if (this.partyType === 0 || this.partyType === 1) {
          this.getMerchantList();
        }
      }
      this.$emit('changeDownloadDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxList = response.data;
      });
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    downloadListData() {
      this.listLoading = true;
      downloadTaxWszmdj(this.exData).then(response => {
        downloadExcel(response.data, '完税明细.xls');
        this.listLoading = false;
        this.myDialogFormVisible = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};