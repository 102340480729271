var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "归属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择归属劳务公司"
    },
    model: {
      value: _vm.saveForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxNo", $$v);
      },
      expression: "saveForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知类型",
      prop: "noticeType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择通知类型"
    },
    on: {
      change: _vm.noticeTypeChanged
    },
    model: {
      value: _vm.saveForm.noticeType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "noticeType", $$v);
      },
      expression: "saveForm.noticeType"
    }
  }, _vm._l(_vm.$formatTypeArray("noticeType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_vm.saveForm.noticeType === 1 ? _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知位置",
      prop: "popupFlag"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.saveForm.popupFlag,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "popupFlag", $$v);
      },
      expression: "saveForm.popupFlag"
    }
  }, _vm._l(_vm.$formatTypeArray("popupFlag"), function (item) {
    return _c("el-checkbox", {
      key: item.label,
      attrs: {
        label: item.label,
        disabled: item.label == 0
      }
    }, [_vm._v(_vm._s(item.name) + "\n            ")]);
  }), 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知标题",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入通知标题",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "title", $$v);
      },
      expression: "saveForm.title"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.saveForm.noticeType === 0 || _vm.saveForm.noticeType === 1 ? [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "副标题",
      prop: "subTitle"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入副标题",
      maxlength: "40",
      type: "textarea",
      row: 2,
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.subTitle,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "subTitle", $$v);
      },
      expression: "saveForm.subTitle"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "打开方式",
      prop: "openType"
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.openTypeChanged
    },
    model: {
      value: _vm.saveForm.openType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "openType", $$v);
      },
      expression: "saveForm.openType"
    }
  }, _vm._l(_vm.$formatTypeArray("openType"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n              ")]);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _vm.saveForm.openType === 0 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知详情",
      prop: "contents",
      rules: _vm.contentsRules
    }
  }, [_c("vue-editor", {
    ref: "editor",
    attrs: {
      editorOptions: _vm.editorSettings,
      placeholder: "请输入通知详情",
      useCustomImageHandler: ""
    },
    on: {
      "image-added": _vm.handleImageAdded
    },
    model: {
      value: _vm.saveForm.contents,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contents", $$v);
      },
      expression: "saveForm.contents"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.openType === 1 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "H5链接配置",
      prop: "contents",
      rules: _vm.contentsRules
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入要跳转的H5链接",
      maxlength: "100",
      type: "textarea",
      autosize: "",
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.contents,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contents", $$v);
      },
      expression: "saveForm.contents"
    }
  })], 1)], 1)], 1) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.saveForm.noticeType === 2 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "短信内容",
      prop: "contents",
      rules: _vm.contentsRules
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入短信内容",
      maxlength: "200",
      type: "textarea",
      row: 3,
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.contents,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contents", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "saveForm.contents"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.noticeType === 1 || _vm.saveForm.noticeType === 2 ? [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知对象",
      prop: "accepterType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.saveForm.accepterType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "accepterType", $$v);
      },
      expression: "saveForm.accepterType"
    }
  }, [_c("el-radio", {
    key: 0,
    attrs: {
      label: 0
    }
  }, [_vm._v("所有商户")]), _vm._v(" "), _c("el-radio", {
    key: 1,
    attrs: {
      label: 1
    }
  }, [_vm._v("所有服务商")]), _vm._v(" "), _c("el-radio", {
    key: 2,
    attrs: {
      label: 2
    }
  }, [_vm._v(_vm._s(_vm.accountOption))])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.saveForm.accepterType === 2 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.accountTitle,
      prop: "accepterList",
      rules: _vm.accepterListRules
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.accountPlaceholder,
      type: "textarea",
      row: 3,
      "show-word-limit": ""
    },
    model: {
      value: _vm.saveForm.accepterList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "accepterList", $$v);
      },
      expression: "saveForm.accepterList"
    }
  })], 1)], 1)], 1) : _vm._e()] : _vm._e(), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发布方式",
      prop: "publishType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.saveForm.publishType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "publishType", $$v);
      },
      expression: "saveForm.publishType"
    }
  }, _vm._l(_vm.$formatTypeArray("publishType"), function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n            ")]);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _vm.saveForm.publishType === 1 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发布时间",
      prop: "effectTime"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetime",
      "range-separator": "-",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      clearable: false
    },
    model: {
      value: _vm.saveForm.effectTime,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "effectTime", $$v);
      },
      expression: "saveForm.effectTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.noticeType === 0 || _vm.saveForm.noticeType === 1 ? _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "失效时间",
      prop: "invalidTime"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetime",
      "range-separator": "-",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      clearable: false
    },
    model: {
      value: _vm.saveForm.invalidTime,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "invalidTime", $$v);
      },
      expression: "saveForm.invalidTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("确定添加")]), _vm._v(" "), _c("cancel-button")], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };