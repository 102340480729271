var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值订单信息"
    }
  }), _vm._v(" "), _c("el-form", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      model: _vm.view,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目子编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.projectNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.projectName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值金额(元)："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.view.rechargeAmount)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.bankName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行账户："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.accountNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.createTime))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行流水号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.bankSerialNo))])])], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值凭证查看"
    }
  }), _vm._v(" "), _vm._l(_vm.view.voucherFileList, function (item, index) {
    return _c("el-image", {
      key: index,
      staticStyle: {
        width: "300px",
        height: "200px"
      },
      attrs: {
        src: item.fileUrlShow,
        "preview-src-list": [item.fileUrlShow]
      }
    });
  })], 2), _vm._v(" "), _vm.view.logList && _vm.view.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.view.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };