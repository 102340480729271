import GatherList from './components/gatherList';
import GatherInvoice from './components/gatherInvoice';
export default {
  name: 'taskLibInvoice',
  components: {
    GatherList,
    GatherInvoice
  },
  data() {
    return {
      active: 0,
      records: [],
      totalAmt: 0
    };
  },
  methods: {
    onSelect(records, totalAmt) {
      this.active = 1;
      this.records = records;
      this.totalAmt = totalAmt;
    },
    onCancel() {
      this.active = 0;
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    onSubmit() {
      this.showConfirm();
    },
    showConfirm() {
      this.$alert('  申请已提交，请前往“开票记录”下载履约确认函，打印并盖公章后将其扫描件上传。' + '平台将会在上传履约确认函后三个工作日内出票并寄出！', '温馨提示', {
        type: 'warning',
        confirmButtonText: '前往开票记录',
        center: true,
        closeOnClickModal: false
      }).then(() => {
        const {
          path,
          name
        } = this.$router.currentRoute;
        this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
          this.$router.push({
            path: '/invoice/list'
          });
        });
      }).catch(() => {
        this.goBack();
      });
    }
  }
};