import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 获取发票列表
 */
export const getInvoiceListReq = params => {
  return request({
    url: '/invoice/invoiceRecord/list',
    method: 'post',
    data: params
  });
};

/**
 * 申请开票-入口页展示
 */
export const applyInvoiceEntryReq = params => {
  return request({
    url: '/invoice/invoiceRecord/index',
    method: 'post',
    data: params
  });
};

/**
 * 新增发票信息
 */
export const addInvoiceInfoReq = params => {
  return request({
    url: '/invoice/invoiceConfig/save',
    method: 'post',
    data: params
  });
};

/**
 * 修改发票注册信息
 */
export const updateInvoiceInfoReq = params => {
  return request({
    url: '/invoice/invoiceConfig/update',
    method: 'post',
    data: params
  });
};

/**
 * 新增地址信息
 */
export const addAddressInfoReq = params => {
  return request({
    url: '/mchntProject/mchntProjectAddress/save',
    method: 'post',
    data: params
  });
};

/**
 * 修改地址信息
 */
export const updateAddressInfoReq = params => {
  return request({
    url: '/mchntProject/mchntProjectAddress/update',
    method: 'post',
    data: params
  });
};

/**
 * 删除地址
 */
export const deleteAddressReq = params => {
  return request({
    url: '/mchntProject/mchntProjectAddress/delete',
    method: 'post',
    data: params
  });
};

/**
 * 获取地址列表
 */
export const getAddressListReq = params => {
  return request({
    url: '/mchntProject/mchntProjectAddress/list',
    method: 'post',
    data: params
  });
};

/**
 * 获取地址详情
 */
export const getAddressInfoReq = params => {
  return request({
    url: '/mchntProject/mchntProjectAddress/info',
    method: 'post',
    data: params
  });
};

/**
 * 申请开票
 */
export const makeOutInvoiceReq = params => {
  return request({
    url: '/invoice/invoiceRecord/apply',
    method: 'post',
    data: params
  });
};

/**
 * 获取发票详情
 */
export const getInvoiceInfoReq = params => {
  return request({
    url: '/invoice/invoiceRecord/info',
    method: 'post',
    data: params
  });
};

/**
 * 开票审核处理
 */
export const invoiceRecordAudit = params => {
  return request({
    url: '/invoice/invoiceRecord/taxAudit',
    method: 'post',
    data: params
  });
};

/**
 * 更新物流信息
 */
export const invoiceRecordPosted = params => {
  return request({
    url: '/invoice/invoiceRecord/taxPosted',
    method: 'post',
    data: params
  });
};

/**
 * 取消发票申请记录
 */
export const invoiceRecordCancel = params => {
  return request({
    url: '/invoice/invoiceRecord/invoiceCancel',
    method: 'post',
    data: params
  });
};

/**
 *更新履约确认函信息(企业)
 */
export const updateLetterUrl = params => {
  return request({
    url: '/invoice/invoiceRecord/updateLetterUrl',
    method: 'post',
    data: params
  });
};

/**
 *更新发票信息
 */
export const updateBilled = params => {
  return request({
    url: '/invoice/invoiceRecord/taxBilled',
    method: 'post',
    data: params
  });
};

/**
 * 下载开票记录
 */
export const downloadInvoiceRecords = params => {
  return download({
    url: '/invoice/invoiceRecord/download',
    data: params
  });
};

/**
 * 下载履约确认函
 */
export const downloadLetter = id => {
  return download({
    url: '/invoice/invoiceRecord/downloadLetter',
    data: {
      id
    }
  });
};

/**
 * 发票内容列表
 */
export const getInvoiceContentList = params => {
  return request({
    url: '/invoice/invoiceContent/list',
    method: 'post',
    data: params
  });
};

/**
 * 新增发票内容
 */
export const saveInvoiceContent = params => {
  const {
    contentCode,
    contentName,
    invoiceType,
    remark,
    taxNo,
    exampleProjectList
  } = params;
  return request({
    url: '/invoice/invoiceContent/save',
    method: 'post',
    data: {
      contentCode,
      contentName,
      invoiceType,
      remark,
      taxNo,
      exampleProjectList
    }
  });
};

/**
 * 删除发票内容
 */
export const deleteInvoiceContent = id => {
  return request({
    url: '/invoice/invoiceContent/delete',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 获取发票内容详细信息
 */
export const getInvoiceContentInfo = id => {
  return request({
    url: '/invoice/invoiceContent/info',
    method: 'post',
    data: id
  });
};

/**
 * 获取发票内容详细信息
 */
export const updateInvoiceContent = params => {
  const {
    id,
    contentCode,
    contentName,
    invoiceType,
    remark,
    taxNo,
    exampleProjectList
  } = params;
  return request({
    url: '/invoice/invoiceContent/update',
    method: 'post',
    data: {
      id,
      contentCode,
      contentName,
      invoiceType,
      remark,
      taxNo,
      exampleProjectList
    }
  });
};

/**
 * 修改开票内容(未审核前)
 */
export const updateContentInvoiceRecord = params => {
  return request({
    url: '/invoice/invoiceRecord/updateContent',
    method: 'post',
    data: params
  });
};

/**
 * v3.2获取可开票记录列表 - 条件过滤
 */
export const unTicketPaymentListReq = params => {
  return request({
    url: '/invoice/invoiceRecord/unTicketPaymentList',
    method: 'post',
    data: params
  });
};

/**
 * V3.2 获取可开票记录列表 - 按预期金额自动过滤；summary存储期望金额相近的记录可开票金额
 */
export const unTicketPaymentAutoListReq = params => {
  return request({
    url: '/invoice/invoiceRecord/unTicketPaymentAutoList',
    method: 'post',
    data: params
  });
};

/**
 * V3.2 申请开票 - 开启任务库方式
 */
export const submitTaskLibApplyReq = params => {
  return request({
    url: '/invoice/invoiceRecord/taskLibApply',
    method: 'post',
    data: params
  });
};

/**
 * V3.2-商户权限开票 - 根据开票类目获取所有支持的任务类型列表
 */
export const getCategoryByBillReq = params => {
  return request({
    url: '/invoice/invoiceRecord/categoryByBill',
    method: 'post',
    data: params
  });
};

/**
 * V4。1 -票据关联列表
 */
export const invoiceAssociateList = params => {
  return request({
    url: '/invoice/invoiceAssociate/list',
    method: 'post',
    data: params
  });
};

/**
 * V4。1 -下载票据关联列表
 */
export const downloadInvoiceAssociate = params => {
  return download({
    url: '/invoice/invoiceAssociate/download',
    data: params
  });
};

/**
 * V4。1 -关联+关联详情{id:xx}
 * isDetail	integer接口类型（0：关联 1：查看）
 */
export const invoiceAssociateInfoReg = params => {
  return request({
    url: '/invoice/invoiceAssociate/info',
    method: 'post',
    data: params
  });
};

/**
 * 票据关联
 */
export const invoiceAssociateReg = params => {
  return request({
    url: '/invoice/invoiceAssociate/invoiceAssociate',
    method: 'post',
    data: params
  });
};

/**
 * 票据关联-导入关联明细
 */
export const importAssociatePageReg = params => {
  return request({
    url: '/invoice/invoiceAssociate/improtAssociate4Page',
    method: 'post',
    data: params
  });
};

/**
 * 导入关联 - 关联
 */
export const improtAssociateSaveReg = params => {
  return request({
    url: '/invoice/invoiceAssociate/improtAssociateSave',
    method: 'post',
    data: params
  });
};