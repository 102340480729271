var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart-view"
  }, [_c("item-title", {
    attrs: {
      name: "劳务公司收益趋势"
    }
  }), _vm._v(" "), _vm.chartData.rows.length > 0 ? _c("div", [_vm._m(0), _vm._v(" "), _c("dbs-histogram", {
    attrs: {
      data: _vm.chartData,
      settings: _vm.chartSettings
    }
  })], 1) : _c("div", {
    staticClass: "el-table__empty-block",
    staticStyle: {
      height: "100%",
      width: "1161px"
    }
  }, [_c("span", {
    staticClass: "el-table__empty-text"
  }, [_vm._v("暂无数据")])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "date-text"
  }, [_c("span", [_vm._v("单位：万 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };