/**
 * Created by wanglb on 2019/01/16.
 */
import { validUsername, validMoneyNumber, validMobile, validRegexn, validRegexnNoLen, validRegexn4Len, validRegexn6Len, validRegexn16Len, validRegexn16to20Len, validInteger, validEmail, validPassword, validateURL, validIdcard, validZipCode, validBankCardNo, validFloat, validFloat01, validFloat02, validLicenseNo } from '@/utils/validate';
import { validateJudgeTime, validDecimal } from '@/utils/validate';
/** 校验用户名 */
export function isValidUserName(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validUsername(value)) {
      callback(new Error('请输入正确的用户名!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 保留两位小数 */
export function isMoneyNumber(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validMoneyNumber(value)) {
      callback(new Error('请输入正确的数字，最多保留两位小数!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 保留小数 */
export function isDecimal(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validDecimal(value)) {
      callback(new Error('请输入正确的数字'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}
/** 验证用户密码 */
export function isValidPassword(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validPassword(value)) {
      callback(new Error('输入错误，密码需包含大小写字母、数字和符号，长度6~16位'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证手机号 */
export function isMobile(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validMobile(value)) {
      callback(new Error('请输入正确的手机号码!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 判断数组是否有值 */
export function isNoEmptyAarry(rule, value, callback) {
  // debugger
  if (value !== undefined && value != null && value.length > 0) {
    callback();
  } else {
    callback(new Error('请填写!'));
  }
}

/** 验证手机号多个 */
export function isMobiles(rule, value, callback) {
  if (value != null && value !== '') {
    if (value.includes('，')) {
      callback(new Error('请输入半角","'));
    }
    const mobiles = value.split(',');
    for (let i = 0; i < mobiles.length; i++) {
      if (!validMobile(mobiles[i])) {
        callback(new Error('请输入正确的手机号码!'));
        return;
      }
    }
    callback();
  } else {
    callback();
  }
}

/** 含有非法字符(只能输入数字、字母、汉字、下划线) */
export function isRegexn(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexn(value)) {
      callback(new Error('输入错误(只能输入数字、字母、汉字、下划线(不能开头和结尾))!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证不限长数字、字母或下划线*/
export function isRegexnNoLen(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexnNoLen(value)) {
      callback(new Error('输入错误(只能输入数字、字母或下划线)!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证4位数字、字母*/
export function isRegexn4Len(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexn4Len(value)) {
      callback(new Error('输入错误(只能输入6位数字或字母)!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}
/** 验证6位数字、字母*/
export function isRegexn6Len(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexn6Len(value)) {
      callback(new Error('输入错误(只能输入6位数字或字母)!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证16位数字、字母*/
export function isRegexn16Len(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexn16Len(value)) {
      callback(new Error('只能输入1~16位数字或字母!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证16~20位数字、字母*/
export function isRegexn16to20Len(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validRegexn16to20Len(value)) {
      callback(new Error('只能输入16~20位数字或字母!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 请输入正整数*/
export function isInteger(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validInteger(value)) {
      callback(new Error('请输入正整数!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 请输入金额*/
export function isMoney(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validMoneyNumber(value)) {
      callback(new Error('请输入正确的金额，最多保留两位小数!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证Email地址 */
export function isEmail(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validEmail(value)) {
      callback(new Error('请输入正确的邮箱地址!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证URL地址 */
export function isURL(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validateURL(value)) {
      callback(new Error('请输入正确的URL地址!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/**  判断时间是否过期 */
export function judgeTime(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validateJudgeTime(value)) {
      callback(new Error('请输入正确的时间!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证身份证号 */
export function isIdCardNo(rule, value, callback) {
  console.log('==========isIdCardNo===');
  if (value != null && value !== '') {
    if (!validIdcard(value)) {
      callback(new Error('请输入正确的身份证号!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证邮政编码 */
export function isZipCode(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validZipCode(value)) {
      callback(new Error('请输入正确的邮政编码!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 验证银行卡号 */
export function isBankCardNo(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validBankCardNo(value)) {
      callback(new Error('请输入正确的银行卡号!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/* 大于等于0的小数 */
export function isValidFloat(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validFloat(value)) {
      callback(new Error('格式错误!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/* 通道单日申请限额 */
export function isValidQuota(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validFloat(value)) {
      callback(new Error('格式错误!'));
    } else {
      const valueStr = value + '';
      const num = valueStr.split('.');
      if (num[0].length > 9) {
        callback(new Error('超过最大额度!'));
      } else if (num.length === 2 && num[1].length > 2) {
        callback(new Error('小数点保留两位!'));
      } else {
        callback();
      }
    }
  } else {
    callback();
  }
}

/* 大于0小于1的小数 */
export function isValidFloat01(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validFloat01(value)) {
      callback(new Error('请输入正确的费率!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/* 大于0小于等于1的小数 */
export function isValidFloat02(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validFloat02(value)) {
      callback(new Error('请输入正确的数值!'));
    } else {
      callback();
    }
  } else {
    callback();
  }
}
export function isGEOneHundred(rule, value, callback) {
  if (value == null || value === '') {
    callback(new Error('请输入正确的数字，最多保留两位小数!'));
    return;
  }
  if (!validMoneyNumber(value)) {
    callback(new Error('请输入正确的数字，最多保留两位小数!'));
    return;
  }
  const amount = Number.parseFloat(value);
  if (Number.isNaN(amount)) {
    callback(new Error('请输入正确的数字，最多保留两位小数!'));
    return;
  }
  if (amount < 100) {
    callback(new Error('金额必须大于或者等于100元!'));
    return;
  }
  callback();
}
export function isTrueTags(rule, value, callback) {
  if (value.length > 0) {
    value.forEach(item => {
      if (!validRegexn(item)) {
        callback(new Error('输入错误(只能输入数字、字母、汉字、下划线(不能开头和结尾))!'));
      }
    });
  }
  callback();
}
export function isLicenseNo(rule, value, callback) {
  if (value == null || value === '') {
    callback(new Error('请输入统一信用代码'));
    return;
  }
  if (!validLicenseNo(value)) {
    callback(new Error('大于11，小于20个的数字'));
  }
  callback();
}

/** 超额单日最大限额*/
export function isExcessSingleMax(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validMoneyNumber(value)) {
      callback(new Error('请输入正确的数字，最多保留两位小数!'));
    } else if (+value > 100000) {
      callback(new Error(`输入金额不能超过10万`));
    } else if (+value < 0) {
      callback(new Error(`输入金额必须大于0`));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/** 超额单日最小限额*/
export function isExcessSingleMin(rule, value, callback) {
  if (value != null && value !== '') {
    if (!validMoneyNumber(value)) {
      callback(new Error('请输入正确的数字，最多保留两位小数!'));
    } else if (+value > 20000) {
      callback(new Error(`输入金额不能超过2万`));
    } else if (+value < 0) {
      callback(new Error(`输入金额必须大于0`));
    } else {
      callback();
    }
  } else {
    callback();
  }
}

/**
     * 参数 item
     * required true  必填项
     * maxLength  字符串的最大长度
     * min 和 max 必须同时给 min < max  type=number
     * type 手机号 mobile
     *      邮箱   email
     *      金额   money
     *      整数   integer
     *      字符   regexn
     *      各种自定义类型   定义在 src/utils/validate 中    持续添加中.......
     * */
/*
Vue.prototype.filter_rules = function(item) {
  let rules = []
  if (item.required) {
    rules.push({ required: true, message: '该输入项为必填项!', trigger: 'blur' })
  }
  if (item.maxLength) {
    rules.push({ min: 1, max: item.maxLength, message: '最多输入' + item.maxLength + '个字符!', trigger: 'blur' })
  }
  if (item.min && item.max) {
    rules.push({ min: item.min, max: item.max, message: '字符长度在' + item.min + '至' + item.max + '之间!', trigger: 'blur' })
  }
  if (item.type) {
    let type = item.type
    switch (type) {
      case 'money':
        rules.push({ validator: isMoneyNumber, trigger: 'blur,change' })
        break
      case 'mobile':
        rules.push({ validator: isMobile, trigger: 'blur,change' })
        break
      case 'regexn':
        rules.push({ validator: isRegexn, trigger: 'blur,change' })
        break
      case 'integer':
        rules.push({ validator: isInteger, trigger: 'blur,change' })
        break
      case 'email':
        rules.push({ validator: isEmail, trigger: 'blur,change' })
        break
      default:
        rules.push({})
        break
    }
  }
  return rules
}
*/