var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    attrs: {
      size: "mini",
      inline: true,
      model: _vm.listQuery
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称",
      prop: "status"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "角色名称",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.name,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.getQuery
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.addRoleVisible ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.roles,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "角色名称",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "类型",
      width: "120",
      formatter: _vm.formatType
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      width: "100",
      formatter: _vm.formatStatus
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "180"
    }
  }), _vm._v(" "), _vm.deleteRoleVisible || _vm.updateRoleVisible || _vm.viewRolePath && _vm.viewRolePath.length > 0 ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "165",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.updateRoleVisible ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.viewRolePath && _vm.viewRolePath.length > 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.deleteRoleVisible && scope.row.type != 0 ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDel(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }], null, false, 2344201340)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.pageNo,
      limit: _vm.listQuery.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };