var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("account-recorded-index", {
    ref: "child",
    attrs: {
      orderNoChild: _vm.orderNo,
      isChild: true
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };