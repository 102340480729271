var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-bottom": "2rem"
    },
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属商户"
    },
    on: {
      change: _vm.merchantChange
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _vm.partyType !== 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务编号：",
      prop: "taskNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taskNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskNo", $$v);
      },
      expression: "searchForm.taskNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务名称：",
      prop: "taskName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taskName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskName", $$v);
      },
      expression: "searchForm.taskName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务类型：",
      prop: "taskType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务类型",
      "no-data-text": _vm.typeSelectNoDataText
    },
    model: {
      value: _vm.searchForm.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taskType", $$v);
      },
      expression: "searchForm.taskType"
    }
  }, _vm._l(_vm.taskTypeSelectType, function (item) {
    return _c("el-option", {
      key: item.dictCode,
      attrs: {
        label: item.dictValue,
        value: item.dictCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.$formatTypeArray("taskStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间：",
      prop: "createTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.createTime,
      callback: function ($$v) {
        _vm.createTime = $$v;
      },
      expression: "createTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskNo",
      label: "任务编号",
      "min-width": "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row.id);
            }
          }
        }, [_vm._v("\n          " + _vm._s(scope.row.taskNo) + "\n        ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskName",
      label: "任务名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskTypeName",
      label: "任务类型",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "所属商户",
      "min-width": "180",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", [_c("div", [_vm._v(_vm._s(scope.row.merchantName))]), _vm._v(" "), _c("div", [_vm._v("(" + _vm._s(scope.row.merchantNo) + ")")])])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "180",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "startDate",
      label: "任务开始时间",
      "min-width": "120",
      formatter: _vm.$formatDateInTable
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "endDate",
      label: "任务结束时间",
      "min-width": "120",
      formatter: _vm.$formatDateInTable
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "pricingModeName",
      label: "任务计价方式",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "任务金额(元)",
      "min-width": "170"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticStyle: {
            display: "flex"
          }
        }, [_c("div", [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(scope.row.singleAmount)) + "\n          ")]), _vm._v(" "), scope.row.singleMaxAmount > 0 ? _c("div", [_vm._v("\n            -\n            " + _vm._s(_vm.$fmoneyWithSymbol(scope.row.singleMaxAmount)) + "\n          ")]) : _vm._e()])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "任务状态",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("taskStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      "min-width": "190",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.updateTaskVisible && scope.row.status !== 4 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.gotoUpdate(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.resubmitTaskVisible && scope.row.status === 9 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.gotoResubmit(scope.row);
            }
          }
        }, [_vm._v("重新提交")]) : _vm._e(), _vm._v(" "), _vm.reviewTaskVisible && scope.row.status === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleReview(scope.row.id);
            }
          }
        }, [_vm._v("立即审核")]) : _vm._e(), _vm._v(" "), _vm.checkReviewTaskVisible && scope.row.status === 5 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheckReview(scope.row.id);
            }
          }
        }, [_vm._v("立即复核")]) : _vm._e(), _vm._v(" "), _vm.cancelTaskVisible && (scope.row.status === 0 || scope.row.status === 5 || scope.row.status === 9) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCancel(scope.row);
            }
          }
        }, [_vm._v("取消")]) : _vm._e(), _vm._v(" "), _vm.finishTaskVisible && (scope.row.status === 1 || scope.row.status === 2) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleFinish(scope.row);
            }
          }
        }, [_vm._v("结束")]) : _vm._e(), _vm._v(" "), _vm.suspendTaskVisible && scope.row.status === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleSuspend(scope.row);
            }
          }
        }, [_vm._v("暂停")]) : _vm._e(), _vm._v(" "), _vm.upTaskVisible && scope.row.status === 2 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handlePrefer(scope.row);
            }
          }
        }, [_vm._v("上架")]) : _vm._e(), _vm._v(" "), _vm.batchSavePath && scope.row.status === 1 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.gotoBatchSavePath(scope.row);
            }
          }
        }, [_vm._v("批量提交")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };