var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "企业-人员记录"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "查询关键字："
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      clearable: "",
      placeholder: "请输入姓名/手机号/身份证号/银行卡号"
    },
    model: {
      value: _vm.searchForm.keyWords,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "keyWords", $$v);
      },
      expression: "searchForm.keyWords"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "企业编号："
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入企业编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "甲方单位名称："
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入甲方单位名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantName", $$v);
      },
      expression: "searchForm.merchantName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "认证方式："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择认证方式",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.authType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "authType", $$v);
      },
      expression: "searchForm.authType"
    }
  }, _vm._l(_vm.authTypes, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "认证状态："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择认证状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.empStatuses, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "半身照状态："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择半身照状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.halfBodyStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "halfBodyStatus", $$v);
      },
      expression: "searchForm.halfBodyStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("halfBodyStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证照片："
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择身份证照片",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.idcardPicStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "idcardPicStatus", $$v);
      },
      expression: "searchForm.idcardPicStatus"
    }
  }, _vm._l(_vm.$formatTypeArray("isHasNo"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "最后更新时间："
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.updateTimePicker,
      callback: function ($$v) {
        _vm.updateTimePicker = $$v;
      },
      expression: "updateTimePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empNo",
      label: "编号",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empName",
      label: "姓名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "sex",
      label: "性别",
      formatter: _vm.formatEmpSex,
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "签约账号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "reservePhone",
      label: "手机号码",
      width: "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "企业编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "authType",
      label: "认证方式",
      formatter: _vm.formatAuthTypes,
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "认证状态",
      formatter: _vm.formatEmpStatus,
      width: "90"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcardPicStatus",
      label: "身份证照片",
      formatter: _vm.$formatTypeInTable("isHasNo"),
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "halfBodyStatus",
      label: "半身照状态",
      "min-width": "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.halfBodyStatus === 2 ? _c("el-popover", {
          attrs: {
            trigger: "hover",
            placement: "top"
          }
        }, [_c("p", [_vm._v("失败原因: " + _vm._s(scope.row.halfBodyMsg))]), _vm._v(" "), _c("div", {
          staticClass: "name-wrapper",
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_c("el-tag", {
          attrs: {
            size: "medium"
          }
        }, [_vm._v(_vm._s(_vm.$formatType("halfBodyStatus", scope.row.halfBodyStatus)))])], 1)]) : _c("div", [_vm._v("\n            " + _vm._s(_vm.$formatType("halfBodyStatus", scope.row.halfBodyStatus)) + "\n          ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "authMessage",
      label: "认证描述",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "commonAddress",
      label: "常驻地址",
      width: "100",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "最后更新时间",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "130",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticClass: "make-class"
        }, [scope.row.status === 9 && _vm.hasUpdatePermission ? _c("el-button", {
          key: 1,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.uploadFilePath && scope.row.idcardPicStatus === 0 || scope.row.halfBodyStatus !== 1 ? _c("el-button", {
          key: 2,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.gotoUploadFile(scope.row);
            }
          }
        }, [_vm._v("上传证件照")]) : _vm._e(), _vm._v(" "), _vm.invitePermission && scope.row.status === 1 && scope.row.canInviteHalfBody === 1 && scope.row.halfBodyStatus === 0 ? _c("el-button", {
          key: 3,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showInviteDialog(scope.row);
            }
          }
        }, [_vm._v("邀请半身照认证")]) : _vm._e(), _vm._v(" "), _vm.downPersonPicPermission && scope.row.status === 1 ? _c("el-button", {
          key: 4,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadPersonPicFile(scope.row);
            }
          }
        }, [_vm._v("下载证件照")]) : _vm._e(), _vm._v(" "), _vm.viewPersonPicPermission && (scope.row.idcardBack || scope.row.idcardFront) ? _c("el-button", {
          key: 5,
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.viewPersonPicFile(scope.row);
            }
          }
        }, [_vm._v("查看证件照")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _vm._v(" "), _c("update-emp-dialog", {
    attrs: {
      updateEmpData: _vm.chooseItem,
      updateDialogVisible: _vm.updateDialogVisible
    },
    on: {
      changeVisibleUpdate: _vm.changeVisibleUpdate
    }
  }), _vm._v(" "), _c("upload-jpg-file-dialog", {
    attrs: {
      dialogFormVisible: _vm.uploadPhotoDialog,
      name: _vm.photoItem.empName,
      empNo: _vm.photoItem.empNo
    },
    on: {
      changeVisibleUploadPhotoDialog: _vm.changeVisibleUploadPhotoDialog
    }
  }), _vm._v(" "), _c("invite-employee-dialog", {
    attrs: {
      dialogFormVisible: _vm.inviteDialog,
      item: _vm.inviteItem
    },
    on: {
      changeVisibleInviteDialog: _vm.changeVisibleInviteDialog
    }
  }), _vm._v(" "), _c("audit-employee-dialog", {
    attrs: {
      dialogFormVisible: _vm.auditDialogVisible,
      item: _vm.auditItem
    },
    on: {
      changeVisibleAuditDialog: _vm.changeVisibleAuditDialog
    }
  }), _vm._v(" "), _c("view-id-card-dialog", {
    attrs: {
      dialogVisible: _vm.viewIdCardDialogVisible,
      idcardBack: _vm.idcardBack,
      idcardFront: _vm.idcardFront,
      halfBody: _vm.halfBody
    },
    on: {
      changeVisibleDialog: _vm.changeVisibleViewIdDialog
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };