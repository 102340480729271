import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import logo from './logo';
export default {
  components: {
    SidebarItem,
    logo
  },
  computed: {
    ...mapGetters(['sidebar', 'permission_routers', 'buttonsMap']),
    isCollapse() {
      return !this.sidebar.opened;
    },
    activeKey() {
      const item = this.$route.matched.filter(one => one.path === this.$route.path)[0];
      const oo = this.inMenu(this.$route.path) ? item.parent.path : this.$route.path;
      return oo;
    }
  },
  methods: {
    inMenu(routePath) {
      let data = false;
      for (var [value] of this.buttonsMap) {
        if (value && typeof value === 'object') {
          for (var [v] of value) {
            if (routePath === v) {
              data = true;
              return data;
            }
          }
        }
      }
      return data;
    }
  }
};