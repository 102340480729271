import request from '@/utils/request';
import axios from 'axios';
import { encrypt } from '@/utils/encryptionAES';
export async function login(username, password, loginCode) {
  let response = {};
  await axios.post(process.env.BASE_API + '/sys/manager/login', {
    username,
    password,
    loginCode
  }).then(response2 => {
    response = response2;
  });
  return response;
}
export async function getCaptcha() {
  let res;
  await axios.get(process.env.BASE_API + '/sys/manager/captcha', {
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/*
export function getInfo(token) {
    return request({
        url: '/user/info',
        method: 'get',
        params: {
            token
        }
    })
}*/

export function logout() {
  return request({
    url: '/sys/manager/logout',
    method: 'post'
  });
}
export function forgetPwd(pwdForm) {
  const {
    username,
    phone,
    verifyCode
  } = pwdForm;
  let {
    newPassword
  } = pwdForm;
  newPassword = encrypt(newPassword);
  return request({
    url: '/sys/manager/forgetPassword',
    method: 'post',
    data: {
      username,
      phone,
      newPassword,
      verifyCode
    }
  });
}

/**
 * 获取用户创建的角色信息*/
export function getRouteList() {
  return request({
    url: '/sys/menu/routeList',
    method: 'get'
  });
}