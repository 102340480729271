var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "客户经理编号",
      prop: "no"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户经理编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.no,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "no", $$v);
      },
      expression: "searchForm.no"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户经理姓名",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户经理姓名",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "name", $$v);
      },
      expression: "searchForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "联系电话",
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入联系电话",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "phone", $$v);
      },
      expression: "searchForm.phone"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasAddPermission ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addForm
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.managers,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "salesmanNo",
      label: "客户经理编号",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "salesmanName",
      label: "客户经理名称",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "salesmanIdcard",
      label: "身份证号",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "salesmanPhone",
      label: "联系电话",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "80",
      formatter: _vm.formatStatus
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _vm.hasUpdatePermission || _vm.hasRegisterPermission || _vm.hasDimissionPermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "180",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasUpdatePermission && (scope.row.status === 1 || scope.row.status === 2) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.updateRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.hasRegisterPermission && scope.row.status === 1 ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.cancelRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("冻结")]) : _vm._e(), _vm._v(" "), _vm.hasRegisterPermission && scope.row.status === 2 ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.cancelRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("激活")]) : _vm._e(), _vm._v(" "), _vm.hasDimissionPermission && (scope.row.status === 1 || scope.row.status === 2) ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.departRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("离职")]) : _vm._e()];
      }
    }], null, false, 2815359228)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _c("add-form", {
    attrs: {
      isVisible: _vm.isAddVisible,
      manager: _vm.curManager
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isAddVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isAddVisible = $event;
      },
      added: _vm.newRecordAdded
    }
  }), _vm._v(" "), _c("c-manager-registration", {
    attrs: {
      isVisible: _vm.isRegisterVisible,
      manager: _vm.curManager
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isRegisterVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isRegisterVisible = $event;
      },
      updated: _vm.recordUpdated
    }
  }), _vm._v(" "), _c("dimission", {
    attrs: {
      isVisible: _vm.isDimissionVisible,
      manager: _vm.curManager
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isDimissionVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isDimissionVisible = $event;
      },
      updated: _vm.recordUpdated
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };