var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      placement: "bottom"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a", {
    staticClass: "link",
    attrs: {
      href: _vm.url,
      target: "_blank"
    }
  }, [_vm._v("转到 Github")])]), _vm._v(" "), _c("el-button", {
    staticClass: "btn-text can-hover",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleClick
    }
  }, [_c("nx-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      name: "github"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };