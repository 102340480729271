var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "离职办理"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "left",
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "交接客户经理",
      prop: "id"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择交接客户经理"
    },
    model: {
      value: _vm.form.id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "id", $$v);
      },
      expression: "form.id"
    }
  }, _vm._l(_vm.managers, function (manager) {
    return _c("el-option", {
      key: manager.id,
      attrs: {
        label: _vm.concatManger(manager),
        value: manager.id
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };