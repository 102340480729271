import request from '@/utils/request';
import download from '@/utils/download';

/**
 * /empTeam/signed/list 班组签约列表
 */
export const empTeamSignedList = data => {
  return request({
    url: '/empTeam/signed/list',
    method: 'post',
    data
  });
};

/**
 * /empTeam/signed/download 导出班组签约列表
 */
export const downloadSigned = params => {
  return download({
    url: '/empTeam/signed/download',
    data: params
  });
};

/**
 * /empTeam/signed/saveContract 保存合同文件
 */
export const saveContract = params => {
  return request({
    url: '/empTeam/signed/saveContract',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/signed/contractList 获取合同列表(传参示例{id:123})
 */
export const contractList = params => {
  return request({
    url: '/empTeam/signed/contractList',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/signed/delContract 删除合同文件(合同记录ID)(传参示例{id:123})
 */
export const delContract = params => {
  return request({
    url: '/empTeam/signed/delContract',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/signed/invoiceInit 班组申请开票初始化(传参示例{id:123})
 */
export const signedInvoiceInit = params => {
  return request({
    url: '/empTeam/signed/invoiceInit',
    method: 'post',
    data: params
  });
};

/**
 * /empTeam/invoice/submit 提交班组开票
 */
export const signedInvoiceSubmit = params => {
  return request({
    url: '/empTeam/invoice/submit',
    method: 'post',
    data: params
  });
};

/**
 *确认班组签约
 */
export const signedConfirm = params => {
  return request({
    url: '/empTeam/signed/confirm',
    method: 'post',
    data: params
  });
};

/**
 *撤销班组签约
 */
export const signedRevoke = params => {
  return request({
    url: '/empTeam/signed/revoke',
    method: 'post',
    data: params
  });
};