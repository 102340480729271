var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "申请开票："
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    staticStyle: {
      "margin-top": "20px",
      width: "70%"
    },
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "剩余可开票金额："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.formatAmount) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票金额：",
      prop: "ticketAmount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入开票金额"
    },
    model: {
      value: _vm.addForm.ticketAmount,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "ticketAmount", $$v);
      },
      expression: "addForm.ticketAmount"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票类型：",
      prop: "invoiceType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.addForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "invoiceType", $$v);
      },
      expression: "addForm.invoiceType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("增值税普通发票")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("增值税专用发票")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票内容：",
      prop: "contents"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择开票内容"
    },
    on: {
      change: function ($event) {
        return _vm.contentsChange();
      }
    },
    model: {
      value: _vm.addForm.contents,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "contents", $$v);
      },
      expression: "addForm.contents"
    }
  }, _vm._l(_vm.contentList, function (item) {
    return _c("el-option", {
      key: item.contentCode,
      attrs: {
        label: item.contentName,
        value: item.contentName
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票备注：",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: "",
      placeholder: "请输入开票备注描述",
      clearable: ""
    },
    model: {
      value: _vm.addForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "remark", $$v);
      },
      expression: "addForm.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确认")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };