import { saveFile } from '@/api/upload';
import { getAcountInfo } from '@/utils/auth';
import { beforUploadMorType, beforUploadPdfImageBmp } from '../../../utils/util';
export default {
  name: 'uploadMoreFile',
  model: {
    prop: 'fileList',
    event: 'changeFileList' // 自定义方法，用来更新 model
  },
  watch: {
    fileList(val) {
      this.fileList = val;
    }
  },
  data() {
    return {
      myUrl: '',
      partyCode: '',
      saveFile: saveFile,
      myFileName: ''
    };
  },
  props: {
    action: {
      type: String,
      default: saveFile
    },
    limit: {
      required: false,
      default: () => {
        return 30;
      }
    },
    extendData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bizCode: {
      type: String,
      default: ''
    },
    fileList: {
      required: true,
      type: Array
    },
    fileType: {
      required: false,
      default: () => {
        return 1;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.extendData.bizCode = this.bizCode;
    this.partyCode = getAcountInfo().partyCode;
  },
  mounted() {
    this.partyCode = this.bizCode || this.partyCode;
  },
  methods: {
    beforeDemoUpload(file) {
      if (this.fileType === 2) {
        return beforUploadPdfImageBmp(this, file);
      } else {
        return beforUploadMorType(this, file);
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择  ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      this.$emit('changeFileList', fileList);
    },
    handleSuccess(response, file, fileList) {
      const uploadFiles = fileList.filter(item => {
        if (item.status === 'success' && item.response != null && item.response.code === 'BASE.000' || item.status !== 'success') {
          return true;
        } else {
          return false;
        }
      });
      if (response.code !== 'BASE.000') {
        this.$message.error(`${file.name}上传失败`);
      }
      this.$emit('changeFileList', uploadFiles);
    },
    handleError(err, file, fileList) {
      this.$message.error(`${file.name}上传失败`);
    }
  }
};