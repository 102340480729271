var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "segement-border"
  }, [_c("item-title", {
    attrs: {
      name: "批量付款"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    staticClass: "form-table",
    attrs: {
      model: _vm.addForm,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left",
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "27%"
    },
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择请选择劳务公司"
    },
    on: {
      change: _vm.getMerchantList
    },
    model: {
      value: _vm.addForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "taxNo", $$v);
      },
      expression: "addForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属甲方单位：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "27%"
    },
    attrs: {
      placeholder: "请选择所属甲方单位",
      clearable: "",
      filterable: "",
      disabled: !_vm.addForm.taxNo || _vm.merchantList.length === 0
    },
    on: {
      change: _vm.merchantSelectChange
    },
    model: {
      value: _vm.addForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "merchantNo", $$v);
      },
      expression: "addForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属项目：",
      prop: "projectNo"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "27%"
    },
    attrs: {
      placeholder: "请选择所属项目",
      clearable: "",
      filterable: "",
      disabled: !_vm.addForm.merchantNo || _vm.projectList.length === 0
    },
    model: {
      value: _vm.addForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "projectNo", $$v);
      },
      expression: "addForm.projectNo"
    }
  }, _vm._l(_vm.projectList, function (item) {
    return _c("el-option", {
      key: item.projectNo,
      attrs: {
        label: _vm.concatProject(item),
        value: item.projectNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "付款通道：",
      prop: "channelId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "27%"
    },
    attrs: {
      placeholder: "请选择付款通道",
      clearable: "",
      filterable: "",
      disabled: !_vm.addForm.merchantNo || _vm.channelList.length === 0
    },
    model: {
      value: _vm.addForm.channelId,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "channelId", $$v);
      },
      expression: "addForm.channelId"
    }
  }, _vm._l(_vm.channelList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: _vm.concatChannel(item),
        value: item.id
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.hasBanckFlow ? _c("el-form-item", {
    staticClass: "upload",
    attrs: {
      required: "",
      label: "上传银行流水"
    }
  }, [_c("el-upload", {
    ref: "bankUpload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadBankFile,
      "before-upload": _vm.beforeBankUpload,
      "on-remove": _vm.handleBankRemove,
      "on-success": _vm.handleBankDataList,
      limit: 1,
      "file-list": _vm.addForm.bankFileList,
      data: _vm.exBankData,
      "auto-upload": true
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")])], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 文件大小不超过10M")])], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "批次描述",
      prop: "repayDesc"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "27%"
    },
    attrs: {
      type: "textarea",
      placeholder: "请输入批次描述",
      maxlength: "100",
      "show-word-limit": ""
    },
    model: {
      value: _vm.addForm.repayDesc,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "repayDesc", $$v);
      },
      expression: "addForm.repayDesc"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "upload",
    attrs: {
      required: "",
      label: "上传交易信息"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadApplyFile,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      "on-success": _vm.handleDataList,
      limit: 1,
      data: _vm.exData,
      "auto-upload": false
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("上传到服务器")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_temp_label",
    attrs: {
      type: "primary",
      href: _vm.downloadUrl,
      target: "_blank",
      download: "批量付款模版"
    }
  }, [_vm._v("批量付款模版下载")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 只能上传已填写好的标准模板文件，数据记录最多不能超过1000条，且文件大小不超过10M")])], 1), _vm._v(" "), _c("preview", {
    attrs: {
      isVisible: _vm.isDialogVisible,
      transactionData: _vm.responseData,
      repayType: _vm.repayType,
      unAuthCnt: _vm.unAuthCnt
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      "on-success": _vm.uploadSuccess,
      "on-error": _vm.uploadError,
      "on-cancel": _vm.uploadCancel
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "space-line"
  }, [_c("item-title", {
    attrs: {
      name: "批量付款指导"
    }
  })], 1), _vm._v(" "), _vm._m(1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "65%",
      title: "重复付款风险提示",
      visible: _vm.idCardRepeatDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.idCardRepeatDialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("\n      本次提交文件存在以下重复付款信息，确认无误点击“继续”，否则点击“取消”，修改文件后重新上传")]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-col", {
    staticStyle: {
      "font-size": "medium"
    },
    attrs: {
      span: 3
    }
  }, [_vm._v("\n        重复信息：\n      ")]), _vm._v(" "), _c("el-col", {
    staticClass: "border-spec",
    attrs: {
      span: 16
    }
  }, [_c("el-scrollbar", [_c("p", {
    staticStyle: {
      "white-space": "pre-line",
      height: "100px",
      overflow: "visible"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.repeatDesc) + "\n          ")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.showUploadDialog();
      }
    }
  }, [_vm._v("继 续")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.idCardDialogCancel();
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-top"
  }, [_c("div", [_vm._v("应相关政策要求，付款时须有完善的证件信息，包括实名认证（含身份证照片）。")]), _vm._v(" "), _c("div", [_vm._v("因此，系统将对证件信息缺失的付款进行拦截，请及时提醒建筑工人/班组长补充证件信息，以免付款失败。对您造成不便，敬请谅解。")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      "font-size": "1.4rem"
    }
  }, [_c("div", [_vm._v("1. 请先下载申请付款信息模板，并按照模板填写表格")]), _vm._v(" "), _c("div", [_vm._v("2. 点击选取文件，选择已填写完并保存的表格进行导入")]), _vm._v(" "), _c("div", [_vm._v("3. 选择已填写完并保存的表格进行导入")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#FF4C4C"
    }
  }, [_vm._v("\n        注：若建筑工人未完成在线签约，给该建筑工人发放服务费可能存在资金风险，为了您甲方单位的安全稳定发展，请敦促建筑工人进行在线签约后再对其进行发放操作。\n      ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };