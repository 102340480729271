var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "已签约劳务公司"
    }
  }), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.signConfigList,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolStartShow",
      label: "协议开始日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "protocolEndShow",
      label: "协议终止日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "billMode",
      label: "扣费模式",
      width: "110",
      formatter: _vm.$formatTypeInTable("chargeType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "80",
      formatter: _vm.$formatTypeInTable("taxMerchantSignStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "签约费率",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.baseRate * 100) + "%")])];
      }
    }])
  })], 1), _vm._v(" "), _c("rate-info-dialog", {
    attrs: {
      dialogFormVisible: _vm.dialogFormVisible,
      rateInfo: _vm.rateInfo
    },
    on: {
      changeVisible: _vm.changeVisible2
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };