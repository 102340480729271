import { render, staticRenderFns } from "./updateBankCard.vue?vue&type=template&id=bc9f9d42&scoped=true"
import script from "./updateBankCard.vue?vue&type=script&lang=js"
export * from "./updateBankCard.vue?vue&type=script&lang=js"
import style0 from "./updateBankCard.vue?vue&type=style&index=0&id=bc9f9d42&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc9f9d42",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bc9f9d42')) {
      api.createRecord('bc9f9d42', component.options)
    } else {
      api.reload('bc9f9d42', component.options)
    }
    module.hot.accept("./updateBankCard.vue?vue&type=template&id=bc9f9d42&scoped=true", function () {
      api.rerender('bc9f9d42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/merchant/components/update/updateBankCard.vue"
export default component.exports