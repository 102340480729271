var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值订单信息"
    }
  }), _vm._v(" "), _c("el-form", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      model: _vm.view,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.taxName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.merchantName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目子编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.projectNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.projectName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值金额(元)："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.view.rechargeAmount)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行名称："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.bankName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行账户："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.accountNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.view.createTime))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "充值凭证查看"
    }
  }), _vm._v(" "), _vm._l(_vm.view.voucherFileList, function (item, index) {
    return _c("el-image", {
      key: index,
      staticStyle: {
        width: "300px",
        height: "200px"
      },
      attrs: {
        src: item.fileUrlShow,
        "preview-src-list": [item.fileUrlShow]
      }
    });
  })], 2), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "处理意见"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "reviewForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.reviewForm,
      rules: _vm.rules,
      size: "mini",
      "label-position": "left",
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款流水号",
      prop: "bankSerialNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入收款流水号（审核通过必填）",
      maxlength: "30"
    },
    model: {
      value: _vm.reviewForm.bankSerialNo,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "bankSerialNo", $$v);
      },
      expression: "reviewForm.bankSerialNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "复核意见",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入批次复核意见，最多输入80个字",
      maxlength: "80",
      "show-word-limit": "",
      autosize: {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.reviewForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "remark", $$v);
      },
      expression: "reviewForm.remark"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "operation"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.pass
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.reject
    }
  }, [_vm._v("拒绝")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "#E7AB29",
      color: "white"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("暂不复核")])], 1)], 1)], 1), _vm._v(" "), _vm.view.logList && _vm.view.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.view.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "35%",
      title: "审核密码",
      visible: _vm.passDialogVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.passDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    staticClass: "login-forget-form",
    attrs: {
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "请输入校验密码",
      "label-width": "100px"
    }
  }, [_c("el-input", {
    attrs: {
      "disguising-password": "",
      autoComplete: "new-password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.rechargeUpdate
    }
  }, [_vm._v("确认")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.passDialogVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };