import { registerCustomerManagerRequest, deregisterCustomerManagerRequest } from '@/api/customerManager';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'c-manager-registration',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    manager: {}
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    registration: function () {
      return this.manager.status === 2;
    },
    title: function () {
      return this.registration === true ? '激活' : '冻结';
    },
    message: function () {
      return this.registration === true ? `是否确认激活客户经理${this.manager.salesmanName}？` : `是否确认冻结客户经理${this.manager.salesmanName}？`;
    }
  },
  methods: {
    submitForm() {
      this.dialogVisible = false;
      if (this.registration === true) {
        this.registerCustomerManager();
      } else {
        this.deregisterCustomerManager();
      }
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    registerCustomerManager() {
      registerCustomerManagerRequest({
        id: this.manager.id
      }).then(response => {
        this.$message.success('客户经理' + this.manager.salesmanName + '已激活');
        this.$emit('updated');
      });
    },
    deregisterCustomerManager() {
      deregisterCustomerManagerRequest({
        id: this.manager.id
      }).then(response => {
        this.$message.success('客户经理' + this.manager.salesmanName + '已冻结');
        this.$emit('updated');
      });
    }
  }
};