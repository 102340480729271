var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "提现记录"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "客户编号",
      prop: "customerNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerNo", $$v);
      },
      expression: "searchForm.customerNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户名称",
      prop: "customerName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerName", $$v);
      },
      expression: "searchForm.customerName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "业务订单号",
      prop: "businessNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入业务订单号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.businessNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "businessNo", $$v);
      },
      expression: "searchForm.businessNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "是否上传发票",
      prop: "invoiceFlag"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.invoiceFlag,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceFlag", $$v);
      },
      expression: "searchForm.invoiceFlag"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "是否上传回单",
      prop: "receiptFlag"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.receiptFlag,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "receiptFlag", $$v);
      },
      expression: "searchForm.receiptFlag"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "提现时间",
      prop: "date",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "30px 0",
      flex: "1"
    },
    attrs: {
      data: _vm.transactions,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "业务订单号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "withdrawTime",
      label: "提现时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentNo",
      label: "客户编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentName",
      label: "客户名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "收款户名",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "收款账户",
      "min-width": "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "账户类型",
      "min-width": "100",
      formatter: _vm.formatAccountType
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "withdrawAmount",
      label: "提现金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "withdrawStatus",
      label: "状态",
      "min-width": "100",
      formatter: _vm.formatStatus
    }
  }), _vm._v(" "), _vm.hasDetailPermission || _vm.hasPayPermission || _vm.hasCheckPermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "200",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasDetailPermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.checkRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.hasCheckPermission && scope.row.withdrawStatus === 0 ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.reviewRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e(), _vm._v(" "), _vm.hasCheckPermission && scope.row.invoiceUrl === "" && (scope.row.withdrawStatus === 1 || scope.row.withdrawStatus === 2) ? _c("el-button", {
          staticStyle: {
            color: "green"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showUploadInvoiceDialog(scope.row);
            }
          }
        }, [_vm._v("上传发票")]) : _vm._e(), _vm._v(" "), _vm.hasCheckPermission && scope.row.receiptUrl === "" && (scope.row.withdrawStatus === 1 || scope.row.withdrawStatus === 2) ? _c("el-button", {
          staticStyle: {
            color: "green"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showUploadReceptDialog(scope.row);
            }
          }
        }, [_vm._v("上传回单")]) : _vm._e()];
      }
    }], null, false, 3661895143)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("upload-invoice-dialog", {
    attrs: {
      dialogFormVisible: _vm.uploadInvoiceDialogVisible,
      id: _vm.invoiceId
    },
    on: {
      changeInvoiceVisible: _vm.changeInvoiceVisible
    }
  }), _vm._v(" "), _c("upload-recept-dialog", {
    attrs: {
      dialogFormVisible: _vm.uploadReceptDialogVisible,
      id: _vm.receptId
    },
    on: {
      changeReceptVisible: _vm.changeReceptVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };