import { restAppKey, updateAppInfo, generateKeys } from '@/api/safe';
import { getAcountInfo } from '@/utils/auth';
import { getAlgorithmTypes } from '../../../api/safe';
export default {
  name: 'updateAppInfo',
  data() {
    return {
      generateForm: {
        genPublicKey: '',
        genPrivateKey: '',
        algorithmType: ''
      },
      resetDialog: false,
      generateDialog: false,
      isMerchant: false,
      // 判断是否是企业
      algorithmTypes: null,
      // 获取加密算法类型及对应平台公钥
      rules: {
        publicKey: [{
          required: true,
          message: '请输入企业公钥',
          trigger: 'blur'
        }, {
          max: 500,
          message: '最多输入500个字符',
          trigger: 'blur'
        }],
        callBackUrl: [{
          max: 200,
          message: '最多输入200个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    saveForm(val) {
      this.saveForm = val;
      console.log('=======' + val);
    }
  },
  props: {
    saveForm: {},
    updateAppInfo: false,
    resetAppInfoSign: false
  },
  created() {
    const partyType = getAcountInfo().partyType;
    if (partyType === 3) {
      this.isMerchant = true;
    }
    getAlgorithmTypes().then(response => {
      this.algorithmTypes = response.data;
    });
  },
  methods: {
    showResetDialog() {
      this.resetDialog = true;
    },
    restAppKey() {
      restAppKey().then(res => {
        this.resetDialog = false;
        this.$message({
          message: '重置成功',
          type: 'success'
        });
        this.saveForm.appKey = res.data;
      });
    },
    generateKeys() {
      // const abc = this.algorithmTypes.entries()
      //
      // console.log(this.algorithmTypes.entries().get(this.saveForm.algorithmType))
      this.generateDialog = true;
      generateKeys(this.saveForm.algorithmType).then(res => {
        this.generateForm.algorithmType = this.saveForm.algorithmType;
        this.generateForm.genPrivateKey = res.data.privateKey;
        this.generateForm.genPublicKey = res.data.publicKey;
      });
    },
    setGenPublicKey() {
      this.generateDialog = false;
      this.saveForm.publicKey = this.generateForm.genPublicKey;
    },
    update() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateAppInfo(this.saveForm).then(() => {
            this.$message.success('修改成功。。。。');
          });
        } else {
          return false;
        }
      });
    },
    getAlgorithmTypePUBKey() {
      if (this.saveForm.algorithmType === 'SM2') {
        this.saveForm.publicKey1 = this.algorithmTypes.SM2;
      } else if (this.saveForm.algorithmType === 'RSA2') {
        this.saveForm.publicKey1 = this.algorithmTypes.RSA2;
      } else {
        this.saveForm.publicKey1 = null;
      }
      this.saveForm.publicKey = null;
    }
  }
};