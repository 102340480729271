import MerchantEmpSignList from './signList';
import { EMPLOYEE_NO } from '@/utils/idName';
export default {
  name: 'merchantEmpSignListInfo',
  components: {
    MerchantEmpSignList
  },
  data() {
    return {
      empNo: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const empNo = window.sessionStorage.getItem(EMPLOYEE_NO);
      vm.empNo = empNo;
      vm.$refs.child.getListByChild(empNo);
    });
  },
  created() {}
};