var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "aside-container"
  }, [_c("div", {
    staticClass: "option-container"
  }, [_vm.addMenuVisible ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("新增")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "tree-container"
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.treeData,
      props: _vm.defaultProps,
      "highlight-current": true,
      "node-key": "id",
      "default-expand-all": ""
    },
    on: {
      "node-click": _vm.handleNodeClick
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };