import { getSysRole } from '@/api/sys';
import { PARTYROLE_INFO_ID } from '@/utils/idName';
export default {
  name: 'view-partyrole',
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        roleType: '',
        roleId: '',
        partyType: ''
      },
      partyTypeArr: this.$formatTypeArray('partyType'),
      roleTypeArr: this.$formatTypeArray('roleType'),
      roleArr: []
    };
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      this.handleInit();
      const infoJson = sessionStorage.getItem(PARTYROLE_INFO_ID);
      if (infoJson) {
        this.editForm = JSON.parse(infoJson);
      }
    },
    back() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    handleInit() {
      getSysRole().then(res => {
        this.roleArr = res.data;
      });
    }
  },
  mounted() {}
};