import { signedInvoiceInit, signedInvoiceSubmit } from '@/api/teamSignRecord';
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { uploadSettlement } from '@/api/upload';
import { isMoneyNumber } from '@/utils/validate_rules';
export default {
  props: {
    visiable: Boolean,
    projectNo: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {
    UploadMoreFile
  },
  data() {
    return {
      uploadSettlement,
      formLabelWidth: '90px',
      initData: {
        serialNo: '',
        contentList: [],
        invoiceTypeList: []
      },
      invoiceHeaderVisible: false,
      settlementList: [],
      form: {
        "invoiceAmount": '',
        // 开票金额
        "invoiceContent": "",
        // 开票内容
        "invoiceType": '',
        // 发票类型(1-增值税普票(1%)，2-增值税专票(1%)，3-增值税普票(3%)，4-增值税专票(3%))
        "remark": "",
        "serialNo": "",
        // 开票记录编号
        "settlementUrl": "" // 结算单OSSKey(调用文件上传后返回的saveKey)
        // "signId": 0, // 签约记录主键ID
      },
      extendData: {},
      rules: {
        invoiceAmount: [{
          required: true,
          message: '请输入开票金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        invoiceContent: [{
          required: true,
          message: '请选择开票类目',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get() {
        return this.visiable;
      },
      set(value) {
        this.$emit('update:visiable', value);
      }
    },
    title() {
      return '开票申请';
    }
  },
  methods: {
    opened() {
      this.signedInvoiceInitAPI();
    },
    signedInvoiceInitAPI() {
      signedInvoiceInit({
        id: this.formData.id
      }).then(res => {
        console.log('res: ', res);
        this.initData = res.data;
        this.extendData.signId = this.initData.signId;
        this.extendData.serialNo = this.initData.serialNo;
      });
    },
    onClose() {
      this.handleClose();
      this.dialogFormVisible = false;
    },
    handleClose(done) {
      this.settlementList = [];
      this.$refs['ruleForm'].resetFields();
      done && done();
    },
    onSure() {
      // this.settlementList.forEach(item => {
      //   if (!this.form.agreementUrls) {
      //     this.form.agreementUrls = []
      //   }
      //   if (item.response && item.response.data.saveKey) {
      //     this.form.agreementUrls.push(item.response.data.saveKey)
      //   }
      // })
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.signedInvoiceSubmitAPI();
        } else {
          return false;
        }
      });
    },
    signedInvoiceSubmitAPI() {
      const signId = this.formData.id;
      let settlementUrl = '';
      if (this.settlementList.length) {
        settlementUrl = this.settlementList[0].response.data.saveKey;
      }
      const serialNo = this.initData.serialNo;
      signedInvoiceSubmit({
        ...this.form,
        signId,
        serialNo,
        settlementUrl
      }).then(() => {
        this.modalToast();
      });
    },
    async modalToast(data) {
      this.$confirm('提交成功！待班组长在小程序确认开票申请！', '提示', {
        confirmButtonText: '知道了',
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.settlementList = [];
        this.$refs['ruleForm'].resetFields();
        this.dialogFormVisible = false;
      }).catch(() => {
        this.settlementList = [];
        this.$refs['ruleForm'].resetFields();
        this.dialogFormVisible = false;
      });
    },
    onDetail() {
      this.invoiceHeaderVisible = true;
    }
  }
};