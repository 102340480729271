import { render, staticRenderFns } from "./dialogDeleteReview.vue?vue&type=template&id=74720849"
import script from "./dialogDeleteReview.vue?vue&type=script&lang=js"
export * from "./dialogDeleteReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74720849')) {
      api.createRecord('74720849', component.options)
    } else {
      api.reload('74720849', component.options)
    }
    module.hot.accept("./dialogDeleteReview.vue?vue&type=template&id=74720849", function () {
      api.rerender('74720849', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/transaction/components/dialogDeleteReview.vue"
export default component.exports