import ItemTitle from '@/base/ui/title/itemTitle';
import BatchFiles from './batchFiles';
export default {
  name: 'batchTaskFiles',
  components: {
    BatchFiles,
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false
    };
  },
  props: {
    dialogVisible: false
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  methods: {
    dialogClosed() {
      this.myDialogFormVisible = false;
    }
  }
};