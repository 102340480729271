var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "60%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("item-title", {
    attrs: {
      name: "上传履约确认函"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base save-base-letter",
    attrs: {
      model: _vm.saveForm,
      "label-width": "140px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticClass: "upload",
    attrs: {
      label: "履约确认函"
    }
  }, [_c("div", {
    staticStyle: {
      display: "-webkit-box"
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 10,
      fileType: 2
    },
    model: {
      value: _vm.saveForm.fileList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "fileList", $$v);
      },
      expression: "saveForm.fileList"
    }
  }), _vm._v(" "), _c("el-button", {
    staticStyle: {
      left: "130px",
      position: "absolute"
    },
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("上传至服务器")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    staticStyle: {
      "font-size": "1.4rem",
      color: "#FF4C4C"
    }
  }, [_vm._v("操作须知：")]), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    staticStyle: {
      "font-size": "1.4rem",
      color: "#FF4C4C",
      "margin-left": "20px"
    }
  }, [_c("div", [_vm._v("1.履约确认函务必已盖公章;")]), _vm._v(" "), _c("div", [_vm._v("2.上传的文件须是PDF 或 图片格式（PNG、JPG、JEPG、BMP）;")]), _vm._v(" "), _c("div", [_vm._v("3.最多上传10份文件，每份文件不超过8M。")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };