var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("item-title", {
    attrs: {
      name: _vm.name
    }
  }), _vm._v(" "), _vm.isUpdate && _vm.isShowBottom ? _c("div", {
    staticClass: "free-product-icon"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        return _vm.addProduct();
      }
    }
  }, [_vm._v("增加行")]), _vm._v("   \n      "), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      icon: "el-icon-remove"
    },
    on: {
      click: function ($event) {
        return _vm.removeProduct();
      }
    }
  }, [_vm._v("删除行")])], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("free-item", _vm._b({
      key: "product0" + index,
      ref: "freeItem",
      refInFor: true,
      attrs: {
        item: item,
        index: index,
        text: _vm.text,
        typeName: _vm.typeName,
        isUpdate: _vm.isUpdate
      },
      on: {
        checkFreeItem: _vm.checkFreeItem,
        endChange: _vm.endChange
      }
    }, "free-item", _vm.$props, false));
  }), 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };