import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { getAgentRechargeCharts } from '../../../../api/stat';
export default {
  name: 'saleManCharts',
  components: {
    ItemTitle
  },
  data() {
    const xAxis = [{
      axisLabel: {
        show: true,
        rotate: 1,
        textStyle: {
          fontSize: 12
        }
      }
    }];
    const chartSettings = {
      itemStyle: {
        color: '#2DA8FF'
      },
      labelMap: {
        'statTotal': '金额'
      },
      legendName: {
        'statTotal': '金额'
      },
      dataOrder: false
    };
    return {
      chartData: {
        columns: ['agentName', 'statTotal'],
        rows: []
      },
      chartSettings: chartSettings,
      taxSources: [],
      xAxis: xAxis,
      legend: {
        show: false
      }
    };
  },
  props: {
    searchForm: {
      required: true,
      default: () => {
        return {
          area: '',
          beginTime: '',
          endTime: '',
          taxNo: ''
        };
      }
    }
  },
  watch: {
    searchForm(val) {
      this.getList();
    }
  },
  created() {
    this.getTaxSources();
    this.getList();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList() {
      getAgentRechargeCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    }
  }
};