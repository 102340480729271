import AddAndEditAgreement from './components/dialog/addAndEditAgreement.vue';
import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { merchantList, projectFreeze, projectUnfreeze, audit, downloadMerchant, mchntProjectDownload, merchantBelongMchntList, getInviteUrlById } from '@/api/merchant';
import { showlist } from '@/api/taxsource';
import { mapGetters } from 'vuex';
import { MERCHANT_UPDATE_ID, MERCHANT_INFO_ID, PROJECT_NO } from '@/utils/idName';
import { timePickerOptions, downloadExcel } from '@/utils/util';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  components: {
    CityCascader,
    Pagination,
    DbDatePicker,
    AddAndEditAgreement
  },
  name: 'merchantList',
  data() {
    return {
      auditDialogVisible: false,
      dialogInviteVisible: false,
      auditMerchantNo: '',
      total: 0,
      updateMerchant: '',
      viewMerchant: '',
      addMerchant: '',
      addAgreementPer: '',
      viewInvitePer: '',
      hasForzenPermission: false,
      auditMerchant: '',
      list: [],
      datePicker: [],
      listLoading: true,
      taxStatus: this.$formatTypeArray('projectStatusType'),
      taxsourceList: [],
      merchantNos: [],
      updateStatus: {
        id: '',
        status: ''
      },
      inviteUrl: '',
      pageNo: 1,
      pageSize: 10,
      searchForm: {
        beginTime: '',
        endTime: '',
        merchantNo: '',
        projectName: '',
        parentProjectNo: '',
        projectNo: '',
        status: '',
        //项目状态：0-待审核(暂未使用)，1-进行中，2-已完成，9-已取消
        agentNo: '',
        taxNo: ''
      },
      currentSearch: {},
      isFresh: false,
      hasDownloadPermission: false,
      dialogVisiable: false,
      activeRow: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateMerchant && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addMerchant = map.get('addMerchant');
      this.viewMerchant = map.get('viewMerchant');
      this.updateMerchant = map.get('updateMerchant');
      this.auditMerchant = map.get('auditMerchant');
      this.addAgreementPer = map.get('addAgreement');
      this.viewInvitePer = map.get('viewInvite');
      this.hasForzenPermission = map.has('forzenProject');
      this.hasDownloadPermission = map.has('downloadMerchant');
    }
    const that = this;
    showlist().then(response => {
      that.taxsourceList = response.data;
      // 为接口添加系统平台参数
      // that.taxsourceList.push({
      //   taxName: '系统平台',
      //   taxNo: 'SYSTEM'
      // })
    });
  },
  methods: {
    async onSelectTaxNo(value) {
      this.merchantNos = [];
      const params = {
        pageNo: 0,
        pageSize: 300,
        taxNo: value
      };
      let res = await merchantBelongMchntList(params);
      this.merchantNos = res.data || [];
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatProject(project) {
      return `${project.projectName}(${project.projectNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      this.currentSearch.pageNo = this.pageNo;
      this.currentSearch.pageSize = this.pageSize;
      merchantList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getStatus(status) {
      if (status === 1) {
        return '冻结';
      } else if (status === 9) {
        return '解冻';
      }
    },
    clearQuery() {
      // this.searchForm = {
      //   beginTime: '',
      //   endTime: '',
      //   merchantName: '',
      //   merchantNo: '',
      //   status: ''
      // }
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.queryForm();
    },
    handleAdd() {
      this.$router.push({
        path: this.addMerchant
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(PROJECT_NO, row.projectNo);
      this.clickUpdate = true;
      this.$router.push({
        path: this.updateMerchant
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(PROJECT_NO, row.projectNo);
      this.$router.push({
        path: this.viewMerchant
      });
    },
    addAgreement(index, row) {
      console.log('添加合同', row);
      this.activeRow = row;
      this.dialogVisiable = true;
    },
    viewInvite(index, row) {
      const id = row.id;
      getInviteUrlById(id).then(res => {
        this.inviteUrl = res.data;
        this.dialogInviteVisible = true;
      }).catch(() => {});
    },
    formatCity() {
      if (this.searchForm.provinceCode === '') {
        return '';
      } else {
        return this.searchForm.provinceCode + '/' + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('projectStatusType', row.status);
    },
    unfreeze(row) {
      this.$confirm('是否确定解冻该企业?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        projectUnfreeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    freeze(row) {
      this.$confirm('是否确定解冻该企业?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        projectFreeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    showAuditDialog(row) {
      this.auditMerchantNo = row.merchantNo;
      this.auditDialogVisible = true;
    },
    audit(status) {
      this.auditDialogVisible = false;
      audit(this.auditMerchantNo, status).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.getList();
      });
    },
    downloadForm() {
      mchntProjectDownload(this.currentSearch).then(response => {
        downloadExcel(response.data, '项目列表.xls');
      });
    }
  }
};