import { uploadImg, saveFile } from '@/api/upload';
import ItemTitle from '@/base/ui/title/itemTitle';
import { updateLetterUrl } from '../../../api/invoice';
import { getAcountInfo } from '../../../utils/auth';
// eslint-disable-next-line no-unused-vars
import { isZip } from '../../../utils/util';
import UploadMoreFile from '../../../base/ui/upload/uploadMoreFile';
export default {
  name: 'uploadLetterDialog',
  components: {
    UploadMoreFile,
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      saveFile: saveFile,
      filename: '',
      fileList: [],
      saveForm: {
        fileList: []
      },
      rules: {
        fileList: [{
          required: true,
          message: '请上传履约函',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    uploadDialogVisible: false,
    id: {
      required: true
    }
  },
  watch: {
    uploadDialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeUploadDialogVisible', val);
    }
  },
  // computed: {
  //   letterUrl: function() {
  //     if (this.saveForm.letterUrl) {
  //       return uploadImg + this.saveForm.letterUrl
  //     }
  //     return ''
  //   }
  // },
  created() {
    this.partyCode = getAcountInfo().partyCode;
  },
  methods: {
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    // handleRemove() {
    //   this.saveForm.letterUrl = ''
    //   this.filename = ''
    // },
    // isZip(file) {
    //   isZip(file)
    // },
    beforeAvatarUpload(file) {
      // const isPDF = (file.type === 'application/pdf' || file.type === 'application/doc')
      const isPDF = file.type === 'application/pdf';
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isdoc = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const isZip2 = isZip(file);

      // debugger
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isPDF && !isJPG && !isdoc && !isZip2) {
        this.$message.error('上传文件只支持PDF、jpg、png、word、zip格式!');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      }
      return true;
      // return beforeUploadPdf(this, file)
    },
    // submitUpload() {
    //   this.$refs.upload.submit()
    // },
    submitUpload(val) {
      const list = this.saveForm.fileList;
      if (list.length === 0) {
        this.$message.error('请选择上传文件。');
        return;
      }
      const letterUrlList = [];
      list.forEach(item => {
        letterUrlList.push(item.response.data.saveKey);
      });
      updateLetterUrl({
        id: this.id,
        letterUrlList: letterUrlList
      }).then(() => {
        this.clearData();
        this.$message.success('上传成功');
        this.myDialogFormVisible = false;
      });
    },
    clearData() {
      this.myDialogFormVisible = false;
      this.saveForm = {};
      this.fileList = [];
    }
  }
};