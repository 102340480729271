import { repayBatchDeleteCheck } from '@/api/taxMerchant';
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        status: '',
        remark: ''
      },
      rules: {
        status: [{
          required: true,
          message: '请选择复核意见',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入复核备注',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.repayBatchDeleteCheckAPI();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('refresh');
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.resetForm('form');
    },
    async repayBatchDeleteCheckAPI() {
      let res = await repayBatchDeleteCheck({
        id: this.id,
        status: this.form.status,
        remark: this.form.remark
      });
      this.handleClose();
    }
  }
};