import { mapGetters } from 'vuex';
import BatchPreviewPayment from '../components/batchPreviewPayment';
import ItemTitle from '@/base/ui/title/itemTitle';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'batch-sure',
  components: {
    ItemTitle,
    BatchPreviewPayment
  },
  data() {
    return {
      fileList: [],
      uploadApplyFile: BASE_URL + '/trade/confirm/upload',
      responseData: null,
      isDialogVisible: false,
      idCardRepeatDialogVisible: false,
      repeatDesc: ''
    };
  },
  props: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    exData: function () {
      return {};
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    beforeUpload(file) {
      const xls = 'application/vnd.ms-excel';
      const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = file.type;
      const fileName = file.name;
      var isExcel = false;
      if (fileType != null && fileType !== '') {
        isExcel = fileType === xls || fileType === xlsx;
      } else {
        const reg = /^.*\.(xls|xlsx)$/; // 取不到文件type时，文件后缀匹配
        isExcel = reg.test(fileName);
      }
      if (!isExcel) {
        this.$message.error('上传文件只能是 .xls/.xlsx 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt2M && isExcel;
    },
    handleDataList(response, file, fileList) {
      if (response != null && response.code === 'BASE.000') {
        this.responseData = response.data;
        this.unAuthCnt = response.data.unAuthCnt;
        // debugger
        if (response.data.repeatDesc) {
          this.idCardRepeatDialogVisible = true;
          this.repeatDesc = response.data.repeatDesc;
        } else {
          this.isDialogVisible = true;
        }
      } else {
        if (response != null) {
          this.$message.error(response.errMsg);
        } else {
          this.$message.error('上传失败');
        }
        this.handleRemove();
      }
    },
    handleRemove() {
      this.fileList = [];
      this.responseData = null;
    },
    uploadSuccess() {
      this.clearData();
    },
    uploadError() {
      this.clearData();
    },
    uploadCancel() {
      this.clearData();
    },
    clearData() {
      this.$refs['addForm'].resetFields();
      this.fileList = [];
      this.responseData = null;
      this.repeatDesc = '';
    },
    idCardDialogCancel() {
      this.idCardRepeatDialogVisible = false;
      this.clearData();
    }
  }
};