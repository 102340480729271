var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-tabs", {
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: item.label,
        name: item.name
      }
    }, [_c("keep-alive", [_c(item.component, {
      tag: "component"
    })], 1)], 1);
  }), 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };