import { isMoneyNumber } from '@/utils/validate_rules';
import { setValuePayment } from '../../../../api/account';
export default {
  name: 'setValueDialog',
  data() {
    return {
      saveForm: {
        nationwidePayLimit: '',
        taxPayLimit: ''
      },
      myDialogFormVisible: false,
      rules: {
        nationwidePayLimit: [{
          required: true,
          message: '请正确设置全国付款限额预警阀值',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        taxPayLimit: [{
          required: true,
          message: '请正确设置劳务公司付款限额预警阀值',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dialogVisible: false
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  created() {},
  methods: {
    addBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          setValuePayment(this.saveForm).then(() => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.myDialogFormVisible = false;
      this.$refs['saveForm'].resetFields();
      this.$emit('on-cancel');
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    }
  }
};