var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名：",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入姓名",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "custName", $$v);
      },
      expression: "searchForm.custName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxsList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "凭证类型",
      prop: "voucherType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择凭证类型"
    },
    model: {
      value: _vm.searchForm.voucherType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "voucherType", $$v);
      },
      expression: "searchForm.voucherType"
    }
  }, _vm._l(_vm.$formatTypeArray("voucherType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "完税凭证",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择完税凭证"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.$formatTypeArray("taxDjStatus"), function (option) {
    return _c("el-option", {
      key: option.label,
      attrs: {
        label: option.name,
        value: option.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "申报月份：",
      prop: "djMonth",
      clearable: ""
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "请选择申报月份",
      format: "yyyy-MM",
      "value-format": "yyyy-MM",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.djMonth,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "djMonth", $$v);
      },
      expression: "searchForm.djMonth"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.saveTaxWszmdj ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.showSaveDialog
    }
  }, [_vm._v("委托代征登记")]) : _vm._e(), _vm._v(" "), _vm.updateTaxWszmdj ? _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.showUpdateDialog
    }
  }, [_vm._v("修改登记")]) : _vm._e(), _vm._v(" "), _vm.deleteTaxWszmdj ? _c("el-button", {
    attrs: {
      type: "info",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.showDeleteDialog
    }
  }, [_vm._v("数据回滚")]) : _vm._e(), _vm._v(" "), _vm.downloadVisible ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadListData
    }
  }, [_vm._v("导出申报数据")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("付款总额:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalPayAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总申报总额:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalDjAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总增值税:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalZzsAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总城市维护建设税:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalCswhjsAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总教育费附加:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalJyffjAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总地方教育费附加:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalDfjyfjAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总水利建设专项收入:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalSljszxAmount))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "total"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("总个税:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.totalGrsdsAmount))])])])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "姓名",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "手机号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      label: "身份证号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      width: "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "payAmount",
      label: "付款总额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serviceRemark",
      label: "服务类别",
      width: "160",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "voucherType",
      label: "凭证类型",
      width: "160",
      formatter: _vm.$formatTypeInTable("voucherType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "djAmount",
      label: "申报总额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountZzs",
      label: "增值税",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountCswhjs",
      label: "城市维护建设税",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountJyffj",
      label: "教育费附加",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountDfjyfj",
      label: "地方教育附加",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountSljszx",
      label: "水利建设专项收入",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxAmountGrsds",
      label: "个税",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "djMonth",
      label: "申报月份",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "完税平衡",
      width: "120",
      formatter: _vm.$formatTypeInTable("taxDjStatus")
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  })], 1), _vm._v(" "), _c("save-wtdzdj-dialog", {
    attrs: {
      dialogVisible: _vm.saveDialogVisible
    },
    on: {
      changeAddDialogVisible: _vm.changeAddDialogVisible
    }
  }), _vm._v(" "), _c("update-wtdzdj-dialog", {
    attrs: {
      dialogVisible: _vm.updateDialogVisible
    },
    on: {
      changeUpdateDialogVisible: _vm.changeUpdateDialogVisible
    }
  }), _vm._v(" "), _c("delete-wtdzdj-dialog", {
    attrs: {
      dialogVisible: _vm.deleteDialogVisible
    },
    on: {
      changeDeleteDialogVisible: _vm.changeDeleteDialogVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };