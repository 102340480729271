import { MESSAGE_CENTER_INFO_ID } from '../../../../utils/idName';
import { clickRead } from '../../../../api/sys';
export default {
  name: 'msgItem',
  props: {
    item: {
      required: true,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    gotoInfo() {
      this.setRead();
      if (this.item.openType === 1) {
        window.open(this.item.contents);
      } else {
        sessionStorage.setItem(MESSAGE_CENTER_INFO_ID, this.item.id);
        this.$router.push({
          path: '/msgCenter/msgCenterList/msgCenterInfo'
        });
      }
    },
    setRead() {
      clickRead(this.item.id).then(() => {
        this.$emit('freshData');
        this.$store.dispatch('GetNoReadNum');
      });
    }
  }
};