var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "total-data"
  }, [_c("total-data-item", {
    attrs: {
      num: _vm.merchantTotal,
      name: "商户总数量",
      imgIcon: "static/img/first/imgIcon1.png"
    }
  }), _vm._v(" "), _c("total-data-item", {
    attrs: {
      num: _vm.tradeAmountTotal,
      name: "累计交易额",
      imgIcon: "static/img/first/imgIcon2.png",
      unit: "万"
    }
  }), _vm._v(" "), _c("total-data-item", {
    attrs: {
      num: _vm.info.addMerchant30Day,
      name: "新增商户(近30天)",
      imgIcon: "static/img/first/imgIcon3.png"
    }
  }), _vm._v(" "), _c("total-data-item", {
    attrs: {
      num: _vm.info.addMerchantToday,
      name: "今日新增商户数量",
      imgIcon: "static/img/first/imgIcon4.png"
    }
  }), _vm._v(" "), _c("total-data-item", {
    attrs: {
      num: _vm.info.tradeAmountToday,
      name: "今日交易额",
      unit: "元",
      imgIcon: "static/img/first/imgIcon5.png"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };