import ItemTitle from '@/base/ui/title/itemTitle';
import { invoiceRecordPosted } from '../../../api/invoice';
export default {
  name: 'invoice-list-check',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    expressInfo: {
      type: Object
    }
  },
  watch: {
    expressInfo: function (newValue, oldValue) {
      this.addForm.id = newValue != null ? newValue.id : null;
      this.addForm.logistics = newValue != null ? newValue.logistics : null;
      this.addForm.expressNumber = newValue != null ? newValue.expressNumber : null;
    }
  },
  data() {
    return {
      addForm: {
        id: '',
        logistics: '',
        expressNumber: '',
        remark: ''
      },
      rules: {
        logistics: [{
          required: true,
          message: '请输入物流公司',
          trigger: 'blur'
        }],
        expressNumber: [{
          required: true,
          message: '请输入快递单号',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.checkInvoice(2);
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      // this.$refs['addForm'].resetFields()
      this.$refs['addForm'].clearValidate();
    },
    checkInvoice(status) {
      const param = {
        id: this.addForm.id,
        logistics: this.addForm.logistics,
        expressNumber: this.addForm.expressNumber,
        status: status
      };
      invoiceRecordPosted(param).then(() => {
        this.$message.success('更新物流信息成功');
      });
    }
  }
};