import ItemTitle from '../../../base/ui/title/itemTitle';
import { getTaxRouterRechargeInfo } from '../../../api/taxRouter';
import { VIEW_TAXSOURCE_ROUTER_RECHARGE_ID } from '../../../utils/idName';
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      info: {}
    };
  },
  created() {
    const id = sessionStorage.getItem(VIEW_TAXSOURCE_ROUTER_RECHARGE_ID);
    getTaxRouterRechargeInfo(id).then(response => {
      this.info = response.data;
    });
  }
};