export default {
  name: 'merchantRate',
  data() {
    // this.chartSettings = {
    //   label:{
    //     show:true,
    //     textStyle: {
    //       fontSize: 12
    //     },
    //     /* formatter: '{b} : ({c}门) \n {d}%'	*/
    //     formatter: function(params){ //避免文字太长做省略处理
    //
    //       var name=params.name; //名字
    //       var percent=params.percent; //占比
    //       var value=params.value; //数量
    //       if(name.length>8){
    //         return name.slice(0,7)+"..."+"\n"+"("+value+"门)"+percent+"%";
    //       }else{
    //         return name+"\n"+"("+value+"门)"+percent+"%";
    //       }
    //     }
    //   }
    // }
    return {
      chartData: {
        columns: ['gradeName', 'gradeCount'],
        rows: []
      }
    };
  },
  props: {
    list: {
      required: true
    }
  },
  mounted() {
    this.initData();
  },
  watch: {
    list(val) {
      this.initData();
    }
  },
  methods: {
    initData() {
      this.chartData.rows = this.list;
    },
    afterSetOption(chartObj) {
      var self = this;
      chartObj.setOption({
        legend: {
          // data: self.chartData.columns,
          formatter(params) {
            let name = '';
            self.list.forEach(item => {
              if (item.gradeName === params) {
                name = params + ':' + item.gradeRange;
                return;
              }
            });
            return name;
          }
        }
      });
    }
  }
};