export default {
  name: 'CommonHome',
  data() {
    return {};
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
    blackList() {
      const list = ['teamInvoiceDetail', 'invoiceListDetail', 'agentRateList', 'msgCenterListIndex'];
      return list;
    }
  }
};