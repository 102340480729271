var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list.length > 0 ? _c("div", {
    staticClass: "notice-gong-gao",
    on: {
      click: _vm.gotoInfo
    }
  }, [_c("img", {
    attrs: {
      src: "static/img/notice_icon.png"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "gong-gao-text"
  }, [_vm._v(_vm._s(_vm.showItem.title) + " >>")])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };