import CityCascader from '@/base/ui/cascader/cityCascader';
import ItemTitle from '@/base/ui/title/itemTitle';
import UploadMoreFile from '@/base/ui/upload/uploadMoreFile';
import { isInteger, isMoneyNumber } from '@/utils/validate_rules';
import { saveMerchantTask } from '@/api/task';
import DbDatePicker from '../../../base/ui/date/db-date-picker';
import { getPricingModeSelect, getTaskTypeSelect, getTaskReportList } from '../../../api/task';
import { getAcountInfo } from '../../../utils/auth';
import { merchantShowList, taxSelectByMchntNo, isJobOpen, isShowResult } from '../../../api/merchant';
import { VueEditor } from 'vue2-editor';
import { fullToolbar } from '@/utils/editor_toolbar';
export default {
  name: 'addContent',
  components: {
    DbDatePicker,
    UploadMoreFile,
    ItemTitle,
    CityCascader,
    VueEditor
  },
  data() {
    return {
      fileList: [],
      partyType: '',
      merchantList: [],
      addForm: {
        caption: '',
        fileSaveKeys: [],
        taskName: '',
        taskType: '',
        startDate: '',
        endDate: '',
        taxNo: '',
        invitePublic: 0,
        pricingMode: '',
        singleAmount: '',
        singleMaxAmount: '',
        submitMode: 0,
        merchantNo: '',
        captionInfo: '',
        templeteNo: ''
      },
      editorSettings: {
        modules: {
          toolbar: fullToolbar
        },
        theme: 'snow'
      },
      rules: {
        taskName: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }],
        caption: [{
          required: true,
          message: '请输入任务描述',
          trigger: 'blur'
        }, {
          min: 1,
          max: 150,
          message: '最多可输入150个字符',
          trigger: 'blur'
        }],
        selectedAddress: [{
          required: true,
          message: '请选择地区',
          trigger: 'blur'
        }],
        fileSaveKeys: [{
          required: true,
          message: '请上传任务附件',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属商户',
          trigger: 'blur'
        }],
        taskType: [{
          required: true,
          message: '请选择任务类型',
          trigger: 'blur'
        }],
        pricingMode: [{
          required: true,
          message: '请选择任务计价方式',
          trigger: 'blur'
        }],
        time: [{
          required: true,
          message: '请选择任务有效期',
          trigger: 'blur'
        }],
        singleAmount: [{
          required: true,
          message: '请输入任务金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        singleMaxAmount: [{
          required: true,
          message: '请输入任务金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        deviceType: [{
          required: true,
          message: '请输入设备型号',
          trigger: 'blur'
        }],
        itemCycleNum: [{
          required: true,
          message: '请输入租赁时长',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }],
        itemDeviceNum: [{
          required: true,
          message: '请输入需求数量',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }],
        devicePrice: [{
          required: true,
          message: '请输入每台报价',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }],
        unitPrice: [{
          required: true,
          message: '单价不能为空',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }]
      },
      taxSources: [],
      pricingModeType: [],
      taskTypeSelectType: [],
      amountType: 0,
      partyCode: '',
      taskReportSwitch: 0,
      taskTemplateList: []
    };
  },
  created() {
    this.getSelect();
    // this.getJobOpen()
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.partyCode = user.partyCode;
    if (this.partyType === 3) {
      this.addForm.merchantNo = user.partyCode;
      this.getJobOpen();
      this.getTaxSources(this.addForm.merchantNo);
      this.getTaskTypeList(this.addForm.merchantNo);
    } else {
      this.getMerchantList();
    }
    if (this.partyType === 1) {
      this.addForm.taxNo = user.partyCode;
    }
  },
  methods: {
    getTaskReportList() {
      getTaskReportList({}).then(res => {
        this.taskTemplateList = res.data;
      });
    },
    getJobOpen() {
      isShowResult({
        bizNo: this.partyType === 0 || this.partyType === 1 ? this.addForm.merchantNo : this.partyCode
      }).then(response => {
        this.taskReportSwitch = response.data.taskReportSwitch;
        if (this.taskReportSwitch === 1) {
          this.getTaskReportList();
        }
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources(mechantNo) {
      if (mechantNo === '') {
        return;
      }
      taxSelectByMchntNo({
        merchantNo: mechantNo
      }).then(response => {
        this.taxSources = response.data;
      });
    },
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    submitForm() {
      this.addForm.fileSaveKeys = [];
      this.fileList.forEach(item => {
        this.addForm.fileSaveKeys.push(item.response.data.saveKey);
      });
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          if (this.taskReportSwitch === 1 && !this.addForm.templeteNo) {
            this.$message.error('请选择任务模版');
            return;
          }
          saveMerchantTask({
            caption: this.addForm.caption,
            fileSaveKeys: this.addForm.fileSaveKeys,
            taskName: this.addForm.taskName,
            taskType: this.addForm.taskType,
            startDate: this.addForm.time[0],
            endDate: this.addForm.time[1],
            taxNo: this.addForm.taxNo,
            invitePublic: this.addForm.invitePublic,
            pricingMode: this.addForm.pricingMode,
            singleAmount: this.addForm.singleAmount,
            singleMaxAmount: this.addForm.singleMaxAmount,
            merchantNo: this.addForm.merchantNo,
            captionInfo: this.addForm.captionInfo,
            templeteNo: this.addForm.templeteNo
          }).then(() => {
            this.$message.success('提交成功');
            this.goToList();
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    goToList() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.push('/task/taskRecord');
      });
    },
    getSelect() {
      getPricingModeSelect().then(resonse => {
        this.pricingModeType = resonse.data;
      });
    },
    changeAmountType() {
      if (this.amountType === 0) {
        this.amountType = 1;
      } else {
        this.amountType = 0;
      }
    },
    merchantChange(val) {
      this.getJobOpen();
      this.getTaxSources(val);
      this.getTaskTypeList(val);
    },
    getTaskTypeList(val) {
      getTaskTypeSelect(val).then(resonse => {
        this.taskTypeSelectType = resonse.data;
      });
    }
  }
};