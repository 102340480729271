var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "editForm",
    staticClass: "view-role-form border-bg",
    attrs: {
      size: "mini",
      model: _vm.editForm,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      disabled: ""
    },
    model: {
      value: _vm.editForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "name", $$v);
      },
      expression: "editForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.editForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "status", $$v);
      },
      expression: "editForm.status"
    }
  }, [_c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 1
    }
  }, [_vm._v("启用")]), _vm._v(" "), _c("el-radio", {
    staticClass: "radio",
    attrs: {
      label: 0
    }
  }, [_vm._v("禁用")])], 1)], 1), _vm._v(" "), this.userType !== 0 ? _c("el-form-item", {
    attrs: {
      prop: "parentId",
      label: "上级角色"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择",
      disabled: ""
    },
    on: {
      change: _vm.handleMenuTree
    },
    model: {
      value: _vm.editForm.parentId,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "parentId", $$v);
      },
      expression: "editForm.parentId"
    }
  }, _vm._l(_vm.roleArr, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.name,
        value: role.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      type: "textarea",
      disabled: ""
    },
    model: {
      value: _vm.editForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "remark", $$v);
      },
      expression: "editForm.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "授权"
    }
  }, [_c("div", {
    staticStyle: {
      "min-width": "300px",
      "max-width": "480px"
    }
  }, [_c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.treeData,
      "show-checkbox": "",
      "node-key": "id",
      "highlight-current": "",
      "default-expand-all": "",
      props: _vm.defaultProps
    }
  })], 1)]), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };