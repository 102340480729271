import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import { saveImport, viewPageList } from '@/api/operateEmp';
import table from '@/utils/table';
export default {
  name: 'ImportViewDialog',
  components: {
    ItemTitle,
    Pagination
  },
  props: {
    uploadViewFromVisible: false,
    uploadRespData: {
      type: Object
    }
  },
  data() {
    return {
      show: false,
      listLoading: false,
      isLoading: false,
      myUploadViewFromVisible: false,
      param: {
        pageNo: 1,
        pageSize: 10
      },
      employeeList: []
    };
  },
  computed: {
    taxNo: function () {
      return this.uploadRespData != null ? this.uploadRespData.taxNo : '';
    },
    taxName: function () {
      return this.uploadRespData != null ? this.uploadRespData.taxName : '';
    },
    merchantNo: function () {
      return this.uploadRespData != null ? this.uploadRespData.merchantNo : '';
    },
    merchantName: function () {
      return this.uploadRespData != null ? this.uploadRespData.merchantName : '';
    },
    authTypeDesc: function () {
      return this.uploadRespData != null ? this.$formatType('authType', this.uploadRespData.authType) : '';
    },
    totalNum: function () {
      return this.uploadRespData != null ? this.uploadRespData.totalNum : 0;
    }
  },
  watch: {
    uploadRespData: function (newValue, oldValue) {
      this.employeeList = newValue != null ? newValue.pageDataList.list : [];
    },
    uploadViewFromVisible(val) {
      this.myUploadViewFromVisible = val;
    },
    myUploadViewFromVisible(val) {
      this.$emit('changeVisibleUpdateView', val);
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.param.pageNo, this.param.pageSize);
    },
    submitForm() {
      this.employeeListSave();
    },
    cancelForm() {
      this.myUploadViewFromVisible = false;
      this.$emit('on-cancel');
    },
    employeeListSave() {
      this.isLoading = true;
      saveImport({
        merchantNo: this.merchantNo,
        cacheKey: this.uploadRespData.cacheKey
      }).then(response => {
        this.isLoading = false;
        this.myUploadViewFromVisible = false;
        this.employeeList = [];
        this.$emit('on-success', response.data);
      }).catch(() => {
        this.isLoading = false;
        this.myUploadViewFromVisible = false;
        this.$emit('on-error');
      });
    },
    getPageList() {
      this.listLoading = true;
      viewPageList({
        merchantNo: this.merchantNo,
        pageNo: this.param.pageNo,
        pageSize: this.param.pageSize,
        cacheKey: this.uploadRespData.cacheKey
      }).then(response => {
        this.listLoading = false;
        this.employeeList = response.data.pageDataList.list;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};