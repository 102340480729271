import { saveUser, getRoleByUser } from '@/api/sys';
import { isEmail, isMobile } from '@/utils/validate_rules';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        email: '',
        mobile: '',
        partyCode: '',
        password: '',
        realname: '',
        roleIdList: [],
        // roleType: 0,
        status: 2,
        username: ''
      },
      userStatusArr: this.$formatTypeArray('userStatus'),
      roleArr: [],
      addRules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 6,
          max: 50,
          message: '用户名长度6-50位',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: '请输入真实姓名',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '真实姓名长度有误',
          trigger: 'blur'
        }],
        roleIdList: [{
          type: 'array',
          required: true,
          message: '请至少选择一个角色信息',
          trigger: 'change'
        }],
        email: [{
          validator: isEmail,
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 100,
          message: '邮箱长度有误',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.handleRoles();
    console.log(this.editForm);
    console.log(this.editForm.roleIdList);
  },
  methods: {
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          saveUser(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    },
    handleRoles() {
      getRoleByUser().then(res => {
        this.roleArr = res.data;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};