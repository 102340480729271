import { getEmployeeInfo, uploadIdphotoReq } from '../../../api/operateEmp';
import { EMPLOYEE_INFO_ID } from '../../../utils/idName';
import DetailColumn from '../../../components/detailColumn/index';
import UploadImage from '../../../base/ui/upload/uploadImage';
export default {
  name: 'uploadIdCardFile',
  components: {
    UploadImage,
    DetailColumn
  },
  data() {
    return {
      id: '',
      info: {
        idcardFrontShow: '',
        idcardBackShow: '',
        halfBodyUrlShow: ''
      },
      saveForm: {
        idcardFrontUrl: '',
        idcardBackUrl: '',
        halfBodyUrl: ''
      },
      rules: {}
    };
  },
  created() {
    this.id = sessionStorage.getItem(EMPLOYEE_INFO_ID);
    this.getInfo(this.id);
  },
  methods: {
    getInfo(id) {
      getEmployeeInfo(id).then(response => {
        this.info = response.data;
        this.saveForm.halfBodyUrl = this.info.halfBodyUrl;
        this.saveForm.empNo = this.info.empNo;
      });
    },
    handleIdCardFrontSuccess(res) {
      this.saveForm.idcardFrontUrl = res.data.saveKey;
    },
    handleIdCardBackSuccess(res) {
      this.saveForm.idcardBackUrl = res.data.saveKey;
    },
    handleHalfBodySuccess(res) {
      this.saveForm.halfBodyUrl = res.data.saveKey;
    },
    doSubmit() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          uploadIdphotoReq(this.saveForm).then(() => {
            this.$message.success('上传成功，数据已保存');
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};