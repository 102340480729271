import table from '@/utils/table';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import { WITHDRAW_DETAIL_OBJECT } from '../../../utils/idName';
import { getAgentProfitDetailList } from '../../../api/transaction';
export default {
  name: 'agentProfitDetail',
  components: {
    Pagination,
    ItemTitle
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      bussincessMonth: '',
      id: '',
      info: {}
    };
  },
  created() {
    const json = sessionStorage.getItem(WITHDRAW_DETAIL_OBJECT);
    this.info = JSON.parse(json);
    this.queryForm();
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getAgentProfitDetailList({
        agentNo: this.info.agentNo,
        profitMonth: this.info.bussincessMonth,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};