import { mapGetters } from 'vuex';
import { getPartyRoleList, deletePartyRole } from '@/api/sys';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { PARTYROLE_UPDATE_ID, PARTYROLE_INFO_ID } from '@/utils/idName';
export default {
  name: 'partyroleIndex',
  components: {
    Pagination
  },
  data() {
    return {
      searchForm: {
        partyType: '',
        roleType: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: true,
      apis: [],
      addPartyrolePath: '',
      addPartyroleVisible: false,
      viewPartyrolePath: '',
      deletePartyroleVisible: false,
      updatePartyroleVisible: false,
      updatePartyrolePath: '',
      roleTypes: this.$formatTypeArray('roleType'),
      partyTypes: this.$formatTypeArray('partyType')
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updatePartyrolePath && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewPartyrolePath = map.get('viewPartyrole');
      this.updatePartyrolePath = map.get('updatePartyrole');
      this.updatePartyroleVisible = map.has('updatePartyrole');
      this.addPartyrolePath = map.get('addPartyrole');
      this.addPartyroleVisible = map.has('addPartyrole');
      this.deletePartyroleVisible = map.has('deletePartyrole');
    } else {
      this.addPartyroleVisible = false;
      this.updatePartyroleVisible = false;
      this.deletePartyroleVisible = false;
    }
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      getPartyRoleList(this.currentSearch).then(response => {
        this.apis = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        partyType: '',
        roleType: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deletePartyRole(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handleAdd() {
      this.$router.push({
        path: this.addPartyrolePath
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.clickUpdate = true;
      const context = {
        id: row.id,
        roleType: row.roleType,
        partyType: row.partyType,
        roleId: row.roleId
      };
      sessionStorage.setItem(PARTYROLE_UPDATE_ID, JSON.stringify(context));
      this.$router.push({
        path: this.updatePartyrolePath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      const context = {
        id: row.id,
        roleType: row.roleType,
        partyType: row.partyType,
        roleId: row.roleId
      };
      sessionStorage.setItem(PARTYROLE_INFO_ID, JSON.stringify(context));
      this.$router.push({
        path: this.viewPartyrolePath
      });
    },
    formatRoleType(row, column) {
      return this.$formatType('roleType', row.roleType);
    },
    formatPartyType(row, column) {
      return this.$formatType('partyType', row.partyType);
    }
  },
  mounted() {}
};