import pwdForm from './pwdform';
import { mapGetters } from 'vuex';
import '../login&forget.scss';
export default {
  name: 'login',
  components: {
    pwdForm
  },
  data() {
    return {
      activeName: 'user',
      summaryDesc: process.env.SUMMARY_DESC,
      servicePhone: process.env.SERVICE_PHONE,
      serviceQRCode: process.env.SERVICE_QRCODE,
      recordAddress: process.env.RECORD_ADDRESS,
      recordNumber: process.env.RECORD_NUMBER,
      carouselImgs: ['static/img/1@2x.png', 'static/img/2@2x.png',
      // 'static/img/3@2x.png',
      // 'static/img/4@2x.png',
      'static/img/5@2x.png']
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['website'])
  },
  props: [],
  methods: {}
};