var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myUploadViewFromVisible,
      center: "",
      width: "80%",
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myUploadViewFromVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "人员导入预览"
    }
  })], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.employeeList,
      "highlight-current-row": "",
      size: "mini",
      height: "100px",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "empName",
      "min-width": "120",
      label: "姓名"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "idcard",
      "min-width": "150",
      label: "身份证号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "phone",
      width: "140",
      label: "手机号码"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      "min-width": "150",
      label: "银行卡号"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.totalNum > 0,
      expression: "totalNum>0"
    }],
    attrs: {
      total: _vm.totalNum,
      page: _vm.param.pageNo,
      limit: _vm.param.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.param, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.param, "pageSize", $event);
      },
      pagination: _vm.getPageList
    }
  }), _vm._v(" "), _vm.show ? _c("el-input", {
    attrs: {
      value: _vm.merchantNo
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "15px",
      "text-align": "center",
      display: "flex",
      color: "red",
      "font-weight": "bold"
    }
  }, [_c("div", {
    staticStyle: {
      width: "50%",
      "text-align": "left"
    }
  }, [_c("span", [_vm._v("总导入人数：")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      value: _vm.totalNum,
      "auto-complete": "off",
      readonly: ""
    }
  })], 1)]), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.isLoading
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确认导入")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      type: "danger",
      disabled: _vm.isLoading
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };