var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.title,
      "before-close": _vm.handleClose,
      visible: _vm.dialogFormVisible,
      "close-on-click-modal": false
    },
    on: {
      opened: _vm.opened,
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "合同编号:",
      "label-width": _vm.formLabelWidth,
      prop: "customAgreementNo"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.isEdit,
      autocomplete: "off"
    },
    model: {
      value: _vm.form.customAgreementNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customAgreementNo", $$v);
      },
      expression: "form.customAgreementNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "合同名称:",
      "label-width": _vm.formLabelWidth,
      prop: "agreementName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.agreementName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "agreementName", $$v);
      },
      expression: "form.agreementName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "合同金额:",
      "label-width": _vm.formLabelWidth,
      prop: "agreementAmt"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      maxlength: "12"
    },
    model: {
      value: _vm.form.agreementAmt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "agreementAmt", $$v);
      },
      expression: "form.agreementAmt"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "签约时间:",
      "label-width": _vm.formLabelWidth,
      prop: "signDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      "value-format": "yyyy-MM-dd",
      placeholder: "选择日期"
    },
    model: {
      value: _vm.form.signDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "signDate", $$v);
      },
      expression: "form.signDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "合同备注:",
      "label-width": _vm.formLabelWidth,
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autocomplete: "off"
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "合同附件:",
      "label-width": _vm.formLabelWidth,
      required: ""
    }
  }, [_c("upload-more-file", {
    attrs: {
      limit: 1,
      bizCode: _vm.projectNo
    },
    model: {
      value: _vm.agreementUrlList,
      callback: function ($$v) {
        _vm.agreementUrlList = $$v;
      },
      expression: "agreementUrlList"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading,
      size: "mini"
    },
    on: {
      click: _vm.onSure
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };