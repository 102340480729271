import CityCascader from '@/base/ui/cascader/cityCascader';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { mchntProjectSave, gjhelperSupportReq, gjOemCodeSelectReq, queryGjAgentReq, taxsourceShowlist, merchantBelongMchntList } from '@/api/merchant';
import { showSalesmanlist } from '@/api/salesman';
import { showAgentlist } from '@/api/agent';
import { isMobile, isInteger } from '@/utils/validate_rules';
import { validMobile } from '@/utils/validate';
import ItemTitle from '@/base/ui/title/itemTitle';
import { isRegexnNoLen } from '@/utils/validate_rules';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader,
    DbDatePicker
  },
  data() {
    return {
      taxsourceList: [],
      merchantList: [],
      merchantInvoiceType: this.$formatTypeArray('merchantInvoiceType'),
      projectScopeList: [{
        value: '建筑劳务分包',
        label: '建筑劳务分包'
      }],
      saveForm: {
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        address: '',
        contactMobile: '',
        invoiceType: '',
        contactName: '',
        merchantNo: '',
        projectName: '',
        projectPeriod: '',
        projectScope: '',
        remark: '',
        reviewMobile: '',
        taxNo: ''
      },
      creditCode: '',
      rules: {
        projectName: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }],
        parentProjectNo: [{
          required: true,
          message: '请输入项目母编号',
          trigger: 'blur'
        }, {
          validator: isRegexnNoLen,
          trigger: 'blur'
        }],
        projectNo: [{
          required: true,
          message: '请输入项目子编号',
          trigger: 'blur'
        }, {
          validator: isRegexnNoLen,
          trigger: 'blur'
        }],
        projectScope: [{
          required: true,
          message: '请选择分包范围',
          trigger: 'blur'
        }],
        projectPeriod: [{
          required: true,
          message: '请输入工程工期',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'blur'
        }],
        areaCode: [{
          required: true,
          message: '请选择项目地址',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请输入项目详细地址',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        contactName: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }],
        contactMobile: [{
          required: true,
          message: '请输入联系人手机号',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属企业',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    }
  },
  created() {
    this.taxsourceShowlist();
  },
  methods: {
    async taxsourceShowlist() {
      let res = await taxsourceShowlist();
      this.taxsourceList = res.data;
    },
    onTaxsourceList(value) {
      this.merchantShowList();
    },
    async merchantShowList() {
      this.merchantList = [];
      this.saveForm.merchantNo = '';
      const params = {
        pageNo: 0,
        pageSize: 300,
        taxNo: this.saveForm.taxNo
      };
      let res = await merchantBelongMchntList(params);
      this.merchantList = res.data;
    },
    onMerchantList(value) {
      this.merchantList.forEach(val => {
        if (val.merchantNo === value) {
          this.creditCode = val.licenseNo;
        }
      });
    },
    changeMobile(val) {
      this.saveForm.reviewMobile = this.saveForm.contactMobile;
    },
    addSubmit() {
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
        this.saveForm.areaCode = this.$refs.proviceCity.selectedOptions[2];
      }
      console.log(this.saveForm);
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          mchntProjectSave(this.saveForm).then(response => {
            this.$message.success('新增成功！');
            this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    }
  }
};