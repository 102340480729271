var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "border-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.taxName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道类型"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatChannelType(_vm.saveForm.channelType)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道编号"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.channelNo) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账开户行"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.bankName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账账户"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.accountNo) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "入账户名"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.custName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道名称"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.channelName) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "基准类型"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("taxChannelBaseType", _vm.saveForm.baseType)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatStatus(_vm.saveForm.status)) + "\n        ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };