import ItemTitle from '@/base/ui/title/itemTitle';
import { saveEmp } from '@/api/merchantEmp';
import { mchntImportPersonTaxSelect } from '@/api/taxsource';
export default {
  name: 'AddDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxSources: [],
      saveForm: {
        accountNo: '',
        empName: '',
        idcard: '',
        phone: '',
        taxNo: ''
      },
      myDialogFormVisible: false,
      rules: {
        accountNo: [{
          required: true,
          message: '请输入银行卡号',
          trigger: 'blur'
        }, {
          min: 12,
          max: 19,
          message: '请输入12到19位银行卡号',
          trigger: 'blur'
        }],
        empName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 11,
          message: '大于2，小于11个字符',
          trigger: 'blur'
        }],
        idcard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          min: 18,
          max: 18,
          message: '请输入正确的身份证号码',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '请输入11位手机号码',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dialogFormVisible: false
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleAdd', val);
    }
  },
  created() {
    this.getTaxSources();
  },
  methods: {
    addBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          saveEmp(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    cancelBtn() {
      this.myDialogFormVisible = false;
      this.$emit('on-cancel');
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      mchntImportPersonTaxSelect().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};