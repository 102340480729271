import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
export default {
  name: 'updateProductByWeiXin',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxSourceList: [],
      taxStatusType: this.$formatTypeArray('taxStatusType'),
      rechargeAutoStatus: this.$formatTypeArray('isSupport'),
      rules: {
        appId: [{
          required: true,
          message: '请输入渠道商户编号',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '请输入正确的渠道商户编号',
          trigger: 'blur'
        }],
        appKey: [{
          required: true,
          message: '请输入渠道接入签名密钥',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入渠道接入加密密钥KeyNum（基础渠道获取)',
          trigger: 'blur'
        }],
        publicKey: [{
          required: true,
          message: '请输入渠道平台公钥（基础渠道获取）',
          trigger: 'blur'
        }],
        rechargeAuto: [{
          required: true,
          message: '请选择是否支持自动充值',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择通道状态',
          trigger: 'blur'
        }],
        useofCode: [{
          required: true,
          message: '请输入渠道产品编号',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    saveForm: {
      accountNo: '',
      appId: '',
      appKey: '',
      bankName: '',
      channelName: '',
      channelNo: '',
      channelType: '',
      custName: '',
      privateKey: '',
      publicKey: '',
      rechargeAuto: '',
      status: '',
      taxNo: '',
      useofCode: '',
      subMchid: '',
      subAppid: ''
    }
  },
  created() {
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    checkSaveForm() {
      const {
        id,
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        appKey,
        privateKey,
        publicKey,
        rechargeAuto,
        status,
        useofCode,
        subMchid,
        subAppid,
        singleMin,
        singleMax
      } = this.saveForm; // 防止与建行或者支付宝通道数据串了

      const form = {
        id,
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        appKey,
        privateKey,
        publicKey,
        rechargeAuto,
        status,
        useofCode,
        subMchid,
        subAppid,
        singleMin,
        singleMax
      };
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$emit('addSubmit', form, true);
        } else {
          this.$emit('addSubmit', form, false);
        }
      });
    }
  }
};