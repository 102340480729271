import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import ElLink from 'element-ui/packages/link/src/main';
import { batchAuditTaskRecord } from '../../../api/task';
export default {
  name: 'auditTaskDialog',
  components: {
    ElLink,
    ItemTitle,
    DetailColumn
  },
  data() {
    return {
      infoForm: {
        logList: []
      },
      auditForm: {
        auditRemark: '',
        ids: [],
        status: ''
      },
      dialogVisible: false
    };
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    ids: {
      type: Array
    }
  },
  watch: {
    isVisible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit('updated', val);
    }
  },
  methods: {
    // 审核
    check(status) {
      batchAuditTaskRecord({
        auditRemark: this.auditForm.auditRemark,
        status: status,
        ids: this.ids
      }).then(() => {
        this.$message.success('处理成功');
        this.dialogVisible = false;
      });
    },
    dialogClosed() {
      this.$refs['auditForm'].clearValidate();
    }
  }
};