var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base-agent",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商名称：",
      prop: "agentName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.agentName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "agentName", $$v);
      },
      expression: "saveForm.agentName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商类型：",
      prop: "agentType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择服务商类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.agentType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "agentType", $$v);
      },
      expression: "saveForm.agentType"
    }
  }, _vm._l(_vm.agentType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.saveForm.agentType !== 0 ? "法人姓名：" : "姓名：",
      prop: "legalPerson"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPerson,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPerson", $$v);
      },
      expression: "saveForm.legalPerson"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.saveForm.agentType !== 0 ? "法人身份证号：" : "身份证号：",
      prop: "legalPersonIdcard"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPersonIdcard,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPersonIdcard", $$v);
      },
      expression: "saveForm.legalPersonIdcard"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名：",
      prop: "contacts"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contacts", $$v);
      },
      expression: "saveForm.contacts"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机：",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactNumber", $$v);
      },
      expression: "saveForm.contactNumber"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上级服务商：",
      prop: "superiorAgent"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属服务商",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.superiorAgent,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "superiorAgent", $$v);
      },
      expression: "saveForm.superiorAgent"
    }
  }, _vm._l(_vm.agentList, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.agentName + "(" + item.agentNo + ")",
        value: item.agentNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商属性：",
      prop: "agentLevel"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择服务商属性",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.agentLevel,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "agentLevel", $$v);
      },
      expression: "saveForm.agentLevel"
    }
  }, _vm._l(_vm.$formatTypeArray("agentLevelStatutes"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属客户经理：",
      prop: "salesmanNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择客户经理",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.salesmanNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "salesmanNo", $$v);
      },
      expression: "saveForm.salesmanNo"
    }
  }, _vm._l(_vm.salesManList, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.salesmanName + "(" + item.salesmanPhone + ")",
        value: item.salesmanNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区：",
      prop: "cityCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      placeholder: "请选择所属地区"
    },
    model: {
      value: _vm.selectedOptions,
      callback: function ($$v) {
        _vm.selectedOptions = $$v;
      },
      expression: "selectedOptions"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "协议有效期：",
      prop: "datePicker"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd",
      clearable: false
    },
    model: {
      value: _vm.saveForm.datePicker,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "datePicker", $$v);
      },
      expression: "saveForm.datePicker"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系地址："
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "address", $$v);
      },
      expression: "saveForm.address"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传协议附件：",
      prop: "baseFileList"
    }
  }, [_c("upload-more-file", {
    model: {
      value: _vm.saveForm.baseFileList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "baseFileList", $$v);
      },
      expression: "saveForm.baseFileList"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 11
    }
  }, [_vm.saveForm.agentType !== 0 ? _c("el-form-item", {
    attrs: {
      label: "统一信用代码：",
      prop: "licenseNo"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.licenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "licenseNo", $$v);
      },
      expression: "saveForm.licenseNo"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：统一信用代码提交成功后，不可修改。)")])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传身份证",
      prop: "legalPersonIdcardpic"
    }
  }, [_c("upload-image", {
    on: {
      imageSuccess: _vm.handleAvatarSuccessByICCard
    }
  })], 1)], 1), _vm._v(" "), _vm.saveForm.agentType !== 0 ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传营业执照",
      prop: "licensePic"
    }
  }, [_c("upload-image", {
    on: {
      imageSuccess: _vm.handleAvatarSuccessBylicense
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };