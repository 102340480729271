import { getRoleInfo, getRoleTree, getUserRole } from '@/api/sys';
import { getAcountInfo } from '@/utils/auth';
import { ROLE_INFO_ID } from '@/utils/idName';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        menuIdList: [],
        type: '',
        status: 1,
        remark: '',
        parentId: ''
      },
      roleArr: [],
      userType: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  created() {
    this.handleInit();
  },
  methods: {
    handleInit() {
      this.userType = getAcountInfo().usertype;
      const id = sessionStorage.getItem(ROLE_INFO_ID);
      if (id) {
        getRoleInfo(id).then(response => {
          this.editForm = response.data;
          if (this.userType === 0) {
            this.handleMenuTree(true);
          } else {
            getUserRole().then(res => {
              this.roleArr = res.data;
            });
            this.handleMenuTree(false);
          }
          this.$refs.tree.setCheckedKeys(this.editForm.menuIdList);
        });
      }
    },
    handleMenuTree(isFlag) {
      let roleId = '';
      if (!isFlag) {
        roleId = this.editForm.parentId;
      }
      const that = this;
      getRoleTree(roleId, true).then(res => {
        this.treeData = that.formatTreeData(res.data);
      });
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        item.disabled = true;
        this.formatTreeData(item.children);
      }
      return data;
    },
    back() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};