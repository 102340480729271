var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.cachedViews,
      exclude: _vm.blackList
    }
  }, [_c("router-view", {
    key: _vm.key,
    attrs: {
      max: 10
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };