import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import table from '@/utils/table';
// import Fecha from 'fecha'

export default {
  name: 'ViewSignInfoDialog',
  components: {
    ItemTitle,
    Pagination
  },
  props: {
    viewDialogFormVisible: {
      type: Boolean
    },
    signInfoData: {
      type: Object
    }
  },
  data() {
    return {
      myViewDialogFormVisible: false
    };
  },
  computed: {
    signInfoList: function () {
      return this.signInfoData != null ? this.signInfoData.signList : [];
    },
    empNo: function () {
      return this.signInfoData != null ? this.signInfoData.empNo : '';
    },
    empName: function () {
      return this.signInfoData != null ? this.signInfoData.empName : '';
    },
    idcard: function () {
      return this.signInfoData != null ? this.signInfoData.idcard : '';
    },
    phone: function () {
      return this.signInfoData != null ? this.signInfoData.reservePhone : '';
    }
  },
  watch: {
    viewDialogFormVisible(val) {
      // console.log('=============>' + val)
      this.myViewDialogFormVisible = val;
    },
    myViewDialogFormVisible(val) {
      this.$emit('changeVisibleView', val);
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, 1, 100);
    },
    formatEmpSignStatus(row, column) {
      return this.$formatType('empSignStatus', row.status);
    },
    dateFormat(row, column, cellValue) {
      return this.$formatDate(cellValue);
    }
  }
};