var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "view-merchant-tax-dialog",
    attrs: {
      title: "查看详情",
      visible: _vm.myDialogFormVisible,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "9rem",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_c("div", {
    staticClass: "value-box"
  }, [_vm._v("\n              " + _vm._s(_vm.item.taxName) + "\n            ")])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约日期："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.item.protocolStart + "-" + _vm.item.protocolEnd) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "阶梯累计类型："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.formatStatus(_vm.item.rateType)) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标准充值费率："
    }
  }, [_vm._v("\n              " + _vm._s((_vm.item.rechargeRate * 100).toFixed(2)) + "%\n          ")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "协议附件："
    }
  }, [_c("el-link", {
    attrs: {
      type: "primary",
      href: _vm.item.protocolUrlShow,
      download: "协议模版",
      target: "_blank"
    }
  }, [_vm._v("协议模版")])], 1)], 1), _vm._v(" "), _vm.item.rateListInfo && _vm.item.rateListInfo.length > 0 ? _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("item-title", {
    attrs: {
      name: "阶梯费率政策"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("free-view", {
    ref: "freeView",
    attrs: {
      list: _vm.item.rateListInfo,
      text: _vm.freeText,
      name: _vm.freeName,
      isUpdate: false
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };