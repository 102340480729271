import table from '@/utils/table';
import Pagination from '@/components/pagination';
export default {
  components: {
    Pagination
  },
  name: 'merchantViewBankCard',
  data() {
    return {
      total: 0,
      bankType: this.$formatTypeArray('bankType'),
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        taxNo: this.taxNo
      },
      typeList: [],
      listTaxIndustry: []
    };
  },
  props: {
    belong: '',
    bankCardList: {
      type: Array,
      default: {
        type: Array,
        default: () => {
          return [{}];
        }
      }
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.queryParam.pageNo, this.queryParam.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('bankType', row.status);
    }
  }
};