const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  currentPath: state => state.app.currentPath,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  buttonsMap: state => state.permission.buttons,
  alreadyFetchRouter: state => state.permission.alreadyFetchRouter,
  website: state => state.common.website,
  isLock: state => state.user.isLock,
  lockPasswd: state => state.user.lockPasswd,
  isFullScren: state => state.common.isFullScren,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  browserHeaderTitle: state => state.user.browserHeaderTitle,
  authSession: state => state.user.authSession,
  userInfo: state => state.user.userInfo,
  noReadNum: state => state.msgCenter.noReadNum,
  updateRecordResponse: state => state.common.updateRecordResponse
};
export default getters;