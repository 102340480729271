import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import dialogDeleteReview from '../components/dialogDeleteReview.vue';
import dialogDelete from '../components/dialogDelete.vue';
import { getBatchListReq, transactionRecordsDownload, syncRepayBatchReq, repayBatchCancel } from '@/api/transaction';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { BATCH_DETAIL_ID, BATCH_CHECK_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { showlist, signedConfirm, signedRevoke } from '@/api/taxsource';
import { merchantShowList } from '@/api/merchant';
import { mchntProjectShowlist } from '@/api/taxMerchant';
export default {
  name: 'batchReviewCommon',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker,
    dialogDeleteReview,
    dialogDelete
  },
  data() {
    return {
      searchForm: {
        taxSource: '',
        merchantNo: '',
        merchantName: '',
        batchNo: '',
        status: '',
        source: '',
        repayDesc: '',
        projectName: '',
        projectNo: '',
        oemName: '',
        data: [],
        bizBatchNo: ''
      },
      taxSources: [],
      merchantList: [],
      projectList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      batches: [],
      hasDetailPermission: false,
      hasCancelPermission: false,
      hasDeletePermission: false,
      hasReviewPermission: false,
      detailPath: '',
      hasCheckPermission: false,
      syncRepayVisible: false,
      checkPath: '',
      partyType: '',
      taxName: '',
      currentSearch: {
        taxSource: '',
        merchantNo: '',
        merchantName: '',
        batchNo: '',
        status: '',
        source: '',
        data: []
      },
      reviewVisible: false,
      delVisible: false,
      selectId: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    title() {
      return '批次记录';
    },
    statusOptions() {
      return this.$formatTypeArray('batchStatus');
    },
    sourceOptions() {
      return this.$formatTypeArray('orderSource');
    },
    pickerOptions() {
      return timePickerOptions;
    }
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
      this.getMerchantList();
    }
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.hasDetailPermission = map.has('detail');
      this.detailPath = map.get('detail');
      this.hasCheckPermission = map.has('check');
      this.hasCancelPermission = map.has('cancel');
      this.hasDeletePermission = map.has('delete');
      this.hasReviewPermission = map.has('review');
      this.checkPath = map.get('check');
      this.syncRepayVisible = map.get('syncRepay');
    } else {
      this.hasDetailPermission = false;
      this.detailPath = '';
      this.hasCheckPermission = false;
      this.checkPath = '';
      this.hasDeletePermission = false;
      this.hasReviewPermission = false;
    }
    this.getTaxSources();
    this.queryForm();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatProject(project) {
      return `${project.projectName}(${project.projectNo})`;
    },
    getMerchantList() {
      this.searchForm.merchantNo = null;
      this.merchantList = [];
      if (this.searchForm.taxNo !== '') {
        const params = {
          taxNo: this.searchForm.taxNo
        };
        merchantShowList(params).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    merchantSelectChange(value) {
      console.log(11);
      if (value) {
        this.getProjectList();
      }
      this.searchForm.projectNo = '';
      this.projectList = [];
    },
    async getProjectList() {
      const params = {
        merchantNo: this.searchForm.merchantNo,
        taxNo: this.searchForm.taxNo
      };
      let res = await mchntProjectShowlist(params);
      this.projectList = res.data;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    checkRow(index, object) {
      // 查看详情
      sessionStorage.setItem(BATCH_DETAIL_ID, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    reviewRow(index, object) {
      // 处理
      sessionStorage.setItem(BATCH_CHECK_ID, object.id);
      this.$router.push({
        path: this.checkPath
      });
    },
    downloadRow(index, object) {
      const params = {
        batchNo: object.batchNo,
        taxNo: object.taxNo
      };
      transactionRecordsDownload(params).then(response => {
        const fileName = `repayment-record-${object.batchNo}.xls`;
        downloadExcel(response.data, fileName);
      });
    },
    cancel(index, object) {
      const params = {
        id: object.id
      };
      repayBatchCancel(params).then(response => {
        this.$message.success('撤销成功');
        this.getList();
      });
    },
    onDelete(index, row) {
      this.selectId = String(row.id);
      this.delVisible = true;
    },
    onDeleteReview(index, row) {
      this.selectId = String(row.id);
      this.reviewVisible = true;
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      getBatchListReq({
        taxNo: this.currentSearch.taxSource,
        batchNo: this.currentSearch.batchNo,
        merchantNo: this.currentSearch.merchantNo,
        merchantName: this.currentSearch.merchantName,
        source: this.currentSearch.source,
        status: this.currentSearch.status,
        beginTime: beginTime,
        endTime: endTime,
        repayDesc: this.currentSearch.repayDesc,
        repayType: 0,
        projectName: this.currentSearch.projectName,
        projectNo: this.currentSearch.projectNo,
        syncStatus: this.currentSearch.syncStatus,
        oemName: this.currentSearch.oemName,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        bizBatchNo: this.currentSearch.bizBatchNo
      }).then(response => {
        this.batches = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    syncRepayBatch() {
      syncRepayBatchReq().then(() => {
        this.$message.success('操作成功');
      }).catch(error => {
        this.$message.error(error.errMsg);
      });
    }
  }
};