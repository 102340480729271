import ItemTitle from '../../../base/ui/title/itemTitle';
import DetailColumn from '../../../components/detailColumn/index';
import { taxWtdzdjInfoReg } from '../../../api/taxsource';
import { WTDZ_INFO_ID } from '../../../utils/idName';
import Pagination from '../../../components/pagination/index';
export default {
  components: {
    Pagination,
    DetailColumn,
    ItemTitle
  },
  data() {
    return {
      infoForm: {},
      list: [],
      total: 0,
      status: '',
      checked: false,
      summary: '',
      // 临时税务登记状态：0-未登记，1-已登记，2-已注销
      pageNo: 1,
      pageSize: 10,
      listLoading: false
    };
  },
  created() {
    const infoJson = sessionStorage.getItem(WTDZ_INFO_ID);
    if (infoJson != null) {
      // debugger
      this.infoForm = JSON.parse(infoJson);
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      this.listLoading = true;
      taxWtdzdjInfoReg({
        taxNo: this.infoForm.taxNo,
        idcard: this.infoForm.idcard,
        status: this.checked ? 0 : '',
        // 完税平衡状态：0-异常，1-正常
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(respnose => {
        this.listLoading = false;
        this.list = respnose.data.list;
        this.summary = respnose.data.summary;
        this.total = respnose.data.totalCount;
      });
    },
    getSummary() {
      if (this.summary == 1) {
        return '是';
      } else {
        return '否';
      }
    }
  }
};