/**
 * 动态插入css
 */

export const loadStyle = url => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
 * 设置浏览器头部标题
 */
export const setTitle = function (title) {
  title = title ? `${title}` : process.env.TAX_BRAND;
  window.document.title = title;
};
export const timePickerOptions = {
  shortcuts: [{
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
};

// 当月
export function getCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  let month = now.getMonth() + 1; // 0-11表示1-12月
  let day = now.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  const startDay = year + '-' + month + '-' + '01';
  const endDay = year + '-' + month + '-' + day;
  return [startDay, endDay];
}

// 当天
export function getCurrentDay() {
  const now = new Date();
  const year = now.getFullYear();
  let month = now.getMonth() + 1; // 0-11表示1-12月
  let day = now.getDate();
  month = month < 10 ? '0' + month : month;
  // day = day < 10 ? ('0' + day) : day

  const startDay = year + '-' + month + '-' + day + ' 00:00:00';
  const endDay = year + '-' + month + '-' + day + ' 23:59:59';
  return [startDay, endDay];
}

// 上一月
export function getLastMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // 0-11表示1-12月

  const lastMonth = month - 1;
  if (lastMonth <= 0) {
    return year - 1 + '-' + 12;
  } else if (lastMonth < 10) {
    return year + '-0' + lastMonth;
  } else {
    return year + '-' + lastMonth;
  }
}

// 当年
export function getCurrentYear() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // 0-11表示1-12月

  const startDay = year + '-' + '01';
  const endMonth = month - 1 <= 0 ? 1 : month - 1;
  const monthStr = endMonth < 10 ? '0' + endMonth : endMonth;
  const endDay = year + '-' + monthStr;
  return [startDay, endDay];
}

// 近一年
export function getLast12Month() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // 0-11表示1-12月
  const dateArray = [];
  // dateArray[1] = year + '-' + month

  let endMonth = month - 1;
  if (endMonth <= 0) {
    // 如果是1、2、3、4、5、6、7、8、9、10、11月，年数往前推一年
    dateArray[1] = year - 1 + '-' + '12';
  } else {
    endMonth = endMonth < 10 ? '0' + endMonth : endMonth;
    dateArray[1] = year + '-' + endMonth;
  }
  const startMonth = month < 10 ? '0' + month : month;
  dateArray[0] = year - 1 + '-' + startMonth;
  return dateArray;
}

// 近3个月
export function getLast3Month() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // 0-11表示1-12月
  const day = now.getDate();
  const dateObj = {};

  // const nowMonth = month < 10 ? ('0' + month) : month
  // const nowDay = day < 10 ? ('0' + day) : day
  // dateObj.now = year + '-' + nowMonth + '-' + nowDay
  const nowMonthDay = new Date(year, month, 0).getDate(); // 当前月的总天数

  let nowYear = '';
  let nowMonth = '';
  let nowDay = '';
  if (month - 1 <= 0) {
    // 如果是1 月，年数往前推一年
    var last1MonthDay = new Date(year - 1, 12 - (1 - parseInt(month)), 0).getDate(); // 1个月前所在月的总天数
    if (last1MonthDay < day) {
      // 1个月前所在月的总天数小于现在的天日期
      nowYear = year - 1;
      nowMonth = 12 - (1 - month);
      nowDay = last1MonthDay;
    } else {
      nowYear = year - 1;
      nowMonth = 12 - (1 - month);
      nowDay = day;
    }
  } else {
    const last1MonthDay = new Date(year, parseInt(month) - 1, 0).getDate(); // 1个月前所在月的总天数
    if (last1MonthDay < day) {
      // 1个月前所在月的总天数小于现在的天日期
      if (day < nowMonthDay) {
        // 当前天日期小于当前月总天数,2月份比较特殊的月份
        nowYear = year;
        nowMonth = month - 1;
        nowDay = last1MonthDay - (nowMonthDay - day);
      } else {
        nowYear = year;
        nowMonth = month - 1;
        nowDay = last1MonthDay;
      }
    } else {
      nowYear = year;
      nowMonth = month - 1;
      nowDay = day;
    }
  }
  nowMonth = nowMonth < 10 ? '0' + nowMonth : nowMonth;
  nowDay = nowDay < 10 ? '0' + nowDay : nowDay;
  dateObj.now = nowYear + '-' + nowMonth + '-' + nowDay;
  let lastYear = '';
  let lastMonth = '';
  let lastDay = '';
  if (month - 3 <= 0) {
    // 如果是1、2、3月，年数往前推一年
    var last3MonthDay = new Date(year - 1, 12 - (3 - parseInt(month)), 0).getDate(); // 3个月前所在月的总天数
    if (last3MonthDay < day) {
      // 3个月前所在月的总天数小于现在的天日期
      // dateObj.last = (year - 1) + '-' + (12 - (3 - month)) + '-' + last3MonthDay
      lastYear = year - 1;
      lastMonth = 12 - (3 - month);
      lastDay = last3MonthDay;
    } else {
      lastYear = year - 1;
      lastMonth = 12 - (3 - month);
      lastDay = day;
      // dateObj.last = (year - 1) + '-' + (12 - (3 - month)) + '-' + day
    }
  } else {
    const last3MonthDay = new Date(year, parseInt(month) - 3, 0).getDate(); // 3个月前所在月的总天数
    if (last3MonthDay < day) {
      // 3个月前所在月的总天数小于现在的天日期
      if (day < nowMonthDay) {
        // 当前天日期小于当前月总天数,2月份比较特殊的月份
        lastYear = year;
        lastMonth = month - 3;
        lastDay = last3MonthDay - (nowMonthDay - day);
        // dateObj.last = year + '-' + (month - 3) + '-' + (last3MonthDay - (nowMonthDay - day))
      } else {
        lastYear = year;
        lastMonth = month - 3;
        lastDay = last3MonthDay;
        // dateObj.last = year + '-' + (month - 3) + '-' + last3MonthDay
      }
    } else {
      lastYear = year;
      lastMonth = month - 3;
      lastDay = day;
      // dateObj.last = year + '-' + (month - 3) + '-' + day
    }
  }
  lastMonth = lastMonth < 10 ? '0' + lastMonth : lastMonth;
  lastDay = lastDay < 10 ? '0' + lastDay : lastDay;
  dateObj.last = lastYear + '-' + lastMonth + '-' + lastDay;
  return dateObj;
}
export function isExcelFile(file) {
  const xls = 'application/vnd.ms-excel';
  const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileType = file.type;
  const fileName = file.name;
  // console.log('====>Upload File Type=' + file.type)
  // console.log('====>Upload File Name=' + file.name)
  var isExcel = false;
  if (fileType != null && fileType !== '') {
    isExcel = fileType === xls || fileType === xlsx;
    // console.log('====>Upload File isExcelByType=' + isExcel)
  } else {
    const reg = /^.*\.(xls|xlsx)$/; // 取不到文件type时，文件后缀匹配
    isExcel = reg.test(fileName);
    // console.log('====>Upload File isExcelByName=' + isExcel)
  }
  return isExcel;
}
export function isZip(file) {
  const type = file.type;
  if (type === 'application/zip') {
    return true;
  } else if (type === 'application/x-compressed') {
    return true;
  } else if (type === 'application/x-zip-compressed') {
    return true;
  } else if (type === 'multipart/x-zip') {
    return true;
  }
  return false;
}
export const beforUploadByExcel = (that, file) => {
  // console.log('====>Upload File isExcel=' + isExcel)
  const isExcel = isExcelFile(file);
  if (!isExcel) {
    that.$message.error('上传文件只支持.xls/.xlsx 格式!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    that.$message.error('上传文件大小不能超过 10MB!');
  }
  return isLt2M && isExcel;
};
export const beforUploadByExcelZip = (that, file) => {
  // console.log('====>Upload File isExcel=' + isExcel)
  const isExcel = isExcelFile(file);
  const isZIP = isZip(file);
  if (!isExcel && !isZIP) {
    that.$message.error('上传文件只支持.xls/.xlsx/.zip 格式!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    that.$message.error('上传文件大小不能超过 10MB!');
  }
  return isLt2M && isExcel && isZIP;
};
export const beforeUploadPdf = (that, file) => {
  // const isPDF = (file.type === 'application/pdf' || file.type === 'application/doc')
  const isPDF = file.type === 'application/pdf';
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isPDF) {
    that.$message.error('上传文件只支持PDF格式!');
    return false;
  }
  if (!isLt2M) {
    that.$message.error('上传文件大小不能超过 10MB!');
    return false;
  }
  return true;
};
export const beforUploadImgPdf = (that, file, size) => {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
  const isPDF = file.type === 'application/pdf';
  const isLt5M = file.size / 1024 / 1024 < 10;
  const isFile = isJPG || isPDF;
  if (!isFile) {
    that.$message.error('上传图片只能是 JPG、PNG、PDF 格式!');
  }
  if (!isLt5M) {
    that.$message.error('上传图片大小不能超过 10MB!');
  }
  return isLt5M && isFile;
};
export const beforUploadImg = (that, file, size) => {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
  const isLt5M = file.size / 1024 / 1024 < 10;
  if (!isJPG) {
    that.$message.error('上传图片只能是 JPG、PNG 格式!');
  }
  if (!isLt5M) {
    that.$message.error('上传图片大小不能超过 10MB!');
  }
  return isLt5M && isJPG;
};
export const beforUploadPdfImageBmp = (that, file) => {
  const isPDF = file.type === 'application/pdf';
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
  const isBMP = file.type === 'image/bmp';
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isPDF && !isJPG && !isBMP) {
    that.$message.error('上传文件只支持PDF、jpg、png、BMP格式!');
    return false;
  }
  if (!isLt10M) {
    that.$message.error('上传文件大小不能超过 10MB!');
    return false;
  }
  return true;
};
export const beforUploadMorType = (that, file, size) => {
  // const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png')
  //
  // const isPDF = (file.type === 'application/pdf')
  //
  //
  // const isExcel = isExcelFile(file)
  //
  // const isWord = (file.type === 'application/msword')
  //
  // const isTxt = (file.type === 'text/plain')
  //
  // const isFile = isJPG || isPDF || isExcel || isWord || isTxt
  //
  // if (!isFile) {
  //   that.$message.error('上传图片只能是 .jpg、.jpeg、.png、.pdf、.word、.txt、.excel格式!')
  // }
  const isLt5M = file.size / 1024 / 1024 < 10;
  if (!isLt5M) {
    that.$message.error('上传图片大小不能超过 10MB!');
  }
  return isLt5M;
};
export const downloadExcel = (data, fileName) => {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel;charset=utf-8'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
};
export const downloadPdf = (data, fileName) => {
  const blob = new Blob([data], {
    type: 'application/pdf;charset=utf-8'
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
};
export const downloadAllFile = (data, fileName) => {
  let typeStr = '';
  const files = fileName.split('.');
  const type = files[files.length - 1];
  if (type === 'pdf') {
    typeStr = 'application/pdf';
  } else if (type === 'zip') {
    typeStr = 'application/zip';
  } else if (type === 'xls') {
    typeStr = 'application/vnd.ms-excel';
  } else if (type === 'xlsx') {
    typeStr = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  } else if (type === 'doc') {
    typeStr = 'application/msword';
  } else if (type === 'docx') {
    typeStr = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (type === 'dot') {
    typeStr = 'application/msword';
  } else if (type === 'dotx') {
    typeStr = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
  } else if (type === 'ppt') {
    typeStr = 'application/vnd.ms-powerpoint';
  } else if (type === 'txt') {
    typeStr = 'text/plain';
  } else if (type === 'gif') {
    typeStr = 'image/gif';
  } else if (type === 'jpeg') {
    typeStr = 'image/jpeg';
  } else if (type === 'jpg') {
    typeStr = 'image/jpeg';
  } else if (type === 'png') {
    typeStr = 'image/png';
  } else if (type === 'xsl') {
    typeStr = 'text/xml';
  }
  if (typeStr === '') {
    return;
  }
  const blob = new Blob([data], {
    type: typeStr
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
};

// 从时间控件获取的Date分离出开始时间和结束时间
export const separateDate = date => {
  let beginTime = '';
  let endTime = '';
  if (Array.isArray(date) && date.length === 2) {
    beginTime = date[0];
    endTime = date[1];
  }
  return {
    beginTime,
    endTime
  };
};

// 复制的方法
export function copyText(text) {
  // text: 要复制的内容
  return new Promise(resolve => {
    if (window.clipboardData) {
      window.clipboardData.setData('text', text);
    } else {
      (function (text) {
        document.oncopy = function (e) {
          e.clipboardData.setData('text', text);
          e.preventDefault();
          document.oncopy = null;
        };
      })(text);
      document.execCommand('Copy');
    }
    resolve();
  });
}