import ItemTitle from '@/base/ui/title/itemTitle';
import UploadMoreFile from '@/base/ui/upload/uploadMoreFile';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import { merchantShowList } from '@/api/merchant';
import { saveFile } from '@/api/upload';
import PreviewDialog from './preview2';
import { isExcelFile } from '@/utils/util';
import { invoiceSelectListReg } from '../../../api/task';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'updateRecordFiles',
  components: {
    PreviewDialog,
    UploadMoreFile,
    ItemTitle
  },
  data() {
    return {
      saveFile: saveFile,
      updateRecordsUrl: BASE_URL + '/project/merchantTaskRecord/updateRecords',
      partyCode: '',
      fileList: [],
      taxSources: [],
      addForm: {
        taxNo: '',
        invoiceSerialNo: '',
        merchantNo: '',
        saveKeys: []
      },
      merchantNo: '',
      merchantList: [],
      invoiceList: [],
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'change'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属商户',
          trigger: 'change'
        }],
        invoiceSerialNo: [{
          required: true,
          message: '请选择开票订单',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    exData: function () {
      return {
        merchantNo: this.addForm.merchantNo,
        taxNo: this.addForm.taxNo,
        invoiceSerialNo: this.addForm.invoiceSerialNo
      };
    }
  },
  props: {
    limit: {
      required: false,
      default: () => {
        return 10;
      }
    },
    dialogVisible: false
  },
  watch: {
    dialogVisible(val) {
      this.fileList = [];
      this.$refs['addForm'].resetFields();
    }
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    } else {
      this.addForm.merchantNo = user.partyCode;
      this.merchantName = user.realname;
    }
    if (this.partyType === 1) {
      this.addForm.taxNo = user.partyCode;
    }
    this.getTaxSources();
    this.invoiceSelectList();
  },
  methods: {
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    taxSelectChange() {
      this.invoiceSelectList();
    },
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选取提交文件。');
        return;
      }
      this.$confirm('是否确定提交？', '温馨提示', {
        type: 'plain',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs['addForm'].validate(valid => {
          if (valid) {
            this.$refs.upload.submit();
          } else {
            return false;
          }
        });
      }).catch(() => {});
    },
    beforeUpload(file) {
      const isExcel = isExcelFile(file);
      const isLt3M = file.size / 1024 / 1024 < 50;
      if (!isExcel) {
        this.$message.error('上传文件只能是Excel格式!');
      }
      if (!isLt3M) {
        this.$message.error('上传文件大小不能超过 50MB!');
      }
      return isExcel && isLt3M;
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件，本次选择了1个文件。');
    },
    handleRemove() {
      this.fileList = [];
      this.addForm.saveKey = '';
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 'BASE.000') {
        this.closeDialog(response.data);
      } else {
        this.$message.error(`${file.name}上传失败`);
      }
    },
    closeDialog(data) {
      this.$store.dispatch('setUpdateRecordResponse', data);
      this.$emit('dialogClosed');
    },
    invoiceSelectList() {
      if (this.addForm.merchantNo) {
        invoiceSelectListReg({
          taxNo: this.addForm.taxNo,
          merchantNo: this.addForm.merchantNo
        }).then(response => {
          this.invoiceList = response.data;
        });
      }
    }
  }
};