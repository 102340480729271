var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "账户信息"
    }
  }), _vm._v(" "), _vm.saveForm ? _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base-bg",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账户名称："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.baseForm.agentName) + "\n            ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账户类型："
    }
  }, [_vm.baseForm.agentType === 1 ? _c("div", [_vm._v("对公")]) : _vm._e(), _vm._v(" "), _vm.baseForm.agentType === 0 ? _c("div", [_vm._v("对私")]) : _vm._e()])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "银行账户："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.accountNo) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户银行："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.bankName) + "\n\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "预留手机号："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.mobile) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户城市："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.formatCity()) + "\n        ")])], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };