var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("merchant-view-base", {
    attrs: {
      saveForm: _vm.bean.merchantBase,
      gjAgentInfo: _vm.gjAgentInfo,
      gjHelperSupport: _vm.gjHelperSupport
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };