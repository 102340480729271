export default {
  name: 'nxBreadcrumb',
  created() {
    this.getBreadcrumb();
  },
  data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb() {
      const routers = this.$route.matched.filter(one => {
        if (one.parent && one.path === this.fomaterSubPath(one.parent.path)) {
          return false;
        }
        return true;
      });
      let matched = routers.filter(item => item.name);
      const first = matched[0];
      if (first && first.name !== 'home') {
        matched = [{
          path: '/',
          meta: {
            title: '首页'
          }
        }].concat(matched);
      }
      this.levelList = matched;
    },
    fomaterSubPath(path) {
      return path.endsWith('/') ? path : path.concat('/');
    }
  }
};