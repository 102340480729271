import ItemTitle from '@/base/ui/title/itemTitle';
import { updateEmpInfo } from '@/api/operateEmp';
export default {
  name: 'UpdateEmpDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      show: false,
      authTypes: this.$formatTypeArray('authType'),
      updateEmpForm: {
        pbNo: '',
        merchantNo: '',
        empName: '',
        idcard: '',
        accountNo: '',
        reservePhone: ''
        // authType: ''
      },
      myDialogFormVisible: false,
      updateEmpFormRules: {
        accountNo: [{
          required: true,
          message: '请输入银行卡号',
          trigger: 'blur'
        }, {
          min: 12,
          max: 19,
          message: '请输入12到19位银行卡号',
          trigger: 'blur'
        }],
        empName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 11,
          message: '大于2，小于11个字符',
          trigger: 'blur'
        }],
        idcard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          min: 18,
          max: 18,
          message: '请输入正确的身份证号码',
          trigger: 'blur'
        }],
        reservePhone: [{
          required: true,
          message: '请输入银行预留手机号码',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '请输入11位手机号码',
          trigger: 'blur'
        }],
        authType: [{
          required: true,
          message: '请选择认证方式',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    updateDialogVisible: false,
    updateEmpData: {
      type: Object
    }
  },
  watch: {
    updateEmpData: function (newValue, oldValue) {
      this.updateEmpForm = newValue != null ? newValue : null;
    },
    updateDialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleUpdate', val);
    }
  },
  methods: {
    updateSubmit() {
      this.$refs['updateEmpForm'].validate(valid => {
        if (valid) {
          updateEmpInfo(this.updateEmpForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '修改成功!',
              type: 'success'
            });
            this.$emit('on-success');
          }).catch(() => {
            this.myDialogFormVisible = false;
            this.$emit('on-error');
          });
        } else {
          return false;
        }
      });
    },
    cancelBtn() {
      this.myDialogFormVisible = false;
      this.$message({
        message: '取消修改!',
        type: 'view.vue'
      });
      this.$emit('on-cancel');
    },
    dialogClosed() {
      this.$refs['updateEmpForm'].resetFields();
    }
  }
};