import { merchantInfo } from '@/api/merchant';
import { MERCHANT_INFO_ID } from '@/utils/idName';
import MerchantViewBase from './components/view/viewBase';
import MerchantViewBankCard from './components/view/viewBankCard';
export default {
  components: {
    MerchantViewBankCard,
    MerchantViewBase
  },
  data() {
    return {
      active: 0,
      message: 'first',
      id: sessionStorage.getItem(MERCHANT_INFO_ID),
      bean: {
        feeConfig: {},
        bankCardList: [],
        merchantBase: {
          merchantNo: ''
        },
        signConfigList: []
      },
      merchantNo: '',
      gjAgentInfo: {},
      gjHelperSupport: ''
    };
  },
  created() {
    this.freshData();
    console.log('==========created');
  },
  beforeMount() {
    console.log('==========beforeMount');
  },
  mounted() {
    console.log('==========mounted');
  },
  beforeUpdate() {
    console.log('==========beforeUpdate');
  },
  updated() {
    console.log('==========updated');
  },
  beforeDestroy() {
    console.log('==========beforeDestroy');
  },
  watch: {
    merchantNo(val) {
      console.log('===watch2====merchantNo:' + val);
      this.merchantNo = val;
    }
  },
  methods: {
    beforeRouteLeave(to, from, next) {
      to.meta.keepAlive = false; // 下一页不缓存
      next();
    },
    freshData() {
      const param = {
        id: this.id,
        operateType: 1 // 操作类型(0-查看详情, 1-编辑修改)
      };
      merchantInfo(param).then(response => {
        this.bean = response.data;
        this.gjAgentInfo = response.data.gjAgent;
        this.gjHelperSupport = response.data.gjHelperSupport;
        this.merchantNo = this.bean.merchantBase.merchantNo;
      });
    }
  }
};