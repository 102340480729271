var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("ve-histogram", {
    attrs: {
      data: _vm.data,
      settings: _vm.settings,
      "after-set-option": _vm.afterSetOption,
      extend: _vm.extend,
      width: _vm.width
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };