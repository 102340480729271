import { mapGetters } from 'vuex';
import { getTransactionInfoReq } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import { QUERY_DETAIL_ID } from '@/utils/idName';
export default {
  name: 'transaction-detail',
  components: {
    ItemTitle,
    DetailColumn
  },
  data() {
    return {
      infoForm: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    businessStatus: function () {
      if (this.infoForm != null) {
        return this.$formatType('transactionBusinessStatus', this.infoForm.repayStatus);
      }
      return '';
    },
    /*
    reconStatus: function() {
      if (this.infoForm != null) {
        return this.$formatType('transactionReconStatus', this.infoForm.reconStatus)
      }
      return ''
    },
    */
    accountType: function () {
      if (this.infoForm != null) {
        return this.$formatType('accountType', this.infoForm.accountType);
      }
      return '';
    },
    accountStatus: function () {
      if (this.infoForm != null) {
        return this.$formatType('transactionAccountStatus', this.infoForm.accountStatus);
      }
      return '';
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const id = sessionStorage.getItem(QUERY_DETAIL_ID);
      getTransactionInfoReq({
        id: id
      }).then(response => {
        console.log(response.data);
        this.infoForm = response.data;
      });
    }
  }
};