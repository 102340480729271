export default {
  name: 'dbsHistogram',
  data() {
    const extend = {
      series: {
        barWidth: 20 // 柱图宽度
      }
    };
    return {
      extend: extend
    };
  },
  props: {
    data: {
      require: true
    },
    settings: {
      require: true
    },
    rotate: {
      default: () => {
        return false;
      }
    },
    width: ''
  },
  methods: {
    afterSetOption(chartObj) {
      const xAxis = {
        axisLabel: {
          interval: 0,
          textStyle: {
            fontSize: 14
          }
        }
      };
      let legend = {};
      if (this.data.rows.length > 15 || this.rotate) {
        xAxis.axisLabel.rotate = 40;
      }
      if (this.data.columns.length > 2) {
        legend = {
          show: true
        };
      } else {
        legend = {
          show: false
        };
      }
      if (this.data.rows.length > 20 && this.data.rows.length <= 31) {
        this.extend.series.barWidth = 10;
        xAxis.axisLabel.textStyle.fontSize = 13;
      } else if (this.data.rows.length > 31 && this.data.rows.length <= 40) {
        this.extend.series.barWidth = 5;
        xAxis.axisLabel.textStyle.fontSize = 12;
      } else if (this.data.rows.length > 41) {
        xAxis.axisLabel.textStyle.fontSize = 10;
        this.extend.series.barWidth = 3;
      }
      chartObj.setOption({
        xAxis: xAxis,
        legend: legend
      });
    }
  }
};