var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "addForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "140px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("div", {
    staticClass: "border-bg task-base"
  }, [_c("el-form-item", {
    attrs: {
      label: "所属商户：",
      prop: "taxNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.addForm.merchantName) + " (" + _vm._s(_vm.addForm.merchantNo) + ")\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.addForm.taxName) + " (" + _vm._s(_vm.addForm.taxNo) + ")\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务名称：",
      prop: "taskName"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.addForm.taskName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务类型：",
      prop: "taskType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务类型"
    },
    model: {
      value: _vm.addForm.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "taskType", $$v);
      },
      expression: "addForm.taskType"
    }
  }, _vm._l(_vm.taskTypeSelectType, function (item) {
    return _c("el-option", {
      key: item.dictCode,
      attrs: {
        label: item.dictValue,
        value: item.dictCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务有效期：",
      prop: "taskTime"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.addForm.taskTime,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "taskTime", $$v);
      },
      expression: "addForm.taskTime"
    }
  })], 1), _vm._v(" "), _vm.taskReportSwitch === 1 ? _c("el-form-item", {
    attrs: {
      label: "任务成果模版",
      prop: "templeteNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "任务成果模版"
    },
    model: {
      value: _vm.addForm.templeteNo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "templeteNo", $$v);
      },
      expression: "addForm.templeteNo"
    }
  }, _vm._l(_vm.taskTemplateList, function (item) {
    return _c("el-option", {
      key: item.optionValue,
      attrs: {
        label: item.optionText,
        value: item.optionValue
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务计价方式：",
      prop: "pricingMode"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择任务计价方式"
    },
    model: {
      value: _vm.addForm.pricingMode,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "pricingMode", $$v);
      },
      expression: "addForm.pricingMode"
    }
  }, _vm._l(_vm.pricingModeType, function (item) {
    return _c("el-option", {
      key: item.dictCode,
      attrs: {
        label: item.dictValue,
        value: item.dictCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    class: _vm.amountType === 0 ? "amount-line-single" : "amount-line-max"
  }, [_c("el-form-item", {
    attrs: {
      label: "任务金额(元)：",
      prop: "singleAmount"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.amountType === 0 ? "请按计价方式输入单笔金额" : "请输入最小金额"
    },
    model: {
      value: _vm.addForm.singleAmount,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "singleAmount", $$v);
      },
      expression: "addForm.singleAmount"
    }
  })], 1), _vm._v(" "), _vm.amountType === 1 ? _c("div", {
    staticStyle: {
      padding: "7px 8px",
      height: "30px"
    }
  }, [_vm._v("\n        -\n      ")]) : _vm._e(), _vm._v(" "), _vm.amountType === 1 ? _c("el-form-item", {
    staticStyle: {
      "margin-left": "-140px"
    },
    attrs: {
      label: "",
      prop: "singleMaxAmount"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入最大金额"
    },
    model: {
      value: _vm.addForm.singleMaxAmount,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "singleMaxAmount", $$v);
      },
      expression: "addForm.singleMaxAmount"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px",
      padding: "0",
      height: "30px"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.changeAmountType
    }
  }, [_vm._v("\n        " + _vm._s(_vm.amountType === 0 ? "设置区间金额" : "设置固定金额") + "\n      ")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "是否公开邀约："
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.addForm.invitePublic,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "invitePublic", $$v);
      },
      expression: "addForm.invitePublic"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("否")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("是")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务描述：",
      prop: "caption"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入任务描述，最多可输入200个字(含标点符号)",
      type: "textarea",
      maxlength: "200",
      "show-word-limit": ""
    },
    model: {
      value: _vm.addForm.caption,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "caption", $$v);
      },
      expression: "addForm.caption"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "任务详细描述",
      prop: "captionInfo"
    }
  }, [_c("vue-editor", {
    ref: "editor",
    attrs: {
      editorOptions: _vm.editorSettings
    },
    model: {
      value: _vm.addForm.captionInfo,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "captionInfo", $$v);
      },
      expression: "addForm.captionInfo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "上传任务附件："
    }
  }, [_c("upload-more-file", {
    staticStyle: {
      width: "460px"
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "100%",
      "text-align": "center",
      margin: "20px 0"
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };