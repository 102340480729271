import { mapGetters } from 'vuex';
import { getRoleList, deleteRole } from '@/api/sys';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { ROLE_UPDATE_ID, ROLE_INFO_ID } from '@/utils/idName';
export default {
  name: 'roleList',
  components: {
    Pagination
  },
  data() {
    return {
      listQuery: {
        name: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: true,
      roles: [],
      addRolePath: '',
      addRoleVisible: false,
      updateRolePath: '',
      viewRolePath: '',
      updateRoleVisible: false,
      deleteRoleVisible: false
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewRolePath = map.get('viewRole');
      this.updateRolePath = map.get('updateRole');
      this.updateRoleVisible = map.has('updateRole');
      this.addRolePath = map.get('addRole');
      this.addRoleVisible = map.has('addRole');
      this.deleteRoleVisible = map.has('deleteRole');
    } else {
      this.addRoleVisible = false;
      this.updateRoleVisible = false;
      this.deleteRoleVisible = false;
    }
    this.getQuery();
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    formatType(row, column) {
      return row.type === 0 ? '系统角色' : '用户创建';
    },
    formatStatus(row, column) {
      return row.status === 0 ? '禁用' : '启用';
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.listQuery.pageNo, this.listQuery.pageSize);
    },
    getQuery() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      getRoleList(this.listQuery).then(response => {
        this.roles = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.listQuery = {
        name: '',
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteRole(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(ROLE_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updateRolePath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(ROLE_INFO_ID, row.id);
      this.$router.push({
        path: this.viewRolePath
      });
    },
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleAdd() {
      this.$router.push({
        path: this.addRolePath
      });
    }
  },
  mounted() {}
};