var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "upload-invoice-dialog",
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "导入发票内容"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("el-form", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票内容："
    }
  }, [_c("div", {
    staticStyle: {
      display: "-webkit-box"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      action: _vm.uploadApplyFile,
      "before-upload": _vm.beforeUpload,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.onSuccess,
      "auto-upload": false,
      limit: 1
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "success"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("上传至服务器")]), _vm._v(" "), _c("el-link", {
    staticClass: "download_temp_label",
    attrs: {
      type: "primary",
      href: _vm.templateUrl,
      target: "_blank",
      download: "开票类目模板"
    }
  }, [_vm._v("下载开票类目模板")])], 1)], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };