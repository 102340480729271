var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _vm._v(" "), _c("sidebar", {
    staticClass: "sidebar-container"
  }), _vm._v(" "), _c("div", {
    staticClass: "main-container"
  }, [_c("navbar"), _vm._v(" "), _c("tags-view"), _vm._v(" "), _c("app-main", {
    staticClass: "app-main-container"
  }), _vm._v(" "), _c("footer-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };