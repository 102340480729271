import { render, staticRenderFns } from "./rechargeList.vue?vue&type=template&id=04953631&scoped=true"
import script from "./rechargeList.vue?vue&type=script&lang=js"
export * from "./rechargeList.vue?vue&type=script&lang=js"
import style0 from "./rechargeList.vue?vue&type=style&index=0&id=04953631&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04953631",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04953631')) {
      api.createRecord('04953631', component.options)
    } else {
      api.reload('04953631', component.options)
    }
    module.hot.accept("./rechargeList.vue?vue&type=template&id=04953631&scoped=true", function () {
      api.rerender('04953631', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profit/trade/rechargeList.vue"
export default component.exports