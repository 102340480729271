import { addTaxIndustry, listTaxIndustry, delTaxIndustry } from '@/api/taxsource';
import { queryDistrictListToIndustry } from '@/api/common';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { isValidFloat01 } from '@/utils/validate_rules';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    Pagination
  },
  data() {
    return {
      total: 0,
      saveForm: {
        code: '',
        feeRate: '',
        taxNo: ''
      },
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        taxNo: this.taxNo
      },
      typeList: [],
      listTaxIndustry: [],
      rules: {
        code: [{
          required: true,
          message: '请选择行业类型',
          trigger: 'blur'
        }],
        feeRate: [{
          required: true,
          message: '请输入行业税率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    const that = this;
    this.getList();
    queryDistrictListToIndustry().then(response => {
      that.typeList = response.data;
    });
  },
  props: {
    gotoNext: {
      default: null
    },
    taxNo: ''
  },
  methods: {
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        delTaxIndustry(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    getList() {
      const that = this;
      listTaxIndustry(this.queryParam).then(response => {
        that.listTaxIndustry = response.data;
        that.total = response.data.length;
      });
    },
    addBtn() {
      this.saveForm.taxNo = this.taxNo;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          addTaxIndustry(this.saveForm).then(response => {
            this.$message({
              type: 'success',
              message: '添加行业税率成功'
            });
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    addSubmit() {
      if (this.listTaxIndustry.length === 0) {
        this.$message.error('请添加行业税率');
        return;
      }
      if (this.gotoNext) {
        this.gotoNext('');
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.queryParam.pageNo, this.queryParam.pageSize);
    }
  }
};