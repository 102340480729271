import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { getTaxChannelList, freeze, unfreeze } from '../../api/taxChannel';
import { UPDATE_TAXSOURCE_CHANNEL_ID, VIEW_TAXSOURCE_CHANNEL_ID } from '../../utils/idName';
export default {
  name: 'taxChannelList',
  components: {
    CityCascader,
    Pagination
  },
  data() {
    return {
      total: 0,
      updateProduct: '',
      viewProduct: '',
      saveProduct: '',
      forzenChannel: '',
      list: [],
      listLoading: true,
      taxStatus: this.$formatTypeArray('taxStatusType'),
      channelTypes: this.$formatTypeArray('channelType'),
      updateStatus: {
        id: '',
        status: ''
      },
      searchForm: {
        channelType: '',
        order: '',
        status: '',
        taxName: '',
        taxNo: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateProduct && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.saveProduct = map.get('saveProduct');
      this.viewProduct = map.get('viewProduct');
      this.updateProduct = map.get('updateProduct');
      this.forzenChannel = map.get('forzenChannel');
    }
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (this.$refs.proviceCity !== undefined && this.$refs.proviceCity.selectedOptions) {
        this.currentSearch.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.currentSearch.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      getTaxChannelList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getStatus(status) {
      if (status === 1) {
        return '冻结';
      } else if (status === 9) {
        return '解冻';
      }
    },
    clearQuery() {
      this.searchForm = {
        channelType: '',
        order: '',
        status: '',
        taxName: '',
        taxNo: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    handleAdd() {
      this.$router.push({
        path: this.saveProduct
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(UPDATE_TAXSOURCE_CHANNEL_ID, row.id);
      this.clickUpdate = true;
      this.$router.push({
        path: this.updateProduct
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(VIEW_TAXSOURCE_CHANNEL_ID, row.id);
      this.$router.push({
        path: this.viewProduct
      });
    },
    formatCity() {
      if (this.searchForm.provinceCode === '' || this.searchForm.provinceCode === undefined) {
        return '请选择所属地区';
      } else {
        return this.searchForm.provinceCode + '/' + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('taxStatusType', row.status);
    },
    formatChannelType(row) {
      return this.$formatType('channelType', row.channelType);
    },
    formatIsSupport(row) {
      return this.$formatType('isSupport', row.rechargeAuto);
    },
    unfreeze(row) {
      this.$confirm('是否确定解冻该通道?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        unfreeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    freeze(row) {
      this.$confirm('冻结操作后通道相关交易均不可用，确定冻结点击“确定”，其他点击“取消”！', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        freeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    }
  }
};