import { saveApi } from '@/api/sys';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        name: '',
        type: 1,
        status: 1,
        permission: '',
        url: '',
        description: ''
      },
      isContinue: 0,
      typeArr: this.$formatTypeArray('apiType'),
      statusArr: this.$formatTypeArray('apiStatus'),
      addRules: {
        name: [{
          required: true,
          message: '请输入接口名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '接口名称长度有误',
          trigger: 'blur'
        }],
        url: [{
          type: 'string',
          min: 1,
          max: 50,
          message: '接口地址长度有误',
          trigger: 'blur'
        }],
        permission: [{
          type: 'string',
          min: 1,
          max: 100,
          message: '接口权限名称长度有误',
          trigger: 'blur'
        }],
        description: [{
          min: 1,
          max: 200,
          message: '接口描述长度有误',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {},
  methods: {
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          saveApi(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            if (this.isContinue === 0) {
              this.goBack();
            } else {
              this.editForm.name = '';
              this.editForm.description = '';
            }
          });
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};