import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { showlist } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import { downloadStatGrade, getStatMerchantGradeList } from '@/api/stat';
import { getLast3Month } from '../../../../utils/util';
export default {
  name: 'tadGradeList',
  components: {
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      tadGradeChartPath: '',
      hasDownloadPermission: false,
      list: [],
      listLoading: true,
      createTime: [],
      searchForm: {
        merchantName: '',
        merchantNo: '',
        isNewMchnt: '',
        taxNo: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      taxSources: [],
      dateTime: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getList();
    this.getTaxSources();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.tadGradeChartPath = map.get('tadGradeChart');
      this.hasDownloadPermission = map.get('download');
    }
    const dates = getLast3Month();
    this.dateTime = [dates.last, dates.now];
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getStatMerchantGradeList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    handleTadGradeChartPath() {
      this.$router.push({
        path: this.tadGradeChartPath
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      downloadStatGrade(this.currentSearch).then(response => {
        downloadExcel(response.data, '商户评级统计表.xls');
      });
    }
  }
};