import { mapGetters } from "vuex";
import table from "@/utils/table";
import Pagination from "@/components/pagination";
import { separateDate } from "../../../utils/util";
import DbDatePicker from "../../../base/ui/date/db-date-picker";
import { blockLogList, enable, disable, blackListAdd, blackListUpdate } from "../../../api/sys";
import { showlist } from "@/api/taxsource";
import { getAcountInfo } from "@/utils/auth";
import { merchantShowList } from "@/api/merchant";
export default {
  name: "blackListBlock",
  components: {
    DbDatePicker,
    Pagination
  },
  data() {
    return {
      searchForm: {
        custName: "",
        idcardNo: "",
        mobile: "",
        blackType: "",
        taxNo: "",
        merchantNo: ""
      },
      dialogForm: {
        blackType: "",
        blackValue: "",
        taxNo: "",
        merchantNo: "",
        remark: ""
      },
      addBlack: "",
      // 新增按钮权限
      updateBlack: "",
      // 修改按钮权限
      checkBlack: "",
      // 切换按钮权限
      dialogFormVisible: false,
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      dateTime: [],
      taxSources: [],
      merchantList: [],
      partyType: "",
      editId: "",
      rules: {
        blackType: [{
          required: true,
          message: "请选择黑名单类型",
          trigger: "change"
        }],
        taxNo: [{
          required: true,
          message: "请选择所属劳务公司",
          trigger: "change"
        }]
        // blackValue: [
        //   { required: true, message: "请输入黑名单值", trigger: "blur" }
        // ]
      }
    };
  },
  computed: {
    ...mapGetters(["buttonsMap"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  mounted() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addBlack = map.get("addBlack");
      this.updateBlack = map.get("updateBlack");
      this.checkBlack = map.get("checkBlack");
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs["searchForm"].resetFields();
      this.dateTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getList() {
      this.listLoading = true;
      const time = separateDate(this.dateTime);
      blockLogList({
        ...this.currentSearch,
        beginTime: time.beginTime,
        endTime: time.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    handleCheck(item) {
      const {
        id,
        status
      } = item;
      this.$confirm(`是否确定${status === 1 ? "停用" : "启用"}?`, "温馨提示", {
        type: "warning",
        closeOnClickModal: false
      }).then(async () => {
        const res = status === 1 ? await disable({
          id
        }) : await enable({
          id
        });
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.getList();
      });
    },
    handleUpdate(item) {
      this.editId = item.id;
      const {
        blackType,
        blackValue,
        taxNo,
        merchantNo,
        remark
      } = item;
      this.dialogForm.blackType = blackType;
      this.dialogForm.blackValue = blackValue;
      this.dialogForm.taxNo = taxNo;
      this.dialogForm.remark = remark;
      this.getMerchantList();
      this.dialogForm.merchantNo = merchantNo;
      this.dialogFormVisible = true;
    },
    dialogFormConfirm() {
      this.$refs["dialogForm"].validate(valid => {
        if (valid) {
          // 个人黑名单时，黑名单值必填
          if ((this.dialogForm.blackType === 1 || this.dialogForm.blackType === 2) && !this.dialogForm.blackValue) {
            this.$message({
              message: "请输入黑名单值",
              type: "error"
            });
            return false;
          }
          if (this.dialogForm.blackType === 3 && !this.dialogForm.merchantNo) {
            this.$message({
              message: "请选择所属企业",
              type: "error"
            });
            return false;
          }
          this.$confirm(`是否确定${this.editId ? "修改" : "新增"}?`, "温馨提示", {
            type: "warning",
            closeOnClickModal: false
          }).then(async () => {
            const res = this.editId ? await blackListUpdate({
              id: this.editId,
              ...this.dialogForm
            }) : await blackListAdd({
              ...this.dialogForm
            });
            this.$message({
              message: this.editId ? "修改成功" : "新增",
              type: "success"
            });
            this.getList();
            this.dialogFormVisible = false;
          });
        }
      });
    },
    getMerchantList() {
      this.searchForm.merchantNo = null;
      this.merchantList = [];
      if (this.searchForm.taxNo !== "") {
        merchantShowList({
          taxNo: this.searchForm.taxNo
        }).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
    // concatTaxSource(tax) {
    //   return `${tax.taxName}(${tax.taxNo})`;
    // },
    // concatMerchant(merchant) {
    //   return `${merchant.merchantName}(${merchant.merchantNo})`;
    // },
  }
};