import CityCascader from '@/base/ui/cascader/cityCascader';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { downloadFile } from '../../../../api/upload';
import { downloadAllFile } from '../../../../utils/util';
export default {
  name: 'saveBase',
  components: {
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      legalPersonIdcardpic: '',
      licensePic: '',
      protocolUrl: '',
      salesManList: [],
      agentType: this.$formatTypeArray('agentTypeStatutes')
    };
  },
  props: {
    saveForm: {
      address: '',
      agentNo: '',
      agentName: '',
      agentType: '',
      areaCode: '',
      cityCode: '',
      contactNumber: '',
      contacts: '',
      legalPerson: '',
      legalPersonIdcard: '',
      legalPersonIdcardpic: '',
      licenseNo: '',
      licensePic: '',
      protocolEnd: '',
      protocolStart: '',
      protocolUrl: '',
      provinceCode: '',
      recharegeRate: '',
      salesmanNo: ''
    }
  },
  methods: {
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = file.response.data;
      this.licensePic = URL.createObjectURL(file.raw);
      this.file = file;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = file.response.data;
      this.legalPersonIdcardpic = URL.createObjectURL(file.raw);
      this.file = file;
    },
    handleAvatarSuccessByprotocolUrl(res, file) {
      this.saveForm.protocolUrl = file.response.data;
      this.protocolUrl = URL.createObjectURL(file.raw);
      this.file = file;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt2M && isJPG;
    },
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city;
      }
    },
    formatAgentTypeStatutes() {
      return this.$formatType('agentTypeStatutes', this.saveForm.agentType);
    },
    downloadUrl(item) {
      const that = this;
      downloadFile(item.fileUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename, that);
      });
    }
  }
};