var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "巡检记录"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "巡检记录编号",
      prop: "inspectNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入巡检记录编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.inspectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "inspectNo", $$v);
      },
      expression: "searchForm.inspectNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "巡检时间",
      prop: "inspectionTime"
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchForm.inspectionTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "inspectionTime", $$v);
      },
      expression: "searchForm.inspectionTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "巡检人",
      prop: "inspector"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入巡检人",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.inspector,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "inspector", $$v);
      },
      expression: "searchForm.inspector"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "业务劳务公司",
      prop: "taxSource"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxSource,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxSource", $$v);
      },
      expression: "searchForm.taxSource"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "收款户名",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入收款户名",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "custName", $$v);
      },
      expression: "searchForm.custName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "收款账号",
      prop: "accountNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入收款账号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.accountNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "accountNo", $$v);
      },
      expression: "searchForm.accountNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "付款说明",
      prop: "repayRemark"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入付款说明",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.repayRemark,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "repayRemark", $$v);
      },
      expression: "searchForm.repayRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "业务状态",
      prop: "repayStatus"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择业务状态"
    },
    model: {
      value: _vm.searchForm.repayStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "repayStatus", $$v);
      },
      expression: "searchForm.repayStatus"
    }
  }, _vm._l(_vm.repayStatusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "提交时间",
      prop: "subTime"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.subTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "subTime", $$v);
      },
      expression: "searchForm.subTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "完成时间",
      prop: "completeTime"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.completeTime,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "completeTime", $$v);
      },
      expression: "searchForm.completeTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]), _vm._v(" "), _vm.hasImportPermission ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-mouse"
    },
    on: {
      click: function ($event) {
        _vm.uploadDialogVisible = true;
      }
    }
  }, [_vm._v("巡检结果导入")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("查询汇总：  交易笔数  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.summary[0]))]), _vm._v(" \n     笔；  交易金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[1])))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "30px 0",
      flex: "1"
    },
    attrs: {
      data: _vm.records,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "inspectNo",
      label: "巡检记录编号",
      width: "220"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "result",
      label: "巡检结果",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "inspectTime",
      label: "巡检时间",
      "min-width": "100",
      formatter: _vm.$formatDateInTable
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "inspector",
      label: "巡检人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "业务订单号",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantNo",
      label: "甲方单位编号",
      "min-width": "130"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "甲方单位名称",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "业务劳务公司",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "收款户名",
      "min-width": "130",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "收款账号",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "账户类型",
      width: "170",
      formatter: _vm.$formatTypeInTable("accountType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayAmount",
      label: "订单金额",
      width: "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayStatus",
      label: "业务状态",
      width: "130",
      formatter: _vm.$formatTypeInTable("transactionBusinessStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "orderTime",
      label: "提交时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "completeTime",
      label: "完成时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayRemark",
      label: "付款(业绩)说明",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "最后更新时间",
      width: "170"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "70",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasDeletePermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("upload-result-dialog", {
    attrs: {
      dialogFormVisible: _vm.uploadDialogVisible
    },
    on: {
      changeDialogVisible: _vm.changeDialogVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };