import ItemTitle from '@/base/ui/title/itemTitle';
import ElRadioGroup from 'element-ui/packages/radio/src/radio-group';
import ElRadio from 'element-ui/packages/radio/src/radio';
import { saveMerchantConfig, updateMerchantConfig } from '../../../api/merchant';
import { isMobiles } from '@/utils/validate_rules';
export default {
  name: 'WarnSet',
  components: {
    ElRadio,
    ElRadioGroup,
    ItemTitle
  },
  data() {
    return {
      rules: {
        warnSwitch: [{
          required: true,
          message: '请选择是否预警',
          trigger: 'blur'
        }],
        warnValue: [{
          required: true,
          message: '请输入预警阈值',
          trigger: 'blur'
        }, {
          validator: this.validatorWarnValue,
          trigger: 'blur'
        }],
        warnContacts: [{
          required: true,
          message: '请输入预警通知手机',
          trigger: 'blur'
        }, {
          validator: isMobiles,
          trigger: 'blur'
        }],
        warnCount: [{
          required: true,
          message: '请输入单日预警次数',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    console.log('=====mounted merchantInfo:' + this.merchantInfo.id);
  },
  watch: {
    merchantInfo(val) {
      this.merchantInfo = val;
    }
  },
  props: {
    merchantInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    doSubmit() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          const {
            id,
            merchantNo,
            warnContacts,
            warnCount,
            warnSwitch,
            warnValue
          } = this.merchantInfo;
          const params = {
            id,
            merchantNo,
            warnContacts,
            warnCount,
            warnSwitch,
            warnValue
          };
          if (params.id <= 0 || params.id === undefined) {
            saveMerchantConfig(params).then(() => {
              this.$message.success('操作成功');
            });
          } else {
            updateMerchantConfig(params).then(() => {
              this.$message.success('操作成功');
            });
          }
        } else {
          return false;
        }
      });
    },
    validatorWarnValue(rule, value, callback) {
      if (value != null && value !== '') {
        if (value > 1000000) {
          callback(new Error('预警阈值不能大于1000000'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
  }
};