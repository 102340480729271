var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通知标题：",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入通知标题",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "title", $$v);
      },
      expression: "searchForm.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建账号：",
      prop: "createUser"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入创建账号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.createUser,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "createUser", $$v);
      },
      expression: "searchForm.createUser"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "归属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择归属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "通知类型：",
      prop: "noticeType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择通知类型"
    },
    model: {
      value: _vm.searchForm.noticeType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "noticeType", $$v);
      },
      expression: "searchForm.noticeType"
    }
  }, _vm._l(_vm.$formatTypeArray("noticeType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.$formatTypeArray("sysMessageStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间：",
      prop: "dateTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM-dd"
    },
    model: {
      value: _vm.dateTime,
      callback: function ($$v) {
        _vm.dateTime = $$v;
      },
      expression: "dateTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.addSysMessage ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "sys-message-class",
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "noticeType",
      label: "通知类型",
      "min-width": "90",
      formatter: _vm.$formatTypeInTable("noticeType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "通知标题",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accepterType",
      label: "通知对象",
      "min-width": "80",
      formatter: _vm.$formatTypeInTable("accepterType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "effectTime",
      label: "发布时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invalidTime",
      label: "下线时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "70",
      formatter: _vm.$formatTypeInTable("sysMessageStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createUser",
      label: "创建账号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "归属劳务公司",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "200",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row);
            }
          }
        }, [_vm._v("详情")]), _vm._v(" "), scope.row.status === 1 && (scope.row.noticeType === 1 || scope.row.noticeType === 2) ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRollBack(scope.row);
            }
          }
        }, [_vm._v("撤回")]) : _vm._e(), _vm._v(" "), scope.row.status === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCancel(scope.row);
            }
          }
        }, [_vm._v("取消")]) : _vm._e(), _vm._v(" "), scope.row.status === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handlePublish(scope.row);
            }
          }
        }, [_vm._v("立即发布")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && scope.row.noticeType === 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleOffLine(scope.row);
            }
          }
        }, [_vm._v("立即下线")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };