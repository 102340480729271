import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 新增任务
 * @param param
 */
export const saveMerchantTask = param => {
  return request({
    url: '/project/merchantTask/save',
    method: 'post',
    data: param
  });
};

/**
 * 获取任务计价方式下拉列表
 */
export const getPricingModeSelect = () => {
  return request({
    url: '/project/merchantTask/pricingModeSelect',
    method: 'get'
  });
};

/**
 *获取任务类型下拉列表
 */
export const getTaskTypeSelect = merchantNo => {
  let params = '';
  if (merchantNo) {
    params = '?merchantNo=' + merchantNo;
  }
  return request({
    url: '/project/merchantTask/taskTypeSelect' + params,
    method: 'get'
  });
};

/**
 *获取任务下拉列表
 */
export const getTaskSelect = taxNo => {
  return request({
    url: '/project/merchantTask/taskSelect',
    method: 'post',
    data: {
      taxNo
    }
  });
};

/**
 * 修改任务
 * @param param
 */
export const updateMerchantTask = param => {
  return request({
    url: '/project/merchantTask/update',
    method: 'post',
    data: param
  });
};

/**
 * 上架任务（商户）
 * @param param
 */
export const upMerchantTask = id => {
  return request({
    url: '/project/merchantTask/up',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 暂停任务（商户）
 * @param param
 */
export const suspendMerchantTask = id => {
  return request({
    url: '/project/merchantTask/suspend',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 结束任务（商户）
 * @param param
 */
export const finishMerchantTask = id => {
  return request({
    url: '/project/merchantTask/finish',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 取消任务（商户）
 * @param param
 */
export const cancelMerchantTask = id => {
  return request({
    url: '/project/merchantTask/cancel',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 任务详情
 * @param param
 */
export const getMerchantTaskInfo = id => {
  return request({
    url: '/project/merchantTask/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *获取任务列表
 */
export const getTaskList = param => {
  return request({
    url: '/project/merchantTask/list',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录审核
 */
export const auditTaskRecord = param => {
  return request({
    url: '/project/merchantTaskRecord/audit',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录批量审核
 */
export const batchAuditTaskRecord = param => {
  return request({
    url: '/project/merchantTaskRecord/batchAudit',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录批量提交附件记录
 */
export const batchSaveRecordAnnexs = param => {
  return request({
    url: '/project/merchantTaskRecord/saveRecordAnnexs',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录批量提交记录入库
 */
export const batchSaveRecords = taskNo => {
  return request({
    url: '/project/merchantTaskRecord/batchSaveRecords',
    method: 'post',
    data: {
      taskNo
    }
  });
};

/**
 *商户任务提交记录
 *获取详细信息
 */
export const getTaskRecordInfo = id => {
  return request({
    url: '/project/merchantTaskRecord/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *任务提交记录详情-系统-记录分页
 */
export const getVoucherRecordList = param => {
  return request({
    url: '/project/merchantVoucherRecord/list',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录获取列表信息
 */
export const getTaskRecordList = param => {
  return request({
    url: '/project/merchantTaskRecord/list',
    method: 'post',
    data: param
  });
};

/**
 *获取开票订单下拉选项框(根据园区+商户)
 */
export const invoiceSelectListReg = param => {
  return request({
    url: '/project/merchantTaskRecord/invoiceSelectList',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录获取提交记录列表信息
 */
export const getRecordsPreview = param => {
  return request({
    url: '/project/merchantTaskRecord/recordsPreview',
    method: 'post',
    data: param
  });
};

/**
 *商户任务提交记录上传提交记录
 */
export const uploadRecords = param => {
  return request({
    url: '/project/merchantTaskRecord/uploadRecords',
    method: 'post',
    data: param
  });
};

/**
 *企业任务类目表列表信息
 */
export const getTaskCategoryList = param => {
  return request({
    url: '/project/taskCategory/list',
    method: 'post',
    data: param
  });
};

/**
 *企业任务类目表-新增
 */
export const saveTaskCategory = param => {
  return request({
    url: '/project/taskCategory/save',
    method: 'post',
    data: param
  });
};

/**
 *企业任务类目表-修改
 */
export const updateTaskCategory = param => {
  return request({
    url: '/project/taskCategory/update',
    method: 'post',
    data: param
  });
};

/**
 *企业任务类目表-详情
 */
export const getTaskCategoryInfo = id => {
  return request({
    url: '/project/taskCategory/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *企业任务类目表-删除
 */
export const deleteTaskCategory = id => {
  return request({
    url: '/project/taskCategory/delete',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *企业任务类目表-下载
 */
export const downloadTaskCategory = param => {
  return download({
    url: '/project/taskCategory/download',
    data: param
  });
};

/**
 *企业月度任务报告表-下载履约确认函
 */
export const downloadTaskReportMerchant = param => {
  return download({
    url: '/project/taskReportMerchant/downloadLetter',
    data: param
  });
};

/**
 *企业月度任务报告表-获取列表信息
 */
export const getTaskReportMerchantList = param => {
  return request({
    url: '/project/taskReportMerchant/list',
    method: 'post',
    data: param
  });
};

/**
 *企业月度任务报告表-新增
 */
export const saveTaskReportMerchant = param => {
  return request({
    url: '/project/taskReportMerchant/save',
    method: 'post',
    data: param
  });
};

/**
 *企业月度任务报告表-上传履约确认函信息
 */
export const updateTaskReportLetterUrl = param => {
  return request({
    url: '/project/taskReportMerchant/updateLetterUrl',
    method: 'post',
    data: param
  });
};

/**
 *人员月度任务报告表-下载履约确认函
 */
export const downloadTaskReportPerson = param => {
  return download({
    url: '/project/taskReportPerson/download',
    data: param
  });
};

/**
 *人员月度任务报告表-获取列表信息
 */
export const getTaskReportPersonList = param => {
  return request({
    url: '/project/taskReportPerson/list',
    method: 'post',
    data: param
  });
};

/**
 *人员月度任务报告表-发送月度报告短信
 */
export const sendSmsByTaskReportPerson = id => {
  return request({
    url: '/project/taskReportPerson/sendSms',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *V3.2-下载任务批量提交模板
 */
export const downTaskRecordTemplate = param => {
  return download({
    url: '/project/merchantTaskRecord/downTaskRecordTemplate',
    data: param
  });
};

/**
 *任务记录-任务选择
 */
export const taskSelectByTaxMchntNo = param => {
  return request({
    url: '/project/merchantTask/taskSelectByTaxMchntNo',
    method: 'post',
    data: param
  });
};

/**
 *任务记录-记录更新
 */
export const updateRecords = param => {
  return request({
    url: '/project/merchantTaskRecord/updateRecords',
    method: 'post',
    data: param
  });
};

/**
 *任务记录-V3.2-导出附件模板(导出任务库记录和任务附件模板)
 */
export const downLoadTaskRecord = param => {
  return download({
    url: '/project/merchantTaskRecord/downLoadTaskRecord',
    data: param
  });
};

/**
 *任务记录-V3.2-预导出附件模板(预导出任务库记录和任务附件模板)，返回统计数进行提示
 */
export const preDownLoadTaskRecord = param => {
  return request({
    url: '/project/merchantTaskRecord/preDownLoadTaskRecord',
    method: 'post',
    data: param
  });
};

/**
 *任务记录-附件记录列表
 */
export const taskAnnexList = param => {
  return request({
    url: '/project/merchantTaskAnnex/list',
    method: 'post',
    data: param
  });
};

/**
 *付款完成任务汇总表
 */
export const repayReportReg = param => {
  return request({
    url: '/project/mchntTaskReport/repayReport',
    method: 'post',
    data: param
  });
};

/**
 *下载付款完成任务汇总表
 */
export const downRepayReportReg = param => {
  return download({
    url: '/project/mchntTaskReport/downRepayReport',
    data: param
  });
};

/**
 *任务完成情况汇总表
 */
export const taskReportReg = param => {
  return request({
    url: '/project/mchntTaskReport/taskReport',
    method: 'post',
    data: param
  });
};

/**
 *下载任务完成情况汇总表
 */
export const downTaskReportReg = param => {
  return download({
    url: '/project/mchntTaskReport/downTaskReport',
    data: param
  });
};

/**
 *任务明细汇总表
 */
export const taskRecordsReportReg = param => {
  return request({
    url: '/project/mchntTaskReport/taskRecordsReport',
    method: 'post',
    data: param
  });
};

/**
 *下载任务明细汇总表
 */
export const downTaskRecordsReportReg = param => {
  return download({
    url: '/project/mchntTaskReport/downTaskRecordsReport',
    data: param
  });
};
/**
 *任务审核
 */
export const taskReview = param => {
  return request({
    url: '/project/merchantTask/check',
    method: 'post',
    data: param
  });
};
/**
 *任务复核
 */
export const taskCheckReview = param => {
  return request({
    url: '/project/merchantTask/review',
    method: 'post',
    data: param
  });
};
// 获取人员月度任务推送列表
export const getReportPushList = param => {
  return request({
    url: '/project/taskReportSum/list',
    method: 'post',
    data: param
  });
};

// 推送数据
export const createPushInfo = param => {
  return request({
    url: '/project/taskReportSum/create',
    method: 'post',
    data: param
  });
};

// 获取待推送数据
export const getPushInfo = param => {
  return request({
    url: '/project/taskReportSum/query',
    method: 'post',
    data: param
  });
};

// 人员月度报告详情
export const getPersonInfo = param => {
  return request({
    url: '/project/taskReportPerson/info',
    method: 'post',
    data: param
  });
};

// 获取任务成果模版
export const getTaskReportList = param => {
  return request({
    url: '/project/merchantTask/taskTypeTempleteSelect',
    method: 'get',
    data: param
  });
};
// 是否展示成果