var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "黑名单类型",
      prop: "blackType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择黑名单类型"
    },
    model: {
      value: _vm.searchForm.blackType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "blackType", $$v);
      },
      expression: "searchForm.blackType"
    }
  }, _vm._l(_vm.$formatTypeArray("blackType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "黑名单值",
      prop: "blackValue"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入黑名单值"
    },
    model: {
      value: _vm.searchForm.blackValue,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "blackValue", $$v);
      },
      expression: "searchForm.blackValue"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "添加时间",
      prop: "dateTime",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM-dd"
    },
    model: {
      value: _vm.dateTime,
      callback: function ($$v) {
        _vm.dateTime = $$v;
      },
      expression: "dateTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.addBlack,
      expression: "addBlack"
    }],
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: function ($event) {
        _vm.dialogFormVisible = true;
      }
    }
  }, [_vm._v("新增")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "blackType",
      label: "黑名单类型",
      width: "100",
      formatter: _vm.$formatTypeInTable("blackType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "blackValue",
      label: "黑名单值",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "所属企业",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "添加时间",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注说明",
      width: "100",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createUser",
      label: "创建人",
      "min-width": "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "100",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.checkBlack,
            expression: "checkBlack"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheck(scope.row);
            }
          }
        }, [_vm._v(_vm._s(scope.row.status === 1 ? "停用" : "启用"))]), _vm._v(" "), _c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.updateBlack,
            expression: "updateBlack"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(scope.row);
            }
          }
        }, [_vm._v("修改")])];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.editId ? "修改黑名单" : "新增黑名单",
      visible: _vm.dialogFormVisible,
      width: "600px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      },
      closed: _vm.dialogFormClose
    }
  }, [_c("el-form", {
    ref: "dialogForm",
    attrs: {
      model: _vm.dialogForm,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "黑名单类型",
      prop: "blackType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择黑名单类型"
    },
    model: {
      value: _vm.dialogForm.blackType,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "blackType", $$v);
      },
      expression: "dialogForm.blackType"
    }
  }, _vm._l(_vm.$formatTypeArray("blackType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    on: {
      change: function ($event) {
        return _vm.getMerchantList();
      }
    },
    model: {
      value: _vm.dialogForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "taxNo", $$v);
      },
      expression: "dialogForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属企业",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属企业"
    },
    model: {
      value: _vm.dialogForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "merchantNo", $$v);
      },
      expression: "dialogForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "黑名单值",
      prop: "blackValue"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: 40,
      placeholder: "请输入黑名单值，最多可输入40个字符"
    },
    model: {
      value: _vm.dialogForm.blackValue,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "blackValue", $$v);
      },
      expression: "dialogForm.blackValue"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注说明",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: 100,
      type: "textarea",
      placeholder: "请输入备注说明，最多可输入100个字符"
    },
    model: {
      value: _vm.dialogForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.dialogForm, "remark", $$v);
      },
      expression: "dialogForm.remark"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogFormVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.dialogFormConfirm
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };