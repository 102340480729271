var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "委托代征数据回滚"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.deleteForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "回滚月份：",
      prop: "djMonth",
      clearable: ""
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "请选择回滚月份",
      format: "yyyy-MM",
      "value-format": "yyyy-MM",
      clearable: ""
    },
    model: {
      value: _vm.deleteForm.djMonth,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "djMonth", $$v);
      },
      expression: "deleteForm.djMonth"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.deleteForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.deleteForm, "taxNo", $$v);
      },
      expression: "deleteForm.taxNo"
    }
  }, _vm._l(_vm.taxList, function (tax) {
    return tax.taxNo !== "SYSTEM" ? _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    }) : _vm._e();
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "title-span"
  }, [_vm._v("\n    注意：回滚后，所选月份的委托代征数据会被重置(申报金额为0)，完税平衡状态重置为未处理。\n  ")]), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.submitUpload();
      }
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.myDialogFormVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };