import { isValidUserName } from "@/utils/validate_rules";
import { loginCode } from "@/api/verifyCode";
import { setCookie, delCookie } from "@/utils/cookieUtil";
import { encrypt, decrypt } from "@/utils/encryptionAES";
import { getCookie } from "../../utils/cookieUtil";
const MSGINIT = "发送验证码";
// const MSGERROR = '验证码发送失败'
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
export default {
  name: "userlogin",
  data() {
    return {
      taxBrand: process.env.TAX_BRAND,
      checkbox: true,
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      captchaImg: "",
      loginForm: {
        username: "",
        password: "",
        loginCode: ""
      },
      checked: false,
      captcha: {
        src: "",
        value: "",
        len: 4,
        type: "text"
      },
      loginRules: {
        username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }, {
          min: 1,
          max: 50,
          message: "用户名长度有误",
          trigger: "blur"
        }, {
          validator: isValidUserName,
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }, {
          min: 5,
          max: 18,
          message: "请输入密码",
          trigger: "blur"
        }],
        captcha: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }, {
          min: 5,
          max: 5,
          message: "验证码长度为5位",
          trigger: "blur"
        }]
      },
      passwordType: "password"
    };
  },
  mounted() {},
  created() {
    this.loginForm.username = getCookie("nameUser");
    const pwdCookie = getCookie("passUser");
    if (pwdCookie !== "" || pwdCookie !== undefined) {
      const pwd = decrypt(getCookie("passUser"));
      this.loginForm.password = pwd;
    }
  },
  computed: {},
  props: [],
  methods: {
    showPassword() {
      this.passwordType === "" ? this.passwordType = "password" : this.passwordType = "";
    },
    handleSend() {
      if (this.msgKey) {
        return;
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          loginCode(this.loginForm.username, this.taxBrand).then(res => {
            if (this.msgKey) return;
            this.$message({
              type: "success",
              message: res.data
            });
            this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
            this.msgKey = true;
            const time = setInterval(() => {
              this.msgTime--;
              this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
              if (this.msgTime === 0) {
                this.msgTime = MSGTIME;
                this.msgText = MSGINIT;
                this.msgKey = false;
                clearInterval(time);
              }
            }, 1000);
          });
        }
      });
    },
    handleLogin() {
      if (!this.loginForm.loginCode) {
        this.$message.error("验证码不能为空");
        return false;
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const pwd = encrypt(this.loginForm.password);
          const form = {
            username: this.loginForm.username,
            password: pwd,
            loginCode: this.loginForm.loginCode
          };
          this.$store.dispatch("Login", form).then(res => {
            if (this.checkbox) {
              this.setCookieByUser();
            } else {
              delCookie("nameUser");
              delCookie("passUser");
            }
            this.$router.push({
              path: "/"
            });
          });
        }
      });
    },
    handleForgetPwd() {
      if (!this.loginForm.username) {
        this.$message.error("请输入用户名");
        return false;
      }
      localStorage.setItem("username", this.loginForm.username);
      this.$router.push({
        path: "/forgetPwd"
      });
    },
    setCookieByUser() {
      const pwd = encrypt(this.loginForm.password);
      setCookie("nameUser", this.loginForm.username, 10);
      setCookie("passUser", pwd, 10);
    }
  }
};