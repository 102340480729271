var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "110px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.saveForm.merchantName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "merchantName", $$v);
      },
      expression: "saveForm.merchantName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码",
      prop: "licenseNo"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.saveForm.licenseNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "licenseNo", $$v);
      },
      expression: "saveForm.licenseNo"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名",
      prop: "legalPerson"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPerson,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPerson", $$v);
      },
      expression: "saveForm.legalPerson"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件号码",
      prop: "legalPersonIdcard"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.legalPersonIdcard,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "legalPersonIdcard", $$v);
      },
      expression: "saveForm.legalPersonIdcard"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名",
      prop: "contacts"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contacts", $$v);
      },
      expression: "saveForm.contacts"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机",
      prop: "contactMobile"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.contactMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactMobile", $$v);
      },
      expression: "saveForm.contactMobile"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.gjHelperSupport === 1 ? [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "渠道来源"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.gjAgentInfo.oemName,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人手机号"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.gjAgentInfo.gjUserPhone,
      callback: function ($$v) {
        _vm.$set(_vm.gjAgentInfo, "gjUserPhone", $$v);
      },
      expression: "gjAgentInfo.gjUserPhone"
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人名称"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.gjAgentInfo.gjUserName,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "推广人身份"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.gjAgentInfo.gjUserRole,
      disabled: ""
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属服务商"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.gjAgentInfo.gjAgentName,
      disabled: ""
    }
  })], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型",
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: "",
      placeholder: "请选择开票类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "invoiceType", $$v);
      },
      expression: "saveForm.invoiceType"
    }
  }, _vm._l(_vm.merchantInvoiceType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区",
      prop: "cityCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      placeholder: _vm.formatCity()
    },
    model: {
      value: _vm.saveForm.cityCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "cityCode", $$v);
      },
      expression: "saveForm.cityCode"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.saveForm.agentNo ? _c("el-form-item", {
    attrs: {
      label: "所属客户经理",
      prop: "salesmanNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择客户经理",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.salesmanNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "salesmanNo", $$v);
      },
      expression: "saveForm.salesmanNo"
    }
  }, _vm._l(_vm.salesManList, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.salesmanName + "(" + item.salesmanPhone + ")",
        value: item.salesmanNo
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.gjHelperSupport !== 1 ? _c("el-form-item", {
    attrs: {
      label: "所属服务商",
      prop: "agentNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属服务商",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.agentNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "agentNo", $$v);
      },
      expression: "saveForm.agentNo"
    }
  }, _vm._l(_vm.agentList, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.agentName + "(" + item.agentNo + ")",
        value: item.agentNo
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "交易额是否参与业绩奖励",
      prop: "rewardFlag"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.rewardFlag,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rewardFlag", $$v);
      },
      expression: "saveForm.rewardFlag"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "纳税人性质",
      prop: "taxpayerType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择纳税人性质",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.taxpayerType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxpayerType", $$v);
      },
      expression: "saveForm.taxpayerType"
    }
  }, _vm._l(_vm.taxpayerTypeStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "复核手机号码",
      prop: "reviewMobile"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.reviewMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "reviewMobile", $$v);
      },
      expression: "saveForm.reviewMobile"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业联系地址",
      prop: "address"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "address", $$v);
      },
      expression: "saveForm.address"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_vm.partyCode === "SYSTEM" ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否启用任务：",
      prop: "jobSwitch"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.jobSwitch,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "jobSwitch", $$v);
      },
      expression: "saveForm.jobSwitch"
    }
  }, _vm._l(_vm.$formatTypeArray("isTrueFalse"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.jobSwitch === 1 && _vm.partyType !== 1 ? _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务库开关",
      prop: "taskLibSwitch"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择任务库开关",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.taskLibSwitch,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taskLibSwitch", $$v);
      },
      expression: "saveForm.taskLibSwitch"
    }
  }, _vm._l(_vm.$formatTypeArray("switchStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传营业执照",
      prop: "licensePic"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.saveForm.licensePicShow
    },
    on: {
      imageSuccess: _vm.handleAvatarSuccessBylicense
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传身份证",
      prop: "legalPersonIdcardpic"
    }
  }, [_c("upload-image", {
    attrs: {
      imageSrc: _vm.saveForm.legalPersonIdcardpicShow
    },
    on: {
      imageSuccess: _vm.handleAvatarSuccessByICCard
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("修改")])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };