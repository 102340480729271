import ItemTitle from '../../../../base/ui/title/itemTitle';
export default {
  name: 'ViewRate',
  components: {
    ItemTitle
  },
  data() {
    return {};
  },
  props: {
    gotoNext: {
      default: null
    },
    taxNo: '',
    saveForm: {
      accountNo: '',
      agentRate: '',
      appId: '',
      appKey: '',
      bankName: '',
      custName: '',
      personRate: '',
      privateKey: '',
      publicKey: '',
      returnRate: '',
      serviceRate: '',
      supertaxRate: '',
      taxNo: '',
      vatRate: '',
      ncPreposeIp: '',
      ncUserId: '',
      ncUserPwd: ''
    }
  },
  methods: {}
};