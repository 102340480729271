import { timePickerOptions } from '@/utils/util';
import ItemTitle from '@/base/ui/title/itemTitle';
import { isValidFloat01 } from '@/utils/validate_rules';
import { merchantSignInfo, updateTaxMerchantSign } from '@/api/taxMerchant';
import { UPDATE_MERCHANT_SIGN_INFO_ID } from '@/utils/idName';
import FreeView from '../../../../../base/ui/free/freeView';
import UploadFile from '../../../../../base/ui/upload/uploadFile';
import { getAcountInfo } from '../../../../../utils/auth';
import UploadMoreFile from '../../../../../base/ui/upload/uploadMoreFile';
import { queryGjAgentFeeRateReg } from '../../../../../api/merchant';
export default {
  name: 'updateMerchantSign',
  components: {
    UploadMoreFile,
    UploadFile,
    FreeView,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        merchantNo: '',
        protocolEnd: '',
        protocolStart: '',
        protocolUrl: '',
        taxNo: '',
        taxName: '',
        rateType: '',
        rechargeRate: '',
        rateInfoList: [],
        date: '111',
        billMode: '',
        sideAgreementSwitch: 0,
        baseFileList: [],
        sideAgreementUrl: '',
        yearLargeSwitch: '',
        taxYearLargeSwitch: ''
      },
      rules: {
        rateType: [{
          required: true,
          message: '请选择阶梯累计类型',
          trigger: 'blur'
        }],
        rechargeRate: [{
          required: true,
          message: '请输入标准充值费率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        date: [{
          required: true,
          message: '请选择签约日期',
          trigger: 'blur'
        }],
        protocolUrl: [{
          required: true,
          message: '请上传协议附件',
          trigger: 'blur'
        }],
        billMode: [{
          required: true,
          message: '请选择扣款模式',
          trigger: 'blur'
        }],
        cumulativeType: [{
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        }],
        baseRate: [{
          required: true,
          message: '基准服务费计费费率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        baseFileList: [{
          required: true,
          message: '请上传协议',
          trigger: 'blur'
        }],
        yearLargeSwitch: [{
          required: true,
          message: '请选择个人大额出款开关',
          trigger: 'blur'
        }]
      },
      date: '111',
      rateTypeStatus: this.$formatTypeArray('rateTypeStatus'),
      taxSelectList: [],
      freeText: '企业充值金额',
      freeName: '充值阶梯政策',
      rateName: '充值累计类型:',
      partyCode: ''
    };
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.getDetailInfo();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  methods: {
    getDetailInfo() {
      this.partyCode = getAcountInfo().partyCode;
      const id = sessionStorage.getItem(UPDATE_MERCHANT_SIGN_INFO_ID);
      if (id) {
        merchantSignInfo(id).then(response => {
          this.saveForm = response.data;
          this.date = [response.data.protocolStartShow, response.data.protocolEndShow];
          this.saveForm.baseFileList = [];
          response.data.fileList.forEach(item => {
            const file = {
              name: item.fileName,
              url: item.fileUrlShow,
              response: {
                data: {
                  saveKey: item.fileUrl
                },
                code: 'BASE.000'
              }
            };
            this.saveForm.baseFileList.push(file);
          });
          this.chargeTypeChange();
        });
      }
    },
    updateBtn() {
      if (Array.isArray(this.date) && this.date.length === 2) {
        this.saveForm.protocolStart = this.date[0];
        this.saveForm.protocolEnd = this.date[1];
        this.saveForm.date = this.date;
        console.log('============this.date:' + this.saveForm.date);
      } else {
        this.$message({
          message: '请选择签约日期',
          type: 'error'
        });
        return;
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$refs['freeView'].checkFreeAll();
        } else {
          return;
        }
      });
    },
    doSubmit() {
      this.saveForm.protocolUrl = [];
      this.saveForm.baseFileList.forEach(item => {
        this.saveForm.protocolUrl.push(item.response.data.saveKey);
      });
      updateTaxMerchantSign({
        baseFileList: this.saveForm.baseFileList,
        baseRate: this.saveForm.baseRate,
        billMode: this.saveForm.billMode,
        merchantNo: this.saveForm.merchantNo,
        protocolEnd: this.saveForm.protocolEnd,
        protocolStart: this.saveForm.protocolStart,
        protocolUrl: this.saveForm.protocolUrl,
        rateInfoList: this.saveForm.rateInfoList,
        sideAgreementSwitch: this.saveForm.sideAgreementSwitch,
        sideAgreementUrl: this.saveForm.sideAgreementUrl,
        taxNo: this.saveForm.taxNo,
        yearLargeSwitch: this.saveForm.yearLargeSwitch,
        cumulativeType: this.saveForm.cumulativeType,
        id: this.saveForm.id
      }).then(response => {
        this.date = [];
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.goBack();
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    chargeTypeChange() {
      switch (this.saveForm.billMode) {
        case 0:
          {
            this.freeName = '充值阶梯政策';
            this.freeText = '企业充值金额';
            break;
          }
        case 1:
          {
            this.freeName = '出款阶梯政策';
            this.freeText = '个人出款金额';
            break;
          }
        case 2:
          {
            this.freeName = '企业出款阶梯政策';
            this.freeText = '企业出款金额';
            break;
          }
      }
    },
    dateChange(value) {
      this.saveForm.date = value;
    },
    // 新增企业签约劳务公司/修改企业签约劳务公司时，验证商户的最低费率是否低于商户归属国金助手合伙人的费率，进行提示。
    // 接口说明：费率信息在data返回，data值为null时，代表无需验证费率信息(1、系统未开通国金助手；2、商户无国金合伙人关系；)
    // 返回格式：{"code":"0000","errMsg":"success","data":0.05}
    queryGjAgentFeeRate() {
      queryGjAgentFeeRateReg({
        merchantNo: this.saveForm.belong
      }).then(response => {
        const feet = response.data;
        if (feet) {
          if (this.saveForm.rateInfoList[0].rate < feet) {
            this.$confirm('所设置的费率已低于该客户在国金助手的费率,是否确认设置生效?', '提示', {
              type: 'warning',
              closeOnClickModal: false
              // closeOnPressEscape: false,
            }).then(() => {
              this.doSubmit();
            });
          } else {
            this.doSubmit();
          }
        } else {
          this.doSubmit();
        }
      });
    }
  }
};