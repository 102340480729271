import nxIcon from '@/components/nx-icon';
export default {
  name: 'nx-help',
  components: {
    nxIcon
  },
  data() {
    return {
      dialogVisible: false
    };
  }
};