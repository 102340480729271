import { saveFile } from '@/api/upload';
import { beforeUploadPdf } from '@/utils/util';
import { getAcountInfo } from '@/utils/auth';
export default {
  name: 'uploadFile',
  data() {
    return {
      myUrl: '',
      partyCode: '',
      saveFile: saveFile,
      myFileName: ''
    };
  },
  watch: {
    url(val) {
      this.myUrl = val;
    },
    fileName(val) {
      this.myFileName = val;
    }
  },
  props: {
    url: {},
    fileName: '',
    bizCode: {
      type: String,
      default: ''
    }
  },
  created() {
    this.partyCode = getAcountInfo().partyCode;
    this.myFileName = this.fileName;
    this.myUrl = this.url;
  },
  mounted() {
    this.partyCode = this.bizCode || this.partyCode;
  },
  methods: {
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.myUrl = res.data.showUrl;
        this.myFileName = file.raw.name;
      } else {
        this.$message.error(res.errMsg);
      }
      this.$emit('onSuccess', res, file);
    },
    beforeDemoUpload(file) {
      return beforeUploadPdf(this, file);
    }
  }
};