import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import { bannerListReg, deleteBannerReg } from '../../../api/sys';
import ElImage from 'element-ui/packages/image/src/main';
import { BANNER_INFO_ID, BANNER_UPDATE_ID } from '../../../utils/idName';
import table from '@/utils/table';
export default {
  name: 'bannerList',
  components: {
    ElImage,
    Pagination
  },
  data() {
    return {
      listQuery: {
        bankName: '',
        pageNo: 1,
        pageSize: 10
      },
      listLoading: true,
      total: 0,
      list: [],
      addPath: '',
      viewPath: '',
      updatePath: '',
      deleteBank: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.updatePath = map.get('updateBanner');
      this.addPath = map.get('addBanner');
      this.viewPath = map.get('bannerInfo');
      this.deleteBank = map.has('deleteBanner');
    }
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  methods: {
    getQuery() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    // 获取用户列表
    getList() {
      this.listLoading = true;
      bannerListReg(this.listQuery).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.listQuery = {
        bankName: '',
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('删除将不能恢复，你还要继续吗？', '确定需要删除此条数据吗？', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteBannerReg(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    handleAdd() {
      this.$router.push({
        path: this.addPath
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(BANNER_UPDATE_ID, row.id);
      this.$router.push({
        path: this.updatePath
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(BANNER_INFO_ID, row.id);
      this.$router.push({
        path: this.viewPath
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.listQuery.pageNo, this.listQuery.pageSize);
    }
  },
  mounted() {}
};