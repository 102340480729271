import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { isMoneyNumber } from '@/utils/validate_rules';
import { updateTaxThresholdReq } from '../../../../api/taxsource';
export default {
  name: 'updateThresholdDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }],
        presetMonth: [{
          required: true,
          message: '请选择生效月份',
          trigger: 'blur'
        }],
        presetValue: [{
          required: true,
          message: '请输入正确的金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        currentValue: [{
          required: true,
          message: '请输入正确的金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    title: function () {
      if (this.thresholdType == 1) {
        return '修改临登提醒阀值';
      } else {
        return '修改个体户提醒阀值';
      }
    }
  },
  props: {
    dialogVisible: false,
    thresholdType: {
      required: true
    },
    saveForm: {}
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    submitUpload() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.saveForm.thresholdType = this.thresholdType;
          updateTaxThresholdReq({
            thresholdType: this.thresholdType,
            id: this.saveForm.id,
            presetMonth: this.saveForm.presetMonth,
            presetValue: this.saveForm.presetValue
          }).then(response => {
            this.$message.success('操作成功');
            this.myDialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    }
  }
};