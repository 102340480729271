import { updatePartyRole, getSysRole } from '@/api/sys';
import { PARTYROLE_UPDATE_ID } from '@/utils/idName';
export default {
  name: 'updatePartyrole',
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        roleType: '',
        roleId: '',
        partyType: ''
      },
      addRules: {
        roleId: [{
          required: true,
          message: '请选择角色名称',
          trigger: 'change'
        }]
      },
      partyTypeArr: this.$formatTypeArray('partyType'),
      roleTypeArr: this.$formatTypeArray('roleType'),
      roleArr: []
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.getDetailInfo();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  methods: {
    getDetailInfo() {
      this.handleInit();
      const infoJson = sessionStorage.getItem(PARTYROLE_UPDATE_ID);
      if (infoJson) {
        this.editForm = JSON.parse(infoJson);
      }
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          updatePartyRole(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    },
    handleInit() {
      getSysRole().then(res => {
        this.roleArr = res.data;
      });
    }
  },
  mounted() {}
};