import { mapGetters } from 'vuex';
import { getDictGroupInfo, getDictDetailList, addDictDetail, updateDictDetail, deleteDictDetail } from '@/api/dict';
import Pagination from '@/components/pagination';
import { DICTDETAIL_ID } from '@/utils/idName';
import ItemTitle from '@/base/ui/title/itemTitle';
import table from '@/utils/table';
export default {
  name: 'viewDictDetail',
  components: {
    Pagination,
    ItemTitle
  },
  data() {
    return {
      show: false,
      listLoading: true,
      dialogStatus: '',
      textMap: {
        update: '修改字典明细项',
        create: '新增字典明细项'
      },
      dialogFormVisible: false,
      //  表单数据
      total: 0,
      searchForm: {
        dictId: null,
        dictCode: null,
        dictValue: null,
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      viewDictGroupForm: {
        id: null,
        sysCode: null,
        dictGroupCode: null,
        dictGroupName: null
      },
      dictDetailList: [],
      editDictDetailRules: {
        dictCode: [{
          required: true,
          message: '请输入字典Key',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 128,
          message: '字典Key要求1-128位',
          trigger: 'blur'
        }],
        dictValue: [{
          required: true,
          message: '请输入字典Value',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 1024,
          message: '字典Value要求1-1024位',
          trigger: 'blur'
        }]
      },
      // dialog编辑界面数据
      editDictDetailForm: {
        id: null,
        dictId: null,
        dictCode: null,
        dictValue: null,
        remark: null
      },
      addDictDetailPath: '',
      updateDictDetailPath: '',
      deleteDictDetailPath: '',
      addDictDetailVisible: false,
      updateDictDetailVisible: false,
      deleteDictDetailVisible: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getDictGroupInfo();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.deleteDictDetailPath = map.get('deleteDictDetail');
      this.deleteDictDetailVisible = map.get('deleteDictDetail');
      this.updateDictDetailPath = map.get('updateDictDetail');
      this.updateDictDetailVisible = map.has('updateDictDetail');
      this.addDictDetailPath = map.get('addDictDetail');
      this.addDictDetailVisible = map.has('addDictDetail');
    } else {
      this.addDictDetailVisible = false;
      this.updateDictDetailVisible = false;
      this.deleteDictDetailVisible = false;
    }
  },
  methods: {
    getDictGroupInfo() {
      this.listLoading = true;
      const id = sessionStorage.getItem(DICTDETAIL_ID);
      if (id) {
        getDictGroupInfo(id).then(response => {
          this.viewDictGroupForm = response.data;
          this.searchForm.dictId = response.data.id;
          this.dictDetailList = response.data.pageListData.list;
          this.total = response.data.pageListData.totalCount;
        });
      }
      this.listLoading = false;
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.handleQuery();
    },
    handleQuery() {
      this.listLoading = true;
      const id = sessionStorage.getItem(DICTDETAIL_ID);
      if (id) {
        this.searchForm.dictId = this.currentSearch.dictId = id;
        getDictDetailList(this.currentSearch).then(response => {
          this.viewDictGroupForm = response.data;
          this.searchForm.dictId = response.data.id;
          this.dictDetailList = response.data.pageListData.list;
          this.total = response.data.pageListData.totalCount;
        });
      }
      this.listLoading = false;
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.editDictDetailForm = Object.assign({}, row);
    },
    // 显示新增界面
    handleAdd() {
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.editDictDetailForm = {
        id: null,
        dictId: this.viewDictGroupForm.id,
        dictCode: null,
        dictValue: null,
        remark: null
      };
    },
    // 删除
    handleDel(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteDictDetail(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          // this.getDictDetailList(this.viewDictGroupForm.id)
          this.getDictGroupInfo();
        });
      }).catch(() => {});
    },
    // 新增
    addDictDetail() {
      this.$refs.editDictDetailForm.validate(valid => {
        if (valid) {
          this.$confirm('确认新增该字典项吗？', '提示', {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            const para = Object.assign({}, this.editDictDetailForm);
            addDictDetail(para).then(res => {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$refs['editDictDetailForm'].resetFields();
              this.dialogFormVisible = false;
              // this.getDictDetailList(this.viewDictGroupForm.id)
              this.getDictGroupInfo();
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    // 编辑
    updateDictDetail() {
      this.$refs.editDictDetailForm.validate(valid => {
        if (valid) {
          this.$confirm('确认更新该字典项吗？', '提示', {
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            const para = Object.assign({}, this.editDictDetailForm);
            updateDictDetail(para).then(res => {
              this.$message({
                message: '更新成功',
                type: 'success'
              });
              this.$refs['editDictDetailForm'].resetFields();
              this.dialogFormVisible = false;
              // this.getDictDetailList(this.viewDictGroupForm.id)
              this.getDictGroupInfo();
            });
          }).catch(e => {
            // 打印一下错误
            console.log(e);
          });
        }
      });
    },
    clearCondition() {
      this.searchForm = {
        dictId: null,
        dictCode: null,
        dictValue: null,
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    }
    // 返回
    /*
    goBack() {
      const { path, name } = this.$router.currentRoute
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1)
      })
    },
     */
  },
  mounted() {}
};