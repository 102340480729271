export default {
  name: 'address-item',
  props: {
    selected: {
      type: Boolean,
      required: true
    },
    address: {
      type: Object,
      required: true
    }
  },
  computed: {
    alias: function () {
      return this.address.label ? '(' + this.address.label + ')' : '';
    }
  },
  methods: {
    click() {
      this.$emit('click', this.address);
    }
  }
};