import { mapGetters } from 'vuex';
import { homePageInfoReq } from '@/api/transaction';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import ProcessItem from './components/processItem';
import MenuItem from './components/menuItem';
import TransactionChart from './components/transactionChart';
import DataReportView from './dataReport/dataReportView';
import { queryWindowNotice } from '../../api/sys';
import NoticeDialog from './components/noticeDialog';
import NoticeGongGao from './components/noticeGongGao';
export default {
  name: 'home-page-index',
  components: {
    NoticeGongGao,
    NoticeDialog,
    DataReportView,
    ItemTitle,
    ProcessItem,
    MenuItem,
    TransactionChart
  },
  data() {
    return {
      partyType: '',
      userType: '',
      totalAmount: '',
      count: '',
      transactions: [],
      chartSettings: {
        yAxisName: ['元'],
        legendName: {
          amount: '交易金额'
        }
      },
      chartData: {
        columns: ['days', 'amount']
      },
      noticeItem: {},
      noticeDialogVisible: false,
      noticeItemIndex: 0,
      noticeList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    formatterAmount: function () {
      return this.$fmoneyWithSymbol(this.totalAmount);
    }
  },
  created() {
    // const map = this.buttonsMap.get(this.$route.path)
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.userType = user.usertype;
    if (this.partyType === 3) {
      this.getInfo();
    }
    if (this.partyType === 3 || this.partyType === 2) {
      this.getMsgNotice();
    }
  },
  methods: {
    getInfo() {
      homePageInfoReq().then(res => {
        this.count = res.data.count;
        this.totalAmount = res.data.totalAmount;
        this.chartData = {
          columns: ['days', 'amount'],
          rows: res.data.dayStatList
        };
      });
    },
    goToAccountPage() {
      this.$router.push({
        name: 'accountInfoIndex'
      });
    },
    gotoMenu(index) {
      let path = '';
      switch (index) {
        case 1:
          path = '/account/info'; // 账户充值
          break;
        case 2:
          path = '/employee/employeeList'; // 人员录入
          break;
        case 3:
          path = '/transaction/apply'; // 批量付款
          break;
        case 4:
          path = '/invoice/apply'; // 申请开票
          break;
      }
      this.$router.push({
        path: path
      });
    },
    getMsgNotice() {
      queryWindowNotice().then(response => {
        const list = response.data;
        if (list.length > 0) {
          this.noticeItemIndex = 0;
          this.noticeList = list;
          this.showNoticeDialog();
        }
      });
    },
    changeVisibleNoticeDialog(val) {
      this.noticeDialogVisible = val;
      setTimeout(() => {
        this.showNoticeDialog();
      }, 100);
    },
    showNoticeDialog() {
      if (this.noticeItemIndex > this.noticeList.length - 1) {
        return;
      }
      this.noticeItem = this.noticeList[this.noticeItemIndex];
      this.noticeDialogVisible = true;
      this.noticeItemIndex++;
    }
  }
};