import { sysMessageInfo } from '../../../api/sys';
import { SYS_MESSAGE_INFO_ID } from '../../../utils/idName';
import { decode } from 'js-base64';
export default {
  name: 'sysMessageInfo-nocache',
  data() {
    return {
      info: {
        taxNo: '',
        noticeType: '',
        title: '',
        popupFlag: 0,
        openType: '',
        subTitle: '',
        contents: '',
        accepterType: '',
        accepterList: '',
        publishType: '',
        effectTime: '',
        invalidTime: ''
      }
    };
  },
  computed: {
    accountTitle() {
      if (this.info.noticeType === 2) {
        return '用户手机号';
      } else {
        return '用户账号';
      }
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    decodeContents(content) {
      return decode(content);
    },
    getInfo() {
      const id = sessionStorage.getItem(SYS_MESSAGE_INFO_ID);
      sysMessageInfo(id).then(response => {
        this.info = response.data;
      });
    }
  }
};