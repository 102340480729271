var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "查看劳务公司费率",
      visible: _vm.myDialogFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    staticClass: "save-base",
    attrs: {
      "label-width": "12rem",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司名称："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.item.taxName) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "标准充值费率："
    }
  }, [_vm._v("\n        " + _vm._s((_vm.item.rechargeRate * 100).toFixed(2)) + "%\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "阶梯累计类型："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.formatStatus(_vm.item.rateType)) + "\n      ")])], 1), _vm._v(" "), _vm.item.rateListInfo && _vm.item.rateListInfo.length > 0 ? _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("item-title", {
    attrs: {
      name: "阶梯费率政策"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("free-view", {
    ref: "freeView",
    attrs: {
      list: _vm.item.rateListInfo,
      text: _vm.freeText,
      name: _vm.freeName,
      isUpdate: false
    }
  })], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.item.logList,
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作员",
      "min-width": "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "描述",
      "min-width": "200"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };