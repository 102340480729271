import request from '@/utils/request';
import { encrypt } from '@/utils/encryptionAES';
export const setPwd = params => {
  let {
    password,
    newPassword
  } = params;
  password = encrypt(password);
  newPassword = encrypt(newPassword);
  return request({
    url: '/sys/user/updatePassword',
    method: 'post',
    data: {
      password,
      newPassword
    }
  });
};

/**
 * 重置充值复核密码
 */
export const updateResetRechargePwd = params => {
  let {
    password,
    newPassword,
    verifyCode
  } = params;
  password = encrypt(password);
  newPassword = encrypt(newPassword);
  return request({
    url: '/tax/taxsource/resetRechargePwd',
    method: 'post',
    data: {
      password,
      newPassword,
      verifyCode
    }
  });
};

/**
 * 获取详细信息
 */
export const getAppInfo = id => {
  return request({
    url: '/oauth2/appinfo/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 开通API接口参数
 */
export const openApp = () => {
  return request({
    url: '/oauth2/appinfo/openApp',
    method: 'post'
  });
};

/**
 * 重置签名秘钥
 */
export const restAppKey = () => {
  return request({
    url: '/oauth2/appinfo/restAppKey',
    method: 'get'
  });
};

/**
 * 获取企业公私钥
 */
export const generateKeys = type => {
  return request({
    url: '/oauth2/appinfo/generate',
    method: 'post',
    data: {
      type
    }
  });
};

/**
 * 更新应用信息
 */
export const updateAppInfo = params => {
  const {
    callBackUrl,
    id,
    publicKey,
    algorithmType
  } = params;
  return request({
    url: '/oauth2/appinfo/update',
    method: 'post',
    data: {
      callBackUrl,
      id,
      publicKey,
      algorithmType
    }
  });
};

/**
 * 获取客户密钥管理列表
 */
export const getAppInfoList = params => {
  return request({
    url: '/oauth2/appinfo/list',
    method: 'post',
    data: params
  });
};

/**
 * 运营修改接入配置
 */
export const updateConfig = params => {
  return request({
    url: '/oauth2/appinfo/updateConfig',
    method: 'post',
    data: params
  });
};

/**
 *获取加密算法类型及对应平台公钥
 */
export const getAlgorithmTypes = () => {
  return request({
    url: '/oauth2/appinfo/algorithmTypes',
    method: 'get'
  });
};