import { makeOutInvoiceReq } from '@/api/invoice';
import { mchntProjectInfo } from '@/api/merchant';
import { queryMakeInvoiceContents } from '@/api/common';
import ItemTitle from '@/base/ui/title/itemTitle';
import { fmoneyWithYuan } from '@/utils/formatter';
import { isDecimal } from '@/utils/validate_rules';
import DetailColumn from '../../../components/detailColumn/index';
export default {
  name: 'makeInvoice',
  components: {
    DetailColumn,
    ItemTitle
  },
  data() {
    return {
      addForm: {
        ticketAmount: '',
        invoiceType: '',
        contents: '',
        remark: '',
        projectCaption: '',
        projectName: '',
        valuationType: '',
        projectEndDate: '',
        projectStartDate: '',
        datePicker: []
      },
      filename: '',
      availableAmount: '',
      contentList: [],
      rules: {
        ticketAmount: [{
          required: true,
          message: '请输入开票金额',
          trigger: 'blur'
        }, {
          validator: isDecimal,
          trigger: 'blur'
        }],
        contents: [{
          required: true,
          message: '请选择开票内容',
          trigger: 'change'
        }],
        invoiceType: [{
          required: true,
          message: '请选择开票类型',
          trigger: 'change'
        }],
        projectCaption: [{
          required: true,
          message: '请输入项目说明',
          trigger: 'blur'
        }],
        valuationType: [{
          required: true,
          message: '请选择项目计价方式',
          trigger: 'change'
        }],
        projectName: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'change'
        }, {
          min: 1,
          max: 30,
          message: '超过最大长度30个字符',
          trigger: 'blur'
        }],
        datePicker: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }]
      },
      makeInvoiceInfo: {},
      exampleProjectList: [],
      projectInfo: {}
    };
  },
  created() {
    this.init();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.init();
    });
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    formatAmount: function () {
      this.availableAmount = this.makeInvoiceInfo.unticketTotal;
      return fmoneyWithYuan(this.availableAmount);
    }
  },
  methods: {
    init() {
      this.makeInvoiceInfo = JSON.parse(sessionStorage.getItem('makeInvoice'));
      this.getContents();
      this.getInvoiceType();
      this.mchntProjectInfo();
    },
    async mchntProjectInfo() {
      const param = {
        projectNo: this.makeInvoiceInfo.projectNo
      };
      const res = await mchntProjectInfo(param);
      this.projectInfo = Object.assign({}, res.data);
      this.addForm.remark = `项目名称：${this.projectInfo.projectName}\n项目地址：${this.projectInfo.province}${this.projectInfo.city}${this.projectInfo.area}${this.projectInfo.address}`;
    },
    setProjectCaption(value) {
      console.log('setProjectCaption=====' + value);
      this.addForm.projectName = value;
    },
    getTaxpayerType() {
      // 0-差额发票，1-全额发票
      this.addForm.invoiceType = 1;
      // if (this.makeInvoiceInfo.merchantType == 1) {
      //   if (this.makeInvoiceInfo.taxpayerType == 0) {
      //     this.addForm.invoiceType = ''
      //   } else {
      //     this.addForm.invoiceType = 0
      //   }
      // }
    },
    getInvoiceType() {
      // 0-差额发票，1-全额发票
      this.addForm.invoiceType = 1;
      // if (this.makeInvoiceInfo.merchantType == 0) {
      //   this.addForm.invoiceType = 2
      // } else {
      //   if (this.makeInvoiceInfo.taxpayerType == 0) {
      //     this.addForm.invoiceType = ''
      //   } else {
      //     this.addForm.invoiceType = 0
      //   }
      // }
    },
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          const amount = Number.parseFloat(this.addForm.ticketAmount);
          if (Number.isNaN(amount)) {
            this.$message.error('请输入正确的开票金额');
            return;
          }
          if (amount <= 0) {
            this.$message.error('开票金额必须大于0');
            return;
          }
          if (amount > this.availableAmount) {
            this.modalToast();
            return;
          }
          if (this.addForm.datePicker.length) {
            this.addForm.projectEndDate = this.addForm.datePicker[1];
            this.addForm.projectStartDate = this.addForm.datePicker[0];
          }
          this.makeInvoice(0);
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    modalToast() {
      this.$confirm('超过可开票金额！是否确定继续提交！', '温馨提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.makeInvoice(1);
      }).catch(() => {});
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    makeInvoice(exceedQuota) {
      const param = {
        ...this.addForm,
        exceedQuota,
        addressId: this.makeInvoiceInfo.addressId,
        merchantNo: this.makeInvoiceInfo.merchantNo,
        taxNo: this.makeInvoiceInfo.taxNo
      };
      makeOutInvoiceReq(param).then(() => {
        this.$message.success('开票申请成功...');
        this.showConfirm();
        // this.goBack()
      });
    },
    getContents() {
      // const code = (this.merchantType === '0' || this.merchantType === 0) ? 'biz_invoice_diff' : 'biz_invoice_content'
      if (this.makeInvoiceInfo && this.makeInvoiceInfo.taxNo) {
        queryMakeInvoiceContents(this.makeInvoiceInfo.taxNo, 1).then(response => {
          this.contentList = response.data;
        });
      }
    },
    contentsChange() {
      const _self = this;
      this.contentList.find(function (item) {
        if (item.contentName === _self.addForm.contents) {
          _self.exampleProjectList = item.exampleProjectList;
          return;
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    showConfirm() {
      this.$alert('申请已提交，请前往“开票记录”查看，平台将会在三个工作日内出票并寄出！', '温馨提示', {
        type: 'warning',
        confirmButtonText: '前往开票记录',
        center: true,
        closeOnClickModal: false
      }).then(() => {
        this.$router.push({
          path: '/invoice/list'
        });
      });
    }
  }
};