import { timePickerOptions } from '@/utils/util';
import ItemTitle from '../../../base/ui/title/itemTitle';
import { isValidFloat01 } from '@/utils/validate_rules';
import FreeView from '../../../base/ui/free/freeView';
import { RATE_UPDATE_AGENT_INFO } from '../../../utils/idName';
import DetailColumn from '../../../components/detailColumn/index';
import { getAgentProfitRuleInfo, updateAgentProfitRule } from '../../../api/agent';
export default {
  name: 'updateAgentRate',
  components: {
    DetailColumn,
    FreeView,
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        agentNo: '',
        taxNo: '',
        profitExplain: '',
        rewardExplain: '',
        ratioExplain: '',
        profitInfo: [],
        profitRatio: [],
        rewardInfo: [],
        profitType: ''
      },
      profitRateList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      ratioRateList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      rewardRateList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      rules: {
        rateType: [{
          required: true,
          message: '请选择阶梯累计类型',
          trigger: 'blur'
        }],
        rechargeRate: [{
          required: true,
          message: '请输入标准充值费率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        profitType: [{
          required: true,
          message: '业绩分润阶梯费率类型',
          trigger: 'blur'
        }]
      },
      date: [],
      fileList: [],
      rateTypeStatus: this.$formatTypeArray('agentRateTypeStatus'),
      freeText: '充值金额',
      freeName: '阶梯政策',
      infoForm: {},
      rewardDoSubmitFlag: false,
      profitDoSubmitFlag: false,
      ratioDoSubmitFlag: false
    };
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  created() {
    const infoFormJson = sessionStorage.getItem(RATE_UPDATE_AGENT_INFO);
    if (infoFormJson) {
      this.infoForm = JSON.parse(infoFormJson);
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      getAgentProfitRuleInfo(this.infoForm.id).then(response => {
        this.saveForm = response.data;
        this.profitRateList = this.saveForm.profitInfoList;
        this.ratioRateList = this.saveForm.profitRatioList;
        this.rewardRateList = this.saveForm.rewardInfoList;
      });
    },
    updateBtn() {
      this.saveForm.agentNo = this.infoForm.agentNo;
      this.saveForm.taxNo = this.infoForm.taxNo;
      this.saveForm.profitInfo = this.profitRateList;
      this.saveForm.profitRatio = this.ratioRateList;
      this.saveForm.rewardInfo = this.rewardRateList;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$refs['profitFreeView'].checkFreeAll();
          this.$refs['ratioFreeView'].checkFreeAll();
          this.$refs['rewardFreeView'].checkFreeAll();
        }
      });
    },
    doSubmit() {
      if (this.rewardDoSubmitFlag && this.profitDoSubmitFlag && this.ratioDoSubmitFlag) {
        updateAgentProfitRule(this.saveForm).then(response => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.goBack();
        });
      }
    },
    rewardDoSubmit() {
      this.rewardDoSubmitFlag = true;
      this.doSubmit();
    },
    profitDoSubmit() {
      this.profitDoSubmitFlag = true;
      this.doSubmit();
    },
    ratioDoSubmit() {
      this.ratioDoSubmitFlag = true;
      this.doSubmit();
    },
    // endChange(index) { // 费率输入框回调
    //   console.log('======index' + index + ';this.saveForm.rateInfoList.length:' + this.rateInfoList.length)
    //   if (this.rateInfoList.length - 1 > index) {
    //     this.rateInfoList[index + 1].start = this.rateInfoList[index].end
    //   }
    // },
    chargeTypeChange() {
      // if (this.saveForm.chargeType === 0) {
      //   this.freeName = '充值阶梯政策'
      //   this.freeText = '企业充值金额'
      // } else {
      //   this.freeName = '出款阶梯政策'
      //   this.freeText = '个人出款金额'
      // }
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};