import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { beforUploadByExcel } from '../../../../utils/util';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'saveWtdzdjDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E5%A7%94%E6%89%98%E4%BB%A3%E5%BE%81%E7%99%BB%E8%AE%B0%E6%A8%A1%E6%9D%BF.xlsx',
      uploadApplyFile: BASE_URL + '/taxdj/taxWtdzdj/saveWtdzdj',
      taxList: [],
      exData: {
        taxNo: '',
        djMonth: ''
      },
      myDialogFormVisible: false,
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'blur'
        }],
        djMonth: [{
          required: true,
          message: '请选择申报月份',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dialogVisible: false,
    id: ''
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      if (val) {
        this.getTaxsList();
      }
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.taxList = response.data;
    });
  },
  methods: {
    submitUpload() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs.upload.clearFiles();
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxList = response.data;
      });
    },
    beforeUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.myDialogFormVisible = false;
        this.$alert(res.data, '提示', {
          confirmButtonText: '知道了',
          center: true,
          closeOnClickModal: false
        });
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    }
  }
};