export default {
  name: 'menu-item',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    detail: {
      type: String,
      required: true
    }
  }
};