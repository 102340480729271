import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { showlist } from '@/api/taxsource';
import ItemTitle from '@/base/ui/title/itemTitle';
import { VIEW_INVOICE_CONTENT_ID } from '@/utils/idName';
import { getAcountInfo } from '@/utils/auth';
import { downloadRefund, refundAudit, refundList } from '@/api/account';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'refundList',
  components: {
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        bussincessNo: '',
        merchantName: '',
        merchantNo: '',
        status: '',
        // 退款状态(0-待确认,1-待记账,2-已记账,9-无效)
        taxNo: '',
        submitTime: [],
        // 提交时间起
        completeTime: [] // 完成时间
      },
      currentSearch: {},
      partyType: '',
      taxName: '',
      taxSources: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      auditDialogVisible: false,
      viewRefundInfo: '',
      hasDownloadRefund: false,
      hasRefundAudit: false,
      auditId: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    rechargeTypeOptions: function () {
      return this.$formatTypeArray('refundStatus');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadRefund = map.has('downloadRefund');
      this.hasRefundAudit = map.has('refundAudit');
      this.viewRefundInfo = map.get('viewRefundInfo');
    } else {
      this.viewRefundInfo = '';
      this.hasDownloadRefund = false;
      this.hasRefundAudit = false;
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else {
      this.getTaxSources();
    }
  },
  methods: {
    concatMerchant(tax) {
      return `${tax.merchantName}(${tax.merchantNo})`;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      const submitTime = separateDate(this.currentSearch.submitTime);
      const completeTime = separateDate(this.currentSearch.completeTime);
      const params = {
        bussincessNo: this.currentSearch.bussincessNo,
        merchantName: this.currentSearch.merchantName,
        merchantNo: this.currentSearch.merchantNo,
        status: this.currentSearch.status,
        taxNo: this.currentSearch.taxNo,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        submitBeginTime: submitTime.beginTime,
        submitEndTime: submitTime.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      downloadRefund(params).then(response => {
        downloadExcel(response.data, '退款记录.xls');
      });
    },
    gotoRefundInfo(row) {
      sessionStorage.setItem(VIEW_INVOICE_CONTENT_ID, row.id);
      this.$router.push({
        path: this.viewRefundInfo
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const submitTime = separateDate(this.currentSearch.submitTime);
      const completeTime = separateDate(this.currentSearch.completeTime);
      refundList({
        bussincessNo: this.currentSearch.bussincessNo,
        merchantName: this.currentSearch.merchantName,
        merchantNo: this.currentSearch.merchantNo,
        status: this.currentSearch.status,
        taxNo: this.currentSearch.taxNo,
        completeBeginTime: completeTime.beginTime,
        completeEndTime: completeTime.endTime,
        submitBeginTime: submitTime.beginTime,
        submitEndTime: submitTime.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    refundAuditConfirm(status) {
      refundAudit({
        id: this.auditId,
        status: status
      }).then(() => {
        this.$message.success('操作成功');
        this.auditDialogVisible = false;
        this.getList();
      });
    },
    showAuditDialog(row) {
      this.auditId = row.id;
      this.auditDialogVisible = true;
    }
  }
};