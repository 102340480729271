import request from '@/utils/request';
import axios from 'axios';
import { getAuthSession } from '@/utils/auth';

/**
 * 获取人员签约列表信息
 */
export const empSignList = params => {
  return request({
    url: '/employee/merchant/protocollist',
    method: 'post',
    data: params
  });
};

/**
 * 恢复人员签约记录
 */
export const activateEmpSign = id => {
  return request({
    url: '/employee/merchant/activate',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 解约人员签约信息
 */
export const cancelEmpSign = id => {
  return request({
    url: '/employee/merchant/cancel',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 *下载签约列表信息
 */
export async function downloadSignList(params) {
  let res;
  await axios({
    url: process.env.BASE_API + '/employee/merchant/download',
    method: 'post',
    headers: {
      'X-AUTH-SESSION': getAuthSession()
    },
    data: params,
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/**
 * 预览数据分页查询
 */
export const viewPageList = params => {
  const {
    merchantNo,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/employee/merchant/importPage',
    method: 'post',
    data: {
      merchantNo,
      pageNo,
      pageSize
    }
  });
};
/**
 * 批量数据提交保存
 */
export const saveImport = params => {
  const {
    merchantNo
  } = params;
  return request({
    url: '/employee/merchant/saveImport',
    method: 'post',
    data: {
      merchantNo
    }
  });
};

/**
 * 查看人员签约详细信息
 */
export const empSignInfo = id => {
  return request({
    url: '/employee/merchant/signInfo',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 获取人员列表信息
 */
export const empList = params => {
  return request({
    url: '/employee/merchant/list',
    method: 'post',
    data: params
  });
};

/**
 * 新增人员信息
 */
export const saveEmp = params => {
  const {
    accountNo,
    bankName,
    empName,
    idcard,
    phone,
    taxNo
  } = params;
  return request({
    url: '/employee/merchant/save',
    method: 'post',
    data: {
      accountNo,
      bankName,
      empName,
      idcard,
      phone,
      taxNo
    }
  });
};

/**
 * 修改人员信息
 */
export const updateEmp = params => {
  const {
    accountNo,
    bankName,
    id,
    phone,
    status,
    empName
  } = params;
  return request({
    url: '/employee/merchant/update',
    method: 'post',
    data: {
      accountNo,
      bankName,
      id,
      phone,
      status,
      empName
    }
  });
};

/**
 * 下载人员列表信息
 */
export async function downloadList(params) {
  let res;
  await axios({
    url: process.env.BASE_API + '/employee/merchant/download',
    method: 'post',
    headers: {
      'X-AUTH-SESSION': getAuthSession()
    },
    data: params,
    responseType: 'blob'
  }).then(response => {
    res = response;
  });
  return res;
}

/**
 * 获取企业小程序二维码
 */
export const getwxacode = () => {
  return request({
    url: '/mchntProject/wxappCode',
    method: 'post'
  });
};