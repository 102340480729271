var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg",
    staticStyle: {
      height: "100%"
    }
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    staticStyle: {
      width: "40%",
      height: "100%"
    },
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题：",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入标题"
    },
    model: {
      value: _vm.saveForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "title", $$v);
      },
      expression: "saveForm.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "顺序：",
      prop: "seqNum"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入顺序"
    },
    model: {
      value: _vm.saveForm.seqNum,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "seqNum", $$v);
      },
      expression: "saveForm.seqNum"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "banner组：",
      prop: "appGroup"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入banner组"
    },
    model: {
      value: _vm.saveForm.appGroup,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "appGroup", $$v);
      },
      expression: "saveForm.appGroup"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属系统：",
      prop: "appCode"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择所属系统",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.appCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "appCode", $$v);
      },
      expression: "saveForm.appCode"
    }
  }, _vm._l(_vm.$formatTypeArray("appCode"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "status", $$v);
      },
      expression: "saveForm.status"
    }
  }, _vm._l(_vm.$formatTypeArray("bannerStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "概述："
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入概述",
      type: "textarea",
      maxLength: "200",
      "show-word-limit": "",
      row: 2
    },
    model: {
      value: _vm.saveForm.summary,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "summary", $$v);
      },
      expression: "saveForm.summary"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "banner图："
    }
  }, [_c("upload-image", {
    attrs: {
      saveFile: _vm.saveFile
    },
    on: {
      imageSuccess: _vm.handleSuccessLogUrl
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addBtn
    }
  }, [_vm._v("保存")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };