import img_404 from '@/assets/images/404_images/404.png';
import img_404_cloud from '@/assets/images/404_images/404_cloud.png';
export default {
  name: 'page404',
  data() {
    return {
      img_404,
      img_404_cloud
    };
  },
  computed: {
    message() {
      return '特朗普说这个页面你不能进......';
    }
  }
};