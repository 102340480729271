import ItemTitle from '@/base/ui/title/itemTitle';
import { updateTaxRouterOpen } from '../../../../api/taxRouterOpen';
import { isValidFloat01 } from '@/utils/validate_rules';
export default {
  name: 'AddTaxRouterOpenDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        id: '',
        diffRechargeRate: '',
        rechargeRate: '',
        status: ''
      },
      myDialogFormVisible: false,
      belongList: [],
      taxRouterList: [],
      taxRouterStatus: this.$formatTypeArray('taxRouterStatus'),
      rules: {
        diffRechargeRate: [{
          required: true,
          message: '请输入付款时的充值费率(差额)',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        rechargeRate: [{
          required: true,
          message: '请输入付款时的充值费率(增值)',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    updateDialogVisible: false,
    item: ''
  },
  watch: {
    updateDialogVisible(val) {
      this.myDialogFormVisible = val;
      if (val) {
        this.saveForm.rechargeRate = this.item.rechargeRate;
        this.saveForm.diffRechargeRate = this.item.diffRechargeRate;
        this.saveForm.status = this.item.status;
      }
    },
    myDialogFormVisible(val) {
      this.$emit('changeUpdateDialogVisible', val);
    }
  },
  methods: {
    updateBtn() {
      this.saveForm.id = this.item.id;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateTaxRouterOpen(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};