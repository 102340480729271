import request from '@/utils/request';
export const addDictGroup = params => {
  const {
    sysCode,
    dictGroupCode,
    dictGroupName
  } = params;
  return request({
    url: '/sys/dict/save',
    method: 'post',
    data: {
      sysCode,
      dictGroupCode,
      dictGroupName
    }
  });
};
export const updateDictGroup = params => {
  const {
    id,
    sysCode,
    dictGroupCode,
    dictGroupName
  } = params;
  return request({
    url: '/sys/dict/updateDict',
    method: 'post',
    data: {
      id,
      sysCode,
      dictGroupCode,
      dictGroupName
    }
  });
};
export const addDictDetail = params => {
  const {
    dictId,
    dictCode,
    dictValue,
    remark
  } = params;
  return request({
    url: '/sys/dict/saveDictDetail',
    method: 'post',
    data: {
      dictId,
      dictCode,
      dictValue,
      remark
    }
  });
};
export const deleteDictDetail = params => {
  const id = params;
  const url = '/sys/dict/deleteDictDetail';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const updateDictDetail = params => {
  const {
    id,
    dictId,
    dictCode,
    dictValue,
    remark
  } = params;
  return request({
    url: '/sys/dict/updateDictDetail',
    method: 'post',
    data: {
      id,
      dictId,
      dictCode,
      dictValue,
      remark
    }
  });
};
export const getDictGroupInfo = params => {
  const id = params;
  const url = '/sys/dict/info';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const getDictGroupList = params => {
  const {
    sysCode,
    dictGroupCode,
    dictGroupName,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/sys/dict/list',
    method: 'post',
    data: {
      sysCode,
      dictGroupCode,
      dictGroupName,
      pageNo,
      pageSize
    }
  });
};
export const getDictDetailList = params => {
  const {
    dictId,
    dictCode,
    dictValue,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/sys/dict/detaillist',
    method: 'post',
    data: {
      dictId,
      dictCode,
      dictValue,
      pageNo,
      pageSize
    }
  });
};

// export const getDetailListById = params => {
//   const { dictId } = params
//   return request({
//     url: '/sys/dict/list',
//     method: 'post',
//     data: {
//       dictId
//     }
//   })
// }