import ItemTitle from '../title/itemTitle';
import FreeItem from './freeItem';
export default {
  components: {
    FreeItem,
    ItemTitle
  },
  props: {
    list: {
      required: true,
      type: Array
    },
    name: {
      type: String,
      default() {
        return '阶梯费率政策';
      }
    },
    max: {
      type: Number,
      default() {
        return 5;
      }
    },
    text: {
      required: true
    },
    isUpdate: {
      type: Boolean,
      default: true
    },
    isShowBottom: {
      type: Boolean,
      default: true
    },
    typeName: {
      type: String,
      default() {
        return '费率';
      }
    }
  },
  methods: {
    addProduct() {
      if (this.list.length < this.max) {
        // 充值代付最多允许添加三条
        this.list.push({
          start: this.list.length > 0 ? this.list[this.list.length - 1].end : 0,
          end: '',
          attach: '',
          feeRate: ''
        });
      }
    },
    removeProduct() {
      console.log('=========removeProduct=');
      const length = this.list.length;
      if (length > 1) {
        this.list.splice(length - 1, 1);
      }
    },
    endChange(index) {
      // 费率输入框回调
      console.log('======index' + index + ';this.list.vue.length:' + this.list.length);
      if (this.list.length - 1 > index) {
        this.list[index + 1].start = this.list[index].end;
      }
    },
    checkFreeItem(val) {
      if (val) {
        this.checkFreeItemTimes++;
      }
      if (this.checkFreeItemTimes === this.list.length) {
        this.$emit('doSubmit');
        return true;
      }
      return false;
    },
    checkFreeAll() {
      this.checkFreeItemTimes = 0;
      this.$refs.freeItem.forEach(item => {
        item.validateForm(); // 调用费率控件的方法
      });
    }
  }
};