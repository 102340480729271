import { updateAgentTaxs } from '@/api/agent';
import { timePickerOptions } from '@/utils/util';
import ItemTitle from '../../../base/ui/title/itemTitle';
import { isValidFloat01 } from '@/utils/validate_rules';
import FreeView from '../../../base/ui/free/freeView';
export default {
  name: 'UpdateAgentTaxDialog',
  components: {
    FreeView,
    ItemTitle
  },
  data() {
    return {
      saveForm: {},
      rateInfoList: [{
        start: 0,
        end: '',
        rate: ''
      }],
      myDialogFormVisible: false,
      rules: {
        rateType: [{
          required: true,
          message: '请选择阶梯累计类型',
          trigger: 'blur'
        }],
        rechargeRate: [{
          required: true,
          message: '请输入标准充值费率',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }]
      },
      date: [],
      fileList: [],
      rateTypeStatus: this.$formatTypeArray('agentRateTypeStatus'),
      freeText: '充值金额',
      freeName: '阶梯政策'
    };
  },
  props: {
    dialogFormVisible: false,
    merchantNo: '',
    item: {},
    agentNo: ''
  },
  watch: {
    dialogFormVisible(val) {
      console.log('===watch===dialogFormVisible==');
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      if (val === false) {
        this.clearData();
      }
      console.log('===watch===myDialogFormVisible==');
      this.$emit('changeVisible', val);
    },
    item(val) {
      this.saveForm = val;
      if (val.rateListInfo.length > 0) {
        this.rateInfoList = val.rateListInfo;
      }
      this.chargeTypeChange();
    }
  },
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  methods: {
    updateBtn() {
      this.saveForm.agentNo = this.agentNo;
      this.saveForm.rateInfoList = this.rateInfoList;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          if (this.saveForm.rateType === 0) {
            this.doSubmit();
          } else {
            this.$refs['freeView'].checkFreeAll();
          }
        }
      });
    },
    checkFreeItem(val) {
      if (val) {
        this.checkFreeItemTimes++;
      }
      if (this.checkFreeItemTimes === this.rateInfoList.length) {
        this.doSubmit();
      } else {
        this.myDialogFormVisible = true;
      }
    },
    doSubmit() {
      updateAgentTaxs(this.saveForm).then(response => {
        console.log('======updateAgentTaxs=');
        this.myDialogFormVisible = false;
        this.date = [];
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.clearData();
      });
    },
    endChange(index) {
      // 费率输入框回调
      console.log('======index' + index + ';this.saveForm.rateInfoList.length:' + this.rateInfoList.length);
      if (this.rateInfoList.length - 1 > index) {
        this.rateInfoList[index + 1].start = this.rateInfoList[index].end;
      }
    },
    clearData() {
      this.$refs['saveForm'].resetFields();
      this.rateInfoList = [{
        start: 0,
        end: '',
        rate: ''
      }];
    },
    chargeTypeChange() {
      // if (this.saveForm.chargeType === 0) {
      //   this.freeName = '充值阶梯政策'
      //   this.freeText = '企业充值金额'
      // } else {
      //   this.freeName = '出款阶梯政策'
      //   this.freeText = '个人出款金额'
      // }
    }
  }
};