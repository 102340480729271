var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg task-base"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务名称：",
      value: _vm.addForm.taskName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务编号：",
      value: _vm.addForm.taskNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "劳务公司名称：",
      value: _vm.addForm.taxName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "劳务公司编号：",
      value: _vm.addForm.taxNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "商户名称：",
      value: _vm.addForm.merchantName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "商户编号：",
      value: _vm.addForm.merchantNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务类型：",
      value: _vm.addForm.taskTypeName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务计价方式：",
      value: _vm.addForm.pricingModeName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务金额(元)：",
      value: _vm.amountValue(),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务有效期：",
      value: _vm.$formatDate(_vm.addForm.startDate) + "至" + _vm.$formatDate(_vm.addForm.endDate),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "是否公开邀约：",
      value: _vm.$formatType("isTrueFalse", _vm.addForm.invitePublic),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "创建时间：",
      value: _vm.addForm.createTime,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "任务描述：",
      value: _vm.addForm.caption,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "任务成果模版：",
      value: _vm.addForm.templeteName,
      left: false
    }
  })], 1), _vm._v(" "), _vm.addForm.fileList.length > 0 ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "detail-column-title"
  }, [_vm._v("任务附件：")]), _vm._v(" "), _c("div", {
    staticClass: "link-view"
  }, _vm._l(_vm.addForm.fileList, function (item, index) {
    return _c("el-link", {
      key: index,
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.downloadUrl(item);
        }
      }
    }, [_vm._v("\n          " + _vm._s(item.fileName) + "\n        ")]);
  }), 1)]) : _vm._e()]), _vm._v(" "), _vm.addForm.captionInfo ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "任务描述详情："
    }
  }), _vm._v(" "), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.addForm.captionInfo)
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.addForm.logList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.addForm.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isReview ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "任务审核"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "reviewForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.reviewForm,
      "label-position": "right",
      "label-width": "100px",
      size: "mini",
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "审核结果",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.reviewForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "status", $$v);
      },
      expression: "reviewForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("审核通过")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 9
    }
  }, [_vm._v("审核驳回")]), _vm._v(" "), _vm.isCheckReview != "1" ? _c("el-radio", {
    attrs: {
      label: 5
    }
  }, [_vm._v("申请复核")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "意见填写",
      prop: "checkRemark"
    }
  }, [_c("el-input", {
    staticStyle: {
      "max-width": "50%"
    },
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "请输入审核意见"
    },
    model: {
      value: _vm.reviewForm.checkRemark,
      callback: function ($$v) {
        _vm.$set(_vm.reviewForm, "checkRemark", $$v);
      },
      expression: "reviewForm.checkRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleReview
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")])], 1)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };