var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-cascader", {
    attrs: {
      placeholder: _vm.placeholder,
      disabled: _vm.isUpdate,
      props: _vm.props
    },
    model: {
      value: _vm.selectedOptions,
      callback: function ($$v) {
        _vm.selectedOptions = $$v;
      },
      expression: "selectedOptions"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };