import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { getTaxsourceList, freeze, unfreeze } from '@/api/taxsource';
import { mapGetters } from 'vuex';
import { TAX_UPDATE_ID } from '@/utils/idName';
import { timePickerOptions, separateDate } from '@/utils/util';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import dialogInvoiceHeader from './components/dialogInvoiceHeader.vue';
export default {
  name: 'taxsourceIndex',
  components: {
    CityCascader,
    Pagination,
    DbDatePicker,
    dialogInvoiceHeader
  },
  data() {
    return {
      total: 0,
      updateTaxsource: '',
      viewTaxsource: '',
      addTaxsource: '',
      forzenTaxsource: '',
      configInvoiceHeader: '',
      list: [],
      listLoading: true,
      selectRow: {},
      invoiceHeaderVisible: false,
      taxStatus: this.$formatTypeArray('taxStatusType'),
      updateStatus: {
        id: '',
        status: ''
      },
      createTime: [],
      searchForm: {
        beginTime: '',
        endTime: '',
        provinceCode: '',
        cityCode: '',
        status: '',
        taxName: '',
        taxNo: '',
        order: '',
        sort: '',
        yearLargeSwitch: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      clickUpdate: false,
      clickQuery: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateTaxsource && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    this.getList();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addTaxsource = map.get('addTaxsource');
      this.viewTaxsource = map.get('viewTaxsource');
      this.updateTaxsource = map.get('updateTaxsource');
      this.deleteTaxsource = map.get('deleteTaxsource');
      this.forzenTaxsource = map.get('forzenTaxsource');
      this.configInvoiceHeader = map.get('configInvoiceHeader');
    }
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const qryCreateTime = separateDate(this.createTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      if (this.$refs.proviceCity !== undefined && this.$refs.proviceCity.selectedOptions) {
        this.currentSearch.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.currentSearch.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      getTaxsourceList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getStatus(status) {
      if (status === 1) {
        return '冻结';
      } else if (status === 9) {
        return '解冻';
      }
    },
    clearQuery() {
      this.searchForm = {
        beginTime: '',
        endTime: '',
        provinceCode: '',
        cityCode: '',
        status: '',
        taxName: '',
        taxNo: '',
        order: '',
        sort: '',
        pageNo: 1,
        pageSize: 10
      };
      this.createTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.$refs.proviceCity.selectedOptions = [];
      this.getList();
    },
    handleAdd() {
      this.$router.push({
        path: this.addTaxsource
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(TAX_UPDATE_ID, row.id);
      this.clickUpdate = true;
      this.$router.push({
        path: this.updateTaxsource
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(TAX_UPDATE_ID, row.id);
      this.$router.push({
        path: this.viewTaxsource
      });
    },
    formatCity() {
      if (this.searchForm.provinceCode === '' || this.searchForm.provinceCode === undefined) {
        return '请选择所属地区';
      } else {
        return this.searchForm.provinceCode + '/' + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('taxStatusType', row.status);
    },
    formatIsSupport(row) {
      return this.$formatType('isSupport', row.supportDiff);
    },
    unfreeze(row) {
      this.$confirm('是否确定解冻该劳务公司?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        unfreeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    onInvoiceHeader(row) {
      this.selectRow = row;
      this.invoiceHeaderVisible = true;
    },
    freeze(row) {
      this.$confirm('是否确定冻结该劳务公司?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        freeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    }
  }
};