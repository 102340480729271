var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "查询关键字",
      prop: "keyWords"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入姓名/手机号/身份证号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.keyWords,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "keyWords", $$v);
      },
      expression: "searchForm.keyWords"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "班组编号",
      prop: "teamNo"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入班组编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.teamNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "teamNo", $$v);
      },
      expression: "searchForm.teamNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目子编号",
      prop: "projectNo"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入签约项目子编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectNo", $$v);
      },
      expression: "searchForm.projectNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约项目名称",
      prop: "projectName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入签约项目名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectName", $$v);
      },
      expression: "searchForm.projectName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入签约劳务公司",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择签约状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.groupSignStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "签约日期"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.download ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表\n      ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "teamNo",
      label: "班组编号",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "teamName",
      label: "班组名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "monitorName",
      label: "班组长姓名",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "monitorIdcard",
      label: "班组长身份证号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "monitorMobile",
      label: "手机号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectNo",
      label: "签约项目子编号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectName",
      label: "签约项目名称",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "签约劳务公司编号",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "statusName",
      label: "签约状态",
      width: "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      width: "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建日期",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "signDate",
      label: "签约日期",
      width: "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "240",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.teamDetail ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onTeamDetail(scope.$index, scope.row);
            }
          }
        }, [_vm._v("班组详情")]) : _vm._e(), _vm._v(" "), [1].includes(scope.row.status) && _vm.contractManage ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onContractManage(scope.$index, scope.row);
            }
          }
        }, [_vm._v("合同管理")]) : _vm._e(), _vm._v(" "), [1].includes(scope.row.status) && _vm.invoiceApply ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onInvoiceApply(scope.$index, scope.row);
            }
          }
        }, [_vm._v("开票申请")]) : _vm._e(), _vm._v(" "), [0].includes(scope.row.status) && _vm.cancel ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onCancel(scope.$index, scope.row);
            }
          }
        }, [_vm._v("撤销")]) : _vm._e(), _vm._v(" "), [0].includes(scope.row.status) && _vm.signed ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onSigned(scope.$index, scope.row);
            }
          }
        }, [_vm._v("确认签约")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("dialog-agreement-manager", {
    attrs: {
      visiable: _vm.agreementVisible,
      formData: _vm.selectRow
    },
    on: {
      "update:visiable": function ($event) {
        _vm.agreementVisible = $event;
      }
    }
  }), _vm._v(" "), _c("dialog-invoice-apply", {
    attrs: {
      visiable: _vm.invoiceVisible,
      formData: _vm.selectRow
    },
    on: {
      "update:visiable": function ($event) {
        _vm.invoiceVisible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };