import { updateMenu, getMenuTree, getApiList, getMenuInfo } from '@/api/sys';
import { MENU_INFO_ID } from '@/utils/idName';
export default {
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 编辑界面数据
      editForm: {
        id: '',
        name: '',
        parentId: '',
        type: 1,
        orderNum: '',
        isContinue: 0,
        icon: '',
        parentName: '',
        apiIdList: [],
        component: '',
        redirect: '',
        title: '',
        hidden: false,
        url: ''
      },
      isTrue: true,
      dialogVisible: false,
      apiArr: [],
      allApiList: [],
      loading: false,
      menuTypeArr: this.$formatTypeArray('menuType'),
      isHiddenArr: this.$formatTypeArray('isHidden')
    };
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      // 获取用户列表
      const id = sessionStorage.getItem(MENU_INFO_ID);
      getMenuInfo(id).then(response => {
        this.editForm = response.data;
      });
      const query = {
        pageSize: 10000,
        pageNo: 1,
        name: '',
        status: 1
      };
      getApiList(query).then(response => {
        this.allApiList = response.data.list;
        this.apiArr = this.allApiList;
      });
    },
    initApiList() {
      this.apiArr = this.allApiList;
    },
    updateData() {
      updateMenu(this.editForm).then(response => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.$router.push({
          path: '/sys/menu'
        });
      });
    },
    handleSelectTree() {
      this.dialogVisible = true;
      this.handleMenuTree();
    },
    handleChangeMenu() {
      this.dialogVisible = false;
    },
    handleNodeClick(data) {
      if (data) {
        this.editForm.parentId = data.id;
        this.editForm.parentName = data.label;
      }
    },
    handleMenuTree() {
      getMenuTree().then(res => {
        this.treeData = res.data;
      });
    },
    changeValue() {
      this.remoteMethod('');
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.apiArr = this.allApiList.filter(item => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.apiArr = this.allApiList;
      }
    },
    back() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};