import BankItem from './components/bankItem.vue';
import { isMoneyNumber } from '@/utils/validate_rules';
import { bankYeepayList } from '@/api/common.js';
import { rechargeCreate } from '@/api/transaction';
export default {
  name: 'rechargeInline',
  components: {
    BankItem
  },
  data() {
    return {
      ruleForm: {
        amount: '',
        payType: 2,
        // 表示甲方单位网银
        bankItem: {},
        remark: ''
      },
      rules: {
        amount: [{
          required: true,
          message: '充值金额不能为空',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        bankItem: [{
          required: true,
          message: '请选择甲方单位网银',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      },
      banks: []
    };
  },
  created() {
    console.log('this.$route.query.requiredAmount', this.$route.query.requiredAmount);
    this.ruleForm.amount = this.$route.query.requiredAmount || '';
    this.bankYeepayList();
  },
  methods: {
    async bankYeepayList() {
      let res = await bankYeepayList();
      this.banks = res.data;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.rechargeCreate();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async rechargeCreate() {
      const {
        bankCode,
        bankName
      } = this.ruleForm.bankItem;
      // 重定向地址
      let returnUrl = `${window.location.origin}${window.location.pathname}#/account/recharge`;
      const params = {
        amount: this.ruleForm.amount,
        bankCode,
        bankName,
        payType: this.ruleForm.payType,
        remark: this.ruleForm.remark,
        returnUrl
      };
      console.log(params);
      let res = await rechargeCreate(params);
      console.log(res);
      if (res.data) {
        window.location.href = res.data;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};