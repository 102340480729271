var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "info"
  }, [_c("item-title", {
    attrs: {
      name: "消息详情"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "text1"
  }, [_vm._v(_vm._s(_vm.info.title))]), _vm._v(" "), _c("div", {
    staticClass: "text2"
  }, [_vm._v(_vm._s(_vm.info.effectTime))]), _vm._v(" "), _c("div", {
    staticClass: "line"
  }), _vm._v(" "), _vm.info.noticeType === 2 ? _c("div", {
    staticClass: "text3"
  }, [_vm._v(_vm._s(_vm.info.contents))]) : _vm.info.contents ? _c("div", {
    staticClass: "decode-class",
    domProps: {
      innerHTML: _vm._s(_vm.decodeContents(_vm.info.contents))
    }
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };