import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import { merchantShowList } from '@/api/merchant';
import { saveFile } from '@/api/upload';
import { isExcelFile } from '@/utils/util';
const BASE_URL = process.env.BASE_API;
import UpdateRecordFiles from './updateRecordFiles';
export default {
  name: 'updateRecordDialog',
  components: {
    UpdateRecordFiles,
    ItemTitle
  },
  data() {
    return {
      myDialogFormVisible: false,
      saveFile: saveFile,
      uploadMatchRecords: BASE_URL + '/project/merchantTaskRecord/uploadMatchRecords',
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E5%8F%91%E6%94%BE%E8%AE%B0%E5%BD%95%E5%8C%B9%E9%85%8D%E8%AF%81%E6%8D%AE%E9%93%BE%E6%A8%A1%E6%9D%BF.xlsx',
      partyCode: '',
      fileList: [],
      taskList: [],
      taxSources: [],
      addForm: {
        taxNo: '',
        saveKeys: []
      },
      merchantNo: '',
      merchantList: [],
      partyType: '',
      rules: {
        taxNo: [{
          required: true,
          message: '请选择所属劳务公司',
          trigger: 'change'
        }],
        merchantNo: [{
          required: true,
          message: '请选择所属商户',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    exData: function () {
      return {
        merchantNo: this.addForm.merchantNo,
        taxNo: this.addForm.taxNo
      };
    }
  },
  props: {
    dialogVisible: false
  },
  watch: {
    dialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    } else {
      this.addForm.merchantNo = user.partyCode;
      this.merchantName = user.realname;
    }
    if (this.partyType === 1) {
      this.addForm.taxNo = user.partyCode;
    }
    this.getTaxSources();
  },
  methods: {
    dialogClosed() {
      this.myDialogFormVisible = false;
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选取提交文件。');
        return;
      }
      this.$confirm('是否确定提交？', '温馨提示', {
        type: 'plain',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs['addForm'].validate(valid => {
          if (valid) {
            this.$refs.upload.submit();
          } else {
            return false;
          }
        });
      }).catch(() => {});
    },
    beforeUpload(file) {
      const isExcel = isExcelFile(file);
      const isLt3M = file.size / 1024 / 1024 < 50;
      if (!isExcel) {
        this.$message.error('上传文件只能是Excel格式!');
      }
      if (!isLt3M) {
        this.$message.error('上传文件大小不能超过 50MB!');
      }
      return isExcel && isLt3M;
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件，本次选择了1个文件。');
    },
    handleRemove() {
      this.fileList = [];
      this.addForm.saveKey = '';
    },
    changeFiles(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 'BASE.000') {
        this.dialogClosed(response.data);
      } else {
        this.$message.error(response.errMsg);
      }
    }
  }
};