/**
 * 公共接口api
 */
import request from '@/utils/request';
export function queryDistrictList(params) {
  const parentCode = params;
  return request({
    url: '/api/common/queryDistrictList',
    method: 'post',
    data: {
      parentCode
    }
  });
}
export function queryDistrictListToBankName() {
  const dictGroupCode = 'biz_bank_code';
  const sysCode = 'ZGOSS';
  return request({
    url: '/api/common/queryDictList',
    method: 'post',
    data: {
      dictGroupCode,
      sysCode
    }
  });
}
export function queryDistrictName(params) {
  const gbCode = params;
  return request({
    url: '/api/common/queryName',
    method: 'post',
    data: {
      gbCode
    }
  });
}
export function queryDistrictListToIndustry() {
  const dictGroupCode = 'biz_industry_type';
  const sysCode = 'ZGOSS';
  return request({
    url: '/api/common/queryDictList',
    method: 'post',
    data: {
      dictGroupCode,
      sysCode
    }
  });
}
export function queryMakeInvoiceContents(taxNo, invoiceType) {
  return request({
    url: '/invoice/invoiceContent/showlist',
    method: 'post',
    data: {
      taxNo,
      invoiceType
    }
  });
}
export function privateUrlShow(saveKey) {
  return request({
    url: '/ossfile/privateUrlShow',
    method: 'post',
    data: {
      saveKey
    }
  });
}

/**
 * 获取易宝支付支持的银行列表信息
 */
export const bankYeepayList = params => {
  return request({
    url: '/common/bank/yeepayList',
    method: 'post',
    data: params
  });
};