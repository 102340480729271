import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { separateDate } from '@/utils/util';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import { getAcountInfo } from '@/utils/auth';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { taskAnnexList } from '../../../api/task';
import { merchantShowList } from '../../../api/merchant';
import ElLink from 'element-ui/packages/link/src/main';
export default {
  name: 'annexListContent',
  components: {
    ElLink,
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        annexName: '',
        annexNo: '',
        merchantNo: '',
        status: '',
        // 退款状态(0-待确认,1-待记账,2-已记账,9-无效)
        taxNo: '',
        invoiceSerialNo: '',
        submitTime: [] // 提交时间起
      },
      currentSearch: {},
      partyType: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      hasDownloadVisible: false,
      merchantName: '',
      merchantList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadVisible = map.has('download');
    } else {
      this.hasDownloadVisible = false;
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 0 || this.partyType === 1) {
      this.getMerchantList();
    } else {
      this.currentSearch.merchantNo = this.searchForm.merchantNo = user.partyCode;
      this.merchantName = user.realname;
    }
    this.getList();
  },
  methods: {
    getMerchantList() {
      merchantShowList({}).then(response => {
        this.merchantList = response.data;
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const submitTime = separateDate(this.currentSearch.submitTime);
      taskAnnexList({
        annexName: this.currentSearch.annexName,
        annexNo: this.currentSearch.annexNo,
        merchantNo: this.currentSearch.merchantNo,
        status: this.currentSearch.status,
        beginTime: submitTime.beginTime,
        endTime: submitTime.endTime,
        invoiceSerialNo: this.currentSearch.invoiceSerialNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};