import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { downloadExcel } from '@/utils/util';
import { separateDate } from '@/utils/util';
import { downloadTaxIncome, getTaxIncomeList } from '../../../api/stat';
import { getLast12Month } from '../../../utils/util';
import MonthImportFile from './components/sysMonthImport';
import { TEMPLATE_TAX_MONTH } from '../../../../static/template/url';
import TaxMonthCharts from './taxMonthChart';
import { showlist } from '../../../api/taxsource';
export default {
  name: 'taxMonthList',
  components: {
    TaxMonthCharts,
    MonthImportFile,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      hasDownloadPermission: false,
      hasImportPermission: false,
      list: [],
      taxSources: [],
      listLoading: true,
      searchForm: {
        taxNo: '',
        beginTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10,
        dateTime: []
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      summary: [],
      isShowDialog: false,
      templateUrl: TEMPLATE_TAX_MONTH
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.get('download');
      this.hasImportPermission = map.get('import');
    }
  },
  mounted() {
    this.searchForm.dateTime = getLast12Month();
    this.getList();
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      if (this.searchForm.dateTime === null || this.searchForm.dateTime.length === 0) {
        this.$message.error('请选择结算周期');
        return;
      }
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.searchForm.dateTime);
      this.currentSearch.beginTime = beginTime;
      this.currentSearch.endTime = endTime;
      getTaxIncomeList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.summary = response.data.summary.split('#');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.searchForm.dateTime = getLast12Month();
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      downloadTaxIncome(this.currentSearch).then(response => {
        downloadExcel(response.data, '劳务公司月收益统计表.xls');
      });
    },
    changeDialogVisible(val) {
      this.isShowDialog = val;
      this.$refs.chart.getList();
      this.getList();
    },
    showImportDialog() {
      this.isShowDialog = true;
    }
  }
};