var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "menu-wrapper"
  }, [_vm._l(_vm.routes, function (item, index) {
    return !item.hidden && item.children ? [!_vm.hasSubMenu(item) ? _c("router-link", {
      key: item.name ? item.name : item.path,
      attrs: {
        to: item.redirect && item.redirect !== "noredirect" ? item.redirect : item.path
      }
    }, [_c("el-menu-item", {
      class: {
        "submenu-title-noDropdown": !_vm.isNest
      },
      attrs: {
        index: item.redirect && item.redirect !== "noredirect" ? item.redirect : item.path
      }
    }, [_vm.findMatchMenu(item) && _vm.findMatchMenu(item).icon ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.findMatchMenu(item).icon
      }
    }) : _vm._e(), _vm._v(" "), _vm.findMatchMenu(item) && _vm.findMatchMenu(item).title ? _c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(_vm.findMatchMenu(item).title))]) : _vm._e()], 1)], 1) : _vm.hasOneShowingChildren(item.children) && !item.children[0].children && !item.alwaysShow ? _c("router-link", {
      key: item.children[0].name,
      attrs: {
        to: item.path + "/" + item.children[0].path
      }
    }, [_c("el-menu-item", {
      class: {
        "submenu-title-noDropdown": !_vm.isNest
      },
      attrs: {
        index: item.path + "/" + item.children[0].path
      }
    }, [item.children[0].meta && item.children[0].meta.icon ? _c("svg-icon", {
      attrs: {
        "icon-class": item.children[0].meta.icon
      }
    }) : _vm._e(), _vm._v(" "), item.children[0].meta && item.children[0].meta.title ? _c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(item.children[0].meta.title))]) : _vm._e()], 1)], 1) : _c("el-submenu", {
      key: index,
      attrs: {
        index: item.name || item.path
      }
    }, [_c("template", {
      slot: "title"
    }, [item.meta && item.meta.icon ? _c("svg-icon", {
      attrs: {
        "icon-class": item.meta.icon
      }
    }) : _vm._e(), _vm._v(" "), item.meta && item.meta.title ? _c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(item.meta.title))]) : _vm._e()], 1), _vm._v(" "), _vm._l(item.children, function (child) {
      return !child.hidden ? [_vm.checkNest(child) ? _c("sidebar-item", {
        key: child.path,
        staticClass: "nest-menu",
        attrs: {
          "is-nest": true,
          routes: [child]
        }
      }) : _c("router-link", {
        key: child.name + child.path,
        attrs: {
          to: item.path + "/" + child.path
        }
      }, [_c("el-menu-item", {
        staticClass: "child-menu",
        attrs: {
          index: item.path + "/" + child.path
        }
      }, [child.meta && child.meta.icon ? _c("svg-icon", {
        attrs: {
          "icon-class": child.meta.icon
        }
      }) : _vm._e(), _vm._v(" "), child.meta && child.meta.title ? _c("span", {
        attrs: {
          slot: "title"
        },
        slot: "title"
      }, [_vm._v(_vm._s(child.meta.title))]) : _vm._e()], 1)], 1)] : _vm._e();
    })], 2)] : _vm._e();
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };