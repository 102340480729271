import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { getAppInfoList, updateConfig } from '../../../api/safe';
import { VIEW_APP_INFO_ID } from '../../../utils/idName';
export default {
  name: 'appInfoList2',
  components: {
    CityCascader,
    Pagination
  },
  data() {
    return {
      show: false,
      auditMerchantSignId: '',
      updateDialogVisible: false,
      currentSignId: '',
      currentChannelNo: '',
      total: 0,
      viewAppInfoVisible: '',
      updateAppInfoVisible: '',
      list: [],
      listLoading: true,
      appInfoStatus: this.$formatTypeArray('appInfoStatus'),
      authTypes: this.$formatTypeArray('authType'),
      needIdcardPicStatus: this.$formatTypeArray('needIdcardPicStatus'),
      searchForm: {
        appId: '',
        appName: '',
        status: ''
      },
      updateForm: {
        checkMobile: '',
        needIdcardPic: '',
        status: ''
      },
      updateFormRules: {
        checkMobile: [{
          required: true,
          message: '请选择签约验证方式',
          trigger: 'blur'
        }],
        needIdcardPic: [{
          required: true,
          message: '请选择是否上传身份证',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择应用状态',
          trigger: 'blur'
        }]
      },
      pageNo: 1,
      pageSize: 10,
      currentSearch: {}
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getList();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.viewAppInfoVisible = map.get('viewAppInfo');
      this.updateAppInfoVisible = map.get('updateAppInfo');
    }
  },
  methods: {
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = false;
      this.currentSearch.pageNo = this.pageNo;
      this.currentSearch.pageSize = this.pageSize;
      getAppInfoList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        appId: '',
        appName: '',
        status: ''
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.updateForm.id = row.id;
      this.updateForm.checkMobile = row.checkMobile;
      this.updateForm.needIdcardPic = row.needIdcardPic;
      this.updateForm.status = row.status;
      this.updateDialogVisible = true;
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(VIEW_APP_INFO_ID, row.id);
      this.$router.push({
        path: this.viewAppInfoVisible
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    doUpdate() {
      this.$refs['updateForm'].validate(valid => {
        if (valid) {
          updateConfig(this.updateForm).then(res => {
            this.updateDialogVisible = false;
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            // 清空dialog表单
            this.updateForm = {
              id: '',
              checkMobile: '',
              needIdcardPic: '',
              status: ''
            };
            this.getList();
          });
        } else {
          return;
        }
      });
    }
  }
};