import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      setpwdFormVisible: false,
      setpwdFormPath: ''
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.setpwdFormVisible = map.get('setpwdForm');
      this.setpwdFormPath = map.get('setpwdForm');
    } else {
      this.setpwdFormVisible = false;
    }
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  methods: {
    // 显示编辑界面
    handleEdit(index, row) {
      this.$router.push({
        path: this.setpwdFormPath
      });
    }
  }
};