import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'viewProductByZfb',
  components: {
    ItemTitle
  },
  props: {
    saveForm: {
      accountNo: '',
      appId: '',
      appKey: '',
      bankName: '',
      channelName: '',
      channelNo: '',
      channelType: '',
      custName: '',
      ncPreposeIp: '',
      ncUserId: '',
      ncUserPwd: '',
      privateKey: '',
      publicKey: '',
      quotaMax: '',
      rechargeAuto: '',
      status: '',
      taxNo: '',
      billCode: '',
      useofCode: '',
      alipayPid: ''
    }
  },
  created() {
    // 初始化执行方法
  },
  methods: {
    formatStatus(status) {
      return this.$formatType('taxStatusType', status);
    },
    formatIsSupport(rechargeAuto) {
      return this.$formatType('isSupport', rechargeAuto);
    }
  }
};