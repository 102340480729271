import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { getAccountListReq, downloadAccountRecords } from '@/api/account';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { ACCOUNT_DETAIL_ID } from '@/utils/idName';
import { downloadExcel } from '@/utils/util';
import AddRefundDialog from './components/refundDialog';
export default {
  name: 'accountQueryIndex',
  components: {
    AddRefundDialog,
    Pagination,
    ItemTitle
  },
  data() {
    return {
      searchForm: {
        belong: '',
        belongName: '',
        belongType: '',
        taxSource: ''
      },
      currentSearch: {},
      taxSources: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      accounts: [],
      hasDetailPermission: false,
      hasDownloadPermission: false,
      detailPath: '',
      partyType: '',
      taxName: '',
      addRefundDialogVisible: false,
      refundItem: {},
      hasAddRefound: false,
      totalAmount: '',
      frozenAmount: '',
      availAmount: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log('map: ', map);
    if (map) {
      this.hasDetailPermission = map.has('detail');
      this.hasDownloadPermission = map.has('download');
      this.detailPath = map.get('detail');
      this.hasAddRefound = map.get('addRefund');
    } else {
      this.hasDetailPermission = false;
      this.hasDownloadPermission = false;
      this.detailPath = '';
      this.addRefund = false;
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else {
      this.getTaxSources();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatBelongType(row, column) {
      return this.$formatType('belongType', row.belongType);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    downloadForm() {
      downloadAccountRecords({
        belong: this.currentSearch.belong,
        belongName: this.currentSearch.belongName,
        belongType: this.currentSearch.belongType,
        taxNo: this.currentSearch.taxSource
      }).then(response => {
        downloadExcel(response.data, 'account-records.xls');
      });
    },
    checkRow(index, object) {
      // 查看详情
      sessionStorage.setItem(ACCOUNT_DETAIL_ID, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getAccountListReq({
        belong: this.currentSearch.belong,
        belongName: this.currentSearch.belongName,
        belongType: this.currentSearch.belongType,
        taxNo: this.currentSearch.taxSource,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.accounts = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmount = response.data.totalAmount;
        this.frozenAmount = response.data.frozenAmount;
        this.availAmount = response.data.availAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    addRefund(row) {
      this.refundItem = row;
      this.addRefundDialogVisible = true;
    },
    changeRefundDialogVisible(val) {
      this.addRefundDialogVisible = val;
      if (val === false) {
        this.getList();
        this.refundItem = {};
      }
    }
  }
};