var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trans-histogram"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "date-text"
  }, [_c("span", [_vm._v("单位：万 ")]), _vm._v(" "), _c("div", [_c("span", {
    class: _vm.clickIndex === 4 ? "date-text-click" : "",
    on: {
      click: function ($event) {
        return _vm.freshData(4);
      }
    }
  }, [_vm._v("全年")]), _vm._v(" "), _c("span", {
    class: _vm.clickIndex === 1 ? "date-text-click" : "",
    on: {
      click: function ($event) {
        return _vm.freshData(1);
      }
    }
  }, [_vm._v("今日")]), _vm._v(" "), _c("span", {
    class: _vm.clickIndex === 2 ? "date-text-click" : "",
    on: {
      click: function ($event) {
        return _vm.freshData(2);
      }
    }
  }, [_vm._v("本周")]), _vm._v(" "), _c("span", {
    class: _vm.clickIndex === 3 ? "date-text-click" : "",
    on: {
      click: function ($event) {
        return _vm.freshData(3);
      }
    }
  }, [_vm._v("本月")])])]), _vm._v(" "), _c("dbs-histogram", {
    attrs: {
      data: _vm.chartData,
      settings: _vm.chartSettings,
      afterSetOption: _vm.afterSetOption,
      width: "100%"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trans-histogram-title"
  }, [_c("img", {
    staticClass: "index-data-icon",
    attrs: {
      src: "static/img/first/index-data-icon.png"
    }
  }), _vm._v(" "), _c("span", [_vm._v("交易额趋势")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };