import { mapGetters } from 'vuex';
import { getAccountInfoReq } from '@/api/account';
import ItemTitle from '@/base/ui/title/itemTitle';
import WithdrawDialog from './components/withdrawDialog';
import { TAX_NAME, ACCOUNT_DETAIL_ID } from '@/utils/idName';
import { getAcountInfo } from '@/utils/auth';
import Account from './components/account';
export default {
  name: 'account-info-index',
  components: {
    ItemTitle,
    Account,
    WithdrawDialog
  },
  data() {
    return {
      amount: '',
      list: [],
      servicePhone: process.env.SERVICE_PHONE,
      isDialogVisible: false,
      availableAmount: '',
      hasDetailPermission: false,
      hasWithdrawPermission: false,
      hasCardPermission: false,
      // hasSendPermission: false,
      hasApplyPermission: false,
      detailPath: ''
      // sendPath: ''
    };
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.hasDetailPermission = map.has('detail');
      this.hasWithdrawPermission = map.has('withdraw');
      // this.hasCardPermission = map.has('card')
      this.hasCardPermission = map.has('setBankCard');
      // this.hasSendPermission = map.has('send')
      this.hasApplyPermission = map.has('transactionApply');
      this.detailPath = map.get('detail');
      // this.sendPath = map.get('send')
      // this.sendPath = map.get('apply')
    } else {
      this.hasDetailPermission = false;
      this.hasWithdrawPermission = false;
      this.hasCardPermission = false;
      // this.hasSendPermission = false
      this.hasApplyPermission = false;
      this.detailPath = '';
      // this.sendPath = ''
    }
    this.getQuery();
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    formatterAmount: function () {
      return this.$fmoneyWithSymbol(this.amount);
    },
    accountType: function () {
      const user = getAcountInfo();
      return user.partyType;
    }
  },
  methods: {
    getQuery() {
      getAccountInfoReq().then(response => {
        this.availableAmount = response.data.withdrawAmount;
        this.amount = response.data.totalAmount;
        this.list = response.data.accountList;
      });
    },
    withdraw(account) {
      this.isDialogVisible = true;
    },
    updated() {
      this.getQuery();
    },
    checkRow(index, object) {
      // 查看详情
      sessionStorage.setItem(TAX_NAME, object.taxName);
      sessionStorage.setItem(ACCOUNT_DETAIL_ID, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    batchSendRow(index, object) {
      this.$router.push({
        path: '/transaction/apply'
      });
    },
    accountManagement() {
      this.$router.push({
        path: '/merchantInfo/setBankCard'
      });
    }
  }
};