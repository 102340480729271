var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "费率信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "阶梯累计类型：",
      prop: "rateType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择阶梯累计类型"
    },
    model: {
      value: _vm.saveForm.rateType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rateType", $$v);
      },
      expression: "saveForm.rateType"
    }
  }, _vm._l(_vm.$formatTypeArray("rateTypeStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _vm.saveForm.chargeType !== 1 ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标准充值费率：",
      prop: "rechargeRate"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.rechargeRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeRate", $$v);
      },
      expression: "saveForm.rechargeRate"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("（说明：请输入大于0的数值，例如：0.05表示费率为5%）")])], 1)], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };