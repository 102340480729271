var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chart-view"
  }, [_vm.chartData.rows.length > 0 ? _c("div", [_c("ve-bar", {
    attrs: {
      data: _vm.chartData,
      settings: _vm.chartSettings,
      "x-axis": _vm.xAxis,
      legend: _vm.legend
    }
  })], 1) : _c("div", {
    staticClass: "el-table__empty-block",
    staticStyle: {
      height: "100%",
      width: "1161px"
    }
  }, [_c("span", {
    staticClass: "el-table__empty-text"
  }, [_vm._v("暂无数据")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };