import { getRoleInfo, getRoleTree, updateRole, getUserRole } from '@/api/sys';
import { getAcountInfo } from '@/utils/auth';
import { ROLE_UPDATE_ID } from '@/utils/idName';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        menuIdList: [],
        type: '',
        status: 1,
        remark: '',
        parentId: ''
      },
      addRules: {
        name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '角色名称长度有误',
          trigger: 'blur'
        }],
        parentId: [{
          required: true,
          message: '请选择所属角色',
          trigger: 'change'
        }],
        remark: [{
          type: 'string',
          min: 1,
          max: 100,
          message: '备注长度有误',
          trigger: 'blur'
        }]
      },
      roleArr: [],
      userType: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  created() {
    this.handleInit();
  },
  methods: {
    handleInit() {
      this.userType = getAcountInfo().usertype;
      const id = sessionStorage.getItem(ROLE_UPDATE_ID);
      if (id) {
        getRoleInfo(id).then(response => {
          this.editForm = response.data;
          if (this.userType === 0) {
            this.handleMenuTree(true);
          } else {
            getUserRole().then(res => {
              this.roleArr = res.data;
            });
            this.handleMenuTree(false);
          }
          this.$refs.tree.setCheckedKeys(this.editForm.menuIdList);
        });
      }
    },
    updateData() {
      if (this.$refs.tree.getCheckedKeys() && this.$refs.tree.getCheckedKeys().length > 0) {
        this.editForm.menuIdList = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
      }
      this.$refs.editForm.validate(valid => {
        if (valid) {
          updateRole(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    },
    handleMenuTree(isFlag) {
      let roleId = '';
      if (!isFlag) {
        roleId = this.editForm.parentId;
      }
      getRoleTree(roleId).then(res => {
        this.treeData = res.data;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    }
  },
  mounted() {}
};