import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { empSignList, downloadSignList, downloadProtocol } from '@/api/operateEmp';
import { showlist } from '@/api/taxsource';
import { mapGetters } from 'vuex';
import { timePickerOptions, downloadAllFile } from '@/utils/util';
import UpdateEmpDialog from './components/updateEmpDialog';
import ImportEmpCollapse from './components/importEmpCollapse';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'empEndSignList',
  components: {
    ItemTitle,
    Pagination,
    UpdateEmpDialog,
    ImportEmpCollapse,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      hasDownloadPermission: '',
      // 导出人员信息权限
      hasDownEndProtocolPermission: '',
      //协议下载
      list: [],
      datePicker: [],
      updateDatePicker: [],
      listLoading: true,
      empSignStatus: this.$formatTypeArray('empSignStatus'),
      empAuthStatus: this.$formatTypeArray('empStatus'),
      authTypes: this.$formatTypeArray('authType'),
      searchForm: {
        taxNo: '',
        merchantNo: '',
        merchantName: '',
        authType: '',
        keyWords: '',
        status: '2',
        beginTime: '',
        endTime: '',
        updateBeginTime: '',
        updateEndTime: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10,
        status: '2'
      },
      taxSourceList: [],
      updateDialogVisible: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPremission = map.get('optDownloadEmpEndSign');
      this.hasDownEndProtocolPermission = map.get('optDownloadEndProtocol');
    }
    this.getTaxSources();
  },
  methods: {
    getTaxSources() {
      showlist().then(response => {
        this.taxSourceList = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (Array.isArray(this.updateDatePicker) && this.updateDatePicker.length === 2) {
        this.currentSearch.updateBeginTime = this.updateDatePicker[0];
        this.currentSearch.updateEndTime = this.updateDatePicker[1];
      }
      empSignList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        taxNo: '',
        merchantNo: '',
        merchantName: '',
        authType: '',
        keyWords: '',
        status: '2',
        beginTime: '',
        endTime: '',
        updateBeginTime: '',
        updateEndTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.updateDatePicker = [];
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatEmpSignStatus(row, column) {
      return this.$formatType('empSignStatus', row.status);
    },
    formatAuthTypes(row, column) {
      return this.$formatType('authType', row.authType);
    },
    // formatEmpSex(row, column) {
    //   return this.$formatType('sexType', row.sex)
    // },
    changeVisibleUpdate(val) {
      // 监听修改dialog的updateDialogVisible的变化
      this.updateDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    downloadProtocolData(index, object) {
      this.listLoading = true;
      downloadProtocol({
        id: object.id
      }).then(response => {
        // debugger
        downloadAllFile(response.data, response.headers.filename, this);
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    downloadData() {
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.searchForm.beginTime = this.datePicker[0];
        this.searchForm.endTime = this.datePicker[1];
      }
      if (Array.isArray(this.updateDatePicker) && this.updateDatePicker.length === 2) {
        this.searchForm.updateBeginTime = this.updateDatePicker[0];
        this.searchForm.updateEndTime = this.updateDatePicker[1];
      }
      downloadSignList(this.searchForm).then(response => {
        if (!response.data) {
          return;
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        const fileName = 'EmployeeSignList.xls';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
        console.log('error: ' + error);
        this.$message({
          message: '下载文件异常',
          type: 'error'
        });
      });
    }
  }
};