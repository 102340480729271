import { timePickerOptions } from '@/utils/util';
export default {
  name: 'DbDatePicker',
  inheritAttrs: false,
  computed: {
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  props: {
    format: {
      type: String,
      default: () => {
        return 'yyyy-MM-dd HH:mm:ss';
      }
    }
  }
};