var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.loading ? _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_vm.isOpen ? _c("save-record-list-content", {
    ref: "child"
  }) : _c("job-open")], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };