var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    staticClass: "border-bg",
    attrs: {
      model: _vm.info,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "付款方信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款劳务公司编："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.payTaxNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款户名："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.payName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款渠道编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.payChannelNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款银行："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.payBankName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款账号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.payAcct))])])], 1)], 1)], 1), _vm._v(" "), _c("el-form", {
    staticClass: "border-bg",
    attrs: {
      model: _vm.info,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "收款方信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款劳务公司编："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.recTaxNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款户名："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.recName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款渠道编号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.recChannelNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款银行："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.recBankName))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款账号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.recAcct))])])], 1)], 1)], 1), _vm._v(" "), _c("el-form", {
    staticClass: "border-bg",
    attrs: {
      model: _vm.info,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "业务信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款订单号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.bussincessNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "转账充值费率："
    }
  }, [_c("div", [_vm._v(_vm._s((_vm.info.rechargeRate * 100).toFixed(2)) + "%")])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "付款订单金额："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.info.orderAmount)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "转账充值金额："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.info.rechargeAmount)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "转账订单号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.orderNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "创建时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.createTime))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道订单号："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.rechargeOrderNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "转账状态："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$formatType("taxStatus", _vm.info.rechargeStatus)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道应答码："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.respCode))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道应答描述："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.respMessage))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "记账状态："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$formatType("routerAccountStatus", _vm.info.accountStatus)))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "更新时间："
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.info.updateTime))])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };