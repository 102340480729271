var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-collapse", {
    staticClass: "border-bg",
    staticStyle: {
      "padding-top": "0"
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "账户说明"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "process-desc"
  }, [_c("div", [_vm._v("1.项目充值账户是项目用于给劳务公司充值转账时的资金账户；")]), _vm._v(" "), _c("div", [_vm._v("2.项目最多可以添加10个项目充值账户（账户名称必须与项目名称一致）；")]), _vm._v(" "), _c("div", [_vm._v("3.项目使用已添加的账户对劳务公司进行充值转账时，平台自动同步充值转账信息并入账至项目账户。")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#FF4C4C"
    }
  }, [_vm._v("\n          注：若使用项目充值账户列表中没有的账户进行充值转账，平台可能无法同步充值信息并入账项目账户。请使用账户列表中的账户进行充值转账。\n        ")])])], 2)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "text-line"
  }, [_c("item-title", {
    staticStyle: {
      margin: "auto 0"
    },
    attrs: {
      name: "账户设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "text1",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("最多添加10个账户")]), _vm._v(" "), _c("el-button", {
    staticClass: "text-add",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-plus",
    staticStyle: {
      "margin-right": "0.5rem"
    }
  }), _vm._v("新增充值账户")])], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.bankCardList,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "80",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "账户名称",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "充值帐户",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountType",
      label: "账户类型",
      formatter: _vm.$formatTypeInTable("accountType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bankName",
      label: "开户银行",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      fixed: "right",
      width: "120",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.deleteCard(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("add-bank-card-dialog", {
    attrs: {
      dialogFormVisible: _vm.dialogFormVisible
    },
    on: {
      changeVisibleAdd: _vm.changeVisibleAdd
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };