import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import AddTaxRouterOpenDialog from './components/AddTaxRouterOpenDialog';
import UpdateTaxRouterOpenDialog from './components/UpdateTaxRouterOpenDialog';
import { getTaxRouterOpenList } from '../../../api/taxRouterOpen';
import { getAcountInfo } from '../../../utils/auth';
export default {
  name: 'taxRouterOpenList',
  components: {
    UpdateTaxRouterOpenDialog,
    AddTaxRouterOpenDialog,
    Pagination,
    ItemTitle
  },
  data() {
    return {
      searchForm: {
        belong: '',
        belongName: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: false,
      transactions: [],
      taxRouterStatus: this.$formatTypeArray('taxRouterStatus'),
      taxRouterRechargeInfo: '',
      addDialogVisible: false,
      updateDialogVisible: false,
      addTaxRouterOpenVisible: '',
      updateTaxRouterOpenVisible: '',
      clickItem: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addTaxRouterOpenVisible = map.get('addTaxRouterOpen');
      this.updateTaxRouterOpenVisible = map.get('updateTaxRouterOpen');
    } else {
      this.AddTaxRouterOpenVisible = '';
      this.updateTaxRouterOpenVisible = '';
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.searchForm = {
        belong: '',
        belongName: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getTaxRouterOpenList(this.currentSearch).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    showUpdateDialog(row) {
      this.clickItem = row;
      this.updateDialogVisible = true;
    },
    formatStatus(row) {
      return this.$formatType('taxRouterStatus', row.status);
    },
    changeAddDialogVisible(val) {
      this.addDialogVisible = val;
      this.getList();
    },
    changeUpateDialogVisible(val) {
      this.updateDialogVisible = val;
      this.getList();
    }
  }
};