import { getApiInfo, updateApi } from '@/api/sys';
import { API_UPDATE_ID } from '@/utils/idName';
export default {
  data() {
    return {
      // 编辑界面数据
      editForm: {
        id: '',
        name: '',
        type: '',
        status: '',
        permission: '',
        url: '',
        description: ''
      },
      typeArr: this.$formatTypeArray('apiType'),
      statusArr: this.$formatTypeArray('apiStatus'),
      addRules: {
        name: [{
          required: true,
          message: '请输入接口名称',
          trigger: 'blur'
        }, {
          type: 'string',
          min: 1,
          max: 50,
          message: '接口名称长度有误',
          trigger: 'blur'
        }],
        url: [{
          type: 'string',
          min: 1,
          max: 50,
          message: '接口地址长度有误',
          trigger: 'blur'
        }],
        permission: [{
          type: 'string',
          min: 1,
          max: 100,
          message: '接口权限名称长度有误',
          trigger: 'blur'
        }],
        description: [{
          min: 1,
          max: 200,
          message: '接口描述长度有误',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      const id = sessionStorage.getItem(API_UPDATE_ID);
      if (id) {
        getApiInfo(id).then(response => {
          this.editForm = response.data;
        });
      }
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    updateData() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          updateApi(this.editForm).then(response => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    }
  },
  mounted() {}
};