import { bannerInfoReg, updateBannerReg } from '../../../api/sys';
import UploadImage from '../../../base/ui/upload/uploadImage';
import { BANNER_UPDATE_ID } from '../../../utils/idName';
import { savePublicFile } from '@/api/upload';
export default {
  name: 'updateBanner',
  components: {
    UploadImage
  },
  data() {
    return {
      saveFile: savePublicFile,
      id: '',
      saveForm: {
        appCode: '',
        appGroup: '',
        imgUrl: '',
        linkUrl: '',
        seqNum: '',
        status: '',
        summary: '',
        title: ''
      },
      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '标题长度限制为1～100个字符',
          trigger: 'blur'
        }],
        seqNum: [{
          required: true,
          message: '请输入顺序',
          trigger: 'blur'
        }],
        appGroup: [{
          required: true,
          message: '请输入banner组',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: 'banner组长度限制为1～100个字符',
          trigger: 'blur'
        }],
        appCode: [{
          required: true,
          message: '请选择所属系统',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.id = sessionStorage.getItem(BANNER_UPDATE_ID);
    this.getInfo();
  },
  methods: {
    getInfo() {
      bannerInfoReg(this.id).then(response => {
        this.saveForm = response.data;
      });
    },
    updateBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateBannerReg(this.saveForm).then(response => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    handleSuccessLogUrl(res, file) {
      this.saveForm.imgUrl = res.data.showUrl;
    }
  }
};