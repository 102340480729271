const MSGINIT = "发送验证码";
// const MSGERROR = '验证码发送失败'
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
import { isMobile, isValidPassword, isValidUserName } from "@/utils/validate_rules";
import { getDynamicCode } from "@/api/verifyCode";
export default {
  name: "forgetpwd",
  data() {
    return {
      taxBrand: process.env.TAX_BRAND,
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      pwdform: {
        phone: "",
        verifyCode: "",
        newPassword: "",
        rePassword: "",
        username: ""
      },
      loginRules: {
        username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }, {
          min: 1,
          max: 50,
          message: "用户名长度有误",
          trigger: "blur"
        }, {
          validator: isValidUserName,
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入新密码",
          trigger: "blur"
        }, {
          validator: isValidPassword,
          trigger: "blur",
          message: "输入错误，密码需包含大写字母、小写字母、数字和特殊符号至少两种，长度6~16位"
        }],
        phone: [{
          required: true,
          message: "请输入手机号码",
          trigger: "blur"
        }, {
          validator: isMobile,
          trigger: "blur"
        }]
      },
      passwordType: "password"
    };
  },
  created() {
    this.$nextTick(function () {
      this.pwdform.username = localStorage.getItem("username");
    });
  },
  mounted() {},
  props: [],
  methods: {
    showPassword() {
      this.passwordType === "" ? this.passwordType = "password" : this.passwordType = "";
    },
    handleSend() {
      if (this.msgKey) {
        return;
      }
      this.$refs.pwdform.validate(valid => {
        if (valid) {
          getDynamicCode(this.pwdform.phone, this.pwdform.username, this.taxBrand).then(res => {
            if (this.msgKey) return;
            this.$message({
              type: "success",
              message: res.data
            });
            this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
            this.msgKey = true;
            const time = setInterval(() => {
              this.msgTime--;
              this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
              if (this.msgTime === 0) {
                this.msgTime = MSGTIME;
                this.msgText = MSGINIT;
                this.msgKey = false;
                clearInterval(time);
              }
            }, 1000);
          });
        }
      });
    },
    handleLogin() {
      if (!this.pwdform.verifyCode) {
        this.$message.error("验证码不能为空");
        return false;
      }
      this.$refs.pwdform.validate(valid => {
        if (valid) {
          this.$store.dispatch("ForgetPwd", this.pwdform).then(res => {
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.$router.push({
              path: "/login"
            });
          });
        }
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.go(-1);
      });
    }
  }
};