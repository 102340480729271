import { mapGetters } from "vuex";
import table from "@/utils/table";
import { timePickerOptions, separateDate, downloadExcel } from "@/utils/util";
import Pagination from "@/components/pagination";
import { getInvoiceListReq, downloadInvoiceRecords, getInvoiceInfoReq } from "@/api/invoice";
import { showlist } from "@/api/taxsource";
import { getAcountInfo } from "@/utils/auth";
import { INVOICE_OPERATION_PERMISSION } from "@/utils/idName";
import CheckInvoice from "../components/checkInvoice";
import UpdateInvoice from "../components/updateInvoice";
import ItemTitle from "@/base/ui/title/itemTitle";
import { AUDIT_INVOICE_ID, UPDATE_INVOICE_ITEM_ID } from "../../../utils/idName";
import DbDatePicker from "@/base/ui/date/db-date-picker";
import { downloadLetter, invoiceRecordCancel, updateBilled } from "../../../api/invoice";
import UploadLetterDialog from "../components/uploadLetterUrl";
import ElLink from "element-ui/packages/link/src/main";
import { downloadAllFile } from "../../../utils/util";
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
export default {
  name: "invoiceListIndex",
  components: {
    ElLink,
    UploadLetterDialog,
    ItemTitle,
    Pagination,
    CheckInvoice,
    UpdateInvoice,
    DbDatePicker,
    UploadMoreFile
  },
  data() {
    return {
      searchForm: {
        merchantNo: "",
        taxSource: "",
        merchantName: "",
        status: "",
        serialNo: "",
        date: [],
        billedTime: []
      },
      taxSources: [],
      invoices: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      hasDetailPermission: false,
      hasCancelPermission: false,
      auditInvoicePath: "",
      hasUpdatePermission: false,
      detailPath: "",
      updatePath: "",
      partyType: "",
      taxName: "",
      isCheckVisible: false,
      isUpdateVisible: false,
      expressInfo: null,
      currentSearch: {},
      uploadLetterDialogVisible: false,
      updateBillNoVisible: false,
      uploadDialogId: "",
      updateBillForm: {
        billUrlList: []
      },
      billType: '',
      updateBillVisible: false,
      hasUpdateBillPermission: false,
      currentBillId: "",
      type: 0,
      oldBillNo: "",
      updateBillRules: {
        billInfo: [{
          required: true,
          message: "请输入新的发票编号",
          trigger: "blur"
        }]
      }
    };
  },
  computed: {
    ...mapGetters(["buttonsMap"]),
    pickerOptions: function () {
      return timePickerOptions;
    },
    statusOptions: function () {
      return this.$formatTypeArray("invoiceStatus");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else if (this.partyType === 3) {
      this.searchForm.customerNo = user.partyCode;
    }
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDetailPermission = map.has("detail");
      this.auditInvoicePath = map.get("auditInvoice");
      this.hasCancelPermission = map.has("cancel");
      this.hasUpdatePermission = map.has("update");
      this.detailPath = map.get("detail");
      this.updatePath = map.get("updateInvoiceItem");
      this.hasUpdateBillPermission = map.has("updateBill");
    } else {
      this.hasDetailPermission = false;
      this.auditInvoicePath = "";
      this.hasCancelPermission = false;
      this.hasUpdatePermission = false;
      this.detailPath = "";
    }
    if (this.partyType !== 1) {
      this.getTaxSources();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatInvoiceType(row, column) {
      return this.$formatType("invoiceType", row.invoiceType);
    },
    formatStatus(row, column) {
      return this.$formatType("invoiceStatus", row.status);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs["searchForm"].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      if (this.invoices.length <= 0) {
        this.$message.error("暂无数据导出");
        return;
      }
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const billedTime = separateDate(this.currentSearch.billedTime);
      const params = {
        merchantNo: this.currentSearch.merchantNo,
        merchantName: this.currentSearch.merchantName,
        status: this.currentSearch.status,
        taxNo: this.currentSearch.taxSource,
        beginTime: beginTime,
        endTime: endTime,
        billedBeginTime: billedTime.beginTime,
        billedEndTime: billedTime.endTime,
        serialNo: this.currentSearch.serialNo
      };
      downloadInvoiceRecords(params).then(response => {
        downloadExcel(response.data, "invoice-records.xls");
      });
    },
    // 刷新
    updated() {
      this.getList();
    },
    checkRow(index, object) {
      // 查看详情
      // const permission = {
      //   id: object.id,
      //   cancel: this.hasCancelPermission,
      //   check: this.auditInvoicePath,
      //   update: this.hasUpdatePermission
      // }
      sessionStorage.setItem(INVOICE_OPERATION_PERMISSION, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    // 取消
    cancelRow(index, object) {
      this.$confirm("取消后该条开票申请作废，释放开票金额，是否确认取消？", "取消开票", {
        type: "warning",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.invoiceCancel(object.id);
      });
    },
    // 审核
    reviewRow(index, object) {
      sessionStorage.setItem(AUDIT_INVOICE_ID, object.id);
      this.$router.push(this.auditInvoicePath);
    },
    // 更新物流
    updateRow(index, object) {
      this.expressInfo = {
        id: object.id,
        logistics: object.logistics,
        expressNumber: object.expressNumber,
        remark: object.remark
      };
      this.isUpdateVisible = true;
    },
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      const billedTime = separateDate(this.currentSearch.billedTime);
      getInvoiceListReq({
        merchantNo: this.currentSearch.merchantNo,
        merchantName: this.currentSearch.merchantName,
        status: this.currentSearch.status,
        taxNo: this.currentSearch.taxSource,
        beginTime: beginTime,
        endTime: endTime,
        billedBeginTime: billedTime.beginTime,
        billedEndTime: billedTime.endTime,
        serialNo: this.currentSearch.serialNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.invoices = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    invoiceCancel(id) {
      invoiceRecordCancel({
        id: id
      }).then(() => {
        this.$message.success("开票申请已取消...");
        this.getQuery();
      });
    },
    gotoUpdate(row) {
      sessionStorage.setItem(UPDATE_INVOICE_ITEM_ID, row.id);
      this.$router.push({
        path: this.updatePath
      });
    },
    changeUploadDialogVisible(val) {
      this.uploadLetterDialogVisible = val;
      this.getList();
    },
    showUploadDialog(id) {
      console.log("======showUploadDialog");
      this.uploadDialogId = id;
      this.uploadLetterDialogVisible = true;
    },
    downloadLetterById(id, serialNo) {
      downloadLetter(id).then(response => {
        downloadAllFile(response.data, response.headers.filename, this);
      });
    },
    showUpdateBillDialog(row, type) {
      this.billType = type;
      this.currentBillId = row.id;
      this.type = 0;
      this.updateBillNoVisible = true;
    },
    UpdateBillNo(row, type) {
      this.billType = type;
      this.currentBillId = row.id;
      this.getBillInfo(row.id);
      this.type = 1;
      this.oldBillNo = row.billInfo;
      this.updateBillNoVisible = true;
    },
    async getBillInfo(id) {
      const res = await getInvoiceInfoReq({
        id
      });
      this.updateBillForm.billUrlList = (res.data.billList || []).map(v => {
        return {
          name: v.fileName,
          status: "success",
          response: {
            code: "BASE.000",
            data: {
              saveKey: v.fileUrl,
              showUrl: v.fileUrlShow
            }
          }
        };
      });
    },
    doUpdateBilled() {
      this.$refs["updateBillForm"].validate(valid => {
        if (valid) {
          let fileList = [];
          (this.updateBillForm.billUrlList || []).forEach(item => {
            fileList.push(item.response.data.saveKey);
          });
          updateBilled({
            billInfo: this.updateBillForm.billInfo,
            billUrlList: fileList,
            id: this.currentBillId,
            type: this.type
          }).then(() => {
            this.updateBillVisible = false;
            this.updateBillNoVisible = false;
            this.updateBillForm = {};
            this.getList();
            this.$message.success("操作成功");
          });
        } else {
          return;
        }
      });
    }
  }
};