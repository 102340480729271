var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    attrs: {
      type: "plain",
      size: "mini"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };