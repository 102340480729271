var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "editForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.editForm,
      "label-width": "150px",
      rules: _vm.editFormRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "原密码",
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      type: _vm.passwordType,
      autoComplete: "new-password"
    },
    model: {
      value: _vm.editForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "password", $$v);
      },
      expression: "editForm.password"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "新密码",
      prop: "newPassword"
    }
  }, [_c("el-input", {
    attrs: {
      type: _vm.passwordType,
      autoComplete: "new-password"
    },
    model: {
      value: _vm.editForm.newPassword,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "newPassword", $$v);
      },
      expression: "editForm.newPassword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "重复密码",
      prop: "repassword"
    }
  }, [_c("el-input", {
    attrs: {
      type: _vm.passwordType,
      autoComplete: "new-password"
    },
    model: {
      value: _vm.editForm.repassword,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "repassword", $$v);
      },
      expression: "editForm.repassword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.resetFields
    }
  }, [_vm._v("重置")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "plain"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };