import BatchSaveContent from './batchSaveContent';
import { isJobOpen } from '@/api/merchant';
import JobOpen from '@/base/ui/jobOpen';
export default {
  name: 'batchSave',
  components: {
    JobOpen,
    BatchSaveContent
  },
  data() {
    return {
      loading: true,
      isOpen: false
    };
  },
  created() {
    this.getJobOpen();
  },
  methods: {
    getJobOpen() {
      this.loading = true;
      isJobOpen().then(response => {
        this.loading = false;
        this.isOpen = response.data;
      });
    }
  }
};