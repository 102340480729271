var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称：",
      prop: "merchantName"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.merchantName) + "（" + _vm._s(_vm.saveForm.merchantNo) + "）")])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名：",
      prop: "contacts"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contacts))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机：",
      prop: "contactMobile"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.contactMobile))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区：",
      prop: "cityCode"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatCity()))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位地址：",
      prop: "address"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.address))])])], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "工商信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码：",
      prop: "licenseNo"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.licenseNo))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "纳税人性质：",
      prop: "repayNotifyUrl"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatTaxpayerTypeStatus(_vm.saveForm.taxpayerType)))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名：",
      prop: "legalPerson"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.legalPerson))])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "证件号码：",
      prop: "legalPersonIdcard"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.saveForm.legalPersonIdcard))])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型：",
      prop: "invoiceType"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.formatMchantInvoiceType(_vm.saveForm.invoiceType)))])])], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "证件照片"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照：",
      prop: "licensePic"
    }
  }, [_vm.saveForm.licensePicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.licensePicShow,
      "preview-src-list": [_vm.saveForm.licensePicShow]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证：",
      prop: "legalPersonIdcardpic"
    }
  }, [_vm.saveForm.legalPersonIdcardpicShow ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.saveForm.legalPersonIdcardpicShow,
      "preview-src-list": [_vm.saveForm.legalPersonIdcardpicShow]
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };