export default {
  name: 'dateView',
  data() {
    return {
      time: '',
      weekday: '',
      day: ''
    };
  },
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      const date = new Date();
      const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      this.weekday = week[date.getDay()];
      this.time = date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      this.day = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
      setTimeout(() => {
        this.getDate();
      }, 1000 * 10);
    }
  }
};