import CityCascader from '@/base/ui/cascader/cityCascader';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { getAgentList, freeze, unfreeze, audit } from '@/api/agent';
import { getAcountInfo } from '@/utils/auth';
import { mapGetters } from 'vuex';
import { AGENT_INFO_ID, AGENT_UPDATE_ID } from '@/utils/idName';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import { downloadAgent } from '../../api/agent';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { RATE_LIST_AGENT_NO } from '../../utils/idName';
export default {
  name: 'agentList2',
  // eslint-disable-next-line no-dupe-keys
  components: {
    DbDatePicker,
    CityCascader,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      auditDialogVisible: false,
      total: 0,
      updateAgent: '',
      viewAgent: '',
      addAgent: '',
      forzenAgent: '',
      auditAgent: '',
      list: [],
      listLoading: true,
      statusType: this.$formatTypeArray('statusType'),
      updateStatus: {
        id: '',
        status: ''
      },
      createTime: [],
      searchForm: {
        agentName: '',
        agentNo: '',
        beginTime: '',
        endTime: '',
        taxNo: '',
        pageNo: 1,
        pageSize: 10,
        status: '',
        superiorAgent: ''
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      isFresh: false,
      partyType: '',
      taxSources: [],
      hasDownloadPermission: false,
      agentRateListPath: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.updateAgent && this.clickUpdate) {
      to.meta.isFresh = true; // 去修改页面是否刷新页面的标志
    }
    this.clickUpdate = false;
    next();
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addAgent = map.get('addAgent');
      this.viewAgent = map.get('viewAgent');
      this.updateAgent = map.get('updateAgent');
      this.deleteAgent = map.get('deleteAgent');
      this.forzenAgent = map.get('forzenAgent');
      this.auditAgent = map.get('auditAgent');
      this.hasDownloadPermission = map.has('downloadAgent');
      this.agentRateListPath = map.get('agentRateList');
    }
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    }
    this.getTaxSources();
  },
  methods: {
    operateFlag(row) {
      if (getAcountInfo().partyCode === row.taxNo) {
        return true;
      } else {
        return false;
      }
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const qryCreateTime = separateDate(this.createTime);
      this.currentSearch.beginTime = qryCreateTime.beginTime;
      this.currentSearch.endTime = qryCreateTime.endTime;
      getAgentList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getStatus(status) {
      if (status === 1) {
        return '冻结';
      } else if (status === 9) {
        return '解冻';
      }
    },
    clearQuery() {
      this.searchForm = {
        agentName: '',
        agentNo: '',
        beginTime: '',
        endTime: '',
        taxNo: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      };
      this.createTime = [];
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    handleAdd() {
      this.$router.push({
        path: this.addAgent
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      sessionStorage.setItem(AGENT_UPDATE_ID, row.id);
      this.clickUpdate = true;
      this.$router.push({
        path: this.updateAgent
      });
    },
    // 显示查看界面
    handleView(index, row) {
      sessionStorage.setItem(AGENT_INFO_ID, row.id);
      this.$router.push({
        path: this.viewAgent
      });
    },
    formatCity() {
      if (this.searchForm.provinceCode === '') {
        return '';
      } else {
        return this.searchForm.provinceCode + '/' + this.searchForm.cityCode;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatStatus(row, column) {
      return this.$formatType('statusType', row.status);
    },
    unfreeze(row) {
      this.$confirm('是否确定解冻该服务商?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        unfreeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    freeze(row) {
      this.$confirm('是否确定解冻该服务商?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        freeze(row.id).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.getList();
        });
      }).catch(() => {});
    },
    showAuditDialog(row) {
      this.auditAgentNo = row.agentNo;
      this.auditDialogVisible = true;
    },
    audit(status) {
      this.auditDialogVisible = false;
      audit(this.auditAgentNo, status).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.getList();
      });
    },
    downloadForm() {
      downloadAgent(this.currentSearch).then(response => {
        downloadExcel(response.data, '服务商列表.xls');
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    gotoRate(row) {
      sessionStorage.setItem(RATE_LIST_AGENT_NO, JSON.stringify(row));
      this.$router.push({
        path: this.agentRateListPath
      });
    }
  }
};