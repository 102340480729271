var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-item-container"
  }, [_c("div", {
    staticClass: "process-item-number"
  }, [_vm._v("\n    " + _vm._s(_vm.number) + "\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "process-item-title-container"
  }, [_c("div", {
    staticStyle: {
      color: "black"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#777777"
    }
  }, [_vm._v(_vm._s(_vm.detail))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };