import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { taxChannelInfo, updateTaxChannel } from '@/api/taxChannel';
import { UPDATE_TAXSOURCE_CHANNEL_ID } from '@/utils/idName';
import UpdateProductByCcb from './updateProductByCCB';
import UpdateProductByBjb from './updateProductByBJB';
import UpdateProductByZfb from './updateProductByZFB';
import UpdateProductByMyb from './updateProductByMYB';
import UpdateProductByCcbzx from './updateProductByCCBZX';
import UpdateProductByWeiXin from './updateProductByWEIXIN';
import UpdateProductByYeepay from './updateProductByYeepay.vue';
import { isInteger, isMoney } from '@/utils/validate_rules.js';
export default {
  name: 'updateProduct',
  components: {
    UpdateProductByWeiXin,
    UpdateProductByCcbzx,
    UpdateProductByMyb,
    UpdateProductByZfb,
    UpdateProductByBjb,
    UpdateProductByCcb,
    UpdateProductByYeepay,
    ItemTitle
  },
  data() {
    return {
      taxSourceList: [],
      taxStatusType: this.$formatTypeArray('taxStatusType'),
      rechargeAutoStatus: this.$formatTypeArray('isSupport'),
      saveForm: {
        accountNo: '',
        appId: '',
        appKey: '',
        bankName: '',
        channelName: '',
        channelType: 'YEEPAY',
        custName: '',
        ncPreposeIp: '',
        ncUserId: '',
        ncUserPwd: '',
        privateKey: '',
        publicKey: '',
        quotaMax: '',
        rechargeAuto: '',
        status: '',
        billCode: '',
        useofCode: '',
        baseType: '',
        alipayPid: '',
        ccbBillCode: '',
        subMchid: '',
        subAppid: '',
        singleMin: '',
        singleMax: '',
        onlinePayStatus: 0,
        onlinePayMax: 0,
        onlinePayCount: 0
      },
      rules: {
        bankName: [{
          required: true,
          message: '请输入入账开户行',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        accountNo: [{
          required: true,
          message: '请输入账账户',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        custName: [{
          required: true,
          message: '请输入入账户名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入代付商户SM2私钥',
          trigger: 'blur'
        }],
        channelName: [{
          required: true,
          message: '请输入通道名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '请输入正确的通道名称',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择通道状态',
          trigger: 'blur'
        }],
        baseType: [{
          required: true,
          message: '请选择基准类型',
          trigger: 'blur'
        }],
        onlinePayStatus: [{
          required: true,
          message: '请选择基准类型',
          trigger: 'blur'
        }],
        onlinePayMax: [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }, {
          validator: isMoney,
          trigger: 'blur'
        }],
        onlinePayCount: [{
          required: true,
          message: '请输入次数',
          trigger: 'blur'
        }, {
          validator: isInteger,
          trigger: 'blur'
        }]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.getInfo();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  created() {
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
    // this.getInfo()
  },
  methods: {
    getInfo() {
      const id = sessionStorage.getItem(UPDATE_TAXSOURCE_CHANNEL_ID);
      taxChannelInfo(id).then(response => {
        this.saveForm = response.data;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    addSubmit() {
      // this.saveForm = saveForm
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateTaxChannel(this.saveForm).then(response => {
            this.$message.success('操作成功');
            this.goBack();
          });
        } else {
          return false;
        }
      });
    },
    goBack(message) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (message) {
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    },
    formatChannelType(channelType) {
      return this.$formatType('channelType', channelType);
    }
  }
};