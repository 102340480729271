var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "修改开票类目"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "140px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.item.taxName) + "(" + _vm._s(_vm.item.taxNo) + ")\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票类型：",
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择开票类型"
    },
    model: {
      value: _vm.saveForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "invoiceType", $$v);
      },
      expression: "saveForm.invoiceType"
    }
  }, _vm._l(_vm.$formatTypeArray("merchantInvoiceType"), function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票内容：",
      prop: "contentName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票名称"
    },
    model: {
      value: _vm.saveForm.contentName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contentName", $$v);
      },
      expression: "saveForm.contentName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "示例项目名称：",
      prop: "exampleProjectList"
    }
  }, [_c("db-add-tags", {
    attrs: {
      btnText: "+ 新 增"
    },
    model: {
      value: _vm.saveForm.exampleProjectList,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "exampleProjectList", $$v);
      },
      expression: "saveForm.exampleProjectList"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.saveForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "remark", $$v);
      },
      expression: "saveForm.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateBtn
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };