import UpdateAppInfo from './update';
import { getAppInfo, openApp } from '@/api/safe';
import { mapGetters } from 'vuex';
import { VIEW_APP_INFO_ID } from '../../../utils/idName';
export default {
  components: {
    UpdateAppInfo
  },
  data() {
    return {
      appInfo: null,
      openAppInfo: false,
      updateAppInfo: false,
      resetAppInfoSign: false,
      queryLoading: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    this.getAppInfo();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.openAppInfo = map.has('openAppInfo');
      this.updateAppInfo = map.has('updateAppInfo');
      this.resetAppInfoSign = map.has('resetAppInfoSign');
    }
  },
  methods: {
    openApp() {
      openApp().then(() => {
        this.$message.success('开通成功。。。');
        this.getAppInfo();
      });
    },
    getAppInfo() {
      this.queryLoading = true;
      const id = sessionStorage.getItem(VIEW_APP_INFO_ID);
      getAppInfo(id).then(response => {
        this.queryLoading = false;
        this.appInfo = response.data;
      });
    }
  }
};