var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商编号：",
      prop: "agentNo"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入服务商编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.agentNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "agentNo", $$v);
      },
      expression: "searchForm.agentNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "服务商名称：",
      prop: "agentName"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入服务商名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.agentName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "agentName", $$v);
      },
      expression: "searchForm.agentName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.$formatTypeArray("agentProfitRecordStatus"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "服务商：",
      prop: "agentLevel"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择服务商属性"
    },
    model: {
      value: _vm.searchForm.agentLevel,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "agentLevel", $$v);
      },
      expression: "searchForm.agentLevel"
    }
  }, _vm._l(_vm.$formatTypeArray("agentLevelStatutes"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "月份：",
      prop: "profitMonth",
      clearable: ""
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "月份",
      format: "yyyy-MM",
      "value-format": "yyyy-MM",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.profitMonth,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "profitMonth", $$v);
      },
      expression: "searchForm.profitMonth"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]) : _vm._e(), _vm._v(" "), _vm.singleSettlePermisson ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-coin"
    },
    on: {
      click: _vm.batchSettle
    }
  }, [_vm._v("批量结算")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "total_label"
  }, [_vm._v("查询汇总：  \n    交易业绩总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[0])))]), _vm._v("；\n    业绩分润总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[1])))]), _vm._v("；\n    业绩奖励总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[2])))]), _vm._v("；\n    "), _c("br"), _vm._v("\n    应结算总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[3])))]), _vm._v("；\n    实际结算总金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.summary[4])))]), _vm._v("。\n\n  ")], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "profitMonth",
      label: "月份",
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentNo",
      label: "服务商编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentName",
      label: "服务商名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "agentLevel",
      label: "服务商属性",
      "min-width": "150",
      formatter: _vm.$formatTypeInTable("agentLevelStatutes")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "province",
      label: "所属地区",
      "min-width": "140px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "tradeAmount",
      label: "交易业绩",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "profitAmount",
      label: "业绩分润",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rewardAmount",
      label: "业绩奖励",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "settleAmount",
      label: "应结算金额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "adjustAmount",
      label: "调账金额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "incomeAmount",
      label: "实际结算金额",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      "min-width": "120",
      formatter: _vm.$formatTypeInTable("agentProfitRecordStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "submitOpinion",
      label: "备注",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "130",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.viewRecordPath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), scope.row.status === -1 && _vm.adjustRecordPath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleAdjust(scope.row);
            }
          }
        }, [_vm._v("复核账单")]) : _vm._e(), _vm._v(" "), scope.row.status === 0 && _vm.confrimRecordPath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleConfirm(scope.row);
            }
          }
        }, [_vm._v("确认")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && _vm.singleSettlePermisson ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleSingleSettle(scope.row);
            }
          }
        }, [_vm._v("结算")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };