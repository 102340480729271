import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import DialogSignApply from '../components/dialog/dialogSignApply';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { mapGetters } from 'vuex';
import { teamInfoDetailList } from '@/api/leaderApply';
export default {
  name: 'LeaderApplyList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker,
    DialogSignApply
  },
  data() {
    return {
      loading: false,
      agreementVisible: false,
      invoiceVisible: false,
      groupDetail: '',
      groupEdit: '',
      signApply: '',
      searchForm: {
        keyWords: '',
        status: '',
        teamType: ''
      },
      datePicker: [],
      datePicker1: [],
      statusList: [],
      list: [],
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      signVisible: false,
      selectRow: {}
    };
  },
  created() {
    this.statusList = this.$formatTypeArray('leaderStatus');
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    if (map) {
      this.groupDetail = map.get('groupDetail');
      this.groupEdit = map.get('groupEdit');
      this.signApply = map.get('signApply');
    }
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryForm();
    });
  },
  methods: {
    queryForm() {
      this.currentSearch = {
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    onExport() {
      // todo: 带参数调用导出接口
      // downloadMerchant(this.currentSearch)
      //   .then(response => {
      //     downloadExcel(response.data, '企业列表.xls')
      //   })
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = this.$options.data().searchForm;
      this.queryForm();
    },
    async getList() {
      this.loading = true;
      const {
        keyWords,
        status,
        teamType
      } = this.searchForm;
      const {
        pageNo,
        pageSize
      } = this.currentSearch;
      const [cBeginTime, cEndTime] = this.datePicker;
      const [uBeginTime, uEndTime] = this.datePicker1;
      const params = {
        keyWords,
        status,
        teamType,
        pageNo,
        pageSize,
        cBeginTime,
        cEndTime,
        uBeginTime,
        uEndTime
      };
      const res = await teamInfoDetailList(params);
      this.list = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    },
    handleView(index, row) {
      this.$router.push({
        path: this.groupDetail,
        query: {
          review: 0,
          id: row.id
        }
      });
    },
    onAgreement(index, row) {
      this.agreementVisible = true;
    },
    onApplyInvoice(index, row) {
      this.invoiceVisible = true;
    },
    onGroupEdit(index, row) {
      this.$router.push({
        path: this.groupEdit,
        query: {
          review: 1,
          id: row.id
        }
      });
    },
    onSignApply(index, row) {
      this.selectRow = row;
      this.signVisible = true;
    }
  }
};