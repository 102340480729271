var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-steps", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      active: _vm.active,
      "finish-status": "success"
    }
  }, [_c("el-step", {
    attrs: {
      title: "基本信息"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "费率设置"
    }
  })], 1), _vm._v(" "), _vm.active === 0 ? _c("tax-source-save-base", {
    ref: "submit",
    attrs: {
      gotoNext: _vm.gotoSecond
    }
  }) : _vm._e(), _vm._v(" "), _vm.active === 1 ? _c("save-rate", {
    ref: "submit",
    attrs: {
      gotoNext: _vm.finish,
      taxNo: _vm.taxNo
    }
  }) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticClass: "step-next-btn",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.next
    }
  }, [_vm._v("下一步")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };