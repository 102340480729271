import { repaymentSaveReq, transactionUploadListReq } from '@/api/transaction';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import table from '@/utils/table';
import { PAY_VERIFY_CODE } from '@/utils/idName';
import VerifyCode from '@/base/ui/verifyCode';
import { repaymentDownUnAuthReq } from '../../../api/transaction';
import { downloadExcel } from '../../../utils/util';
export default {
  name: 'transaction-batch-query',
  components: {
    VerifyCode,
    ItemTitle,
    Pagination
  },
  props: {
    repayType: {
      default: {
        type: Number,
        default: () => {
          return 0; // 付款类型：0-临时经营个人付款，1-个体工商户付款
        }
      }
    },
    isVisible: {
      required: true,
      type: Boolean
    },
    transactionData: {
      type: Object
    },
    unAuthCnt: {
      type: Number,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      listLoading: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      repaymentList: [],
      payVerifyCode: PAY_VERIFY_CODE,
      verifyDialogVisible: false,
      payAuthVerifySms: 0
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    total: function () {
      return this.transactionData != null ? this.transactionData.total : 0;
    },
    totalAmount: function () {
      return this.transactionData != null ? this.$fmoneyWithSymbol(this.transactionData.totalAmount) : '';
    },
    batchNo: function () {
      return this.transactionData != null ? this.transactionData.batchNo : '';
    },
    largeCount: function () {
      return this.transactionData != null ? this.transactionData.total : 0;
    }
  },
  watch: {
    transactionData: function (newValue, oldValue) {
      this.repaymentList = newValue != null ? newValue.firstPageList.list : [];
    }
  },
  created() {
    // this.$refs.elDialog[0].$el.firstChild.style.height = '80%'
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    submitForm() {
      if (this.unAuthCnt > 0) {
        this.showHalfImagNotice();
      } else {
        this.verifyDialogVisible = true;
      }
    },
    cancelForm() {
      this.dialogVisible = false;
      this.$emit('on-cancel');
    },
    repaymentSave() {
      this.isLoading = true;
      this.verifyDialogVisible = false;
      let code = '';
      if (this.transactionData.payAuthVerifySms === 1) {
        code = this.$refs.verifyDialog.verifyCode;
      }
      repaymentSaveReq({
        taxNo: this.transactionData.taxNo,
        attachName: this.transactionData.attachName,
        attachUrl: this.transactionData.attachUrl,
        cacheKey: this.transactionData.cacheKey
      }).then(() => {
        this.$message.success('操作成功！');
        this.isLoading = false;
        this.dialogVisible = false;
        this.$emit('on-success');
      }).catch(() => {
        this.isLoading = false;
      });
    },
    pageChanged() {
      this.listLoading = true;
      transactionUploadListReq({
        taxNo: this.transactionData.taxNo,
        cacheKey: this.transactionData.cacheKey,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listLoading = false;
        this.repaymentList = response.data.list;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    showHalfImagNotice() {
      this.$confirm(`
          付款名单中，存在${this.unAuthCnt}人身份信息不存在/不完善，可下载名单查看具体人员和错误原因。请先线下通知他们完善信息后再重新提交！`, '证件信息提醒', {
        type: 'warning',
        // confirmButtonText: '继续付款，并下载名单',
        cancelButtonText: '下载名单',
        showConfirmButton: false,
        center: true,
        showClose: false,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        // this.repaymentDownUnAuth(true)
      }).catch(() => {
        this.repaymentDownUnAuth(false);
      });
    },
    repaymentDownUnAuth(isSave) {
      repaymentDownUnAuthReq({
        taxNo: this.transactionData.taxNo,
        merchantNo: this.transactionData.merchantNo,
        projectNo: this.transactionData.projectNo,
        batchNo: this.transactionData.batchNo
      }).then(response => {
        if (isSave) {
          this.repaymentSave();
        } else {
          this.dialogVisible = false;
          this.$emit('on-cancel');
        }
        downloadExcel(response.data, '认证问题数据.xls');
      });
    }
  }
};