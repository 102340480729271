var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属地区：",
      prop: "selectedOptions"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      placeholder: "请选择所属地区",
      leaf: 0
    },
    model: {
      value: _vm.selectedOptions,
      callback: function ($$v) {
        _vm.selectedOptions = $$v;
      },
      expression: "selectedOptions"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "入账时间："
    }
  }, [_c("db-date-picker", {
    attrs: {
      format: "yyyy-MM-dd"
    },
    model: {
      value: _vm.dateTime,
      callback: function ($$v) {
        _vm.dateTime = $$v;
      },
      expression: "dateTime"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("region-trans-chart", {
    attrs: {
      searchForm: _vm.currentSearch
    }
  }), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "province",
      label: "所属地区",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "交易额(元)",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.currentSearch.pageNo,
      limit: _vm.currentSearch.pageSize,
      autoScroll: false
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageNo", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.currentSearch, "pageSize", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };