var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择",
      size: "mini"
    },
    on: {
      change: _vm.onSelectTaxNo
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxsourceList, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "所属企业",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.searchForm.taxNo || _vm.merchantNos.length === 0,
      clearable: "",
      filterable: "",
      placeholder: "请选择所属企业",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "merchantNo", $$v);
      },
      expression: "searchForm.merchantNo"
    }
  }, _vm._l(_vm.merchantNos, function (item) {
    return _c("el-option", {
      key: item.merchantNo,
      attrs: {
        label: _vm.concatMerchant(item),
        value: item.merchantNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目名称",
      prop: "projectName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectName", $$v);
      },
      expression: "searchForm.projectName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目母编号",
      prop: "parentProjectNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目母编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.parentProjectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "parentProjectNo", $$v);
      },
      expression: "searchForm.parentProjectNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目子编号",
      prop: "projectNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目子编号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "projectNo", $$v);
      },
      expression: "searchForm.projectNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "项目状态"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择状态",
      size: "mini"
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, _vm._l(_vm.taxStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间"
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.datePicker,
      callback: function ($$v) {
        _vm.datePicker = $$v;
      },
      expression: "datePicker"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearQuery
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.addMerchant ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]) : _vm._e(), _vm._v(" "), _vm.hasDownloadPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: _vm.downloadForm
    }
  }, [_vm._v("导出列表\n      ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "50",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "parentProjectNo",
      label: "项目母编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectNo",
      label: "项目子编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectName",
      label: "项目名称",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "项目状态",
      formatter: _vm.formatStatus
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "所属甲方单位",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "所属劳务公司",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "projectScope",
      label: "分包范围",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "amount",
      label: "项目金额",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "startDate",
      label: "开工日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "completeDate",
      label: "完工日期",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "工程地址",
      "min-width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n        " + _vm._s(scope.row.address) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "180",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.viewMerchant ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleView(scope.$index, scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm._v(" "), _vm.updateMerchant ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), scope.row.status === 1 && _vm.hasForzenPermission ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.freeze(scope.row);
            }
          }
        }, [_vm._v("冻结")]) : _vm._e(), _vm._v(" "), scope.row.status === 9 && _vm.hasForzenPermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.unfreeze(scope.row);
            }
          }
        }, [_vm._v("解冻\n        ")]) : _vm._e(), _vm._v(" "), _vm.addAgreementPer ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.addAgreement(scope.$index, scope.row);
            }
          }
        }, [_vm._v("添加合同")]) : _vm._e(), _vm._v(" "), _vm.viewInvitePer ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.viewInvite(scope.$index, scope.row);
            }
          }
        }, [_vm._v("邀请码")]) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("add-and-edit-agreement", {
    attrs: {
      visiable: _vm.dialogVisiable,
      projectNo: _vm.activeRow.projectNo
    },
    on: {
      "update:visiable": function ($event) {
        _vm.dialogVisiable = $event;
      },
      handleSuccess: _vm.getList
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "项目邀请码",
      visible: _vm.dialogInviteVisible,
      width: "36%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogInviteVisible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      width: "400px",
      height: "400px",
      margin: "0 auto"
    }
  }, [_c("el-image", {
    attrs: {
      src: _vm.inviteUrl,
      fit: "fit"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };