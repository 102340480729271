import { mapGetters } from "vuex";
import table from "@/utils/table";
import { invoiceInfo } from "@/api/teamInvoice";
import { INVOICE_OPERATION_PERMISSION } from "@/utils/idName";
import ItemTitle from "@/base/ui/title/itemTitle";
import DetailColumn from "@/components/detailColumn";
import CheckInvoice from "../components/checkInvoice";
import UpdateInvoice from "../components/updateInvoice";
export default {
  name: "teamInvoiceDetail",
  components: {
    ItemTitle,
    DetailColumn,
    CheckInvoice,
    UpdateInvoice
  },
  data() {
    return {
      infoForm: {
        logList: []
      },
      hasCancelPermission: false,
      hasCheckPermission: false,
      hasUpdatePermission: false,
      id: "",
      span: 12,
      gutter: 40,
      isCheckVisible: false,
      isUpdateVisible: false,
      expressInfo: null
    };
  },
  computed: {
    ...mapGetters(["buttonsMap"]),
    invoiceType: function () {
      return this.$formatType("invoiceType", this.infoForm.invoiceType);
    },
    openType: function () {
      return this.$formatType("invoiceOpenType", this.infoForm.openType);
    },
    status: function () {
      return this.$formatType("invoiceStatus", this.infoForm.invoiceStatus);
    },
    isCheck: function () {
      return this.infoForm.status === 0 && (this.hasCancelPermission || this.hasCheckPermission) || (this.infoForm.status === 1 || this.infoForm.status === 2) && this.hasUpdatePermission;
    }
  },
  mounted() {
    console.log('----------');
    this.id = sessionStorage.getItem(INVOICE_OPERATION_PERMISSION);
    this.getDetail();
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.id = sessionStorage.getItem(INVOICE_OPERATION_PERMISSION);
  //     vm.getDetail();
  //   });
  // },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    getDetail() {
      invoiceInfo({
        id: this.id
      }).then(response => {
        console.log('response: ', response);
        this.infoForm = response.data;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch("delVisitedViewsByPath", path, name).then(() => {
        this.$router.go(-1);
      });
    },
    // 刷新
    updated() {
      this.getDetail();
    }
  }
};