var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "total-data-item"
  }, [_c("img", {
    attrs: {
      src: _vm.imgIcon
    }
  }), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "total-data-item-name"
  }, [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]), _vm._v(" "), _vm.num > -1 ? _c("div", {
    staticClass: "total-data-item-num"
  }, [_c("nx-count-up", {
    attrs: {
      start: 14,
      end: _vm.num
    }
  }), _c("span", {
    staticClass: "total-data-item-unit"
  }, [_vm._v(_vm._s(_vm.unit))])], 1) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };