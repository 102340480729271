import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { employeeList, downloadEmpList } from '@/api/operateEmp';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import UpdateEmpDialog from './components/updateEmpDialog';
import { downPersonPic, queryEmpInfo } from '../../api/operateEmp';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import UploadJpgFileDialog from '@/base/ui/upload/uploadJpgFileDialog';
import InviteEmployeeDialog from '@/base/ui/dialog/inviteEmployeeDialog';
import { downloadAllFile } from '@/utils/util';
import AuditEmployeeDialog from '@/base/ui/dialog/auditEmployeeDialog';
import ViewIdCardDialog from './components/viewIDCardDialog';
import { EMPLOYEE_INFO_ID } from '../../utils/idName';
export default {
  name: 'optEmployeeList',
  components: {
    ViewIdCardDialog,
    AuditEmployeeDialog,
    InviteEmployeeDialog,
    UploadJpgFileDialog,
    UpdateEmpDialog,
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      viewIdCardDialogVisible: false,
      idcardBack: '',
      idcardFront: '',
      halfBody: '',
      updateDialogVisible: false,
      total: 0,
      hasUpdatePermission: '',
      // 修改人员信息权限,
      hasDownloadPermission: '',
      // 导出人员信息权限
      uploadFilePath: '',
      invitePermission: '',
      downPersonPicPermission: '',
      viewPersonPicPermission: false,
      list: [],
      datePicker: [],
      updateTimePicker: [],
      listLoading: true,
      empStatuses: this.$formatTypeArray('empStatus'),
      authTypes: this.$formatTypeArray('authType'),
      searchForm: {
        merchantNo: '',
        merchantName: '',
        authType: '',
        keyWords: '',
        status: '',
        beginTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      chooseItem: null,
      photoItem: {},
      uploadPhotoDialog: false,
      inviteItem: {},
      auditItem: {},
      inviteDialog: false,
      auditDialogVisible: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPremission = map.get('optDownloadEmpList');
      this.hasUpdatePermission = map.get('optUpdateEmpSign');
      this.hasDownloadPermission = map.has('optDownloadEmpList');
      this.uploadFilePath = map.get('uploadFile');
      this.invitePermission = map.has('invite');
      this.downPersonPicPermission = map.has('downPersonPic');
      this.viewPersonPicPermission = map.has('viewPersonPic');
    }
    this.getList();
  },
  methods: {
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      if (Array.isArray(this.updateTimePicker) && this.updateTimePicker.length === 2) {
        this.currentSearch.updateBeginTime = this.updateTimePicker[0];
        this.currentSearch.updateEndTime = this.updateTimePicker[1];
      }
      employeeList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
        this.auditItem = this.list[0];
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        merchantNo: '',
        merchantName: '',
        authType: '',
        keyWords: '',
        status: '',
        beginTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.updateTimePicker = [];
      this.getList();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    formatEmpStatus(row, column) {
      return this.$formatType('empStatus', row.status);
    },
    formatAuthTypes(row, column) {
      return this.$formatType('authType', row.authType);
    },
    formatEmpSex(row, column) {
      return this.$formatType('sexType', row.sex);
    },
    downloadData() {
      this.listLoading = true;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.searchForm.beginTime = this.datePicker[0];
        this.searchForm.endTime = this.datePicker[1];
      }
      if (Array.isArray(this.updateTimePicker) && this.updateTimePicker.length === 2) {
        this.currentSearch.updateBeginTime = this.updateTimePicker[0];
        this.currentSearch.updateEndTime = this.updateTimePicker[1];
      }
      downloadEmpList(this.searchForm).then(response => {
        if (!response.data) {
          return;
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        const fileName = '人员记录.xls';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
        console.log('error: ' + error);
        this.$message({
          message: '下载文件异常',
          type: 'error'
        });
      });
    },
    // 显示编辑界面
    handleEdit(index, row) {
      queryEmpInfo(row.pbNo).then(response => {
        if (response.code === 'BASE.000') {
          this.chooseItem = response.data;
          this.updateDialogVisible = true;
        } else {
          this.$message({
            message: response.errMsg,
            type: 'error'
          });
        }
      });
    },
    changeVisibleUpdate(val) {
      // 监听修改dialog的updateDialogVisible的变化
      this.updateDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    changeVisibleUploadPhotoDialog(val) {
      this.uploadPhotoDialog = val;
      if (val === false) {
        this.getList();
      }
    },
    showUploadPhotoDialog(row) {
      this.photoItem = row;
      this.uploadPhotoDialog = true;
    },
    changeVisibleInviteDialog(val) {
      this.inviteDialog = val;
      if (val === false) {
        this.getList();
      }
    },
    showInviteDialog(row) {
      this.inviteItem = row;
      this.inviteDialog = true;
    },
    downloadPersonPicFile(row) {
      downPersonPic(row.id).then(response => {
        downloadAllFile(response.data, response.headers.filename);
      });
    },
    changeVisibleAuditDialog(val) {
      this.auditDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    showAuditDialog(row) {
      this.auditItem = row;
      this.auditDialogVisible = true;
    },
    changeVisibleViewIdDialog(val) {
      this.viewIdCardDialogVisible = val;
    },
    viewPersonPicFile(row) {
      this.idcardBack = row.idcardBack;
      this.idcardFront = row.idcardFront;
      this.halfBody = row.halfBodyUrl;
      this.viewIdCardDialogVisible = true;
    },
    gotoUploadFile(row) {
      sessionStorage.setItem(EMPLOYEE_INFO_ID, row.id);
      this.$router.push({
        path: this.uploadFilePath
      });
    }
  }
};