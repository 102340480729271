import website from '@/const/website';
const common = {
  state: {
    website: website,
    updateRecordResponse: ''
  },
  actions: {
    // 设置返回对象
    setUpdateRecordResponse({
      commit
    }, responseData) {
      return new Promise((resolve, reject) => {
        // debugger
        commit('SET_UPDATE_RECORD_RESPONSE', responseData);
        resolve(responseData);
      });
    }
  },
  mutations: {
    SET_UPDATE_RECORD_RESPONSE: (state, data) => {
      state.updateRecordResponse = data;
    }
  }
};
export default common;