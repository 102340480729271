var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "邀请半身照认证"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      "label-width": "90px",
      size: "mini",
      "label-position": "left",
      model: _vm.saveForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "邀请人："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.userName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "被邀请人："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.item.empName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "编号：",
      prop: "accountName"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.item.empNo) + "\n    ")]), _vm._v(" "), _c("div", [_vm._v("\n      被邀请用户可登录“合作签约”微信小程序，在“我的信息”\n      页面进行半身照认证，认证成功后即可进行大额发放,是否确\n      定立即邀请？\n    ")]), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("确认邀请")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.myDialogFormVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };