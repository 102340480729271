var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "merchant-view"
  }, [_vm._m(0), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.list
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "排名",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticClass: "merchant-index"
        }, [_vm._v(_vm._s(scope.$index + 1))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "商户名称",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "成交额",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", {
          staticStyle: {
            color: "#FF5400"
          }
        }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(scope.row.tradeAmount)))])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "merchant-view-title"
  }, [_c("span", [_vm._v("商户交易排行")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };