import { addInvoiceInfoReq, updateInvoiceInfoReq } from '@/api/invoice';
import { getAcountInfo } from '@/utils/auth';
import { INVOICE_INFO } from '@/utils/idName';
export default {
  name: 'invoice-apply-update-info',
  data() {
    return {
      invoiceForm: {
        accountNo: '',
        bankName: '',
        companyAddress: '',
        companyNumber: '',
        invoiceNo: '',
        invoiceType: '',
        merchantName: '',
        merchantNo: '',
        openType: ''
      },
      tips: ['增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人，购买人取得后不可以进行进项税额抵扣。若您还有疑问，建议联系贵司财务确认后再提交开票需求。', '增值税专用发票开给一般纳税人，申请时需要提供公司名称、税号、地址电话、开户行名称及账户，一般纳税人批复，购买人取得后可以进行进项税额抵扣。若您还有疑问，建议联系贵司财务确认后再提交开票需求。', '其他组织（如政府、公共事业、非盈利机构等），无税号。'],
      isAdd: true,
      rules: {
        openType: [{
          required: true,
          message: '请选择开具类型',
          trigger: 'change'
        }],
        merchantName: [{
          required: true,
          message: '请输入发票抬头',
          trigger: 'blur'
        }],
        invoiceType: [{
          required: true,
          message: '请选择发票类型',
          trigger: 'change'
        }],
        invoiceNo: [{
          required: true,
          message: '税务登记证号',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    let info = null;
    const infoJson = sessionStorage.getItem(INVOICE_INFO);
    if (infoJson != null) {
      info = JSON.parse(infoJson);
    }
    if (info != null) {
      this.isAdd = false;
      this.invoiceForm = {
        ...info
      };
    } else {
      this.isAdd = true;
      const user = getAcountInfo();
      this.invoiceForm.merchantNo = user.partyCode;
    }
  },
  methods: {
    submitForm() {
      this.$refs['invoiceForm'].validate(valid => {
        if (valid) {
          if (this.isAdd) {
            // 新增
            this.addInvoiceInfo();
          } else {
            // 修改
            this.updateInvoiceInfo();
          }
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.goBack();
    },
    addInvoiceInfo() {
      addInvoiceInfoReq(this.invoiceForm).then(() => {
        this.goBack('添加成功...');
      });
    },
    updateInvoiceInfo() {
      updateInvoiceInfoReq(this.invoiceForm).then(() => {
        this.goBack('修改成功...');
      });
    },
    goBack(message) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (message) {
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};