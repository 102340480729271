/**
 * Created by jiachenpan on 16/11/18.
 */

export function validUsername(val) {
  /** const valid_map = ['admin', 'editor']
   return valid_map.indexOf(str.trim()) >= 0*/
  return true;
}
/* 验证用户密码 密码需包含大小写字母、数字和符号，长度6~16位 */
export function validPassword(val) {
  // const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{6,16}$/
  const reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{6,16}$/;
  return reg.test(val);
}

/* 验证有两位小数的正实数 */
export function validMoneyNumber(val) {
  const reg = /^[0-9]+(.[0-9]{1,2})?$/;
  return reg.test(val);
}
/* 验证小数的正实数 */
export function validDecimal(val) {
  const reg = /^[0-9]+(.[0-9]+)?$/;
  return reg.test(val);
}

/* 非负正整数 */
export function validInteger(val) {
  const reg = /^\d*$/;
  return reg.test(val);
}

/* 只能输入数字、字母、汉字、下划线) */
export function validRegexn(val) {
  // const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/
  const reg = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  return reg.test(val);
}

/* 只能输入数字、字母和下划线 */
export function validRegexnNoLen(val) {
  // const reg = /^[a-zA-Z0-9_]+$/
  const reg = /^\w+$/;
  return reg.test(val);
}

/* 只能输入4位数字、字母 */
export function validRegexn4Len(val) {
  const reg = /^[a-zA-Z0-9]{4}$/;
  return reg.test(val);
}

/* 只能输入6位数字、字母 */
export function validRegexn6Len(val) {
  const reg = /^[a-zA-Z0-9]{6}$/;
  return reg.test(val);
}

/* 只能输入1~16位数字、字母 */
export function validRegexn16Len(val) {
  const reg = /^[a-zA-Z0-9]{1,16}$/;
  return reg.test(val);
}

/* 只能输入16～20位数字、字母 */
export function validRegexn16to20Len(val) {
  const reg = /^[a-zA-Z0-9]{16,20}$/;
  return reg.test(val);
}

/* 验证身份证格式 */
export function validIdcard(idcard) {
  const reg = /^([1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x))+$/;
  return reg.test(idcard);
}

/* 验证手机号(170,171,176 虚拟号段暂不支持) */
export function validMobile(mobile) {
  const reg = /^(1[3-9])\d{9}$/;
  return reg.test(mobile);
}

/* 验证Email地址 */
export function validEmail(email) {
  // const reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return reg.test(email);
}

/* 小写字母*/
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/* 合法URL*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 判断时间是否过期*/
export function validateJudgeTime(time) {
  var strtime = time.replace('/-/g', '/');
  var date1 = new Date(strtime);
  var date2 = new Date();
  if (date1 > date2) {
    return true;
  } else {
    return false;
  }
}

/* n位的数字 */
export function validNumberLen(len, val) {
  const reg = /^[0-9]{len}$/;
  return reg.test(val);
}

/* 邮政编码 */
export function validZipCode(val) {
  const reg = /^[0-9]{6}$/;
  return reg.test(val);
}

/* 银行卡号 */
export function validBankCardNo(val) {
  const reg = /^\d{6,24}$/;
  return reg.test(val);
}

/* 大于0的小数 */
export function validFloat(val) {
  const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  return reg.test(val);
}
/* 0<val<1的小数 */
export function validFloat01(val) {
  const reg = /^0(\.[0-9]+)?$/;
  return reg.test(val);
}
/* 0<val<=1的小数 */
export function validFloat02(val) {
  const reg = /^([01](\.0+)?|0\.[0-9]+)$/;
  return reg.test(val);
}
export function validLicenseNo(val) {
  const reg = /^[a-zA-Z0-9]{11,20}$/;
  return reg.test(val);
}