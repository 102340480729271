import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '../../../../api/taxsource';
import { addTaxRouterOpen, routerSelect } from '../../../../api/taxRouterOpen';
import { isValidFloat01 } from '@/utils/validate_rules';
export default {
  name: 'AddTaxRouterOpenDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        belong: '',
        taxNo: '',
        diffRechargeRate: '',
        phone: ''
      },
      myDialogFormVisible: false,
      belongList: [],
      taxRouterList: [],
      rules: {
        belong: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: '请选择路由劳务公司',
          trigger: 'blur'
        }],
        diffRechargeRate: [{
          required: true,
          message: '请输入付款时的充值费率(差额)',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }],
        rechargeRate: [{
          required: true,
          message: '请输入付款时的充值费率(增值)',
          trigger: 'blur'
        }, {
          validator: isValidFloat01,
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    addDialogVisible: false
  },
  watch: {
    addDialogVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeAddDialogVisible', val);
    }
  },
  created() {
    showlist().then(response => {
      this.belongList = response.data;
    });
    routerSelect().then(response => {
      this.taxRouterList = response.data;
    });
  },
  methods: {
    addBtn() {
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          addTaxRouterOpen(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};