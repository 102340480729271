var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("el-image", {
    staticStyle: {
      width: "140px",
      height: "auto"
    },
    attrs: {
      src: "static/img/logo_white.png"
    }
  }), _vm._v(" "), _c("transition-group", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.isCollapse ? void 0 : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };