var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "upload-box"
  }, [this.myUrl !== "" ? _c("el-link", {
    attrs: {
      size: "mini",
      type: "primary",
      href: this.myUrl,
      target: "_blank"
    }
  }, [_vm._v(_vm._s(this.myFileName) + "\n    ")]) : _vm._e(), _vm._v(" "), _c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "show-file-list": false,
      "on-success": _vm.onSuccess,
      "before-upload": _vm.beforeDemoUpload,
      action: _vm.saveFile,
      data: {
        bizCode: _vm.partyCode
      }
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("上传")])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：上传PDF文件，不超过8M)")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };