import { mapGetters } from 'vuex';
import { getWithdrawInfoReq, getWithdrawUpdateReq } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import { WITHDRAW_DETAIL_ID, WITHDRAW_CHECK_ID } from '@/utils/idName';
import AgentProfitDetail from './agentProfitDetail';
import ElLink from 'element-ui/packages/link/src/main';
export default {
  name: 'withdraw-detail',
  components: {
    ElLink,
    AgentProfitDetail,
    ItemTitle,
    DetailColumn
  },
  data() {
    return {
      infoForm: {
        logList: []
      },
      reviewForm: {
        withdrawRemark: ''
      },
      span: 12,
      gutter: 40,
      rules: {
        withdrawRemark: [{
          required: true,
          message: '审核意见不能为空',
          trigger: 'blur'
        }, {
          max: 50,
          message: '最多输入50个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    accountType: function () {
      return this.$formatType('bankType', this.infoForm.accountType);
    },
    status: function () {
      return this.$formatType('withdrawStatus', this.infoForm.withdrawStatus);
    },
    isCheck: function () {
      return this.$route.path.includes('check');
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    pass() {
      this.$refs['reviewForm'].validate(valid => {
        if (valid) {
          this.$confirm('确认通过此次提现吗？', '审核确认', {
            confirmButtonText: '确认通过',
            cancelButtonText: '取消',
            center: true,
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            this.checkDetail(1);
          });
        } else {
          return false;
        }
      });
    },
    reject() {
      this.$refs['reviewForm'].validate(valid => {
        if (valid) {
          this.$confirm('确认拒绝此次提现吗？', '审核确认', {
            confirmButtonText: '确认拒绝',
            cancelButtonText: '取消',
            center: true,
            closeOnClickModal: false
            // closeOnPressEscape: false,
          }).then(() => {
            this.checkDetail(3);
          });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.goBack();
    },
    getDetail() {
      const id = this.isCheck ? sessionStorage.getItem(WITHDRAW_CHECK_ID) : sessionStorage.getItem(WITHDRAW_DETAIL_ID);
      getWithdrawInfoReq({
        id: id
      }).then(response => {
        this.infoForm = response.data;
      });
    },
    checkDetail(status) {
      const id = sessionStorage.getItem(WITHDRAW_CHECK_ID);
      const withdrawRemark = this.reviewForm.withdrawRemark;
      getWithdrawUpdateReq({
        id: id,
        withdrawRemark: withdrawRemark
      }).then(() => {
        this.goBack(status);
      });
    },
    goBack(status) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (status) {
          const message = status === 1 ? '已通过' : '已拒绝';
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};