var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "160px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "支付宝参数"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝APPID",
      prop: "appId"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入支付宝APPID"
    },
    model: {
      value: _vm.saveForm.appId,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "appId", $$v);
      },
      expression: "saveForm.appId"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝PID",
      prop: "alipayPid"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入支付宝PID"
    },
    model: {
      value: _vm.saveForm.alipayPid,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "alipayPid", $$v);
      },
      expression: "saveForm.alipayPid"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付宝应用私钥",
      prop: "privateKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      clearable: "",
      placeholder: "请输入支付宝应用私钥"
    },
    model: {
      value: _vm.saveForm.privateKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "privateKey", $$v);
      },
      expression: "saveForm.privateKey"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "其他设置"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 60
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支持充值入单",
      prop: "rechargeAuto"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.rechargeAuto,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "rechargeAuto", $$v);
      },
      expression: "saveForm.rechargeAuto"
    }
  }, _vm._l(_vm.rechargeAutoStatus, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "通道启用状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "status", $$v);
      },
      expression: "saveForm.status"
    }
  }, _vm._l(_vm.taxStatusType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px",
      "margin-left": "-140px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.checkSaveForm
    }
  }, [_vm._v("保存")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };