import errGif from '@/assets/images/401_images/401.gif';
export default {
  name: 'page401',
  data() {
    return {
      errGif: errGif + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false
    };
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: '/'
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};