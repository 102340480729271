var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "app-main"
  }, [_c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.cachedViews
    }
  }, [_c("router-view")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };