import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate } from '@/utils/util';
import Pagination from '@/components/pagination';
import { getConsultListReq, closeConsultReq } from '@/api/consult';
import ItemTitle from '@/base/ui/title/itemTitle';
import HandleForm from './components/handleForm';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'consultIndex',
  components: {
    ItemTitle,
    Pagination,
    HandleForm,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        customerName: '',
        customerPhone: '',
        companyName: '',
        status: '',
        handlerNo: '',
        date: []
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      consults: [],
      hasHandlePermission: false,
      hasClosePermission: false,
      isHandleVisible: false,
      curRecordID: ''
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    statusOptions: function () {
      return this.$formatTypeArray('consultStatus');
    },
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasHandlePermission = map.has('handle');
      this.hasClosePermission = map.get('close');
    } else {
      this.hasHandlePermission = false;
      this.hasClosePermission = false;
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    handleRow(index, object) {
      // 处理咨询
      this.curRecordID = object.id;
      this.isHandleVisible = true;
    },
    closeRow(index, object) {
      // 关闭咨询
      this.$confirm('确认关闭当前客户咨询记录，不再跟踪处理？', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        // closeOnPressEscape: false,
        center: true
      }).then(() => {
        this.closeRecord(object.id);
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.currentSearch.date);
      getConsultListReq({
        custCompany: this.currentSearch.companyName,
        custName: this.currentSearch.customerName,
        custPhone: this.currentSearch.customerPhone,
        userName: this.currentSearch.handlerNo,
        status: this.currentSearch.status,
        beginTime: beginTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.consults = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    closeRecord(id) {
      closeConsultReq({
        id: id
      }).then(response => {
        this.$message.success('已关闭...');
        this.getList();
      });
    }
  }
};