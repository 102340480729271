import CityCascader from '@/base/ui/cascader/cityCascader';
import { updateBase, showAgentlist } from '@/api/agent';
import { showSalesmanlist } from '@/api/salesman';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { isLicenseNo, isMobile } from '@/utils/validate_rules';
import UploadImage from '../../../../base/ui/upload/uploadImage';
import UploadFile from '../../../../base/ui/upload/uploadFile';
import UploadMoreFile from '../../../../base/ui/upload/uploadMoreFile';
export default {
  name: 'saveBase',
  components: {
    UploadMoreFile,
    UploadFile,
    UploadImage,
    ItemTitle,
    CityCascader
  },
  data() {
    return {
      agentList: [],
      legalPersonIdcardpic: '',
      licensePic: '',
      selectedOptions: '',
      salesManList: [],
      city: '',
      agentType: this.$formatTypeArray('agentTypeStatutes'),
      rules: {
        address: [{
          required: true,
          message: '请输入地址',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        contactNumber: [{
          required: true,
          message: '请输入联系人手机号码',
          trigger: 'blur'
        }, {
          validator: isMobile,
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确联系人姓名',
          trigger: 'blur'
        }],
        legalPerson: [{
          required: true,
          message: '请输入法人姓名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 11,
          message: '请输入正确的法人姓名',
          trigger: 'blur'
        }],
        legalPersonIdcard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }, {
          min: 18,
          max: 18,
          message: '请输入正确的身份证号码',
          trigger: 'blur'
        }],
        agentName: [{
          required: true,
          message: '请输入服务商名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        licenseNo: [{
          required: true,
          message: '请输入统一信用代码',
          trigger: 'blur'
        }, {
          validator: isLicenseNo,
          trigger: 'blur'
        }],
        cityCode: [{
          required: true,
          message: '请选择所属地区',
          trigger: 'blur'
        }],
        agentType: [{
          required: true,
          message: '请选择服务商类型',
          trigger: 'blur'
        }],
        licensePic: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }],
        datePicker: [{
          required: true,
          message: '请选择协议有效期',
          trigger: 'blur'
        }],
        legalPersonIdcardpic: [{
          required: true,
          message: '请上传身份证',
          trigger: 'blur'
        }],
        baseFileList: [{
          required: true,
          message: '请上传协议',
          trigger: 'blur'
        }],
        salesmanNo: [{
          required: true,
          message: '请输入所属客户经理手机号',
          trigger: 'blur'
        }],
        superiorAgent: [{
          required: true,
          message: '请选择上级服务商',
          trigger: 'blur'
        }],
        agentLevel: [{
          required: true,
          message: '请选择服务商属性',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    },
    saveForm: {
      default: () => {
        return {
          datePicker: [],
          address: '',
          agentName: '',
          agentType: '',
          areaCode: '',
          cityCode: '',
          contactNumber: '',
          contacts: '',
          legalPerson: '',
          legalPersonIdcard: '',
          legalPersonIdcardpic: '',
          licenseNo: '',
          licensePic: '',
          protocolEnd: '',
          protocolStart: '',
          protocolUrl: '',
          provinceCode: '',
          salesmanNo: '',
          superiorAgent: '',
          baseFileList: []
        };
      }
    }
  },
  created() {
    const that = this;
    showSalesmanlist('').then(response => {
      that.salesManList = response.data;
    });
    this.getAngentList();
  },
  watch: {
    saveForm(value) {
      this.saveForm.datePicker = [value.protocolStart, value.protocolEnd];
      if (this.saveForm.fileList !== undefined) {
        this.saveForm.baseFileList = [];
        this.saveForm.fileList.forEach(item => {
          const file = {
            name: item.fileName,
            url: item.fileUrlShow,
            response: {
              data: {
                saveKey: item.fileUrl
              },
              code: 'BASE.000'
            }
          };
          this.saveForm.baseFileList.push(file);
        });
      }
      this.getAngentList();
    }
  },
  methods: {
    getAngentList() {
      const that = this;
      showAgentlist().then(response => {
        const list = response.data;
        for (let i = 1; i < list.length; i++) {
          const item = list[i];
          if (item.agentNo === that.saveForm.agentNo) {
            list.splice(i, 1);
          }
        }
        that.agentList = list;
      });
    },
    addSubmit() {
      this.saveForm.protocolUrl = [];
      this.saveForm.baseFileList.forEach(item => {
        this.saveForm.protocolUrl.push(item.response.data.saveKey);
      });
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      if (this.saveForm.datePicker !== null && this.saveForm.datePicker !== undefined && this.saveForm.datePicker.length) {
        this.saveForm.protocolEnd = this.saveForm.datePicker[1];
        this.saveForm.protocolStart = this.saveForm.datePicker[0];
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          updateBase(this.saveForm).then(response => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = res.data.saveKey;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = res.data.saveKey;
    },
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city;
      }
    }
  }
};