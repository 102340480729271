var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", [_c("item-title", {
    attrs: {
      name: "使用流程"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      display: "flex",
      "flex-direction": "row"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    }
  }, [_c("img", {
    staticClass: "avatar avatar-disable",
    staticStyle: {
      height: "auto"
    },
    attrs: {
      src: _vm.wxacode
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span",
    staticStyle: {
      "font-size": "1.8rem"
    }
  }, [_c("strong", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("邀请码：" + _vm._s(_vm.merchantNo))])])]), _vm._v(" "), _vm._m(0)])], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "2rem 0 2rem 0"
    }
  }, [_c("item-title", {
    attrs: {
      name: "小程序签约流程"
    }
  }), _vm._v(" "), _vm._m(1)], 1), _vm._v(" "), _vm._m(2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      flex: "2",
      "font-size": "1.2rem"
    }
  }, [_c("p", [_vm._v("第一步：企业需保存专属二维码，发放给待签约的建筑工人/班组长；")]), _vm._v(" "), _c("p", [_vm._v("第二步：建筑工人/班组长通过微信扫码进入小程序，根据流程完成签约；")]), _vm._v(" "), _c("p", [_vm._v("第三步：企业通过“签约记录”查看建筑工人/班组长签约完成情况；")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-items": "center"
    }
  }, [_c("div", {
    staticClass: "next-item"
  }, [_c("div", {
    staticClass: "next-number"
  }, [_vm._v("1")]), _vm._v(" "), _c("p", [_vm._v("微信授权登录")])]), _vm._v(" "), _c("img", {
    staticClass: "next-image",
    attrs: {
      src: "static/img/next.png"
    }
  }), _vm._v(" "), _c("img", {
    staticClass: "next-image",
    attrs: {
      src: "static/img/next.png"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "next-item"
  }, [_c("div", {
    staticClass: "next-number"
  }, [_vm._v("2")]), _vm._v(" "), _c("p", [_vm._v("实名认证")])]), _vm._v(" "), _c("img", {
    staticClass: "next-image",
    attrs: {
      src: "static/img/next.png"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "next-item"
  }, [_c("div", {
    staticClass: "next-number"
  }, [_vm._v("3")]), _vm._v(" "), _c("p", [_vm._v("选择签约项目 ")])]), _vm._v(" "), _c("img", {
    staticClass: "next-image",
    attrs: {
      src: "static/img/next.png"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "next-item"
  }, [_c("div", {
    staticClass: "next-number"
  }, [_vm._v("4")]), _vm._v(" "), _c("p", [_vm._v("查看协议在线签名 ")])]), _vm._v(" "), _c("img", {
    staticClass: "next-image",
    attrs: {
      src: "static/img/next.png"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "next-item"
  }, [_c("div", {
    staticClass: "next-number"
  }, [_vm._v("5")]), _vm._v(" "), _c("p", [_vm._v("完成签约")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("p", [_c("strong", {
    staticStyle: {
      "font-size": "1.4rem"
    }
  }, [_vm._v("小提示：")])]), _vm._v(" "), _c("p", {
    staticStyle: {
      "font-size": "1.4rem",
      color: "#666666"
    }
  }, [_vm._v("\n      建筑工人/班组长签约后再对其发放个人劳务所得/劳务分包所得，有助于企业规避业务风险，确保资金结算过程合法合规。为了您企业的安全稳定发展，请敦促建筑工人/班组长进行在线签约。")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };