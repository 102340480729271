import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import { invoiceAssociateList, downloadInvoiceAssociate } from '@/api/invoice';
import { showlist } from '@/api/taxsource';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
import { ASSOCIATE_LIST_ID, ASSOCIATE_INFO_ID } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'associateList',
  components: {
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        merchantNo: '',
        taxSource: '',
        merchantName: '',
        relateStatus: '',
        serialNo: '',
        applyTime: []
      },
      taxSources: [],
      invoices: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      hasDetailPermission: false,
      hasCancelPermission: false,
      associateBtnVisible: false,
      hasUpdatePermission: false,
      detailPath: '',
      partyType: '',
      taxName: '',
      isCheckVisible: false,
      isUpdateVisible: false,
      expressInfo: null,
      currentSearch: {},
      uploadDialogId: '',
      updateBillForm: {},
      updateBillVisible: false,
      currentBillId: '',
      type: 0,
      oldBillNo: '',
      updateBillRules: {
        billInfo: [{
          required: true,
          message: '请输入发票编号',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    },
    statusOptions: function () {
      return this.$formatTypeArray('invoiceAssociateStatus');
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    if (this.partyType === 1) {
      this.searchForm.taxSource = user.partyCode;
      this.taxName = `${user.partyName}(${user.partyCode})`;
    } else if (this.partyType === 3) {
      this.searchForm.customerNo = user.partyCode;
    }
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDetailPermission = map.has('associateInfo');
      this.detailPath = map.get('associateInfo');
      this.associateBtnVisible = map.has('associateBtn');
    } else {
      this.hasDetailPermission = false;
      this.associateBtnVisible = false;
      this.hasCancelPermission = false;
      this.hasUpdatePermission = false;
      this.detailPath = '';
    }
    if (this.partyType !== 1) {
      this.getTaxSources();
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      if (this.invoices.length <= 0) {
        this.$message.error('暂无数据导出');
        return;
      }
      const applyTime = separateDate(this.currentSearch.applyTime);
      const params = {
        serialNo: this.currentSearch.serialNo,
        merchantName: this.currentSearch.merchantName,
        merchantNo: this.currentSearch.merchantNo,
        taxNo: this.currentSearch.taxSource,
        relateStatus: this.currentSearch.relateStatus,
        beginTime: applyTime.beginTime,
        endTime: applyTime.endTime
      };
      downloadInvoiceAssociate(params).then(response => {
        downloadExcel(response.data, '开票关联列表.xls');
      });
    },
    infoRow(index, object) {
      sessionStorage.setItem(ASSOCIATE_INFO_ID, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    // 关联
    associateRow(index, object) {
      sessionStorage.setItem(ASSOCIATE_LIST_ID, object.id);
      this.$router.push({
        path: '/invoice/subList'
      });
    },
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const applyTime = separateDate(this.currentSearch.applyTime);
      invoiceAssociateList({
        merchantNo: this.currentSearch.merchantNo,
        merchantName: this.currentSearch.merchantName,
        relateStatus: this.currentSearch.relateStatus,
        taxNo: this.currentSearch.taxSource,
        beginTime: applyTime.beginTime,
        endTime: applyTime.endTime,
        serialNo: this.currentSearch.serialNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.invoices = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};