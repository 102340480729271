var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      model: _vm.infoForm,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "月份："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.infoForm.profitMonth) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商属性："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("agentLevelStatutes", _vm.infoForm.agentLevel)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商编号："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.infoForm.agentNo) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务商名称："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.infoForm.agentName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所在区域："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.infoForm.province) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票抬头："
    }
  }, [_c("el-link", {
    attrs: {
      type: "primary",
      download: "查看发票抬头",
      target: "_blank"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = true;
      }
    }
  }, [_vm._v("查看发票抬头")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "分润信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      model: _vm.infoForm,
      "label-width": "110px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "交易业绩："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.tradeAmount)) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "业绩分润："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.profitAmount)) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "业绩奖励："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.rewardAmount)) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "调账金额："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.adjustAmount)) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "应结算金额："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.settleAmount)) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "实际结算金额："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.incomeAmount)) + "\n          ")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "付款明细"
    }
  }), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "profitMonth",
      label: "日期",
      "min-width": "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "belongType",
      label: "类型",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("agentProfitDetailType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "merchantName",
      label: "商户名称",
      "min-width": "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "tradeAmount",
      label: "交易业绩",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "profitAmount",
      label: "业绩分润",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rewardAmount",
      label: "业绩奖励",
      "min-width": "120",
      formatter: _vm.$fmoneyWithSymbol
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "确认信息"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "20px 0"
    },
    attrs: {
      data: _vm.infoForm.logList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "120"
    }
  })], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      width: "40%",
      title: "发票抬头",
      visible: _vm.showDialog,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_c("el-form", {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      model: _vm.invoiceTitleInfo,
      "label-position": "left",
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票抬头："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.invoiceTitle) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "纳税人识别号："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.invoiceNo) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "银行账号："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.accountNo) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开户银行："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.bankName) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "单位电话："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.companyNumber) + "\n      ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "注册地址："
    }
  }, [_vm._v("\n        " + _vm._s(_vm.invoiceTitleInfo.companyAddress) + "\n      ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };