var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.empName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "身份证号:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.idCard) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.mobile) + "\n    ")])], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "系统信息"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "注册时间:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.registerTime) + "\n    ")]), _vm._v(" "), _c("el-form-item"), _vm._v(" "), _c("el-form-item")], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "证件信息"
    }
  }), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      gutter: 50
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardFront,
      "preview-src-list": [_vm.info.idcardFront]
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.info.idcardBack,
      "preview-src-list": [_vm.info.idcardBack]
    }
  })], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "申请范围"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "类型范围:",
      prop: "projectNo"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.bizScope) + "\n    ")])], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "申请形式"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticClass: "searchForm",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "申请形式:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.teamTypeStr) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "选择工种:"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.info.workType || "-") + "\n    ")])], 1), _vm._v(" "), +_vm.$route.query.review === 1 ? [_c("item-title", {
    attrs: {
      name: "处理意见"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "review-container"
  }, [_c("el-input", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "textarea",
      maxlength: "120"
    },
    model: {
      value: _vm.auditRemark,
      callback: function ($$v) {
        _vm.auditRemark = $$v;
      },
      expression: "auditRemark"
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.pass
    }
  }, [_vm._v("通过")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: _vm.refuse
    }
  }, [_vm._v("拒绝")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      size: "mini"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("暂不复核")])], 1)] : [_c("item-title", {
    attrs: {
      name: "操作日志"
    }
  }), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "90%"
    },
    attrs: {
      data: _vm.info.logList,
      "highlight-current-row": "",
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "userName",
      label: "操作人",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      width: "240"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "操作描述",
      "min-width": "320"
    }
  })], 1)]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };