import ViewBase from './components/view/viewBase';
import ViewIndustry from './components/view/viewIndustry';
import ViewProduct from './components/view/viewProduct';
import ViewRate from './components/view/viewRate';
import { info } from '@/api/taxsource';
import { TAX_UPDATE_ID } from '@/utils/idName';
export default {
  components: {
    ViewBase,
    ViewIndustry,
    ViewProduct,
    ViewRate
  },
  data() {
    return {
      active: 0,
      message: 'first',
      id: sessionStorage.getItem(TAX_UPDATE_ID),
      bean: {
        taxBase: {},
        industryList: [],
        rateConfig: {},
        paramsConfig: {}
      }
    };
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      info(this.id).then(response => {
        that.bean = response.data;
      });
    }
  }
};