var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "60%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "处理意见"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "auditForm",
    staticClass: "save-base-agent border-bg",
    attrs: {
      model: _vm.auditForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "审核意见"
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入任务审核意见，最多可输入80个字(含标点符号)",
      maxlength: "90",
      "show-word-limit": ""
    },
    model: {
      value: _vm.auditForm.auditRemark,
      callback: function ($$v) {
        _vm.$set(_vm.auditForm, "auditRemark", $$v);
      },
      expression: "auditForm.auditRemark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.check(2);
      }
    }
  }, [_vm._v("同意")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.check(3);
      }
    }
  }, [_vm._v("拒绝")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };