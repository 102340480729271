import CityCascader from "@/base/ui/cascader/cityCascader";
import { updateBase } from "@/api/merchant";
import { showSalesmanlist } from "@/api/salesman";
// import { showAgentlist } from '@/api/agent'
import UploadImage from "../../../../base/ui/upload/uploadImage";
import { isMobile } from "@/utils/validate_rules";
import { getAcountInfo } from "../../../../utils/auth";
import ItemTitle from "../../../../base/ui/title/itemTitle";
import { gjhelperSupportReq } from "../../../../api/merchant";
export default {
  name: "merchantUpdateBase",
  components: {
    ItemTitle,
    UploadImage,
    CityCascader
  },
  data() {
    return {
      isGjhelperSupport: false,
      legalPersonIdcardpic: "",
      licensePic: "",
      protocolUrl: "",
      salesManList: [],
      agentList: [],
      partyType: "",
      partyCode: "",
      merchantInvoiceType: this.$formatTypeArray("merchantInvoiceType"),
      taxpayerTypeStatus: this.$formatTypeArray("taxpayerTypeStatus"),
      rules: {
        address: [
        // { required: true, message: '请输入地址', trigger: 'blur' },
        {
          max: 30,
          message: "小于30个字符",
          trigger: "blur"
        }],
        // repayNotifyUrl: [
        //   // { required: true, message: '请输入地址', trigger: 'blur' },
        //   { max: 30, message: '小于30个字符', trigger: 'blur' }
        // ],
        taxpayerType: [{
          required: true,
          message: "请选择纳税人性质",
          trigger: "blur"
        }],
        contactMobile: [{
          required: true,
          message: "请输入联系人手机号码",
          trigger: "blur"
        }, {
          validator: isMobile,
          trigger: "blur"
        }],
        contacts: [{
          required: true,
          message: "请输入联系人姓名",
          trigger: "blur"
        }, {
          min: 1,
          max: 11,
          message: "请输入正确联系人姓名",
          trigger: "blur"
        }],
        legalPerson: [{
          required: true,
          message: "请输入法人姓名",
          trigger: "blur"
        }, {
          min: 1,
          max: 11,
          message: "请输入正确的法人姓名",
          trigger: "blur"
        }],
        legalPersonIdcard: [{
          required: true,
          message: "请输入身份证号码",
          trigger: "blur"
        }, {
          min: 18,
          max: 18,
          message: "请输入正确的身份证号码",
          trigger: "blur"
        }],
        merchantName: [{
          required: true,
          message: "请输入甲方单位名称",
          trigger: "blur"
        }, {
          min: 1,
          max: 30,
          message: "小于30个字符",
          trigger: "blur"
        }],
        licenseNo: [{
          required: true,
          message: "请输入统一信用代码",
          trigger: "blur"
        }, {
          min: 10,
          max: 20,
          message: "大于10字符，小于20个字符",
          trigger: "blur"
        }],
        areaCode: [{
          required: true,
          message: "请选择所属地区",
          trigger: "blur"
        }],
        invoiceType: [{
          required: true,
          message: "请选择开票类型",
          trigger: "blur"
        }],
        // licensePic: [
        //   { required: true, message: '请上传营业执照', trigger: 'blur' }
        // ],
        // legalPersonIdcardpic: [
        //   { required: true, message: '请上传身份证', trigger: 'blur' }
        // ],
        protocolUrl: [{
          required: true,
          message: "请上传协议模版",
          trigger: "blur"
        }],
        salesmanNo: [{
          required: true,
          message: "请选择所属客户经理",
          trigger: "blur"
        }],
        agentNo: [{
          required: true,
          message: "请选择所属服务商",
          trigger: "blur"
        }],
        reviewMobile: [
        // { required: true, message: '请输入复核手机号码', trigger: 'blur' },
        {
          validator: isMobile,
          trigger: "blur"
        }],
        rewardFlag: [{
          required: true,
          message: "请选择交易额是否参与业绩奖励",
          trigger: "blur"
        }],
        taskLibSwitch: [{
          required: true,
          message: "请选择是否开启任务开关",
          trigger: "blur"
        }],
        jobSwitch: [{
          required: true,
          message: "请选择是否开启任务",
          trigger: "blur"
        }],
        taskReportSwitch: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }]
      }
    };
  },
  props: {
    gotoNext: {
      default: null
    },
    saveForm: {
      address: "",
      agentNo: "",
      areaCode: "",
      cityCode: "",
      contactMobile: "",
      contacts: "",
      legalPerson: "",
      legalPersonIdcard: "",
      legalPersonIdcardpic: "",
      licenseNo: "",
      licensePic: "",
      merchantName: "",
      provinceCode: "",
      // repayNotifyUrl: '',
      reviewMobile: "",
      salesmanNo: "",
      merchantNo: "",
      taxpayerType: "",
      taskLibSwitch: "",
      jobSwitch: "",
      taskReportSwitch: ""
    },
    gjAgentInfo: {
      oemName: "",
      gjUserPhone: "",
      gjUserName: "",
      gjAgentName: "",
      employeeUserName: ""
    },
    gjHelperSupport: ""
  },
  created() {
    // this.getAngentList()

    const that = this;
    showSalesmanlist().then(response => {
      that.salesManList = response.data;
    });
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.partyCode = user.partyCode;

    // this.gjhelperSupport()
  },
  watch: {
    saveForm(val) {
      showSalesmanlist().then(response => {
        this.salesManList = response.data;
      });
    }
  },
  methods: {
    /*
      getAngentList() {
        const that = this
        showAgentlist().then(response => {
          that.agentList = response.data
        })
      },
      */
    getSalesmanList() {
      const that = this;
      if (this.saveForm.agentNo === "" || this.saveForm.agentNo === undefined) {
        this.$message({
          message: "请选择服务商",
          type: "error"
        });
        return;
      }
      showSalesmanlist(this.saveForm.agentNo).then(response => {
        that.salesManList = response.data;
      });
    },
    addSubmit() {
      if (this.$refs.proviceCity.selectedOptions.length) {
        this.saveForm.provinceCode = this.$refs.proviceCity.selectedOptions[0];
        this.saveForm.cityCode = this.$refs.proviceCity.selectedOptions[1];
      }
      this.$refs["saveForm"].validate(valid => {
        if (valid) {
          updateBase(this.saveForm).then(response => {
            this.$message({
              message: "修改成功",
              type: "success"
            });
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccessBylicense(res, file) {
      this.saveForm.licensePic = res.data.saveKey;
    },
    handleAvatarSuccessByICCard(res, file) {
      this.saveForm.legalPersonIdcardpic = res.data.saveKey;
    },
    formatCity() {
      if (this.saveForm.city === "" || this.saveForm.province === undefined) {
        return "";
      } else {
        return this.saveForm.province + "/" + this.saveForm.city + "/" + this.saveForm.area;
      }
    }
    /*,
      gjhelperSupport() {
        // 查看系统是否支持国金助手服务商体系
        gjhelperSupportReq().then(res => {
          this.isGjhelperSupport = (res.data === 1) // 1-支持，其他-不支持
        })
      }
      */
  }
};