var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "35%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("item-title", {
    attrs: {
      name: _vm.titleName
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "sys-month-import-content"
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      limit: 1,
      action: _vm.baseUrl + _vm.saveFile,
      "on-preview": _vm.handlePreview,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.onSuccess,
      "before-upload": _vm.beforeAvatarUpload,
      data: _vm.uploadData,
      "auto-upload": false
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "mini",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("选取文件")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: _vm.submitUpload
    }
  }, [_vm._v("上传到服务器")])], 1), _vm._v(" "), _c("el-link", {
    staticClass: "download_label",
    attrs: {
      type: "primary",
      href: _vm.templateUrl,
      target: "_blank",
      download: "模版"
    }
  }, [_vm._v(_vm._s(_vm.templateName))])], 1), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip title-span"
  }, [_vm._v("* 只能上传已填写好的标准模板文件，数据记录最多不能超过500条，且文件大小不超过10M")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };