import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { empList } from '@/api/merchantEmp';
import { getAcountInfo } from '@/utils/auth';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import AddDialog from './components/addDialog';
import UploadJpgFileDialog from '@/base/ui/upload/uploadJpgFileDialog';
import InviteEmployeeDialog from '@/base/ui/dialog/inviteEmployeeDialog';
import { downloadAllFile } from '@/utils/util';
import AuditEmployeeDialog from '@/base/ui/dialog/auditEmployeeDialog';

// import ImportEmpCollapse from './components/importEmpCollapse'
import { EMPLOYEE_NO } from '@/utils/idName';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { downPersonPic } from '../../api/operateEmp';
import ViewIdCardDialog from '../operateEmp/components/viewIDCardDialog';
import { EMPLOYEE_INFO_ID } from '../../utils/idName';
export default {
  name: 'merchantEmpList',
  /* components: { ItemTitle, Pagination, AddDialog, UppDialog, ViewSignInfoDialog, QrcodCollapse, ImportEmpCollapse },*/
  components: {
    ViewIdCardDialog,
    AuditEmployeeDialog,
    InviteEmployeeDialog,
    UploadJpgFileDialog,
    ItemTitle,
    Pagination,
    AddDialog,
    DbDatePicker
  },
  data() {
    return {
      viewIdCardDialogVisible: false,
      total: 0,
      // updateEmp: '',
      addEmp: '',
      // activateEmp: '',
      list: [],
      datePicker: [],
      updateDatePicker: [],
      listLoading: true,
      empStatus: this.$formatTypeArray('empStatus'),
      sexStatus: this.$formatTypeArray('sexType'),
      authTypes: this.$formatTypeArray('authType'),
      updateStatus: {
        id: '',
        status: ''
      },
      searchForm: {
        keyWords: '',
        authType: '',
        status: '',
        beginTime: '',
        endTime: '',
        halfBodyStatus: ''
      },
      pageNo: 1,
      pageSize: 10,
      dialogFormVisible: false,
      updateDialogFormVisible: false,
      viewDialogFormVisible: false,
      chooseItem: {},
      signInfoData: null,
      merchantNo: '',
      currentSearch: {},
      importMerchantEmpVisible: false,
      uploadFilePath: '',
      invitePermission: '',
      downPersonPicPermission: '',
      viewMerchantEmpSignListInfo: '',
      viewPersonPicPermission: '',
      photoItem: {},
      // uploadPhotoDialog: false,
      inviteItem: {},
      auditItem: {},
      inviteDialog: false,
      auditDialogVisible: false,
      idcardBack: '',
      idcardFront: '',
      halfBody: ''
    };
  },
  watch: {
    empNo(value) {
      this.empNo = value;
    }
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.addEmp = map.get('addMerchantEmp');
      this.viewMerchantEmpSignListInfo = map.get('merchantEmpSignListInfo');
      this.importMerchantEmpVisible = map.get('importMerchantEmp');
      this.uploadFilePath = map.get('uploadFile');
      this.invitePermission = map.has('invite');
      this.downPersonPicPermission = map.has('downPersonPic');
      this.viewPersonPicPermission = map.has('viewPersonPic');
    }
    this.merchantNo = getAcountInfo().partyCode;
    // this.getList()
  },
  methods: {
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      if (Array.isArray(this.datePicker) && this.datePicker.length === 2) {
        this.currentSearch.beginTime = this.datePicker[0];
        this.currentSearch.endTime = this.datePicker[1];
      }
      if (Array.isArray(this.updateDatePicker) && this.updateDatePicker.length === 2) {
        this.currentSearch.updateBeginTime = this.updateDatePicker[0];
        this.currentSearch.updateEndTime = this.updateDatePicker[1];
      }
      empList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.searchForm = {
        keyWords: '',
        authType: '',
        status: '',
        beginTime: '',
        endTime: '',
        updateBeginTime: '',
        updateEndTime: ''
      };
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.updateDatePicker = [];
      this.queryForm();
    },
    handleAdd() {
      this.dialogFormVisible = true;
    },
    viewSignInfo(index, row) {
      sessionStorage.setItem(EMPLOYEE_NO, row.empNo);
      this.empNo = row.empNo;
      this.$router.push({
        path: this.viewMerchantEmpSignListInfo
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatEmpStatus(row, column) {
      return this.$formatType('empStatus', row.status);
    },
    formatAuthTypes(row, column) {
      return this.$formatType('authType', row.authType);
    },
    formatSex(row, column) {
      return this.$formatType('sexType', row.sex);
    },
    changeVisibleAdd(val) {
      // 监听新增dialog的dialogFormVisible的变化
      this.dialogFormVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    changeVisibleUpdate(val) {
      // 监听修改dialog的updateDialogVisible的变化
      this.updateDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    // changeVisibleUploadPhotoDialog(val) {
    //   this.uploadPhotoDialog = val
    //   if (val === false) {
    //     this.getList()
    //   }
    // },
    gotoUploadFile(row) {
      sessionStorage.setItem(EMPLOYEE_INFO_ID, row.id);
      this.$router.push({
        path: this.uploadFilePath
      });
    },
    changeVisibleInviteDialog(val) {
      this.inviteDialog = val;
      if (val === false) {
        this.getList();
      }
    },
    showInviteDialog(row) {
      this.inviteItem = row;
      this.inviteDialog = true;
    },
    downloadPersonPicFile(row) {
      downPersonPic(row.id).then(response => {
        downloadAllFile(response.data, response.headers.filename);
      });
    },
    changeVisibleAuditDialog(val) {
      this.auditDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    showAuditDialog(row) {
      this.auditItem = row;
      this.auditDialogVisible = true;
    },
    changeVisibleViewIdDialog(val) {
      this.viewIdCardDialogVisible = val;
    },
    viewPersonPicFile(row) {
      this.idcardBack = row.idcardBack;
      this.idcardFront = row.idcardFront;
      this.halfBody = row.halfBodyUrl;
      this.viewIdCardDialogVisible = true;
    }
  }
};