var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      center: "",
      width: "400px",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: _vm.title
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      "label-position": "right",
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收件人姓名",
      prop: "custName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入收件人姓名",
      clearable: ""
    },
    model: {
      value: _vm.addForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "custName", $$v);
      },
      expression: "addForm.custName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "mobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入收件人手机号码",
      clearable: ""
    },
    model: {
      value: _vm.addForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "mobile", $$v);
      },
      expression: "addForm.mobile"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "收件地址",
      prop: "address"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入收件人详细地址",
      clearable: ""
    },
    model: {
      value: _vm.addForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "address", $$v);
      },
      expression: "addForm.address"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "电子邮箱",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入电子邮箱",
      clearable: ""
    },
    model: {
      value: _vm.addForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "email", $$v);
      },
      expression: "addForm.email"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "isDefault"
    }
  }, [_c("el-checkbox", {
    staticStyle: {
      color: "#E3530A"
    },
    model: {
      value: _vm.addForm.isDefault,
      callback: function ($$v) {
        _vm.$set(_vm.addForm, "isDefault", $$v);
      },
      expression: "addForm.isDefault"
    }
  }, [_vm._v("设为默认地址")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("提交")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelForm
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };