import UploadFile from '../../../base/ui/upload/uploadFile';
import ItemTitle from '../../../base/ui/title/itemTitle';
import UploadMoreFile from '../../../base/ui/upload/uploadMoreFile';
import { beforUploadByExcel } from '../../../utils/util';
const BASE_URL = process.env.BASE_API;
export default {
  name: 'uploadInvoiceDialog',
  components: {
    UploadMoreFile,
    ItemTitle,
    UploadFile
  },
  data() {
    return {
      myDialogFormVisible: false,
      saveForm: {
        fileList: [],
        filePath: '',
        id: []
      },
      uploadApplyFile: BASE_URL + '/invoice/invoiceContent/improtAssociate',
      templateUrl: 'https://gyoss.inabei.cn/product/template/%E5%8F%91%E7%A5%A8%E5%86%85%E5%AE%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    };
  },
  props: {
    dialogFormVisible: false
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeDialogVisible', val);
    }
  },
  methods: {
    handleUploadSuccess(res, file) {
      this.saveForm.filePath = res.data.saveKey;
    },
    dialogClosed() {
      this.saveForm.fileList = [];
    },
    doSubmit() {
      this.$refs.upload.submit();
    },
    beforeUpload(file) {
      return beforUploadByExcel(this, file);
    },
    handleRemove(file, fileList) {},
    onSuccess(res, file) {
      if (res.code === 'BASE.000') {
        this.$emit('onSuccess', res, file);
        this.$refs.upload.clearFiles();
        this.$message.success('提交成功');
        this.myDialogFormVisible = false;
      } else {
        this.$message.error(res.errMsg);
      }
      this.$refs.upload.clearFiles();
    }
  }
};