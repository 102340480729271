import { mapGetters } from "vuex";
import table from "@/utils/table";
import { timePickerOptions, separateDate, downloadExcel } from "@/utils/util";
import Pagination from "@/components/pagination";
import { invoiceList, downloadInvoice, cancel } from "@/api/teamInvoice";
import { INVOICE_OPERATION_PERMISSION } from "@/utils/idName";
import dialogReviewInvoice from "./components/dialogReviewInvoice";
import dialogConfirmInvoice from "./components/dialogConfirmInvoice";
import ItemTitle from "@/base/ui/title/itemTitle";
import DbDatePicker from "@/base/ui/date/db-date-picker";
import ElLink from "element-ui/packages/link/src/main";
import UploadMoreFile from "@/base/ui/upload/uploadMoreFile";
import { showlist } from '@/api/taxsource';
export default {
  name: "teamInvoiceList",
  components: {
    ElLink,
    ItemTitle,
    Pagination,
    dialogReviewInvoice,
    dialogConfirmInvoice,
    DbDatePicker,
    UploadMoreFile
  },
  data() {
    return {
      searchForm: {
        "applyTimeBegin": "",
        "applyTimeEnd": "",
        "auditTimeBegin": "",
        "auditTimeEnd": "",
        "completeTimeBegin": "",
        "completeTimeEnd": "",
        "invoiceDateBegin": "",
        "invoiceDateEnd": "",
        "invoiceMerchantName": "",
        "invoiceStatus": '',
        "merchantNo": "",
        "monitorName": "",
        "order": "",
        "projectName": "",
        "projectNo": "",
        "serialNo": "",
        "sort": "",
        "taxNo": "",
        "teamName": "",
        "teamNo": "",
        applyDate: [],
        invoiceDate: [],
        completeTime: []
      },
      taxSources: [],
      invoices: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      totalAmt: 0,
      listLoading: false,
      detailPath: '',
      auditInvoicePath: "",
      hasCancelPermission: false,
      hasConfirmInvoice: false,
      isCheckVisible: false,
      isReviewVisible: false,
      isConfirmVisible: false,
      expressInfo: null,
      currentSearch: {},
      uploadLetterDialogVisible: false,
      updateBillNoVisible: false,
      uploadDialogId: "",
      updateBillForm: {
        billUrlList: []
      },
      billType: '',
      updateBillVisible: false,
      hasUpdateBillPermission: false,
      currentBillId: "",
      type: 0,
      oldBillNo: "",
      updateBillRules: {
        billInfo: [{
          required: true,
          message: "请输入新的发票编号",
          trigger: "blur"
        }]
      },
      selectRow: {}
    };
  },
  computed: {
    ...mapGetters(["buttonsMap"]),
    pickerOptions() {
      return timePickerOptions;
    },
    statusOptions() {
      return this.$formatTypeArray("teamInvoiceStatus");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.getTaxSources();
    const map = this.buttonsMap.get(this.$route.path);
    console.log('map: ', map);
    if (map) {
      this.detailPath = map.get("detail");
      this.auditInvoicePath = map.get("auditInvoice");
      this.hasCancelPermission = map.has("cancel");
      this.hasConfirmInvoice = map.has("confirmInvoice");
    } else {
      this.detailPath = '';
      this.auditInvoicePath = "";
      this.hasCancelPermission = false;
      this.hasConfirmInvoice = false;
    }
    console.log('this.detailPath: ', this.detailPath);
  },
  methods: {
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    formatInvoiceType(row, column) {
      return this.$formatType("invoiceType", row.invoiceType);
    },
    formatStatus(row, column) {
      return this.$formatType("invoiceStatus", row.status);
    },
    queryForm() {
      this.pageNo = 1;
      this.getList();
    },
    clearForm() {
      this.$refs["searchForm"].resetFields();
      this.queryForm();
    },
    downloadForm() {
      if (this.invoices.length <= 0) {
        this.$message.error("暂无数据导出");
        return;
      }
      const applyDate = separateDate(this.searchForm.applyDate);
      const invoiceDate = separateDate(this.searchForm.invoiceDate);
      const completeTime = separateDate(this.searchForm.completeTime);
      const params = {
        ...this.searchForm,
        applyTimeBegin: applyDate.beginTime,
        applyTimeEnd: applyDate.endTime,
        invoiceDateBegin: invoiceDate.beginTime,
        invoiceDateEnd: invoiceDate.endTime,
        completeTimeBegin: completeTime.beginTime,
        completeTimeEnd: completeTime.endTime
      };
      downloadInvoice(params).then(response => {
        downloadExcel(response.data, "班组开票记录.xls");
      });
    },
    // 刷新
    updated() {
      this.getList();
    },
    checkRow(index, object) {
      // 查看详情
      // const permission = {
      //   id: object.id,
      //   cancel: this.hasCancelPermission,
      //   check: this.auditInvoicePath,
      //   update: this.hasUpdatePermission
      // }
      sessionStorage.setItem(INVOICE_OPERATION_PERMISSION, object.id);
      this.$router.push({
        path: this.detailPath
      });
    },
    // 取消
    cancelRow(index, object) {
      this.$confirm("是否确认取消开票申请？", "取消开票", {
        type: "warning",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        center: true,
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.cancelAPI(object.id);
      });
    },
    cancelAPI(id) {
      cancel({
        id
      }).then(res => {
        this.$message.success('取消成功！');
        this.getList();
      }).catch(() => {});
    },
    // 审核
    reviewRow(index, row) {
      this.selectRow = row;
      this.isReviewVisible = true;
    },
    // 确认出票
    confirmRow(index, row) {
      this.selectRow = row;
      this.isConfirmVisible = true;
    },
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const applyDate = separateDate(this.searchForm.applyDate);
      const invoiceDate = separateDate(this.searchForm.invoiceDate);
      const completeTime = separateDate(this.searchForm.completeTime);
      invoiceList({
        ...this.searchForm,
        applyTimeBegin: applyDate.beginTime,
        applyTimeEnd: applyDate.endTime,
        invoiceDateBegin: invoiceDate.beginTime,
        invoiceDateEnd: invoiceDate.endTime,
        completeTimeBegin: completeTime.beginTime,
        completeTimeEnd: completeTime.endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.invoices = response.data.list;
        this.total = response.data.totalCount;
        this.totalAmt = response.data.totalAmount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};