var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    staticClass: "border-bg save-merchant-sign",
    attrs: {
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票抬头："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.merchantName) + "\n         ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "税务登记账号："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.invoiceNo) + "\n         ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户银行："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.bankName) + "\n         ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开户账号："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.accountNo) + "\n         ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票金额(元)："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.$fmoneyWithSymbol(_vm.infoForm.amount)) + "\n         ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "注册电话：",
      prop: "taxNo"
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.companyNumber) + "\n         ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "申请时间："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.applyTime) + "\n         ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票状态：",
      prop: "taxNo"
    }
  }, [_vm._v("\n           " + _vm._s(_vm.$formatType("invoiceStatus", _vm.infoForm.status)) + "\n         ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "注册地址："
    }
  }, [_vm._v("\n           " + _vm._s(_vm.infoForm.companyAddress) + "\n         ")])], 1)], 1)], 1), _vm._v(" "), _c("el-form", {
    ref: "updateForm",
    staticClass: "border-bg update-invoice-item",
    attrs: {
      model: _vm.updateForm,
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "发票类型：",
      prop: "invoiceType"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.updateForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "invoiceType", $$v);
      },
      expression: "updateForm.invoiceType"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("增值税普通发票")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("增值税专用发票")])], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票内容：",
      prop: "contents"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "请选择开票内容"
    },
    on: {
      change: function ($event) {
        return _vm.contentsChange();
      }
    },
    model: {
      value: _vm.updateForm.contents,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "contents", $$v);
      },
      expression: "updateForm.contents"
    }
  }, _vm._l(_vm.contentList, function (item) {
    return _c("el-option", {
      key: item.contentCode,
      attrs: {
        label: item.contentName,
        value: item.contentName
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收件人：",
      prop: "custName"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.updateForm.custName,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "custName", $$v);
      },
      expression: "updateForm.custName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系电话：",
      prop: "mobile"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.updateForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "mobile", $$v);
      },
      expression: "updateForm.mobile"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收件地址：",
      prop: "address"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.updateForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "address", $$v);
      },
      expression: "updateForm.address"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票备注："
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.updateForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.updateForm, "remark", $$v);
      },
      expression: "updateForm.remark"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    staticStyle: {
      margin: "20px auto 0 42%"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doUpdate
    }
  }, [_vm._v("修改")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };