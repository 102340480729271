import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { KAI_PIAO_INFO } from '@/utils/idName';
import DetailColumn from '@/components/detailColumn/index';
import { taxLsswkpInfoReq } from '../../../api/taxsource';
export default {
  name: 'kpInfo',
  components: {
    DetailColumn,
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      datePicker: [],
      listLoading: true,
      empSignType: this.$formatTypeArray('empSignType'),
      searchForm: {
        beginTime: '',
        endTime: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      kpInfo: {}
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const infoJson = sessionStorage.getItem(KAI_PIAO_INFO);
      vm.kpInfo = JSON.parse(infoJson);
      vm.getList();
    });
  },
  created() {
    const infoJson = sessionStorage.getItem(KAI_PIAO_INFO);
    this.kpInfo = JSON.parse(infoJson);
  },
  methods: {
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (this.datePicker === null) {
        this.datePicker = [];
      }
      taxLsswkpInfoReq({
        idCard: this.kpInfo.idCard,
        taxNo: this.kpInfo.taxNo,
        beginTicketTime: this.datePicker[0],
        endTicketTime: this.datePicker[1],
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.datePicker = [];
      this.pageNo = 1;
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    }
  }
};