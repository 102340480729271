var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("item-title", {
    attrs: {
      name: "已添加行业税率"
    }
  }), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.industryList,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "90",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "code",
      label: "行业代码"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "行业名称"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "feeRate",
      label: "行业税率"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v("\n          " + _vm._s((scope.row.feeRate * 100).toFixed(2)) + "%\n        ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };