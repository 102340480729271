import router from './router';
import store from './store';
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import Layout from './base/ui/layout/Layout';
import { MessageBox } from 'element-ui';
const _import = require('./router/_import_' + process.env.NODE_ENV);
// import _import from './router/_import_development'
import { getAuthSession } from '@/utils/auth';
import { setTitle } from '@/utils/util'; // 设置浏览器头部标题
const whiteList = ['/forgetPwd', '/login']; // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start();
  store.dispatch('AddRoutePath', to.path);
  if (getAuthSession()) {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
      NProgress.done();
    } else {
      if (!store.getters.alreadyFetchRouter) {
        store.dispatch('GenerateRoutes', {}).then(response => {
          // 执行路由跳转方法
          const data = filterAsyncRouter(response.children); // 过滤路由
          router.addRoutes(data); // 动态添加路由
          store.dispatch('StoreRoutes', data).then(() => {
            next({
              ...to,
              replace: true
            });
          });
        });
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      NProgress.done();
    }
  }
});
function filterAsyncRouter(asyncRouterMap) {
  // 遍历后台传来的路由字符串，转换为组件对象
  const accessedRouters = asyncRouterMap.filter(route => {
    if (route.component) {
      if (route.component === 'Layout') {
        // Layout组件特殊处理
        route.component = Layout;
      } else {
        try {
          route.component = _import(route.component);
        } catch (error) {
          route.component = _import('errorPage/404');
        }
      }
    }
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
  return accessedRouters;
}
router.afterEach(to => {
  NProgress.done(); // 结束Progress
  if (to.path !== '/safe/setpwd/setpwdForm' && to.path !== '/login' && store.getters.userInfo.firstLogin === 1) {
    MessageBox.confirm('为了您的账户安全，首次登录请修改登录密码！', '提示', {
      type: 'warning',
      confirmButtonText: '修改密码',
      cancelButtonText: '退出登录'
    }).then(() => {
      router.replace('/safe/setpwd/setpwdForm');
    }).catch(() => {
      store.dispatch('LogOut').then(() => {
        router.replace('/login');
      });
    });
  }
  setTimeout(() => {
    const browserHeaderTitle = store.getters.browserHeaderTitle;
    setTitle(browserHeaderTitle);
  }, 0);
});