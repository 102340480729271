import TaxRouterOpenList from '../merchant/taxRouterOpen/list';
export default {
  name: 'merchantRouterList',
  components: {
    TaxRouterOpenList
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.child.getList();
    });
  }
};