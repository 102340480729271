import request from '@/utils/request';

/**
 * 用户管理******/
export const getUserList = params => {
  const {
    pageNo,
    pageSize,
    username,
    realname
  } = params;
  return request({
    url: '/sys/user/list',
    method: 'post',
    data: {
      pageNo,
      pageSize,
      username,
      realname
    }
  });
};
export const getUserInfo = params => {
  const id = params;
  const url = '/sys/user/info';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
/**
 * 获取用户所属角色信息
 */
export const getUserRole = () => {
  const url = '/sys/role/getUserRole';
  return request({
    url: url,
    method: 'get'
  });
};
export const deleteUser = params => {
  const id = params;
  const url = '/sys/user/delete';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const saveUser = params => {
  const {
    email,
    mobile,
    partyCode,
    password,
    realname,
    roleIdList,
    roleType,
    status,
    username
  } = params;
  return request({
    url: '/sys/user/save',
    method: 'post',
    data: {
      email,
      mobile,
      partyCode,
      password,
      realname,
      roleIdList,
      roleType,
      status,
      username
    }
  });
};
export const updateUser = params => {
  const {
    id,
    email,
    mobile,
    partyCode,
    password,
    realname,
    roleIdList,
    roleType,
    status,
    username
  } = params;
  return request({
    url: '/sys/user/update',
    method: 'post',
    data: {
      id,
      email,
      mobile,
      partyCode,
      password,
      realname,
      roleIdList,
      roleType,
      status,
      username
    }
  });
};

/**
 * 获取用户创建的角色信息*/
export const getRoleByUser = () => {
  return request({
    url: '/sys/role/getRoleByUser',
    method: 'get'
  });
};

/**
 * 获取系统有效角色*/
export const getSysRole = () => {
  return request({
    url: '/sys/role/getSysRole',
    method: 'get'
  });
};

/**
 * 菜单管理******/
export const getMenuTree = () => {
  return request({
    url: '/sys/menu/menuTreeList',
    method: 'get'
  });
};
export const getRoleTree = (roleId, disabled) => {
  return request({
    url: '/sys/menu/roleTreeList',
    method: 'post',
    data: {
      roleId,
      disabled
    }
  });
};
export const getMenuList = params => {
  const {
    parentId,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/sys/menu/list',
    method: 'post',
    data: {
      parentId,
      pageNo,
      pageSize
    }
  });
};
export const saveMenu = params => {
  const {
    name,
    parentId,
    type,
    orderNum,
    isContinue,
    icon,
    parentName,
    apiIdList,
    component,
    redirect,
    title,
    hidden,
    url
  } = params;
  return request({
    url: '/sys/menu/save',
    method: 'post',
    data: {
      name,
      parentId,
      type,
      orderNum,
      isContinue,
      icon,
      parentName,
      apiIdList,
      component,
      redirect,
      title,
      hidden,
      url
    }
  });
};
export const updateMenu = params => {
  const {
    id,
    name,
    parentId,
    type,
    orderNum,
    isContinue,
    icon,
    parentName,
    apiIdList,
    component,
    redirect,
    title,
    hidden,
    url
  } = params;
  return request({
    url: '/sys/menu/update',
    method: 'post',
    data: {
      id,
      name,
      parentId,
      type,
      orderNum,
      isContinue,
      icon,
      parentName,
      apiIdList,
      component,
      redirect,
      title,
      hidden,
      url
    }
  });
};
export const deleteMenu = params => {
  const id = params;
  const url = '/sys/menu/delete';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const getMenuInfo = params => {
  const id = params;
  const url = '/sys/menu/info';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 角色管理******/
export const getRoleList = params => {
  const {
    pageNo,
    pageSize,
    name
  } = params;
  return request({
    url: '/sys/role/list',
    method: 'post',
    data: {
      pageNo,
      pageSize,
      name
    }
  });
};
export const getRoleInfo = id => {
  const url = '/sys/role/info';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const deleteRole = id => {
  const url = '/sys/role/delete';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const saveRole = params => {
  const {
    menuIdList,
    name,
    parentId,
    remark,
    status
  } = params;
  return request({
    url: '/sys/role/save',
    method: 'post',
    data: {
      menuIdList,
      name,
      parentId,
      remark,
      status
    }
  });
};
export const updateRole = params => {
  const {
    id,
    menuIdList,
    name,
    parentId,
    remark,
    status
  } = params;
  return request({
    url: '/sys/role/update',
    method: 'post',
    data: {
      id,
      menuIdList,
      name,
      parentId,
      remark,
      status
    }
  });
};

/**
 * API管理******/
export const getApiList = params => {
  const {
    pageNo,
    pageSize,
    name,
    type,
    status
  } = params;
  return request({
    url: '/sys/api/list',
    method: 'post',
    data: {
      pageNo,
      pageSize,
      name,
      type,
      status
    }
  });
};
export const getApiInfo = id => {
  const url = '/sys/api/info';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const deleteApi = id => {
  const url = '/sys/api/delete';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const saveApi = params => {
  const {
    description,
    name,
    permission,
    status,
    type,
    url
  } = params;
  return request({
    url: '/sys/api/save',
    method: 'post',
    data: {
      description,
      name,
      permission,
      status,
      type,
      url
    }
  });
};
export const updateApi = params => {
  const {
    id,
    description,
    name,
    permission,
    status,
    type,
    url
  } = params;
  return request({
    url: '/sys/api/update',
    method: 'post',
    data: {
      id,
      description,
      name,
      permission,
      status,
      type,
      url
    }
  });
};

/**
 * 角色机构管理******/
export const getPartyRoleList = params => {
  const {
    pageNo,
    pageSize,
    partyType,
    roleType
  } = params;
  return request({
    url: '/sys/partyRole/list',
    method: 'post',
    data: {
      pageNo,
      pageSize,
      partyType,
      roleType
    }
  });
};

/**
 * export const getPartyRoleInfo = id => {
  const url = '/sys/partyRole/info/' + id
  return request({
    url: url,
    method: 'get'
  })
}*/

export const deletePartyRole = id => {
  const url = '/sys/partyRole/delete';
  return request({
    url: url,
    method: 'post',
    data: {
      id
    }
  });
};
export const savePartyRole = params => {
  const {
    partyType,
    roleId,
    roleType
  } = params;
  return request({
    url: '/sys/partyRole/save',
    method: 'post',
    data: {
      partyType,
      roleId,
      roleType
    }
  });
};
export const updatePartyRole = params => {
  const {
    id,
    partyType,
    roleId,
    roleType
  } = params;
  return request({
    url: '/sys/partyRole/update',
    method: 'post',
    data: {
      id,
      partyType,
      roleId,
      roleType
    }
  });
};
/**
 费率设置**********/
export const queryFees = () => {
  return request({
    url: '/sys/config/queryFees',
    method: 'get'
  });
};
export const updateFees = params => {
  const {
    productFeesRequestList
  } = params;
  return request({
    url: '/sys/config/updateFees',
    method: 'post',
    data: {
      productFeesRequestList
    }
  });
};
export const getNoticeContentList = params => {
  return request({
    url: '/common/noticeContent/list',
    method: 'post',
    data: params
  });
};

/**
 * 系统通知撤回
 * @param id
 */
export const sysMessageRollBack = id => {
  return request({
    url: '/common/noticeContent/rollBack',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 系统通知立即发布
 * @param id
 */
export const sysMessagePublish = id => {
  return request({
    url: '/common/noticeContent/publish',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *系统通知立即下线
 * @param id
 */
export const sysMessageOffLine = id => {
  return request({
    url: '/common/noticeContent/offLine',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *系统通知详情
 * @param id
 */
export const sysMessageInfo = id => {
  return request({
    url: '/common/noticeContent/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *系统通知-取消
 * @param id
 */
export const sysMessageCancel = id => {
  return request({
    url: '/common/noticeContent/cancel',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *系统通知-新增
 * @param id
 */
export const sysMessageAdd = params => {
  return request({
    url: '/common/noticeContent/save',
    method: 'post',
    data: params
  });
};

/**
 *消息中心列表(全部/未读)
 * @param id
 */
export const getMsgCenterList = params => {
  return request({
    url: '/common/noticeContent/msgCenterList',
    method: 'post',
    data: params
  });
};

/**
 *未读数量
 * @param id
 */
export const noReadNum = () => {
  return request({
    url: '/common/noticeContent/noReadNum',
    method: 'post'
  });
};

/**
 *标记全部阅读
 */
export const allRead = () => {
  return request({
    url: '/common/noticeContent/allRead',
    method: 'post'
  });
};

/**
 *消息中心详情
 * @param id
 */
export const getMsgCenterInfo = id => {
  return request({
    url: '/common/noticeContent/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *设置已读
 * @param id
 */
export const clickRead = id => {
  return request({
    url: '/common/noticeContent/clickRead',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *查询状态为需要弹窗但是还未弹窗的通知（站内通知数据）
 */
export const queryWindowNotice = () => {
  return request({
    url: '/common/noticeContent/queryWindowNotice',
    method: 'post'
  });
};

/**
 *查询状态为需要弹窗但是还未弹窗的通知（悬浮公告数据）
 */
export const queryWindowNoticeGongGao = () => {
  return request({
    url: '/common/noticeContent/queryWindowNoticeGongGao',
    method: 'post'
  });
};

/**
 *银行图标管理-列表
 */
export const bankListReg = params => {
  return request({
    url: '/common/bank/list',
    method: 'post',
    data: params
  });
};

/**
 *银行图标管理-新增
 */
export const addBankReg = params => {
  return request({
    url: '/common/bank/save',
    method: 'post',
    data: params
  });
};

/**
 *银行图标管理-详情
 */
export const bankInfoReg = id => {
  return request({
    url: '/common/bank/info',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 *银行图标管理-修改
 */
export const updateBankReg = params => {
  return request({
    url: '/common/bank/update',
    method: 'post',
    data: params
  });
};
/**
 *银行图标管理-删除
 */
export const deleteBankReg = id => {
  return request({
    url: '/common/bank/delete',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *Banner管理-列表
 */
export const bannerListReg = params => {
  return request({
    url: '/common/advert/list',
    method: 'post',
    data: params
  });
};

/**
 *Banner管理-新增
 */
export const addBannerReg = params => {
  return request({
    url: '/common/advert/save',
    method: 'post',
    data: params
  });
};

/**
 *Banner管理-详情
 */
export const bannerInfoReg = id => {
  return request({
    url: '/common/advert/info',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 *Banner管理-修改
 */
export const updateBannerReg = params => {
  return request({
    url: '/common/advert/update',
    method: 'post',
    data: params
  });
};
/**
 *银行图标管理-删除
 */
export const deleteBannerReg = id => {
  return request({
    url: '/common/advert/delete',
    method: 'post',
    data: {
      id
    }
  });
};
/**
 *黑名单管理-列表查询
 */
export const blackList = params => {
  return request({
    url: '/blacklist/list',
    method: 'post',
    data: params
  });
};
/**
 *黑名单管理-启用
 */
export const enable = params => {
  return request({
    url: '/blacklist/enable',
    method: 'post',
    data: params
  });
};
/**
 *黑名单管理-停用
 */
export const disable = params => {
  return request({
    url: '/blacklist/disable',
    method: 'post',
    data: params
  });
};
/**
 *黑名单管理-新增
 */
export const blackListAdd = params => {
  return request({
    url: '/blacklist/save',
    method: 'post',
    data: params
  });
};
/**
 *黑名单管理-修改
 */
export const blackListUpdate = params => {
  return request({
    url: '/blacklist/update',
    method: 'post',
    data: params
  });
};
/**
 *黑名单管理-阻断记录列表列表查询
 */
export const blockLogList = params => {
  return request({
    url: '/blackintercept/list',
    method: 'post',
    data: params
  });
};