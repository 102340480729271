import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import { applyInvoiceEntryReq } from '@/api/invoice';
import UpdateAddress from '../components/updateAddress';
import MakeInvoice from './makeInvoice';
import UpdateInfo from '../components/updateInfo';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import AddressItem from '../components/addressItem';
export default {
  name: 'invoice-queryname',
  components: {
    ItemTitle,
    Pagination,
    DetailColumn,
    AddressItem,
    UpdateAddress,
    MakeInvoice,
    UpdateInfo
  },
  data() {
    return {
      addressList: [],
      taxsourceList: [],
      invoiceConfig: null,
      editInvoiceConfig: null,
      hasUpdateInfoPermission: false,
      hasAddAddrPermission: false,
      hasUpdateAddrPermission: false,
      hasMakePermission: false,
      isAddrVisible: false,
      curEditAddress: null,
      selectedAddr: null,
      curMakeInvoice: null,
      isAddrExpanded: false,
      // projectInvoiceType: '',
      taskLibSwitch: 0,
      taxpayerType: '',
      isInfoVisible: false,
      makeInvoicePath: '',
      taskLibPath: '' // 开启任务库的开票路径
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getInvoiceInfo();
    });
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    isFiveAddr: function () {
      if (Array.isArray(this.addressList) && this.addressList.length >= 5) {
        return true;
      }
      return false;
    },
    invoiceOperation: function () {
      return this.invoiceConfig != null ? '修改' : '新增';
    },
    openType: function () {
      return this.invoiceConfig != null ? this.$formatType('invoiceOpenType', this.invoiceConfig.openType) : '';
    },
    invoiceType: function () {
      return this.invoiceConfig != null ? this.$formatType('invoiceType', this.invoiceConfig.invoiceType) : '';
    },
    expandAddress: function () {
      return this.addressList.slice(1);
    }
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasUpdateInfoPermission = map.has('updateInfo');
      this.hasAddAddrPermission = map.has('addAddress');
      this.hasUpdateAddrPermission = map.has('editAddress');
      this.makeInvoicePath = map.get('makeInvoice');
      this.taskLibPath = map.get('taskLibInvoice');
    } else {
      this.hasUpdateInfoPermission = false;
      this.hasAddAddrPermission = false;
      this.hasUpdateAddrPermission = false;
      this.makeInvoicePath = '';
      this.taskLibPath = '';
    }
  },
  methods: {
    // 已开票金额
    substract(object) {
      return this.$fmoneyWithSymbol(object.rechargeTotal - object.unticketTotal);
    },
    isAddrSelected(addr1) {
      return addr1 && this.selectedAddr && addr1.id === this.selectedAddr.id;
    },
    // 新增或者修改发票信息
    updateInvoiceInfo() {
      // 为了取消对话框之前的修改
      this.editInvoiceConfig = {
        ...this.invoiceConfig
      };
      this.isInfoVisible = true;
    },
    // 新增地址
    addAddress() {
      this.curEditAddress = null;
      this.isAddrVisible = true;
    },
    // 编辑地址
    editAddrRow(index, object) {
      this.curEditAddress = {
        ...object
      };
      this.isAddrVisible = true;
    },
    // 处理地址选择改变
    selAddrChanged(val) {
      this.selectedAddr = val;
    },
    // 刷新
    updated() {
      this.getInvoiceInfo();
    },
    expand() {
      this.isAddrExpanded = !this.isAddrExpanded;
    },
    // 立即开票
    makeInvoiceRow(object) {
      if (this.invoiceConfig == null) {
        this.$message.error('请先新增发票信息');
        return;
      }
      if (this.selectedAddr == null) {
        this.$message.error('请先选择收件地址');
        return;
      }
      this.curMakeInvoice = {
        ...object,
        addressId: this.selectedAddr.id,
        // merchantType: this.projectInvoiceType,
        merchantType: 1,
        // 全额发票
        taxpayerType: this.taxpayerType
      };

      // :invoice="curMakeInvoice" :projectInvoiceType="projectInvoiceType" :taxpayerType="taxpayerType"
      sessionStorage.setItem('makeInvoice', JSON.stringify(this.curMakeInvoice));
      this.$router.push(this.makeInvoicePath);
      // if (this.taskLibSwitch == 0) {
      //   this.$router.push(this.makeInvoicePath)
      // } else {
      //   this.$router.push(this.taskLibPath)
      // }
    },
    getInvoiceInfo() {
      applyInvoiceEntryReq().then(response => {
        this.addressList = response.data.addressList;
        this.taxsourceList = response.data.taxsourceList;
        this.invoiceConfig = response.data.invoiceConfig;
        // this.projectInvoiceType = response.data.projectInvoiceType
        this.taskLibSwitch = response.data.taskLibSwitch;
        this.taxpayerType = response.data.mchntTaxpayerType;
        if (Array.isArray(this.addressList) && this.addressList.length > 0) {
          this.selectedAddr = this.addressList[0];
        }
      });
    }
  }
};