import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import CityCascader from '@/base/ui/cascader/cityCascader';
import { downloadTaxLsswkp, taxLsswkpListReq } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import SaveLsswkpDialog from './components/saveDialog';
import { KAI_PIAO_INFO } from '@/utils/idName';
export default {
  name: 'taxLsswdjList',
  components: {
    SaveLsswkpDialog,
    ItemTitle,
    Pagination,
    DbDatePicker,
    CityCascader
  },
  data() {
    return {
      total: 0,
      list: [],
      taxSources: [],
      listLoading: true,
      searchForm: {
        custName: '',
        idcard: '',
        taxNo: ''
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      importVisible: false,
      downloadVisible: false,
      saveDialogVisible: false,
      kpInfoPath: ''
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.importVisible = map.get('import');
      this.downloadVisible = map.get('download');
      this.kpInfoPath = map.get('kpInfo');
    }
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      taxLsswkpListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    downloadData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      downloadTaxLsswkp(this.currentSearch).then(response => {
        downloadExcel(response.data, '开票登记记录.xls');
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    gotoInfo(row) {
      sessionStorage.setItem(KAI_PIAO_INFO, JSON.stringify(row));
      this.$router.push({
        path: this.kpInfoPath
      });
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    }
  }
};