var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "65%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "记录更新"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      }
    }
  }, [_c("update-record-files", {
    ref: "child",
    attrs: {
      dialogVisible: _vm.dialogVisible
    },
    on: {
      dialogClosed: _vm.dialogClosed
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };