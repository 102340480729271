import { confirmSaveReq, tradeConfirmPageList } from '@/api/transaction';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import table from '@/utils/table';
import { PAY_VERIFY_CODE } from '@/utils/idName';
import VerifyCode from '@/base/ui/verifyCode';
import { tradeConfirmDownError } from '../../../api/transaction';
import { downloadExcel } from '../../../utils/util';
export default {
  name: 'transaction-batch-query',
  components: {
    VerifyCode,
    ItemTitle,
    Pagination
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    transactionData: {
      type: Object
    }
  },
  data() {
    return {
      listLoading: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      repaymentList: [],
      payAuthVerifySms: 0,
      confirmConfig: {
        visiable: false,
        showClose: true,
        content: "是否确认提交？",
        submitTitle: '确定',
        cancelTitle: '取消',
        submitHandle: () => {},
        cancelHandle: () => {}
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    total: function () {
      return this.transactionData != null && this.transactionData.firstPageList != null ? this.transactionData.firstPageList.totalCount : 0;
    },
    totalCnt: function () {
      return this.transactionData != null ? this.transactionData.totalCnt : 0;
    },
    totalAmount: function () {
      return this.transactionData != null ? this.$fmoneyWithSymbol(this.transactionData.totalAmount) : '';
    },
    successCnt: function () {
      return this.transactionData != null ? this.transactionData.successCnt : 0;
    },
    successAmount: function () {
      return this.transactionData != null ? this.$fmoneyWithSymbol(this.transactionData.successAmount) : '';
    },
    failureAmount: function () {
      return this.transactionData != null ? this.$fmoneyWithSymbol(this.transactionData.failureAmount) : '';
    },
    failureCnt: function () {
      return this.transactionData != null ? this.transactionData.failureCnt : 0;
    }
  },
  watch: {
    transactionData: function (newValue, oldValue) {
      this.repaymentList = newValue != null && newValue.firstPageList != null ? newValue.firstPageList.list : [];
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    submitForm() {
      this.confirmConfig.content = '是否确认提交？';
      this.confirmConfig.submitTitle = '确定';
      this.confirmConfig.cancelTitle = '取消';
      this.confirmConfig.submitHandle = () => {
        this.repaymentSave();
      };
      this.confirmConfig.cancelHandle = () => {
        this.confirmConfig.visiable = false;
      };
      this.confirmConfig.visiable = true;
    },
    cancelForm() {
      this.dialogVisible = false;
      this.$emit('on-cancel');
    },
    repaymentSave() {
      this.isLoading = true;
      this.verifyDialogVisible = false;
      confirmSaveReq({
        cacheKey: this.transactionData.cacheKey
      }).then(res => {
        this.isLoading = false;
        this.saveInfoModal(res.data);
      }).catch(() => {
        this.isLoading = false;
      });
    },
    saveInfoModal(res) {
      if (res.errorCnt > 0) {
        this.confirmConfig.content = `本次处理成功${res.successCnt}笔，处理失败${res.errorCnt}笔，请下载查看失败记录。`;
        this.confirmConfig.submitTitle = '下载';
        this.confirmConfig.showClose = false;
        this.confirmConfig.submitHandle = () => {
          this.repaymentDownUnAuth(res.errorCacheKey);
        };
        this.confirmConfig.cancelTitle = '';
      } else {
        this.confirmConfig.content = `本次处理成功${res.successCnt}笔，处理失败0笔。`;
        this.confirmConfig.submitTitle = '关闭';
        this.confirmConfig.showClose = false;
        this.confirmConfig.submitHandle = () => {
          this.$emit('on-success');
          this.confirmConfig.visiable = false;
          this.dialogVisible = false;
        };
        this.confirmConfig.cancelTitle = '';
      }
    },
    pageChanged() {
      this.listLoading = true;
      tradeConfirmPageList({
        cacheKey: this.transactionData.cacheKey,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listLoading = false;
        this.repaymentList = response.data.list;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    repaymentDownUnAuth(cacheKey) {
      tradeConfirmDownError({
        cacheKey
      }).then(response => {
        downloadExcel(response.data, '批量出款确认失败数据.xls');
        this.$emit('on-success');
        this.confirmConfig.visiable = false;
        this.dialogVisible = false;
      });
    }
  }
};