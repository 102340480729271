import { queryAgentTaxs } from '@/api/agent';
import table from '@/utils/table';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import UpdateAgentTaxDialog from '../updateAgentTaxDialog';
import ViewAgentTaxDialog from '../viewAgentTaxDialog';
export default {
  name: 'ViewAgentTax',
  components: {
    ViewAgentTaxDialog,
    UpdateAgentTaxDialog,
    ItemTitle
  },
  data() {
    return {
      queryParam: {
        agentNo: ''
      },
      list: [],
      updateAgentTaxDialogVisible: false,
      viewAgentTaxDialogVisible: false,
      dialogItem: {},
      viewDialogItem: {}
    };
  },
  created() {
    this.getList();
  },
  watch: {
    agentNo(vla) {
      this.agentNo = vla;
      this.getList();
    }
  },
  props: {
    gotoNext: {
      default: null
    },
    agentNo: ''
  },
  methods: {
    getList() {
      const that = this;
      if (!that.agentNo) {
        return;
      }
      that.queryParam.agentNo = that.agentNo;
      queryAgentTaxs(this.queryParam).then(response => {
        that.list = response.data;
      });
    },
    handleView(row) {
      this.viewDialogItem = row;
      this.viewAgentTaxDialogVisible = true;
    },
    handleUpdate(raw) {
      this.dialogItem = {
        agentNo: this.agentNo,
        taxNo: raw.taxNo,
        taxName: raw.taxName
      };
      this.updateAgentTaxDialogVisible = true;
    },
    indexMethod(index) {
      return table.indexMethod(index, 1, 200);
    },
    changeVisible(val) {
      // 监听新增dialog的dialogFormVisible的变化
      console.log('=========changeVisible2====');
      this.updateAgentTaxDialogVisible = val;
      if (val === false) {
        this.getList();
      }
    },
    changeViewVisible(val) {
      this.viewAgentTaxDialogVisible = val;
    },
    addSubmit() {
      if (this.gotoNext) {
        this.gotoNext();
      }
    }
  }
};