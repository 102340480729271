import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { showlist } from '@/api/taxsource';
import { downloadExcel } from '@/utils/util';
import { getCurrentYear, separateDate } from '../../../../utils/util';
import { downloadTaxSummary, getTaxSummaryList } from '../../../../api/stat';
import ParkTransCharts from './chart';
export default {
  name: 'parkTransList',
  components: {
    ParkTransCharts,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      hasDownloadPermission: false,
      list: [],
      listLoading: true,
      searchForm: {
        statType: 1,
        taxNo: '',
        beginTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        statType: 1,
        pageNo: 1,
        pageSize: 10
      },
      taxSources: [],
      summary: [],
      dateTime: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.hasDownloadPermission = map.get('download');
    }
  },
  mounted() {
    this.dateTime = getCurrentYear();
    this.getList();
    this.getTaxSources();
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      if (this.dateTime === null || this.dateTime.length === 0) {
        this.$message.error('请选择入账时间');
        return;
      }
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.dateTime);
      this.currentSearch.beginTime = beginTime;
      this.currentSearch.endTime = endTime;
      getTaxSummaryList(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.summary = response.data.summary.split('#');
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.dateTime = getCurrentYear();
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      downloadTaxSummary(this.currentSearch).then(response => {
        downloadExcel(response.data, '劳务公司交易额统计表.xls');
      });
    }
  }
};