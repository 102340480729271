var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "完税证明登记"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.exData,
      "label-width": "100px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司：",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.exData.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.exData, "taxNo", $$v);
      },
      expression: "exData.taxNo"
    }
  }, _vm._l(_vm.taxList, function (tax) {
    return tax.taxNo !== "SYSTEM" ? _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    }) : _vm._e();
  }), 1)], 1), _vm._v(" "), _vm.partyType === 0 || _vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "签约企业：",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择签约企业"
    },
    model: {
      value: _vm.exData.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.exData, "merchantNo", $$v);
      },
      expression: "exData.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (merchant) {
    return _c("el-option", {
      key: merchant.merchantNo,
      attrs: {
        label: _vm.concatMerchant(merchant),
        value: merchant.merchantNo
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "完税月份：",
      prop: "djMonth"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "请选择截止月份",
      format: "yyyy-MM",
      "value-format": "yyyy-MM"
    },
    model: {
      value: _vm.exData.djMonth,
      callback: function ($$v) {
        _vm.$set(_vm.exData, "djMonth", $$v);
      },
      expression: "exData.djMonth"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.downloadListData();
      }
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: function ($event) {
        _vm.myDialogFormVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };