var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "address-item-container",
    on: {
      click: _vm.click
    }
  }, [_vm.selected ? _c("div", {
    staticClass: "selected-addr"
  }, [_c("i", {
    staticClass: "el-icon-check"
  })]) : _c("div", {
    staticClass: "unselected-addr"
  }), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isDefault,
      expression: "address.isDefault"
    }],
    staticClass: "default-address"
  }, [_vm._v("默认地址")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px",
      "margin-right": "10px"
    }
  }, [_vm._v(_vm._s(_vm.address.custName))]), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "0 10px"
    }
  }, [_vm._v(_vm._s(_vm.address.mobile))]), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "0 10px"
    }
  }, [_vm._v(_vm._s(_vm.address.address))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };