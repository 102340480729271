var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item-title", {
    attrs: {
      name: "修改劳务公司路由顺序"
    }
  })], 1), _vm._v(" "), _c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "劳务公司："
    }
  }, [_vm._v("\n      " + _vm._s(_vm.taxName) + "\n    ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "路由顺序：",
      prop: "seqNumber"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "数字格式，越小优先级越高"
    },
    model: {
      value: _vm.saveForm.seqNumber,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "seqNumber", $$v);
      },
      expression: "saveForm.seqNumber"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.updateBtn();
      }
    }
  }, [_vm._v("修改")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };