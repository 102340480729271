var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "editForm",
    staticClass: "view-api-form border-bg",
    attrs: {
      size: "mini",
      model: _vm.editForm,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "接口名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      disabled: ""
    },
    model: {
      value: _vm.editForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "name", $$v);
      },
      expression: "editForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "type",
      label: "接口类型"
    }
  }, [_c("el-radio-group", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.editForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "type", $$v);
      },
      expression: "editForm.type"
    }
  }, _vm._l(_vm.typeArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "url",
      label: "接口地址"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      disabled: ""
    },
    model: {
      value: _vm.editForm.url,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "url", $$v);
      },
      expression: "editForm.url"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "permission",
      label: "接口权限名称"
    }
  }, [_c("el-input", {
    attrs: {
      "auto-complete": "off",
      disabled: ""
    },
    model: {
      value: _vm.editForm.permission,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "permission", $$v);
      },
      expression: "editForm.permission"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "status",
      label: "状态"
    }
  }, [_c("el-radio-group", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.editForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "status", $$v);
      },
      expression: "editForm.status"
    }
  }, _vm._l(_vm.statusArr, function (item) {
    return _c("el-radio", {
      key: item.label,
      attrs: {
        label: item.label
      }
    }, [_vm._v(_vm._s(item.name) + "\n      ")]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "description",
      label: "接口描述"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      "auto-complete": "off",
      disabled: "",
      autosize: {
        minRows: 2,
        maxRows: 5
      }
    },
    model: {
      value: _vm.editForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.editForm, "description", $$v);
      },
      expression: "editForm.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "plain"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };