import UpdateBase from './components/update/updateBase';
import UpdateIndustry from './components/update/updateIndustry';
import UpdateRate from './components/update/updateRate';
import { info } from '@/api/taxsource';
import { TAX_UPDATE_ID } from '@/utils/idName';
export default {
  components: {
    UpdateBase,
    UpdateIndustry,
    UpdateRate
  },
  name: 'updateTaxsource',
  data() {
    return {
      active: 0,
      message: 'first',
      taxBase: {
        taxNo: ''
      },
      industryList: [],
      rateConfig: {},
      paramsConfig: {}
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.isFresh || from.path === '/') {
      next(vm => {
        vm.freshData();
      });
    } else {
      next();
    }
    to.meta.isFresh = false;
  },
  created() {
    this.freshData();
  },
  methods: {
    freshData() {
      const that = this;
      const id = sessionStorage.getItem(TAX_UPDATE_ID);
      info(id).then(response => {
        that.taxBase = response.data.taxBase;
        that.industryList = response.data.industryList;
        if (response.data.rateConfig !== null) {
          that.rateConfig = response.data.rateConfig;
        }
        if (response.data.paramsConfig !== null) {
          that.paramsConfig = response.data.paramsConfig;
        }
      });
    }
  }
};