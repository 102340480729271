import request from '@/utils/request';

/**
 * 获取园区通道列表*/
export const getTaxChannelList = params => {
  const {
    channelType,
    order,
    status,
    taxName,
    taxNo,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/tax/taxChannel/list',
    method: 'post',
    data: {
      channelType,
      order,
      status,
      taxName,
      taxNo,
      pageNo,
      pageSize
    }
  });
};

/**
 * 解冻园区通道*/
export const unfreeze = id => {
  return request({
    url: '/tax/taxChannel/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结园区通道*/
export const freeze = id => {
  return request({
    url: '/tax/taxChannel/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 新增服务通道商参数设置信息
 * @param params
 */
export const saveTaxChannel = params => {
  return request({
    url: '/tax/taxChannel/save',
    method: 'post',
    data: params
  });
};

/**
 *获取园区通道详细信息*/
export const taxChannelInfo = id => {
  return request({
    url: '/tax/taxChannel/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 修改服务通道商参数设置信息
 * @param params
 */
export const updateTaxChannel = params => {
  return request({
    url: '/tax/taxChannel/update',
    method: 'post',
    data: params
  });
};