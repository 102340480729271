import ItemTitle from '@/base/ui/title/itemTitle';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { mapGetters } from 'vuex';
import { timePickerOptions } from '@/utils/util';
import { showlist } from '@/api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
import { downRepayReportReg, repayReportReg } from '@/api/task';
import { merchantShowList } from '@/api/merchant';
import { deleteTaxWszmdjReg, taxWszmdjListReq } from '../../../api/taxsource';
import { getAcountInfo } from '../../../utils/auth';
import AddWszmdjDialog from './components/addDialog';
import DownloadWszmdjDialog from './components/downloadDialog';
export default {
  name: 'taxWszmdjList',
  components: {
    DownloadWszmdjDialog,
    AddWszmdjDialog,
    ItemTitle,
    Pagination,
    DbDatePicker
  },
  data() {
    return {
      total: 0,
      list: [],
      taxsList: [],
      merchantList: [],
      listLoading: true,
      searchForm: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        merchantNo: ''
      },
      currentSearch: {
        beginTime: '',
        endTime: '',
        taxNo: '',
        merchantNo: ''
      },
      pageNo: 1,
      pageSize: 10,
      saveWszm: false,
      deleteWszm: false,
      downloadWszm: false,
      downloadVisible: false,
      partyType: '',
      saveDialogVisible: false,
      downloadDialogVisible: false
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.saveWszm = map.get('saveWszm');
      this.deleteWszm = map.get('deleteWszm');
      this.downloadWszm = map.get('downloadWszm');
      this.downloadVisible = map.get('download');
    }
    this.getTaxsList();
    this.partyType = getAcountInfo().partyType;
    // if (this.partyType === 0 || this.partyType === 1) {
    //   this.getMerchantList()
    // }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      taxWszmdjListReq(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getTaxsList() {
      showlist().then(response => {
        this.taxsList = response.data;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.pageNo = 1;
      this.queryForm();
    },
    downloadData() {
      if (this.list.length === 0) {
        this.$message.warning('暂无数据');
        return;
      }
      this.listLoading = true;
      downRepayReportReg(this.currentSearch).then(response => {
        if (!response.data) {
          return;
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        const fileName = '付款完成任务汇总表.xls';
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
        console.log('error: ' + error);
        this.$message({
          message: '下载文件异常',
          type: 'error'
        });
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该完税证明?', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        deleteTaxWszmdjReg(row.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          // this.getDictDetailList(this.viewDictGroupForm.id)
          this.getList();
        });
      }).catch(() => {});
    },
    changeAddDialogVisible(val) {
      this.saveDialogVisible = val;
      this.getList();
    },
    changeDownloadDialogVisible(val) {
      this.downloadDialogVisible = val;
    },
    getMerchantList() {
      this.searchForm.merchantNo = null;
      this.merchantList = [];
      if (this.searchForm.taxNo !== '') {
        merchantShowList(this.searchForm).then(response => {
          this.merchantList = response.data;
        });
      }
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    }
  }
};