import ItemTitle from '@/base/ui/title/itemTitle';
import { isDecimal } from '@/utils/validate_rules';
export default {
  name: 'invoice-list-check',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      addForm: {
        expectAmount: ''
      },
      rules: {
        expectAmount: [{
          required: true,
          message: '请输入期望开票金额',
          trigger: 'blur'
        }, {
          validator: isDecimal,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          this.$emit('input', this.addForm.expectAmount);
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      this.$refs['addForm'].resetFields();
    }
  }
};