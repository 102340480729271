import ItemTitle from '@/base/ui/title/itemTitle';
import { showlist } from '@/api/taxsource';
import { isValidQuota } from '@/utils/validate_rules';
export default {
  name: 'updateProductByYeepay',
  components: {
    ItemTitle
  },
  data() {
    return {
      taxSourceList: [],
      taxStatusType: this.$formatTypeArray('taxStatusType'),
      rechargeAutoStatus: this.$formatTypeArray('isSupport'),
      rules: {
        appId: [{
          required: true,
          message: '请输入易宝商户编号',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '请输入正确的易宝商户编号',
          trigger: 'blur'
        }],
        appKey: [{
          required: true,
          message: '请输入易宝应用KEY',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入易宝商户RSA2私钥',
          trigger: 'blur'
        }],
        rechargeAuto: [{
          required: true,
          message: '请选择是否支持自动充值',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择通道状态',
          trigger: 'blur'
        }],
        onlinePayStatus: [{
          required: true,
          message: '请选择基准类型',
          trigger: 'blur'
        }],
        onlinePayMax: [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    saveForm: {
      accountNo: '',
      appId: '',
      appKey: '',
      bankName: '',
      channelName: '',
      channelNo: '',
      channelType: '',
      custName: '',
      ncPreposeIp: '',
      ncUserId: '',
      ncUserPwd: '',
      privateKey: '',
      publicKey: '',
      quotaMax: '',
      rechargeAuto: '',
      status: '',
      taxNo: '',
      onlinePayStatus: 0,
      onlinePayMax: 0,
      onlinePayCount: 0
      // billCode: '',
      // useofCode: '',
    }
  },
  created() {
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    checkSaveForm() {
      const {
        id,
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        quotaMax,
        appKey,
        privateKey,
        publicKey,
        ncUserId,
        ncUserPwd,
        ncPreposeIp,
        rechargeAuto,
        status,
        singleMin,
        singleMax,
        onlinePayStatus,
        onlinePayMax,
        onlinePayCount
      } = this.saveForm; // 防止与建行或者支付宝通道数据串了

      const form = {
        id,
        taxNo,
        channelType,
        channelNo,
        bankName,
        accountNo,
        custName,
        channelName,
        baseType,
        appId,
        quotaMax,
        appKey,
        privateKey,
        publicKey,
        ncUserId,
        ncUserPwd,
        ncPreposeIp,
        rechargeAuto,
        status,
        singleMin,
        singleMax,
        onlinePayStatus,
        onlinePayMax,
        onlinePayCount
      };
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.$emit('addSubmit', form, true);
        } else {
          this.$emit('addSubmit', form, false);
        }
      });
    }
  }
};