var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.myDialogFormVisible,
      center: "",
      width: "40%",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      title: "查看证件照"
    },
    on: {
      "update:visible": function ($event) {
        _vm.myDialogFormVisible = $event;
      },
      close: _vm.dialogClosed
    }
  }, [_c("div", [_c("el-row", [_c("el-col", {
    attrs: {
      span: 2
    }
  }, [_vm._v("正面：")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.idcardFrontUrl ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.idcardFrontUrl,
      "preview-src-list": [_vm.idcardFrontUrl]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 2
    }
  }, [_vm._v("反面：")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.idcardBackUrl ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.idcardBackUrl,
      "preview-src-list": [_vm.idcardBackUrl]
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 2
    }
  }, [_vm._v("半身照：")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_vm.halfBodyUrl ? _c("el-image", {
    staticClass: "avatar avatar-disable",
    attrs: {
      src: _vm.halfBodyUrl,
      "preview-src-list": [_vm.halfBodyUrl]
    }
  }) : _vm._e()], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };