import { mapGetters } from 'vuex';
import table from '@/utils/table';
import Pagination from '@/components/pagination';
import { getBatchInfoReq, getTransactionListReq, transactionReviewDownload, reviewBatchReq, repayBatchReviewCheck } from '@/api/transaction';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import { BATCH_DETAIL_ID, BATCH_CHECK_ID } from '@/utils/idName';
import VerifyCode from '@/base/ui/verifyCode';
import { PAY_VERIFY_CODE } from '@/utils/idName';
import { downloadFile } from '@/api/upload';
import { downloadExcel, downloadAllFile } from '@/utils/util';
export default {
  components: {
    VerifyCode,
    ItemTitle,
    DetailColumn,
    Pagination
  },
  name: 'batchDetail',
  data() {
    return {
      rechargeInline: '',
      infoForm: {
        logList: [],
        payAuthVerifySms: ''
      },
      reviewForm: {
        remark: ''
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      span: 12,
      gutter: 40,
      listLoading: false,
      transactions: [],
      passDialogVisible: false,
      rejectDialogVisible: false,
      dialogVisible: false,
      rejectVerifyCode: '',
      passVerifyCode: '',
      rules: {
        remark: [{
          required: true,
          message: '处理意见不能为空',
          trigger: 'blur'
        }, {
          max: 80,
          message: '最多输入80个字符',
          trigger: 'blur'
        }]
      },
      verifyCodeRules: {},
      payVerifyCode: PAY_VERIFY_CODE
    };
  },
  props: {
    repayType: {
      default: () => {
        return 0;
      }
    }
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    status: function () {
      return this.$formatType('batchStatus', this.infoForm.status);
    },
    source: function () {
      return this.$formatType('orderSource', this.infoForm.source);
    },
    isCheck: function () {
      return this.$route.path.includes('check');
    }
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    console.log(map);
    this.rechargeInline = map && map.has('rechargeInline') ? map.get('rechargeInline') : '';
    this.getDetail();
  },
  methods: {
    downloadRow(index, object) {
      const params = {
        batchNo: this.infoForm.batchNo,
        taxNo: this.infoForm.taxNo,
        showFlag: true
      };
      transactionReviewDownload(params).then(response => {
        const fileName = `repayment-record-${this.infoForm.batchNo}.xls`;
        downloadExcel(response.data, fileName);
      });
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    pass() {
      this.$refs['reviewForm'].validate(valid => {
        if (valid) {
          // 充值金额预计算
          this.startRepayBatchReviewCheck();
        } else {
          return false;
        }
      });
    },
    startRepayBatchReviewCheck() {
      const id = sessionStorage.getItem(BATCH_CHECK_ID);
      const params = {
        id,
        status: 1
      };
      repayBatchReviewCheck(params).then(res => {
        console.log(res);
        if (res.data.rechargeFlag === 1) {
          // 需要充值
          this.modalToast(res.data);
          return;
        }
        this.passDialogVisible = true;
      });
    },
    modalToast(data) {
      this.$confirm(data.rechargeMsg, '提示', {
        confirmButtonText: '去充值',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({
          // path: '/transaction/review/rechargeInline',
          path: '/account/info',
          query: {
            requiredAmount: data.requiredAmount
          }
        });
      }).catch(() => {});
      this.passDialogVisible = false;
      return;
    },
    reject() {
      this.$confirm('是否确定拒绝该付款批次？', '提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        this.$refs['reviewForm'].validate(valid => {
          if (valid) {
            // this.rejectDialogVisible = true
            this.reviewBatch(9);
            return;
          } else {
            return false;
          }
        });
      }).catch(() => {});
    },
    cancel() {
      this.goBack();
    },
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    showDiv() {
      // 当余额 ≤ 订单总金额 时，可以明确得知余额不足以付款，因此在付款明细列表中，提示“余额不足”（字体标红）。
      if (this.infoForm.availAmount < this.infoForm.totalAmount) {
        $("#fontShow").show();
      } else {
        $("#fontShow").hide();
      }
    },
    getList() {
      this.listLoading = true;
      getTransactionListReq({
        showFlag: true,
        batchNo: this.infoForm.batchNo,
        taxNo: this.infoForm.taxNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    getDetail() {
      const id = this.isCheck ? sessionStorage.getItem(BATCH_CHECK_ID) : sessionStorage.getItem(BATCH_DETAIL_ID);
      getBatchInfoReq({
        id: id
      }).then(response => {
        this.infoForm = response.data;
        console.log('this.infoForm: ', this.infoForm);
        console.log('this.infoForm: ', this.infoForm.attachName);
        console.log('this.infoForm: ', this.infoForm.attachUrl);
        this.getQuery();
        this.showDiv();
      });
    },
    reviewBatch(status) {
      const id = sessionStorage.getItem(BATCH_CHECK_ID);
      let code = '';
      if (this.infoForm.payAuthVerifySms === 1) {
        if (status === 9) {
          // code = this.$refs.rejectDialog.verifyCode
        } else {
          code = this.$refs.passDialog.verifyCode;
        }
      }
      reviewBatchReq({
        id: id,
        merchantNo: this.infoForm.merchantNo,
        projectNo: this.infoForm.projectNo,
        remark: this.reviewForm.remark,
        status: status,
        verifyCode: code
      }).then(() => {
        this.goBack(status);
      });
    },
    /*
    downloadUrl(attachUrl) {
      const that = this
      downloadFile(attachUrl).then(response => {
        downloadAllFile(response.data, response.headers.filename, that)
      })
    },
    */
    goBack(status) {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        if (status) {
          const message = status === 1 ? '已通过' : '已拒绝';
          this.$message.success(message);
        }
        this.$router.go(-1);
      });
    }
  }
};