var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "title-top"
  }, [_c("div", {
    staticClass: "addr-tip-container"
  }, [_c("item-title", {
    attrs: {
      name: "地址管理"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "addr-tip"
  }, [_vm._v("最多添加5个地址")])], 1), _vm._v(" "), _vm.hasAddPermission && !_vm.isFiveAddr ? _c("el-button", {
    attrs: {
      type: "text",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.addAddress
    }
  }, [_vm._v("新增地址")]) : _vm._e()], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      data: _vm.addressList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: 1
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "收件人",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "电话号码",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "address",
      label: "收件人地址",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "默认地址",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.isDefault ? _c("span", {
          staticClass: "default-addr"
        }, [_vm._v("默认")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "260",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!scope.row.isDefault && _vm.hasSetDefaultPermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.setAddrRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("设为默认地址")]) : _vm._e(), _vm._v(" "), _vm.hasUpdatePermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.editAddrRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.hasDeletePermission ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.deleteAddrRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("update-address", {
    attrs: {
      isVisible: _vm.isAddrVisible,
      addressObj: _vm.curEditAddress
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isAddrVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isAddrVisible = $event;
      },
      updated: _vm.updated
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };