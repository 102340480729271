import { handleConsultReq } from '@/api/consult';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'consult-handleForm',
  components: {
    ItemTitle
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    id: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      handleForm: {
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '请输入处理描述',
          trigger: 'blur'
        }, {
          max: 100,
          message: '最多输入100个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['handleForm'].validate(valid => {
        if (valid) {
          this.handleConsultRecord();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    cancelForm() {
      this.dialogVisible = false;
    },
    dialogClosed() {
      this.$refs['handleForm'].resetFields();
    },
    handleConsultRecord() {
      handleConsultReq({
        id: this.id,
        handleDesc: this.handleForm.remark
      }).then(() => {
        this.$message.success('处理成功...');
        this.$emit('handled');
      });
    }
  }
};