var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-collapse", {
    staticStyle: {
      "padding-top": "0"
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("item-title", {
    attrs: {
      name: "充值指引"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "process-desc",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", [_vm._v("1.项目方使用项目所属甲方单位的银行账户线下转账至指定的劳务公司银行账户。")]), _vm._v(" "), _c("div", [_vm._v("2.劳务公司确认账户收到款项后系统会自动计算入账金额并计入项目方账户余额。")]), _vm._v(" "), _c("div", [_vm._v("3.由于对公账户到账情况不定，请在法定工作日工作期间进行充值，以免充值入账延后影响项目付款。")])])], 2)], 1), _vm._v(" "), _c("div", [_c("item-title", {
    attrs: {
      name: "劳务公司信息"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: 1
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "劳务公司名称",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "劳务公司账户",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bankName",
      label: "开户银行",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "rechargeRate",
      label: "标准充值费率",
      "min-width": "80"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };