import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn/index';
import { mapGetters } from 'vuex';
import { invoiceAssociateInfoReg, invoiceAssociateReg } from '../../../api/invoice';
import { ASSOCIATE_LIST_ID } from '@/utils/idName';
import Pagination from '@/components/pagination/index';
import { importAssociateFile } from '@/api/upload';
import { beforUploadByExcel } from '@/utils/util';
import { Loading } from 'element-ui';
import AssociatePreview from './components/preview';
export default {
  components: {
    AssociatePreview,
    Pagination,
    DetailColumn,
    ItemTitle
  },
  data() {
    return {
      id: '',
      info: {},
      importVisible: false,
      downloadVisible: false,
      list: [],
      pageNo: 0,
      pageSize: 10,
      total: 0,
      listLoading: false,
      amountTotal: 0,
      multipleSelection: [],
      allSelection: [],
      selectMoney: 0,
      importAssociateFile: importAssociateFile,
      loadingInstance: {},
      selectList: [],
      responseData: {},
      isDialogVisible: false,
      serialNo: '',
      totalCount: '',
      totalAmount: '',
      url: 'https://gyoss.inabei.cn/product/template/%E5%8F%91%E6%94%BE%E8%AE%B0%E5%BD%95%E5%85%B3%E8%81%94%E5%BC%80%E7%A5%A8%E6%A8%A1%E6%9D%BF.xlsx'
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  created() {
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.importVisible = map.has('import');
      this.downloadVisible = map.has('download');
    }
    this.id = sessionStorage.getItem(ASSOCIATE_LIST_ID, 0);
    this.getQuery();
  },
  methods: {
    pageChanged() {
      this.getList();
    },
    getQuery() {
      this.pageNo = 1;
      this.getList();
    },
    getList() {
      invoiceAssociateInfoReg({
        id: this.id,
        isDetail: 0,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.info = response.data;
        this.list = response.data.pageList.list;
        this.total = response.data.pageList.totalCount;
      });
    },
    handleSelectionChange(val) {
      this.selectMoney = 0;
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        this.selectMoney += item.repayAmount;
      });
    },
    doSubmit() {
      if (this.selectMoney > this.info.amount) {
        this.$alert('  已到达可关联金额上限，请重新关联！', '提示', {
          type: 'error',
          confirmButtonText: '知道了',
          closeOnClickModal: false
        });
        return;
      }
      if (this.checkSelect()) {
        this.$confirm(`已选择${this.multipleSelection.length}条匹配数据，是否确认关联?关联后不可撤销！！！`, '提示', {
          closeOnClickModal: false
          // closeOnPressEscape: false,
        }).then(() => {
          const ids = [];
          this.multipleSelection.forEach(item => {
            ids.push(item.id);
          });
          invoiceAssociateReg({
            id: this.id,
            ids: ids
          }).then(() => {
            this.$router.go(-1);
            this.$message.success(`数据匹配已完成，共匹配数据${this.multipleSelection.length}条.`);
          }).catch(error => {
            this.$message.error(error.errMsg);
          });
        });
      }
    },
    checkSelect() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择需要关联的数据。');
        return false;
      }
      return true;
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    onSuccess(res, file) {
      this.loadingInstance.close();
      if (res.code === 'BASE.000') {
        this.totalAmount = res.data.totalAmount;
        this.totalCount = res.data.totalCount;
        this.serialNo = res.data.serialNo;
        this.isDialogVisible = true;
      } else {
        this.$message.error(res.errMsg);
      }
    },
    beforeDemoUpload(file) {
      this.loadingInstance = Loading.service({
        fullscreen: true
      });
      return beforUploadByExcel(this, file);
    },
    getRowKeys(row) {
      return row.id;
    },
    changeDialogVisible(val) {
      this.isDialogVisible = val;
      this.getList();
    },
    previewSuccess() {
      this.goBack();
    }
  }
};