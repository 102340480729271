import { addBankCard } from '@/api/merchant';
import { getAcountInfo } from '@/utils/auth';
import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'AddBankCardDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        acountNo: '',
        bankName: '',
        custName: '',
        belong: ''
      },
      myDialogFormVisible: false,
      rules: {
        accountNo: [{
          required: true,
          message: '请输入结算充值账户',
          trigger: 'blur'
        }],
        bankName: [{
          required: true,
          message: '请输入开户银行名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '小于30个字符',
          trigger: 'blur'
        }],
        accountType: [{
          required: true,
          message: '请选择账户类型',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dialogFormVisible: false
  },
  watch: {
    dialogFormVisible(val) {
      this.myDialogFormVisible = val;
    },
    myDialogFormVisible(val) {
      this.$emit('changeVisibleAdd', val);
    }
  },
  created() {
    const user = getAcountInfo();
    this.saveForm.custName = `${user.partyName}`;
    this.saveForm.belong = `${user.partyCode}`;
  },
  methods: {
    addBtn() {
      const that = this;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          addBankCard(this.saveForm).then(response => {
            this.myDialogFormVisible = false;
            this.date = [];
            this.$refs['saveForm'].resetFields();
            that.$message({
              message: '添加成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};