var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "saveForm",
    attrs: {
      model: _vm.saveForm,
      "label-width": "140px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("item-title", {
    attrs: {
      name: "费率设置"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "title-span"
  }, [_vm._v("(注：费率及税率均为大于等于0且小于1的数值，如0.5%即输入 0.005)")])], 1), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "技术服务费率",
      prop: "serviceRate"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.saveForm.serviceRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "serviceRate", $$v);
      },
      expression: "saveForm.serviceRate"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "平台代理费率(增值)",
      prop: "agentRate"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.saveForm.agentRate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "agentRate", $$v);
      },
      expression: "saveForm.agentRate"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("修改")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };