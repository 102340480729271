import ItemTitle from '@/base/ui/title/itemTitle';
import { saveTaskCategory } from '@/api/task';
import { merchantShowList } from '@/api/merchant';
import { getTaskCategoryInfo, updateTaskCategory } from '../../../api/task';
import { TASK_UPDATE_CATEGORY_ID } from '../../../utils/idName';
export default {
  name: 'updateCategory',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        billingCategory: '',
        categoryName: '',
        merchantNo: '',
        hasExtra: 0,
        taskCategoryExtraList: []
      },
      merchantList: [],
      dialogForm: {
        extraName: '',
        extraType: '',
        required: '',
        extraValue: ''
      },
      dialogVisible: false,
      updateIndex: -1,
      dialogType: 0,
      // 0新增，1修改
      rules: {
        merchantNo: [{
          required: true,
          message: '请选择劳务公司',
          trigger: 'blur'
        }],
        categoryName: [{
          required: true,
          message: '请输入任务分类名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '最多可输入32个字符',
          trigger: 'blur'
        }],
        billingCategory: [{
          required: true,
          message: '请输入对应开票类目',
          trigger: 'blur'
        }, {
          max: 200,
          message: '最多可输入200个字符',
          trigger: 'blur'
        }],
        hasExtra: [{
          required: true,
          message: '是否添加任务提交参数',
          trigger: 'blur'
        }]
      },
      dialogRules: {
        extraType: [{
          required: true,
          message: '请选择参数类型',
          trigger: 'blur'
        }],
        extraName: [{
          required: true,
          message: '请输入参数名',
          trigger: 'blur'
        }, {
          max: 32,
          message: '最多可输入32个字符',
          trigger: 'blur'
        }],
        required: [{
          required: true,
          message: '请选择是否必填',
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    merchantShowList({}).then(response => {
      this.merchantList = response.data;
    });
    const id = sessionStorage.getItem(TASK_UPDATE_CATEGORY_ID);
    getTaskCategoryInfo(id).then(response => {
      this.saveForm = response.data;
      this.saveForm.id = id;
    });
  },
  methods: {
    addBtn() {
      if (this.saveForm.hasExtra === 1 && this.saveForm.taskCategoryExtraList.length === 0) {
        this.$message.error('请添加自定义参数');
        return;
      } else if (this.saveForm.hasExtra === 0) {
        this.saveForm.taskCategoryExtraList = [];
      }
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          this.doSubmit();
        } else {
          return false;
        }
      });
    },
    doSubmit() {
      this.$confirm('是否确定提交?', '温馨提示', {
        type: 'warning',
        closeOnClickModal: false
        // closeOnPressEscape: false,
      }).then(() => {
        updateTaskCategory(this.saveForm).then(response => {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.goBack();
        });
      });
    },
    concatMerchant(merchant) {
      return `${merchant.merchantName}(${merchant.merchantNo})`;
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    handleEdit(index, row) {
      this.dialogForm = {
        extraName: row.extraName,
        extraType: row.extraType,
        required: row.required,
        extraValue: row.extraValue
      };
      this.updateIndex = index;
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    handleAdd() {
      if (this.saveForm.taskCategoryExtraList.length >= 6) {
        this.$alert('单个任务分类最多配置6个自定义参数！', '温馨提示', {
          type: 'warning',
          confirmButtonText: '知道了',
          center: true,
          closeOnClickModal: false
        });
        return;
      }
      this.dialogForm = {};
      this.dialogType = 0;
      this.dialogVisible = true;
    },
    handleDelete(index) {
      this.saveForm.taskCategoryExtraList.splice(index, 1);
    },
    addCategoryExtraList() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          if (this.dialogType === 0) {
            // 新增
            this.saveForm.taskCategoryExtraList.push({
              extraName: this.dialogForm.extraName,
              extraType: this.dialogForm.extraType,
              required: this.dialogForm.required,
              extraValue: this.dialogForm.extraValue
            });
          } else {
            // 修改
            this.saveForm.taskCategoryExtraList[this.updateIndex] = {
              extraName: this.dialogForm.extraName,
              extraType: this.dialogForm.extraType,
              required: this.dialogForm.required,
              extraValue: this.dialogForm.extraValue
            };
          }
          this.$refs['dialogForm'].resetFields();
          this.dialogVisible = false;
          // debugger
        }
      });
    },
    dialogCancel() {
      this.$refs['dialogForm'].resetFields();
      this.dialogVisible = false;
    }
  }
};