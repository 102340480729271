var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "130px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业编号："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appId) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "甲方单位名称："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appName) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "应用ID："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appId) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签名秘钥："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.saveForm.appKey) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 2
    }
  }, [_vm.resetAppInfoSign && _vm.isMerchant ? _c("el-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.showResetDialog();
      }
    }
  }, [_vm._v("重置")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约认证方式："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("authType", _vm.saveForm.checkMobile)) + "\n        ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否上传身份证："
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$formatType("needIdcardPicStatus", _vm.saveForm.needIdcardPic)) + "\n        ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "加密算法"
    }
  }, [_vm.isMerchant ? _c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择加密算法",
      size: "mini"
    },
    on: {
      change: function ($event) {
        return _vm.getAlgorithmTypePUBKey();
      }
    },
    model: {
      value: _vm.saveForm.algorithmType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "algorithmType", $$v);
      },
      expression: "saveForm.algorithmType"
    }
  }, _vm._l(_vm.$formatTypeArray("algorithmType"), function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1) : _c("div", [_vm._v(_vm._s(_vm.saveForm.algorithmType))])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "平台公钥：",
      prop: "appKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6,
      disabled: ""
    },
    model: {
      value: _vm.saveForm.publicKey1,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "publicKey1", $$v);
      },
      expression: "saveForm.publicKey1"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业公钥：",
      prop: "publicKey"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6,
      disabled: !_vm.isMerchant
    },
    model: {
      value: _vm.saveForm.publicKey,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "publicKey", $$v);
      },
      expression: "saveForm.publicKey"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "padding-left": "0px",
      "margin-top": "10px"
    },
    attrs: {
      span: 1
    }
  }, [_vm.resetAppInfoSign && _vm.isMerchant ? _c("el-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.generateKeys();
      }
    }
  }, [_vm._v("获取企业密钥")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "回调通知地址：",
      prop: "callBackUrl"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: !_vm.isMerchant
    },
    model: {
      value: _vm.saveForm.callBackUrl,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "callBackUrl", $$v);
      },
      expression: "saveForm.callBackUrl"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.updateAppInfo && _vm.isMerchant ? _c("el-button", {
    staticClass: "update-btn",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("保\n      存")]) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "重置签名秘钥",
      visible: _vm.resetDialog,
      width: "30%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.resetDialog = $event;
      }
    }
  }, [_c("span", [_vm._v("重置签名密钥后，原API接入签名密钥即刻失效，企业需要同步更新API接入的密钥信息，确认重置签名密钥？")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.restAppKey();
      }
    }
  }, [_vm._v("确定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.resetDialog = false;
      }
    }
  }, [_vm._v("取消")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "企业密钥信息",
      visible: _vm.generateDialog,
      width: "65%",
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.generateDialog = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "medium",
      "line-height": "25px"
    }
  }, [_vm._v("    系统不保管企业私钥信息，请双击密钥选择复制并妥善保管企业私钥。点击“确定”后会自动填充企业公钥，然后点击“保存”更新密钥，否则不会更新。")]), _vm._v(" "), _c("el-form", {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      size: "mini",
      model: _vm.generateForm,
      "label-width": "120px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 22
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业私钥："
    }
  }, [_vm.generateForm.algorithmType == "SM2" ? _c("el-input", {
    staticStyle: {
      "font-size": "small"
    },
    attrs: {
      type: "textarea",
      rows: 2
    },
    model: {
      value: _vm.generateForm.genPrivateKey,
      callback: function ($$v) {
        _vm.$set(_vm.generateForm, "genPrivateKey", $$v);
      },
      expression: "generateForm.genPrivateKey"
    }
  }) : _vm._e(), _vm._v(" "), _vm.generateForm.algorithmType == "RSA2" ? _c("el-input", {
    staticStyle: {
      "font-size": "small"
    },
    attrs: {
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.generateForm.genPrivateKey,
      callback: function ($$v) {
        _vm.$set(_vm.generateForm, "genPrivateKey", $$v);
      },
      expression: "generateForm.genPrivateKey"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 22
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业公钥："
    }
  }, [_vm.generateForm.algorithmType == "SM2" ? _c("el-input", {
    staticStyle: {
      "font-size": "small"
    },
    attrs: {
      type: "textarea",
      rows: 3
    },
    model: {
      value: _vm.generateForm.genPublicKey,
      callback: function ($$v) {
        _vm.$set(_vm.generateForm, "genPublicKey", $$v);
      },
      expression: "generateForm.genPublicKey"
    }
  }) : _vm._e(), _vm._v(" "), _vm.generateForm.algorithmType == "RSA2" ? _c("el-input", {
    staticStyle: {
      "font-size": "small"
    },
    attrs: {
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.generateForm.genPublicKey,
      callback: function ($$v) {
        _vm.$set(_vm.generateForm, "genPublicKey", $$v);
      },
      expression: "generateForm.genPublicKey"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function ($event) {
        return _vm.setGenPublicKey();
      }
    }
  }, [_vm._v("确 定")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      size: "mini"
    },
    on: {
      click: function ($event) {
        _vm.generateDialog = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };