var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "quick-entry"
  }, [_vm._m(0), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/sysProfit");
      }
    }
  }, [_vm._v("平台月收益统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/monthTrans");
      }
    }
  }, [_vm._v("劳务公司交易额月统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/taxTransaction");
      }
    }
  }, [_vm._v("商户交易额统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/taxGrade");
      }
    }
  }, [_vm._v("商户评级统计统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/regionTransaction");
      }
    }
  }, [_vm._v("区域交易额统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/saleTransation");
      }
    }
  }, [_vm._v("客户经理交易额统计表")]), _vm._v(" "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.gotoPath("/dataReport/partner");
      }
    }
  }, [_vm._v("服务商交易额排行")])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "quick-entry-title"
  }, [_c("span", [_vm._v("统计报表快捷入口")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };