var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("el-form", {
    ref: "saveForm",
    staticClass: "save-base",
    attrs: {
      model: _vm.saveForm,
      "label-width": "100px",
      rules: _vm.rules,
      size: "mini",
      "label-position": "right"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目名称",
      prop: "projectName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入项目名称"
    },
    model: {
      value: _vm.saveForm.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "projectName", $$v);
      },
      expression: "saveForm.projectName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目母编号",
      prop: "parentProjectNo"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      placeholder: "请输入项目母编号"
    },
    model: {
      value: _vm.saveForm.parentProjectNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "parentProjectNo", $$v);
      },
      expression: "saveForm.parentProjectNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目子编号",
      prop: "projectNo"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20",
      placeholder: "请输入项目子编号"
    },
    model: {
      value: _vm.saveForm.projectNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "projectNo", $$v);
      },
      expression: "saveForm.projectNo"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分包范围",
      prop: "projectScope"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择分包范围",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.projectScope,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "projectScope", $$v);
      },
      expression: "saveForm.projectScope"
    }
  }, _vm._l(_vm.projectScopeList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开工日期",
      prop: "startDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "选择日期",
      "value-format": "yyyy-MM-dd",
      editable: false
    },
    model: {
      value: _vm.saveForm.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "startDate", $$v);
      },
      expression: "saveForm.startDate"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "完工日期",
      prop: "completeDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "选择日期",
      "value-format": "yyyy-MM-dd",
      editable: false
    },
    model: {
      value: _vm.saveForm.completeDate,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "completeDate", $$v);
      },
      expression: "saveForm.completeDate"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司",
      size: "mini"
    },
    on: {
      change: _vm.onTaxsourceList
    },
    model: {
      value: _vm.saveForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "taxNo", $$v);
      },
      expression: "saveForm.taxNo"
    }
  }, _vm._l(_vm.taxsourceList, function (item) {
    return _c("el-option", {
      key: item.taxNo,
      attrs: {
        label: item.taxName,
        value: item.taxNo
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属甲方单位",
      prop: "merchantNo"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: !_vm.saveForm.taxNo || _vm.merchantList.length === 0,
      clearable: "",
      filterable: "",
      placeholder: "请选择所属企业",
      size: "mini"
    },
    on: {
      change: _vm.onMerchantList
    },
    model: {
      value: _vm.saveForm.merchantNo,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "merchantNo", $$v);
      },
      expression: "saveForm.merchantNo"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.merchantNo,
      attrs: {
        label: item.merchantName,
        value: item.merchantNo
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人姓名",
      prop: "contactName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人姓名"
    },
    model: {
      value: _vm.saveForm.contactName,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactName", $$v);
      },
      expression: "saveForm.contactName"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码",
      prop: "creditCode"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: true,
      placeholder: "统一信用代码"
    },
    model: {
      value: _vm.creditCode,
      callback: function ($$v) {
        _vm.creditCode = $$v;
      },
      expression: "creditCode"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机号",
      prop: "contactMobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人手机号"
    },
    on: {
      change: _vm.changeMobile
    },
    model: {
      value: _vm.saveForm.contactMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "contactMobile", $$v);
      },
      expression: "saveForm.contactMobile"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开票类型",
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择开票类型",
      size: "mini"
    },
    model: {
      value: _vm.saveForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "invoiceType", $$v);
      },
      expression: "saveForm.invoiceType"
    }
  }, _vm._l(_vm.merchantInvoiceType, function (item) {
    return _c("el-option", {
      key: item.label,
      attrs: {
        label: item.name,
        value: item.label
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "工程所在区域",
      prop: "areaCode"
    }
  }, [_c("city-cascader", {
    ref: "proviceCity",
    attrs: {
      leaf: "2",
      placeholder: "请选择工程所在区域"
    },
    model: {
      value: _vm.saveForm.areaCode,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "areaCode", $$v);
      },
      expression: "saveForm.areaCode"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "工程详细地址",
      prop: "address"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入工程详细地址"
    },
    model: {
      value: _vm.saveForm.address,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "address", $$v);
      },
      expression: "saveForm.address"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "复核人手机号",
      prop: "reviewMobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "默认为联系人手机号"
    },
    model: {
      value: _vm.saveForm.reviewMobile,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "reviewMobile", $$v);
      },
      expression: "saveForm.reviewMobile"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.saveForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "remark", $$v);
      },
      expression: "saveForm.remark"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 130
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "业主单位"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入业主单位"
    },
    model: {
      value: _vm.saveForm.businessOwner,
      callback: function ($$v) {
        _vm.$set(_vm.saveForm, "businessOwner", $$v);
      },
      expression: "saveForm.businessOwner"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-button", {
    staticClass: "step-next-btn",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubmit
    }
  }, [_vm._v("确定")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };