import ItemTitle from '@/base/ui/title/itemTitle';
import { teamLeaderApplyInfo, teamLeaderApplyUpdate } from '@/api/leaderApply';
export default {
  name: 'applyDetail',
  components: {
    ItemTitle
  },
  data() {
    return {
      loading: false,
      auditRemark: '',
      info: {}
    };
  },
  computed: {
    itemId: {
      get() {
        return this.$route.query.id;
      },
      set() {
        this.teamLeaderApplyInfo();
      }
    }
  },
  created() {
    this.teamLeaderApplyInfo();
  },
  methods: {
    async teamLeaderApplyInfo() {
      this.loading = true;
      const params = {
        id: this.itemId
      };
      const res = await teamLeaderApplyInfo(params);
      this.info = res.data;
      this.loading = false;
    },
    pass() {
      if (!this.auditRemark) {
        this.$message.error('请先填写处理意见！');
        return;
      }
      this.$confirm('确认通过审核并赋予该用户班组长身份并创建班组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const status = 1;
        this.teamLeaderApplyUpdate(status);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    refuse() {
      if (!this.auditRemark) {
        this.$message.error('请先填写处理意见！');
        return;
      }
      this.$confirm('确认拒绝?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const status = 2;
        this.teamLeaderApplyUpdate(status);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    async teamLeaderApplyUpdate(status) {
      const params = {
        auditRemark: this.auditRemark,
        id: this.itemId,
        status
      };
      const res = await teamLeaderApplyUpdate(params);
      this.$message({
        type: 'success',
        message: '审核成功!'
      });
      this.back();
    },
    back() {
      this.$router.go(-1);
    }
  }
};