import ItemTitle from '@/base/ui/title/itemTitle';
import { isBankCardNo, isMoneyNumber } from '@/utils/validate_rules';
import { saveRefund } from '../../../../api/account';
export default {
  name: 'addRefundDialog',
  components: {
    ItemTitle
  },
  data() {
    return {
      saveForm: {
        accountName: '',
        accountNo: '',
        adjustAmount: '',
        merchantNo: '',
        refundAmount: '',
        remark: '',
        taxNo: ''
      },
      myDialogFormVisible: false,
      belongList: [],
      taxRouterList: [],
      taxRouterStatus: this.$formatTypeArray('taxRouterStatus'),
      rules: {
        accountName: [{
          required: true,
          message: '请输入退回账户名称',
          trigger: 'blur'
        }],
        accountNo: [{
          required: true,
          message: '请输入退回账户名称',
          trigger: 'blur'
        }, {
          validator: isBankCardNo,
          trigger: 'blur'
        }],
        refundAmount: [{
          required: true,
          message: '请输入退款金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        adjustAmount: [{
          required: true,
          message: '请输入调账金额',
          trigger: 'blur'
        }, {
          validator: isMoneyNumber,
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请输入退款原因',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    addRefundDialogVisible: false,
    item: ''
  },
  watch: {
    addRefundDialogVisible(val) {
      this.myDialogFormVisible = val;
      if (val) {
        this.saveForm.custName = this.item.custName;
        this.saveForm.availAmount = this.item.availAmount;
        this.saveForm.taxNo = this.item.taxNo;
        this.saveForm.merchantNo = this.item.belong;
      }
    },
    myDialogFormVisible(val) {
      this.$emit('changeRefundDialogVisible', val);
    }
  },
  methods: {
    doSubmit() {
      this.saveForm.id = this.item.id;
      this.$refs['saveForm'].validate(valid => {
        if (valid) {
          const params = {
            accountName: this.saveForm.accountName,
            accountNo: this.saveForm.accountNo,
            adjustAmount: this.saveForm.adjustAmount,
            refundAmount: this.saveForm.refundAmount,
            remark: this.saveForm.remark,
            merchantNo: this.item.belong,
            taxNo: this.item.taxNo
          };
          saveRefund(params).then(response => {
            this.myDialogFormVisible = false;
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          });
        } else {
          return false;
        }
      });
    },
    dialogClosed() {
      this.$refs['saveForm'].resetFields();
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};