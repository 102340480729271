import { render, staticRenderFns } from "./setBankCard.vue?vue&type=template&id=0cecb78f&scoped=true"
import script from "./setBankCard.vue?vue&type=script&lang=js"
export * from "./setBankCard.vue?vue&type=script&lang=js"
import style0 from "./setBankCard.vue?vue&type=style&index=0&id=0cecb78f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cecb78f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test-builder-web@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cecb78f')) {
      api.createRecord('0cecb78f', component.options)
    } else {
      api.reload('0cecb78f', component.options)
    }
    module.hot.accept("./setBankCard.vue?vue&type=template&id=0cecb78f&scoped=true", function () {
      api.rerender('0cecb78f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/merchantInfo/setBankCard.vue"
export default component.exports