import { mapGetters } from 'vuex';
import Pagination from '@/components/pagination';
import table from '@/utils/table';
import { getPushInfo, createPushInfo, getReportPushList } from '../../../api/task';
import { getAcountInfo } from '../../../utils/auth';
import { showlist } from '../../../api/taxsource';
export default {
  name: 'reportMerchantContent',
  components: {
    Pagination
  },
  data() {
    return {
      searchForm: {
        month: '',
        status: ''
      },
      diaLogForm: {
        taxNo: '',
        month: '',
        merchantCnt: '',
        empCnt: '',
        mustReportCnt: ''
      },
      dialogVisible: false,
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      list: [],
      pushVisible: '',
      addCategoryShow: false,
      updateCategoryShow: false,
      updateItem: {},
      merchantList: [],
      taxSources: [],
      partyType: '',
      pushInfoShow: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    isDisable() {
      if (!this.diaLogForm.merchantCnt && !this.diaLogForm.empCnt && !this.diaLogForm.mustReportCnt) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getTaxSources();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.pushVisible = map.has('push');
    }
    this.getList();
  },
  methods: {
    rePush(item, type) {
      this.$confirm(`是否确定${type == '1' ? '继续' : '重新'}推送?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        createPushInfo({
          taxNo: item.taxNo,
          month: item.month,
          id: item.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.getList();
        });
      });
    },
    confirmPush() {
      this.$confirm('是否确定推送?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        createPushInfo({
          taxNo: this.diaLogForm.taxNo,
          month: this.diaLogForm.month
        }).then(res => {
          if (res.code == 'BASE.000') {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.errMsg
            });
          }
          this.getList();
          this.dialogVisible = false;
        });
      });
    },
    handlePush() {
      this.dialogVisible = true;
      this.diaLogForm.taxNo = '';
      this.diaLogForm.month = '';
      this.pushInfoShow = false;
    },
    dialogChange() {
      if (this.diaLogForm.taxNo && this.diaLogForm.month) {
        getPushInfo({
          month: this.diaLogForm.month,
          taxNo: this.diaLogForm.taxNo
        }).then(res => {
          console.log(res);
          this.pushInfoShow = true;
          this.diaLogForm.empCnt = res.data.empCnt;
          this.diaLogForm.merchantCnt = res.data.merchantCnt;
          this.diaLogForm.mustReportCnt = res.data.mustReportCnt;
        });
      } else {
        this.pushInfoShow = false;
      }
    },
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      getReportPushList({
        ...this.currentSearch,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    }
  }
};