var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("div", {
    staticClass: "layout-container"
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      "label-width": "100px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "充值金额",
      prop: "amount"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    model: {
      value: _vm.ruleForm.amount,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "amount", $$v);
      },
      expression: "ruleForm.amount"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "支付方式",
      prop: "bankItem"
    }
  }, [_c("div", {}, [_c("span", {
    staticClass: "title-container"
  }, [_vm._v("\n            甲方单位网银\n          ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "-10px"
    }
  }, [_c("el-radio-group", {
    staticClass: "radio-group-content",
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ruleForm.bankItem,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "bankItem", $$v);
      },
      expression: "ruleForm.bankItem"
    }
  }, _vm._l(_vm.banks, function (item, index) {
    return _c("el-radio", {
      key: item.id,
      staticClass: "custom-radio",
      attrs: {
        label: item,
        border: ""
      }
    }, [_c("bank-item", {
      attrs: {
        icon: item.logUrl,
        title: item.bankName
      }
    })], 1);
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#E3530A",
      "font-size": "12px"
    }
  }, [_vm._v("\n            甲方单位网银充值，请在跳转网银后的3个自然日内完成充值，超期将会退款。\n          ")])])]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.ruleForm.remark,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "remark", $$v);
      },
      expression: "ruleForm.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v("立即充值")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };