import request from '@/utils/request';
import download from '@/utils/download';

/**
 * 查询园区企业签约信息列表
 * @param param
 */
export const queryMerchantSignList = params => {
  const {
    merchantNo,
    merchantName,
    taxNo,
    status,
    channelType,
    jobSwitch,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/tax/taxMerchant/list',
    method: 'post',
    data: {
      merchantNo,
      merchantName,
      taxNo,
      status,
      channelType,
      jobSwitch,
      pageNo,
      pageSize
    }
  });
};

/**
 * 获取园区通道列表*/
export const queryTaxChannelList = taxNo => {
  return request({
    url: '/tax/taxMerchant/channelSelect',
    method: 'post',
    data: {
      taxNo
    }
  });
};

/**
 * 修改更新企业签约通道信息
 * @param param
 */
export const updateTaxChannel = params => {
  return request({
    url: '/tax/taxMerchant/updateChannel',
    method: 'post',
    data: params
  });
};

/**
 * 查看企业商户详细信息(运营/园区)
 * @param param
 */
export const merchantSignInfo = id => {
  return request({
    url: '/tax/taxMerchant/info',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 新增企业签约信息
 * @param param
 */
export const saveTaxMerchantSign = param => {
  return request({
    url: '/tax/taxMerchant/save',
    method: 'post',
    data: param
  });
};

/**
 * 修改企业签约信息
 * @param param
 */
export const updateTaxMerchantSign = param => {
  return request({
    url: '/tax/taxMerchant/update',
    method: 'post',
    data: param
  });
};

/**
 * 审核企业签约关系*/
export const auditMerchantSign = id => {
  return request({
    url: '/tax/taxMerchant/audit',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 解冻企业签约关系*/
export const unfreezeMerchantSign = id => {
  return request({
    url: '/tax/taxMerchant/unfreeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 * 冻结企业签约关系*/
export const freezeMerchantSign = id => {
  return request({
    url: '/tax/taxMerchant/freeze',
    method: 'post',
    data: {
      id
    }
  });
};

/**
 *获取可选择园区列表(用于新增企业签约和修改企业签约)
 * @param param
 */
export const taxSelect = () => {
  return request({
    url: '/tax/taxMerchant/taxSelect',
    method: 'post'
  });
};

/**
 * 下载企业签约列表数据)
 * @param param
 */
export const downloadMchntSignList = params => {
  return download({
    url: '/tax/taxMerchant/download',
    data: params
  });
};

/**
 * 获取项目下拉列表
 * @param param
 */
export const mchntProjectShowlist = params => {
  return request({
    url: '/mchntProject/showlist',
    method: 'post',
    data: params
  });
};

/**
 * 获取归属项目下拉列表
 * @param param
 */
export const mchntProjectBelongProjectList = params => {
  return request({
    url: '/mchntProject/belongProjectList',
    method: 'post',
    data: params
  });
};

/**
 * 获取详细信息(传参示例{merchantNo:M123})
 * @param param
 */
export const invoiceConfigInfo = params => {
  return request({
    url: '/invoice/invoiceConfig/info',
    method: 'post',
    data: params
  });
};

/**
 * 发票信息更新
 * @param param
 */
export const invoiceConfigUpdate = params => {
  return request({
    url: '/invoice/invoiceConfig/update',
    method: 'post',
    data: params
  });
};

/**
 * 选择付款通道
 * @param param
 */
export const taxChannelSelectList = params => {
  return request({
    url: '/tax/taxChannel/selectList',
    method: 'post',
    data: params
  });
};

/**
 * 代付批次信息表
 * @param param
 */
export const repayBatchDelete = params => {
  return request({
    url: '/trade/repayBatch/delete',
    method: 'post',
    data: params
  });
};

/**
 * 代付批次信息表）
 * @param param
 */
export const repayBatchDeleteCheck = params => {
  return request({
    url: '/trade/repayBatch/deleteCheck',
    method: 'post',
    data: params
  });
};