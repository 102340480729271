import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { getInvoiceInfoReq } from '@/api/invoice';
import ItemTitle from '@/base/ui/title/itemTitle';
import DetailColumn from '@/components/detailColumn';
import CheckInvoice from '../components/checkInvoice';
import UpdateInvoice from '../components/updateInvoice';
import { AUDIT_INVOICE_ID } from '../../../utils/idName';
import { invoiceRecordAudit } from '../../../api/invoice';
export default {
  name: 'auditInvoice',
  components: {
    ItemTitle,
    DetailColumn,
    CheckInvoice,
    UpdateInvoice
  },
  data() {
    return {
      infoForm: {
        logList: []
      },
      hasCancelPermission: false,
      hasCheckPermission: false,
      hasUpdatePermission: false,
      id: '',
      span: 12,
      gutter: 40,
      isCheckVisible: false,
      isUpdateVisible: false,
      expressInfo: null,
      auditForm: {
        auditRemark: ''
      }
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    invoiceType: function () {
      return this.$formatType('invoiceType', this.infoForm.invoiceType);
    },
    openType: function () {
      return this.$formatType('invoiceOpenType', this.infoForm.openType);
    },
    status: function () {
      return this.$formatType('invoiceStatus', this.infoForm.status);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.id = sessionStorage.getItem(AUDIT_INVOICE_ID);
      vm.getDetail();
    });
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    // 审核
    check(status) {
      this.isCheckVisible = true;
      invoiceRecordAudit({
        auditRemark: this.auditForm.auditRemark,
        status: status,
        id: this.id
      }).then(response => {
        this.$message.success('处理成功');
        this.goBack();
      });
    },
    getDetail() {
      getInvoiceInfoReq({
        id: this.id
      }).then(response => {
        this.infoForm = response.data;
      });
    },
    goBack() {
      const {
        path,
        name
      } = this.$router.currentRoute;
      this.$store.dispatch('delVisitedViewsByPath', path, name).then(() => {
        this.$router.go(-1);
      });
    },
    // 刷新
    updated() {
      this.getDetail();
    }
  }
};