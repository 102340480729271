var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.className
  }, [_c("div", {
    class: _vm.titleClassName
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c("div", {
    class: _vm.valueClassName
  }, [_vm._v(_vm._s(_vm.value))]), _vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };