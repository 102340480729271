var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "发票信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票抬头：",
      value: _vm.info.merchantName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "税务登记号：",
      value: _vm.info.invoiceNo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票金额：",
      value: _vm.$fmoneyWithSymbol(_vm.info.amount),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "发票内容：",
      value: _vm.info.contents,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "发票类型：",
      value: _vm.$formatType("invoiceType", _vm.info.invoiceType),
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "发票编号：",
      value: _vm.info.billInfo,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "所属劳务公司：",
      value: _vm.info.taxName,
      left: true
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "关联付款"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "value-line"
  }, [_c("div", {
    staticClass: "total_label"
  }, [_vm._v("\n        " + _vm._s(_vm.info.availAmount) + "\n        可关联金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.info.amount)))]), _vm._v("；\n        已关联金额(元)：  "), _c("font", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$fmoneyWithSymbol(_vm.selectMoney)))])], 1), _vm._v(" "), _c("div", {
    staticClass: "btn-right"
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      "show-file-list": false,
      "on-success": _vm.onSuccess,
      "before-upload": _vm.beforeDemoUpload,
      action: _vm.importAssociateFile,
      data: {
        id: _vm.id
      }
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "success"
    }
  }, [_vm._v("导入关联明细")])], 1), _vm._v(" "), _c("el-link", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      href: _vm.url,
      download: "关联模板",
      target: "_blank"
    }
  }, [_vm._v("下载关联模板")])], 1)]), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    ref: "table",
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.list,
      "highlight-current-row": "",
      size: "mini",
      border: "",
      "row-key": _vm.getRowKeys
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55",
      "reserve-selection": true
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "bussincessNo",
      label: "业务订单号",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "账户名称",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayAmount",
      label: "付款金额",
      "min-width": "140",
      formatter: _vm.$fmoneyWithSymbol
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "accountNo",
      label: "收款账户",
      "min-width": "140"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "completeTime",
      label: "付款时间",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "repayRemark",
      label: "付款(业绩)说明",
      "min-width": "150"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      position: "relative",
      height: "40px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.doSubmit
    }
  }, [_vm._v("确认关联")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    staticStyle: {
      position: "absolute",
      right: "0",
      bottom: "0"
    },
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1)], 1), _vm._v(" "), _c("associate-preview", {
    attrs: {
      dialogVisible: _vm.isDialogVisible,
      totalAmount: _vm.totalAmount,
      totalCount: _vm.totalCount,
      serialNo: _vm.serialNo
    },
    on: {
      "update:dialogVisible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      "update:dialog-visible": function ($event) {
        _vm.isDialogVisible = $event;
      },
      changeDialogVisible: _vm.changeDialogVisible,
      "on-success": _vm.previewSuccess
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };