import table from '@/utils/table';
import { mapGetters } from 'vuex';
import Pagination from '../../../components/pagination/index';
import { downloadExcel } from '../../../utils/util';
import { showlist } from '../../../api/taxsource';
import { downloadPaymentAlert, paymentAlertListReg, querySetValueReg } from '../../../api/account';
import SetValueDialog from './components/setValueDialog';
export default {
  name: 'paymentAlertList',
  components: {
    SetValueDialog,
    Pagination
  },
  data() {
    return {
      total: 0,
      searchForm: {
        queryDimension: 1,
        pageNo: 1,
        pageSize: 10
      },
      currentSearch: {
        pageNo: 1,
        pageSize: 10
      },
      taxSources: [],
      nationwidePayLimit: '',
      // 全国付款限额
      taxPayLimit: '',
      // 劳务公司付款限额
      list: [],
      setValuePermission: false,
      hasDownloadPermission: false,
      setValueDialogVisible: false
    };
  },
  computed: {
    ...mapGetters(['buttonsMap'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    this.querySetValue();
    this.getList();
    this.getTaxSources();
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.setValuePermission = map.get('setValue');
      this.hasDownloadPermission = map.get('download');
    }
  },
  methods: {
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    },
    getTaxSources() {
      showlist().then(response => {
        this.taxSources = response.data;
      });
    },
    queryForm() {
      this.searchForm.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    getList() {
      this.listLoading = true;
      paymentAlertListReg(this.currentSearch).then(response => {
        this.list = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    indexMethod(index) {
      return table.indexMethod(index, this.currentSearch.pageNo, this.currentSearch.pageSize);
    },
    downloadForm() {
      downloadPaymentAlert(this.currentSearch).then(response => {
        downloadExcel(response.data, '付款预警计表.xls');
      });
    },
    querySetValue() {
      querySetValueReg().then(response => {
        this.nationwidePayLimit = response.data.nationwidePayLimit;
        this.taxPayLimit = response.data.taxPayLimit;
      });
    },
    changeDialogVisible(val) {
      this.setValueDialogVisible = val;
      this.getList();
      this.querySetValue();
    },
    dimensionChange() {
      this.searchForm.taxNo = '';
      this.queryForm();
    }
  }
};