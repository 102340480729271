import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import table from '@/utils/table';
import VerifyCode from '@/base/ui/verifyCode';
import { batchSaveRecords, getRecordsPreview } from '../../../api/task';
export default {
  name: 'transaction-batch-query',
  components: {
    VerifyCode,
    ItemTitle,
    Pagination
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean
    },
    transactionData: {
      type: Object
    }
  },
  data() {
    return {
      listLoading: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      repaymentList: []
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(val) {
        this.$emit('update:isVisible', val);
      }
    },
    total: function () {
      return this.transactionData != null ? this.transactionData.total : 0;
    },
    taskNo: function () {
      return this.transactionData != null ? this.transactionData.taskNo : '';
    },
    taskName: function () {
      return this.transactionData != null ? this.transactionData.taskName : '';
    },
    largeCount: function () {
      return this.transactionData != null ? this.transactionData.total : 0;
    }
  },
  watch: {
    transactionData: function (newValue, oldValue) {
      this.repaymentList = newValue != null ? newValue.firstPageList.list : [];
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    submitForm() {
      this.isLoading = true;
      batchSaveRecords(this.transactionData.taskNo).then(() => {
        this.isLoading = false;
        this.dialogVisible = false;
        this.$emit('on-success');
      }).catch(() => {
        this.isLoading = false;
      });
    },
    cancelForm() {
      this.dialogVisible = false;
      this.$emit('on-cancel');
    },
    pageChanged() {
      this.listLoading = true;
      getRecordsPreview({
        merchantNo: this.transactionData.merchantNo,
        taxNo: this.transactionData.taxNo,
        taskNo: this.transactionData.taskNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(response => {
        this.listLoading = false;
        this.repaymentList = response.data.list;
      }).catch(() => {
        this.listLoading = false;
      });
    }
  }
};