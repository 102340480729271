import { mapGetters } from 'vuex';
import table from '@/utils/table';
import { timePickerOptions, separateDate, downloadExcel } from '@/utils/util';
import Pagination from '@/components/pagination';
import ItemTitle from '@/base/ui/title/itemTitle';
import { downloadTaxRouterRecharge, getTaxRouterRechargeList } from '../../../api/taxRouter';
import { VIEW_TAXSOURCE_ROUTER_RECHARGE_ID } from '../../../utils/idName';
import { getAcountInfo } from '../../../utils/auth';
import { showlist } from '../../../api/taxsource';
import DbDatePicker from '@/base/ui/date/db-date-picker';
export default {
  name: 'taxRouterRechargeList',
  components: {
    Pagination,
    ItemTitle,
    DbDatePicker
  },
  data() {
    return {
      searchForm: {
        beginTime: '',
        endTime: '',
        bussincessNo: '',
        payTaxNo: '',
        rechargeStatus: '',
        date: []
      },
      currentSearch: {},
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
      transactions: [],
      taxStatus: this.$formatTypeArray('taxStatus'),
      taxRouterRechargeInfo: '',
      partyType: '',
      taxSourceList: []
    };
  },
  computed: {
    ...mapGetters(['buttonsMap']),
    pickerOptions: function () {
      return timePickerOptions;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getList();
    });
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    showlist().then(response => {
      this.taxSourceList = response.data;
    });
    const map = this.buttonsMap.get(this.$route.path);
    if (map) {
      this.taxRouterRechargeInfo = map.get('taxRouterRechargeInfo');
    } else {
      this.taxRouterRechargeInfo = '';
    }
  },
  methods: {
    indexMethod(index) {
      return table.indexMethod(index, this.pageNo, this.pageSize);
    },
    queryForm() {
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.getList();
    },
    clearForm() {
      this.searchForm = {
        beginTime: '',
        endTime: '',
        bussincessNo: '',
        payTaxNo: '',
        rechargeStatus: '',
        date: []
      };
      this.pageNo = 1;
      this.currentSearch = JSON.parse(JSON.stringify(this.searchForm));
      this.queryForm();
    },
    downloadForm() {
      const {
        beginTime,
        endTime
      } = separateDate(this.searchForm.date);
      this.searchForm.beginTime = beginTime;
      this.searchForm.endTime = endTime;
      downloadTaxRouterRecharge(this.searchForm).then(response => {
        downloadExcel(response.data, '劳务公司路由转账记录.xls');
      });
    },
    pageChanged() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const {
        beginTime,
        endTime
      } = separateDate(this.searchForm.date);
      this.searchForm.beginTime = beginTime;
      this.searchForm.endTime = endTime;
      this.currentSearch.pageSize = this.pageSize;
      this.currentSearch.pageNo = this.pageNo;
      getTaxRouterRechargeList(this.currentSearch).then(response => {
        this.transactions = response.data.list;
        this.total = response.data.totalCount;
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
    gotoInfo(row) {
      sessionStorage.setItem(VIEW_TAXSOURCE_ROUTER_RECHARGE_ID, row.id);
      this.$router.push(this.taxRouterRechargeInfo);
    },
    formatStatus(row) {
      return this.$formatType('taxStatus', row.rechargeStatus);
    },
    concatTaxSource(tax) {
      return `${tax.taxName}(${tax.taxNo})`;
    }
  }
};