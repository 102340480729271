var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "咨询记录"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "客户名称",
      prop: "customerName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerName", $$v);
      },
      expression: "searchForm.customerName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "客户电话",
      prop: "customerPhone"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入客户电话",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.customerPhone,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "customerPhone", $$v);
      },
      expression: "searchForm.customerPhone"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "公司名称",
      prop: "companyName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入公司名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "companyName", $$v);
      },
      expression: "searchForm.companyName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "处理状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择处理状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    key: -1,
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.statusOptions, function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  })], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "处理人",
      prop: "handlerNo"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入处理人账号",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.handlerNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "handlerNo", $$v);
      },
      expression: "searchForm.handlerNo"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "申请时间",
      prop: "date",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")])], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.consults,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      label: "序号",
      width: "70",
      index: _vm.indexMethod
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custName",
      label: "客户名称",
      "min-width": "100"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custPhone",
      label: "客户电话",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "custCompany",
      label: "公司名称",
      "min-width": "190"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: "咨询内容",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "申请时间",
      "min-width": "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "处理状态",
      "min-width": "100",
      formatter: _vm.$formatTypeInTable("consultStatus")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "userName",
      label: "处理人",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "handleDesc",
      label: "处理描述",
      "min-width": "200",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "处理时间",
      "min-width": "180"
    }
  }), _vm._v(" "), _vm.hasHandlePermission || _vm.hasClosePermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasHandlePermission && scope.row.status == 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("处理")]) : _vm._e(), _vm._v(" "), _vm.hasClosePermission && scope.row.status == 0 ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.closeRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("关闭")]) : _vm._e()];
      }
    }], null, false, 4023992364)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  }), _vm._v(" "), _c("handle-form", {
    attrs: {
      isVisible: _vm.isHandleVisible,
      id: _vm.curRecordID
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.isHandleVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.isHandleVisible = $event;
      },
      handled: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };