var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "基础信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "所属月份:",
      value: _vm.infoForm.month,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "状态:",
      value: _vm.$formatType("taskReportStatus", _vm.infoForm.status),
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "姓名:",
      value: _vm.infoForm.custName,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "身份证号:",
      value: _vm.infoForm.idcard,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "手机号:",
      value: _vm.infoForm.mobile,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "本月服务企业:",
      value: _vm.infoForm.merchantName,
      left: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "完成任务数:",
      value: _vm.infoForm.taskCount,
      left: true
    }
  }), _vm._v(" "), _c("detail-column", {
    attrs: {
      title: "实收佣金",
      value: _vm.$fmoneyWithSymbol(_vm.infoForm.payAmount),
      left: false
    }
  })], 1)], 1), _vm._v(" "), Object.keys(_vm.infoForm).length > 0 && _vm.infoForm.recordList && _vm.infoForm.recordList.length > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "完成任务清单"
    }
  }), _vm._v(" "), _c("el-table", {
    staticStyle: {
      margin: "30px 0"
    },
    attrs: {
      data: _vm.infoForm.recordList,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "recordNo",
      label: "提交记录编号",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taskName",
      label: "任务名称",
      "min-width": "160"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "服务劳务公司",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "submitCount",
      label: "完成数量",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditTime",
      label: "确认时间",
      "min-width": "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "auditAmount",
      formatter: _vm.$fmoneyWithSymbol,
      label: "确认金额",
      "min-width": "120"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.infoForm.status > 0 ? _c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "确认记录"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "确认时间:",
      value: _vm.infoForm.completeTime,
      left: true
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("detail-column", {
    attrs: {
      title: "用户签名:",
      left: true
    }
  }, [_c("el-image", {
    staticStyle: {
      width: "120px",
      height: "100px",
      "margin-right": "30px"
    },
    attrs: {
      src: _vm.infoForm.signedUrl,
      "preview-src-list": [_vm.infoForm.signedUrl]
    }
  })], 1)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };