var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "saveForm",
    staticClass: "border-bg save-merchant-sign",
    attrs: {
      model: _vm.saveForm,
      "label-width": "120px",
      size: "mini",
      "label-position": "left"
    }
  }, [_c("item-title", {
    attrs: {
      name: "基本信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约劳务公司："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.taxName) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "企业："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.merchantName) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约项目："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.belongName) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "签约日期："
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.protocolStartShow) + "至" + _vm._s(_vm.saveForm.protocolEndShow) + "\n\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传协议附件："
    }
  }, [_c("div", {
    staticClass: "link-view"
  }, _vm._l(_vm.saveForm.fileList, function (item, index) {
    return _c("el-link", {
      key: index,
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.downloadUrl(item);
        }
      }
    }, [_vm._v("\n                " + _vm._s(item.fileName) + "\n              ")]);
  }), 1)])], 1)], 1)], 1), _vm._v(" "), _c("item-title", {
    attrs: {
      name: "扣费信息"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "save-base-bg"
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "扣费模式：",
      "label-width": "160px"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$formatType("chargeType", _vm.saveForm.billMode)) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_vm.saveForm.billMode !== 1 ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "服务费费率：",
      "label-width": "160px"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.saveForm.baseRate) + "\n          ")])], 1) : _vm._e(), _vm._v(" "), _vm.saveForm.billMode === 2 ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "类型:",
      prop: "cumulativeType"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$formatType("cumulativeType", _vm.saveForm.cumulativeType)) + "\n          ")])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("free-view", {
    ref: "freeView",
    attrs: {
      list: _vm.saveForm.rateInfoList,
      text: _vm.freeText,
      name: _vm.freeName,
      isUpdate: false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "border-bg1"
  }, [_c("el-table", {
    attrs: {
      data: _vm.saveForm.logList,
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "realName",
      label: "操作员",
      "min-width": "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "操作时间",
      "min-width": "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "描述",
      "min-width": "200"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };