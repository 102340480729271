import CityCascader from '@/base/ui/cascader/cityCascader';
import ItemTitle from '../../../../base/ui/title/itemTitle';
import { getAcountInfo } from '@/utils/auth';
export default {
  components: {
    ItemTitle,
    CityCascader
  },
  name: 'merchantViewBase',
  data() {
    return {
      legalPersonIdcardpic: '',
      licensePic: '',
      protocolUrl: '',
      salesManList: [],
      agentList: [],
      partyType: '',
      partyCode: ''
    };
  },
  props: {
    gotoNext: {
      default: null
    },
    saveForm: {
      address: '',
      agentNo: '',
      areaCode: '',
      cityCode: '',
      contactMobile: '',
      contacts: '',
      legalPerson: '',
      legalPersonIdcard: '',
      legalPersonIdcardpic: '',
      licenseNo: '',
      licensePic: '',
      merchantName: '',
      provinceCode: '',
      repayNotifyUrl: '',
      reviewMobile: '',
      salesmanNo: '',
      merchantNo: ''
    },
    gjAgentInfo: {},
    gjHelperSupport: ''
  },
  created() {
    const user = getAcountInfo();
    this.partyType = user.partyType;
    this.partyCode = user.partyCode;
  },
  methods: {
    formatCity() {
      if (this.saveForm.city === '' || this.saveForm.province === undefined) {
        return '';
      } else {
        return this.saveForm.province + '/' + this.saveForm.city + '/' + this.saveForm.area;
      }
    },
    formatMchantInvoiceType(val) {
      return this.$formatType('merchantInvoiceType', val);
    },
    formatTaxpayerTypeStatus(val) {
      return this.$formatType('taxpayerTypeStatus', val);
    }
  }
};