export const MERCHANT_INFO_ID = 'merchantInfoId';
export const MERCHANT_SIGN_INFO_ID = 'merchantSignInfoId';
export const UPDATE_MERCHANT_SIGN_INFO_ID = 'updateMerchantSignInfoId';
export const MERCHANT_UPDATE_ID = 'merchantUpdateId';
export const MERCHANT_SIGN_UPDATE_ID = 'merchantSignUpdateId';
export const AGENT_INFO_ID = 'agentInfoId';
export const AGENT_UPDATE_ID = 'agentUpdateId';
export const TAX_UPDATE_ID = 'taxUpdateId';
export const ADJUST_VERIFY_ID = 'adjustVerifyId';
export const AUDIT_RECHARGE_ID = 'auditRechargeId';
export const VIEW_RECON_ERROR_INFO_ID = 'viewReconErrorInfoId';
export const WITHDRAW_VERIFY_ID = 'withdrawVerifyId';
export const API_UPDATE_ID = 'apiUpdateId';
export const API_INFO_ID = 'apiInfoId';
export const DICTDETAIL_ID = 'dictDetailId';
export const MENU_UPDATE_ID = 'menuUpdateId';
export const MENU_INFO_ID = 'menuInfoId';
export const ROLE_UPDATE_ID = 'roleUpdateId';
export const ROLE_INFO_ID = 'roleInfoId';
export const USER_UPDATE_ID = 'userUpdateId';
export const USER_INFO_ID = 'userInfoId';
export const PARTYROLE_UPDATE_ID = 'partyRoleUpdateId';
export const PARTYROLE_INFO_ID = 'partyRoleInfoId';
export const PROFIT_DETAIL_ID = 'profitDetailId';
export const RISK_BLACK_UPDATE_ID = 'riskBlackUpdateId';
export const RISK_WHITE_UPDATE_ID = 'riskWhiteUpdateId';
export const SALESMAN_INFO_ID = 'salesmanInfoId';
export const UPDATE_APP_INFO_ID = 'updateAppInfoId';
export const VEW_APP_INFO_ID = 'vewAppInfoId';
export const VIEW_REPAYMENT_ID = 'viewRepaymentId';
export const INVOICE_INFO = 'invoiceInfo';
export const INVOICE_OPERATION_PERMISSION = 'invoiceOperationPermission';
export const TAX_NAME = 'taxName';
export const EMPLOYEE_NO = 'empNo';
export const ACCOUNT_DETAIL_ID = 'accountDetailId';
export const RECHARGE_DETAIL_ID = 'rechargeDetailId';
export const WITHDRAW_DETAIL_ID = 'withdrawDetailId';
export const WITHDRAW_CHECK_ID = 'withdrawCheckId';
export const BATCH_DETAIL_ID = 'batchDetailId';
export const BATCH_CHECK_ID = 'batchCheckId';
export const QUERY_DETAIL_ID = 'queryDetailId';
export const VIEW_TAXSOURCE_CHANNEL_ID = 'viewTaxSourceChannelID';
export const UPDATE_TAXSOURCE_CHANNEL_ID = 'updateTaxSourceChannelID';
export const VIEW_TAXSOURCE_ROUTER_RECHARGE_ID = 'viewTaxSourceRouterRechargeID';
export const VIEW_APP_INFO_ID = 'viewAppInfoId';
export const VIEW_INVOICE_CONTENT_ID = 'invoiceContentId';
export const PAY_VERIFY_CODE = '1001';
export const UPDATE_INVOICE_ITEM_ID = 'updateInvoiceItemId';
export const VIEW_INVOICE_ID = 'viewInvoiceID';
export const AUDIT_INVOICE_ID = 'auditInvoiceID';
export const PROJECT_INFO_ID = 'projectInfoId';
export const TASK_INFO_ID = 'taskInfoId';
export const TASK_UPDATE_ID = 'taskUpdateId';
export const TASK_AUDIT_ID = 'taskAuditId';
export const TASK_RECORD_ID = 'taskRecordId';
export const BATCH_SAVE_INFO = 'batchSaveInfo';
export const RATE_LIST_AGENT_NO = 'rateListAgentNo';
export const RATE_ADD_AGENT_INFO = 'rateAddAgentInfo';
export const RATE_UPDATE_AGENT_INFO = 'rateUpdateAgentInfo';
export const RATE_VIEW_AGENT_INFO = 'rateViewAgentInfo';
export const WITHDRAW_DETAIL_OBJECT = 'withdrawDetailObject';
export const PROFIT_VIEW_RECORD_INFO = 'viewProfitRecordInfo';
export const PROFIT_CONFIRM_RECORD_ID = 'confirmProfitRecordId';
export const PROFIT_ADJUST_RECORD_ID = 'adjustProfitRecordId';
export const SYS_MESSAGE_INFO_ID = 'sysMessageInfoID';
export const SYS_MESSAGE_ADD_ID = 'sysMessageAddID';
export const MESSAGE_CENTER_INFO_ID = 'msgCenterInfoId';
export const TASK_UPDATE_CATEGORY_ID = 'updateCategory';
export const BANK_UPDATE_ID = 'updateBankId';
export const BANK_INFO_ID = 'infoBankId';
export const BANNER_UPDATE_ID = 'updateBannerId';
export const BANNER_INFO_ID = 'infoBannerId';
export const ASSOCIATE_LIST_ID = 'associateListId';
export const ASSOCIATE_INFO_ID = 'associateInfoId';
export const WTDZ_INFO_ID = 'taxWszmdjInfoId';
export const EMPLOYEE_INFO_ID = 'employeeInfoId';
export const KAI_PIAO_INFO = 'kpInfo';
export const PROJECT_NO = 'projectNo';