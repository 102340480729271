var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "border-bg"
  }, [_c("item-title", {
    attrs: {
      name: "发票内容管理"
    }
  }), _vm._v(" "), _c("el-form", {
    ref: "searchForm",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      model: _vm.searchForm,
      inline: "",
      size: "mini"
    }
  }, [_vm.partyType === 1 ? _c("el-form-item", {
    attrs: {
      label: "所属劳务公司"
    }
  }, [_c("el-input", {
    attrs: {
      value: _vm.taxName,
      disabled: ""
    }
  })], 1) : _c("el-form-item", {
    attrs: {
      label: "所属劳务公司",
      prop: "taxNo"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择所属劳务公司"
    },
    model: {
      value: _vm.searchForm.taxNo,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "taxNo", $$v);
      },
      expression: "searchForm.taxNo"
    }
  }, _vm._l(_vm.taxSources, function (tax) {
    return _c("el-option", {
      key: tax.taxNo,
      attrs: {
        label: _vm.concatTaxSource(tax),
        value: tax.taxNo
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开票类型",
      prop: "invoiceType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择开票类型",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.invoiceType,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "invoiceType", $$v);
      },
      expression: "searchForm.invoiceType"
    }
  }, _vm._l(_vm.$formatTypeArray("merchantInvoiceType"), function (status) {
    return _c("el-option", {
      key: status.label,
      attrs: {
        label: status.name,
        value: status.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "发票内容",
      prop: "contentName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入发票内容",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.contentName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "contentName", $$v);
      },
      expression: "searchForm.contentName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "创建时间",
      prop: "date",
      clearable: ""
    }
  }, [_c("db-date-picker", {
    model: {
      value: _vm.searchForm.date,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "date", $$v);
      },
      expression: "searchForm.date"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.queryForm
    }
  }, [_vm._v("查询")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "plain",
      icon: "el-icon-refresh"
    },
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _vm._v(" "), _vm.hasAddPermission ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.showAddDialog
    }
  }, [_vm._v("新增")]) : _vm._e(), _vm._v(" "), _vm.hasImportPermission ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-download"
    },
    on: {
      click: function ($event) {
        _vm.uploadDialogVisible = true;
      }
    }
  }, [_vm._v("导入")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      flex: "1"
    },
    attrs: {
      data: _vm.invoices,
      "highlight-current-row": "",
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "taxNo",
      label: "劳务公司编号",
      width: "120"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "taxName",
      label: "劳务公司名称",
      "min-width": "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "invoiceType",
      label: "开票类型",
      width: "100",
      formatter: _vm.$formatTypeInTable("merchantInvoiceType")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "contentName",
      label: "发票内容",
      "min-width": "200",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "exampleProject",
      label: "示例项目名称",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注说明",
      "min-width": "220",
      "show-overflow-tooltip": ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      width: "165"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "更新时间",
      width: "165"
    }
  }), _vm._v(" "), _vm.hasUpdatePermission || _vm.hasDeletePermission ? _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "120",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.hasUpdatePermission ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showUpdateDialog(scope.row);
            }
          }
        }, [_vm._v("修改")]) : _vm._e(), _vm._v(" "), _vm.hasDeletePermission ? _c("el-button", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.deleteRow(scope.$index, scope.row);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }], null, false, 2454478203)
  }) : _vm._e()], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.pageNo,
      limit: _vm.pageSize
    },
    on: {
      "update:page": function ($event) {
        _vm.pageNo = $event;
      },
      "update:limit": function ($event) {
        _vm.pageSize = $event;
      },
      pagination: _vm.pageChanged
    }
  })], 1), _vm._v(" "), _c("AddInvoiceContentDialog", {
    attrs: {
      addDialogVisible: _vm.addDialogVisible
    },
    on: {
      changeAddDialogVisible: _vm.changeAddDialogVisible
    }
  }), _vm._v(" "), _c("UpdateInvoiceContentDialog", {
    attrs: {
      updateDialogVisible: _vm.updateDialogVisible,
      item: _vm.updateItem
    },
    on: {
      changeUpdateDialogVisible: _vm.changeUpdateDialogVisible
    }
  }), _vm._v(" "), _c("upload-invoice-dialog", {
    attrs: {
      dialogFormVisible: _vm.uploadDialogVisible
    },
    on: {
      changeDialogVisible: _vm.changeUploadDialogVisible
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };