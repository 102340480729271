import ItemTitle from '@/base/ui/title/itemTitle';
import { getTaxSummaryCharts } from '../../../../api/stat';
import DbsHistogram from '../../../../components/chart/dbs-histogram';
export default {
  name: 'parkTransCharts',
  components: {
    DbsHistogram,
    ItemTitle
  },
  data() {
    const chartSettings = {
      // itemStyle: {
      //   color: '#2DA8FF'
      // },
      labelMap: {
        'rechargeTotalMillion': '充值金额',
        'repayTotalMillion': '发放金额',
        'ticketTotalMillion': '开票金额'
      },
      legendName: {
        'rechargeTotalMillion': '充值金额',
        'repayTotalMillion': '发放金额',
        'ticketTotalMillion': '开票金额'
      },
      dataOrder: false
    };
    return {
      chartData: {
        columns: ['taxName', 'rechargeTotalMillion', 'repayTotalMillion', 'ticketTotalMillion'],
        rows: []
      },
      chartSettings: chartSettings
    };
  },
  props: {
    searchForm: {
      required: true,
      default: () => {
        return {
          area: '',
          beginTime: '',
          endTime: '',
          taxNo: ''
        };
      }
    }
  },
  watch: {
    searchForm(val) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    clearQuery() {
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList() {
      getTaxSummaryCharts(this.searchForm).then(response => {
        this.chartData.rows = response.data;
      });
    },
    afterSetOption(chartObj) {
      chartObj.setOption({
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 40,
            textStyle: {
              fontSize: 10
            }
          }
        }
      });
    }
  }
};