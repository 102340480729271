import request from '@/utils/request';

/**
 * 查询园区路由池列表
 * @param param
 */
export const getTaxRouterOpenList = params => {
  const {
    belong,
    belongName,
    status,
    pageNo,
    pageSize
  } = params;
  return request({
    url: '/tax/taxsourceRouterOpen/list',
    method: 'post',
    data: {
      belong,
      belongName,
      status,
      pageNo,
      pageSize
    }
  });
};

/**
 * 获取路由池所有可选园区信息
 * @param param
 */
export const routerSelect = () => {
  return request({
    url: '/tax/taxsourceRouterOpen/routerSelect',
    method: 'post'
  });
};

/**
 * 新增园区路由开通
 * @param param
 */
export const addTaxRouterOpen = params => {
  const {
    belong,
    diffRechargeRate,
    rechargeRate,
    taxNo
  } = params;
  return request({
    url: '/tax/taxsourceRouterOpen/save',
    method: 'post',
    data: {
      belong,
      diffRechargeRate,
      rechargeRate,
      taxNo
    }
  });
};

/**
 * 修改园区路由开通
 * @param param
 */
export const updateTaxRouterOpen = params => {
  const {
    id,
    diffRechargeRate,
    rechargeRate,
    status
  } = params;
  return request({
    url: '/tax/taxsourceRouterOpen/update',
    method: 'post',
    data: {
      id,
      diffRechargeRate,
      rechargeRate,
      status
    }
  });
};

/**
 * 园区路由开通详情
 * @param param
 */
export const taxRouterOpenInfo = () => {
  return request({
    url: '/tax/taxsourceRouterOpen/info',
    method: 'post'
  });
};