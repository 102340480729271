import ItemTitle from '@/base/ui/title/itemTitle';
export default {
  name: 'rechargeModel',
  components: {
    ItemTitle
  },
  props: {
    saveForm: {
      required: true
    }
  }
};